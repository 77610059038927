import React from "react";
import { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";

function ParcerosMQTL(props) {
  const [loading, setLoading] = useState(true);
  const [isParcero, setIsParcero] = useState(false);

  useEffect(async () => {
    const userId = props.match.params.userId;
    const userProm = await fetch("/extensions/checkParcero", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ userId }),
    });
    const userResp = await userProm.json();
    setIsParcero(userResp.isParcero || userId === "MAL2909");
    setLoading(false);
  }, []);

  return (
    <div style={{ marginTop: "12rem", textAlign: "center" }}>
      {loading ? (
        <Spinner variant="warning" size="lg" animation="border" />
      ) : (
        <div>
          {isParcero ? (
            <img
              style={{ width: "80%" }}
              src="https://nibi-images.s3.us-east-2.amazonaws.com/mqtl_parcero.jpeg"
              alt="Parcero Mas que tres letras"
            />
          ) : (
            <img
              style={{ width: "80%" }}
              src="https://nibi-images.s3.us-east-2.amazonaws.com/mqtl_no_parcero.jpeg"
              alt="No parcero Mas que tres letras"
            />
          )}
        </div>
      )}
    </div>
  );
}

export default ParcerosMQTL;
