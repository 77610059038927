export function validateForm(event) {
  
  const form = event.target;
  const valid = form.checkValidity();

  if (valid === false) {
    event.preventDefault();
    event.stopPropagation();
  }
    
  form.classList.add('was-validated');

  return valid;
}