const IS_BUSINESS_ADMIN = 'nibi/business/IS_BUSINESS_ADMIN';
const BUSINESS_PLAN = 'nibi/business/BUSINESS_PLAN';

const initialState = {
  isBusinessAdmin: false,
  businessPlan: ''
};

export default function reducer(state = initialState, action) {

  const payload = action.payload;

  switch (action.type) {
    case IS_BUSINESS_ADMIN:
      return {
        ...state,
        isBusinessAdmin: payload
      };
    case BUSINESS_PLAN:
      return {
        ...state,
        businessPlan: payload.plan
      };
    default:
      return state;
  }

}

export function setIsBusinessAdmin(payload) {
  return { type: IS_BUSINESS_ADMIN, payload };
}

export function setBusinessPlan(payload) {
  return { type: BUSINESS_PLAN, payload };
}
