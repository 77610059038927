import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

function PrivacyPolicy() {

  return (
    <div style={{ margin: '50px 0 100px' }}>
      <Container>
        <Row>
          <Col>
            <h1 style={{ textAlign: 'center' }}>
              Política de Tratamiento de Datos Personales de Clientes Nibi S.A.S.
            </h1>
            <br />

            <p>
              La presente Política de Protección de Datos Personales (en adelante la “Política”), regula la recolección, almacenamiento, uso, circulación y supresión (en adelante el “Tratamiento”) de Datos Personales que realiza Nibi S.A.S. (en adelante “Nibi”), identificada con NIT 901236282-2, de acuerdo con las disposiciones contenidas en la Ley Estatutaria 1581 de 2012, el Decreto 1074 de 2015 y la demás normativa aplicable, por medio de la cual se dictan disposiciones generales para la protección de Datos Personales.
            </p>
            <p>
              Esta Política de Tratamiento de Datos Personales es de obligatorio y estricto cumplimiento por parte de todos los empleados de Nibi en Colombia, los contratistas y terceros que obran en nombre de Nibi. Todos los empleados de Nibi deben observar y respetar esta Política en el cumplimiento de sus funciones. En los casos en que no exista vínculo laboral se deberá incluir una cláusula contractual para que quienes obren en nombre de Nibi se obliguen a cumplir esta Política.
            </p>
            <p>
              El incumplimiento de esta Política originará sanciones de tipo laboral o responsabilidad contractual según el caso. Lo anterior sin perjuicio del deber de responder patrimonialmente por los daños y perjuicios que cause a los Titulares de los datos y/o a Nibi por el incumplimiento de Política o el indebido Tratamiento de Datos Personales.
            </p>

            <ol>
              <li>
                <h5><strong>Información del Responsable del Tratamiento de la información personal</strong></h5>
                <p>
                  La empresa Responsable del Tratamiento de los Datos Personales es:
                </p>

                <ul>
                  <li>Razón Social: Nibi S.A.S.</li>
                  <li>Domicilio: Bogotá, Colombia</li>
                  <li>Dirección: Calle 92 # 16 - 11</li>
                  <li>Correo Electrónico: jairo@nibi.com.co</li>
                </ul>
                <br />
                <p>
                  Nibi, en calidad de Responsable del Tratamiento cumplirá con los deberes establecidos por el artículo 17 de la Ley 1581 de 2012.
                </p>
              </li>
              <li>
                <h5><strong>Definiciones</strong></h5>
                <p>
                  Los siguientes son los términos definidos que serán utilizados en esta Política de Protección de Datos Personales, de acuerdo con lo establecido en la Ley 1581 de 2012, Decreto 1074 de 2015 y la demás normativa aplicable:
                </p>

                <ol type="a">
                  <li>
                    <strong>Autorización: </strong>
                    Consentimiento previo, expreso e informado del Titular para llevar a cabo el Tratamiento de Datos Personales.
                  </li>
                  <li>
                    <strong>Base de Datos: </strong>
                    Conjunto organizado de Datos Personales que sea objeto de Tratamiento.
                  </li>
                  <li>
                    <strong>Datos Personales: </strong>
                    Cualquier información vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables.
                  </li>
                  <li>
                    <strong>Dato Público: </strong>
                    Son considerados Datos Públicos, entre otros, los datos relativos al estado civil de las personas, a su profesión y oficio y a su calidad de comerciante o de servidor público. Por su naturaleza, los Datos Públicos pueden estar contenidos, entre otros, en registros públicos, documentos públicos, gacetas y boletines oficiales y sentencias judiciales debidamente ejecutoriadas que no estén sometidas a reserva.
                  </li>
                  <li>
                    <strong>Encargado del Tratamiento: </strong>
                    Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, realice el Tratamiento de Datos Personales por cuenta del Responsable del Tratamiento.
                  </li>
                  <li>
                    <strong>Responsable del Tratamiento: </strong>
                    Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, decida sobre la base de datos y/o el Tratamiento de los datos.
                  </li>
                  <li>
                    <strong>Titular: </strong>
                    Persona natural cuyos Datos Personales sean objeto de Tratamiento.
                  </li>
                  <li>
                    <strong>Tratamiento: </strong>
                    Cualquier operación o conjunto de operaciones sobre Datos Personales, tales como la recolección, almacenamiento, uso, circulación o supresión.
                  </li>
                  <li>
                    <strong>Transferencia: </strong>
                    La Transferencia de datos tiene lugar cuando el Responsable y/o Encargado del Tratamiento de Datos Personales, ubicado en Colombia, envía la información o los Datos Personales a un receptor, que a su vez es Responsable del Tratamiento y se encuentra dentro o fuera del país.
                  </li>
                  <li>
                    <strong>Transmisión: </strong>
                    Tratamiento de Datos Personales que implica la comunicación de los mismos dentro o fuera del territorio de la República de Colombia cuando tenga por objeto la realización de un Tratamiento por el Encargado por cuenta del Responsable.
                  </li>
                </ol>
                <br />
              </li>

              <li>
                <h5><strong>Principios para el Tratamiento de Datos Personales</strong></h5>

                <ol type="a">
                  <li><p> Principio de legalidad: El Tratamiento es una actividad reglada que debe sujetarse a lo establecido en la Ley 1581 de 2012 y en las demás disposiciones que la desarrollen.</p></li>
                  <li><p> Principio de finalidad: El Tratamiento debe obedecer a una finalidad legítima de acuerdo con la Constitución y la ley, la cual debe ser informada al Titular.</p></li>
                  <li><p> Principio de libertad: El Tratamiento sólo puede ejercerse con el consentimiento, previo, expreso e informado del Titular. Los datos personales no podrán ser obtenidos o divulgados sin previa autorización, o en ausencia de mandato legal o judicial que releve el consentimiento.</p></li>
                  <li><p> Principio de veracidad o calidad: La información sujeta a Tratamiento debe ser veraz, completa, exacta, actualizada, comprobable y comprensible. Se prohíbe el Tratamiento de datos parciales, incompletos, fraccionados o que induzcan a error.</p></li>
                  <li><p> Principio de transparencia: En el Tratamiento debe garantizarse el derecho del Titular a obtener del Responsable del Tratamiento o del Encargado del Tratamiento, en cualquier momento y sin restricciones, información acerca de la existencia de datos que le conciernan.</p></li>
                  <li><p> Principio de acceso y circulación restringida: El Tratamiento se sujeta a los límites que se derivan de la naturaleza de los datos personales, de las disposiciones de la ley y la Constitución. El Tratamiento sólo podrá hacerse por personas autorizadas por el Titular y/o por las personas previstas en la ley. Los datos personales, salvo la información pública, no podrán estar disponibles en Internet u otros medios de divulgación o comunicación masiva, salvo que el acceso sea técnicamente controlable para brindar un conocimiento restringido sólo a los Titulares o terceros autorizados conforme a la ley.</p></li>
                  <li><p> Principio de seguridad: La información sujeta a Tratamiento por el Responsable del Tratamiento o Encargado del Tratamiento a que se refiere la ley, se deberá manejar con las medidas técnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los registros evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.</p></li>
                  <li><p> Principio de confidencialidad: Todas las personas que intervengan en el Tratamiento de datos personales que no tengan la naturaleza de públicos están obligadas a garantizar la reserva de la información, inclusive después de finalizada su relación con alguna de las labores que comprende el Tratamiento, pudiendo sólo realizar suministro o comunicación de datos personales cuando ello corresponda al desarrollo de las actividades autorizadas en la ley y en los términos de la misma.</p></li>
                </ol>
              </li>

              
              <li>
                <h5><strong>Tratamiento al cual serán sometidos los Datos Personales</strong></h5>
                <p>
                  Los Datos Personales de clientes son recolectados, almacenados, organizados, usados, circulados, transmitidos, transferidos, actualizados, rectificados, suprimidos, eliminados y gestionados de acuerdo con la naturaleza de dichos datos y conforme con las finalidades establecidas en esta Política.
                </p>
                <h6><strong>4.1. Finalidades del Tratamiento de Datos por parte de Nibi</strong></h6>
                <p>
                  Las finalidades del Tratamiento de Datos Personales realizado por Nibi, son las siguientes:
                </p>

                <h6><strong>4.1.1. Finalidades para el Tratamiento de Datos Personales de los Titulares</strong></h6>
                <p>
                  Teniendo en consideración que Nibi cuenta con 4 tipos de usuarios, a continuación se incluye las finalidades para el tratamiento de datos personales de cada uno de ellos.
                </p>
                <h6><strong>Personas naturales</strong></h6>
                <ul type="disc">
                  <li>Enviar información al donante y/o voluntario(a) para informarle sobre los avances y/o resultados que las organizaciones sin ánimo de lucro han logrado a través de las campañas que suben a la plataforma de Nibi.</li>
                  <li>Enviar información al donante, voluntario(a) y/o cliente para informarle sobre las nuevas campañas y/o productos sostenibles que las organizaciones sin ánimo de lucro y los comercios sostenibles suben a la plataforma de Nibi.</li>
                  <li>Promocionar las diferentes campañas de donaciones, voluntariados y ventas de las organizaciones sin ánimo de lucro, los comercios sostenibles y/o Nibi.</li>
                  <li>Enviar información acerca de la plataforma de Nibi y sus funcionalidades, incluyendo información sobre próximos cambios o mejoras.</li>
                  <li>Cumplir lo expedido en la Ley 720 de 2001, el Decreto 4290 de 2005 y las demás normas que las complementen o modifiquen, en donde se establece la información mínima con la que debe contar un sistema de registro de voluntarios.</li>
                  <li>Categorizar a los donantes, voluntarios(as) y/o clientes con base en sus gustos e intereses sociales y de consumo responsable para así ofrecer campañas y productos más de acorde a sus preferencias.</li>
                  <li>Consultar la identidad de la persona natural en diferentes fuentes de información públicas como lo son las de la Procuraduría General de la Nación, entre otras, para determinar posibles casos de robo, suplantación o duplicidad de identidad o prácticas ilegales como el lavado de activos, financiación del terrorismo, corrupción o sobornos.</li>
                  <li>Enviar productos de los comercios sostenibles de la plataforma de Nibi al lugar de residencia o dirección estipulada por el cliente.</li>
                  <li>Realizar las transacciones bancarias para las campañas de las organizaciones sin ánimo de lucro o de los productos sostenibles de los comercios sostenibles.</li>
                  <li>Estudiar, de manera agregada, las tendencias y el comportamiento de las personas naturales en temas de impacto social y/o ambiental y de consumo responsable.</li>
                  <li>Utilizar los datos recogidos a través de la plataforma para mejorar la experiencia de los usuarios y la calidad general de la plataforma y sus funcionalidades.</li>
                  <li>Evaluar la calidad del servicio y gestionar trámites como peticiones, quejas, reclamos, preguntas y solicitudes.</li>
                  <li>Suministrar información de contacto a la fuerza comercial y/o red de distribución, telemercadeo, investigación de mercados y cualquier tercero con el cual Nibi tenga un vínculo contractual para el desarrollo de actividades de ese tipo (investigación de mercados y telemercadeo, etc) para la ejecución de las mismas.</li>
                  <li>Contactar al Titular a través de medios electrónicos (SMS, chat, entre otros) para el envío de noticias relacionadas con campañas de fidelización o mejora de servicio.</li>
                  <li>Suministrar la información a terceros con los cuales Nibi tenga relación contractual y que sea necesario entregársela para el cumplimiento del objeto contratado.</li>
                </ul>
                <br />

                <h6><strong>Organizaciones sin ánimo de lucro</strong></h6>
                <ul type="disc">
                  <li>Compartir la información con el/la representante de la organización para que tenga conocimiento sobre las donaciones y/o voluntarios(as) que se gestionen a través de la plataforma de Nibi.</li>
                  <li>Contactar a el/la representante de la organización sin ánimo de lucro para realizar el proceso de validación de la organización y que, en caso de quedar validada, pueda hacer uso de las funcionalidades de la plataforma de Nibi.</li>
                  <li>Contactar a el/la representante de la organización sin ánimo de lucro en caso de requerir algunas aclaraciones, correcciones o demás modificaciones que se sobre el perfil y/o las campañas que la organización sube a la plataforma de Nibi.</li>
                  <li>Gestionar y hacer seguimiento de las donaciones e inscripciones de voluntarios(as) que se hacen a través de la plataforma de Nibi.</li>
                  <li>Socializar y promocionar las diferentes funcionalidades con las que cuenta Nibi.</li>
                  <li>Enviar información acerca de la plataforma de Nibi y sus funcionalidades, incluyendo información sobre próximos cambios o mejoras.</li>
                  <li>Promocionar las diferentes campañas, eventos y beneficios realizados por Nibi.</li>
                  <li>Categorizar a organizaciones sin ánimo de lucro para segmentar mejor la oferta de sus campañas y aumentar sus donaciones y/o voluntarios.</li>
                  <li>Consultar la información bancaria de las organizaciones sin ánimo de lucro o de sus responsables para validar su información y hacer las consignaciones solicitadas.</li>
                  <li>Consultar la información legal de organizaciones sin ánimo de lucro para garantizar que la información suministrada es veraz y válida y que ni la organización ni sus representantes se encuentren en ningún procedimiento legal que afecte la reputación de Nibi.</li>
                  <li>Consultar la información legal de los representantes de las organizaciones sin ánimo de lucro para determinar posibles casos de robo, suplantación o duplicidad de identidad o prácticas ilegales como el lavado de activos, financiación del terrorismo, corrupción o sobornos.</li>
                  <li>Permitir que las personas u organizaciones que entren a ver el perfil de las organizaciones sin ánimo de lucro en Nibi puedan conocer más sobre ella y puedan entrar en contacto a través de otros canales de comunicación.</li>
                  <li>Promocionar las campañas, productos y/o servicios que las organizaciones sin ánimo de lucro comercializan a través de la plataforma de Nibi.</li>
                  <li>Permitir que los diferentes operadores logísticos de envíos aliados de Nibi conozcan el lugar donde deben ir a recoger los productos de las organizaciones sin ánimo de lucro para posteriormente entregarlo al destinatario final.</li>
                  <li>Visibilizar la imagen, la marca, las campañas y/o los productos de la organización sin ánimo de lucro con fines promocionales y de posicionamiento de la página web a través de diferentes eventos, campañas y medios de comunicación de Nibi.</li>
                  <li>Utilizar los datos recogidos a través de la plataforma para mejorar la experiencia de los usuarios y la calidad general de la plataforma y sus funcionalidades.</li>
                  <li>Evaluar la calidad del servicio y gestionar trámites como peticiones, quejas, reclamos, preguntas y solicitudes.</li>
                  <li>Suministrar información de contacto a la fuerza comercial y/o red de distribución, telemercadeo, investigación de mercados y cualquier tercero con el cual Nibi tenga un vínculo contractual para el desarrollo de actividades de ese tipo (investigación de mercados y telemercadeo, etc) para la ejecución de las mismas.</li>
                  <li>Contactar al Titular a través de medios electrónicos (SMS, chat, entre otros) para el envío de noticias relacionadas con campañas de fidelización o mejora de servicio.</li>
                  <li>Contactar al Titular a través de correo electrónico para el envío de extractos, estados de cuenta o facturas en relación con las obligaciones derivadas del uso de la plataforma.</li>
                  <li>Suministrar la información a terceros con los cuales Nibi tenga relación contractual y que sea necesario entregársela para el cumplimiento del objeto contratado.</li>
                </ul>
                <br />

                <h6><strong>Comercios sostenibles</strong></h6>
                <ul type="disc">
                  <li>Compartir la información con el/la representante del comercio sostenible para que tenga conocimiento sobre las ventas que realice a través de la plataforma de Nibi.</li>
                  <li>Contactar a el/la representante del comercio sostenible para realizar el proceso de validación de la organización y que, en caso de quedar validada, pueda hacer uso de las funcionalidades de la plataforma de Nibi.</li>
                  <li>Contactar a el/la representante del comercio responsable en caso de requerir algunas aclaraciones, correcciones o demás modificaciones que se sobre el perfil y/o los productos que el comercio sostenible sube a la plataforma de Nibi.</li>
                  <li>Gestionar y hacer el seguimiento de las ventas que se han realizado a través de la plataforma de Nibi.</li>
                  <li>Socializar y promocionar las diferentes funcionalidades con las que cuenta Nibi.</li>
                  <li>Promocionar las diferentes campañas, eventos y beneficios realizados por Nibi.</li>
                  <li>Enviar información acerca de la plataforma de Nibi y sus funcionalidades, incluyendo información sobre próximos cambios o mejoras.</li>
                  <li>Categorizar a los comercios sostenibles para segmentar mejor la oferta de sus productos y aumentar sus ventas.</li>
                  <li>Consultar la información bancaria de los comercios sostenibles o de sus responsables para validar su información y hacer las consignaciones solicitadas.</li>
                  <li>Consultar la información legal de los comercios sostenibles para garantizar que la información suministrada es veraz y válida y que ni el comercio ni sus representantes se encuentren en ningún procedimiento legal que afecte la reputación de Nibi.</li>
                  <li>Consultar la información legal de los representantes de los comercios sostenibles para determinar posibles casos de robo, suplantación o duplicidad de identidad o prácticas ilegales como el lavado de activos, financiación del terrorismo, corrupción o sobornos.</li>
                  <li>Permitir que las personas u organizaciones que entren a ver el perfil del comercio sostenible en Nibi puedan conocer más sobre él y puedan entrar en contacto a través de otros canales de comunicación.</li>
                  <li>Promocionar los productos y/o servicios que los comercios sostenibles comercializan a través de la plataforma de Nibi.</li>
                  <li>Compartir los descuentos que los comercios sostenibles ofrecen a la comunidad de usuarios de Nibi.</li>
                  <li>Permitir que los diferentes operadores logísticos de envíos aliados de Nibi conozcan el lugar donde deben ir a recoger los productos de los comercios sostenibles para posteriormente entregarlo al destinatario final.</li>
                  <li>Visibilizar la imagen, la marca y los productos del comercio sostenible con fines promocionales y de posicionamiento de la página web a través de diferentes eventos, campañas y medios de comunicación de Nibi</li>
                  <li>Utilizar los datos recogidos a través de la plataforma para mejorar la experiencia de los usuarios y la calidad general de la plataforma y sus funcionalidades.</li>
                  <li>Evaluar la calidad del servicio y gestionar trámites como peticiones, quejas, reclamos, preguntas y solicitudes.</li>
                  <li>Suministrar información de contacto a la fuerza comercial y/o red de distribución, telemercadeo, investigación de mercados y cualquier tercero con el cual Nibi tenga un vínculo contractual para el desarrollo de actividades de ese tipo (investigación de mercados y telemercadeo, etc) para la ejecución de las mismas.</li>
                  <li>Contactar al Titular a través de medios electrónicos (SMS, chat, entre otros) para el envío de noticias relacionadas con campañas de fidelización o mejora de servicio.</li>
                  <li>Contactar al Titular a través de correo electrónico para el envío de extractos, estados de cuenta o facturas en relación con las obligaciones derivadas del uso de la plataforma.</li>
                  <li>Suministrar la información a terceros con los cuales Nibi tenga relación contractual y que sea necesario entregársela para el cumplimiento del objeto contratado.</li>
                </ul>
                <br />

                <h6><strong>Empresas</strong></h6>
                <ul type="disc">
                  <li>Enviar información a la empresa y a los empleados que usen la plataforma para hacer donaciones y/o voluntariado corporativo para informarle sobre los avances y/o resultados que las organizaciones sin ánimo de lucro han logrado a través de las campañas que suben a la plataforma de Nibi.</li>
                  <li>Enviar información a la empresa y a los empleados que usen la plataforma para hacer donaciones, voluntariado corporativo y/o comprar productos para informarle sobre las nuevas campañas y/o productos sostenibles que las organizaciones sin ánimo de lucro y los comercios sostenibles suben a la plataforma de Nibi.</li>
                  <li>Promocionar las diferentes campañas de donaciones, voluntariados corporativos y ventas de las organizaciones sin ánimo de lucro, los comercios sostenibles y/o Nibi.</li>
                  <li>Enviar información acerca de la plataforma de Nibi y sus funcionalidades, incluyendo información sobre próximos cambios o mejoras.</li>
                  <li>Cumplir lo expedido en la Ley 720 de 2001, el Decreto 4290 de 2005 y las demás normas que las complementen o modifiquen, en donde se establece la información mínima con la que debe contar un sistema de registro de voluntarios corporativos.</li>
                  <li>Categorizar a la empresa y a los empleados que usen la plataforma para hacer donaciones, voluntariado corporativo y/o clientes con base en sus gustos e intereses sociales y de consumo responsable para así ofrecer campañas y productos más de acorde a sus preferencias.</li>
                  <li>Consultar la identidad de la persona jurídica de las empresas en diferentes fuentes de información públicas como lo son las Cámaras de Comercio, el Registro Único de Empresas Sociales (RUES), entre otras, para determinar posibles casos de robo, suplantación o duplicidad de identidad o prácticas ilegales como el lavado de activos, financiación del terrorismo, corrupción o sobornos.</li>
                  <li>Enviar productos de los comercios sostenibles de la plataforma de Nibi a la(s) sede(s) de la empresa o dirección estipulada por el cliente.</li>
                  <li>Realizar las transacciones bancarias para las campañas de las organizaciones sin ánimo de lucro o de los productos sostenibles de los comercios sostenibles.</li>
                  <li>Estudiar, de manera agregada, las tendencias y el comportamiento de las personas jurídicas en temas de impacto social y/o ambiental y de consumo responsable.</li>
                  <li>Utilizar los datos recogidos a través de la plataforma para mejorar la experiencia de los usuarios y la calidad general de la plataforma y sus funcionalidades.</li>
                  <li>Evaluar la calidad del servicio y gestionar trámites como peticiones, quejas, reclamos, preguntas y solicitudes.</li>
                  <li>Suministrar información de contacto a la fuerza comercial y/o red de distribución, telemercadeo, investigación de mercados y cualquier tercero con el cual Nibi tenga un vínculo contractual para el desarrollo de actividades de ese tipo (investigación de mercados y telemercadeo, etc) para la ejecución de las mismas.</li>
                  <li>Contactar al Titular a través de medios electrónicos (SMS, chat, entre otros) para el envío de noticias relacionadas con campañas de fidelización o mejora de servicio.</li>
                  <li>Contactar al Titular a través de correo electrónico para el envío de extractos, estados de cuenta o facturas en relación con las obligaciones derivadas del uso de la plataforma.</li>
                  <li>Suministrar la información a terceros con los cuales Nibi tenga relación contractual y que sea necesario entregársela para el cumplimiento del objeto contratado.</li>
                </ul>
                <br />

                <h6><strong>4.2. Tratamiento de Datos Sensibles</strong></h6>
                <p>
                  Por “Datos Sensibles” se deberá entender toda información que pueda afectar la intimidad del Titular o cuyo uso pueda generar su discriminación, tales como aquellos que revelen el origen racial o étnico, la orientación política, las convicciones religiosas o filosóficas, la pertenencia a sindicatos, organizaciones sociales de derechos humanos o que promuevan intereses de cualquier partido político o que garanticen los derechos y garantías de partidos políticos de oposición así como los datos relativos a la salud, la vida sexual y los datos biométricos.
                </p>
                <p>
                  En el evento en que Nibi realice el Tratamiento de Datos Sensibles de clientes o usuarios de la página web, la Compañía observará estrictamente las limitaciones y obligaciones establecidas por la Ley 1581 de 2012, sus decretos reglamentarios y demás normas concordantes. Por lo tanto, en caso de realizar Tratamiento de Datos Sensibles, Nibi se asegurará de:
                </p>
                <ol type="a">
                  <li>Obtener consentimiento expreso del Titular.</li>
                  <li>Informar al Titular que por tratarse de Datos Sensibles no está obligado a autorizar su Tratamiento.</li>
                  <li>Informar al Titular de forma explícita y previa, cuáles de los datos que serán objeto de Tratamiento son sensibles y la finalidad del Tratamiento.</li>
                </ol>
                <br />

                <h6><strong>4.3. Tratamiento de Datos Personales de Niñas, Niños y Adolescentes</strong></h6>
                <p>
                  En el evento en que Nibi realice el Tratamiento de Datos Personales de niños, niñas y/o adolescentes de clientes o usuarios de la página web, la Compañía observará estrictamente las limitaciones y obligaciones establecidas en la Ley 1581 de 2012, sus decretos reglamentarios y demás normas concordantes. Por lo tanto, en caso de realizar Tratamiento de Datos Personales de niños, niñas y/o adolescentes, Nibi se asegurará de lo siguiente:
                </p>
                <ol type="a">
                  <li>Que el Tratamiento responda y respete el interés superior de los niños, niñas y adolescentes.</li>
                  <li>Que en el Tratamiento se asegure el respeto de los derechos fundamentales de los niños, niñas y adolescentes.</li>
                  <li>Valorar la opinión del menor cuando este cuente con la madurez, autonomía y capacidad para entender el asunto.</li>
                </ol>
                <br />

                <h6><strong>4.4. Video Vigilancia – CCTV</strong></h6>
                <p>
                  Nibi podrá utilizar diversos medios de video vigilancia ubicados en diferentes lugares de sus instalaciones u oficinas.
                </p>
                <p>
                  La información recolectada se utilizará para fines de seguridad de las personas, los bienes e instalaciones. Esta información podrá ser empleada como prueba en cualquier tipo de proceso ante cualquier tipo de autoridad u organización. Nibi implementará los respectivos avisos de video vigilancia, así como las respectivas autorizaciones para obtener esta información, de conformidad con la regulación colombiana de protección de Datos Personales.
                </p>

                <h6><strong>4.5. Transferencia y Transmisión de Datos Personales</strong></h6>
                <h6><strong>4.5.2. Transmisión de Datos Personales</strong></h6>

                <p>
                  Nibi realiza la Transmisión de Datos Personales de los Titulares a terceros ubicados en Colombia, en calidad de Encargados, para realizar el Tratamiento de los Datos Personales por cuenta de Nibi con el fin de desarrollar las finalidades señaladas anteriormente en el numeral 4.1.1. Adicionalmente, Nibi podrá realizar la Transmisión de Datos Personales de los Titulares a Encargados que se encuentren ubicados fuera de Colombia para las finalidades señaladas anteriormente en el numeral 4.1.1.
                </p>
                <p>
                  Para tales efectos, Nibi implementará las Autorizaciones o contratos de Transmisión de Datos que sean necesarias para dar cumplimiento a las obligaciones previstas en el régimen colombiano de protección de Datos Personales.
                </p>

                <h6><strong>4.5.3. Transferencia de Datos Personales</strong></h6>
                <p>
                  Nibi podrá realizar la Transferencias de Datos Personales de los Titulares a terceros con los cuales se tenga una relación contractual, con el fin de desarrollar las finalidades señaladas anteriormente en el numeral 4.1.1. Adicionalmente, Nibi podrá realizar la Transferencia de Datos Personales de los Titulares a Responsables que se encuentren ubicados dentro o fuera de Colombia para las finalidades señaladas anteriormente en el numeral 4.1.1.
                </p>
                <p>
                  Para tales efectos, Nibi implementará las Autorizaciones y/o contratos de Transferencia de Datos que sean necesarias para dar cumplimiento a las obligaciones previstas en el régimen colombiano de protección de Datos Personales.
                </p>
              </li>

              <li>
                <h5><strong>Derechos de los Titulares</strong></h5>

                <p>
                  De conformidad con lo establecido en el artículo 8 de la Ley 1581 de 2012 y el Decreto 1074 de 2015 (Capítulo 25), el Titular de los Datos Personales tiene los siguientes derechos:
                </p>

                <ol type="a">
                  <li>Conocer, actualizar y rectificar sus Datos Personales frente a Nibi, en su calidad de Responsable del Tratamiento. Este derecho podrá ejercerlo frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o aquellos cuyo Tratamiento esté expresamente prohibido o no haya sido autorizado.</li>
                  <li>Solicitar prueba de la autorización otorgada a Nibi.</li>
                  <li>Ser informado por Nibi, previa solicitud, respecto del uso que se le ha dado a sus Datos Personales.</li>
                  <li>Presentar ante la Superintendencia de Industria y Comercio (en adelante “SIC”), quejas por infracciones a lo dispuesto en la Ley 1581 de 2012, una vez se haya agotado el trámite de consulta o reclamo ante Nibi, conforme con lo dispuesto en la presente Política.</li>
                  <li>Revocar la autorización y/o solicitar la supresión del dato cuando en el Tratamiento no se respeten los principios, derechos y garantías constitucionales y legales. La revocatoria y/o supresión procederá cuando la SIC haya determinado que en el Tratamiento, el Responsable o Encargado han incurrido en conductas contrarias a la ley y la Constitución.</li>
                  <li>Acceder en forma gratuita a los Datos Personales que hayan sido objeto de Tratamiento por Nibi.</li>
                </ol>
                <br />

                <p>
                  Estos derechos podrán ser ejercidos únicamente por las siguientes personas:
                </p>

                <ol type="a">
                  <li>Por el Titular, quien deberá acreditar su identidad en forma suficiente.</li>
                  <li>Por sus causahabientes, quienes deberán acreditar tal calidad.</li>
                  <li>Por el representante y/o apoderado del Titular, previa acreditación de la representación o apoderamiento.</li>
                  <li>Por estipulación a favor de otro o para otro.</li>
                </ol>
                <br />
              </li>

              <li>
                <h5><strong>Área Responsable de la Atención de Peticiones, Consultas y Reclamos</strong></h5>
                <p>
                  El Oficial de Datos de Nibi será responsable de la atención de peticiones, consultas, reclamos, quejas o para el ejercicio de los derechos del Titular de los Datos Personales objeto de Tratamiento. Este podrá ser contactado mediante el correo electrónico jairo@nibi.com.co o nacho@nibi.com.co 
                </p>
              </li>

              <li>
                <h5><strong>Procedimiento para ejercer los derechos y las consultas y reclamos del Titular de los Datos Personales</strong></h5>

                <h6><strong>7.6. Procedimiento el para acceso y consulta de los Datos Personales</strong></h6>
                <p>
                  El Titular de los Datos Personales, o cualquiera de las personas autorizadas conforme con lo establecido en el capítulo 5 de la presente Política, podrá consultar la información que repose en las bases de datos de Nibi, para lo cual deberán comunicar la correspondiente petición al correo electrónico jairo@nibi.com.co o nacho@nibi.com.co o radicando una comunicación por escrito de lunes a viernes en horario de 8am a 5pm, en la Calle 92 # 16 – 11 a nombre de Jairo Pitta.
                </p>
                <p>
                  Para evitar que terceros no autorizados accedan a la información personal del Titular, será necesario previamente establecer la identificación del Titular. Cuando la solicitud sea formulada por persona distinta del Titular y no se acredite que la misma actúa en representación de aquél, se tendrá por no presentada.
                </p>
                <p>
                  La consulta será atendida en un término máximo de diez (10) días hábiles contados a partir de la fecha de recibo. Cuando no fuere posible atender la consulta dentro de dicho término, se informará al interesado, expresando los motivos de la demora y señalando la fecha en que se atenderá su consulta, la cual en ningún caso podrá superar los cinco (5) días hábiles siguientes al vencimiento del primer término.
                </p>

                <h6><strong>7.7. Procedimiento para solicitar actualización, corrección, supresión, revocatoria de la autorización o para presentar reclamos</strong></h6>
                <p>
                  El Titular, o cualquiera de las personas autorizadas conforme con lo establecido en el capítulo 5, que considere que la información contenida en las bases de datos de Nibi debe ser objeto de corrección, actualización o supresión, o cuando se advierta del presunto incumplimiento de cualquiera de los deberes contenidos en la Ley 1581 de 2012, el Decreto 1074 de 2015 o demás normas complementarias, podrán presentan un reclamo ante Nibi, el cual será tramitado conforme con las siguientes reglas:
                </p>
                <ol type="a">
                  <li>El reclamo se formulará mediante solicitud que puede ser enviada al correo jairo@nibi.com.co o nacho@nibi.com.co  o radicando una comunicación por escrito de lunes a viernes en horario de 8am a 5pm, en la Calle 92 # 16 – 11 a nombre de Jairo Pitta.</li>
                  <li>Para evitar que terceros no autorizados accedan a la información personal del Titular, será necesario previamente establecer la identificación del Titular. Cuando la solicitud sea formulada por persona distinta del Titular y no se acredite que la misma actúa en representación de aquél, se tendrá por no presentada.</li>
                  <li>
                    La solicitud debe contener la siguiente información:
                    <ol type="i">
                      <li>La identificación del Titular.</li>
                      <li>Los datos de contacto (dirección física y/o electrónica y teléfonos de contacto).</li>
                      <li>Los documentos que acrediten la identidad del Titular, o la representación de su representante.</li>
                      <li>La descripción clara y precisa de los Datos Personales respecto de los cuales el Titular busca ejercer alguno de los derechos.</li>
                      <li>La descripción de los hechos que dan lugar al reclamo.</li>
                      <li>Los documentos que se quiera hacer valer.</li>
                      <li>Firma y número de identificación.</li>
                      <li>Radicación en original.</li>
                    </ol>
                  </li>
                  <li>Si el reclamo resulta incompleto, Nibi requerirá al interesado dentro de los cinco (5) días siguientes a la recepción del reclamo para que subsane las fallas. Transcurridos dos (2) meses desde la fecha del requerimiento, sin que el solicitante presente la información requerida, se entenderá que ha desistido del reclamo.</li>
                  <li>Si el área que recibe el reclamo no es competente para resolverlo, dará traslado a quien corresponda en un término máximo de dos (2) días hábiles e informará de la situación al interesado.</li>
                  <li>Una vez recibido el reclamo completo, se incluirá en la base de datos una leyenda que diga “reclamo en trámite” y el motivo del mismo, en un término no mayor a dos (2) días hábiles. Dicha leyenda deberá mantenerse hasta que el reclamo sea decidido.</li>
                  <li>El término máximo para atender el reclamo será de quince (15) días hábiles contados a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atender el reclamo dentro de dicho término, se informará al interesado los motivos de la demora y la fecha en que se atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término.</li>
                </ol>
                <br />

                <h6><strong>7.8. Supresión de Datos</strong></h6>
                <p>
                  El Titular tiene el derecho, en todo momento, a solicitar a Nibi la supresión (eliminación) de sus Datos Personales cuando:
                </p>
                <ol type="a">
                  <li>Considere que los mismos no están siendo Tratados conforme con los principios, deberes y obligaciones previstas en la Ley 1581 de 2012, el Decreto 1074 de 2015 y las demás normas que las complementen o modifiquen.</li>
                  <li>Hayan dejado de ser necesarios o pertinentes para la finalidad para la cual fueron recolectados.</li>
                  <li>Se haya superado el periodo necesario para el cumplimiento de los fines para los que fueron recolectados.</li>
                </ol>
                <br />
                <p>
                  Esta supresión implica la eliminación total o parcial de los Datos Personales de acuerdo con lo solicitado por el Titular en los registros, archivos, bases de datos o Tratamientos realizados por Nibi.
                </p>
                <p>
                  El derecho de supresión no es absoluto y el Responsable puede negar el ejercicio del mismo cuando:
                </p>
                <ol type="a">
                  <li>El Titular tenga un deber legal o contractual de permanecer en la base de datos de Nibi.</li>
                  <li>La supresión de los Datos Personales obstaculice actuaciones judiciales o administrativas vinculadas a obligaciones fiscales, la investigación y persecución de delitos o la actualización de sanciones administrativas.</li>
                  <li>Los Datos Personales sean necesarios para proteger los intereses jurídicamente tutelados del Titular, para realizar una acción en función del interés público, o para cumplir con una obligación legalmente adquirida por el Titular.</li>
                </ol>
                <br />

                <h6><strong>7.9. Revocatoria de la Autorización.</strong></h6>
                <p>
                  El Titular de los Datos Personales puede revocar el consentimiento al Tratamiento de sus Datos Personales en cualquier momento, siempre y cuando no lo impida una disposición legal.
                </p>
              </li>
              
              <li>
                <h5><strong>Seguridad de la Información.</strong></h5>
                <p>
                  En desarrollo del principio de seguridad, Nibi ha adoptado medidas técnicas, administrativas y humanas razonables para proteger la información de los Titulares e impedir adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento. El acceso a los Datos Personales está restringido a sus Titulares y Nibi no permitirá el acceso a esta información por parte de terceros en condiciones diferentes a las enunciadas en la presente Política, a excepción de un pedido expreso del Titular o personas legitimadas de conformidad con la normativa nacional.
                </p>
              </li>

              <li>
                <h5><strong>Término de Conservación de los Datos Personales</strong></h5>
                <p>
                  Los Datos Personales recolectados por Nibi se conservarán por un periodo de tiempo necesario y proporcional con los fines del Tratamiento señalados en esta Política. El término de las Autorizaciones sobre el uso de los datos personales se entiende por el término de la relación contractual y durante el ejercicio del objeto social de Nibi, salvo en aquellos casos en los que la ley disponga un término distinto.
                </p>
                <p>
                  Adicionalmente, Nibi procederá a suprimir los Datos Personales cuando así lo requieran los Titulares, de acuerdo con lo establecido por la Ley 1581 de 2012 y sus decretos reglamentarios, así como con lo establecido en esta Política.
                </p>
              </li>

              <li>
                <h5><strong>Vigencia de la Política.</strong></h5>
                <p>
                  La presente Política rige a partir del 28 de mayo de 2020.
                </p>
              </li>

              <p>
                <strong>Última fecha de actualización: </strong>10 de septiembre de 2020
              </p>
              <p>
                <strong>Versión: </strong>2
              </p>

            </ol>
          </Col>
        </Row>
      </Container>
    </div>
  );

}

export default PrivacyPolicy;
