import React, { Component } from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import { epaycoBaseUrl, epaycoIsTest, epaycoPrivateKey, epaycoPublicKey, wompiPublicKey, redirectBaseUrl } from '../utils/paymentKeys';
import AppSpinner from '../Extras/AppSpinner';
import { connect } from 'react-redux';
import AddressInfoIcon from '../Extras/AddressInfoIcon';
import { NIBI_URL } from "../utils/baseUrl";
import './styles/shoppingCart.scss';

const select = state => {
  return {
    user: state.auth.user,
    token: state.auth.token,
    campaign: state.campaign.campaign
  };
};

class ConnectedCartPsePaymentComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      acceptanceToken: '',
      permalink: '',
      email: props.user.email,
      financialInstitutions: [],
      docType: 'CC',
      userDocument: props.user && props.user.identification ? props.user.identification : '',
      cellPhone: props.user && props.user.cellPhone ? props.user.cellPhone : '',
      contactMethod: 'EMAIL',
      userType: '0',
      institutionSelected: '0',
      isLoading: true,
      formValidated: false,
      creatingTransaction: false,
      deliveryInfo: this.props.deliveryInfo,
      userCity: props.user && props.user.donationCity ? props.user.donationCity : '',
      userAddress: props.user && props.user.donationAddress ? props.user.donationAddress : ''
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.createPsePayment = this.createPsePayment.bind(this);
    this.createTransaction = this.createTransaction.bind(this);
    //this.checkTransaction = this.checkTransaction.bind(this);
  }

  handleInputChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async createPsePayment() {

    const {
      docType,
      userDocument,
      deliveryInfo,
      cellPhone,
      contactMethod,
      userCity,
      userAddress
    } = this.state;
    const {
      token,
      user,
      items,
      products,
      totalAmount,
      campaign
    } = this.props;
    const showContactMethod = this.props.user && !this.props.user.contactMethod;

    const body = {
      userId: user._id,
      items,
      products,
      totalAmount,
      paymentMethod: 'PSE',
      userIdentification: userDocument,
      userIdentificationType: docType,
      userCity,
      userAddress,
      cellPhone,
      contactMethod: showContactMethod ? contactMethod : '',
      campaign
    };

    if (products.length > 0 && deliveryInfo) {
      body.deliveryInfo =
        deliveryInfo.street + " " +
        (deliveryInfo.extra ? deliveryInfo.extra : '') + " " +
        (deliveryInfo.area ? deliveryInfo.area : '') + ", " +
        deliveryInfo.city + ", " +
        deliveryInfo.state + ", " +
        deliveryInfo.country + ", " +
        deliveryInfo.phone;
    }

    const paymentResponse = await fetch('/shoppingCartItems/createCartPayment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(body)
    });

    const paymentData = await paymentResponse.json();
    const { reference } = paymentData;

    return reference;
  }

  async getTokenApify() {
    const tokenApifyResponse = await fetch(`${epaycoBaseUrl}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Basic " +
          new Buffer.from(epaycoPublicKey + ":" + epaycoPrivateKey).toString(
            "base64"
          ),
      },
    });

    const tokenApifyData = await tokenApifyResponse.json();
    return tokenApifyData.token;
  }

  async createTransaction() {
    const {
      institutionSelected,
      acceptanceToken,
      userDocument,
      userType,
      docType,
      email,
      cellPhone,
    } = this.state;

    const { totalAmount } = this.props;

    const reference = await this.createPsePayment();
    const ipResponse = await fetch("/payments/ip_address", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const ipData = await ipResponse.json();
    const ip = ipData.ip;
    const token_apify = await this.getTokenApify();
    const body = JSON.stringify({
      bank: institutionSelected,
      value: `${totalAmount}`,
      docType,
      typePerson: `${docType === "NIT" ? 1 : 0}`,
      docNumber: userDocument,
      name: this.props.user.firstName + " " + this.props.user.lastName,
      email,
      cellPhone: cellPhone.slice(-10),
      urlResponse: `${redirectBaseUrl}/paymentResult/${reference}`,
      ip,
      currency: "COP",
      urlConfirmation: `${NIBI_URL}payments/transaction/events/epayco`,
      methodConfirmation: "GET",
      extra1: reference,
      extra2: `Compra de carrito de compras`,
    });

    const transactionResponse = await fetch(
      `${epaycoBaseUrl}/payment/process/pse`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token_apify}`,
        },
        body,
      }
    );

    const transactionData = await transactionResponse.json();
    if (!transactionData.success) {
      //Enviar log a BD
      await fetch("/nibiLogs/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          content: transactionData,
          userId: this.props.user._id,
          context: "Error en transacción por PSE en carrito de compras",
        }),
      });
      return;
    }
    window.open(transactionData.data.urlbanco, "_self");

    const transactionId = transactionData.data.id;
    return transactionId;
  }

  /* async checkTransaction(transactionId) {

    const transactionResponse = await fetch(`${wompiBaseUrl}/transactions/${transactionId}`);
    const transaction = await transactionResponse.json();
    const { data } = transaction;

    if (data && data.payment_method && data.payment_method.extra && data.payment_method.extra.async_payment_url) {

      clearInterval(this.pollingTransaction);

      const paymentUrl = data.payment_method.extra.async_payment_url;
      if (window.location.href !== paymentUrl) window.location.href = paymentUrl;
    }

  } */

  async handleSubmit(event) {

    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const { institutionSelected } = this.state;

    this.setState({ formValidated: true });

    if (form.checkValidity() && institutionSelected !== '0') {

      this.setState({ creatingTransaction: true });
      const transactionId = await this.createTransaction();

      /* this.pollingTransaction = setInterval(
        async () => {
          this.checkTransaction(transactionId);
        },
        2000
      ); */

    }

  }

  async componentDidMount() {
    const token_apify = await this.getTokenApify();
    const financialInstitutionsFetch = fetch(
      `${epaycoBaseUrl}/payment/pse/banks`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token_apify}`,
        },
      }
    );
    const financialInstitutionsResponse = await financialInstitutionsFetch;
    const financialInstitutionsData =
      await financialInstitutionsResponse.json();
    this.setState({
      financialInstitutions: financialInstitutionsData.data,
    });

    this.setState({ isLoading: false });
  }

  render() {

    const {
      isLoading,
      creatingTransaction,
      permalink,
      financialInstitutions,
      formValidated,
      institutionSelected,
      userType,
      docType,
      userDocument,
      email,
      cellPhone,
      contactMethod,
      userAddress,
      userCity
    } = this.state;
    const showContactMethod = this.props.user && !this.props.user.contactMethod;

    if (isLoading) return <AppSpinner />;

    if (creatingTransaction) {
      return (
        <div className="pse-transaction-spinner">
          <AppSpinner />
          <h4 className="message">Por favor espera mientras procesamos la transacción</h4>
        </div>
      );
    }

    return (
      <div className="pse-payment-component">
        <Form noValidate validated={formValidated} onSubmit={this.handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} lg={4}>
              <Form.Label>Número de teléfono</Form.Label>
              <Form.Control
                name="cellPhone"
                type="text"
                placeholder="Teléfono"
                value={cellPhone}
                onChange={this.handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group as={Col} lg={4}>
              <Form.Label>Institución financiera</Form.Label>
              <Form.Control as="select" className={institutionSelected === '0' ? 'invalid-value' : ''} name="institutionSelected" value={institutionSelected} onChange={this.handleInputChange}>
                {
                  financialInstitutions.map(institution =>
                    <option
                      key={institution.bankCode}
                      value={institution.bankCode}>
                      {institution.bankName}
                    </option>
                  )
                }
              </Form.Control>
              {
                formValidated && institutionSelected === '0' &&
                <div className="text-invalid">
                  Selecciona tu banco.
                </div>
              }
            </Form.Group>
            <Form.Group as={Col} lg={4}>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={email}
                name="email"
                onChange={this.handleInputChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Ingresa un email válido.
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} lg={3} xs={6}>
              <Form.Label>Tipo de persona</Form.Label>
              <Form.Control name="userType" as="select" value={userType} onChange={this.handleInputChange}>
                <option value="0">Natural</option>
                <option value="1">Jurídica</option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} lg={3} xs={6}>
              <Form.Label>Tipo de documento</Form.Label>
              <Form.Control name="docType" as="select" value={docType} onChange={this.handleInputChange}>
                <option value="CC">CC</option>
                <option value="NIT">NIT</option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} lg={6}>
              <Form.Label>Número de documento</Form.Label>
              <Form.Control
                name="userDocument"
                type="text"
                placeholder="Documento"
                value={userDocument}
                onChange={this.handleInputChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Ingresa tu documento.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} lg={6} xs={12}>
              <Form.Label>
                Ciudad <AddressInfoIcon />
              </Form.Label>
              <Form.Control
                name="userCity"
                type="text"
                placeholder="Ciudad"
                value={userCity}
                onChange={this.handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} xs={12}>
              <Form.Label>
                Dirección <AddressInfoIcon />
              </Form.Label>
              <Form.Control
                name="userAddress"
                type="text"
                placeholder="Dirección"
                value={userAddress}
                onChange={this.handleInputChange}
                required
              />
            </Form.Group>

            {
              showContactMethod &&
              <Form.Group as={Col} lg={6}>
                <Form.Label>¿Por dónde te gustaría recibir la información de tu impacto?</Form.Label>
                <Form.Control
                  as="select"
                  name="contactMethod"
                  value={contactMethod}
                  onChange={this.handleInputChange}
                >
                  <option value="WHATS_APP">WhatsApp</option>
                  <option value="CELL_PHONE">Llamada</option>
                  <option value="EMAIL">Correo</option>
                </Form.Control>
              </Form.Group>
            }

          </Form.Row>
          <br />

          <Form.Row>
            <Form.Group as={Col} xs={12}>
              <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="permalinkCheckBox" required />
                <label className="custom-control-label" htmlFor="permalinkCheckBox">
                  Acepto haber leído los <a href={permalink} target="_blank" rel="noopener noreferrer">términos y condiciones y la política de privacidad</a> para hacer este pago.
                </label>
              </div>
            </Form.Group>
            <br />

            <Button variant="primary" className="btn-modal" type="submit">
              Continuar
            </Button>
          </Form.Row>
        </Form>

      </div>
    );
  }
}

const CartPsePaymentComponent = connect(select)(ConnectedCartPsePaymentComponent);

export default CartPsePaymentComponent;
