import React, { useState } from 'react';
import { Alert, Toast } from 'react-bootstrap';
import './styles/emailValidationWarning.scss';

function EmailValidationWarningComponent(props) {

  const { email, userId, userType, isToast } = props;
  const [emailSent, setEmailSent] = useState(false);

  const sendEmail = async () => {

    fetch('/email/verification_email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        userId,
        userType
      })
    });

    setEmailSent(true);

  };

  const message =
    emailSent ? (<p>Te enviamos un correo de verificación. Revisa tu correo electrónico.</p>) :
      (<p>
        Debes verificar tu correo electrónico para poder acceder a todas las funcionalidades.
        Te podemos reenviar el correo de verificación <span onClick={sendEmail}>haciendo clic aquí.</span>
      </p>
      );

  if (!isToast) {
    return (
      <div className="email-validation-warning-component">
        <Alert variant="info">
          {message}
        </Alert>
      </div>
    );
  } else {
    return (
      <div className="email-validation-warning-component email-validation-toast">
        <Toast show={true} onClose={() => { }} className='bg-warning'>
          <Toast.Header></Toast.Header>
          <Toast.Body>
            {message}
          </Toast.Body>
        </Toast>
      </div>
    );
  }

}

export default EmailValidationWarningComponent;
