import React, { Component } from 'react';
import { Form, Col, InputGroup, Row, Spinner, Alert } from 'react-bootstrap';
import {
  wompiBaseUrl,
  wompiPublicKey,
  redirectBaseUrl,
  epaycoBaseUrl,
  epaycoIsTest,
  epaycoPKey,
  epaycoPrivateKey,
  epaycoPublicKey,
  pciVaultUrl,
  pciVaultPassphrase,
  pciVaultPassword,
  pciVaultUser,
} from '../utils/paymentKeys';
import AppSpinner from '../Extras/AppSpinner';
import { connect } from 'react-redux';
import queryString from 'query-string';
import nibifyLogo from '../img/nibify_logo.png';
import { setUpdateNotifications } from '../Redux/modules/chat';
import {
  setUser,
  setIsFoundation,
  setIsEnterprise,
  setUserId,
  setToken,
} from '../Redux/modules/auth';
import { formatMoney, formatUSD } from '../utils/formatMoney';
import { withRouter } from 'react-router';
import AddressInfoIcon from '../Extras/AddressInfoIcon';
import './styles/styles.scss';
import 'react-phone-input-2/lib/bootstrap.css';
import '../PsePayment/styles/landingStyles.scss';
import '../PsePayment/styles/styles.scss';
import { NIBI_URL } from '../utils/baseUrl';
import PhoneInput from 'react-phone-input-2';

const select = (state) => {
  return {
    user: state.auth.user,
    token: state.auth.token,
    campaign: state.campaign.campaign,
    isFoundation: state.auth.isFoundation,
    isEnterprise: state.auth.isEnterprise,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setUpdateNotifications: (update) =>
      dispatch(setUpdateNotifications(update)),
    setUser: (user) => dispatch(setUser(user)),
    setUserId: (userId) => dispatch(setUserId(userId)),
    setIsFoundation: (isFoundation) => dispatch(setIsFoundation(isFoundation)),
    setIsEnterprise: (isEnterprise) => dispatch(setIsEnterprise(isEnterprise)),
    setToken: (token) => dispatch(setToken(token)),
  };
}

const minAmountCOP = 5000;
const minAmountUSD = 2;

class ConnectedRecurringPaymentTransactionComponent extends Component {
  constructor(props) {
    super(props);

    const {
      selectedPlan,
      donation,
      isPaymentLink,
      planQty,
      showInCOP,
      dollarPrice,
      language,
    } = props;
    const user = isPaymentLink ? props.paymentUser : props.user;

    let amount = 0;

    if (selectedPlan) {
      if (selectedPlan.amount || selectedPlan.amountDollars) {
        if (showInCOP) {
          amount = planQty
            ? selectedPlan.amount * parseInt(planQty)
            : selectedPlan.amount;
        } else {
          if (selectedPlan.amountDollars) {
            amount = planQty
              ? selectedPlan.amountDollars * parseInt(planQty)
              : selectedPlan.amountDollars;
          } else {
            amount = planQty
              ? (selectedPlan.amount / dollarPrice) * parseInt(planQty)
              : selectedPlan.amount / dollarPrice;
          }
        }
      }
    } else if (donation) amount = donation;

    amount = Math.round(amount * 100) / 100;

    this.state = {
      cardHolderName: '',
      cardHolderLastName: '',
      cardNumber: '',
      cvv: '',
      cardExpirationMonth: '',
      cardExpirationYear: '',
      cardToken: '',
      permalink: '',
      amount,
      currency: showInCOP ? 'COP' : 'USD',
      pseEmail: user && user.email ? user.email : '',
      referredBy: (user && user.referredBy) || '',
      isLoading: true,
      formValidated: false,
      creatingTransaction: false,
      acceptanceToken: '',
      transactionStatus: '',
      recurringPaymentExists: false,
      docType:
        user && user.identificationType && user.identificationType !== 'Otro'
          ? user.identificationType
          : 'CC',
      userDocument: user && user.identification ? user.identification : '',
      cellPhone: user && user.cellPhone ? user.cellPhone.replace('+', '') : '',
      contactMethod: 'WHATS_APP',
      userType: '0',
      institutionSelected: '0',
      financialInstitutions: [],
      recurringPaymentMethod: 'CARD',
      benefits: [],
      saveCard: false,
      savedPaymentSource: 'Ninguna',
      donationType: 'ONE_TIME_DONATION',
      nibifyCb: props.nibifyCb || false,
      userGender: 'M',
      userCity: '',
      userAddress: '',
      userAdditionalDetails: '',
      discountCode: '',
      validatingCode: false,
      codeSent: false,
      codeValidated: false,
      amountDiscounted: 0,
      donationCity: user && user.donationCity ? user.donationCity : '',
      donationAddress: user && user.donationAddress ? user.donationAddress : '',
      planAmount: 1,
      originalAmount: amount,
      needCertificate: false,
      cellPhoneVerified:
        user && user.cellPhoneVerified ? user.cellPhoneVerified : false,
      cellPhoneVerifiedOriginal:
        user && user.cellPhoneVerified ? user.cellPhoneVerified : false,
      cellPhoneVerificationCodeSent: false,
      smsVerificationCode: '',
      showSentCodeAlert: false,
      sendingSmsCode: false,
      smsVerificationError: '',
      phoneVerificationMethod: 'sms',
      originalPhone:
        user && user.cellPhone ? user.cellPhone.replace('+', '') : '',
      email: user && user.email ? user.email : '',
      language,
      country: user && user.country ? user.country : '',
      isInvalid: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.toggleNeedCertificate = this.toggleNeedCertificate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitNibify = this.handleSubmitNibify.bind(this);
    this.tokenizeCard = this.tokenizeCard.bind(this);
    this.createPayment = this.createPayment.bind(this);
    //this.createTransaction = this.createTransaction.bind(this);
    this.createTransactionEpayco = this.createTransactionEpayco.bind(this);
    this.createEpaycoTransaction = this.createEpaycoTransaction.bind(this);
    this.checkTransaction = this.checkTransaction.bind(this);
    this.checkEpaycoTransaction = this.checkEpaycoTransaction.bind(this);
    this.applyDiscountCode = this.applyDiscountCode.bind(this);
    this.handleSmsCodeChange = this.handleSmsCodeChange.bind(this);
    this.handleVerifyCellPhone = this.handleVerifyCellPhone.bind(this);
    this.createNewUser = this.createNewUser.bind(this);
  }

  toggleNeedCertificate(event) {
    this.setState({
      needCertificate: event.target.checked,
    });
  }

  componentDidUpdate(prevProps) {
    const user = this.props.isPaymentLink
      ? this.props.paymentUser
      : this.props.user;
    const prevUser = prevProps.isPaymentLink
      ? prevProps.paymentUser
      : prevProps.user;

    if (this.props.selectedPlan !== prevProps.selectedPlan) {
      this.setState({ amount: this.props.selectedPlan.amount });
    }
    if (this.props.donation !== prevProps.donation)
      this.setState({ amount: this.props.donation });
    if (user && prevUser && user.identification !== prevUser.identification) {
      this.setState({ userDocument: user.identification });
    }
    if (user && prevUser && user.cellPhone !== prevUser.cellPhone) {
      this.setState({ cellPhone: user.cellPhone });
    }
    if (this.props.nibifyCb !== prevProps.nibifyCb) {
      this.setState({ nibifyCb: this.props.nibifyCb || false });
    }
  }

  handleInputChange(e) {
    if (
      (e.target.name === 'cardExpirationMonth' ||
        e.target.name === 'cardExpirationYear') &&
      e.target.value.toString().length > 2
    )
      return;
    if (
      e.target.name === 'recurringPaymentMethod' &&
      e.target.value === 'PSE' &&
      this.state.docType !== 'CC' &&
      this.state.docType !== 'NIT'
    ) {
      this.setState({ docType: 'CC' });
    }
    if (
      e.target.name === 'donationType' &&
      e.target.value === 'RECURRING_PAYMENT'
    )
      this.setState({ recurringPaymentMethod: 'CARD' });

    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === 'planAmount') {
      this.setState({
        amount: this.state.originalAmount * e.target.value,
      });
    }
  }

  async createNewUser() {
    const {
      cardHolderName,
      cardHolderLastName,
      cellPhone,
      donationAddress,
      donationCity,
      docType,
      userDocument,
      email,
      country,
    } = this.state;
    const userProm = await fetch('/users/payment/create/user', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        firstName: cardHolderName,
        lastName: cardHolderLastName,
        cellPhone,
        donationAddress,
        donationCity,
        docType,
        docNumber: userDocument,
        email,
        country,
        subscriptionPayment: false,
      }),
    });

    const userResp = await userProm.json();
    if (userResp.success) {
      localStorage.setItem(
        'nibi_app',
        JSON.stringify({ token: userResp.token })
      );
      this.props.setUser({ user: userResp.user });
      this.props.setUserId({ userId: userResp.user._id });
      this.props.setToken({ token: userResp.token });
      this.props.setIsFoundation({ isFoundation: userResp.isFoundation });
      this.props.setIsEnterprise({ isEnterprise: userResp.isEnterprise });
    }
    return userResp;
  }

  async handleSmsCodeChange(event) {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.value.length === 4) {
      this.setState({ sendingSmsCode: true });
      const prom = await fetch('/sms/verifyCode', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          cellPhone: '+' + this.state.cellPhone,
          phoneVerificationMethod: this.state.phoneVerificationMethod,
          code: event.target.value,
          userId: this.props.user._id,
        }),
      });
      const res = await prom.json();
      if (res.success) {
        this.setState({
          cellPhoneVerified: true,
          showSentCodeAlert: false,
          smsVerificationError: '',
          sendingSmsCode: false,
        });
        fetch('/nibilytics/registerEvent', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            eventType: 'PHONE_VERIFIED',
            eventContext: this.props.context,
            landingEventId:
              this.props.context === 'LANDING' ? this.props.contextId : '',
            recurringPaymentPlanId:
              this.props.context === 'DONATION_PLAN'
                ? this.props.contextId
                : '',
            productId:
              this.props.context === 'PRODUCT_SALE' ? this.props.contextId : '',
            programId:
              this.props.context === 'PROGRAM' ? this.props.contextId : '',
            detail: 'Telefono verificado',
          }),
        });
        if (res.user) {
          let user = res.user;
          localStorage.setItem(
            'nibi_app',
            JSON.stringify({ token: res.token })
          );
          this.props.setUser({ user: user });
          this.props.setUserId({ userId: user._id });
          this.props.setToken({ token: res.token });
          this.props.setIsFoundation({ isFoundation: false });
          this.props.setIsEnterprise({ isEnterprise: false });
          this.setState({
            user,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            userIdentification: user.identification || '',
            city: user.donationCity || '',
            address: user.donationAddress || '',
            savedCreditCards: user.epaycoCreditCards || [],
            selectedCreditCard:
              user.epaycoCreditCards.length > 0
                ? user.epaycoCreditCards[0].token
                : '',
          });
        }
      } else {
        this.setState({
          smsVerificationError:
            'Ha ocurrido un error al verificar tu número de celular. Inténtalo nuevamente o asegúrate de ingresar el código correctamente.',
          showSentCodeAlert: false,
          smsVerificationCode: '',
          sendingSmsCode: false,
        });
      }
    }
  }

  async handleVerifyCellPhone() {
    this.setState({
      cellPhoneVerificationCodeSent: true,
      showSentCodeAlert: true,
      smsVerificationError: '',
    });
    const prom = await fetch('/sms/verificationLogin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        cellPhone: '+' + this.state.cellPhone,
        phoneVerificationMethod: this.state.phoneVerificationMethod,
      }),
    });

    const resp = await prom.json();
  }

  async createEpaycoTransaction(reference) {
    const user = this.props.isPaymentLink
      ? this.props.paymentUser
      : this.props.user;
    const { email } = user;
    const {
      amount,
      amountDiscounted,
      codeValidated,
      saveCard,
      cardNumber,
      currency,
    } = this.state;
    const { foundationName } = this.props;

    const token_apify = await this.getTokenApify();

    const body = JSON.stringify({
      value: `${codeValidated ? amount - amountDiscounted : amount}`,
      docType: this.state.docType,
      docNumber: this.state.userDocument,
      name: this.state.cardHolderName,
      lastName: this.state.cardHolderLastName,
      email: email,
      cellPhone: this.state.cellPhone,
      phone: this.state.cellPhone,
      cardNumber: this.state.cardNumber,
      cardExpYear: '20' + this.state.cardExpirationYear,
      cardExpMonth: this.state.cardExpirationMonth,
      cardCvc: this.state.cvv,
      dues: '1',
      redirect_url: `${redirectBaseUrl}/pago/resultado`,
      testMode: epaycoIsTest,
      extra1: reference,
      extra2: `Donación a: ${foundationName}`,
      currency,
    });

    const transactionResponse = await fetch(
      `${epaycoBaseUrl}/payment/process`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token_apify}`,
        },
        body,
      }
    );

    const transactionData = await transactionResponse.json();
    if (transactionData.success) {
      if (transactionData.data.transaction.data.estado === 'Aceptada') {
        if (saveCard) {
          if (
            transactionData.data.tokenCard.cardTokenId != 'N/A' &&
            transactionData.data.tokenCard.cardTokenId != ''
          ) {
            await fetch('/payments/save_epayco_card', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                token: transactionData.data.tokenCard.cardTokenId,
                userId: user._id,
                cardNumber: cardNumber.substring(cardNumber.length - 4),
              }),
            });
          }
        }
        return transactionData.data.transaction.data.ref_payco;
      } else {
        //Enviar log a BD
        await fetch('/nibiLogs/create', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            content: transactionData,
            userId: user._id,
            context: 'Transacción no aceptada',
          }),
        });
        this.setState({
          creatingTransaction: false,
          transactionStatus: transactionData.textResponse,
        });
      }
    } else {
      this.setState({
        creatingTransaction: false,
        transactionStatus: transactionData.textResponse,
      });
      //Enviar log a BD
      await fetch('/nibiLogs/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          content: transactionData,
          userId: user._id,
          context: 'Transacción fallida',
        }),
      });
    }
  }

  createNibifyTransaction = async () => {
    const user = this.props.isPaymentLink
      ? this.props.paymentUser
      : this.props.user;
    const { program } = this.props;
    const {
      amount,
      savedPaymentSource,
      saveCard,
      cardNumber,
      userGender,
      userCity,
      userAddress,
      userAdditionalDetails,
      cellPhone,
      userDocument,
      docType,
      contactMethod,
      acceptanceToken,
      discountCode,
    } = this.state;

    const showContactMethod = user && !user.contactMethod;

    if (savedPaymentSource === 'Ninguna') {
      const cardToken = await this.tokenizeCard();

      if (!cardToken.success) return;

      if (saveCard) {
        const cardToken = await this.tokenizeCard();

        fetch('/payments/save_card', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token: cardToken.token,
            userId: user._id,
            cardNumber: cardNumber.substring(cardNumber.length - 4),
            customer_email: user.email,
          }),
        });
      }

      const transactionResponse = await fetch('/nibify/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user._id,
          paymentMethod: 'CARD',
          amount,
          cardToken: cardToken.token,
          foundationId: program.foundationId,
          programId: program._id,
          userEmail: user.email,
          userGender,
          userPhone: cellPhone,
          userAddress,
          userIdentification: userDocument,
          userIdentificationType: docType,
          contactMethod: showContactMethod ? contactMethod : '',
          userCity,
          userAdditionalDetails,
          saveCard,
          cardNumber: cardNumber.trim().slice(-4),
          acceptance_token: acceptanceToken,
          discountCode,
        }),
      });

      const transtactionData = await transactionResponse.json();

      if (transtactionData.success && transtactionData.data)
        return transtactionData.data.id;
      else
        this.setState({
          creatingTransaction: false,
          transactionStatus: 'Error al procesar el pago.',
        });
    } else {
      const transactionResponse = await fetch('/nibify/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user._id,
          paymentMethod: 'CARD',
          amount,
          savedPaymentSource,
          foundationId: program.foundationId,
          programId: program._id,
          userEmail: user.email,
          userGender,
          userPhone: cellPhone,
          userAddress,
          userIdentification: userDocument,
          userIdentificationType: docType,
          contactMethod: showContactMethod ? contactMethod : '',
          userCity,
          userAdditionalDetails,
          saveCard,
          cardNumber: cardNumber.trim().slice(-4),
          acceptance_token: acceptanceToken,
          discountCode,
        }),
      });

      const transactionData = await transactionResponse.json();

      if (transactionData.success && transactionData.data)
        return transactionData.data.id;
      else
        this.setState({
          creatingTransaction: false,
          transactionStatus: 'Error al procesar el pago.',
        });
    }
  };

  async createTransactionEpayco() {
    const {
      programId,
      programName,
      foundationId,
      foundationName,
      customDonation,
      selectedPlan,
      planQty,
      donationDay,
    } = this.props;
    const user = this.props.isPaymentLink
      ? this.props.paymentUser
      : this.props.user;

    const { email, firstName, lastName, businessName } = user;

    const {
      recurringPaymentMethod,
      acceptanceToken,
      amount,
      referredBy,
      pseEmail,
      cardNumber,
      saveCard,
      savedPaymentSource,
      docType,
      userDocument,
      cellPhone,
      donationType,
      discountCode,
      cardHolderName,
      cardHolderLastName,
      userAddress,
      currency,
    } = this.state;

    const paymentSources = user.epaycoCreditCards || [];
    let creditCardMask = '';
    let creditCardBrand = '';
    if (savedPaymentSource !== 'Ninguna') {
      const creditCardInfo = paymentSources.find(
        (creditCard) => creditCard.token === savedPaymentSource
      );
      if (creditCardInfo) {
        creditCardMask = creditCardInfo.mask;
        creditCardBrand = creditCardInfo.brand;
      }
    }

    await fetch('/users/update', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        _id: user._id,
        address: userAddress,
      }),
    });

    const isRecPayment =
      !customDonation ||
      (customDonation && donationType === 'RECURRING_PAYMENT');

    if (recurringPaymentMethod === 'CARD') {
      if (savedPaymentSource !== 'Ninguna') {
        let recurringPaymentData;

        if (isRecPayment) {
          const recurringPaymentResponse = await fetch(
            '/recurringPayments/create',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                amount,
                referredBy,
                recurringPaymentMethod,
                type: 'FLEXIBLE',
                epaycoPaymentSourceId: savedPaymentSource || '',
                foundationId,
                foundationName,
                programName,
                programId,
                userFirstName: firstName,
                userLastName: lastName,
                userBusinessName: businessName,
                userId: user._id,
                userEmail: email,
                userPhone: cellPhone,
                userDoc: userDocument,
                docType,
                selectedPlan,
                customer_email: user.email,
                nibifyCb: this.state.nibifyCb,
                userGender: this.state.userGender,
                userCity: this.state.userCity,
                userAddress: this.state.userAddress,
                userAdditionalDetails: this.state.userAdditionalDetails,
                discountCode,
                planQty,
                donationDay,
                currency,
                creditCardBrand,
                creditCardMask,
              }),
            }
          );

          recurringPaymentData = await recurringPaymentResponse.json();

          if (!recurringPaymentData.success) {
            this.setState({
              creatingTransaction: false,
              transactionStatus:
                recurringPaymentData.message || 'Error al procesar el pago.',
            });
            //Enviar log a BD
            await fetch('/nibiLogs/create', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                content: recurringPaymentData,
                userId: user._id,
                context: 'Error en /recurringPayments/create',
              }),
            });
            return;
          }
        }
        const reference = isRecPayment
          ? await this.createPayment(recurringPaymentData.recurringPayment._id)
          : await this.createPayment();
        const transactionResponse = await fetch(
          '/payments/transaction/create/payment_source',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              userId: user._id,
              amount,
              epaycoPaymentSourceId: savedPaymentSource || '',
              userEmail: email,
              reference,
              discountCode,
              paymentType: 'RECURRING',
              cardHolderName: cardHolderName,
              cardHolderLastName: cardHolderLastName,
              cellPhone: cellPhone,
              foundationName: foundationName,
              userIdentification: userDocument,
              userIdentificationType: docType,
              currency,
            }),
          }
        );
        const transactionData = await transactionResponse.json();
        if (!transactionData.success) {
          //Enviar log a BD
          await fetch('/nibiLogs/create', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              content: transactionData,
              userId: user._id,
              context: 'Error en /payments/transaction/create/payment_source',
            }),
          });
          return this.setState({
            creatingTransaction: false,
            transactionStatus: 'Error al procesar el pago.',
          });
        }

        return transactionData.transactionId;
      } else {
        let recurringPaymentData;

        if (saveCard) {
          const epaycoToken = await this.getTokenApify();
          const tokenizeCardResponse = await fetch(
            `${epaycoBaseUrl}/token/card`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${epaycoToken}`,
              },
              body: JSON.stringify({
                cardNumber: this.state.cardNumber,
                cardExpYear: '20' + this.state.cardExpirationYear,
                cardExpMonth: this.state.cardExpirationMonth,
                cardCvc: this.state.cvv,
              }),
            }
          );

          const tokenCardData = await tokenizeCardResponse.json();
          if (tokenCardData.success) {
            await fetch('/payments/save_epayco_card', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                card: {
                  token: tokenCardData.data.data.id,
                  brand: tokenCardData.data.card.name,
                  mask: tokenCardData.data.card.mask,
                },
                userId: user._id,
              }),
            });
          }
        }

        if (isRecPayment) {
          const epaycoToken = await this.getTokenApify();
          const tokenizeCardResponse = await fetch(
            `${epaycoBaseUrl}/token/card`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${epaycoToken}`,
              },
              body: JSON.stringify({
                cardNumber: this.state.cardNumber,
                cardExpYear: '20' + this.state.cardExpirationYear,
                cardExpMonth: this.state.cardExpirationMonth,
                cardCvc: this.state.cvv,
              }),
            }
          );

          const tokenCardData = await tokenizeCardResponse.json();
          if (tokenCardData.success) {
            const recurringPaymentResponse = await fetch(
              '/recurringPayments/create',
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  type: 'FLEXIBLE',
                  amount,
                  foundationId,
                  foundationName,
                  programName,
                  programId,
                  userFirstName: firstName,
                  userLastName: lastName,
                  userBusinessName: businessName,
                  userId: user._id,
                  userEmail: email,
                  recurringPaymentMethod,
                  userPhone: cellPhone,
                  cardNumber: saveCard
                    ? cardNumber.substring(cardNumber.length - 4)
                    : '',
                  cardBrand: '',
                  referredBy,
                  userDoc: userDocument,
                  docType,
                  cellPhone,
                  selectedPlan,
                  customer_email: user.email,
                  acceptance_token: acceptanceToken,
                  nibifyCb: this.state.nibifyCb,
                  userGender: this.state.userGender,
                  userCity: this.state.userCity,
                  userAddress: this.state.userAddress,
                  userAdditionalDetails: this.state.userAdditionalDetails,
                  discountCode,
                  planQty,
                  donationDay,
                  currency,
                  epaycoPaymentSourceId: tokenCardData.data.data.id,
                  credotCardBrand: tokenCardData.data.card.name,
                  creditCardMask: tokenCardData.data.card.mask,
                }),
              }
            );

            recurringPaymentData = await recurringPaymentResponse.json();

            if (!recurringPaymentData.success) {
              this.setState({
                creatingTransaction: false,
                transactionStatus:
                  recurringPaymentData.message || 'Error al procesar el pago.',
              });
              //Enviar log a BD
              await fetch('/nibiLogs/create', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  content: recurringPaymentData,
                  userId: user._id,
                  context: 'Error en /recurringPayments/create',
                }),
              });
              return;
            }
          } else {
            //Enviar log a BD
            await fetch('/nibiLogs/create', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                content: tokenCardData,
                userId: user._id,
                context: 'Error al tokenizar tarjeta PCI',
              }),
            });
          }
        }

        const reference = isRecPayment
          ? await this.createPayment(recurringPaymentData.recurringPayment._id)
          : await this.createPayment();

        if (isRecPayment) {
          const transactionResponse = await fetch(
            '/payments/transaction/create/payment_source',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                userId: user._id,
                amount,
                savedPaymentSource:
                  recurringPaymentData.recurringPayment.epaycoPaymentSourceId,
                userEmail: user.email,
                reference,
                discountCode,
                paymentType: 'RECURRING',
                cardHolderName: cardHolderName,
                cardHolderLastName: cardHolderLastName,
                cellPhone: cellPhone,
                foundationName: foundationName,
                userIdentification: userDocument,
                userIdentificationType: docType,
                currency,
              }),
            }
          );

          const transactionData = await transactionResponse.json();

          if (!transactionData.success) {
            this.setState({
              creatingTransaction: false,
              transactionStatus: 'Error al procesar el pago.',
            });
            //Enviar log a BD
            await fetch('/nibiLogs/create', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                content: transactionData,
                userId: user._id,
                context: 'Error en /payments/transaction/create/payment_source',
              }),
            });
            return;
          }

          return transactionData.transactionId;
        } else {
          const transactionId = await this.createEpaycoTransaction(reference);
          return transactionId;
        }
      }
    } else {
      const {
        institutionSelected,
        userDocument,
        userType,
        docType,
        cellPhone,
        amount,
        codeValidated,
        amountDiscounted,
        currency,
      } = this.state;

      let recurringPaymentData;

      if (isRecPayment) {
        const recurringPaymentResponse = await fetch(
          '/recurringPayments/create',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              type: 'FLEXIBLE',
              amount,
              foundationId,
              foundationName,
              programName,
              programId,
              userFirstName: firstName,
              userLastName: lastName,
              userBusinessName: businessName,
              userId: user._id,
              userEmail: email,
              pseEmail,
              recurringPaymentMethod,
              referredBy,
              docType,
              userType,
              selectedPlan,
              userDoc: userDocument,
              userPhone: cellPhone,
              financialInstitution: institutionSelected,
              customer_email: user.email,
              discountCode,
              planQty,
              donationDay,
              currency,
              creditCardBrand,
              creditCardMask,
              epaycoPaymentSourceId: savedPaymentSource || '',
            }),
          }
        );

        recurringPaymentData = await recurringPaymentResponse.json();

        if (!recurringPaymentData.success) {
          this.setState({
            creatingTransaction: false,
            transactionStatus:
              recurringPaymentData.message || 'Error al procesar el pago.',
          });
          //Enviar log a BD
          await fetch('/nibiLogs/create', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              content: recurringPaymentData,
              userId: user._id,
              context: 'Error en /recurringPayments/create',
            }),
          });
          return;
        }
      }

      const reference = isRecPayment
        ? await this.createPayment(recurringPaymentData.recurringPayment._id)
        : await this.createPayment();
      const ipResponse = await fetch('/payments/ip_address', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const ipData = await ipResponse.json();
      const ip = ipData.ip;
      const body = JSON.stringify({
        bank: institutionSelected,
        value: `${codeValidated ? amount - amountDiscounted : amount}`,
        docType,
        typePerson: `${docType === 'NIT' ? 1 : 0}`,
        docNumber: userDocument,
        name: firstName + ' ' + lastName,
        email,
        cellPhone: cellPhone.slice(-10),
        urlResponse: `${redirectBaseUrl}/paymentResult/${reference}`,
        ip,
        urlConfirmation: `${NIBI_URL}payments/transaction/events/epayco`,
        methodConfirmation: 'GET',
        extra1: reference,
        extra2: `Donación a: ${foundationName}`,
        currency,
      });
      const token_apify = await this.getTokenApify();

      const transactionResponse = await fetch(
        `${epaycoBaseUrl}/payment/process/pse`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token_apify}`,
          },
          body,
        }
      );

      const transactionData = await transactionResponse.json();
      if (!transactionData.success) {
        this.setState({
          creatingTransaction: false,
          transactionStatus: 'Error al procesar el pago.',
        });
        //Enviar log a BD
        await fetch('/nibiLogs/create', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            content: transactionData,
            userId: user._id,
            context: 'Error en transacción por PSE',
          }),
        });
        return;
      }
      window.open(transactionData.data.urlbanco, '_self');

      const transactionId = transactionData.data.id;
      return transactionId;
    }
  }

  async getTokenApify() {
    try {
      const encodedCredentials = btoa(`${epaycoPublicKey}:${epaycoPrivateKey}`);

      const tokenApifyResponse = await fetch(`${epaycoBaseUrl}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${encodedCredentials}`,
        },
      });

      const tokenData = await tokenApifyResponse.json();

      return tokenData.token;
    } catch (error) {
      console.error('Error fetching token from Apify:', error);
      throw new Error('Failed to fetch token from Apify.');
    }
  }

  async tokenizeCard() {
    const {
      cardNumber,
      cvv,
      cardHolderName,
      cardHolderLastName,
      cardExpirationMonth,
      cardExpirationYear,
    } = this.state;

    const tokenizeCardResponse = await fetch(`${wompiBaseUrl}/tokens/cards`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${wompiPublicKey}`,
      },
      body: JSON.stringify({
        number: `${cardNumber}`,
        cvc: `${cvv}`,
        exp_month: `${cardExpirationMonth}`,
        exp_year: `${cardExpirationYear}`,
        card_holder: cardHolderName + ' ' + cardHolderLastName,
      }),
    });

    const tokenCardData = await tokenizeCardResponse.json();

    if (
      tokenizeCardResponse.status === 201 &&
      tokenCardData.status === 'CREATED'
    )
      return {
        success: true,
        token: tokenCardData.data.id,
        brand: tokenCardData.data.brand,
      };
    else if (tokenizeCardResponse.status === 422)
      return { success: false, message: 'Datos de la tarjeta inválidos.' };
    else return { success: false, message: 'Error al procesar la tarjeta.' };
  }

  async createPayment(recurringPaymentId) {
    const {
      amount,
      recurringPaymentMethod,
      docType,
      userDocument,
      cellPhone,
      contactMethod,
      donationType,
      discountCode,
      donationCity,
      donationAddress,
      currency,
    } = this.state;
    const {
      token,
      programId,
      foundationId,
      campaign,
      customDonation,
      isPaymentLink,
    } = this.props;
    const user = isPaymentLink ? this.props.paymentUser : this.props.user;
    const showContactMethod = user && !user.contactMethod;
    const isRecPayment =
      !customDonation ||
      (customDonation && donationType === 'RECURRING_PAYMENT');

    const paymentResponse = await fetch('/payments/payment_gateway/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({
        programId,
        foundationId,
        amount,
        paymentMethod: recurringPaymentMethod,
        paymentType: isRecPayment ? 'RECURRING_PAYMENT' : donationType,
        recurringPaymentType: 'FLEXIBLE',
        recurringPaymentId,
        userIdentification: userDocument,
        userIdentificationType: docType,
        userCity: donationCity,
        userAddress: donationAddress,
        cellPhone,
        contactMethod: showContactMethod ? contactMethod : '',
        campaign,
        discountCode,
        isPaymentLink,
        userId: user._id,
        currency,
      }),
    });

    const paymentData = await paymentResponse.json();
    const { reference } = paymentData;

    return reference;
  }

  async checkTransaction(transactionId) {
    const transactionResponse = await fetch(
      `${wompiBaseUrl}/transactions/${transactionId}`
    );
    const transaction = await transactionResponse.json();
    const { data } = transaction;
    const { recurringPaymentMethod } = this.state;

    if (recurringPaymentMethod === 'CARD') {
      if (data && data.status !== 'PENDING') {
        clearInterval(this.pollingTransaction);
        this.setState({
          creatingTransaction: false,
          transactionStatus: data.status,
        });
        this.props.setUpdateNotifications({ updateNotifications: true });
      }
    } else {
      if (
        data &&
        data.payment_method &&
        data.payment_method.extra &&
        data.payment_method.extra.async_payment_url
      ) {
        clearInterval(this.pollingTransaction);

        const paymentUrl = data.payment_method.extra.async_payment_url;
        if (window.location.href !== paymentUrl)
          window.location.href = paymentUrl;
      }
    }
  }

  async checkEpaycoTransaction(transactionId) {
    const token_apify = await this.getTokenApify();

    const transactionResponse = await fetch(
      `${epaycoBaseUrl}/payment/transaction`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token_apify}`,
        },
        body: JSON.stringify({
          referencePayco: transactionId,
        }),
      }
    );
    const transaction = await transactionResponse.json();

    const { data } = transaction;
    const { recurringPaymentMethod } = this.state;

    if (recurringPaymentMethod === 'CARD') {
      if (data && data.response !== 'Pendiente') {
        clearInterval(this.pollingTransaction);
        this.setState({
          creatingTransaction: false,
          transactionStatus: 'APPROVED',
        });
        this.props.setUpdateNotifications({ updateNotifications: true });
      }
    }
  }

  async applyDiscountCode() {
    const { discountCode } = this.state;
    const user = this.props.isPaymentLink
      ? this.props.paymentUser
      : this.props.user;

    if (discountCode) {
      this.setState({ validatingCode: true });

      const discCodeProm = await fetch('/discountCode/validate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user._id,
          code: discountCode,
          donationType: 'RECURRING',
        }),
      });

      const discCodeResp = await discCodeProm.json();
      if (discCodeResp.success)
        this.setState({
          codeValidated: discCodeResp.isValid,
          codeSent: true,
          amountDiscounted: parseInt(discCodeResp.discountAmount),
        });

      this.setState({ validatingCode: false });
    }
  }

  async handleSubmitNibify(event) {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    if (this.institutionSelected === '0') {
      this.setState({ formValidated: false });
    } else {
      this.setState({ formValidated: true });
    }
    if (form.checkValidity()) {
      if (this.props.gaTrackEventPayment) this.props.gaTrackEventPayment();

      this.setState({ creatingTransaction: true });

      const transactionId = await this.createNibifyTransaction();
      if (!transactionId) return;

      this.pollingTransaction = setInterval(async () => {
        this.checkEpaycoTransaction(transactionId);
      }, 2000);
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    console.log(event);

    this.setState({ formValidated: true });

    if (form.checkValidity()) {
      if (!this.state.cellPhoneVerified) {
        return alert(
          'Debes verificar tu número telefónico antes de realizar la donación'
        );
      }

      if (this.props.gaTrackEventPayment) this.props.gaTrackEventPayment();

      this.setState({ creatingTransaction: true });

      const { programId, foundationId, customDonation } = this.props;
      const user = this.props.isPaymentLink
        ? this.props.paymentUser
        : this.props.user;
      if (!user._id) {
        const created = await this.createNewUser();
        if (created.errorMessage) {
          return alert(created.errorMessage);
        }
      }
      const benefitsProm = fetch('/benefits/random');

      const transactionId = await this.createTransactionEpayco();
      if (!transactionId) return;

      const benefitsResponse = await benefitsProm;
      const benefitsData = await benefitsResponse.json();

      if (benefitsData.success)
        this.setState({ benefits: benefitsData.benefits });

      this.pollingTransaction = setInterval(async () => {
        //this.checkTransaction(transactionId);
        this.checkEpaycoTransaction(transactionId);
      }, 2000);
    }
  }

  async componentDidMount() {
    //EPAYCO
    const token_apify = await this.getTokenApify();
    const financialInstitutionsFetch = fetch(
      `${epaycoBaseUrl}/payment/pse/banks`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token_apify}`,
        },
      }
    );
    const financialInstitutionsResponse = await financialInstitutionsFetch;
    const financialInstitutionsData =
      await financialInstitutionsResponse.json();
    this.setState({
      financialInstitutions: financialInstitutionsData.data,
    });

    this.setState({ isLoading: false });
  }

  render() {
    const {
      programId,
      programName,
      foundationName,
      isEnterprise,
      isFoundation,
      customDonation,
      selectedPlan,
      program,
      isPaymentLink,
      planQty,
    } = this.props;
    const user = isPaymentLink ? this.props.paymentUser : this.props.user;
    const paymentSources = user.epaycoCreditCards || [];
    const values = queryString.parse(this.props.location.search);
    const { nibify } = values;
    const nibifyProgram = program && program.nibifyProgram && nibify === '1';
    const showContactMethod = user && !user.contactMethod;
    const {
      cardHolderName,
      cardHolderLastName,
      cardNumber,
      cardExpirationMonth,
      cardExpirationYear,
      cvv,
      isLoading,
      permalink,
      formValidated,
      amount,
      currency,
      creatingTransaction,
      transactionStatus,
      recurringPaymentExists,
      recurringPaymentMethod,
      docType,
      userDocument,
      userType,
      cellPhone,
      institutionSelected,
      financialInstitutions,
      pseEmail,
      benefits,
      saveCard,
      savedPaymentSource,
      contactMethod,
      donationType,
      nibifyCb,
      discountCode,
      codeValidated,
      codeSent,
      amountDiscounted,
      donationCity,
      donationAddress,
      planAmount,
      cellPhoneVerified,
      needCertificate,
      cellPhoneVerificationCodeSent,
      smsVerificationCode,
      showSentCodeAlert,
      sendingSmsCode,
      smsVerificationError,
      phoneVerificationMethod,
      email,
      language,
      country,
    } = this.state;

    const SUMMARY = language === 'ES' ? 'RESUMEN' : 'SUMMARY';
    const DONATION = language === 'ES' ? 'Donación' : 'Donation';
    const AMOUNT = language === 'ES' ? 'Monto' : 'Amount';
    const ORGANIZATION = language === 'ES' ? 'Organización' : 'Organization';
    const SELECT_PAYMENT_METHOD =
      language === 'ES'
        ? 'Selecciona un método de pago'
        : 'Select a payment method';
    const CREDIT_OR_DEBIT_CARD =
      language === 'ES'
        ? 'Tarjeta de crédito o débito con CVV'
        : 'Credit or debit card';
    const BANK_DEBIT =
      language === 'ES' ? 'Débito bancario (PSE)' : 'Bank debit (PSE)';
    const DONATION_TYPE =
      language === 'ES' ? 'Tipo de donación' : 'Donation type';
    const UNIQUE = language === 'ES' ? 'Única' : 'One time donation';
    const RECURRING = language === 'ES' ? 'Recurrente' : 'Recurring donation';
    const AMOUNT_TO_DONATE =
      language === 'ES' ? 'Monto a donar' : 'Amount to donate';
    const NUMBER_OF_PLANS =
      language === 'ES' ? 'Cantidad de planes' : 'Number of plans';
    const CELLPHONE_NUMBER =
      language === 'ES' ? 'Número de teléfono' : 'Enter your phone number';
    const CITY = language === 'ES' ? 'Ciudad' : 'City';
    const COUNTRY = language === 'ES' ? 'País' : 'Country';
    const RECEIVE_CODE =
      language === 'ES'
        ? 'Método de verificación'
        : 'Receive verification code via';
    const VERIFY = language === 'ES' ? 'Verificar' : 'Verify';
    const ADDRESS = language === 'ES' ? 'Dirección' : 'Address';
    const VERIFICATION_CODE =
      language === 'ES' ? 'Código de verificación:' : 'Verification code:';
    const EMAIL_ADDRESS =
      language === 'ES' ? 'Correo electrónico' : 'Email address';
    const PAYMENT_INFO =
      language === 'ES' ? 'Información de la tarjeta' : 'Payment info';
    const USE_SAVED_CARD =
      language === 'ES' ? 'Usar tarjeta guardada' : 'Use a saved card';
    const FIRST_NAME = language === 'ES' ? 'Nombre(s)' : 'First name';
    const LAST_NAME = language === 'ES' ? 'Apellido(s)' : 'Last name';
    const SAME_AS_CARD =
      language === 'ES' ? 'Como aparece en la tarjeta' : 'As shown on card';
    const ID_TYPE = language === 'ES' ? 'Tipo documento' : 'ID';
    const RESEND_CODE = language === 'ES' ? 'Reenviar código' : 'Resend code';
    const ID_NUMBER = language === 'ES' ? 'Número de documento' : 'ID number';
    const CARD_NUMBER =
      language === 'ES' ? 'Número de la tarjeta' : 'Card number';
    const EXPIRATION_DATE =
      language === 'ES' ? 'Fecha de expiración' : 'Expiration date';
    const SECURITY_CODE =
      language === 'ES' ? 'Código de seguridad' : 'Security code';
    const SAVE_CARD =
      language === 'ES'
        ? '¿Deseas guardar tu tarjeta de crédito para futuras transacciones?'
        : 'Save this card for future donations';
    const RECEIVE_CONTENT =
      language === 'ES'
        ? '¿Por dónde te gustaría recibir la información de tu impacto?'
        : 'How do you prefer to receive impact content?';
    const ACCEPT_THE =
      language === 'ES' ? 'Acepto haber leído los' : 'I accept the';
    const TERMS_AND_CONDITIONS =
      language === 'ES'
        ? 'términos y condiciones y la política de privacidad.'
        : 'terms, conditions and privacy policy.';
    const CONTINUE = language === 'ES' ? 'Continuar' : 'Continue';
    const WAIT_TRANSACTION =
      language === 'ES'
        ? 'Por favor espera mientras procesamos la transacción. Esto puede tardar varios segundos...'
        : 'Please wait while we process the transaction. This may take a few seconds...';
    const CODE_SENT = language === 'ES' ? 'Código enviado' : 'Code sent';
    const ENTER_CODE =
      language === 'ES'
        ? 'Hemos enviado un código de verificación de 4 dígitos a tu número de celular. Por favor ingrésalo en el campo correspondiente para validar tu número de celular.'
        : 'A 4 digit verification code has been sent to your cellphone. Please enter the code in the corresponding field to validate your phone number.';
    const VERIFICATION_ERROR =
      language === 'ES' ? 'Error de verificación' : 'Verification error';
    const FINANCIAL_INSTITUTION =
      language === 'ES' ? 'Institución financiera' : 'Financial institution';
    const SELECT_BANK =
      language === 'ES' ? 'Selecciona tu banco.' : 'Select your bank';
    const ENTER_VALID_MAIL =
      language === 'ES'
        ? 'Ingresa un correo válido.'
        : 'Enter a valid email address';
    const NONE = language === 'ES' ? 'Ninguna' : 'None';
    const SUCCESSFUL_TRANSACTION =
      language === 'ES' ? 'Transacción exitosa' : 'Successful transaction';
    const TRANSACTION_ERROR =
      language === 'ES' ? 'Error en la transacción' : 'Transaction error';
    const TRANSACTION_ERROR_MESSAGE =
      language === 'ES'
        ? `La transacción fue anulada por la pasarela de pagos. Por favor verifica la transacción con tu entidad bancaria o inténtalo nuevamente.`
        : 'The transaction was denied by the payment gateway. Please verify the transaction with your banking entity or try again.';
    const TEXT_MESSAGE =
      language === 'ES' ? 'Mensaje de texto' : 'Text message';

    if (isFoundation || isEnterprise) {
      return (
        <div className='recurring-payment-component'>
          <p>No puedes donar a una campaña si eres una organización.</p>
        </div>
      );
    }

    let title = '';
    let message = '';

    if (transactionStatus === 'APPROVED') {
      title = SUCCESSFUL_TRANSACTION;
      message =
        language === 'ES'
          ? `Recibimos tu donación por un valor de $${
              formatMoney(amount) + currency
            }.
      Gracias por donar con Nibi, la transacción fue exitosa. Ahora estás apadrinando ${
        programId
          ? `la campaña ${programName}.`
          : `la fundación ${foundationName}.`
      }`
          : `Recibimos tu donación por un valor de $${
              formatMoney(amount) + currency
            }.
      Thank you for donating with Nibi, the transaction was successful. You are now sponsoring ${
        programId ? `${programName} program.` : `${foundationName} foundation.`
      }`;
    } else {
      title = TRANSACTION_ERROR;
      message = TRANSACTION_ERROR_MESSAGE;
    }

    return (
      <div className='recurring-payment-component'>
        {recurringPaymentExists ? (
          <div className='landing-pse-payment-component'>
            <h4 className='message'>
              Ya estás apadrinando{' '}
              {programId ? 'esta campaña' : 'esta organización'}. Para ver tus
              suscripciones ve a tu perfil.
            </h4>
          </div>
        ) : transactionStatus ? (
          <div className='landing-pse-payment-component recurring-payment-component-benefits'>
            <h1 className='title'>{title}</h1>
            <h4 className='message'>{message}</h4>
          </div>
        ) : creatingTransaction ? (
          <div className='pse-transaction-spinner'>
            <AppSpinner />
            <h4 className='message'>{WAIT_TRANSACTION}</h4>
          </div>
        ) : (
          <Row>
            <Col
              md={4}
              style={{ borderRight: '1px solid #DEE2E6' }}>
              <div className='program-donation-summary'>
                <h4 className='title'>{SUMMARY}</h4>
                <p className='program-name'>
                  {DONATION} {programName || foundationName}
                </p>
                <Row
                  noGutters
                  className='row-donation-summary'>
                  <Col xs={6}>
                    <p>
                      <strong>{AMOUNT}</strong>
                    </p>
                  </Col>
                  <Col xs={6}>
                    <p id='programDonationAmount'>
                      {'$' + amount + ' ' + currency}
                    </p>
                  </Col>
                  <Col xs={6}>
                    <p>
                      <strong>{ORGANIZATION}</strong>
                    </p>
                  </Col>
                  <Col xs={6}>
                    <p id='programDonationFoundationName'>{foundationName}</p>
                  </Col>
                  {selectedPlan && (
                    <>
                      <Col xs={6}>
                        <p>
                          <strong>Plan</strong>
                        </p>
                      </Col>
                      <Col xs={6}>
                        <p>{selectedPlan.name}</p>
                      </Col>
                    </>
                  )}
                  {planQty && (
                    <>
                      <Col xs={6}>
                        <p>
                          <strong>{NUMBER_OF_PLANS}</strong>
                        </p>
                      </Col>
                      <Col xs={6}>
                        <p>{planQty}</p>
                      </Col>
                    </>
                  )}
                  {codeSent && codeValidated && amountDiscounted > 0 && (
                    <>
                      <Col xs={6}>
                        <p>
                          <strong>Cupón</strong>
                        </p>
                      </Col>
                      <Col xs={6}>
                        <p>- ${formatMoney(amountDiscounted)} COP</p>
                      </Col>
                    </>
                  )}
                </Row>
                <Row noGutters>
                  <Col xs={6}>
                    <p>
                      <strong>
                        Total{' '}
                        {codeSent &&
                          codeValidated &&
                          discountCode === 'nibiseponela10' &&
                          ' a pagar'}
                      </strong>
                    </p>
                  </Col>
                  <Col xs={6}>
                    {codeSent && codeValidated ? (
                      <p>
                        <strong>
                          ${formatMoney(amount - amountDiscounted)} COP
                        </strong>
                      </p>
                    ) : (
                      <p>
                        <strong>{'$' + amount + ' ' + currency}</strong>
                      </p>
                    )}
                  </Col>
                  {codeSent &&
                    codeValidated &&
                    discountCode === 'nibiseponela10' && (
                      <>
                        <Col xs={6}>
                          <p>
                            <strong>Total mas cupón</strong>
                          </p>
                        </Col>
                        <Col xs={6}>
                          {amount > 0 && codeSent && codeValidated && (
                            <p>
                              <strong>
                                ${formatMoney(parseInt(amount) + 10000)} COP
                              </strong>
                            </p>
                          )}
                        </Col>
                      </>
                    )}
                </Row>
                <p style={{ textAlign: 'center' }}>
                  <strong>{SELECT_PAYMENT_METHOD}</strong>
                </p>
                <div className='program-donation-payment-methods'>
                  <fieldset>
                    <Form.Group>
                      <div
                        className='custom-control custom-radio'
                        id='programDonationRadioCard'>
                        <input
                          type='radio'
                          id='paymentMethodRadio1'
                          name='CARD'
                          className={`custom-control-input${
                            recurringPaymentMethod === 'CARD' ? ' checked' : ''
                          }`}
                          checked={recurringPaymentMethod === 'CARD'}
                          onChange={() =>
                            this.setState({
                              recurringPaymentMethod: 'CARD',
                            })
                          }
                        />
                        <label
                          className='custom-control-label'
                          htmlFor='paymentMethodRadio1'>
                          <i className='far fa-credit-card' />{' '}
                          {CREDIT_OR_DEBIT_CARD}
                        </label>
                      </div>
                      {donationType === 'ONE_TIME_DONATION' &&
                        customDonation && (
                          <div
                            className='custom-control custom-radio'
                            id='programDonationRadioPse'>
                            <input
                              type='radio'
                              id='paymentMethodRadio2'
                              name='PSE'
                              className={`custom-control-input${
                                recurringPaymentMethod === 'PSE'
                                  ? ' checked'
                                  : ''
                              }`}
                              checked={
                                recurringPaymentMethod === 'PSE' &&
                                donationType === 'ONE_TIME_DONATION'
                              }
                              onChange={() =>
                                this.setState({
                                  recurringPaymentMethod: 'PSE',
                                })
                              }
                              disabled={nibifyCb}
                            />
                            <label
                              className='custom-control-label'
                              htmlFor='paymentMethodRadio2'>
                              {BANK_DEBIT}
                            </label>
                          </div>
                        )}
                    </Form.Group>
                  </fieldset>
                </div>
              </div>
            </Col>
            <Col>
              <Form
                noValidate
                validated={formValidated}
                onSubmit={
                  nibifyCb && nibifyProgram
                    ? this.handleSubmitNibify
                    : this.handleSubmit
                }
                style={{ marginTop: '15px' }}>
                <Form.Row>
                  {customDonation && (
                    <>
                      <Form.Group
                        as={Col}
                        lg={6}>
                        <Form.Label className='nibi-label'>
                          {DONATION_TYPE}
                        </Form.Label>
                        <Form.Control
                          as='select'
                          name='donationType'
                          value={donationType}
                          onChange={this.handleInputChange}
                          required>
                          <option value='ONE_TIME_DONATION'>{UNIQUE}</option>
                          <option value='RECURRING_PAYMENT'>{RECURRING}</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        xs={6}>
                        <Form.Label className='nibi-label'>
                          {AMOUNT_TO_DONATE}
                        </Form.Label>
                        <Form.Control
                          type='number'
                          placeholder={AMOUNT_TO_DONATE}
                          name='amount'
                          value={amount}
                          onChange={this.handleInputChange}
                          disabled={!customDonation && isPaymentLink}
                          required
                          min={
                            this.state.currency === 'USD'
                              ? minAmountUSD
                              : minAmountCOP
                          }
                        />
                      </Form.Group>
                    </>
                  )}
                  <Form.Group
                    as={Col}
                    lg={5}>
                    <Form.Label className='nibi-label'>
                      {CELLPHONE_NUMBER}
                    </Form.Label>
                    <InputGroup>
                      <PhoneInput
                        country='co'
                        name='cellPhone'
                        style={{ width: '80%' }}
                        enableSearch={true}
                        value={cellPhone}
                        onChange={(e) => {
                          this.setState({ cellPhone: e });
                          if (e !== this.state.originalPhone) {
                            this.setState({ cellPhoneVerified: false });
                          } else if (this.state.cellPhoneVerifiedOriginal) {
                            this.setState({ cellPhoneVerified: true });
                          }
                        }}
                        isValid={cellPhoneVerified}
                        isInvalid={!cellPhoneVerified}
                        required
                      />
                    </InputGroup>
                  </Form.Group>
                  {!cellPhoneVerified && !cellPhoneVerificationCodeSent && (
                    <Form.Group
                      as={Col}
                      sm={5}>
                      <Form.Label className='nibi-label'>
                        {RECEIVE_CODE}
                      </Form.Label>
                      <Form.Control
                        name='phoneVerificationMethod'
                        as='select'
                        value={phoneVerificationMethod}
                        onChange={this.handleInputChange}>
                        <option value='whatsapp'>WhatsApp</option>
                      </Form.Control>
                    </Form.Group>
                  )}
                  {!cellPhoneVerified && !cellPhoneVerificationCodeSent && (
                    <Col xs={2}>
                      <Form.Group>
                        <button
                          onClick={this.handleVerifyCellPhone}
                          className='nibi-btn'>
                          {VERIFY}
                        </button>
                      </Form.Group>
                    </Col>
                  )}
                  {cellPhoneVerificationCodeSent &&
                    !sendingSmsCode &&
                    !cellPhoneVerified && (
                      <>
                        <Form.Group
                          as={Col}
                          sm={5}>
                          <Form.Label className='profileLabel'>
                            {VERIFICATION_CODE}
                          </Form.Label>
                          <Form.Control
                            type='text'
                            name='smsVerificationCode'
                            value={smsVerificationCode}
                            onChange={this.handleSmsCodeChange}
                            placeholder='- - - -'
                            maxLength={4}
                            required
                          />
                          <p
                            style={{
                              fontSize: '12px',
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                            onClick={this.handleVerifyCellPhone}>
                            {RESEND_CODE}
                          </p>
                        </Form.Group>
                      </>
                    )}
                  {sendingSmsCode && (
                    <Spinner
                      size='md'
                      animation='border'
                      variant='warning'
                    />
                  )}
                  {showSentCodeAlert && (
                    <Alert
                      variant='success'
                      onClose={() =>
                        this.setState({ showSentCodeAlert: false })
                      }
                      dismissible>
                      <Alert.Heading>{CODE_SENT}</Alert.Heading>
                      <p>{ENTER_CODE}</p>
                    </Alert>
                  )}
                  {smsVerificationError && (
                    <Alert
                      variant='danger'
                      onClose={() =>
                        this.setState({ smsVerificationError: '' })
                      }
                      dismissible>
                      <Alert.Heading>{VERIFICATION_ERROR}</Alert.Heading>
                      <p>{smsVerificationError}</p>
                    </Alert>
                  )}
                  {cellPhoneVerified && (
                    <>
                      <Form.Group
                        as={Col}
                        lg={6}
                        xs={6}>
                        <Form.Label className='nibi-label'>
                          {EMAIL_ADDRESS}
                        </Form.Label>
                        <Form.Control
                          name='email'
                          type='text'
                          placeholder={EMAIL_ADDRESS}
                          value={email}
                          onChange={this.handleInputChange}
                          required
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        lg={6}>
                        <Form.Label className='nibi-label'>
                          {FIRST_NAME}
                        </Form.Label>
                        <Form.Control
                          name='cardHolderName'
                          type='text'
                          placeholder={FIRST_NAME}
                          value={cardHolderName}
                          onChange={this.handleInputChange}
                          required
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        lg={6}>
                        <Form.Label className='nibi-label'>
                          {LAST_NAME}
                        </Form.Label>
                        <Form.Control
                          name='cardHolderLastName'
                          type='text'
                          placeholder={LAST_NAME}
                          value={cardHolderLastName}
                          onChange={this.handleInputChange}
                          required
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        lg={4}>
                        <Form.Label className='nibi-label'>
                          {ID_TYPE}
                        </Form.Label>
                        <Form.Control
                          name='docType'
                          as='select'
                          value={docType}
                          onChange={this.handleInputChange}>
                          <option value='CC'>CC</option>
                          <option value='LIC'>LIC</option>
                          <option value='NIT'>NIT</option>
                          {currency === 'USD' && (
                            <>
                              <option value='CE'>CE</option>
                              <option value='PPN'>Pasaporte</option>
                              <option value='DNI'>DNI</option>
                              <option value='SSN'>SSN</option>
                            </>
                          )}
                        </Form.Control>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        lg={8}>
                        <Form.Label className='nibi-label'>
                          Número de documento
                        </Form.Label>
                        <Form.Control
                          name='userDocument'
                          type='text'
                          placeholder='Documento'
                          value={userDocument}
                          onChange={this.handleInputChange}
                          required
                        />
                        <Form.Control.Feedback type='invalid'>
                          Ingresa tu documento.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        controlId='toggleSection'
                        as={Col}
                        xs={12}>
                        <Form.Check
                          type='checkbox'
                          label={'Necesito certificado'}
                          checked={needCertificate}
                          onChange={this.toggleNeedCertificate}
                        />
                      </Form.Group>

                      {needCertificate && (
                        <>
                          <Form.Group
                            as={Col}
                            xs={6}>
                            <Form.Label className='nibi-label'>
                              {CITY} <AddressInfoIcon language={language} />
                            </Form.Label>
                            <Form.Control
                              name='donationCity'
                              type='text'
                              placeholder={CITY}
                              value={donationCity}
                              onChange={this.handleInputChange}
                              required
                            />
                          </Form.Group>
                          {currency === 'USD' && (
                            <Form.Group
                              as={Col}
                              lg={3}
                              xs={3}>
                              <Form.Label className='nibi-label'>
                                {COUNTRY}
                              </Form.Label>
                              <Form.Control
                                name='country'
                                type='text'
                                placeholder={COUNTRY}
                                value={country}
                                onChange={this.handleInputChange}
                                required
                              />
                            </Form.Group>
                          )}
                          <Form.Group
                            as={Col}
                            lg={6}
                            xs={6}>
                            <Form.Label className='nibi-label'>
                              {ADDRESS} <AddressInfoIcon />
                            </Form.Label>
                            <Form.Control
                              name='donationAddress'
                              type='text'
                              placeholder={ADDRESS}
                              value={donationAddress}
                              onChange={this.handleInputChange}
                              required
                            />
                          </Form.Group>
                        </>
                      )}
                      {!customDonation && (
                        <Form.Group
                          as={Col}
                          lg={6}
                          xs={6}>
                          <Form.Label className='nibi-label'>
                            {NUMBER_OF_PLANS}
                          </Form.Label>
                          <Form.Control
                            type='number'
                            name='planAmount'
                            min={1}
                            value={planAmount}
                            onChange={this.handleInputChange}
                            required></Form.Control>
                        </Form.Group>
                      )}
                      {recurringPaymentMethod === 'PSE' && (
                        <>
                          <Col xs={12}>
                            <hr />
                          </Col>
                          <Form.Group
                            as={Col}
                            lg={4}>
                            <Form.Label className='nibi-label'>
                              Tipo de persona
                            </Form.Label>
                            <Form.Control
                              name='userType'
                              as='select'
                              value={userType}
                              onChange={this.handleInputChange}>
                              <option value='0'>Natural</option>
                              <option value='1'>Jurídica</option>
                            </Form.Control>
                          </Form.Group>
                        </>
                      )}
                    </>
                  )}
                </Form.Row>

                {recurringPaymentMethod === 'PSE' && cellPhoneVerified ? (
                  <>
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        lg={6}>
                        <Form.Label className='nibi-label'>
                          {FINANCIAL_INSTITUTION}
                        </Form.Label>
                        {!isLoading ? (
                          <Form.Control
                            as='select'
                            className={
                              institutionSelected === '0' ? 'is-invalid' : ''
                            }
                            name='institutionSelected'
                            value={institutionSelected}
                            onChange={this.handleInputChange}>
                            {financialInstitutions.map((institution) => (
                              <option
                                disabled={institution.bankCode === '0'}
                                hidden={institution.bankCode === '0'}
                                key={institution.bankCode}
                                value={institution.bankCode}>
                                {institution.bankName}
                              </option>
                            ))}
                          </Form.Control>
                        ) : (
                          <>
                            <br />
                            <Spinner
                              animation='grow'
                              variant='warning'
                            />
                          </>
                        )}

                        {formValidated && institutionSelected === '0' && (
                          <div className='text-invalid'>{SELECT_BANK}</div>
                        )}
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        lg={5}>
                        <Form.Label className='nibi-label'>
                          {EMAIL_ADDRESS}
                        </Form.Label>
                        <Form.Control
                          name='pseEmail'
                          type='email'
                          placeholder={EMAIL_ADDRESS}
                          value={pseEmail}
                          onChange={this.handleInputChange}
                          required
                        />
                        <Form.Control.Feedback type='invalid'>
                          {ENTER_VALID_MAIL}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row></Form.Row>
                  </>
                ) : cellPhoneVerified ? (
                  <>
                    <hr />
                    <h5>{PAYMENT_INFO}</h5>
                    <hr />
                    <Form.Row>
                      {paymentSources.length > 0 && (
                        <Form.Group
                          as={Col}
                          lg={6}>
                          <Form.Label className='nibi-label'>
                            {USE_SAVED_CARD}
                          </Form.Label>
                          <Form.Control
                            as='select'
                            name='savedPaymentSource'
                            value={savedPaymentSource}
                            onChange={this.handleInputChange}>
                            <option value='Ninguna'>{NONE}</option>
                            {paymentSources.map((el, index) => (
                              <option
                                key={index}
                                value={el.token}>
                                **** **** **** {el.mask.slice(-4)}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      )}

                      <Form.Group
                        as={Col}
                        lg={6}
                        xs={6}>
                        <Form.Label className='nibi-label'>
                          {FIRST_NAME}
                        </Form.Label>
                        <Form.Control
                          name='cardHolderName'
                          type='text'
                          placeholder={SAME_AS_CARD}
                          value={cardHolderName}
                          onChange={this.handleInputChange}
                          required
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        lg={6}
                        xs={6}>
                        <Form.Label className='nibi-label'>
                          {LAST_NAME}
                        </Form.Label>
                        <Form.Control
                          name='cardHolderLastName'
                          type='text'
                          placeholder={SAME_AS_CARD}
                          value={cardHolderLastName}
                          onChange={this.handleInputChange}
                          required
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        lg={4}
                        xs={5}>
                        <Form.Label className='nibi-label'>
                          {ID_TYPE}
                        </Form.Label>
                        <Form.Control
                          name='docType'
                          as='select'
                          value={docType}
                          onChange={this.handleInputChange}>
                          <option value='CC'>CC</option>
                          <option value='LIC'>LIC</option>
                          <option value='NIT'>NIT</option>
                          {currency === 'USD' && (
                            <>
                              <option value='CE'>CE</option>
                              <option value='PPN'>Pasaporte</option>
                              <option value='DNI'>DNI</option>
                              <option value='SSN'>SSN</option>
                            </>
                          )}
                        </Form.Control>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        lg={4}
                        xs={7}>
                        <Form.Label className='nibi-label'>
                          {ID_NUMBER}
                        </Form.Label>
                        <Form.Control
                          name='userDocument'
                          type='text'
                          placeholder={ID_NUMBER}
                          value={userDocument}
                          onChange={this.handleInputChange}
                          required
                        />
                        <Form.Control.Feedback type='invalid'>
                          Ingresa tu documento.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>

                    {savedPaymentSource === 'Ninguna' && (
                      <Form.Row>
                        <Form.Group
                          as={Col}
                          lg={4}>
                          <Form.Label className='nibi-label'>
                            {CARD_NUMBER}
                          </Form.Label>
                          <Form.Control
                            name='cardNumber'
                            type='number'
                            placeholder='0000 0000 0000 0000'
                            value={cardNumber}
                            onChange={this.handleInputChange}
                            required
                          />
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          lg={4}
                          xs={8}>
                          <Form.Label className='nibi-label'>
                            {EXPIRATION_DATE}
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              name='cardExpirationMonth'
                              type='number'
                              placeholder='MM'
                              min={1}
                              max={12}
                              value={cardExpirationMonth}
                              onChange={this.handleInputChange}
                              required
                            />
                            <Form.Control
                              name='cardExpirationYear'
                              type='number'
                              min={20}
                              placeholder='YY'
                              value={cardExpirationYear}
                              onChange={this.handleInputChange}
                              required
                            />
                          </InputGroup>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          lg={4}
                          xs={4}>
                          <Form.Label className='nibi-label'>CVV</Form.Label>
                          <Form.Control
                            name='cvv'
                            type='number'
                            value={cvv}
                            placeholder={SECURITY_CODE}
                            onChange={this.handleInputChange}
                            required
                          />
                        </Form.Group>
                      </Form.Row>
                    )}
                  </>
                ) : (
                  <></>
                )}

                {nibifyProgram && (
                  <Form.Row>
                    <Form.Group>
                      <div className='custom-control custom-checkbox'>
                        <input
                          type='checkbox'
                          className='custom-control-input'
                          id='nibifyCheckbox2'
                          onChange={() => {
                            const nibify = !nibifyCb;
                            this.setState({ nibifyCb: nibify });

                            if (recurringPaymentMethod === 'PSE') {
                              this.setState({
                                recurringPaymentMethod: 'CARD',
                              });
                            }
                          }}
                          checked={nibifyCb}
                        />
                        <label
                          className='custom-control-label'
                          htmlFor='nibifyCheckbox2'
                          style={{
                            color: '#808080',
                            fontSize: '0.95rem',
                          }}>
                          <img
                            style={{ width: '50px' }}
                            src={nibifyLogo}
                            alt='Logo Nibify'
                          />{' '}
                          ¿Quieres recibir productos sostenibles por hacer tu
                          donación mensual? No tiene ningún costo y te llegan
                          cada 2 meses.
                        </label>
                      </div>
                    </Form.Group>
                  </Form.Row>
                )}
                {nibifyCb && (
                  <Form.Row>
                    <Form.Group
                      as={Col}
                      xs={12}>
                      <Form.Label className='nibi-label'>Género</Form.Label>
                      <Form.Control
                        as='select'
                        value={this.state.userGender}
                        onChange={(event) =>
                          this.setState({ userGender: event.target.value })
                        }>
                        <option value='M'>Masculino</option>
                        <option value='F'>Femenino</option>
                        <option value='O'>Otro</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      md={6}>
                      <Form.Label className='nibi-label'>Ciudad</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Ciudad'
                        value={this.state.userCity}
                        onChange={(event) =>
                          this.setState({ userCity: event.target.value })
                        }
                        required
                      />
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      md={6}>
                      <Form.Label className='nibi-label'>Dirección</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Dirección'
                        value={this.state.userAddress}
                        onChange={(event) =>
                          this.setState({
                            userAddress: event.target.value,
                          })
                        }
                        required
                      />
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      md={6}>
                      <Form.Label className='nibi-label'>
                        Detalles adicionales
                      </Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Detalles adicionales'
                        value={this.state.userAdditionalDetails}
                        onChange={(event) =>
                          this.setState({
                            userAdditionalDetails: event.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Form.Row>
                )}

                <Form.Row>
                  {recurringPaymentMethod === 'CARD' &&
                    savedPaymentSource === 'Ninguna' &&
                    cellPhoneVerified && (
                      <Form.Group
                        as={Col}
                        xs={12}>
                        <div className='custom-control custom-checkbox'>
                          <input
                            type='checkbox'
                            className='custom-control-input'
                            id='permalinkCheckBox-2'
                            onChange={(event) =>
                              this.setState({
                                saveCard: event.target.checked,
                              })
                            }
                            checked={saveCard}
                          />
                          <label
                            className='custom-control-label'
                            htmlFor='permalinkCheckBox-2'
                            style={{
                              color: '#808080',
                              fontSize: '0.95rem',
                            }}>
                            {SAVE_CARD}
                          </label>
                        </div>
                      </Form.Group>
                    )}
                  {showContactMethod && cellPhoneVerified && (
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        lg={6}>
                        <Form.Label className='nibi-label'>
                          {RECEIVE_CONTENT}
                        </Form.Label>
                        <Form.Control
                          as='select'
                          name='contactMethod'
                          value={contactMethod}
                          onChange={this.handleInputChange}>
                          <option value='WHATS_APP'>WhatsApp</option>
                          <option value='CELL_PHONE'>
                            {language === 'ES' ? 'Llamada' : 'Phone call'}
                          </option>
                          <option value='EMAIL'>
                            {language === 'ES' ? 'Correo' : 'Email'}
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>
                  )}
                  {cellPhoneVerified && (
                    <Form.Group
                      as={Col}
                      xs={12}
                      style={{ marginBottom: 0, marginTop: '15px' }}>
                      <div className='custom-control custom-checkbox'>
                        <input
                          type='checkbox'
                          className='custom-control-input'
                          id='permalinkCheckBox'
                          required
                        />
                        <label
                          className='custom-control-label'
                          htmlFor='permalinkCheckBox'
                          style={{
                            color: '#808080',
                            fontSize: '0.95rem',
                          }}>
                          {ACCEPT_THE}{' '}
                          <a
                            href={permalink}
                            target='_blank'
                            rel='noopener noreferrer'>
                            {TERMS_AND_CONDITIONS}
                          </a>
                        </label>
                      </div>
                    </Form.Group>
                  )}
                  <button
                    className='nibi-btn'
                    type='submit'>
                    {CONTINUE}
                  </button>
                </Form.Row>
              </Form>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

const RecurringPaymentTransactionComponent = connect(
  select,
  mapDispatchToProps
)(ConnectedRecurringPaymentTransactionComponent);

export default withRouter(RecurringPaymentTransactionComponent);
