import React, { Component } from "react";
import { Form, Col, InputGroup, Row, Spinner } from "react-bootstrap";
import {
  wompiBaseUrl,
  wompiPublicKey,
  redirectBaseUrl,
  epaycoBaseUrl,
  epaycoIsTest,
  epaycoPKey,
  epaycoPrivateKey,
  epaycoPublicKey,
  pciVaultUrl,
  pciVaultPassphrase,
  pciVaultPassword,
  pciVaultUser,
} from "../utils/paymentKeys";
import AppSpinner from "../Extras/AppSpinner";
import { connect } from "react-redux";
import queryString from "query-string";
import nibifyLogo from "../img/nibify_logo.png";
import { setUpdateNotifications } from "../Redux/modules/chat";
import { formatMoney } from "../utils/formatMoney";
import { withRouter } from "react-router";
import AddressInfoIcon from "../Extras/AddressInfoIcon";
import "./styles/styles.scss";
import "../PsePayment/styles/landingStyles.scss";
import "../PsePayment/styles/styles.scss";
import { NIBI_URL } from "../utils/baseUrl";

const select = (state) => {
  return {
    user: state.auth.user,
    token: state.auth.token,
    campaign: state.campaign.campaign,
    isFoundation: state.auth.isFoundation,
    isEnterprise: state.auth.isEnterprise,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setUpdateNotifications: (update) =>
      dispatch(setUpdateNotifications(update)),
  };
}

class ConnectedTresLetrasTransactionComponent extends Component {
  constructor(props) {
    super(props);

    const { selectedPlan, donation, isPaymentLink, planQty, items } = props;
    const user = isPaymentLink ? props.paymentUser : props.user;

    let amount = 0;
    let itemsNumber = 0;

    items.map((item) => {
      amount += item.price * item.amount;
      itemsNumber += item.amount;
    });

    this.state = {
      cardHolderName: "",
      cardHolderLastName: "",
      cardNumber: "",
      cvv: "",
      cardExpirationMonth: "",
      cardExpirationYear: "",
      cardToken: "",
      permalink: "",
      amount,
      itemsNumber,
      pseEmail: user && user.email ? user.email : "",
      referredBy: user.referredBy || "",
      isLoading: false,
      formValidated: false,
      creatingTransaction: false,
      acceptanceToken: "",
      transactionStatus: "",
      recurringPaymentExists: false,
      docType: user && user.identificationType ? user.identificationType : "CC",
      userDocument: user && user.identification ? user.identification : "",
      cellPhone: user && user.cellPhone ? user.cellPhone : "",
      contactMethod: "WHATS_APP",
      userType: "0",
      institutionSelected: "0",
      financialInstitutions: [],
      recurringPaymentMethod: "CARD",
      benefits: [],
      saveCard: false,
      savedPaymentSource: "Ninguna",
      donationType: "ONE_TIME_DONATION",
      nibifyCb: props.nibifyCb || false,
      userGender: "M",
      userCity: "",
      userAddress: "",
      userAdditionalDetails: "",
      discountCode: "",
      validatingCode: false,
      codeSent: false,
      codeValidated: false,
      amountDiscounted: 0,
      donationCity: user && user.donationCity ? user.donationCity : "",
      donationAddress: user && user.donationAddress ? user.donationAddress : "",
      planAmount: 1,
      originalAmount: amount,
      extras1: "",
      extras2: "",
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.tokenizeCard = this.tokenizeCard.bind(this);
    this.createPayment = this.createPayment.bind(this);
    this.createItemPayment = this.createItemPayment.bind(this);
    this.createTransactionEpayco = this.createTransactionEpayco.bind(this);
    this.createItemPayment = this.createItemPayment.bind(this);
    //this.createTransaction = this.createTransaction.bind(this);
    this.createPlanTransaction = this.createPlanTransaction.bind(this);
    this.createEpaycoTransaction = this.createEpaycoTransaction.bind(this);
    this.checkEpaycoTransaction = this.checkEpaycoTransaction.bind(this);
    this.applyDiscountCode = this.applyDiscountCode.bind(this);
  }

  componentDidUpdate(prevProps) {
    const user = this.props.isPaymentLink
      ? this.props.paymentUser
      : this.props.user;
    const prevUser = prevProps.isPaymentLink
      ? prevProps.paymentUser
      : prevProps.user;

    if (user && prevUser && user.identification !== prevUser.identification) {
      this.setState({ userDocument: user.identification });
    }
    if (user && prevUser && user.cellPhone !== prevUser.cellPhone) {
      this.setState({ cellPhone: user.cellPhone });
    }
    if (this.props.nibifyCb !== prevProps.nibifyCb) {
      this.setState({ nibifyCb: this.props.nibifyCb || false });
    }
  }

  handleInputChange(e) {
    if (
      (e.target.name === "cardExpirationMonth" ||
        e.target.name === "cardExpirationYear") &&
      e.target.value.toString().length > 2
    )
      return;
    if (
      e.target.name === "recurringPaymentMethod" &&
      e.target.value === "PSE" &&
      this.state.docType !== "CC" &&
      this.state.docType !== "NIT"
    ) {
      this.setState({ docType: "CC" });
    }

    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === "planAmount") {
      this.setState({ amount: this.state.originalAmount * e.target.value });
    }
  }

  async createItemPayment(item) {
    let recurringPaymentId = "";
    let selectedPlan = {};
    if (
      item.name === "SuscripciÃ³n Parceros 1 mes" ||
      item.name === "Suscripción Parceros 1 mes"
    ) {
      recurringPaymentId = "629915360f404ce42134ad17";
    } else if (
      item.name === "SuscripciÃ³n Parceros 3 meses" ||
      item.name === "Suscripción Parceros 3 meses"
    ) {
      recurringPaymentId = "629917cd0f404ce42134ad68";
    } else if (
      item.name === "SuscripciÃ³n Parceros 6 meses" ||
      item.name === "Suscripción Parceros 6 meses"
    ) {
      recurringPaymentId = "629915360f404ce42134ad17";
    } else if (
      item.name === "Suscripción Parceros 12 meses" || item.name === "Suscripción Parceros 12 meses"
    ) {
      recurringPaymentId = "629918070f404ce42134ad72";
    }
    if (recurringPaymentId) {
      const planResponse = await fetch("/recurringPayments/getCustomPlan", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id: recurringPaymentId,
          foundationId: this.props.foundationId,
        }),
      });
      const planData = await planResponse.json();
      if (planData.success) {
        selectedPlan = planData.plan;
      }
      for (var i = 0; i < item.amount; i++) {
        await this.createPlanTransaction(selectedPlan, item.price);
      }
    }
  }

  async createEpaycoTransaction() {
    const user = this.props.isPaymentLink
      ? this.props.paymentUser
      : this.props.user;
    const { email } = user;
    const { amount, saveCard, cardNumber, userAddress, cellPhone } = this.state;
    const { foundationName } = this.props;

    const token_apify = await this.getTokenApify();

    const body = JSON.stringify({
      value: `${amount}`,
      docType: this.state.docType,
      docNumber: this.state.userDocument,
      name: this.state.cardHolderName,
      lastName: this.state.cardHolderLastName,
      email: email,
      cellPhone: this.state.cellPhone,
      phone: this.state.cellPhone,
      cardNumber: this.state.cardNumber,
      cardExpYear: "20" + this.state.cardExpirationYear,
      cardExpMonth: this.state.cardExpirationMonth,
      cardCvc: this.state.cvv,
      dues: "1",
      redirect_url: `${redirectBaseUrl}/pago/resultado`,
      testMode: epaycoIsTest,
      extra1: this.state.extras1,
      extra2: this.state.extras2,
    });

    const transactionResponse = await fetch(
      `${epaycoBaseUrl}/payment/process`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token_apify}`,
        },
        body,
      }
    );

    const transactionData = await transactionResponse.json();
    if (transactionData.success) {
      if (transactionData.data.transaction.data.estado === "Aceptada") {
        //Send email
        await fetch("/email/sendLetrasPaymentConfirmation", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            items: this.props.items,
            user,
            epaycoReference: transactionData.data.transaction.data.ref_payco,
            amount,
            userAddress,
            cellPhone,
          }),
        });
        if (saveCard) {
          if (
            transactionData.data.tokenCard.cardTokenId != "N/A" &&
            transactionData.data.tokenCard.cardTokenId != ""
          ) {
            await fetch("/payments/save_epayco_card", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                token: transactionData.data.tokenCard.cardTokenId,
                userId: user._id,
                cardNumber: cardNumber.substring(cardNumber.length - 4),
              }),
            });
          }
        }
        await fetch("/payments/transaction/save/payment_source", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            cardToken: transactionData.data.tokenCard.cardTokenId,
            references: this.state.extras1,
            userIdentificationType: this.state.docType,
            userIdentification: this.state.userDocument,
            cardHolderName: this.state.cardHolderName,
            cardHolderLastName: this.state.cardHolderLastName,
            userEmail: email,
            cellPhone: this.state.cellPhone,
          }),
        });
        return transactionData.data.transaction.data.ref_payco;
      } else {
        //Enviar log a BD
        await fetch("/nibiLogs/create", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            content: transactionData,
            userId: user._id,
            context: "Transacción no aceptada",
          }),
        });
        this.setState({
          creatingTransaction: false,
          transactionStatus: transactionData.textResponse,
        });
      }
    } else {
      this.setState({
        creatingTransaction: false,
        transactionStatus: transactionData.textResponse,
      });
      //Enviar log a BD
      await fetch("/nibiLogs/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          content: transactionData,
          userId: user._id,
          context: "Transacción fallida",
        }),
      });
    }
  }

  async createPlanTransaction(selectedPlan, amount) {
    const { foundationId, foundationName, donationDay } = this.props;
    const user = this.props.isPaymentLink
      ? this.props.paymentUser
      : this.props.user;

    const { email, firstName, lastName } = user;

    const {
      recurringPaymentMethod,
      acceptanceToken,
      cardNumber,
      saveCard,
      savedPaymentSource,
      docType,
      userDocument,
      cellPhone,
      donationType,
      discountCode,
      cardHolderName,
      cardHolderLastName,
      extras1,
      extras2,
    } = this.state;

    if (savedPaymentSource !== "Ninguna") {
      let recurringPaymentData;

      const recurringPaymentResponse = await fetch(
        "/recurringPayments/create",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            amount,
            recurringPaymentMethod,
            type: "FLEXIBLE",
            savedPaymentSource,
            foundationId,
            foundationName,
            userFirstName: firstName,
            userLastName: lastName,
            userId: user._id,
            userEmail: email,
            userPhone: cellPhone,
            userDoc: userDocument,
            docType,
            selectedPlan,
            customer_email: user.email,
            userGender: this.state.userGender,
            userCity: this.state.userCity,
            userAddress: this.state.userAddress,
            userAdditionalDetails: this.state.userAdditionalDetails,
            discountCode,
            donationDay,
          }),
        }
      );

      recurringPaymentData = await recurringPaymentResponse.json();

      if (!recurringPaymentData.success) {
        this.setState({
          creatingTransaction: false,
          transactionStatus:
            recurringPaymentData.message || "Error al procesar el pago.",
        });
        //Enviar log a BD
        await fetch("/nibiLogs/create", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            content: recurringPaymentData,
            userId: user._id,
            context: "Error en /recurringPayments/create",
          }),
        });
        return;
      }

      const reference = await this.createPayment(
        recurringPaymentData.recurringPayment._id,
        amount
      );

      if (extras1) {
        this.setState({ extras1: extras1 + "," + reference });
      } else {
        this.setState({ extras1: reference });
      }
      if (extras2) {
        this.setState({
          extras2: extras2 + "," + `Donación a: ${foundationName}`,
        });
      } else {
        this.setState({ extras2: `Donación a: ${foundationName}` });
      }
    } else {
      let recurringPaymentData;

      if (saveCard) {
        const tokenizeCardResponse = await fetch(
          `${pciVaultUrl}/vault?user=NIBI&key=${pciVaultPassphrase}`,
          {
            method: "POST",
            body: JSON.stringify({
              card_expiry: `${this.state.cardExpirationMonth}-20${this.state.cardExpirationYear}`,
              card_holder: this.state.cardHolderName,
              card_number: this.state.cardNumber,
              cvv: this.state.cvv,
            }),
            headers: {
              Authorization:
                "Basic " +
                new Buffer.from(pciVaultUser + ":" + pciVaultPassword).toString(
                  "base64"
                ),
              "Content-Type": "application/json",
            },
          }
        );

        const tokenCardData = await tokenizeCardResponse.json();
        if (tokenCardData.token) {
          await fetch("/payments/save_card", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              token: tokenCardData.token,
              userId: user._id,
              cardNumber: cardNumber.substring(cardNumber.length - 4),
              customer_email: user.email,
            }),
          });
        }
      }

      const tokenizeCardResponse = await fetch(
        `${pciVaultUrl}/vault?user=NIBI&key=${pciVaultPassphrase}`,
        {
          method: "POST",
          body: JSON.stringify({
            card_expiry: `${this.state.cardExpirationMonth}-20${this.state.cardExpirationYear}`,
            card_holder:
              this.state.cardHolderName + " " + this.state.cardHolderLastName,
            card_number: this.state.cardNumber,
            cvv: this.state.cvv,
          }),
          headers: {
            Authorization:
              "Basic " +
              new Buffer.from(pciVaultUser + ":" + pciVaultPassword).toString(
                "base64"
              ),
            "Content-Type": "application/json",
          },
        }
      );

      const tokenCardData = await tokenizeCardResponse.json();
      if (tokenCardData.token) {
        const recurringPaymentResponse = await fetch(
          "/recurringPayments/create",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              type: "FLEXIBLE",
              amount,
              foundationId,
              foundationName,
              userFirstName: firstName,
              userLastName: lastName,
              userId: user._id,
              userEmail: email,
              recurringPaymentMethod,
              userPhone: cellPhone,
              cardNumber: saveCard
                ? cardNumber.substring(cardNumber.length - 4)
                : "",
              cardBrand: "",
              userDoc: userDocument,
              docType,
              cellPhone,
              selectedPlan,
              customer_email: user.email,
              acceptance_token: acceptanceToken,
              nibifyCb: this.state.nibifyCb,
              userGender: this.state.userGender,
              userCity: this.state.userCity,
              userAddress: this.state.userAddress,
              userAdditionalDetails: this.state.userAdditionalDetails,
              discountCode,
              donationDay,
              creditCardToken: tokenCardData.token,
            }),
          }
        );

        recurringPaymentData = await recurringPaymentResponse.json();

        if (!recurringPaymentData.success) {
          this.setState({
            creatingTransaction: false,
            transactionStatus:
              recurringPaymentData.message || "Error al procesar el pago.",
          });
          //Enviar log a BD
          await fetch("/nibiLogs/create", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              content: recurringPaymentData,
              userId: user._id,
              context: "Error en /recurringPayments/create",
            }),
          });
          return;
        }
      } else {
        //Enviar log a BD
        await fetch("/nibiLogs/create", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            content: tokenCardData,
            userId: user._id,
            context: "Error al tokenizar tarjeta PCI",
          }),
        });
      }

      const reference = await this.createPayment(
        recurringPaymentData.recurringPayment._id,
        amount
      );

      if (extras1) {
        this.setState({ extras1: extras1 + "," + reference });
      } else {
        this.setState({ extras1: reference });
      }
      if (extras2) {
        this.setState({
          extras2: extras2 + "," + `Donación a: ${foundationName}`,
        });
      } else {
        this.setState({ extras2: `Donación a: ${foundationName}` });
      }
    }
  }

  async createTransactionEpayco() {
    const {
      programId,
      programName,
      foundationId,
      foundationName,
      customDonation,
      selectedPlan,
      planQty,
      donationDay,
    } = this.props;
    const user = this.props.isPaymentLink
      ? this.props.paymentUser
      : this.props.user;

    const { email, firstName, lastName, businessName } = user;

    const {
      recurringPaymentMethod,
      acceptanceToken,
      amount,
      referredBy,
      pseEmail,
      cardNumber,
      saveCard,
      savedPaymentSource,
      docType,
      userDocument,
      cellPhone,
      donationType,
      discountCode,
      cardHolderName,
      cardHolderLastName,
    } = this.state;

    const isRecPayment =
      !customDonation ||
      (customDonation && donationType === "RECURRING_PAYMENT");

    if (recurringPaymentMethod === "CARD") {
      if (savedPaymentSource !== "Ninguna") {
        let recurringPaymentData;

        if (isRecPayment) {
          const recurringPaymentResponse = await fetch(
            "/recurringPayments/create",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                amount,
                referredBy,
                recurringPaymentMethod,
                type: "FLEXIBLE",
                savedPaymentSource,
                foundationId,
                foundationName,
                programName,
                programId,
                userFirstName: firstName,
                userLastName: lastName,
                userBusinessName: businessName,
                userId: user._id,
                userEmail: email,
                userPhone: cellPhone,
                userDoc: userDocument,
                docType,
                selectedPlan,
                customer_email: user.email,
                nibifyCb: this.state.nibifyCb,
                userGender: this.state.userGender,
                userCity: this.state.userCity,
                userAddress: this.state.userAddress,
                userAdditionalDetails: this.state.userAdditionalDetails,
                discountCode,
                planQty,
                donationDay,
              }),
            }
          );

          recurringPaymentData = await recurringPaymentResponse.json();

          if (!recurringPaymentData.success) {
            this.setState({
              creatingTransaction: false,
              transactionStatus:
                recurringPaymentData.message || "Error al procesar el pago.",
            });
            //Enviar log a BD
            await fetch("/nibiLogs/create", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                content: recurringPaymentData,
                userId: user._id,
                context: "Error en /recurringPayments/create",
              }),
            });
            return;
          }
        }
        const reference = isRecPayment
          ? await this.createPayment(recurringPaymentData.recurringPayment._id)
          : await this.createPayment();
        const transactionResponse = await fetch(
          "/payments/transaction/create/payment_source",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userId: user._id,
              amount,
              savedPaymentSource,
              userEmail: email,
              reference,
              discountCode,
              paymentType: "RECURRING",
              cardHolderName: cardHolderName,
              cardHolderLastName: cardHolderLastName,
              cellPhone: cellPhone,
              foundationName: foundationName,
              userIdentification: userDocument,
              userIdentificationType: docType,
            }),
          }
        );
        const transactionData = await transactionResponse.json();
        if (!transactionData.success) {
          //Enviar log a BD
          await fetch("/nibiLogs/create", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              content: transactionData,
              userId: user._id,
              context: "Error en /payments/transaction/create/payment_source",
            }),
          });
          return this.setState({
            creatingTransaction: false,
            transactionStatus: "Error al procesar el pago.",
          });
        }

        return transactionData.transactionId;
      } else {
        let recurringPaymentData;

        if (saveCard) {
          const tokenizeCardResponse = await fetch(
            `${pciVaultUrl}/vault?user=NIBI&key=${pciVaultPassphrase}`,
            {
              method: "POST",
              body: JSON.stringify({
                card_expiry: `${this.state.cardExpirationMonth}-20${this.state.cardExpirationYear}`,
                card_holder: this.state.cardHolderName,
                card_number: this.state.cardNumber,
                cvv: this.state.cvv,
              }),
              headers: {
                Authorization:
                  "Basic " +
                  new Buffer.from(
                    pciVaultUser + ":" + pciVaultPassword
                  ).toString("base64"),
                "Content-Type": "application/json",
              },
            }
          );

          const tokenCardData = await tokenizeCardResponse.json();
          if (tokenCardData.token) {
            await fetch("/payments/save_card", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                token: tokenCardData.token,
                userId: user._id,
                cardNumber: cardNumber.substring(cardNumber.length - 4),
                customer_email: user.email,
              }),
            });
          }
        }

        if (isRecPayment) {
          const tokenizeCardResponse = await fetch(
            `${pciVaultUrl}/vault?user=NIBI&key=${pciVaultPassphrase}`,
            {
              method: "POST",
              body: JSON.stringify({
                card_expiry: `${this.state.cardExpirationMonth}-20${this.state.cardExpirationYear}`,
                card_holder:
                  this.state.cardHolderName +
                  " " +
                  this.state.cardHolderLastName,
                card_number: this.state.cardNumber,
                cvv: this.state.cvv,
              }),
              headers: {
                Authorization:
                  "Basic " +
                  new Buffer.from(
                    pciVaultUser + ":" + pciVaultPassword
                  ).toString("base64"),
                "Content-Type": "application/json",
              },
            }
          );

          const tokenCardData = await tokenizeCardResponse.json();
          if (tokenCardData.token) {
            const recurringPaymentResponse = await fetch(
              "/recurringPayments/create",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  type: "FLEXIBLE",
                  amount,
                  foundationId,
                  foundationName,
                  programName,
                  programId,
                  userFirstName: firstName,
                  userLastName: lastName,
                  userBusinessName: businessName,
                  userId: user._id,
                  userEmail: email,
                  recurringPaymentMethod,
                  userPhone: cellPhone,
                  cardNumber: saveCard
                    ? cardNumber.substring(cardNumber.length - 4)
                    : "",
                  cardBrand: "",
                  referredBy,
                  userDoc: userDocument,
                  docType,
                  cellPhone,
                  selectedPlan,
                  customer_email: user.email,
                  acceptance_token: acceptanceToken,
                  nibifyCb: this.state.nibifyCb,
                  userGender: this.state.userGender,
                  userCity: this.state.userCity,
                  userAddress: this.state.userAddress,
                  userAdditionalDetails: this.state.userAdditionalDetails,
                  discountCode,
                  planQty,
                  donationDay,
                  creditCardToken: tokenCardData.token,
                }),
              }
            );

            recurringPaymentData = await recurringPaymentResponse.json();

            if (!recurringPaymentData.success) {
              this.setState({
                creatingTransaction: false,
                transactionStatus:
                  recurringPaymentData.message || "Error al procesar el pago.",
              });
              //Enviar log a BD
              await fetch("/nibiLogs/create", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  content: recurringPaymentData,
                  userId: user._id,
                  context: "Error en /recurringPayments/create",
                }),
              });
              return;
            }
          } else {
            //Enviar log a BD
            await fetch("/nibiLogs/create", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                content: tokenCardData,
                userId: user._id,
                context: "Error al tokenizar tarjeta PCI",
              }),
            });
          }
        }

        const reference = isRecPayment
          ? await this.createPayment(recurringPaymentData.recurringPayment._id)
          : await this.createPayment();

        if (isRecPayment) {
          const transactionResponse = await fetch(
            "/payments/transaction/create/payment_source",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                userId: user._id,
                amount,
                savedPaymentSource:
                  recurringPaymentData.recurringPayment.creditCardToken,
                userEmail: user.email,
                reference,
                discountCode,
                paymentType: "RECURRING",
                cardHolderName: cardHolderName,
                cardHolderLastName: cardHolderLastName,
                cellPhone: cellPhone,
                foundationName: foundationName,
                userIdentification: userDocument,
                userIdentificationType: docType,
              }),
            }
          );

          const transactionData = await transactionResponse.json();

          if (!transactionData.success) {
            this.setState({
              creatingTransaction: false,
              transactionStatus: "Error al procesar el pago.",
            });
            //Enviar log a BD
            await fetch("/nibiLogs/create", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                content: transactionData,
                userId: user._id,
                context: "Error en /payments/transaction/create/payment_source",
              }),
            });
            return;
          }

          return transactionData.transactionId;
        } else {
          const transactionId = await this.createEpaycoTransaction(reference);
          return transactionId;
        }
      }
    } else {
      const {
        institutionSelected,
        userDocument,
        userType,
        docType,
        cellPhone,
        amount,
        codeValidated,
        amountDiscounted,
      } = this.state;

      let recurringPaymentData;

      if (isRecPayment) {
        const recurringPaymentResponse = await fetch(
          "/recurringPayments/create",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              type: "FLEXIBLE",
              amount,
              foundationId,
              foundationName,
              programName,
              programId,
              userFirstName: firstName,
              userLastName: lastName,
              userBusinessName: businessName,
              userId: user._id,
              userEmail: email,
              pseEmail,
              recurringPaymentMethod,
              referredBy,
              docType,
              userType,
              selectedPlan,
              userDoc: userDocument,
              userPhone: cellPhone,
              financialInstitution: institutionSelected,
              customer_email: user.email,
              discountCode,
              planQty,
              donationDay,
            }),
          }
        );

        recurringPaymentData = await recurringPaymentResponse.json();

        if (!recurringPaymentData.success) {
          this.setState({
            creatingTransaction: false,
            transactionStatus:
              recurringPaymentData.message || "Error al procesar el pago.",
          });
          //Enviar log a BD
          await fetch("/nibiLogs/create", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              content: recurringPaymentData,
              userId: user._id,
              context: "Error en /recurringPayments/create",
            }),
          });
          return;
        }
      }

      const reference = isRecPayment
        ? await this.createPayment(recurringPaymentData.recurringPayment._id)
        : await this.createPayment();
      const ipResponse = await fetch("/payments/ip_address", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const ipData = await ipResponse.json();
      const ip = ipData.ip;
      const body = JSON.stringify({
        bank: institutionSelected,
        value: `${codeValidated ? amount - amountDiscounted : amount}`,
        docType,
        typePerson: `${docType === "NIT" ? 1 : 0}`,
        docNumber: userDocument,
        name: firstName + lastName,
        email,
        cellPhone: cellPhone,
        urlResponse: `${redirectBaseUrl}/paymentResult/${reference}`,
        ip,
        urlConfirmation: `${NIBI_URL}payments/transaction/events/epayco`,
        methodConfirmation: "GET",
        extra1: reference,
        extra2: `Donación a: ${foundationName}`,
      });
      const token_apify = await this.getTokenApify();

      const transactionResponse = await fetch(
        `${epaycoBaseUrl}/payment/process/pse`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token_apify}`,
          },
          body,
        }
      );

      const transactionData = await transactionResponse.json();
      if (!transactionData.success) {
        this.setState({
          creatingTransaction: false,
          transactionStatus: "Error al procesar el pago.",
        });
        //Enviar log a BD
        await fetch("/nibiLogs/create", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            content: transactionData,
            userId: user._id,
            context: "Error en transacción por PSE",
          }),
        });
        return;
      }
      window.open(transactionData.data.urlbanco, "_self");

      const transactionId = transactionData.data.id;
      return transactionId;
    }
  }

  async getTokenApify() {
    const tokenApifyResponse = await fetch(`${epaycoBaseUrl}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Basic " +
          new Buffer.from(epaycoPublicKey + ":" + epaycoPrivateKey).toString(
            "base64"
          ),
      },
    });

    const tokenApifyData = await tokenApifyResponse.json();
    return tokenApifyData.token;
  }

  async tokenizeCard() {
    const {
      cardNumber,
      cvv,
      cardHolderName,
      cardHolderLastName,
      cardExpirationMonth,
      cardExpirationYear,
    } = this.state;

    const tokenizeCardResponse = await fetch(`${wompiBaseUrl}/tokens/cards`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${wompiPublicKey}`,
      },
      body: JSON.stringify({
        number: `${cardNumber}`,
        cvc: `${cvv}`,
        exp_month: `${cardExpirationMonth}`,
        exp_year: `${cardExpirationYear}`,
        card_holder: cardHolderName + " " + cardHolderLastName,
      }),
    });

    const tokenCardData = await tokenizeCardResponse.json();

    if (
      tokenizeCardResponse.status === 201 &&
      tokenCardData.status === "CREATED"
    )
      return {
        success: true,
        token: tokenCardData.data.id,
        brand: tokenCardData.data.brand,
      };
    else if (tokenizeCardResponse.status === 422)
      return { success: false, message: "Datos de la tarjeta inválidos." };
    else return { success: false, message: "Error al procesar la tarjeta." };
  }

  async createPayment(recurringPaymentId, amount) {
    const {
      recurringPaymentMethod,
      docType,
      userDocument,
      cellPhone,
      contactMethod,
      donationType,
      discountCode,
      donationCity,
      donationAddress,
    } = this.state;
    const {
      token,
      programId,
      foundationId,
      campaign,
      customDonation,
      isPaymentLink,
    } = this.props;
    const user = isPaymentLink ? this.props.paymentUser : this.props.user;
    const showContactMethod = user && !user.contactMethod;
    const isRecPayment =
      !customDonation ||
      (customDonation && donationType === "RECURRING_PAYMENT");

    const paymentResponse = await fetch("/payments/payment_gateway/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        programId,
        foundationId,
        amount,
        paymentMethod: recurringPaymentMethod,
        paymentType: isRecPayment ? "RECURRING_PAYMENT" : donationType,
        recurringPaymentType: "FLEXIBLE",
        recurringPaymentId,
        userIdentification: userDocument,
        userIdentificationType: docType,
        userCity: donationCity,
        userAddress: donationAddress,
        cellPhone,
        contactMethod: showContactMethod ? contactMethod : "",
        campaign,
        discountCode,
        isPaymentLink,
        userId: user._id,
      }),
    });

    const paymentData = await paymentResponse.json();
    const { reference } = paymentData;

    return reference;
  }

  async checkEpaycoTransaction(transactionId) {
    const token_apify = await this.getTokenApify();

    const transactionResponse = await fetch(
      `${epaycoBaseUrl}/payment/transaction`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token_apify}`,
        },
        body: JSON.stringify({
          referencePayco: transactionId,
        }),
      }
    );
    const transaction = await transactionResponse.json();

    const { data } = transaction;
    const { recurringPaymentMethod } = this.state;

    if (recurringPaymentMethod === "CARD") {
      if (data && data.response !== "Pendiente") {
        clearInterval(this.pollingTransaction);
        this.setState({
          creatingTransaction: false,
          transactionStatus: "APPROVED",
        });
        this.props.setUpdateNotifications({ updateNotifications: true });
      }
    }
  }

  async applyDiscountCode() {
    const { discountCode } = this.state;
    const user = this.props.isPaymentLink
      ? this.props.paymentUser
      : this.props.user;

    if (discountCode) {
      this.setState({ validatingCode: true });

      const discCodeProm = await fetch("/discountCode/validate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: user._id,
          code: discountCode,
          donationType: "RECURRING",
        }),
      });

      const discCodeResp = await discCodeProm.json();
      if (discCodeResp.success)
        this.setState({
          codeValidated: discCodeResp.isValid,
          codeSent: true,
          amountDiscounted: parseInt(discCodeResp.discountAmount),
        });

      this.setState({ validatingCode: false });
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    this.setState({ formValidated: true });

    if (form.checkValidity()) {
      if (this.props.gaTrackEventPayment) this.props.gaTrackEventPayment();

      this.setState({ creatingTransaction: true });

      const { programId, foundationId, customDonation, items } = this.props;

      const user = this.props.isPaymentLink
        ? this.props.paymentUser
        : this.props.user;

      const starterPromise = Promise.resolve(null);
      await items.reduce(
        (p, item) => p.then(() => this.createItemPayment(item)),
        starterPromise
      );

      if (this.state.extras1.split(",").length === this.state.itemsNumber) {
        const transactionId = await this.createEpaycoTransaction();
        if (!transactionId) return;

        this.pollingTransaction = setInterval(async () => {
          //this.checkTransaction(transactionId);
          this.checkEpaycoTransaction(transactionId);
        }, 2000);
      } else {
        return;
      }
    }
  }

  render() {
    const {
      programId,
      programName,
      foundationName,
      isEnterprise,
      isFoundation,
      customDonation,
      selectedPlan,
      program,
      isPaymentLink,
      planQty,
    } = this.props;
    const user = isPaymentLink ? this.props.paymentUser : this.props.user;
    const paymentSources = user.creditCards || [];
    const values = queryString.parse(this.props.location.search);
    const { nibify } = values;
    const nibifyProgram = program && program.nibifyProgram && nibify === "1";
    const showContactMethod = user && !user.contactMethod;
    const {
      cardHolderName,
      cardHolderLastName,
      cardNumber,
      cardExpirationMonth,
      cardExpirationYear,
      cvv,
      isLoading,
      permalink,
      formValidated,
      amount,
      creatingTransaction,
      transactionStatus,
      recurringPaymentExists,
      recurringPaymentMethod,
      referredBy,
      docType,
      userDocument,
      userType,
      cellPhone,
      institutionSelected,
      financialInstitutions,
      pseEmail,
      benefits,
      saveCard,
      savedPaymentSource,
      contactMethod,
      donationType,
      nibifyCb,
      discountCode,
      codeValidated,
      validatingCode,
      codeSent,
      amountDiscounted,
      donationCity,
      donationAddress,
      planAmount,
    } = this.state;

    if (isFoundation || isEnterprise) {
      return (
        <div className="recurring-payment-component">
          <p>No puedes donar a una campaña si eres una organización.</p>
        </div>
      );
    }

    let title = "";
    let message = "";

    if (transactionStatus === "APPROVED") {
      title = "Transacción exitosa";
      message = `Recibimos tu donación por un valor de ${formatMoney(
        amount
      )}. ✨ Gracias por donar con Nibi. ✌🏼 Ahora estas apadrinando a la Corporación Más Que Tres Letras y sus diferentes proyectos para un mejor bienestar de la personas que viven con VIH. ❤️`;
    } else {
      title = "Error en la transacción";
      message = `La transacción fue anulada por la pasarela de pagos. Por favor verifica la transacción con tu entidad bancaria o inténtalo nuevamente.`;
    }

    return (
      <div className="recurring-payment-component">
        {isLoading ? (
          <AppSpinner />
        ) : recurringPaymentExists ? (
          <div className="landing-pse-payment-component">
            <h4 className="message">
              Ya estás apadrinando{" "}
              {programId ? "esta campaña" : "esta organización"}. Para ver tus
              suscripciones ve a tu perfil.
            </h4>
          </div>
        ) : transactionStatus ? (
          <div className="landing-pse-payment-component recurring-payment-component-benefits">
            <h1 className="title">{title}</h1>
            <h4 className="message">{message}</h4>
          </div>
        ) : creatingTransaction ? (
          <div className="pse-transaction-spinner">
            <AppSpinner />
            <h4 className="message">
              Por favor espera mientras procesamos la transacción. Esto puede
              tardar varios segundos...
            </h4>
          </div>
        ) : (
          <Row>
            <Col md={4} style={{ borderRight: "1px solid #DEE2E6" }}>
              <div className="program-donation-summary">
                <h4 className="title">RESUMEN</h4>
                <p className="program-name">
                  Compra {programName || foundationName}
                </p>
                <Row noGutters className="row-donation-summary">
                  <Col xs={6}>
                    <p>
                      <strong>Monto</strong>
                    </p>
                  </Col>
                  <Col xs={6}>
                    <p id="programDonationAmount">${formatMoney(amount)} COP</p>
                  </Col>
                  <Col xs={6}>
                    <p>
                      <strong>Fundación</strong>
                    </p>
                  </Col>
                  <Col xs={6}>
                    <p id="programDonationFoundationName">{foundationName}</p>
                  </Col>
                  {selectedPlan && (
                    <>
                      <Col xs={6}>
                        <p>
                          <strong>Plan seleccionado</strong>
                        </p>
                      </Col>
                      <Col xs={6}>
                        <p>{selectedPlan.name}</p>
                      </Col>
                    </>
                  )}
                  {codeSent && codeValidated && amountDiscounted > 0 && (
                    <>
                      <Col xs={6}>
                        <p>
                          <strong>Cupón</strong>
                        </p>
                      </Col>
                      <Col xs={6}>
                        <p>- ${formatMoney(amountDiscounted)} COP</p>
                      </Col>
                    </>
                  )}
                </Row>
                <Row noGutters>
                  <Col xs={6}>
                    <p>
                      <strong>
                        Total{" "}
                        {codeSent &&
                          codeValidated &&
                          discountCode === "nibiseponela10" &&
                          " a pagar"}
                      </strong>
                    </p>
                  </Col>
                  <Col xs={6}>
                    {codeSent && codeValidated ? (
                      <p>
                        <strong>
                          ${formatMoney(amount - amountDiscounted)} COP
                        </strong>
                      </p>
                    ) : (
                      <p>
                        <strong>${formatMoney(amount)} COP</strong>
                      </p>
                    )}
                  </Col>
                  {codeSent &&
                    codeValidated &&
                    discountCode === "nibiseponela10" && (
                      <>
                        <Col xs={6}>
                          <p>
                            <strong>Total mas cupón</strong>
                          </p>
                        </Col>
                        <Col xs={6}>
                          {amount > 0 && codeSent && codeValidated && (
                            <p>
                              <strong>
                                ${formatMoney(parseInt(amount) + 10000)} COP
                              </strong>
                            </p>
                          )}
                        </Col>
                      </>
                    )}
                </Row>
                <p style={{ textAlign: "center" }}>
                  <strong>Selecciona un método de pago</strong>
                </p>
                <div className="program-donation-payment-methods">
                  <fieldset>
                    <Form.Group>
                      <div
                        className="custom-control custom-radio"
                        id="programDonationRadioCard"
                      >
                        <input
                          type="radio"
                          id="paymentMethodRadio1"
                          name="CARD"
                          className={`custom-control-input${
                            recurringPaymentMethod === "CARD" ? " checked" : ""
                          }`}
                          checked={recurringPaymentMethod === "CARD"}
                          onChange={() =>
                            this.setState({ recurringPaymentMethod: "CARD" })
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="paymentMethodRadio1"
                        >
                          <i className="far fa-credit-card" /> Tarjeta de
                          crédito o débito con CVV
                        </label>
                      </div>
                    </Form.Group>
                  </fieldset>
                </div>
              </div>
            </Col>
            <Col>
              <Form
                noValidate
                validated={formValidated}
                onSubmit={this.handleSubmit}
                style={{ marginTop: "15px" }}
              >
                <Form.Row className="align-items-end">
                  {customDonation && (
                    <Form.Group as={Col} lg={4}>
                      <Form.Label className="nibi-label">
                        Tipo de donación
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="donationType"
                        value={donationType}
                        onChange={this.handleInputChange}
                        required
                      >
                        <option value="ONE_TIME_DONATION">Única</option>
                        <option value="RECURRING_PAYMENT">Recurrente</option>
                      </Form.Control>
                    </Form.Group>
                  )}
                  <Form.Group as={Col} lg={6} xs={6}>
                    <Form.Label className="nibi-label">
                      Ciudad <AddressInfoIcon />
                    </Form.Label>
                    <Form.Control
                      name="donationCity"
                      type="text"
                      placeholder="Ciudad"
                      value={donationCity}
                      onChange={this.handleInputChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group as={Col} lg={6} xs={6}>
                    <Form.Label className="nibi-label">
                      Dirección <AddressInfoIcon />
                    </Form.Label>
                    <Form.Control
                      name="donationAddress"
                      type="text"
                      placeholder="Dirección"
                      value={donationAddress}
                      onChange={this.handleInputChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} lg={4}>
                    <Form.Label className="nibi-label">
                      Número de teléfono
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="cellPhone"
                      placeholder="Teléfono"
                      value={cellPhone}
                      onChange={this.handleInputChange}
                      required
                    />
                  </Form.Group>
                </Form.Row>

                {recurringPaymentMethod === "PSE" ? (
                  <>
                    <Form.Row>
                      <Form.Group as={Col} lg={4}>
                        <Form.Label className="nibi-label">
                          Institución financiera
                        </Form.Label>
                        <Form.Control
                          as="select"
                          className={
                            institutionSelected === "0" ? "invalid-value" : ""
                          }
                          name="institutionSelected"
                          value={institutionSelected}
                          onChange={this.handleInputChange}
                        >
                          {financialInstitutions.map((institution) => (
                            <option
                              key={institution.bankCode}
                              value={institution.bankCode}
                            >
                              {institution.bankName}
                            </option>
                          ))}
                        </Form.Control>
                        {formValidated && institutionSelected === "0" && (
                          <div className="text-invalid">
                            Selecciona tu banco.
                          </div>
                        )}
                      </Form.Group>

                      <Form.Group as={Col} lg={5}>
                        <Form.Label className="nibi-label">
                          Correo electrónico
                        </Form.Label>
                        <Form.Control
                          name="pseEmail"
                          type="email"
                          placeholder="Email"
                          value={pseEmail}
                          onChange={this.handleInputChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Ingresa un correo válido.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group as={Col} lg={3}>
                        <Form.Label className="nibi-label">
                          Tipo de persona
                        </Form.Label>
                        <Form.Control
                          name="userType"
                          as="select"
                          value={userType}
                          onChange={this.handleInputChange}
                        >
                          <option value="0">Natural</option>
                          <option value="1">Jurídica</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group as={Col} lg={6}>
                        <Form.Label className="nibi-label">
                          Nombre(s)
                        </Form.Label>
                        <Form.Control
                          name="cardHolderName"
                          type="text"
                          placeholder="Nombre"
                          value={cardHolderName}
                          onChange={this.handleInputChange}
                          required
                        />
                      </Form.Group>
                      <Form.Group as={Col} lg={6}>
                        <Form.Label className="nibi-label">
                          Apellido(s)
                        </Form.Label>
                        <Form.Control
                          name="cardHolderLastName"
                          type="text"
                          placeholder="Apellido"
                          value={cardHolderLastName}
                          onChange={this.handleInputChange}
                          required
                        />
                      </Form.Group>
                    </Form.Row>
                  </>
                ) : (
                  <>
                    <hr />
                    <h5>Información de la tarjeta</h5>
                    <hr />
                    <Form.Row>
                      {paymentSources.length > 0 && (
                        <Form.Group as={Col} lg={6}>
                          <Form.Label className="nibi-label">
                            Usar tarjeta guardada
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="savedPaymentSource"
                            value={savedPaymentSource}
                            onChange={this.handleInputChange}
                          >
                            <option value="Ninguna">Ninguna</option>
                            {paymentSources.map((el, index) => (
                              <option key={index} value={el.paymentSourceId}>
                                **** **** **** {el.cardNumber}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      )}

                      <Form.Group as={Col} lg={6} xs={6}>
                        <Form.Label className="nibi-label">
                          Nombre(s)
                        </Form.Label>
                        <Form.Control
                          name="cardHolderName"
                          type="text"
                          placeholder="Nombre"
                          value={cardHolderName}
                          onChange={this.handleInputChange}
                          required
                        />
                      </Form.Group>
                      <Form.Group as={Col} lg={6} xs={6}>
                        <Form.Label className="nibi-label">
                          Apellido(s)
                        </Form.Label>
                        <Form.Control
                          name="cardHolderLastName"
                          type="text"
                          placeholder="Apellido"
                          value={cardHolderLastName}
                          onChange={this.handleInputChange}
                          required
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} lg={4} xs={5}>
                        <Form.Label className="nibi-label">
                          Tipo documento
                        </Form.Label>
                        <Form.Control
                          name="docType"
                          as="select"
                          value={docType}
                          onChange={this.handleInputChange}
                        >
                          <option value="CC">CC</option>
                          <option value="NIT">NIT</option>
                          {recurringPaymentMethod !== "PSE" && (
                            <>
                              <option value="CE">CE</option>
                            </>
                          )}
                          {recurringPaymentMethod === "PSE" && (
                            <>
                              <option value="CC">CE</option>
                            </>
                          )}
                        </Form.Control>
                      </Form.Group>

                      <Form.Group as={Col} lg={4} xs={7}>
                        <Form.Label className="nibi-label">
                          Número de documento
                        </Form.Label>
                        <Form.Control
                          name="userDocument"
                          type="text"
                          placeholder="Documento"
                          value={userDocument}
                          onChange={this.handleInputChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Ingresa tu documento.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>

                    {savedPaymentSource === "Ninguna" && (
                      <Form.Row>
                        <Form.Group as={Col} lg={4}>
                          <Form.Label className="nibi-label">
                            Número de la tarjeta
                          </Form.Label>
                          <Form.Control
                            name="cardNumber"
                            type="number"
                            placeholder="0000 0000 0000 0000"
                            value={cardNumber}
                            onChange={this.handleInputChange}
                            required
                          />
                        </Form.Group>

                        <Form.Group as={Col} lg={4} xs={8}>
                          <Form.Label className="nibi-label">
                            Fecha de expiración
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              name="cardExpirationMonth"
                              type="number"
                              placeholder="MM"
                              min={1}
                              max={12}
                              value={cardExpirationMonth}
                              onChange={this.handleInputChange}
                              required
                            />
                            <Form.Control
                              name="cardExpirationYear"
                              type="number"
                              min={20}
                              placeholder="YY"
                              value={cardExpirationYear}
                              onChange={this.handleInputChange}
                              required
                            />
                          </InputGroup>
                        </Form.Group>

                        <Form.Group as={Col} lg={4} xs={4}>
                          <Form.Label className="nibi-label">CVV</Form.Label>
                          <Form.Control
                            name="cvv"
                            type="number"
                            value={cvv}
                            placeholder="Código de seguridad"
                            onChange={this.handleInputChange}
                            required
                          />
                        </Form.Group>
                      </Form.Row>
                    )}
                  </>
                )}

                <Form.Row>
                  {recurringPaymentMethod === "CARD" &&
                    savedPaymentSource === "Ninguna" && (
                      <Form.Group as={Col} xs={12}>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="permalinkCheckBox-2"
                            onChange={(event) =>
                              this.setState({ saveCard: event.target.checked })
                            }
                            checked={saveCard}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="permalinkCheckBox-2"
                            style={{ color: "#808080", fontSize: "0.95rem" }}
                          >
                            ¿Deseas guardar tu tarjeta de crédito para futuras
                            transacciones?
                          </label>
                        </div>
                      </Form.Group>
                    )}
                  {showContactMethod && (
                    <Form.Row>
                      <Form.Group as={Col} lg={6}>
                        <Form.Label className="nibi-label">
                          ¿Por dónde te gustaría recibir la información de tu
                          impacto?
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="contactMethod"
                          value={contactMethod}
                          onChange={this.handleInputChange}
                        >
                          <option value="WHATS_APP">WhatsApp</option>
                          <option value="CELL_PHONE">Llamada</option>
                          <option value="EMAIL">Correo</option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>
                  )}
                  <Form.Group
                    as={Col}
                    xs={12}
                    style={{ marginBottom: 0, marginTop: "15px" }}
                  >
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="permalinkCheckBox"
                        required
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="permalinkCheckBox"
                        style={{ color: "#808080", fontSize: "0.95rem" }}
                      >
                        Acepto haber leído los{" "}
                        <a
                          href={permalink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          términos y condiciones y la política de privacidad
                        </a>{" "}
                        para hacer este pago.
                      </label>
                    </div>
                  </Form.Group>
                  <Col xs={12}>
                    <br />
                    Al adquirir la siguiente suscripción declaro que transfiero
                    a título de donación el valor de la misma a la Corporación
                    +Q3LETRAS, sin posibilidad posterior de solicitar reembolso,
                    así mismo me acojo a las políticas del programa +Q3LETRAS, y
                    las políticas de tratamiento de datos de la Corporación,
                    habiendo sido informado de manera clara y completa sobre las
                    condiciones de las mismas.
                  </Col>
                  <button className="nibi-btn" type="submit">
                    Continuar
                  </button>
                </Form.Row>
              </Form>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

const TresLetrasTransactionComponent = connect(
  select,
  mapDispatchToProps
)(ConnectedTresLetrasTransactionComponent);

export default withRouter(TresLetrasTransactionComponent);
