import React, { useEffect, useState } from 'react';
import ods1 from '../img/Goal-01.png';
import ods2 from '../img/Goal-02.png';
import ods3 from '../img/Goal-03.png';
import ods4 from '../img/Goal-04.png';
import ods5 from '../img/Goal-05.png';
import ods6 from '../img/Goal-06.png';
import ods7 from '../img/Goal-07.png';
import ods8 from '../img/Goal-08.png';
import ods9 from '../img/Goal-09.png';
import ods10 from '../img/Goal-10.png';
import ods11 from '../img/Goal-11.png';
import ods12 from '../img/Goal-12.png';
import ods13 from '../img/Goal-13.png';
import ods14 from '../img/Goal-14.png';
import ods15 from '../img/Goal-15.png';
import ods16 from '../img/Goal-16.png';
import ods17 from '../img/Goal-17.png';
import banner from '../img/foundationBannerImg.jpg';
import './styles/nibifyFoundationCard.scss';

const odsArr = [
  ods1, ods2, ods3, ods4, ods5, ods6, ods7, ods8, ods9, ods10, ods11, ods12, ods13, ods14, ods15, ods16, ods17
];

function NibifyFoundationCard(props) {

  const { foundation } = props;

  const ods = (foundation.mainOds && foundation.mainOds.length > 0 ? foundation.mainOds : []).concat(foundation.secondaryOds && foundation.secondaryOds.length > 0 ? foundation.secondaryOds : []);

  return (
    <div className="nibify-foundation-card">
      <span className="card-imgs" style={{ backgroundImage: `url(${banner})` }}>
        <div
          className="foundation-logo"
          style={{ backgroundImage: foundation.logo ? `url(${foundation.logo})` : `url(${require('../img/user.png')})` }}
        ></div>
        <span>
          {
            ods.length > 0 &&
            ods.slice(0, 2).map((ods, index) =>
              <div className="ods" key={index}>
                <a href="https://www.un.org/sustainabledevelopment/es/objetivos-de-desarrollo-sostenible/" target="_blank" rel="noopener noreferrer">
                  <img
                    src={odsArr[parseInt(ods.value - 1)]}
                    alt="Objetivo de desarrollo sostenible principal de la organización"
                  />
                </a>
              </div>
            )
          }
        </span>
      </span>
      <div className="foundation-info">
        <button className="card-btn" onClick={props.handleClick}>
          Seleccionar
        </button>
        <div className="info">
          <h5 className="foundation-name">{foundation.name}</h5>
          <p className="foundation-desc">
            {foundation.description.substring(0, 330)}
            {foundation.description.length > 330 && '...'}
          </p>
        </div>
      </div>
    </div>
  );
}

export default NibifyFoundationCard;
