import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Container,
  Form,
  Image,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { connect } from "react-redux";
import { setShowRegisterModal } from "../Redux/modules/auth";
import FoundationSubscriptionPlans2022 from "../FoundationSubscriptionPlan/FoundationSubscriptionPlans2022";
import Allies from "../Allies/Allies";
import Foundations from "../Allies/Foundations";
import Media from "../Allies/Media";
import Awards from "../Allies/Awards";
import "./styles/styles.scss";
import "../Home/styles/nibi-home.scss";

const select = (state) => {
  return {
    token: state.auth.token,
    isFoundation: state.auth.isFoundation,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setShowRegisterModal: (show) => dispatch(setShowRegisterModal(show)),
  };
}

const planFeatures = [
  {
    label: "Recibe donaciones nacionales e internacionales",
    height: "40px",
    detail: "",
  },
  {
    label:
      "Debita mensual y automáticamente las donaciones nacionales e internacionales",
    height: "40px",
    detail:
      "Con nibi tus donantes solamente deben suscribirse una vez a ser donantes mensuales. Nosotros nos encargamos de debitarles su donación todos los meses",
  },
  {
    label: "Premia a tus donantes por apoyar a tu organización",
    height: "40px",
    detail:
      "Por cada donación que hagan tus donantes recibirán nibis, puntos virtuales que podrán redimir por descuentos en la red de marcas aliadas de nibi",
  },
  {
    label:
      "Comparte con tus donantes los resultados de los proyectos que realiza tu organización",
    height: "40px",
    detail:
      "En nibi debes subir los resultados del impacto logrado gracias a las donaciones que recibiste. Así tus donantes sabrán qué pasó con su dinero, confiarán más en ti y te continuarán donando.",
  },
  {
    label: "Academia nibi",
    height: "40px",
    detail:
      "Recibirás invitaciones a todos los espacios de formación que nibi realiza para todas sus fundaciones",
  },
  {
    label: "Cuenta con un equipo de soporte para tu organización",
    height: "40px",
    detail:
      "Nuestro equipo te brindará soporte a través de Whatsapp y correo con todas las dudas y problemas que tengas con la plataforma.",
  },
  {
    label:
      "Emite automáticamente certificados de donación a donantes en Colombia",
    height: "40px",
    detail:
      "Si tu fundación está registrada en el Régimen Tributario Especial, puedes emitir certificados de donación automática para que tus donantes cuenten con descuentos tributarios",
  },
  {
    label:
      "Emite automáticamente certificados de donación a donantes en Estados Unidos",
    height: "40px",
    detail:
      "Si recibes donaciones desde Estados Unidos a través de nibi podrás emitir certificados de donación de manera automática a pesar de que tu organización no esté constituida en este país",
  },
  {
    label: "Sistema de alertas personalizado.",
    height: "40px",
    detail:
      "Recibe notificaciones personalizadas, como por ejemplo el recordatorio del cumpleaños de un donante, para brindarles la mejor experiencia de donación",
  },
  {
    label:
      "Cuenta con sesiones de acompañamiento personalizado para tu organización",
    height: "40px",
    detail:
      "Nuestro equipo te acompañará a sacarle el máximo beneficio a nuestra plataforma y te compartirá las mejores prácticas para acelerar tu crecimiento",
  },
  {
    label: "Integra nibi dentro de la página web de tu organización",
    height: "40px",
    detail:
      "Así seguirás recibiendo donaciones desde tu página web y centralizando la información de todos tus donantes en una sola plataforma",
  },
  {
    label: "Mejora tu posicionamiento en el motor de búsqueda de Nibi",
    height: "40px",
    detail:
      "Aumenta la probabilidad de que las personas y empresas te encuentren dentro de nibi y te apoyen con donaciones o voluntariados",
  },
  {
    label: "Integración con Correo",
    height: "25px",
    detail:
      "Próximamente le podrás escribir a tus donantes directamente desde nibi a través de correo electrónico. Esto te permitirá hacer todas tus estrategias de comunicación desde una sola plataforma",
  },
  {
    label: "Costo nibi por transacción",
    height: "90px",
    detail:
      "Nuestros planes Avanzado y Profesional están diseñados para que tu organización ahorre dinero por las donaciones y ventas en nibi. (Esto no aplica para donaciones desde el exterior)",
  },
  { label: "Número de donantes mensuales", height: "25px", detail: "" },
  {
    label: "Número campañas, productos y voluntariados al mes",
    height: "40px",
    detail:
      "A partir del Plan Básico podrás tener cuantas campañas, productos y voluntariados activos quieras al mes",
  },
  {
    label: "Número de Usuarios",
    height: "25px",
    detail:
      "Los usuarios son aquellas personas que pueden acceder al perfil de tu organización en nibi. Tú determinarás el tipo de permisos que cada usuario puede tener dentro de la plataforma",
  },
];

const plansContent = {
  free: {
    amount: "0",
    features: [
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "times" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "times" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "times" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "times" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "times" },
      { style: { height: "25px" }, content: <span>Próximamente</span> },
      {
        class: "plan-fees",
        style: { display: "block", height: "90px" },
        content: (
          <>
            <div>Donación única: 6%</div>
            <div>Donación mensual: 4%</div>
            <div>Ventas: 7%</div>
          </>
        ),
      },
      { style: { height: "25px" }, content: <span>0 a 12</span> },
      { style: { height: "40px" }, content: <span>2 al mes</span> },
      { style: { height: "25px" }, content: <span>1</span> },
    ],
  },
  basic: {
    amount: "60",
    yearAmount: "576",
    features: [
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "times" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "times" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "times" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "times" },
      { style: { height: "25px" }, content: <span>Próximamente</span> },
      {
        class: "plan-fees",
        style: { display: "block", height: "90px" },
        content: (
          <>
            <div>Donación única: 6%</div>
            <div>Donación mensual: 4%</div>
            <div>Ventas: 7%</div>
          </>
        ),
      },
      { style: { height: "25px" }, content: <span>13 a 30</span> },
      { style: { height: "40px" }, content: <span>Ilimitados</span> },
      { style: { height: "25px" }, content: <span>2</span> },
    ],
  },
  advanced: {
    amount: "150",
    yearAmount: "1.440",
    features: [
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "times" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "times" },
      { style: { height: "25px" }, content: <span>Próximamente</span> },
      {
        class: "plan-fees",
        style: { display: "block", height: "90px" },
        content: (
          <>
            <div>Todas las transacciones: 1.5%</div>
          </>
        ),
      },
      { style: { height: "25px" }, content: <span>31 a 70</span> },
      { style: { height: "40px" }, content: <span>Ilimitados</span> },
      { style: { height: "25px" }, content: <span>Hasta 4</span> },
    ],
  },
  pro: {
    amount: "445",
    yearAmount: "4.272",
    features: [
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { class: "plan-item-icon", style: { height: "40px" }, icon: "check" },
      { style: { height: "25px" }, content: <span>Próximamente</span> },
      {
        class: "plan-fees",
        style: { display: "block", height: "90px" },
        content: (
          <>
            <div>Todas las transacciones: 1.5%</div>
          </>
        ),
      },
      { style: { height: "25px" }, content: <span>Ilimitados</span> },
      { style: { height: "40px" }, content: <span>Ilimitados</span> },
      { style: { height: "25px" }, content: <span>Hasta 6</span> },
    ],
  },
};

function ConnectedFoundationsLanding(props) {
  const [tab, setTab] = useState(1);
  const [foundationNumber, setFoundationNumber] = useState(-1);
  const [academyTab, setAcademyTab] = useState(0);
  const [monthlyPlanSelected, setMonthlyPlanSelected] = useState(true);
  const [playVideo, setPlayVideo] = useState(false);
  const [activePlan, setActivePlan] = useState(0);
  const [showDemoModal, setShowDemoModal] = useState(false);
  const [name, setName] = useState("");
  const [foundationName, setFoundationName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [messageSent, setMessageSent] = useState(false);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [planInterest, setPlanInterest] = useState("");
  const planRef = useRef();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  useEffect(() => {
    fetch("/foundations/get/count")
      .then((resp) => resp.json())
      .then((json) => {
        if (json && json.success) setFoundationNumber(json.num);
      });
  }, []);

  const createAccount = () => {
    if (!props.token)
      props.setShowRegisterModal({
        show: true,
        registerUserType: "FOUNDATION",
      });
  };

  const scrollTo = () => {
    window.scrollTo(0, planRef.current.offsetTop - 50);
  };

  const handleContactSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setSendingMessage(true);

    fetch(`/email/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        enterpriseName: foundationName,
        phoneEmail: `${phone}, ${email}`,
        message,
        type: planInterest || "Organización",
      }),
    });

    setSendingMessage(false);
    setMessageSent(true);
  };

  const prevArrow = (
    <i
      className={`fas fa-arrow-circle-left mobile-icon ${
        activePlan === 0 && "disabled"
      }`}
      style={{ marginRight: "20px" }}
      onClick={() => {
        if (activePlan > 0) setActivePlan(activePlan - 1);
      }}
    />
  );

  const nextArrow = (
    <i
      className={`fas fa-arrow-circle-right mobile-icon ${
        activePlan === 3 && "disabled"
      }`}
      style={{ marginLeft: "20px" }}
      onClick={() => {
        if (activePlan < 3) setActivePlan((prev) => prev + 1);
      }}
    />
  );

  return (
    <div className="foundations-landing">
      {/* <div className="banner">
        <h2 style={{ textAlign: 'center', color: '#6212D7' }}>
          Tenemos la solución más completa del sector social
        </h2>
        <h3 style={{ color: '#6212D7' }}>
          Recauda donaciones mensuales para tu fundación
        </h3>
        <p style={{ color: '#6212D7' }}>
          <i className="fas fa-users" />
          {'   '}Ya somos {foundationNumber > 0 ? foundationNumber : <Spinner variant="warning" size="sm" animation="border" />} fundaciones en toda Colombia
        </p>
        <button className="landing-button" onClick={createAccount}>
          Regístrate gratis
        </button>
      </div> */}

      <div className="nibi-home-foundations">
        <div
          className="top-banner-foundations"
          style={{
            height: "fit-content",
            paddingBottom: "50px",
            textAlign: "center",
            zIndex: "1",
          }}
        >
          {/* <img id='nibiGlobe' style={{ animation: 'none', left: '50%' }} src={require('../img/Home/Globo.png')} alt='globo nibi' /> */}
          {/* <img id='nibiBee1' style={{ zIndex: '-10' }} src={require('../img/Home/abejacolmena.png')} alt='abeja nibi' />
          <img id='nibiBee2' style={{ zIndex: '-10' }} src={require('../img/Home/abejanibi.png')} alt='abeja nibi' />
          <img id='nibiBee3' style={{ zIndex: '-10' }} src={require('../img/Home/abejaregalo.png')} alt='abeja nibi' />
          <img id='nibiBee4' style={{ zIndex: '-10' }} src={require('../img/Home/abeja.png')} alt='abeja nibi' />
          <img id='nibiWind' src={require('../img/Home/eolica.png')} alt='globo nibi' /> */}
          {/* <img id='nibiCloud1' style={{ animation: 'none', left: '60%' }} src={require('../img/Home/nube.png')} alt='nube' /> */}
          {/* <img id='nibiCloud2' style={{ animation: 'none', left: '45%' }} src={require('../img/Home/nube.png')} alt='nube' /> */}
          <h1
            className="top-banner-title"
            style={{ display: "block", margin: "0 auto" }}
          >
            Tenemos la solución más completa del sector social
          </h1>
          <div
            className="top-banner-subtitle"
            style={{ zIndex: "100", display: "block", margin: "0 auto" }}
          >
            Recauda donaciones mensuales para tu fundación
          </div>
          <div
            className="top-banner-subtitle"
            style={{
              zIndex: "100",
              fontSize: "1.25rem",
              display: "block",
              margin: "0 auto",
            }}
          >
            <i className="fas fa-users" />
            {"   "}Ya somos{" "}
            {foundationNumber > 0 ? (
              foundationNumber
            ) : (
              <Spinner variant="warning" size="sm" animation="border" />
            )}{" "}
            fundaciones en toda Colombia
            <div>
              <button
                className="landing-button"
                onClick={() => {
                  setShowDemoModal(true);
                }}
                style={{ marginTop: "20px", fontSize: "1rem", zIndex: "100" }}
              >
                Quiero más información
              </button>
            </div>
          </div>
        </div>
      </div>

      <Container style={{ marginTop: "100px" }}>
        <Row>
          <Col>
            <div className="landing-section">
              {/* <h1>
                Tenemos la solución más completa del sector social
              </h1> */}
              <div className="foundation-landing-title">
                <h3>¡LLEVA TU FUNDACIÓN A OTRO NIVEL CON NIBI!</h3>
              </div>

              <p style={{ fontSize: "1.2rem" }}>
                <strong>nibi</strong> es la{" "}
                <strong>solución tecnológica</strong> que te permite{" "}
                <strong>
                  optimizar tus procesos de recaudo y fidelización de donantes
                </strong>{" "}
                para que puedas llevar tus donaciones ¡a otro nivel!
              </p>
              <h4
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#382564",
                }}
              >
                ¡Mira lo que puedes hacer con nibi!
              </h4>
              <div style={{ textAlign: "center" }}>
                <video controls width="300" height="500">
                  <source
                    src="https://nibi-videos.s3.us-east-2.amazonaws.com/nibiTecnologia.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>

              {/* <p>
                Te damos las <strong>herramientas tecnológicas</strong> que
                necesita tu organización, creamos{" "}
                <strong>espacios de formación</strong> para capacitar a tu
                equipo y <strong>te conectamos</strong> con aliados y otras
                organizaciones para lograr que tu organización tenga un mejor y
                mayor impacto.
              </p> */}
            </div>

            <div className="landing-section">
              <h1>Descubre lo que Nibi te ofrece</h1>

              <div className="tab-section">
                <Row>
                  <Col xs={12} md={4}>
                    <button
                      className={`landing-button ${tab === 1 ? "active" : ""}`}
                      onClick={() => setTab(1)}
                    >
                      Tecnología Nibi
                    </button>
                  </Col>
                  <Col xs={12} md={4}>
                    <button
                      className={`landing-button ${tab === 2 ? "active" : ""}`}
                      onClick={() => setTab(2)}
                    >
                      Academia Nibi
                    </button>
                  </Col>
                  <Col xs={12} md={4}>
                    <button
                      className={`landing-button ${tab === 3 ? "active" : ""}`}
                      onClick={() => setTab(3)}
                    >
                      Comunidad Nibi
                    </button>
                  </Col>
                </Row>
              </div>
            </div>

            {tab !== 0 && (
              <div className="tab-content">
                {tab === 1 ? (
                  <div>
                    <Row>
                      <Col md={6}>
                        {/* <img
                          style={{ marginBottom: "10px", width: "100%" }}
                          src={require("../img/FoundationLanding/technologies.png")}
                          alt="tecnologias"
                          width="500px"
                        /> */}
                        <div style={{ textAlign: "center" }}>
                          <video
                            controls
                            width={isMobile ? "300" : "500"}
                            height={isMobile ? "200" : "300"}
                          >
                            <source
                              src="https://nibi-videos.s3.us-east-2.amazonaws.com/nibiTecnologia2.mp4"
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div
                          className="landing-section"
                          style={{ marginTop: 0 }}
                        >
                          <h1>Tecnología nibi para tu fundación</h1>

                          {/* <div className="tech-item">
                            <i className="fas fa-check-circle" />
                            Fidelizar a tus donantes y generar ingresos
                            mensuales
                          </div>
                          <div className="tech-item">
                            <i className="fas fa-check-circle" />
                            Crear y personalizar el perfil de tu fundación
                          </div>
                          <div className="tech-item">
                            <i className="fas fa-check-circle" />
                            Crear campañas de recaudación de donaciones
                          </div>
                          <div className="tech-item">
                            <i className="fas fa-check-circle" />
                            Vender productos en nuestra tienda online
                          </div>
                          <div className="tech-item">
                            <i className="fas fa-check-circle" />
                            Compartir los resultados de tus proyectos
                            sociales/ambientales
                          </div>
                          <br /> */}
                          <p>
                            Genera fácilmente <strong>links de donación</strong>{" "}
                            para redes sociales,{" "}
                            <strong>recibe donaciones</strong> con{" "}
                            <strong>todos los medios de pago</strong>,
                            nacionales e internacionales, integra{" "}
                            <strong>botón de pago</strong> a tu página web, crea
                            automáticamente tu <strong>base de datos</strong>{" "}
                            para{" "}
                            <strong>fidelización y procesos contables</strong>,
                            automatiza tus comunicaciones por{" "}
                            <strong>WhatsApp</strong> y, ¡mucho más!
                          </p>

                          <div style={{ textAlign: "center" }}>
                            <span className="landing-link" onClick={scrollTo}>
                              Conoce todas nuestras funcionalidades aquí
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                ) : tab === 2 ? (
                  <div>
                    <div className="landing-section" style={{ marginTop: 0 }}>
                      {/* <p style={{ margin: 0 }}>
                      Creamos diferentes espacios de formación para fortalecer las habilidades de tu equipo y construir capacidades dentro de tu organización.
                      </p> */}
                      <p>
                        La Academia nibi cuenta con{" "}
                        <strong>3 tipos de espacios de formación:</strong> que
                        permiten:
                      </p>
                      <div style={{ width: "fit-content", margin: "0 auto" }}>
                        <div className="tech-item">
                          <i className="fas fa-check-circle" />
                          Fortalecer las habilidades de tu equipo
                        </div>
                        <div className="tech-item">
                          <i className="fas fa-check-circle" />
                          Construir capacidades dentro de tu organización
                        </div>
                      </div>

                      <div className="academy-tab-section">
                        <div
                          className={`academy-tab ${
                            academyTab === 0 ? "active" : ""
                          }`}
                          onClick={() => setAcademyTab(0)}
                        >
                          <div className="tab-btn"></div>
                          Programas
                        </div>
                        <div
                          className={`academy-tab ${
                            academyTab === 1 ? "active" : ""
                          }`}
                          onClick={() => setAcademyTab(1)}
                        >
                          <div className="tab-btn"></div>
                          Webinars
                        </div>
                        <div
                          className={`academy-tab ${
                            academyTab === 2 ? "active" : ""
                          }`}
                          onClick={() => setAcademyTab(2)}
                        >
                          <div className="tab-btn"></div>
                          Foros
                        </div>
                      </div>
                      <hr />

                      <div className="academy-content">
                        {academyTab === 0 ? (
                          <div>
                            <p style={{ margin: 0 }}>
                              Damos los mejores talleres, asesorías y mentorías.
                            </p>
                            <p>
                              <strong>
                                Contamos con los siguientes programas:
                              </strong>
                            </p>
                            <br />

                            <Row>
                              <Col md={4}>
                                <div className="academy-card">
                                  <h5>
                                    <strong>
                                      Programa de fidelización de donantes
                                    </strong>
                                  </h5>
                                  <p>
                                    <a
                                      href="https://registro.nibi.com.co/programa-fidelizacion-de-donantes"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Conoce todo aquí
                                    </a>
                                  </p>
                                  {/* <p>
                                    Objetivo: fortalecer las capacidades de tu organización para retener y fidelizar a sus donantes logrando aumentar sus ingresos mensuales.
                                  </p> */}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="academy-card">
                                  <h5>
                                    <strong>
                                      Programa de mercadeo digital
                                    </strong>
                                  </h5>
                                  <p>
                                    <a
                                      href="https://registro.nibi.com.co/programa-mercadeo-digital"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Conoce todo aquí
                                    </a>
                                  </p>
                                  {/* <p>
                                    Objetivo: capacitar a tu organización en estrategias de mercadeo digital para que lleguen de mejor manera a su público de interés, aumenten el número de personas que las conocen, que las pueden apoyar y así aumentar sus ingresos mensuales.
                                  </p> */}
                                </div>
                              </Col>

                              <Col md={4}>
                                <div className="academy-card">
                                  <h5>
                                    <strong>
                                      Programa de Fortalecimiento de Capacidades
                                      en Movilización de Recursos 2
                                    </strong>
                                  </h5>
                                  <p>
                                    <a
                                      href="https://registro.nibi.com.co/programa-movilizacion-de-recursos-ciclo2"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Conoce todo aquí
                                    </a>
                                  </p>
                                  {/* <p>
                                    Objetivo: fortalecer las capacidades de tu organización sobre cómo movilizar recursos para sus causas sociales/ambientales a través de dos estrategias complementarias: la cooperación internacional y el crowdfunding
                                  </p> */}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        ) : academyTab === 1 ? (
                          <div>
                            <p style={{ margin: 0 }}>
                              Te compartimos el conocimiento que tu fundación
                              debe tener para maximizar su impacto
                            </p>

                            <Row>
                              <Col md={6}>
                                <div className="landing-section">
                                  <h1>Webinars pasados</h1>
                                  <p>Conoce los eventos que hemos realizado</p>

                                  <Image
                                    src={require("../img/FoundationLanding/Webinars/webinar-1.jpg")}
                                    className="webinar-img"
                                    rounded
                                  />

                                  <div
                                    style={{
                                      width: "fit-content",
                                      margin: "0 auto",
                                    }}
                                  >
                                    <a
                                      href="https://www.youtube.com/watch?v=GiIfR2FPCfU"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <button className="landing-button-2">
                                        Revívelo acá
                                      </button>
                                    </a>
                                  </div>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="landing-section">
                                  <br />
                                  <br />
                                  <br />
                                  <Image
                                    src={require("../img/FoundationLanding/Webinars/webinar-2.png")}
                                    className="webinar-img"
                                    rounded
                                  />

                                  <div
                                    style={{
                                      width: "fit-content",
                                      margin: "0 auto",
                                    }}
                                  >
                                    <a
                                      href={`https://www.youtube.com/watch?v=CHLmIcVeq4w&t=3s&ab_channel=Nibi`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <button className="landing-button-2">
                                        Revívelo acá
                                      </button>
                                    </a>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        ) : (
                          <div>
                            <p style={{ margin: 0 }}>
                              Charlamos sobre temas de interés para el sector
                              social
                            </p>

                            <Row>
                              {/* <Col xs={6}>
                                <div className="landing-section">
                                  <h1>
                                    Próximos webinars
                                  </h1>
                                  <p>
                                    Conoce y regístrate a nuestos próximos eventos
                                  </p>

                                  <Image
                                    src={require('../img/FoundationLanding/Webinars/webinar-1.jpg')}
                                    className="webinar-img"
                                    rounded
                                  />

                                  <div style={{ textAlign: 'center' }}>
                                    <a href="https://glm.zoom.us/meeting/register/tZEqceirpjkjH9DjFCZEDVuqMoZlbRUrXKQy" target="_blank" rel="noopener noreferrer">
                                      Inscríbete gratis acá
                                    </a>
                                  </div>
                                </div>
                              </Col> */}
                              <Col md={6}>
                                <div className="landing-section">
                                  <h1>Foros pasados</h1>
                                  <p>Conoce los eventos que hemos realizado</p>

                                  <Image
                                    src={require("../img/FoundationLanding/Foros/foro-1.png")}
                                    className="webinar-img"
                                    rounded
                                  />

                                  <div
                                    style={{
                                      width: "fit-content",
                                      margin: "0 auto",
                                    }}
                                  >
                                    <a
                                      href={`https://www.youtube.com/watch?v=flF6qersARY&t=1934s&ab_channel=Nibi`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <button className="landing-button-2">
                                        Revívelo acá
                                      </button>
                                    </a>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="landing-section" style={{ marginTop: 0 }}>
                      <p style={{ margin: 0 }}>
                        Nuestra colmena ya cuenta con{" "}
                        {foundationNumber > 0 ? (
                          foundationNumber
                        ) : (
                          <Spinner
                            variant="warning"
                            size="sm"
                            animation="border"
                          />
                        )}{" "}
                        fundaciones en toda Colombia
                      </p>
                      <p>
                        En conjunto con todos nuestros aliados traemos los
                        mejores beneficios para nuestra comunidad
                      </p>
                      <h1>Aliados</h1>
                      <Allies />
                    </div>
                  </div>
                )}
              </div>
            )}
          </Col>
        </Row>
      </Container>

      <div className="desc-section-1">
        <Container>
          <Row>
            <Col>
              <Image
                rounded
                src={require("../img/FoundationLanding/personas.png")}
                style={{
                  maxHeight: "350px",
                  display: "block",
                  margin: "0 auto",
                }}
              />
            </Col>
            <Col>
              <h3>¿Quiénes pueden ser parte?</h3>
              <p>
                Las llamamos fundaciones, pero a la colmena Nibi se pueden sumar{" "}
                <strong>3 tipos de organizaciones</strong> sin ánimo de lucro en
                Colombia:
              </p>
              <div className="desc-info">
                <div className="desc-items">
                  <div>
                    <span style={{ backgroundColor: "#7827EE" }}></span>{" "}
                    Fundaciones
                  </div>
                  <div>
                    <span style={{ backgroundColor: "#9D6CE4" }}></span>{" "}
                    Asociaciones
                  </div>
                  <div>
                    <span style={{ backgroundColor: "#D0B0FF" }}></span>{" "}
                    Corporaciones
                  </div>
                </div>
                <button
                  className="landing-button"
                  onClick={() => setShowDemoModal(true)}
                  style={{ backgroundColor: "#FCC010" }}
                >
                  Quiero más información
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row>
          <Col>
            <div className="landing-section">
              <h1>
                Ya más de{" "}
                {foundationNumber > 0 ? (
                  foundationNumber
                ) : (
                  <Spinner variant="warning" size="sm" animation="border" />
                )}{" "}
                fundaciones hacen parte de la comunidad nibi
              </h1>

              <Foundations />
            </div>
            <div className="landing-section" style={{ marginBottom: "50px" }}>
              <h1>Invierte en tu organización y crece con nibi</h1>
              <p>
                Tenemos la solución más completa para acompañar a tu
                organización a maximizar su impacto
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="plans-section" ref={planRef}>
        <Container>
          <Row>
            <Col>
              <FoundationSubscriptionPlans2022
                createAccount={createAccount}
                token={props.token}
                history={props.history}
                environment={"foundationsLanding"}
                showDemoModal={(plan) => {
                  setPlanInterest(plan);
                  setShowDemoModal(true);
                }}
              />
              <div className="landing-section">
                <h1>Planes que se ajustan a las necesidades de tu fundación</h1>
              </div>
              <div style={{ textAlign: "center" }}>
                <video
                  controls
                  width={isMobile ? "300" : "700"}
                  height={isMobile ? "200" : "400"}
                >
                  <source
                    src="https://nibi-videos.s3.us-east-2.amazonaws.com/nibiPlanes.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="landing-section">
                <h1>
                  ¿Tienes dudas? ¿Quieres una asesoría para saber <br /> cuál es
                  el mejor plan para tu organización?
                </h1>
              </div>

              {/* <div style={{ width: 'fit-content', margin: '0 auto' }}>
                <a href="https://meetings.hubspot.com/adriana-paola-castano" target="_blank" rel="noopener noreferrer">
                  <button className="landing-button-2" style={{ marginBottom: '40px' }}>
                    Agenda tu cita
                  </button>
                </a>
              </div> */}

              <div style={{ width: "fit-content", margin: "0 auto" }}>
                <a
                  href={`https://api.whatsapp.com/send?phone=573152930296&text=Hola%20Equipo%20Nibi%20%F0%9F%9A%80%2C%20me%20gustar%C3%ADa%20conocer%20m%C3%A1s%20sobre%20sus%20planes%20de%20suscripci%C3%B3n%20para%20fundaciones.`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button
                    className="landing-button-2"
                    style={{ marginBottom: "30px" }}
                  >
                    Escríbenos por Whatsapp
                  </button>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row>
          <Col xs={12}>
            <div className="landing-section" style={{ marginBottom: "50px" }}>
              <h1>Otras maneras de aumentar tu impacto</h1>
              <p>
                Contamos con otras maneras innovadoras de ayudar a tu
                organización a recaudar más fondos y a crecer.
              </p>
            </div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "50px" }}>
          <Col>
            <div className="landing-section" style={{ marginTop: 0 }}>
              <h1>Carreras virtuales</h1>
              <p style={{ textAlign: "left" }}>
                Une la pasión de las personas por hacer deporte con sus ganas de
                ayudar.
              </p>
              <p style={{ textAlign: "left" }}>
                En el 2020 hicimos una carrera virtual para recaudar fondos y
                fue un éxito. Lleva esta iniciativa a tu organización.{" "}
                <a
                  href="https://nibi.com.co/carreravirtual"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Conoce más acá
                </a>
              </p>
              <a
                href={`https://api.whatsapp.com/send?phone=573152930296&text=Hola%2C%20quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20c%C3%B3mo%20organizar%20una%20Carrera%20Virtual%20con%20nibi%20%F0%9F%9A%80`}
                target="_blank"
                rel="noopener noreferrer"
              >
                ¿Te interesa? Escríbenos
              </a>
            </div>
          </Col>
          <Col>
            <img
              src={require("../img/FoundationLanding/salvemos2020.png")}
              style={{ maxHeight: "400px" }}
              alt="Salevemos el 2020"
            />
          </Col>
        </Row>

        <Row>
          <Col
            lg={6}
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            {!playVideo ? (
              <div className="video-cover" onClick={() => setPlayVideo(true)}>
                <i className="fas fa-play-circle" />
              </div>
            ) : (
              <iframe
                width="100%"
                height="315"
                frameBorder="0"
                src="https://www.youtube.com/embed/gSx3knkyAaQ"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            )}
          </Col>
          <Col lg={6}>
            <div className="landing-section" style={{ marginTop: 0 }}>
              <h1>Círculos de Dar</h1>
              <p style={{ textAlign: "left" }}>
                De manera innovadora presentamos varias organizaciones a un
                grupo de personas y empresas para que en un solo día logren su
                meta de recaudación.
              </p>
              <a
                href={`https://api.whatsapp.com/send?phone=573152930296&text=Hola%2C%20quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20c%C3%B3mo%20organizar%20una%20Carrera%20Virtual%20con%20nibi%20%F0%9F%9A%80`}
                target="_blank"
                rel="noopener noreferrer"
              >
                ¿Te interesa? Escríbenos
              </a>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="landing-section">
              <h1>Nibi en los medios</h1>

              <Media />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="landing-section">
              <h1>Reconocimientos Nibi</h1>
            </div>
            <Awards />
          </Col>
        </Row>
      </Container>
      <Modal show={showDemoModal} onHide={() => setShowDemoModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Déjanos tu información</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => handleContactSubmit(e)}>
            <Form.Row>
              <Form.Group as={Col} md={6}>
                <Form.Label>Nombre completo</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nombre completo"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group as={Col} md={6}>
                <Form.Label>Nombre de tu organización</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nombre de tu organización"
                  name="foundationName"
                  value={foundationName}
                  onChange={(e) => setFoundationName(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group as={Col} md={6}>
                <Form.Label>Teléfono</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Teléfono"
                  name="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group as={Col} md={6}>
                <Form.Label>Correo electrónico</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Correo electrónico"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group as={Col} xs={12}>
                <Form.Label>Déjanos tu mensaje</Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  rows={4}
                  placeholder="Déjanos tu mensaje..."
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                />
              </Form.Group>
            </Form.Row>

            {messageSent ? (
              <p style={{ textAlign: "center" }}>
                Mensaje enviado, ¡te contactaremos pronto!
              </p>
            ) : (
              <button
                className="hexagon-btn"
                type="submit"
                style={{ display: "block", margin: "0 auto" }}
              >
                {sendingMessage ? (
                  <Spinner size="sm" animation="border" variant="dark" />
                ) : (
                  "Enviar"
                )}
              </button>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

const FoundationsLanding = connect(
  select,
  mapDispatchToProps
)(ConnectedFoundationsLanding);

export default FoundationsLanding;
