import React from "react";
import { useState, useEffect } from "react";
import { Col, Form, Spinner } from "react-bootstrap";
import RecurringPaymentTransaction from "../RecurringPayment/RecurringPaymentTransaction";
import TresLetrasTransaction from "./TresLetrasTransaction";
import "./styles/styles.scss";

function PaymentExtension(props) {
  const {
    donationPlanId,
    foundationId,
    foundationName,
    qty,
    customDonation,
    items,
  } = props;
  const [stage, setStage] = useState(0);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const [plan, setPlan] = useState();

  useEffect(() => {
    if (user && (!donationPlanId || plan)) setStage(1);
  }, [user, plan, donationPlanId]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    setLoading(true);

    const userFetch = getUser();
    const donationFetch = getDonationPlan();

    await userFetch;
    await donationFetch;

    setLoading(false);
  };

  const handleBack = () => {
    if (stage === 0) props.onBack();
    else setStage(stage - 1);
  };

  const getUser = () => {
    return fetch("/users/extention/create/user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email.toLowerCase().trim(),
        firstName: name,
        lastName,
      }),
    })
      .then((resp) => resp.json())
      .then((json) => {
        if (json.success) setUser(json.user);
      });
  };

  const getDonationPlan = () => {
    if (donationPlanId) {
      return fetch("/recurringPayments/getCustomPlan", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id: donationPlanId,
          foundationId,
        }),
      })
        .then((resp) => resp.json())
        .then((json) => {
          if (json.success) setPlan(json.plan);
        });
    }
  };

  return (
    <div className="payment-extension-component">
      <i className="fas fa-arrow-circle-left back-icon" onClick={handleBack} />
      <h3>Paso {stage + 1} de 2</h3>
      {stage === 0 ? (
        <Form onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} md={4}>
              <Form.Label>Correo</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Correo electrónico"
                required
              />
            </Form.Group>
            <Form.Group as={Col} md={4}>
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Nombre"
                required
              />
            </Form.Group>
            <Form.Group as={Col} md={4}>
              <Form.Label>Apellido</Form.Label>
              <Form.Control
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Apellido"
                required
              />
            </Form.Group>
            <Col xs={12}>
              <button className="nibi-btn">
                {loading ? (
                  <Spinner variant="warning" size="sm" animation="border" />
                ) : (
                  "Continuar"
                )}
              </button>
            </Col>
          </Form.Row>
        </Form>
      ) : stage === 1 ? (
        foundationId !== "6241df0a0c27c9db96fa3106" ? (
          <div>
            <RecurringPaymentTransaction
              selectedPlan={plan}
              foundationId={foundationId}
              foundationName={foundationName}
              customDonation={customDonation || false}
              isPaymentLink={true}
              paymentUser={user}
              planQty={qty}
              donationDay={new Date().getUTCDate() <= 15 ? 5 : 25}
            />
          </div>
        ) : (
          <div>
            <TresLetrasTransaction
              selectedPlan={plan}
              foundationId={foundationId}
              foundationName={foundationName}
              customDonation={customDonation || false}
              isPaymentLink={true}
              paymentUser={user}
              planQty={qty}
              donationDay={new Date().getUTCDate() <= 15 ? 5 : 25}
              items={items}
            />
          </div>
        )
      ) : null}
    </div>
  );
}

export default PaymentExtension;
