import React, { useState, useEffect, useRef } from 'react';
import { Col, Container, Row, Modal, Form } from 'react-bootstrap';
import { withRouter } from 'react-router';
import './styles/nibify.scss';

function NibifyFilter(props) {

  const { returnResults } = props;

  const categoriesSection = useRef(null);
  const odsSection = useRef(null);

  const [organizations, setOrganizations] = useState([]);
  const [ods, setOds] = useState([]);
  const [causes, setCauses] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedOds, setSelectedOds] = useState([]);
  const [selectedCauses, setSelectedCauses] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [typeFilter, setTypeFilter] = useState('ORGANIZATION');
  const [certificateFilter, setCertificateFilter] = useState(false);
  const [foundationName, setFoundationName] = useState('');

  const [categoriesOpen, setCategoriesOpen] = useState(true);
  const [odsOpen, setOdsOpen] = useState(false);
  const [causesOpen, setCausesOpen] = useState(false);
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);

  const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop - 200);

  useEffect(() => {
    if (selectedCategory === '') return;
    setSelectedOds([]);
    getOds();
    setOdsOpen(true);
    // scrollToRef(categoriesSection);
  }, [selectedCategory]);

  const getOds = async () => {
    setSelectedCauses([]);
    await fetch(`/foundations/nibify/ods/${selectedCategory}`)
      .then(data => data.json())
      .then(res => {
        setOrganizations(res.organizations);
        setOds(res.ods);
      });
  }

  const getCauses = async () => {
    await fetch('/foundations/nibify/causes', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        odsFilter: selectedOds
      }),
    })
      .then(data => data.json())
      .then(res => {
        setOrganizations(res.organizations);
        setCauses(res.causes);
        scrollToRef(odsSection);
      });
  }

  const searchOrganization = async () => {

    const prom = await fetch('/foundations/nibify/results/foundation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: foundationName.trim().toLowerCase()
      })
    });

    const resp = await prom.json();

    setOrganizations(resp.organizations);

    if (returnResults) {
      returnResults(resp.organizations, 'ORGANIZATION');
      window.scrollTo(0, 0);
    }
  };

  const getResults = async () => {
    await fetch('/foundations/nibify/results', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        odsFilter: selectedOds,
        causesFilter: selectedCauses
      })
    })
      .then(data => data.json())
      .then(res => {
        setOrganizations(res.organizations);

        const filteredResults = res.organizations
          .filter(org => certificateFilter ? org.legalFilesValidation : true)
          .filter(org => selectedLocation.length > 0 ? selectedLocation.includes(org.location) : true);

        if (typeFilter === 'ORGANIZATION') {
          const orgs = filteredResults.length > 0 ? filteredResults : res.organizations;
          if (returnResults) {
            returnResults(orgs, 'ORGANIZATION');
            window.scrollTo(0, 0);
          }
        } else {
          let programs = [];

          filteredResults.forEach(org => {
            if (org.activePrograms.length > 0) programs = programs.concat(org.activePrograms)
          });

          if (programs.length === 0) {
            res.organizations.forEach(org => {
              if (org.activePrograms.length > 0) programs = programs.concat(org.activePrograms)
            });
          }
          if (returnResults) {
            returnResults(programs, 'PROJECT');
            window.scrollTo(0, 0);
          }
        }
      });
  }

  const handlePickOds = category => {

    if (selectedOds.find(cat => cat === category.odsValue)) {
      setSelectedOds(selectedOds.filter(cat => cat !== category.odsValue))
    } else {
      if (selectedOds.length >= 2) return;
      setSelectedOds(selectedOds.concat([category.odsValue]))
    }
  }

  const handlePickCause = cause => {

    if (selectedCauses.find(cat => cat === cause.categoryValue)) {
      setSelectedCauses(selectedCauses.filter(cat => cat !== cause.categoryValue))
    } else {
      if (selectedCauses.length >= 3) return;
      setSelectedCauses(selectedCauses.concat([cause.categoryValue]))
    }
  }

  const handlePickLocation = org => {

    if (selectedLocation.find(loc => loc === org.location)) {
      setSelectedLocation(selectedLocation.filter(loc => loc !== org.location))
    } else {
      setSelectedLocation(selectedLocation.concat([org.location]))
    }
  }

  return (
    <Container fluid className='nibifyFilter'>

      <div className='filterStep' ref={categoriesSection}>
        <div className='filterStepTitle' onClick={() => setCategoriesOpen(!categoriesOpen)}>
          <img className='filterStepTitleImg' src={require('../img/Home/Escoger_logo.svg')} alt='Escoge una categoría' />
          <span>Escoge una categoría</span>
          <i className={`fas fa-chevron-${categoriesOpen ? 'up' : 'down'}`} />
        </div>
        {
          categoriesOpen && <>
            <hr />
            <Row noGutters>
              <Col>
                <div style={{ borderBottomRightRadius: '0px', borderTopRightRadius: '0px' }}
                  className={`filterCard ${selectedCategory === 'SOCIALES' ? 'active' : ''}`}
                  onClick={() => setSelectedCategory('SOCIALES')}
                ><img src={require('../img/Home/Sociales_logo.svg')} alt='Sociales' /> Sociales
                </div>
              </Col>
              <Col>
                <div style={{ borderBottomLeftRadius: '0px', borderTopLeftRadius: '0px' }}
                  className={`filterCard ${selectedCategory === 'AMBIENTALES' ? 'active' : ''}`}
                  onClick={() => setSelectedCategory('AMBIENTALES')}
                ><img src={require('../img/Home/Ambientales_logo.svg')} alt='Ambientales' /> Ambientales
                </div>
              </Col>
            </Row>
          </>
        }

      </div>

      <div className='filterStep' ref={odsSection}>
        <div className='filterStepTitle' onClick={() => setOdsOpen(!odsOpen)}>
          <img className='filterStepTitleImg' src={require('../img/Home/Causas_logo.svg')} alt='¿Qué causas te mueven?' />
          <span>¿Qué causas te mueven?</span>
          <i className={`fas fa-chevron-${odsOpen ? 'up' : 'down'}`} />
        </div>
        {
          odsOpen && <>
            <hr />
            <div className='filterStepLimit'>Selecciona 2 opciones máximo</div>
            {
              ods.map(category => <div key={category.odsValue}
                className={`filterCardSmall ${selectedOds.find(cat => cat === category.odsValue) ? 'active' : ''}`}
                onClick={() => handlePickOds(category)}
              >
                {category.odsName} {selectedOds.find(cat => cat === category.odsValue) && <span className="removeFilter">X</span>}
              </div>)
            }
            <div style={{ textAlign: 'center' }}>
              <button
                className='continueBtn'
                disabled={selectedOds.length < 1}
                onClick={() => {
                  getCauses();
                  setCausesOpen(true);
                }}
              >Continuar</button>
            </div>
          </>
        }
      </div>

      <div className='filterStep'>
        <div className='filterStepTitle' onClick={() => setCausesOpen(!causesOpen)}>
          <img className='filterStepTitleImg' src={require('../img/Home/trabajo_logo.svg')} alt='¿Que trabajen con?' />
          <span>¿Que trabajen con?</span>
          <i className={`fas fa-chevron-${causesOpen ? 'up' : 'down'}`} />
        </div>
        {
          causesOpen && <>
            <hr />
            <div className='filterStepLimit'>Selecciona 3 opciones máximo</div>
            {
              causes.map(cause => <div key={cause.categoryValue}
                className={`filterCardSmall ${selectedCauses.find(cat => cat === cause.categoryValue) ? 'active' : ''}`}
                onClick={() => handlePickCause(cause)}
              >
                {cause.categoryLabel} {selectedCauses.find(cat => cat === cause.categoryValue) && <span className="removeFilter">X</span>}
              </div>)
            }
            <div style={{ textAlign: 'center' }}>
              <button
                className='continueBtn'
                disabled={selectedCauses.length < 1}
                onClick={() => getResults()}
              >Continuar
            </button>
            </div>
          </>
        }
      </div>
      {
        causesOpen && causes.length > 0 && <div className='advancedSearch' onClick={() => setShowAdvancedSearch(true)}>
          <i className={`fas fa-chevron-${showAdvancedSearch ? 'down' : 'right'}`} /> Búsqueda avanzada
        </div>
      }

      <hr />
      <div className="filterStep search-foundation-section">
        <Form.Group className="foundation-search-input">
          <Form.Control
            type="text"
            placeholder="Busca por nombre"
            value={foundationName}
            onChange={e => setFoundationName(e.target.value)}
          />
        </Form.Group>
        <button
          className="continueBtn"
          onClick={searchOrganization}
        >
          Continuar
        </button>
      </div>

      <Modal show={showAdvancedSearch} onHide={() => setShowAdvancedSearch(false)} centered>
        <Container fluid>
          <div className='advancedSearchModal'>
            <i className='fas fa-times closeBtn' onClick={() => setShowAdvancedSearch(false)} />
            <div className='advancedSearchTitle'>Búsqueda avanzada</div>
            {
              organizations.find(o => o.location) && <>
                <div className='advancedSearchFilter'>Ubicación</div>
                <hr />
                {
                  organizations.filter(o => o.location).map(org => <div key={org._id}
                    onClick={() => handlePickLocation(org)}
                    className={`filterCardSmall ${selectedLocation.find(loc => loc === org.location) ? 'active' : ''}`}
                  > {org.location}
                  </div>)
                }
              </>
            }
            {
              organizations.find(org => org.activePrograms && org.activePrograms.length > 0) && <>
                <div className='advancedSearchFilter'>Quiero ver</div>
                <hr />
                <Row noGutters>
                  <Col>
                    <div
                      onClick={() => setTypeFilter('PROJECT')}
                      style={{ borderBottomRightRadius: '0px', borderTopRightRadius: '0px' }}
                      className={`filterCard ${typeFilter === 'PROJECT' ? 'active' : ''}`}
                    >Campaña
                </div>
                  </Col>
                  <Col>
                    <div
                      onClick={() => setTypeFilter('ORGANIZATION')}
                      style={{ borderBottomLeftRadius: '0px', borderTopLeftRadius: '0px' }}
                      className={`filterCard ${typeFilter === 'ORGANIZATION' ? 'active' : ''}`}
                    >Organización
                </div>
                  </Col>
                </Row>
              </>
            }
            <div className='advancedSearchFilter'>¿Te interesa obtener certificado de donación?</div>
            <hr />
            <Row noGutters>
              <Col>
                <div
                  onClick={() => setCertificateFilter(true)}
                  style={{ borderBottomRightRadius: '0px', borderTopRightRadius: '0px' }}
                  className={`filterCard ${certificateFilter ? 'active' : ''}`}
                >Sí, certificado de donación
                </div>
              </Col>
              <Col>
                <div
                  onClick={() => setCertificateFilter(false)}
                  style={{ borderBottomLeftRadius: '0px', borderTopLeftRadius: '0px' }}
                  className={`filterCard ${!certificateFilter ? 'active' : ''}`}
                >No, no es indispensable
                </div>
              </Col>
            </Row>
            <div style={{ textAlign: 'center' }}>
              <button
                className='continueBtn'
                onClick={() => setShowAdvancedSearch(false)}
              >Continuar
            </button>
            </div>
          </div>
        </Container>
      </Modal>

    </Container>
  )
}

export default withRouter(NibifyFilter);
