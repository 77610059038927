import React, { Component } from 'react';
import { setInStorage } from '../utils/storage';
import { Modal, Spinner, Form, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { userLogin, setUser, setIsFoundation, setIsEnterprise, setUserId, setToken, setShowRegisterModal, setShowPaymentModal, setShowingPaymentModal } from '../Redux/modules/auth';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import AppSpinner from '../Extras/AppSpinner';
import GoogleLogo from './styles/img/Google_Logo.png';
import userIcon from '../img/personaNatural.svg';
import foundationIcon from '../img/organizacion.svg';
import enterpriseIcon from '../img/comercioResponsable.svg';
import FoundationEnterpriseRegisterComponent from './FoundationEnterpriseRegister';
import { wsConnect } from '../Redux/modules/websocket';
import wsUrl from '../utils/wsUrl';
import './styles/login-styles.scss';
import './styles/RegisterModal.scss';

function mapDispatchToProps(dispatch) {
  return {
    userLogin: () => dispatch(userLogin()),
    setUser: user => dispatch(setUser(user)),
    setUserId: userId => dispatch(setUserId(userId)),
    setIsFoundation: isFoundation => dispatch(setIsFoundation(isFoundation)),
    setIsEnterprise: isEnterprise => dispatch(setIsEnterprise(isEnterprise)),
    setToken: token => dispatch(setToken(token)),
    setShowRegisterModal: options => dispatch(setShowRegisterModal(options)),
    wsConnect: host => dispatch(wsConnect(host)),
    setShowPaymentModal: show => dispatch(setShowPaymentModal(show)),
    setShowingPaymentModal: show => dispatch(setShowingPaymentModal(show))
  };
}

const select = state => {
  return {
    language: state.auth.language,
    registerUserType: state.auth.registerUserType,
    plan: state.auth.plan,
    showPaymentModal: state.auth.showPaymentModal,
  };
};

class ConnectedRegisterModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confPassword: '',
      businessName: '',
      isLoading: false,
      isLoadingSignup: false,
      formValidated: false,
      signupError: '',
      showRegisterOptions: true,
      registerUserType: 'USER'
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateUserSession = this.updateUserSession.bind(this);
    this.handleSignIn = this.handleSignIn.bind(this);
    this.handleSignUp = this.handleSignUp.bind(this);
    this.responseGoogleSuccess = this.responseGoogleSuccess.bind(this);
    this.responseGoogleFailure = this.responseGoogleFailure.bind(this);
    this.responseFacebook = this.responseFacebook.bind(this);
    this.openLoginModal = this.openLoginModal.bind(this);
    this.openRegisterForm = this.openRegisterForm.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  onInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  async handleSubmit(event) {

    event.preventDefault();
    event.stopPropagation();

    this.setState({ formValidated: true, isLoading: true, signupError: '' });

    const form = event.currentTarget;
    const { password, confPassword, email } = this.state;
    const validPassword = password === confPassword;

    if (!validPassword) this.setState({ signupError: 'Las contraseñas deben coincidir.' });

    if (form.checkValidity() && validPassword) {

      const findUser = await fetch(`/users/find/email?email=${email.toLowerCase()}`);
      const findUserJson = await findUser.json();

      if (findUserJson.exists) {
        this.setState({ signupError: 'Ya existe un usuario con ese correo.' });
      } else {
        const success = await this.handleSignUp();

        if (!success) {
          this.setState({ signupError: 'Error al registrarse.' });
        } else {
          if (this.props.plan) window.location.href = '/suscripciones?plan=' + this.props.plan;
          else if (window.location.pathname.includes('/carreravirtual')) window.location.href = '/carreravirtual?show_popup=true';
          this.handleClose();
        }
      }
    }

    this.setState({ isLoading: false });

  }

  async updateUserSession(userId, isFoundation, isEnterprise, token) {

    this.props.setIsFoundation({ isFoundation });
    this.props.setIsEnterprise({ isEnterprise });
    this.props.setToken({ token });

    const dataUser = await fetch('/users/user/' + userId);
    const res = await dataUser.json();

    this.props.setUser({ user: res });
    this.props.setUserId({ userId });
    this.props.userLogin();
    this.props.wsConnect(`${wsUrl}/${token}`);
    this.props.setShowPaymentModal(true);
    this.props.setShowingPaymentModal(false);
  }

  async handleSignIn() {

    const { email, password } = this.state;

    const fetchParams = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email, password
      })
    };

    const promUsers = await fetch('/signin/account/signin', fetchParams);
    const jsonUsers = await promUsers.json();

    if (jsonUsers) {
      setInStorage('nibi_app', { token: jsonUsers.token });
      await this.updateUserSession(jsonUsers.userId, jsonUsers.isFoundation, jsonUsers.isEnterprise, jsonUsers.token);

      return jsonUsers.success;
    }

    return false;
  }

  async handleSignUp() {

    const {
      firstName,
      lastName,
      email,
      password,
      registerUserType,
      businessName
    } = this.state;

    const prom = await fetch('/signin/account/signup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        password,
        firstName,
        lastName,
        isBusiness: registerUserType === 'BUSINESS',
        businessName
      })
    });

    const json = await prom.json();

    if (json.success) {

      await this.handleSignIn();

      await fetch('/email/userRegistered/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email,
          firstName
        })
      });

    }

    return json.success;
  }

  async responseGoogleSuccess(response) {

    this.setState({ isLoadingSignup: true });
    const { registerUserType } = this.state;

    const tokenId = response.tokenId;
    const body = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token: tokenId,
        isBusiness: registerUserType === 'BUSINESS'
      })
    }

    const promSignup = await fetch('/signin/google/signup', body);
    const jsonSignup = await promSignup.json();

    if (jsonSignup.success) {
      const promLogin = await fetch('/signin/google/signin', body);
      const jsonLogin = await promLogin.json();

      if (jsonLogin.success) {
        setInStorage('nibi_app', { token: jsonLogin.token });
        await this.updateUserSession(jsonLogin.userId, jsonLogin.isFoundation, jsonLogin.isEnterprise, jsonLogin.token);

        const profile = response.profileObj;

        await fetch('/email/userRegistered/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: profile.email,
            firstName: profile.givenName
          })
        });
        if (this.props.plan) window.location.href = '/suscripciones?plan=' + this.props.plan;
        else if (window.location.pathname.includes('/carreravirtual')) window.location.href = '/carreravirtual?show_popup=true';
        else if (!window.location.pathname.includes('/programas') && !this.props.showPaymentModal) window.location.href = '/dashboardUser/perfil';

        this.handleClose();

      } else {
        this.setState({ signupError: jsonLogin.message });
      }

      this.setState({ isLoadingSignup: false });

    } else {
      this.setState({ signupError: jsonSignup.message });
      this.setState({ isLoadingSignup: false });

    }

  }

  responseGoogleFailure() {
    //
  }

  async responseFacebook(response) {

    this.setState({ isLoadingSignup: true });

    const { registerUserType } = this.state;
    const { name, email, accessToken, userID } = response;

    const promSignup = await fetch('/signin/facebook/signup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name,
        email,
        accessToken,
        userID,
        isBusiness: registerUserType === 'BUSINESS'
      })
    });

    const promJsonSignup = await promSignup.json();

    if (promJsonSignup.success) {
      const promLogin = await fetch('/signin/facebook/signin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email,
          accessToken,
          userID,
          name
        })
      });

      const jsonLogin = await promLogin.json();

      if (jsonLogin.success) {
        setInStorage('nibi_app', { token: jsonLogin.token });
        await this.updateUserSession(jsonLogin.userId, jsonLogin.isFoundation, jsonLogin.isEnterprise, jsonLogin.token);

        await fetch('/email/userRegistered/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email,
            firstName: name
          })
        });
        if (this.props.plan) window.location.href = '/suscripciones?plan=' + this.props.plan;
        else if (window.location.pathname.includes('/carreravirtual')) window.location.href = '/carreravirtual?show_popup=true';
        else if (!window.location.pathname.includes('/programas') && !this.props.showPaymentModal) window.location.href = '/dashboardUser/perfil';

        this.handleClose();

      } else {
        this.setState({ signupError: jsonLogin.message });
      }

      this.setState({ isLoadingSignup: false });

    } else {
      this.setState({ signupError: promJsonSignup.message });
      this.setState({ isLoadingSignup: false });

    }
  }

  openLoginModal() {
    this.handleClose();
    this.props.handleShowLoginModal();
  }

  openRegisterForm(option) {
    this.setState({ showRegisterOptions: false, registerUserType: option });
  }

  handleClose() {
    this.setState({
      showRegisterOptions: true
    });

    this.props.handleCloseRegisterModal();
  }

  render() {

    const registerUserType = this.props.registerUserType ? this.props.registerUserType : this.state.registerUserType;
    const showRegisterOptions = this.props.registerUserType ? false : this.state.showRegisterOptions;
    const {
      firstName,
      lastName,
      email,
      password,
      confPassword,
      businessName,
      isLoading,
      isLoadingSignup,
      signupError,
      formValidated
    } = this.state;

    const { language } = this.props;
    const isBusiness = registerUserType === 'BUSINESS';

    const REGISTER_AS = (language === 'ES' ? 'Regístrate como ' : "S'inscrire comme ");
    const REGISTER = (language === 'ES' ? 'Registrarse' : "Créer un compte");
    const PERSON = (language === 'ES' ? 'Persona natural' : "Personne naturelle");
    const ENTERPRISE = (language === 'ES' ? 'Empresa' : "Entreprise");
    const HAS_ACCOUNT_1 = (language === 'ES' ? '¿Ya tienes cuenta? ' : "Vous avez déjà un compte? ");
    const HAS_ACCOUNT_2 = (language === 'ES' ? 'Ingresa aquí' : "Entrer ici");
    const WITH_GOOGLE = (language === 'ES' ? 'Entrar con Google' : "Continuez avec Google");
    const WITH_FACEBOOK = (language === 'ES' ? 'Entrar con Facebook' : "Continuez avec Facebook");
    const INVALID_PASSWORD = (language === 'ES' ? 'Ingresa una contraseña válida.' : "Veuillez saisir un mot de passe valide.");
    const INVALID_EMAIL = (language === 'ES' ? 'Ingresa un email válido.' : "Veuillez saisir un email valide.");
    const INVALID_NAME = (language === 'ES' ? 'Ingresa un nombre válido.' : "Veuillez saisir un prénom valide.");
    const INVALID_LAST_NAME = (language === 'ES' ? 'Ingresa un apellido válido.' : "Veuillez saisir un nom de famille valide.");
    const INSERT_NAME = (language === 'ES' ? 'Ingresa tu nombre' : "Prénom");
    const INSERT_BUSINESS_NAME = (language === 'ES' ? 'Razón social' : "Raison sociale");
    const INSERT_LAST_NAME = (language === 'ES' ? 'Ingresa tu apellido' : "Nom de famille");
    const INSERT_EMAIL = (language === 'ES' ? 'Correo electrónico' : "Email");
    const INSERT_PASSWORD = (language === 'ES' ? 'Contraseña' : "Mot de passe");
    const CONFIRM_PASSWORD = (language === 'ES' ? 'Confirmar contraseña' : "Confirmer mot de passe");

    if (showRegisterOptions) {
      return (
        <div>
          <Modal show={this.props.show} onHide={this.handleClose}>
            <Modal.Header closeButton className="register-options-modal register-options-header">
              <Modal.Title>
                Regístrate ahora
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="register-options-modal register-options-modal-body">
              <div className="register-business" style={{ marginTop: '0' }}>
                <p>
                  ¿Ya tienes cuenta? <span onClick={this.openLoginModal}>ingresa aquí</span>
                </p>
              </div>
              <div className="register-option" onClick={() => this.openRegisterForm('USER')}>
                <img src={userIcon} alt="Ícono persona" />
                <div className="register-option-text">
                  <h6>Persona Natural <i className="fas fa-angle-right" /></h6>
                  <p>
                    Participa en experiencias, vive<br />
                    aventuras o dona a campañas
                  </p>
                </div>
              </div>

              <div className="register-option" onClick={() => this.openRegisterForm('BUSINESS')}>
                <img src={enterpriseIcon} alt="Ícono empresa con planta en su interior" />
                <div className="register-option-text">
                  <h6>Empresa <i className="fas fa-angle-right" /></h6>
                  <p>
                    Ofrecer a tus empleados un paquete de beneficios.
                  </p>
                </div>
              </div>

              <div className="register-option" onClick={() => this.openRegisterForm('FOUNDATION')}>
                <img src={foundationIcon} alt="Ícono casa con corazón en su interior" />
                <div className="register-option-text">
                  <h6>Organizacion sin ánimo de lucro <i className="fas fa-angle-right" /></h6>
                  <p>
                    Inscribe tus campañas y accede a <br />
                    herramientas de automatización
                  </p>
                </div>
              </div>

              <div className="register-option" onClick={() => this.openRegisterForm('ENTERPRISE')}>
                <img src={enterpriseIcon} alt="Ícono empresa con planta en su interior" />
                <div className="register-option-text">
                  <h6>Marca sostenible <i className="fas fa-angle-right" /></h6>
                  <p>
                    Ofrece descuentos a personas <br />
                    interesadas y aumenta tu visibilidad
                  </p>
                </div>
              </div>

            </Modal.Body>
          </Modal>
        </div>
      );
    }

    if (isLoadingSignup) {
      return (
        <div>
          <Modal show={this.props.show} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                {REGISTER_AS}<strong>{isBusiness ? ENTERPRISE : PERSON}</strong>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AppSpinner />
            </Modal.Body>
          </Modal>
        </div>
      );
    }

    let form;

    if (registerUserType === 'USER' || registerUserType === 'BUSINESS') {
      form = (
        <Form noValidate validated={formValidated} onSubmit={this.handleSubmit}>

          <Form.Row className="google-fb-signup">
            <Col md={6}>
              <GoogleLogin
                clientId="360716219899-r9i7vsuekq108povt1m5cprsoflqndto.apps.googleusercontent.com"
                buttonText={WITH_GOOGLE}
                onSuccess={this.responseGoogleSuccess}
                onFailure={this.responseGoogleFailure}
                render={renderProps => (
                  <button className="hexagon-btn light-color google-login-btn" onClick={renderProps.onClick} disabled={renderProps.disabled}>
                    <div className="logo">
                      <img src={GoogleLogo} alt="Entrar con Google" style={{ height: 23, width: 23 }} />
                    </div>
                    <span className="text">{WITH_GOOGLE}</span>
                  </button>
                )}
              />
            </Col>

            <Col md={6}>
              <FacebookLogin
                appId="207412983912631"
                fields="name,email"
                callback={this.responseFacebook}
                cssClass="fb-login-btn light-color hexagon-btn"
                icon={<i className="fab fa-facebook" />}
                textButton={WITH_FACEBOOK}
                isMobile={false}
              />
            </Col>
          </Form.Row>

          <hr />

          {
            isBusiness &&
            <Form.Group>
              <Form.Control
                type="text"
                value={businessName}
                name="businessName"
                onChange={this.onInputChange}
                placeholder={INSERT_BUSINESS_NAME}
                required
              />
              <Form.Control.Feedback type="invalid">
                {INVALID_NAME}
              </Form.Control.Feedback>
            </Form.Group>
          }
          <Form.Row>
            <Form.Group as={Col} lg={6}>
              <Form.Control
                type="text"
                value={firstName}
                name="firstName"
                onChange={this.onInputChange}
                placeholder={INSERT_NAME}
                required
              />
              <Form.Control.Feedback type="invalid">
                {INVALID_NAME}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} lg={6}>
              <Form.Control
                type="text"
                value={lastName}
                name="lastName"
                onChange={this.onInputChange}
                placeholder={INSERT_LAST_NAME}
                required
              />
              <Form.Control.Feedback type="invalid">
                {INVALID_LAST_NAME}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} lg={12}>
              <Form.Control
                type="email"
                value={email}
                name="email"
                onChange={this.onInputChange}
                placeholder={INSERT_EMAIL}
                required
              />
              <Form.Control.Feedback type="invalid">
                {INVALID_EMAIL}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} lg={6}>
              <Form.Control
                type="password"
                value={password}
                name="password"
                onChange={this.onInputChange}
                required
                placeholder={INSERT_PASSWORD}
              />
              <Form.Control.Feedback type="invalid">
                {INVALID_PASSWORD}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} lg={6}>
              <Form.Control
                type="password"
                value={confPassword}
                name="confPassword"
                onChange={this.onInputChange}
                required
                placeholder={CONFIRM_PASSWORD}
              />
              <Form.Control.Feedback type="invalid">
                {INVALID_PASSWORD}
              </Form.Control.Feedback>
            </Form.Group>

          </Form.Row>

          {
            signupError && <div className="signup-error">{signupError}</div>
          }

          <div className="login-btn-section">
            <span className="privacy-terms">
              <div>Para registrarte debes leer y aceptar</div> <br />

              <Form.Group controlId="formBasicCheckbox">
                <Form.Check inline type="checkbox" id="termsConditions" >
                  <Form.Check.Input inline type="checkbox" required />
                  <Form.Control.Feedback type="invalid">
                    Debes aceptar los términos y condiciones
                  </Form.Control.Feedback>
                </Form.Check>
                <a
                  className="forgot-password"
                  href={
                    registerUserType === 'USER' ?
                      'https://docs.google.com/document/d/e/2PACX-1vRxNMIb79GUH5crhif3_0tf86LRF8ya3EvCHk9KaKjrmaIQkTmerJK77m4YPTOGnQ/pub' :
                      'https://docs.google.com/document/d/e/2PACX-1vQ9CvAU67QW_1B6eK-ljjQHf1P3QRI0vkVSKwHPK2LZBGLFgr_QiIl_-LLaUYsetw/pub'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>los términos y condiciones</span>
                </a>
              </Form.Group>

              <Form.Group controlId="formBasicCheckbox">
                <Form.Check inline type="checkbox" id="privacy">
                  <Form.Check.Input inline type="checkbox" required />
                  <Form.Control.Feedback type="invalid">
                    Debes aceptar las políticas de privacidad
                  </Form.Control.Feedback>
                </Form.Check>
                <a
                  className="forgot-password"
                  href='https://docs.google.com/document/d/e/2PACX-1vQAHdcqEsBdWYxpAXCWWTDBvl2AQxXAZE_qdxIaO7cfRE0Y7DR8VcvhBIERTHKvHQ/pub'
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>políticas de tratamiento de datos</span>
                </a>
                {' '}y{' '}
                <a
                  className="forgot-password"
                  href='https://docs.google.com/document/d/e/2PACX-1vQ0iI3eL_Vc2t-xXKj0WzfPOywq986oIaGp6-Cot9LSPFzb9y0W-bg0GWuQS3w_Ag/pub'
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>solicitud de autorización</span>
                </a>
              </Form.Group>
            </span>

            <button type="submit" className="hexagon-btn modal-button" disabled={isLoading}>
              {
                isLoading ? <Spinner size="sm" animation="border" variant="dark" /> : REGISTER
              }
            </button>
          </div>

        </Form>
      );

    } else {
      form = (
        <FoundationEnterpriseRegisterComponent
          isFoundation={registerUserType === 'FOUNDATION'}
          handleCloseRegisterModal={this.handleClose}
          plan={this.props.plan}
        />
      );
    }

    return (
      <div>
        <Modal show={this.props.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: '1.2em' }}>
              <i
                className="fas fa-arrow-left register-modal-back-btn"
                onClick={() => {
                  this.props.setShowRegisterModal({ show: true, registerUserType: '' });
                  this.setState({ showRegisterOptions: true });
                }}
              />
              {REGISTER_AS}
              <strong>
                {
                  registerUserType === 'BUSINESS' ? ENTERPRISE :
                    registerUserType === 'USER' ? PERSON :
                      registerUserType === 'FOUNDATION' ? 'Organización sin ánimo de lucro' :
                        'Marca sostenible'
                }
              </strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="center register-login-link">
              {HAS_ACCOUNT_1}
              <span onClick={this.openLoginModal} className="redirectLoginLink">
                {HAS_ACCOUNT_2}
              </span>
            </div>

            {form}

          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const RegisterModal = connect(select, mapDispatchToProps)(ConnectedRegisterModal);

export default RegisterModal;
