import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './styles/upgradeBillingPlanModalStyles.scss';
import { FOUNDATION_NEW_PROGRAM, FOUNDATION_NEW_VIRTUAL_PROGRAM, FOUNDATION_UPDATE_PROGRAM, FOUNDATION_UPDATE_VIRTUAL_PROGRAM, FOUNDATION_NEW_BENEFIT, FOUNDATION_UPDATE_BENEFIT, setShowBillingPlanUpgradePopup } from '../Redux/modules/billingPlanWarning';

function mapDispatchToProps(dispatch) {
  return {
    setShowBillingPlanUpgradePopup: payload => dispatch(setShowBillingPlanUpgradePopup(payload))
  };
}

const select = state => {
  return {
    showBillingPlanUpgradePopup: state.billingPlanWarning.showBillingPlanUpgradePopup,
    warningType: state.billingPlanWarning.warningType
  };
};

function ConnectedUpgradeBillingPlanModal(props) {

  const { warningType } = props;

  const handleClose = () => {
    props.setShowBillingPlanUpgradePopup({ show: false });
  };

  const upgradeBillingPlan = url => {
    props.setShowBillingPlanUpgradePopup({ show: false });
    window.location.href = `/info/${url}?upgrade=true`;
  };

  const callbackPrograms = () => {
    props.setShowBillingPlanUpgradePopup({ show: false, returnToPrevScreen: true });
  };

  return (
    <div>
      <Modal centered show={props.showBillingPlanUpgradePopup} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Body>
          <div className="upgrade-billing-plan-modal-component">
            {
              warningType === FOUNDATION_UPDATE_PROGRAM ?
              <>
                <h5 className="warning-message-text">
                  No puedes crear esta campaña debido a tu plan actual.
                  Para tener más de 5 campañas de donación debes adquirir otro plan.
                </h5>
                <div className="upgrade-billing-plan-btns">
                  <button className="upgrade-billing-plan-btn" onClick={handleClose}>
                    Aceptar
                  </button>
                  <button
                    className="upgrade-billing-plan-btn"
                    onClick={() => upgradeBillingPlan('organizaciones')}
                  >
                    Cambiar mi plan
                  </button>
                </div>
              </> :
              warningType === FOUNDATION_NEW_PROGRAM ?
              <>
                <h5 className="warning-message-text">
                  No puedes crear esta campaña debido a tu plan actual.
                  Para tener más de 5 campañas de donación debes adquirir otro plan.
                  Por ahora tu campaña quedará guardada como borrador.
                </h5>
                <div className="upgrade-billing-plan-btns">
                  <button className="upgrade-billing-plan-btn" onClick={callbackPrograms}>
                    Aceptar
                  </button>
                  <button
                    className="upgrade-billing-plan-btn"
                    onClick={() => upgradeBillingPlan('organizaciones')}
                  >
                    Cambiar mi plan
                  </button>
                </div>
              </> :
              warningType === FOUNDATION_NEW_VIRTUAL_PROGRAM ?
              <>
                <h5 className="warning-message-text">
                  No puedes crear este voluntariado digital debido a tu plan actual.
                  Para tener más de 5 voluntariados digitales debes adquirir otro plan.
                  Por ahora tu voluntariado digital quedará guardado como borrador.
                </h5>
                <div className="upgrade-billing-plan-btns">
                  <button className="upgrade-billing-plan-btn" onClick={callbackPrograms}>
                    Aceptar
                  </button>
                  <button
                    className="upgrade-billing-plan-btn"
                    onClick={() => upgradeBillingPlan('organizaciones')}
                  >
                    Cambiar mi plan
                  </button>
                </div>
              </> :
              warningType === FOUNDATION_UPDATE_VIRTUAL_PROGRAM ?
              <>
                <h5 className="warning-message-text">
                  No puedes crear este voluntariado digital debido a tu plan actual.
                  Para tener más de 5 voluntariados digitales debes adquirir otro plan.
                </h5>
                <div className="upgrade-billing-plan-btns">
                  <button className="upgrade-billing-plan-btn" onClick={handleClose}>
                    Aceptar
                  </button>
                  <button
                    className="upgrade-billing-plan-btn"
                    onClick={() => upgradeBillingPlan('organizaciones')}
                  >
                    Cambiar mi plan
                  </button>
                </div>
              </> :
              warningType === FOUNDATION_UPDATE_BENEFIT ?
              <>
                <h5 className="warning-message-text">
                  No puedes crear este producto debido a tu plan actual.
                  Para tener más de 5 productos debes adquirir otro plan.
                </h5>
                <div className="upgrade-billing-plan-btns">
                  <button className="upgrade-billing-plan-btn" onClick={handleClose}>
                    Aceptar
                  </button>
                  <button
                    className="upgrade-billing-plan-btn"
                    onClick={() => upgradeBillingPlan('organizaciones')}
                  >
                    Cambiar mi plan
                  </button>
                </div>
              </> :
              warningType === FOUNDATION_NEW_BENEFIT ?
              <>
                <h5 className="warning-message-text">
                  Con la creación de este producto superarás el límite de productos gratuitos.
                  Puedes confirmar o guardarlo como borrador.
                </h5>
                <div className="upgrade-billing-plan-btns">
                  <button className="upgrade-billing-plan-btn" onClick={callbackPrograms}>
                    Guardar como borrador
                  </button>
                  <button
                    className="upgrade-billing-plan-btn"
                    onClick={() => upgradeBillingPlan('organizaciones')}
                  >
                    Confirmar
                  </button>
                </div>
              </> :
              null
            }
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

const UpgradeBillingPlanModalComponent = connect(select, mapDispatchToProps)(ConnectedUpgradeBillingPlanModal);

export default withRouter(UpgradeBillingPlanModalComponent);
