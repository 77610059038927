export const plans = {
  '0': {
    id: 'ABEJA_BEBE',
    name: 'Abeja Bebé',
    amount: 15000,
    description: 'Recibirás 1 producto cada 2 meses sin costos adicionales.',
  },
  '1': {
    id: 'ABEJA_EXPLORADORA',
    name: 'Abeja Exploradora',
    amount: 30000,
    description: 'Recibirás 2 producto cada 2 meses sin costos adicionales.'
  },
  '2': {
    id: 'ABEJA_REINA',
    name: 'Abeja Reina',
    amount: 60000,
    description: 'Recibirás 4 producto cada 2 meses sin costos adicionales.'
  }
}
