export function formatMoney(amount, decimalCount = 0, decimal = ',', thousands = '.') {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e);
  }
}

export var formatCOP = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'COP',
  minimumFractionDigits: 0
});

export var formatUSD = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

export const formatNumber = (numberFormat)=>{
  return  numberFormat.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}