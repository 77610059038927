import React from 'react';
import { connect } from 'react-redux';
import { setShowRegisterModal, setShowShoppingCart } from '../Redux/modules/auth';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button, Spinner, Form, Modal } from 'react-bootstrap';
import ShoppingCartItem from './ShoppingCartItem';
import ShoppingCartPayment from './ShoppingCartPayment';
import AddressesComponent from './AddressesComponent';
import './styles/shoppingCart.scss';
import { formatMoney } from '../utils/formatMoney';

const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

function mapDispatchToProps(dispatch) {
  return {
    setShowRegisterModal: show => dispatch(setShowRegisterModal(show)),
    setShowShoppingCart: show => dispatch(setShowShoppingCart(show))
  };
}

const select = state => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    isFoundation: state.auth.isFoundation,
    isEnterprise: state.auth.isEnterprise,
    showShoppingCart: state.auth.showShoppingCart,
  };
};

class ConnectedShoppingCart extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      items: [],
      products: [],
      userAddresses: [],
      selectedAddress: {},
      isLoading: true,
      loadingDeliveryInfo: false,
      total: 0,
      itemTotal: 0,
      productTotal: 0,
      paymentStep: 0,
      totalNibis: 0,
      deliveryCost: 0,
      showAddressModal: false,
      deliveryError: false,
      hasDelivery: false,
    };

  }

  async componentDidMount() {
    if (this.props && this.props.user._id) {
      await this.getAddresses();
      await this.refreshCart();
    }
  }

  async componentDidUpdate(prevProps) {
    const { user, showShoppingCart } = this.props;
    if (prevProps.user !== user || (showShoppingCart && !prevProps.showShoppingCart && user._id)) {
      await this.getAddresses();
      await this.refreshCart();
    }
  }

  async getAddresses(added) {
    const addresses = await fetch('/addresses/user/' + this.props.user._id);
    const res = await addresses.json();

    let selectedAddress = null;
    if (res.length > 0) selectedAddress = res[0];
    if (added) selectedAddress = res[res.length - 1];

    this.setState({ userAddresses: res, selectedAddress }, () => added ? this.getDeliveryQuotations() : null);
  }

  async getShoppingCart() {
    const data = await fetch('/shoppingCartItems/id/' + this.props.user._id);
    const res = await data.json();
    const total = res.reduce((a, b) => ({ amount: a.amount + b.amount }), { amount: 0 });
    this.setState({ items: res, itemTotal: total.amount });
  }

  async getShoppingCartProducts() {
    const data = await fetch('/shoppingCartItems/products/id/' + this.props.user._id);
    const res = await data.json();
    const total = res.reduce((a, b) => ({ amount: a.amount + b.amount }), { amount: 0 });

    let hasDelivery = false;
    if (res.filter(product => product.product.hasDelivery === true).length > 0) hasDelivery = true;

    this.setState({ products: res, productTotal: total.amount, hasDelivery });
  }

  async getDeliveryQuotations() {

    const { products, selectedAddress } = this.state;
    const { user, showShoppingCart } = this.props;

    if (!selectedAddress || showShoppingCart) return;

    this.setState({ deliveryError: false, loadingDeliveryInfo: true });

    let newDeliveryCost = 0;

    const deliveryProducts = products.filter(product => product.product && product.product.hasDelivery && product.enterpriseId);
    const deliveryProductsFoundation = products.filter(product => product.product && product.product.hasDelivery && product.foundationId);
    const groupedProducts = groupBy(deliveryProducts, 'enterpriseId');
    const groupedFoundationProducts = groupBy(deliveryProductsFoundation, 'foundationId');
    const groupedProductsArray = Object.values(groupedProducts);
    const groupedFoundationProductsArray = Object.values(groupedFoundationProducts);

    for (let i = 0; i < groupedProductsArray.length; i++) {
      const quotationQuery = await fetch('/shoppingCartItems/delivery/getQuotation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          products: groupedProductsArray[i],
          user,
          selectedAddress
        })
      });

      const quotationRes = await quotationQuery.json();
      const quotationData = quotationRes.data;

      if (quotationData.status === 'OK') {
        try {
          const prodIndex = products.indexOf(groupedProductsArray[i][0]);

          products[prodIndex].idRate = quotationData.data.rates[0].idRate;
          products[prodIndex].destination = selectedAddress;
          products[prodIndex].origin = products[prodIndex].product.deliveryInfo;
          products[prodIndex].quotation = quotationData.data;
          products[prodIndex].deliveryCost = quotationData.data.rates[0].flete + quotationData.data.rates[0].minimumInsurance;
          newDeliveryCost += quotationData.data.rates[0].flete + quotationData.data.rates[0].minimumInsurance;
        } catch (error) {
          this.setState({ deliveryError: true });
        }
      } else {
        this.setState({ deliveryError: true });
      }

    }

    for (let i = 0; i < groupedFoundationProductsArray.length; i++) {
      const quotationQuery = await fetch('/shoppingCartItems/delivery/getQuotation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          products: groupedFoundationProductsArray[i],
          user,
          selectedAddress
        })
      });

      const quotationRes = await quotationQuery.json();
      const quotationData = quotationRes.data;

      if (quotationData.status === 'OK') {
        try {
          const prodIndex = products.indexOf(groupedFoundationProductsArray[i][0]);

          products[prodIndex].idRate = quotationData.data.rates[0].idRate;
          products[prodIndex].destination = selectedAddress;
          products[prodIndex].origin = products[prodIndex].product.deliveryInfo;
          products[prodIndex].quotation = quotationData.data;
          products[prodIndex].deliveryCost = quotationData.data.rates[0].flete + quotationData.data.rates[0].minimumInsurance;
          newDeliveryCost += quotationData.data.rates[0].flete + quotationData.data.rates[0].minimumInsurance;
        } catch (error) {
          this.setState({ deliveryError: true });
        }

      } else {
        this.setState({ deliveryError: true });
      }

    }

    this.setState({ products, deliveryCost: newDeliveryCost, loadingDeliveryInfo: false });
  }

  getTotalNibis() {
    let totalNibis = 0;

    this.state.items.forEach(item => {
      totalNibis += item.amount / 1000;
    });

    this.state.products.forEach(product => {
      if (product.nibis) totalNibis -= product.nibis;
    });
    this.setState({ totalNibis: totalNibis });
  }

  async refreshCart() {
    await this.getShoppingCart();
    await this.getShoppingCartProducts();

    this.setState({ isLoading: false });

    await this.getDeliveryQuotations();

    this.getTotalNibis();
    this.setState({ total: this.state.productTotal + this.state.itemTotal });
  }

  handlePayCart(paymentStep) {
    this.setState({ paymentStep });
  }

  handleChangeAddress = (addressValue) => {
    this.setState({ selectedAddress: JSON.parse(addressValue), loadingDeliveryInfo: true }, () => this.getDeliveryQuotations())
  }

  render() {

    const {
      items,
      products,
      total,
      paymentStep,
      totalNibis,
      isLoading,
      userAddresses,
      selectedAddress,
      deliveryCost,
      showAddressModal,
      loadingDeliveryInfo,
      deliveryError,
      hasDelivery,
    } = this.state;

    const {
      showShoppingCart,
      setShowShoppingCart,
      user
    } = this.props;

    const SHOPPING_CART = 'Carrito de compras';

    if (isLoading) return <div style={{ minHeight: '100vh' }} className='centerDiv'><Spinner variant='warning' animation='grow' /></div>;

    if (paymentStep === 1) return (
      <ShoppingCartPayment
        totalAmount={total + deliveryCost}
        deliveryInfo={selectedAddress}
        items={items}
        products={products}
        language={'ES'}
        returnToCart={() => this.handlePayCart(0)}
      />
    )

    return (
      <Container className='shoppingCartSection' style={{ paddingTop: '5%', paddingBottom: '5%' }}>

        <div>
          {
            showShoppingCart &&
            <span className="cartCloseBtn" onClick={() => setShowShoppingCart(false)}>&times;</span>
          }
          <span style={{ fontSize: '28px' }}><strong>{SHOPPING_CART}</strong></span>
        </div>

        <Row>
          <Col sm={showShoppingCart ? '12' : '8'}>
            <div style={{ textAlign: 'right', fontSize: '12px' }}>Valor</div>
            <hr />

            {
              items.length < 1 && products.length < 1 ?
                <div className="center"><h5>¡Carrito de compras vacío!</h5></div>
                :
                <div>
                  {
                    items.map((item, i) => {
                      return (
                        <div key={i}>
                          <ShoppingCartItem item={item} refresh={() => this.refreshCart()} />
                          <hr />
                        </div>
                      )
                    })
                  }

                  {
                    products.map((product, i) => {
                      return (
                        <div key={i}>
                          <ShoppingCartItem item={product} refresh={() => this.refreshCart()} />
                          <hr />
                        </div>
                      )
                    })
                  }
                </div>
            }

            {
              showShoppingCart &&
              <div style={{ textAlign: 'right' }}>
                {
                  totalNibis > user.totalNibis && totalNibis < 0 ?
                    <div>No tienes suficientes nibis <img src={require("../img/nibi_coins.png")} style={{ height: "20px" }} alt="Moneda Nibi" /> para realizar esta transacción</div>
                    : totalNibis > 0 ?
                      <div>Recibirás {totalNibis} <img src={require("../img/nibi_coins.png")} style={{ height: "20px" }} alt="Moneda Nibi" /> por esta transacción</div>
                      : totalNibis < 0 ?
                        <div>Gastarás {totalNibis * -1}  <img src={require("../img/nibi_coins.png")} style={{ height: "20px" }} alt="Moneda Nibi" /> por esta transacción</div>
                        : ''
                }

                <h6>Subtotal: COP {loadingDeliveryInfo ? '...' : formatMoney(total)}</h6>

                <Link to={`/carrito`}>
                  <Button variant="outline-primary" onClick={() => setShowShoppingCart(false)}>
                    Realizar pago
                    </Button>
                </Link>

              </div>
            }

          </Col>
          {
            !showShoppingCart &&
            <Col sm='4'>

              <div className='summaryContainer'>

                <div className='nibiAlertTitle'>
                  {
                    totalNibis > user.totalNibis && totalNibis < 0 ?
                      <div>No tienes suficientes nibis <img src={require("../img/nibi_coins.png")} style={{ height: "20px" }} alt="Moneda Nibi" /> para realizar esta transacción</div>
                      : totalNibis > 0 ?
                        <div>Recibirás {totalNibis} <img src={require("../img/nibi_coins.png")} style={{ height: "20px" }} alt="Moneda Nibi" /> por esta transacción</div>
                        : totalNibis < 0 ?
                          <div>Gastarás {totalNibis * -1}  <img src={require("../img/nibi_coins.png")} style={{ height: "20px" }} alt="Moneda Nibi" /> por esta transacción</div>
                          : ''
                  }
                </div>

                {
                  products.length > 0 &&
                  <div>
                    <div className='chooseAddressTitle'>Elegir dirección de envío</div>
                    <Form.Control
                      onChange={(event) => this.handleChangeAddress(event.target.value)}
                      required
                      disabled={loadingDeliveryInfo}
                      as="select">
                      {
                        userAddresses.map((address, i) =>
                          <option key={i} value={JSON.stringify(address)}>{address.short}</option>
                        )
                      }
                    </Form.Control>
                    <div style={{ textAlign: 'center' }}>
                      <Button
                        variant='outline-success'
                        style={{ margin: '6px auto', height: '30px', fontSize: '12px' }}
                        onClick={() => this.setState({ showAddressModal: true })}
                      >
                        Agregar nueva
                      </Button>
                    </div>
                  </div>
                }

                {
                  selectedAddress && products.length > 0 &&
                  <div className='summaryAddressInfo'>{selectedAddress.street + ', ' + selectedAddress.city}</div>
                }

                <br />

                <div>Subtotal: COP {loadingDeliveryInfo ? '...' : formatMoney(total)}</div>

                {
                  hasDelivery &&
                  <div>Costo de envío: {!loadingDeliveryInfo ? `COP ${formatMoney(deliveryCost)}` : '...'}</div>
                }


                {
                  deliveryError && selectedAddress &&
                  <div style={{ color: 'red', fontSize: '12px' }}>Error al calcular el costo de envío</div>
                }

                <br />
                <h5>Total: {loadingDeliveryInfo ? 'Calculando...' : `COP ${formatMoney(total + deliveryCost)}`}</h5>

                <Button
                  style={{ width: '100%', marginTop: '16px' }}
                  variant="primary" onClick={() => this.handlePayCart(1)}
                  disabled={
                    loadingDeliveryInfo ||
                    deliveryError ||
                    ((totalNibis > user.totalNibis && totalNibis < 0) && (items.length < 1 && products.length < 1)) ||
                    (products.length > 0 && !selectedAddress)}
                >
                  Realizar pago
                </Button>
              </div>
            </Col>
          }
        </Row>

        <Modal show={showAddressModal} onHide={() => this.setState({ showAddressModal: false })} size="lg" centered>
          <Modal.Header closeButton>
            <Modal.Title>Agrega una dirección de envío</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddressesComponent userId={this.props.user._id} refreshAddresses={() => this.getAddresses(true)} closeModal={() => this.setState({ showAddressModal: false })} />
          </Modal.Body>
        </Modal>

      </Container>
    );
  }
}

const ShoppingCart = connect(select, mapDispatchToProps)(ConnectedShoppingCart);

export default ShoppingCart;
