import React from 'react';
import nibiCoin from '../img/nibi_coins.png';
import './styles/redeemNibisReminder.scss';

function RedeemNibisReminder() {
  return (
    <div className="redeem-nibis-reminder">
      <span className="notif-reminder"></span>
      <img src={nibiCoin} alt='nibi coin'/>
      <p>
        <strong>¡Recuerda que al donar obtienes nibis!<br /></strong>
        Éstos los puedes redimir en nuestra tienda.
      </p>
    </div>
  );
}

export default RedeemNibisReminder;
