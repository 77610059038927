const LOGIN = 'nibi/auth/LOGIN';
const LOGOUT = 'nibi/auth/LOGOUT';
const SET_USER = 'nibi/auth/SET_USER';
const SET_IS_FOUNDATION = 'nibi/auth/SET_IS_FOUNDATION';
const SET_IS_ENTERPRISE = 'nibi/auth/SET_IS_ENTERPRISE';
const SET_USER_ID = 'nibi/auth/SET_USER_ID';
const SET_TOKEN = 'nibi/auth/SET_TOKEN';
const SHOW_REGISTER_MODAL = 'nibi/auth/SHOW_REGISTER_MODAL';
const SHOW_LOGIN_MODAL = 'nibi/auth/SHOW_LOGIN_MODAL';
const SET_LANGUAGE = 'nibi/auth/SET_LANGUAGE';
const SET_EXCHANGE_RATE = 'nibi/auth/SET_EXCHANGE_RATE';
const SET_HAS_EXCHANGE_RATE = 'nibi/auth/SET_HAS_EXCHANGE_RATE';
const SHOW_SHOPPING_CART = 'nibi/auth/SHOW_SHOPPING_CART';
const SET_CHAT_NOTIFICATION = 'nibi/auth/SET_CHAT_NOTIFICATION';
const SHOWING_PAYMENT_MODAL = 'nibi/auth/SHOWING_PAYMENT_MODAL';
const SHOW_PAYMENT_MODAL = 'nibi/auth/SHOW_PAYMENT_MODAL';


const initialState = {
  authenticated: false,
  user: {},
  userId: '',
  isFoundation: false,
  isEnterprise: false,
  token: '',
  showRegisterModal: false,
  registerUserType: '',
  plan: null,
  showLoginModal: false,
  language: 'ES',
  conversion: 1,
  hasExchangeRate: false,
  showShoppingCart: false,
  chatNotification: false,
  showingPaymentModal: false,
  showPaymentModal: false,
};

export default function reducer(state = initialState, action) {

  const payload = action.payload;

  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        authenticated: true
      };
    case LOGOUT:
      return {
        ...state,
        authenticated: false,
        user: {},
        userId: '',
        isFoundation: false,
        isEnterprise: false,
        token: ''
      };
    case SET_USER:
      return {
        ...state,
        user: payload.user
      };
    case SET_USER_ID:
      return {
        ...state,
        userId: payload.userId
      };
    case SET_IS_FOUNDATION:
      return {
        ...state,
        isFoundation: payload.isFoundation
      };
    case SET_IS_ENTERPRISE:
      return {
        ...state,
        isEnterprise: payload.isEnterprise
      };
    case SET_TOKEN:
      return {
        ...state,
        token: payload.token
      };
    case SHOW_REGISTER_MODAL:
      return {
        ...state,
        showRegisterModal: payload.show,
        registerUserType: payload.registerUserType ? payload.registerUserType : '',
        plan: payload.plan ? payload.plan : ''
      };
    case SHOW_LOGIN_MODAL:
      return {
        ...state,
        showLoginModal: payload
      };
    case SET_LANGUAGE:
      return {
        ...state,
        language: payload
      };
    case SET_EXCHANGE_RATE:
      return {
        ...state,
        conversion: payload
      };
    case SET_HAS_EXCHANGE_RATE:
      return {
        ...state,
        hasExchangeRate: payload
      };
    case SHOW_SHOPPING_CART:
      return {
        ...state,
        showShoppingCart: payload
      };
    case SET_CHAT_NOTIFICATION:
      return {
        ...state,
        chatNotification: payload
      };
    case SHOWING_PAYMENT_MODAL:
      return {
        ...state,
        showingPaymentModal: payload
      }
    case SHOW_PAYMENT_MODAL:
      return {
        ...state,
        showPaymentModal: payload
      }
    default:
      return state;
  }

}

export function userLogin() {
  return { type: LOGIN };
}

export function userLogout() {
  return { type: LOGOUT };
}

export function setUser(payload) {
  return { type: SET_USER, payload }
}

export function setIsFoundation(payload) {
  return { type: SET_IS_FOUNDATION, payload }
}

export function setIsEnterprise(payload) {
  return { type: SET_IS_ENTERPRISE, payload }
}

export function setUserId(payload) {
  return { type: SET_USER_ID, payload }
}

export function setToken(payload) {
  return { type: SET_TOKEN, payload }
}

export function setShowRegisterModal(payload) {
  return { type: SHOW_REGISTER_MODAL, payload };
}

export function setShowLoginModal(payload) {
  return { type: SHOW_LOGIN_MODAL, payload };
}

export function setLanguage(payload) {
  return { type: SET_LANGUAGE, payload };
}

export function setExchangeRate(payload) {
  return { type: SET_EXCHANGE_RATE, payload };
}

export function setHasExchangeRate(payload) {
  return { type: SET_HAS_EXCHANGE_RATE, payload };
}

export function setShowShoppingCart(payload) {
  return { type: SHOW_SHOPPING_CART, payload };
}

export function setShowingPaymentModal(payload) {
  return { type: SHOWING_PAYMENT_MODAL, payload };
}

export function setShowPaymentModal(payload) {
  return { type: SHOW_PAYMENT_MODAL, payload };
}
