import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import logo1 from '../img/logo2.png';
import logo2 from '../img/logo_nibi_white.png';
import logoEpayco from '../img/logo-epayco.png';
import './styles/footer.scss';

class Footer extends Component {

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  render() {
    const { location } = this.props;

    if (location.pathname.includes('dashboard')) return null;

    return (
			<div>
				<footer className='page-footer font-small pt-4 footer white'>
					<Container>
						<Row>
							<hr className='clearfix w-100 d-md-none pb-3' />
							<Col
								md={2}
								style={{ zIndex: '2' }}>
								<img
									className='footer-img'
									src={logo2}
									style={{ marginBottom: '20px' }}
									alt='Logotipo Nibi'
								/>
								<div className='footer-text'>
									Bogotá, CO
								</div>
								<div className='footer-text'>
									+57 315 2930296
								</div>
							</Col>
							<Col
								md={2}
								style={{ zIndex: '2' }}>
								<ul className='list-unstyled'>
									<li>
										<Link to='/explora'>Campañas</Link>
									</li>
									<li>
										<Link to='/beneficios'>Tienda</Link>
									</li>
									<li>
										<Link to='/fundaciones'>
											Organizaciones
										</Link>
									</li>
									<li>
										<Link to='/comercios'>Comercios</Link>
									</li>
								</ul>
							</Col>
							<Col
								md={2}
								style={{ zIndex: '2' }}>
								<ul className='list-unstyled'>
									<li>
										<Link to='/sobre-nibi'>Nosotros</Link>
									</li>
									<li>
										<Link to='/blog'>Nuestro blog</Link>
									</li>
									<li>
										<Link to='/blog'>Contáctanos</Link>
									</li>
									<li>
										<Link
											to='/politicas-nibi'
											target='_blank'>
											Políticas
										</Link>
									</li>
								</ul>
							</Col>
							<Col
								md={2}
								style={{ zIndex: '2' }}>
								<div className='social-icons'>
									<div>
										<a href='https://www.facebook.com/NibiColombia/'>
											<i className='fab fa-facebook-f'></i>
											Facebook
										</a>
									</div>
									<div>
										<a href='https://www.instagram.com/nibi.bee/'>
											<i className='fab fa-instagram'></i>
											Instagram
										</a>
									</div>
									<div>
										<a href='https://www.linkedin.com/company/nibilatam/'>
											<i className='fab fa-linkedin'></i>
											LinkedIn
										</a>
									</div>
									<div>
										<a
											target='_blank'
											rel='noreferrer'
											href='https://drive.google.com/file/d/13S-en2U0uLHw2C3ws-Me9_RJmN2moLil/view?usp=sharing'>
											Reporte BIC 2022
										</a>
									</div>
								</div>
							</Col>
							<Col
								md={2}
								className='center'
								style={{ zIndex: '2' }}>
								<div className='footer-text'>
									Tus pagos están seguros con:{' '}
								</div>
								<br />
								<img
									style={{
										width: '116px',
										marginBottom: '8px',
									}}
									src={logoEpayco}
									alt='Logotipo de Epayco'
								/>
								<br />
							</Col>
							<Col
								md={2}
								style={{ zIndex: '2' }}>
								<img
									className='footer-img'
									src={logo1}
									alt='Logotipo y eslogan de Nibi'
								/>
							</Col>
						</Row>

						<p className='copyright'>
							Copyright © 2020 Nibi S.A.S. Todos los
							derechos reservados.
						</p>
					</Container>
				</footer>
			</div>
		);
  }
}

export default withRouter(Footer);
