import React from 'react';
import { Form, Col, Button, Spinner } from 'react-bootstrap';
import { daneCodes } from '../utils/daneCodes';

var groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

class AddressesComponent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      userId: this.props,
      type: 'DELIVERY',
      country: 'Colombia',
      state: 'Bogotá D.C.',
      dane: '',
      zip: '',
      city: '',
      area: '',
      street: '',
      extra: '',
      phone: '',
      short: '',
      sending: false,
      availableCities: []
    }

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async handleCreateAddress(event) {
    event.preventDefault();

    this.setState({ sending: true });

    const {
      type,
      country,
      state,
      city,
      zip,
      area,
      street,
      extra,
      phone,
      short,
      dane
    } = this.state;

    const { userId } = this.props;

    const body = {
      userId,
      type,
      country,
      state,
      city: JSON.parse(city).name_city,
      zip,
      area,
      street,
      extra,
      phone,
      short,
      dane,
    };

    const prom = await fetch('/addresses/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body),
    });

    const json = await prom.json();

    if (json.success) {
      this.props.closeModal();
      this.props.refreshAddresses();
      this.setState({
        type: 'DELIVERY',
        country: 'Colombia',
        state: '',
        city: '',
        zip: '',
        area: '',
        street: '',
        extra: '',
        phone: '',
        short: '',
        dane: '',
        sending: false,
      })
    }
  }

  render() {

    const {
      state,
      city,
      zip,
      area,
      street,
      extra,
      phone,
      short,
      sending,
    } = this.state;

    return (
      <Form onSubmit={event => this.handleCreateAddress(event)}>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridCountry">
            <Form.Label><small>País</small></Form.Label>
            <Form.Control placeholder='Colombia' disabled />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridState">
            <Form.Label><small>Departamento</small></Form.Label>
            <Form.Control
              required
              as="select"
              name="state" value={state}
              onChange={(e) => this.handleInputChange(e)}
            >
              <option hidden></option>
              {
                Object.keys(groupBy(daneCodes, 'name_depto')).map((state, i) =>
                  <option
                    key={i}
                    value={state}>
                    {state}
                  </option>
                )
              }
            </Form.Control>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label><small>Ciudad</small></Form.Label>
            <Form.Control
              required
              as="select"
              name="city" value={city}
              onChange={(e) => {
                const cityInfo = JSON.parse(e.target.value);
                this.setState({ city: e.target.value, dane: cityInfo.dane_code.toString() });
              }}
            >
              <option hidden></option>
              {
                Object.values(groupBy(daneCodes.filter(entry => entry.name_depto === state), 'name_city')).map((city, i) =>
                  <option
                    key={i}
                    value={JSON.stringify(city[0])}>
                    {city[0].name_city}
                  </option>
                )
              }
            </Form.Control>
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Col sm={6}>
            <Form.Group controlId="formGridStreet">
              <Form.Label><small>Dirección</small></Form.Label>
              <Form.Control required name='street' value={street} onChange={this.handleInputChange} placeholder="Dirección del domicilio" />
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group controlId="formGridExtra">
              <Form.Label><small>Datos adicionales</small></Form.Label>
              <Form.Control required name='extra' value={extra} onChange={this.handleInputChange} placeholder="Bloque, apartamento, interior" />
            </Form.Group>
          </Col>
          <Col sm={2}>
            <Form.Group controlId="formGridZip">
              <Form.Label><small>Código postal</small></Form.Label>
              <Form.Control required name='zip' value={zip} onChange={this.handleInputChange} placeholder="111411" />
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="formGridArea">
            <Form.Label><small>Barrio</small></Form.Label>
            <Form.Control required name='area' value={area} onChange={this.handleInputChange} />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridPhone">
            <Form.Label><small>Teléfono de contacto</small></Form.Label>
            <Form.Control required name='phone' value={phone} onChange={this.handleInputChange} />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridShort">
            <Form.Label><small>Guardar como</small></Form.Label>
            <Form.Control required name='short' value={short} onChange={this.handleInputChange} placeholder='Trabajo, Casa, Finca...' />
          </Form.Group>
        </Form.Row>

        <div className="center">
          <Button variant="outline-primary" type="submit" style={{ width: '70%' }} disabled={sending}>
            {sending ? <Spinner animation="grow" /> : "Crear"}
          </Button>
        </div>
      </Form>
    );
  }
}

export default AddressesComponent;
