import React, { useState } from 'react';
import {
  Col,
  Container,
  Image,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from 'react-bootstrap';
import './styles/suscriptionPlans.scss';
import { connect } from 'react-redux';
import { setShowRegisterModal } from '../Redux/modules/auth';

const select = (state) => {
  return {
    token: state.auth.token,
    isFoundation: state.auth.isFoundation,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setShowRegisterModal: (show) => dispatch(setShowRegisterModal(show)),
  };
}

function ConnectedFoundationSubscriptionPlans2022(props) {
  const [monthlyPlanSelected, setMonthlyPlanSelected] = useState(true);

  const liteMonth = 35;
  const liteYear = 336;
  const basicMonth = 75;
  const basicYear = 720;
  const advancedMonth = 185;
  const advancedYear = 1.776;
  const proMonth = 399;
  const proYear = 3.83;

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const handleShowRegisterModal = () => {
    if (!props.token)
      props.setShowRegisterModal({
        show: true,
        registerUserType: 'FOUNDATION',
      });
  };

  return (
    <div>
      <div className='landing-section'>
        {props.environment === 'foundationsLanding' && (
          <h1>Nuestros planes</h1>
        )}

        <div className='plan-toggle-buttons'>
          <button
            className={monthlyPlanSelected ? 'active' : ''}
            onClick={() => setMonthlyPlanSelected(true)}
            style={{ marginRight: '-15px' }}>
            Mensual
          </button>
          <button
            className={!monthlyPlanSelected ? 'active' : ''}
            onClick={() => setMonthlyPlanSelected(false)}
            style={{ marginLeft: '-15px' }}>
            Anual
            <span className='bubble bubble-bottom-left'>
              ¡3 meses, <strong>GRATIS</strong>!
            </span>
          </button>
        </div>
      </div>

      <div className='plans'>
        <Row className='justify-content-center'>
          <Col xs={4}>
            <div className='plan-desc'>
              <div className='plan-title'>Increíbles beneficios</div>
              <hr />

              <div
                className='plan-item'
                style={{ height: '70px' }}>
                <span>Precios</span>
              </div>
              <hr />

              <div
                className='plan-item'
                style={{ height: '40px' }}>
                <span>Todos los medios de pago + débito automático</span>
                <OverlayTrigger
                  placement='top'
                  overlay={
                    <Tooltip>
                      Recibe pagos por tarjeta de crédito, débito, PSE y
                      más. Recibe pagos recurrentes mes a mes con tarjetas
                      de crédito y débito
                    </Tooltip>
                  }>
                  <i className='fas fa-question-circle plan-item-question-icon' />
                </OverlayTrigger>
              </div>
              <hr />
              <div
                className='plan-item'
                style={{ height: '40px' }}>
                <span>
                  Plataforma de administración de donantes con
                  automatización de comunicaciones y estadísticas avanzadas
                </span>
                <OverlayTrigger
                  placement='top'
                  overlay={
                    <Tooltip>
                      Centraliza y gestiona la información de tus
                      contactos, clientes, voluntarios y donantes.
                      Automatiza comunicaciones con ellos con alta
                      efectividad.
                    </Tooltip>
                  }>
                  <i className='fas fa-question-circle plan-item-question-icon' />
                </OverlayTrigger>
              </div>
              <hr />

              <div
                className='plan-item'
                style={{ height: '40px' }}>
                <span>
                  Creación de landings para recolección de donaciones,
                  planes padrino, ventas y servicios
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Tooltip>
                        Crea en minutos el landing perfecto para comenzar a
                        recibir ingresos
                      </Tooltip>
                    }>
                    <i className='fas fa-question-circle plan-item-question-icon' />
                  </OverlayTrigger>
                </span>
              </div>
              <hr />
              <div
                className='plan-item'
                style={{ height: '40px' }}>
                <span>Agradecimiento automático por whatsapp/mes</span>
              </div>
              <hr />

              <div
                className='plan-item'
                style={{ height: '40px' }}>
                <span>Envío mensajes de avances de resultados/mes</span>
              </div>
              <hr />

              <div
                className='plan-item'
                style={{ height: '40px' }}>
                <span>
                  Cuenta con sesiones mensuales personalizadas para el
                  crecimiento de tu organización
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Tooltip>
                        ¡Manos a la obra! Sesiones personalizadas de 45
                        minutos donde te guiamos estratégicamente para
                        maximizar tu crecimiento.
                      </Tooltip>
                    }>
                    <i className='fas fa-question-circle plan-item-question-icon' />
                  </OverlayTrigger>
                </span>
              </div>
              <hr />

              <div
                className='plan-item'
                style={{ height: '40px' }}>
                <span>
                  Mejora tu posicionamiento en el motor de búsqueda de nibi
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Tooltip>
                        Aparecerás dentro de las primeras fundaciones en la
                        página
                      </Tooltip>
                    }>
                    <i className='fas fa-question-circle plan-item-question-icon' />
                  </OverlayTrigger>
                </span>
              </div>
              <hr />

              <div
                className='plan-item'
                style={{ height: '40px' }}>
                <span>
                  Integra nibi dentro de la página web de tu organización*
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Tooltip>
                        Instalar nuestro botón de pago en tu página web
                      </Tooltip>
                    }>
                    <i className='fas fa-question-circle plan-item-question-icon' />
                  </OverlayTrigger>
                </span>
              </div>
              <hr />

              <div
                className='plan-item'
                style={{ height: '40px' }}>
                <span>Número de donantes recurrentes</span>
              </div>
              <hr />

              <div
                className='plan-item'
                style={{ height: '40px' }}>
                <span>Número de desembolsos mensuales</span>
              </div>
              <hr />

              <div
                className='plan-item'
                style={{ height: '40px' }}>
                <span>
                  Costo de tecnología por transacción (No incluye costos
                  financieros)
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Tooltip>
                        Costo por transacción usando la plataforma. No
                        incluye costos transaccionales de las entidades
                        financieras aprox. 3,26% (varía dependiendo del
                        método de pago y el monto)
                      </Tooltip>
                    }>
                    <i className='fas fa-question-circle plan-item-question-icon' />
                  </OverlayTrigger>
                </span>
              </div>
            </div>
          </Col>
          <Col xs={2}>
            <div className='plan-desc plan-features plan-basic'>
              <div className='plan-title'>Lite</div>
              <hr />

              <div className='plan-item plan-price'>
                <div style={{ textAlign: 'center' }}>
                  <div className='price'>
                    $ {monthlyPlanSelected ? liteMonth : liteYear}
                    <span> .000</span>
                  </div>
                  <div>/ cop</div>
                  {!monthlyPlanSelected && (
                    <div>
                      <strong>
                        {!monthlyPlanSelected &&
                          props.environment !== 'foundationWallet' &&
                          'Ahorras $90.000'}
                      </strong>
                    </div>
                  )}
                </div>
              </div>
              <hr />

              <div
                className='plan-item plan-item-icon included'
                style={{ height: '40px' }}>
                <i className='fas fa-check-circle' />
              </div>
              <hr />

              <div
                className='plan-item plan-item-icon included'
                style={{ height: '40px' }}>
                <i className='fas fa-check-circle' />
              </div>
              <hr />

              <div
                className='plan-item'
                style={{ height: '40px', fontSize: '1.2rem' }}>
                <strong>5</strong>
              </div>
              <hr />

              <div
                className='plan-item plan-item-icon included'
                style={{ height: '40px' }}>
                <i className='fas fa-check-circle' />
              </div>
              <hr />

              <div
                className='plan-item'
                style={{ height: '40px', fontSize: '1.2rem' }}>
                <strong>10</strong>
              </div>
              <hr />

              <div
                className='plan-item plan-item-icon excluded'
                style={{ height: '40px' }}>
                <i className='fas fa-times-circle' />
              </div>
              <hr />

              <div
                className='plan-item plan-item-icon included'
                style={{ height: '40px' }}>
                <i className='fas fa-check-circle' />
              </div>
              <hr />

              <div
                className='plan-item plan-item-icon excluded'
                style={{ height: '40px' }}>
                <i className='fas fa-times-circle' />
              </div>
              <hr />

              <div
                className='plan-item'
                style={{ height: '40px', fontSize: '1.2rem' }}>
                <strong>10</strong>
              </div>
              <hr />
              <div
                className='plan-item'
                style={{ height: '40px', fontSize: '1.2rem' }}>
                <strong>1</strong>
              </div>
              <hr />

              <div
                className='plan-item'
                style={{ height: '40px', fontSize: '1.2rem' }}>
                <strong>4,0%</strong>
              </div>
              <hr />
              <div
                className='plan-item'
                style={{ padding: '0 5px 15px' }}>
                {props.environment === 'foundationsLanding' && (
                  <button
                    className='landing-button'
                    style={{ padding: '5px 10px' }}
                    onClick={() => props.showDemoModal('Plan Lite')}>
                    ¡Lo quiero!
                  </button>
                )}
                {props.environment === 'foundationWallet' && (
                  <button
                    className={`nibi-btn ${
                      props.plan &&
                      props.plan.planId === 'LITE' &&
                      props.plan.status === 'ACTIVE'
                        ? 'inactive'
                        : ''
                    }`}
                    disabled={
                      props.plan &&
                      props.plan.planId === 'LITE' &&
                      props.plan.status === 'ACTIVE'
                    }
                    onClick={() =>
                      props.handleUpgradePlan('LITE', 30000, 'Lite')
                    }>
                    {props.plan &&
                    props.plan.planId === 'LITE' &&
                    props.plan.status === 'ACTIVE'
                      ? 'Plan actual'
                      : 'Subir de plan'}
                  </button>
                )}
              </div>
            </div>
          </Col>
          <Col xs={2}>
            <div className='plan-desc plan-features plan-basic'>
              <div className='plan-title'>Básico</div>
              <hr />

              <div className='plan-item plan-price'>
                <div style={{ textAlign: 'center' }}>
                  <div className='price'>
                    $ {monthlyPlanSelected ? basicMonth : basicYear}
                    <span> .000</span>
                  </div>
                  <div>/ cop</div>
                  <div>
                    <strong>
                      {!monthlyPlanSelected &&
                        props.environment !== 'foundationWallet' &&
                        'Ahorras $207.000'}
                    </strong>
                  </div>
                </div>
              </div>
              <hr />

              <div
                className='plan-item plan-item-icon included'
                style={{ height: '40px' }}>
                <i className='fas fa-check-circle' />
              </div>
              <hr />

              <div
                className='plan-item plan-item-icon included'
                style={{ height: '40px' }}>
                <i className='fas fa-check-circle' />
              </div>
              <hr />

              <div
                className='plan-item'
                style={{ height: '40px', fontSize: '1.2rem' }}>
                <strong>10</strong>
              </div>
              <hr />

              <div
                className='plan-item plan-item-icon included'
                style={{ height: '40px' }}>
                <i className='fas fa-check-circle' />
              </div>
              <hr />

              <div
                className='plan-item'
                style={{ height: '40px', fontSize: '1.2rem' }}>
                <strong>50</strong>
              </div>
              <hr />

              <div
                className='plan-item'
                style={{ height: '40px', fontSize: '1.2rem' }}>
                <strong>1</strong>
              </div>
              <hr />

              <div
                className='plan-item plan-item-icon included'
                style={{ height: '40px' }}>
                <i className='fas fa-check-circle' />
              </div>
              <hr />

              <div
                className='plan-item plan-item-icon excluded'
                style={{ height: '40px' }}>
                <i className='fas fa-times-circle' />
              </div>
              <hr />

              <div
                className='plan-item'
                style={{ height: '40px', fontSize: '1.2rem' }}>
                <strong>30</strong>
              </div>
              <hr />
              <div
                className='plan-item'
                style={{ height: '40px', fontSize: '1.2rem' }}>
                <strong>2</strong>
              </div>
              <hr />

              <div
                className='plan-item'
                style={{ height: '40px', fontSize: '1.2rem' }}>
                <strong>3,0%</strong>
              </div>
              <hr />
              <div
                className='plan-item'
                style={{ padding: '0 5px 15px' }}>
                {props.environment === 'foundationsLanding' && (
                  <button
                    className='landing-button'
                    style={{ padding: '5px 10px' }}
                    onClick={() => props.showDemoModal('Plan Basico')}>
                    ¡Lo quiero!
                  </button>
                )}
                {props.environment === 'foundationWallet' && (
                  <button
                    className={`nibi-btn ${
                      props.plan &&
                      props.plan.planId === 'BASICO2022' &&
                      props.plan.status === 'ACTIVE'
                        ? 'inactive'
                        : ''
                    }`}
                    disabled={
                      props.plan &&
                      props.plan.planId === 'BASICO2022' &&
                      props.plan.status === 'ACTIVE'
                    }
                    onClick={() =>
                      props.handleUpgradePlan(
                        'BASICO2022',
                        69000,
                        'Básico2022'
                      )
                    }>
                    {props.plan &&
                    props.plan.planId === 'BASICO2022' &&
                    props.plan.status === 'ACTIVE'
                      ? 'Plan actual'
                      : 'Subir de plan'}
                  </button>
                )}
              </div>
            </div>
          </Col>
          <Col xs={2}>
            <div className='plan-desc plan-features plan-advanced'>
              <div className='plan-title'>
                <div className='popular-plan'>
                  <i className='fas fa-star' /> Popular
                </div>
                Avanzado
              </div>
              <hr />

              <div className='plan-item plan-price'>
                <div style={{ textAlign: 'center' }}>
                  <div className='price'>
                    $ {monthlyPlanSelected ? advancedMonth : advancedYear}
                    <span> .000</span>
                  </div>

                  <div>/ cop</div>
                  <div>
                    <strong>
                      {!monthlyPlanSelected &&
                        props.environment !== 'foundationWallet' &&
                        'Ahorras $537.000'}
                    </strong>
                  </div>
                </div>
              </div>
              <hr />

              <div
                className='plan-item plan-item-icon included'
                style={{ height: '40px' }}>
                <i className='fas fa-check-circle' />
              </div>
              <hr />

              <div
                className='plan-item plan-item-icon included'
                style={{ height: '40px' }}>
                <i className='fas fa-check-circle' />
              </div>
              <hr />

              <div
                className='plan-item'
                style={{ height: '40px', fontSize: '1.2rem' }}>
                <strong>20</strong>
              </div>
              <hr />

              <div
                className='plan-item plan-item-icon included'
                style={{ height: '40px' }}>
                <i className='fas fa-check-circle' />
              </div>
              <hr />

              <div
                className='plan-item'
                style={{ height: '40px', fontSize: '1.2rem' }}>
                <strong>80</strong>
              </div>
              <hr />

              <div
                className='plan-item'
                style={{ height: '40px', fontSize: '1.2rem' }}>
                <strong>2</strong>
              </div>
              <hr />

              <div
                className='plan-item plan-item-icon included'
                style={{ height: '40px' }}>
                <i className='fas fa-check-circle' />
              </div>
              <hr />

              <div
                className='plan-item plan-item-icon included'
                style={{ height: '40px' }}>
                <i className='fas fa-check-circle' />
              </div>
              <hr />

              <div
                className='plan-item'
                style={{ height: '40px', fontSize: '1.2rem' }}>
                <strong>50</strong>
              </div>
              <hr />
              <div
                className='plan-item'
                style={{ height: '40px', fontSize: '1.2rem' }}>
                <strong>3</strong>
              </div>
              <hr />

              <div
                className='plan-item'
                style={{ height: '40px', fontSize: '1.2rem' }}>
                <strong>2,0%</strong>
              </div>
              <hr />

              <div
                className='plan-item'
                style={{ padding: '0 5px 15px' }}>
                {props.environment === 'foundationsLanding' && (
                  <button
                    className='landing-button'
                    style={{ padding: '5px 10px' }}
                    onClick={() => props.showDemoModal('Plan Avanzado')}>
                    ¡Lo quiero!
                  </button>
                )}
                {props.environment === 'foundationWallet' && (
                  <button
                    className={`nibi-btn ${
                      props.plan &&
                      props.plan.planId === 'ADVANCED2022' &&
                      props.plan.status === 'ACTIVE'
                        ? 'inactive'
                        : ''
                    }`}
                    disabled={
                      props.plan &&
                      props.plan.planId === 'ADVANCED2022' &&
                      props.plan.status === 'ACTIVE'
                    }
                    onClick={() =>
                      props.handleUpgradePlan(
                        'ADVANCED2022',
                        179000,
                        'Avanzado2022'
                      )
                    }>
                    {props.plan &&
                    props.plan.planId === 'ADVANCED2022' &&
                    props.plan.status === 'ACTIVE'
                      ? 'Plan actual'
                      : 'Subir de plan'}
                  </button>
                )}
              </div>
            </div>
          </Col>
          <Col xs={2}>
            <div className='plan-desc plan-features plan-pro'>
              <div className='plan-title'>Pro</div>
              <hr />

              <div className='plan-item plan-price'>
                <div style={{ textAlign: 'center' }}>
                  <div className='price'>
                    $ {monthlyPlanSelected ? proMonth : proYear}
                    <span> .000</span>
                  </div>

                  <div>/ cop</div>
                  <div>
                    <strong>
                      {!monthlyPlanSelected &&
                        props.environment !== 'foundationWallet' &&
                        'Ahorras $537.000'}
                    </strong>
                  </div>
                </div>
              </div>
              <hr />

              <div
                className='plan-item plan-item-icon included'
                style={{ height: '40px' }}>
                <i className='fas fa-check-circle' />
              </div>
              <hr />

              <div
                className='plan-item plan-item-icon included'
                style={{ height: '40px' }}>
                <i className='fas fa-check-circle' />
              </div>
              <hr />

              <div
                className='plan-item'
                style={{ height: '40px', fontSize: '1.2rem' }}>
                <strong>20</strong>
              </div>
              <hr />

              <div
                className='plan-item plan-item-icon included'
                style={{ height: '40px' }}>
                <i className='fas fa-check-circle' />
              </div>
              <hr />

              <div
                className='plan-item'
                style={{ height: '40px', fontSize: '1.2rem' }}>
                <strong>100</strong>
              </div>
              <hr />

              <div
                className='plan-item'
                style={{ height: '40px', fontSize: '1.2rem' }}>
                <strong>2</strong>
              </div>
              <hr />

              <div
                className='plan-item plan-item-icon included'
                style={{ height: '40px' }}>
                <i className='fas fa-check-circle' />
              </div>
              <hr />

              <div
                className='plan-item plan-item-icon included'
                style={{ height: '40px' }}>
                <i className='fas fa-check-circle' />
              </div>
              <hr />

              <div
                className='plan-item'
                style={{ height: '40px', fontSize: '1.2rem' }}>
                <strong>100</strong>
              </div>
              <hr />
              <div
                className='plan-item'
                style={{ height: '40px', fontSize: '1.2rem' }}>
                <strong>3</strong>
              </div>
              <hr />

              <div
                className='plan-item'
                style={{ height: '40px', fontSize: '1.2rem' }}>
                <strong>1,5%</strong>
              </div>
              <hr />

              <div
                className='plan-item'
                style={{ padding: '0 5px 15px' }}>
                {props.environment === 'foundationsLanding' && (
                  <button
                    className='landing-button'
                    style={{ padding: '5px 10px' }}
                    onClick={() => props.showDemoModal('Plan Avanzado')}>
                    ¡Lo quiero!
                  </button>
                )}
                {props.environment === 'foundationWallet' && (
                  <button
                    className={`nibi-btn ${
                      props.plan &&
                      props.plan.planId === 'PRO2022' &&
                      props.plan.status === 'ACTIVE'
                        ? 'inactive'
                        : ''
                    }`}
                    disabled={
                      props.plan &&
                      props.plan.planId === 'PRO2022' &&
                      props.plan.status === 'ACTIVE'
                    }
                    onClick={() =>
                      props.handleUpgradePlan(
                        'PRO2022',
                        399000,
                        'Avanzado2022'
                      )
                    }>
                    {props.plan &&
                    props.plan.planId === 'PRO2022' &&
                    props.plan.status === 'ACTIVE'
                      ? 'Plan actual'
                      : 'Subir de plan'}
                  </button>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

const FoundationSubscriptionPlans2022 = connect(
  select,
  mapDispatchToProps
)(ConnectedFoundationSubscriptionPlans2022);

export default FoundationSubscriptionPlans2022;
