import React from "react";
import { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import { formatMoney } from "../utils/formatMoney";
import PaymentExtension from "./PaymentExtension";
import "./styles/futbol-pazifico.scss";

// const foundationId = '5e6832390149723a18478fe4';
// const foundationName = 'Fundación Redux';
// const options = [
//   { id: '606dda32481cff20c0f7c417', label: 'Plan 1', value: 'ESCUELA', amount: 30000 },
//   { id: '60ebd4068defeb4754f9f28e', label: 'Plan 2', value: 'ALTO_RENDIMIENTO', amount: 20000 }
// ];

const foundationId = "6241df0a0c27c9db96fa3106";
const foundationName = "Corporación Más Que Tres Letras";
const options = [
  {
    id: "61378ba89651b47f1c76792e",
    label: "Apadrinar un niño escuela - $31.000 pesos mensuales",
    value: "ESCUELA",
    amount: 31000,
  },
  {
    id: "613789849651b47f1c767929",
    label: "Apadrinar un niño de alto rendimiento - $ 120.000 pesos mensuales",
    value: "ALTO_RENDIMIENTO",
    amount: 120000,
  },
];

function PaymentExtensionTresLetras() {
  const params = useParams();

  const [amount, setAmount] = useState(options[0].amount);
  const [selectedDonation, setSelectedDonation] = useState(options[0]);
  const [qty, setQty] = useState(1);
  const [showPayment, setShowPayment] = useState(false);

  const items = JSON.parse(window.atob(params.orderId)).products;

  return (
    <div className="payment-extension-component-fp">
      <Container>
        <Row>
          <Col>
            {showPayment ? (
              <PaymentExtension
                foundationId={foundationId}
                foundationName={foundationName}
                qty={qty}
                donationPlanId={""}
                onBack={() => setShowPayment(false)}
                items={items}
              />
            ) : (
              <>
                <div></div>
                <div>
                  <button
                    className="nibi-btn"
                    onClick={() => setShowPayment(true)}
                  >
                    Continuar
                  </button>
                </div>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PaymentExtensionTresLetras;
