import React, { useState, useEffect } from 'react';
import { Alert, Col, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import AppSpinner from '../Extras/AppSpinner';
import { wompiBaseUrl, wompiPublicKey } from '../utils/paymentKeys';
import RedeemNibisReminder from '../Extras/RedeemNibisReminder';
import { formatMoney } from '../utils/formatMoney';
import AddressInfoIcon from '../Extras/AddressInfoIcon';
import './styles/cashPayment.scss';

const select = state => {
  return {
    user: state.auth.user,
    token: state.auth.token
  };
};

function ConnectedCashPayment(props) {

  const {
    user,
    paymentType,
    token,
    amount,
    amountToPay,
    programId,
    foundationId,
    campaign,
    hasDiscountCode,
    discountCode,
    deliveryInfo,
    items,
    products,
    isRacePayment,
    palapacha,
    detail
  } = props;

  const [loading, setLoading] = useState(true);
  const [creatingTransaction, setCreatingTransaction] = useState(false);
  const [validated, setValidated] = useState(false);
  const [docType, setDocType] = useState(props.user && props.user.identificationType ? props.user.identificationType : 'CC');
  const [docNumber, setDocNumber] = useState(props.user && props.user.identification ? props.user.identification : '');
  const [cellPhone, setCellPhone] = useState(props.user && props.user.cellPhone ? props.user.cellPhone : '');
  const [referredBy, setReferredBy] = useState(props.user && props.user.referredBy ? props.user.referredBy : '');
  const [contactMethod, setContactMethod] = useState('EMAIL');
  const [acceptanceToken, setAcceptanceToken] = useState(null);
  const [permalink, setPermalink] = useState(null);
  const [transactionStatus, setTransactionStatus] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [businessAgreementCode, setBusinessAgreementCode] = useState('');
  const [paymentIntentionIdentifier, setPaymentIntentionIdentifier] = useState('');
  const [userCity, setUserCity] = useState(props.user && props.user.donationCity ? props.user.donationCity : '');
  const [userAddress, setUserAddress] = useState(props.user && props.user.donationAddress ? props.user.donationAddress : '');
  const showContactMethod = props.user && !props.user.contactMethod;
  let interval;

  useEffect(() => {
    getWompiData();
  }, []);

  useEffect(() => {
    if (acceptanceToken && permalink) setLoading(false);
  }, [acceptanceToken, permalink]);

  useEffect(() => {
    if (transactionStatus) setCreatingTransaction(false);
  }, [transactionStatus]);

  useEffect(() => {
    if (businessAgreementCode && paymentIntentionIdentifier) setTransactionStatus('SUCCESS');
  }, [businessAgreementCode, paymentIntentionIdentifier]);

  useEffect(() => {
    if (transactionId) interval = setInterval(checkTransaction, 2000);
  }, [transactionId]);

  const getWompiData = async () => {
    const acceptanceTokenResp = await fetch(`${wompiBaseUrl}/merchants/${wompiPublicKey}`);
    const acceptanceTokenData = await acceptanceTokenResp.json();

    const { data } = acceptanceTokenData;

    if (data) {
      const presignedData = data.presigned_acceptance;

      setAcceptanceToken(presignedData.acceptance_token);
      setPermalink(presignedData.permalink);
    }
  };

  const checkTransaction = async () => {

    if (!transactionStatus) {
      try {

        const transactionResponse = await fetch(`${wompiBaseUrl}/transactions/${transactionId}`);
        const transaction = await transactionResponse.json();

        const { data } = transaction;

        if (data && data.payment_method && data.payment_method.extra) {

          const { business_agreement_code, payment_intention_identifier } = data.payment_method.extra;

          if (business_agreement_code && payment_intention_identifier) {
            clearInterval(interval);
            setBusinessAgreementCode(business_agreement_code);
            setPaymentIntentionIdentifier(payment_intention_identifier);

            fetch('/email/bancolombia_collect', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                email: props.user.email,
                userName: props.user.firstName,
                amount: `$${formatMoney(props.amount)} COP`,
                business_agreement_code,
                payment_intention_identifier
              })
            });

          }
        }

      } catch (err) {
        clearInterval(interval);
        setTransactionStatus('ERROR');
      }
    }

  };

  const handleSubmit = async event => {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);

    const form = event.currentTarget;

    if (form.checkValidity()) {
      setCreatingTransaction(true);
      const transactionId = await createTransaction();

      if (props.gaTrackEventPayment) props.gaTrackEventPayment();

      if (transactionId) setTransactionId(transactionId);
    }
  };

  const createTransaction = async () => {

    let reference;

    if (isRacePayment) reference = await createRacePayment();
    else reference = await createPayment();

    const body = {
      acceptance_token: acceptanceToken,
      currency: 'COP',
      customer_email: user.email,
      payment_method: {
        type: 'BANCOLOMBIA_COLLECT'
      },
      reference
    };

    if (paymentType === 'DONATION') {

      body.amount_in_cents = parseInt(`${!hasDiscountCode ? amount : amountToPay}00`);

    } else if (paymentType === 'CART') {

      body.amount_in_cents = parseInt(`${amount}00`);

    }

    const transactionResponse = await fetch(`${wompiBaseUrl}/transactions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${wompiPublicKey}`
      },
      body: JSON.stringify(body)
    });

    const transactionData = await transactionResponse.json();

    if (!transactionData.data) {
      setTransactionStatus('ERROR');
    } else {
      return transactionData.data.id;
    }

  };

  const createRacePayment = async () => {
    const paymentResponse = await fetch('/strava/payment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify({
        foundationId,
        amount,
        paymentMethod: 'BANCOLOMBIA_COLLECT',
        userIdentification: docNumber,
        userIdentificationType: docType
      })
    });

    const paymentData = await paymentResponse.json();
    const { reference } = paymentData;

    return reference;
  }

  const createPayment = async () => {

    if (paymentType === 'DONATION') {

      const paymentResponse = await fetch('/payments/payment_gateway/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
        body: JSON.stringify({
          programId,
          foundationId,
          amount,
          paymentMethod: 'BANCOLOMBIA_COLLECT',
          userIdentification: docNumber,
          userIdentificationType: docType,
          cellPhone,
          contactMethod: showContactMethod ? contactMethod : '',
          campaign,
          referredBy,
          discountCode,
          hasDiscountCode,
          amountPayed: amountToPay,
          userCity,
          userAddress,
          deliveryInfo: deliveryInfo && deliveryInfo.street + " " + (deliveryInfo.extra ? deliveryInfo.extra : '') + " " +
            (deliveryInfo.area ? deliveryInfo.area : '') + ", " + deliveryInfo.city + ", " + deliveryInfo.state + ", " +
            deliveryInfo.country + ", " + deliveryInfo.phone,
          detail: palapacha ? 'palapacha' : detail, 
        })
      });

      const paymentData = await paymentResponse.json();
      const { reference } = paymentData;

      return reference;

    } else if (paymentType === 'CART') {

      const body = {
        userId: user._id,
        items,
        products,
        totalAmount: amount,
        paymentMethod: 'BANCOLOMBIA_COLLECT',
        userIdentification: docNumber,
        userIdentificationType: docType,
        userCity,
        userAddress,
        cellPhone,
        contactMethod: showContactMethod ? contactMethod : '',
        campaign
      };

      if (products.length > 0 && deliveryInfo) {
        body.deliveryInfo =
          deliveryInfo.street + ' ' +
          (deliveryInfo.extra ? deliveryInfo.extra : '') + ' ' +
          (deliveryInfo.area ? deliveryInfo.area : '') + ', ' +
          deliveryInfo.city + ', ' +
          deliveryInfo.state + ', ' +
          deliveryInfo.country + ', ' +
          deliveryInfo.phone;
      }

      const paymentResponse = await fetch('/shoppingCartItems/createCartPayment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
        body: JSON.stringify(body)
      });

      const paymentData = await paymentResponse.json();
      const { reference } = paymentData;

      return reference;
    }
  };

  if (loading || creatingTransaction) {
    return (
      <div className="cash-payment-component">
        <AppSpinner />
        {
          creatingTransaction &&
          <h4 className="transaction-message">
            Estamos procesando la transacción. Por favor espera.
          </h4>
        }
      </div>
    );
  }

  if (transactionStatus) {
    return (
      <div className="cash-payment-component">
        {
          transactionStatus === 'SUCCESS' ?
            <>
              <h5 className="transaction-message">
                A continuación te compartimos el número de convenio y el número de intención de pago
                para que puedas realizar tu pago en cualquier Corresponsal Bancario Bancolombia (también enviaremos esta información a tu correo):
            </h5>
              <br />

              <h3 className="transaction-message">
                Número de convenio: {businessAgreementCode}
              </h3>
              <h3 className="transaction-message">
                Número de intención de pago: {paymentIntentionIdentifier}
              </h3>
            </> :
            <h3 className="transaction-message">
              Error al procesar la transacción. Inténtalo nuevamente o comunícate con nosotros.
          </h3>
        }

      </div>
    );
  }

  return (
    <div className="cash-payment-component">
      <Alert variant="info">
        Te proporcionaremos el <strong>número de convenio</strong> y el <strong>número de intención de pago</strong> para que puedas completar tu pago en
        cualquier Corresponsal Bancario Bancolombia.
      </Alert>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        {
          paymentType === 'DONATION' &&
          <Form.Row>
            <Form.Group as={Col} md={6} xs={12}>
              <Form.Label>¿Tienes código de referido?</Form.Label>
              <Form.Control
                type="text"
                placeholder="Código referido"
                name="referredBy"
                value={props.user.referredBy ? props.user.referredBy : referredBy}
                onChange={event => setReferredBy(event.target.value)}
                disabled={props.user.referredBy ? true : false}
              />
            </Form.Group>
          </Form.Row>
        }

        <Form.Row>
          <Form.Group as={Col} md={4} xs={12}>
            <Form.Label>Número de teléfono</Form.Label>
            <Form.Control
              type="text"
              value={cellPhone}
              onChange={event => setCellPhone(event.target.value)}
              placeholder="Teléfono"
              required
            />
          </Form.Group>
          <Form.Group as={Col} md={4} xs={12}>
            <Form.Label>Tipo de documento</Form.Label>
            <Form.Control
              as="select"
              value={docType}
              onChange={event => setDocType(event.target.value)}
              required
            >
              <option value="CC">CC</option>
              <option value="NIT">NIT</option>
              <option value="CE">CE</option>
              <option value="Otro">Otro</option>
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} md={4} xs={12}>
            <Form.Label>Número de documento</Form.Label>
            <Form.Control
              type="text"
              value={docNumber}
              onChange={event => setDocNumber(event.target.value)}
              placeholder="Documento"
              required
            />
          </Form.Group>
          <Form.Group as={Col} md={6} xs={12}>
            <Form.Label>
              Ciudad <AddressInfoIcon />
            </Form.Label>
            <Form.Control
              type="text"
              value={userCity}
              onChange={event => setUserCity(event.target.value)}
              placeholder="Ciudad"
              required
            />
          </Form.Group>
          <Form.Group as={Col} md={6} xs={12}>
            <Form.Label>
              Dirección <AddressInfoIcon />
            </Form.Label>
            <Form.Control
              type="text"
              value={userAddress}
              onChange={event => setUserAddress(event.target.value)}
              placeholder="Dirección"
              required
            />
          </Form.Group>
          {
            showContactMethod &&
            <Form.Group as={Col} md={6} xs={12}>
              <Form.Label>¿Por dónde te gustaría recibir la información de tu impacto?</Form.Label>
              <Form.Control
                as="select"
                value={contactMethod}
                onChange={event => setContactMethod(event.target.value)}
              >
                <option value="WHATS_APP">WhatsApp</option>
                <option value="CELL_PHONE">Llamada</option>
                <option value="EMAIL">Correo</option>
              </Form.Control>
            </Form.Group>
          }
          <Col xs={12}>
            <div className="custom-control custom-checkbox">
              <input type="checkbox" className="custom-control-input" id="permalinkCheckBox1" required />
              <label className="custom-control-label" htmlFor="permalinkCheckBox1">
                Acepto haber leído los <a href={permalink} target="_blank" rel="noopener noreferrer">términos y condiciones y la política de privacidad</a> para hacer este pago.
              </label>
            </div>
          </Col>
          {
            paymentType === 'DONATION' &&
            <Col xs={12}>
              <RedeemNibisReminder />
            </Col>
          }

          <button className="nibi-btn" type="submit">
            Continuar
          </button>

        </Form.Row>
      </Form>
    </div>
  );

}

const CashPayment = connect(select)(ConnectedCashPayment);

export default CashPayment;
