import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import RegisterModal from '../modals/RegisterModal';
import LoginModal from '../modals/LoginModal';
import {
  Navbar,
  Nav,
  Popover,
  OverlayTrigger,
  NavDropdown,
  Form,
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import Select from 'react-select';
import { connect } from 'react-redux';
import {
  userLogout,
  setShowRegisterModal,
  setShowLoginModal,
  setShowShoppingCart,
} from '../Redux/modules/auth';
import { getFromStorage } from '../utils/storage';
import './styles/navbar.scss';
import mobileIcon from '../img/nibi icon B.svg';
import desktopIcon from '../img/logo_nibi_2.png';
import menu from '../img/menu.svg';
import { wsDisconnect } from '../Redux/modules/websocket';

function mapDispatchToProps(dispatch) {
  return {
    userLogout: () => dispatch(userLogout()),
    setShowRegisterModal: (show) => dispatch(setShowRegisterModal(show)),
    setShowLoginModal: (show) => dispatch(setShowLoginModal(show)),
    setShowShoppingCart: (show) => dispatch(setShowShoppingCart(show)),
    wsDisconnect: () => dispatch(wsDisconnect()),
  };
}

const select = (state) => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    isFoundation: state.auth.isFoundation,
    isEnterprise: state.auth.isEnterprise,
    showRegisterModal: state.auth.showRegisterModal,
    showLoginModal: state.auth.showLoginModal,
    showShoppingCart: state.auth.showShoppingCart,
    chatNotification: state.chat.chatNotification,
    chatNotificationNumber: state.chat.chatNotificationNumber,
    isBusinessAdmin: state.business.isBusinessAdmin,
  };
};

const customStyles = {
  dropdownIndicator: (base) => {
    let changes = {
      display: 'none',
    };
    return Object.assign(base, changes);
  },
  placeholder: (base) => {
    let changes = {
      color: '#E0E0E0',
      fontWeight: '400',
      fontSize: '13px',
    };
    return Object.assign(base, changes);
  },
  input: (base) => ({
    ...base,
    input: {
      color: '#707070',
      opacity: '1 !important',
    },
  }),
  menu: (base) => {
    let changes = {
      zIndex: '2',
    };
    return Object.assign(base, changes);
  },
  indicatorsContainer: (base) => {
    let changes = {
      display: 'none',
    };
    return Object.assign(base, changes);
  },
  control: (base) => {
    let changes = {
      border: 'none',
      borderRadius: '100px',
      fontSize: '13px',
      paddingLeft: '8px',
      height: 30,
      minHeight: 30,
      boxShadow:
        '0px 3px 10px 0px rgba(0,0,0,0.1), 0 2px 3px 0 rgba(0,0,0,0.2)',
    };
    return Object.assign(base, changes);
  },
};

class ConnectedNavbar extends Component {
  constructor(props) {
    super(props);

    this.timeout = 0;
    this.state = {
      search: '',
      loadingSearch: false,
      searchResults: [],
      newSearch: true,
    };

    this.logout = this.logout.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseRegisterModal =
      this.handleCloseRegisterModal.bind(this);
    this.handleShowRegisterModal = this.handleShowRegisterModal.bind(this);
    this.handleCloseLoginModal = this.handleCloseLoginModal.bind(this);
    this.handleShowLoginModal = this.handleShowLoginModal.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }
  componentDidMount() {
    const path = window.location.pathname;
    if (path.includes('/login1')) {
      this.props.setShowLoginModal(true);
    }
    if (path.includes('/register1')) {
      this.props.setShowRegisterModal({ show: true });
    }
  }

  handleSearchChange(inputValue, { action }) {
    if (action === 'input-change') this.setState({ search: inputValue });

    if (inputValue) {
      this.setState({ loadingSearch: true });

      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(async () => {
        const resultsFetch = await fetch(
          `/programs/search/simple?search=${inputValue}`
        );
        const data = await resultsFetch.json();

        if (data.success) this.setState({ searchResults: data.results });
        this.setState({ loadingSearch: false });
      }, 1000);
    } else {
      if (this.timeout) clearTimeout(this.timeout);
    }
  }

  handleSelectSearch(option) {
    const { history } = this.props;

    if (option) {
      if (option.label.includes('Buscar'))
        this.setState({ search: option.value });
      else this.setState({ search: option.label });

      const { type, label, value } = option;
      const url = encodeURIComponent(label.replace(/ /g, '_'));

      if (type === 'program') history.push(`/programas/${url}`);
      else if (type === 'virtualProgram')
        history.push(`/voluntariado_digital/${url}`);
      else if (type === 'foundation')
        history.push(`/fundaciones/id/${url}`);
      else if (type === 'enterprise') history.push(`/comercios/id/${url}`);
      else if (type === 'benefit') history.push(`/buscar?q=${label}`);
      else if (value) history.push(`/buscar?q=${value}`);
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    const { search } = this.state;

    if (search) this.props.history.push(`/buscar?q=${search}`);
  }

  handleCloseRegisterModal() {
    this.props.setShowRegisterModal({ show: false });
  }

  handleShowRegisterModal() {
    this.props.setShowRegisterModal({ show: true });
  }

  handleCloseLoginModal() {
    this.props.setShowLoginModal(false);
  }

  handleShowLoginModal() {
    this.props.setShowLoginModal(true);
  }

  async logout() {
    const obj = getFromStorage('nibi_app');

    if (obj && obj.token) {
      const { token } = obj;
      // Verify token
      const prom = await fetch('/signin/account/logout?token=' + token);
      const json = await prom.json();

      if (json.success) {
        if (
          this.props.location.pathname.includes('dashboard') ||
          this.props.location.pathname.includes('chat')
        ) {
          this.props.history.push('/');
        }
        this.props.userLogout();
      }
    }

    this.props.wsDisconnect();
  }

  render() {
    const {
      location,
      user,
      isBusinessAdmin,
      token,
      isFoundation,
      isEnterprise,
      setShowShoppingCart,
      showShoppingCart,
      chatNotificationNumber,
      chatNotification,
      showRegisterModal,
      showLoginModal,
      history,
      hideNavbar,
    } = this.props;
    const { search, loadingSearch, searchResults } = this.state;

    const popover = (
      <Popover id='popover-positioned-bottom'>
        <Popover.Title as='h3'>
          {user.totalNibis > 0 ? (
            <div>
              Tienes {user.totalNibis}{' '}
              <img
                src={require('../img/nibi_coins.png')}
                style={{ height: '15px' }}
                aria-hidden='true'
                alt='Moneda Nibi'
              />{' '}
              nibis disponibles
            </div>
          ) : (
            <div>{user.totalNibis}</div>
          )}
        </Popover.Title>
        <Popover.Content className='nibisWindowContent'>
          {user.nibiArray &&
            user.nibiArray.length > 0 &&
            user.nibiArray
              .slice(0)
              .reverse()
              .map((nibis, i) => (
                <div
                  key={i}
                  style={{ fontSize: '0.8em' }}>
                  {nibis.nibis > 0 ? (
                    <span style={{ color: 'green' }}>
                      <strong>{nibis.nibis}</strong>{' '}
                    </span>
                  ) : (
                    <span style={{ color: 'red' }}>
                      <strong>{nibis.nibis}</strong>{' '}
                    </span>
                  )}
                  <img
                    src={require('../img/nibi_coins.png')}
                    style={{ height: '15px' }}
                    aria-hidden='true'
                    alt='Moneda Nibi'
                  />
                  {nibis.description}
                  <hr />
                </div>
              ))}
        </Popover.Content>
      </Popover>
    );
    // Demo
    const demo = user && user.email === 'no-reply@nibi.com.co';
    // Cemex
    const path = window.location.pathname;
    const loginCemex = path.includes('/login') || path.includes('/form');
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const dashboardRoute = isFoundation
      ? 'dashboardFoundation'
      : isEnterprise
      ? 'dashboardEnterprise'
      : 'dashboardUser';

    return (
      <>
        <Navbar
          expand='lg'
          className={`${hideNavbar ? 'hiddennavbar' : ''} lg`}>
          <Navbar.Brand>
            <Link to='/home'>
              <img
                id='homeLogo'
                // src={isMobile ? mobileIcon : require("../img/logo_nibi_2.png")}
                src={desktopIcon}
                alt='Regresar a la página principal'
              />
            </Link>
          </Navbar.Brand>
          {!isMobile && (
            <Nav className='navbarLeftSection mar-auto'>
              <Link
                className='highlight-link'
                to='/explora?programType=PROYECTO'>
                Explorar
              </Link>
              <NavDropdown
                title='Servicios'
                className='highlight-link'>
                <NavDropdown.Item
                  onClick={() => history.push(`/fundaciones`)}
                  className='nav-dropdown-item'>
                  Para fundaciones
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => history.push(`/info/empresas`)}
                  className='nav-dropdown-item'>
                  Para empresas
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => history.push(`/info/comercios`)}
                  className='nav-dropdown-item'>
                  Para emprendimientos
                </NavDropdown.Item>
              </NavDropdown>
              <Link
                style={{ width: '200px' }}
                className='highlight-link'
                to='/sobre-nibi'>
                Sobre nibi
              </Link>
              <Form
                inline
                onSubmit={this.handleSubmit}>
                <div
                  className='search-bar'
                  style={{ marginTop: '5px' }}>
                  <Select
                    className='search-bar-input'
                    styles={customStyles}
                    placeholder={
                      <>
                        <i className='fas fa-search' />
                        <span>
                          {isMobile
                            ? 'Estoy buscando...'
                            : 'Busca fundaciones, campañas o productos'}
                        </span>
                      </>
                    }
                    onChange={(option) => this.handleSelectSearch(option)}
                    onInputChange={(inputValue, event) =>
                      this.handleSearchChange(inputValue, event)
                    }
                    options={!loadingSearch ? searchResults : []}
                    inputValue={search}
                    isMulti={false}
                    isClearable={true}
                    escapeClearsValue={false}
                    noOptionsMessage={(obj) =>
                      !obj.inputValue
                        ? 'Buscar más campañas o cualquier producto de la tienda'
                        : loadingSearch
                        ? 'Cargando...'
                        : 'No se encontraron resultados'
                    }
                  />
                </div>
              </Form>
            </Nav>
          )}

          <div className='navbarCenterSection'>
            {isMobile && (
              <Form
                inline
                onSubmit={this.handleSubmit}>
                <div
                  className='search-bar-mobile'
                  style={{ marginTop: '5px' }}>
                  <Select
                    className='search-bar-input'
                    styles={customStyles}
                    placeholder={
                      <>
                        <i className='fas fa-search' />
                        <span>
                          {isMobile
                            ? 'Estoy buscando...'
                            : 'Buscar más campañas o cualquier producto de la tienda'}
                        </span>
                      </>
                    }
                    onChange={(option) => this.handleSelectSearch(option)}
                    onInputChange={(inputValue, event) =>
                      this.handleSearchChange(inputValue, event)
                    }
                    options={!loadingSearch ? searchResults : []}
                    inputValue={search}
                    isMulti={false}
                    isClearable={true}
                    escapeClearsValue={false}
                    noOptionsMessage={(obj) =>
                      !obj.inputValue
                        ? 'Buscar más campañas o cualquier producto de la tienda'
                        : loadingSearch
                        ? 'Cargando...'
                        : 'No se encontraron resultados'
                    }
                  />
                </div>
              </Form>
            )}

            <Nav className='mr-auto'>
              {
                // Desktop center
                path.includes('cemex') ? (
                  <>
                    {!loginCemex && (
                      <>
                        <div className='ml-auto'></div>

                        <Link
                          className='highlight-link'
                          to='/cemex/categorias?ayudar=0'>
                          Necesito apoyo
                        </Link>
                        <Link
                          className='highlight-link'
                          to='/cemex/categorias?ayudar=1'>
                          Quiero ayudar
                        </Link>
                        <Link
                          className='highlight-link'
                          to='/cemex/mercado'>
                          Mercado
                        </Link>
                        <Link to='/cemex/perfil'>
                          <Link>
                            <img
                              alt='Ir al perfil'
                              className='avatar'
                              src={require('../img/avatar.png')}
                            />
                          </Link>
                        </Link>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {/* {
                      !isMobile &&
                      <>
                        <Link className="highlight-link" to={demo ? "/aportes/explora" : "/explora?programType=PROYECTO"}>
                          Donar
                        </Link>
                        <Link className="highlight-link" to={demo ? "/aportes/explora" : "/explora?programType=VIRTUAL"}>
                          Voluntariados
                        </Link>
                        <Link className="highlight-link" to={demo ? "/empresas/beneficios" : "/beneficios"}>
                          Tienda
                        </Link>
                        <Link className="highlight-link" to="/sobre-nibi">
                          Sobre<span style={{ color: 'transparent' }}>_</span>Nibi
                        </Link>
                      </>
                    } */}
                  </>
                )
              }
            </Nav>
          </div>

          {
            // Mobile right side
            isMobile && (
              <div className='userFunctionsSection'>
                {token && !isFoundation && !isEnterprise && (
                  <div
                    className='functionIcon'
                    id='shoppingCartIcon'
                    onClick={() => setShowShoppingCart(!showShoppingCart)}>
                    <i
                      className='fas fa-shopping-cart fa-lg nav-link'
                      alt='shopping cart'
                    />
                  </div>
                )}
                <NavDropdown
                  alignRight
                  title={
                    <img
                      src={menu}
                      alt='Expandir o contraer menú'
                    />
                  }
                  className='toggle-mobile'>
                  {!token && !path.includes('cemex') && (
                    <>
                      <div className='dropdownTitle'>Bienvenido</div>
                      <div className='dropdownSubtitle'>
                        Ingresa a tu cuenta o crea una nueva
                      </div>
                      <button
                        className='nav-dropdown-item logButton'
                        onClick={this.handleShowLoginModal}>
                        Ingresa
                      </button>
                      <button
                        className='nav-dropdown-item logButton'
                        onClick={this.handleShowRegisterModal}>
                        Regístrate
                      </button>
                      <NavDropdown.Divider />
                    </>
                  )}

                  {token && (
                    <>
                      <Container fluid>
                        <Row noGutters>
                          <Col xs={3}>
                            <div
                              className='functionIcon avatar'
                              style={{
                                backgroundImage:
                                  user.logo && user.logo !== ''
                                    ? `url(${user.logo})`
                                    : `url(${require('../img/user.png')})`,
                              }}
                            />
                          </Col>
                          <Col xs={9}>
                            <div
                              className='dropdownTitle'
                              style={{ marginTop: '8px' }}>
                              Bienvenido
                            </div>
                            <div className='dropdownSubtitle'>
                              {isFoundation || isEnterprise
                                ? user.name
                                : `${user.firstName} ${user.lastName}`}
                            </div>
                          </Col>
                        </Row>
                      </Container>
                      <NavDropdown.Divider />
                      <NavDropdown.Item className='nav-dropdown-item'>
                        <Link to={`/${dashboardRoute}/perfil`}>
                          Mi cuenta
                        </Link>
                      </NavDropdown.Item>
                      {!isEnterprise && !isFoundation && (
                        <>
                          <NavDropdown.Item className='nav-dropdown-item'>
                            <Link to={`/${dashboardRoute}/programas`}>
                              Mis campañas
                            </Link>
                          </NavDropdown.Item>
                          <NavDropdown.Item className='nav-dropdown-item'>
                            <Link to={`/${dashboardRoute}/compras`}>
                              Mis compras
                            </Link>
                          </NavDropdown.Item>
                          <NavDropdown.Item className='nav-dropdown-item'>
                            <Link to={`/${dashboardRoute}/suscripciones`}>
                              Mis suscripciones
                            </Link>
                          </NavDropdown.Item>
                          <NavDropdown.Item className='nav-dropdown-item'>
                            <Link to={`/${dashboardRoute}/certificados`}>
                              Certificados
                            </Link>
                          </NavDropdown.Item>
                        </>
                      )}
                      {isFoundation && (
                        <>
                          <NavDropdown.Item className='nav-dropdown-item'>
                            <Link to={`/${dashboardRoute}/creaciones`}>
                              Mis creaciones
                            </Link>
                          </NavDropdown.Item>
                          <NavDropdown.Item className='nav-dropdown-item'>
                            <Link to={`/${dashboardRoute}/crm`}>
                              Base de Datos
                            </Link>
                          </NavDropdown.Item>
                        </>
                      )}
                      {isEnterprise && (
                        <>
                          <NavDropdown.Item className='nav-dropdown-item'>
                            <Link to={`/${dashboardRoute}/productos`}>
                              Mis productos
                            </Link>
                          </NavDropdown.Item>
                        </>
                      )}
                      <NavDropdown.Item className='nav-dropdown-item'>
                        <Link to={`/${dashboardRoute}/billetera`}>
                          Billetera
                        </Link>
                      </NavDropdown.Item>
                      {isFoundation && (
                        <>
                          <NavDropdown.Item className='nav-dropdown-item'>
                            <Link to={`/${dashboardRoute}/messageModule`}>
                              Mensajes
                            </Link>
                          </NavDropdown.Item>
                        </>
                      )}
                      {isEnterprise && (
                        <NavDropdown.Item className='nav-dropdown-item'>
                          <Link to={`/${dashboardRoute}/ventas`}>
                            Mis ventas
                          </Link>
                        </NavDropdown.Item>
                      )}
                      <hr />
                    </>
                  )}
                  <NavDropdown.Item className='nav-dropdown-item'>
                    <Link to='/explora?programType=PROYECTO'>
                      Explorar
                    </Link>
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item className="nav-dropdown-item">
                    <Link to="/explora?programType=VIRTUAL">Voluntariados</Link>
                  </NavDropdown.Item> */}
                  {/* <NavDropdown.Item className="nav-dropdown-item">
                    <Link to="/beneficios">Tienda</Link>
                  </NavDropdown.Item> */}
                  {!token && (
                    <>
                      <NavDropdown.Item className='nav-dropdown-item'>
                        <Link to='/info/empresas'>Soy Empresa</Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item className='nav-dropdown-item'>
                        <Link to='/fundaciones'>Soy ONG</Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item className='nav-dropdown-item'>
                        <Link to='/info/comercios'>Soy Marca</Link>
                      </NavDropdown.Item>
                    </>
                  )}
                  <NavDropdown.Item className='nav-dropdown-item'>
                    <Link to='/sobre-nibi'>Sobre Nibi</Link>
                  </NavDropdown.Item>
                  {token && (
                    <>
                      <NavDropdown.Divider />
                      <NavDropdown.Item className='nav-dropdown-item'>
                        <div onClick={this.logout}>Cerrar sesión</div>
                      </NavDropdown.Item>
                    </>
                  )}
                </NavDropdown>
              </div>
            )
          }

          {
            //Desktop right side
            !isMobile && (
              <div className='userFunctionsSection'>
                {token && (
                  <Link
                    className='highlight-link-2 functionIcon'
                    to='/chat'>
                    <i className='far fa-comment fa-lg' />
                    {chatNotification && (
                      <>
                        <span className='chat-notification-bubble'></span>
                        <span
                          style={{
                            fontSize: '0.7rem',
                            fontWeight: '300',
                          }}>
                          {' '}
                          {chatNotificationNumber}
                        </span>
                      </>
                    )}
                  </Link>
                )}
                {!token && !path.includes('cemex') && (
                  <>
                    <a
                      onClick={this.handleShowLoginModal}
                      style={{
                        color: '#302465',
                        paddingBottom: '0px',
                        cursor: 'pointer',
                        marginRight: '6px',
                      }}>
                      Ingresa
                    </a>
                    <div
                      style={{ paddingBottom: '0px' }}
                      className='register-web-button-navbar'
                      onClick={this.handleShowRegisterModal}>
                      <button className='hexagon-btn'>Regístrate</button>
                    </div>
                  </>
                )}
                {token && !isFoundation && !isEnterprise && (
                  <div
                    className='functionIcon'
                    onClick={() => setShowShoppingCart(!showShoppingCart)}>
                    <i
                      className='fas fa-shopping-cart fa-lg nav-link'
                      alt='shopping cart'
                    />
                  </div>
                )}
                {token && (
                  <OverlayTrigger
                    trigger='click'
                    placement='bottom'
                    overlay={popover}
                    rootClose={true}>
                    <div className='functionIcon'>
                      <img
                        src={require('../img/nibi_coins.png')}
                        style={{ height: '25px' }}
                        alt='Total de nibis disponibles'
                      />
                      <span style={{ color: '#302464', fontSize: '13px' }}>
                        {user.totalNibis}
                      </span>
                    </div>
                  </OverlayTrigger>
                )}
                {token && (
                  <NavDropdown
                    title=''
                    className='functionIcon avatar'
                    alignRight
                    style={{
                      backgroundImage:
                        user.logo && user.logo !== ''
                          ? `url(${user.logo})`
                          : `url(${require('../img/user.png')})`,
                    }}>
                    {isBusinessAdmin && (
                      <>
                        <NavDropdown.Item
                          onClick={() =>
                            history.push(`/${dashboardRoute}/usuarios`)
                          }
                          className='nav-dropdown-item'>
                          Usuarios
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={() =>
                            history.push(`/${dashboardRoute}/estrategia`)
                          }
                          className='nav-dropdown-item'>
                          Estrategia
                        </NavDropdown.Item>
                      </>
                    )}
                    <NavDropdown.Item
                      onClick={() =>
                        history.push(`/${dashboardRoute}/perfil`)
                      }
                      className='nav-dropdown-item'>
                      Mi cuenta
                    </NavDropdown.Item>
                    {!isFoundation && !isEnterprise && (
                      <NavDropdown.Item
                        onClick={() =>
                          history.push(`/${dashboardRoute}/programas`)
                        }
                        className='nav-dropdown-item'>
                        Mis campañas
                      </NavDropdown.Item>
                    )}
                    {isFoundation && (
                      <>
                        <NavDropdown.Item
                          onClick={() =>
                            history.push(`/${dashboardRoute}/creaciones`)
                          }
                          className='nav-dropdown-item'>
                          Mis creaciones
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={() =>
                            history.push(`/${dashboardRoute}/crm`)
                          }
                          className='nav-dropdown-item'>
                          Base de Datos
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={() =>
                            history.push(
                              `/${dashboardRoute}/messageModule`
                            )
                          }
                          className='nav-dropdown-item'>
                          Mensajes
                        </NavDropdown.Item>
                      </>
                    )}
                    {isEnterprise && (
                      <>
                        <NavDropdown.Item
                          onClick={() =>
                            history.push(`/${dashboardRoute}/productos`)
                          }
                          className='nav-dropdown-item'>
                          Productos
                        </NavDropdown.Item>
                      </>
                    )}
                    {isEnterprise && (
                      <NavDropdown.Item
                        onClick={() =>
                          history.push(`/${dashboardRoute}/ventas`)
                        }
                        className='nav-dropdown-item'>
                        Ventas
                      </NavDropdown.Item>
                    )}
                    <NavDropdown.Item
                      onClick={() =>
                        history.push(`/${dashboardRoute}/billetera`)
                      }
                      className='nav-dropdown-item'>
                      Billetera
                    </NavDropdown.Item>
                    {!isEnterprise && !isFoundation && (
                      <>
                        <NavDropdown.Item
                          onClick={() =>
                            history.push(`/${dashboardRoute}/compras`)
                          }
                          className='nav-dropdown-item'>
                          Compras
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={() =>
                            history.push(
                              `/${dashboardRoute}/suscripciones`
                            )
                          }
                          className='nav-dropdown-item'>
                          Donaciones recurrentes
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={() =>
                            history.push(`/${dashboardRoute}/certificados`)
                          }
                          className='nav-dropdown-item'>
                          Certificados
                        </NavDropdown.Item>
                      </>
                    )}
                    <NavDropdown.Divider />
                    <NavDropdown.Item className='nav-dropdown-item'>
                      <div onClick={this.logout}>Cerrar sesión</div>
                    </NavDropdown.Item>
                  </NavDropdown>
                )}
              </div>
            )
          }
        </Navbar>

        <RegisterModal
          loggedIn={token}
          show={showRegisterModal}
          handleShowRegisterModal={this.handleShowRegisterModal}
          handleCloseRegisterModal={this.handleCloseRegisterModal}
          handleShowLoginModal={this.handleShowLoginModal}
        />
        <LoginModal
          loggedIn={token}
          show={showLoginModal}
          handleShowLoginModal={this.handleShowLoginModal}
          handleCloseLoginModal={this.handleCloseLoginModal}
          handleShowRegisterModal={this.handleShowRegisterModal}
        />
      </>
    );
  }
}

const NavbarMain = connect(select, mapDispatchToProps)(ConnectedNavbar);

export default withRouter(NavbarMain);
