import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { getFromStorage } from './utils/storage';

// Utils
import wsUrl from './utils/wsUrl';

// CSS
import './App.scss';

// Redux
import { connect } from 'react-redux';
import { wsConnect } from './Redux/modules/websocket';
import {
  userLogin,
  setUser,
  setIsFoundation,
  setIsEnterprise,
  setUserId,
  setToken,
  setLanguage,
  setExchangeRate,
  setShowShoppingCart,
} from './Redux/modules/auth';
import {
  setIsBusinessAdmin,
  setBusinessPlan,
} from './Redux/modules/business';

// Static components
import NavbarMain from './Extras/Navbar';
import Footer from './Extras/Footer.js';
import ScrollToTop from './Extras/ScrollToTop.js';
import PushNotifications from './PushNotifications/PushNotifications.jsx';
import UpgradeBillingPlanModalComponent from './modals/UpgradeBillingPlanModal';
import ShoppingCart from './ShoppingCart/ShoppingCart.js';
import Nibify from './Nibify/Nibify';
import FoundationUserRegister from './FoundationUserRegister/FoundationUserRegister';
import PrivacyPolicy from './Extras/PrivacyPolicy';
import FoundationsLanding from './FoundationsLanding/FoundationsLanding';
import SubscriptionPaymentLanding from './SubscriptionPaymentLanding/SubscriptionPaymentLanding';
import PaymentExtensionFutbolPazifico from './PaymentExtension/PaymentExtensionFutbolPazifico';
import PaymentExtensionTresLetras from './PaymentExtension/PaymentExtensionTresLetras';
import ParcerosMQTL from './Extensions/ParcerosMQTL';
import EmailValidationWarningComponent from './Extras/EmailValidationWarning';
import PaymentExtensionRehabilitacionIntegral from './PaymentExtension/PaymentExtensionRehabilitacionIntegral';

// Home, explora & store
const NibiHome = lazy(() => import('./Home/NibiHome'));
const Homepage = lazy(() => import('./Home/Homepage'));
// const Home = lazy(() => import('./Home/Home.js'));
const BenefitsList = lazy(() => import('./Benefits/BenefitsList'));
const Explora = lazy(() => import('./Programas/Explora'));
const ImpactProfile = lazy(() => import('./Impacts/ImpactProfile'));

// Old lists components & about page
const FoundationsList = lazy(() =>
  import('./Foundations/FoundationsList')
);
const EnterprisesList = lazy(() =>
  import('./Enterprises/EnterprisesList')
);
const About = lazy(() => import('./About/About.js'));

// Back office components
const DashboardUser = lazy(() =>
  import('./Dashboards/Users/DashboardUser.js')
);
const DashboardEnterprise = lazy(() =>
  import('./Dashboards/Enterprises/DashboardEnterprise.js')
);
const DashboardFoundation = lazy(() =>
  import('./Dashboards/Foundations/DashboardFoundation.js')
);

// Program components
const Program = lazy(() => import('./Programas/Programa/Program/Program'));
const VirtualProgram = lazy(() =>
  import('./Programas/VirtualProgram/VirtualProgram.js')
);
const Results = lazy(() => import('./Programas/Results/Results.js'));

// Public profile components
const FoundationProfile = lazy(() =>
  import('./Foundations/NewFoundationProfile')
);
const FoundationPlanResults = lazy(() =>
  import('./Foundations/FoundationPlanResults')
);
const CommerceProfile = lazy(() =>
  import('./Enterprises/CommerceProfile')
);
const FoundationMultipurposeLanding = lazy(() =>
  import('./Foundations/FoundationMultipurposeLanding')
);

// Password change components
const PasswordResetComponent = lazy(() =>
  import('./PasswordReset/PasswordResetComponent.js')
);
const RecoverPasswordComponent = lazy(() =>
  import('./PasswordReset/RecoverPasswordComponent.js')
);

// Campaign components
const RaceLandingComponent = lazy(() =>
  import('./Campaigns/RaceLandingComponent.jsx')
);
const RaceLandingComponent2 = lazy(() =>
  import('./Campaigns/RaceLanding2020.jsx')
);
const RaceLanding2021Component = lazy(() =>
  import('./Campaigns/RaceLanding2021.jsx')
);
const FestivalComponent = lazy(() =>
  import('./Campaigns/FestivalComponent.jsx')
);

// Blog components
const BlogListComponent = lazy(() =>
  import('./Blog/BlogList/BlogList.js')
);
const BlogArticleComponent = lazy(() =>
  import('./Blog/BlogArticle/BlogArticle')
);

// Chat components
const Chat = lazy(() => import('./Chat/Chat.js'));

// Login page components
const LoginPageComponent = lazy(() => import('./LoginPage/LoginPage.js'));

// Payment page components
const LandingPsePaymentComponent = lazy(() =>
  import('./PsePayment/LandingPsePayment.js')
);
const RecurringPaymentPseComponent = lazy(() =>
  import('./PsePayment/RecurringPaymentPse.js')
);
const PaymentResultComponent = lazy(() =>
  import('./Payments/PaymentResultPage')
);

// Extra components
const FoundationsSaasInfo = lazy(() =>
  import('./Extras/FoundationsSaasInfo.jsx')
);
const EnterprisesSaasInfo = lazy(() =>
  import('./Extras/EnterprisesSaasInfo.jsx')
);
const BusinessSaasInfo = lazy(() =>
  import('./Extras/BusinessSaasInfo.jsx')
);
const TermsConditions = lazy(() => import('./Extras/TermsConditions.jsx'));
const AccountingFileTemplates = lazy(() =>
  import('./Extras/AccountingFileTemplates.jsx')
);
const SearchComponent = lazy(() => import('./Extras/Search.jsx'));
const EmailValidationComponent = lazy(() =>
  import('./Extras/EmailValidation')
);

// Others
const RegisterLandingComponent = lazy(() =>
  import('./Business/Users/RegisterLanding.jsx')
);
const SubscriptionOverview = lazy(() =>
  import('./Subscriptions/SubscriptionOverview.jsx')
);
const BenefitInfoComponent = lazy(() =>
  import('./Benefits/BenefitInfo.jsx')
);
const Comparative = lazy(() => import('./Comparative/Comparative.jsx'));

// Demo components
const BusinessLeaderBoardComponent = lazy(() =>
  import('./Demo/LeaderBoards/BusinessLeaderBoard.jsx')
);
const EmployeeLeaderBoardComponent = lazy(() =>
  import('./Demo/LeaderBoards/EmployeeLeaderBoard.jsx')
);
const ExplorarComponent = lazy(() =>
  import('./Demo/Explorar/Explorar.jsx')
);
const BusinessBenefitsComponent = lazy(() =>
  import('./Demo/BusinessBenefits/BusinessBenefits.jsx')
);
const LoginLandingComponent = lazy(() =>
  import('./Demo/Login/LoginLanding.jsx')
);
const UserDataFormComponent = lazy(() =>
  import('./Demo/Login/UserDataForm.jsx')
);
const AyudasComponent = lazy(() => import('./Demo/Ayudas/Ayudas.jsx'));
const MercadoComponent = lazy(() => import('./Demo/Mercado/Mercado.jsx'));
const CreateItemComponent = lazy(() =>
  import('./Demo/Mercado/CreateItem.jsx')
);
const CreateAyudaComponent = lazy(() =>
  import('./Demo/Ayudas/CreateAyuda.jsx')
);
const ProfileComponent = lazy(() => import('./Demo/Profile/Profile.jsx'));
const LandingSuccessComponent = lazy(() =>
  import('./Payments/LandingSuccess.js')
);
const ShoppingCartComponent = lazy(() =>
  import('./ShoppingCart/ShoppingCart.js')
);
const Login2Component = lazy(() => import('./Demo/Login/Login2.jsx'));
const CategoriasComponent = lazy(() =>
  import('./Demo/Login/Categorias.jsx')
);
const CreateItemLoginComponent = lazy(() =>
  import('./Demo/Login/CreateItemLogin.jsx')
);

function mapDispatchToProps(dispatch) {
  return {
    userLogin: () => dispatch(userLogin()),
    setUser: (user) => dispatch(setUser(user)),
    setUserId: (userId) => dispatch(setUserId(userId)),
    setIsFoundation: (isFoundation) =>
      dispatch(setIsFoundation(isFoundation)),
    setIsEnterprise: (isEnterprise) =>
      dispatch(setIsEnterprise(isEnterprise)),
    setToken: (token) => dispatch(setToken(token)),
    setLanguage: (language) => dispatch(setLanguage(language)),
    setExchangeRate: (rate) => dispatch(setExchangeRate(rate)),
    setShowShoppingCart: (show) => dispatch(setShowShoppingCart(show)),
    setIsBusinessAdmin: (isBusinessAdmin) =>
      dispatch(setIsBusinessAdmin(isBusinessAdmin)),
    setBusinessPlan: (plan) => dispatch(setBusinessPlan(plan)),
    wsConnect: (host) => dispatch(wsConnect(host)),
  };
}

const select = (state) => {
  return {
    user: state.auth.user,
    userId: state.auth.userId,
    isFoundation: state.auth.isFoundation,
    isEnterprise: state.auth.isEnterprise,
    token: state.auth.token,
    dashboardTab: state.auth.dashboardTab,
    showShoppingCart: state.auth.showShoppingCart,
    authenticated: state.auth.authenticated,
  };
};

class ConnectedApp extends Component {
  async componentDidMount() {
    const obj = getFromStorage('nibi_app');
    const objConversion = getFromStorage('conversion');

    if (objConversion && objConversion.conversion) {
      const { conversion } = objConversion;
      this.props.setExchangeRate(conversion);
    }

    if (obj && obj.token) {
      const { token } = obj;
      // Verify token
      const prom = await fetch('/signin/account/verify?token=' + token);
      const json = await prom.json();

      if (json.success) {
        this.props.setToken({ token });
        this.updateUserSession(
          json.userId,
          json.isFoundation,
          json.isEnterprise,
          token
        );

        const businessResponse = await fetch(
          `/business/isBusinessAdmin/${json.userId}`
        );
        const businessJson = await businessResponse.json();

        if (businessJson.success) {
          this.props.setIsBusinessAdmin(true);
          this.props.setBusinessPlan({ plan: businessJson.plan });
        }
      }
    }
  }

  updateUserSession = async (
    userId,
    isFoundation,
    isEnterprise,
    token
  ) => {
    this.props.setIsFoundation({ isFoundation });
    this.props.setIsEnterprise({ isEnterprise });

    if (isEnterprise) {
      const dataEnterprise = await fetch('/enterprises/' + userId);
      const res = await dataEnterprise.json();

      this.props.setUser({ user: res });
    } else if (isFoundation) {
      const dataFoundation = await fetch(
        '/foundations/foundation/' + userId
      );
      const res = await dataFoundation.json();

      const dataFoundationInfo = await fetch(
        '/foundations/foundation/info/' + res.foundationId
      );
      const res2 = await dataFoundationInfo.json();

      this.props.setUser({ user: res2 });
    } else {
      const dataUser = await fetch('/users/user/' + userId);
      const res = await dataUser.json();

      this.props.setUser({ user: res });
    }

    this.props.setUserId({ userId });
    this.props.userLogin();
    this.props.wsConnect(`${wsUrl}/${token}`);
  };

  render() {
    const hideNavbar =
      window.location.pathname.includes('/ext/pagos') ||
      window.location.pathname.includes('/landing/');
    let dashboardRoute;

    const {
      showShoppingCart,
      isFoundation,
      isEnterprise,
      userId,
      token,
      dashboardTab,
      setShowShoppingCart,
      user,
    } = this.props;

    if (isEnterprise) {
      dashboardRoute = (
        <Route
          path='/dashboardEnterprise'
          render={(props) => (
            <DashboardEnterprise
              {...props}
              dashboardTab={dashboardTab}
            />
          )}
        />
      );
    } else {
      dashboardRoute = (
        <Route
          path='/dashboardUser'
          render={(props) => (
            <DashboardUser
              {...props}
              dashboardTab={dashboardTab}
            />
          )}
        />
      );
    }

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    return (
      <Router>
        <Helmet>
          <meta charSet='utf-8' />
          <meta
            property='og:url'
            content='https://nibi.com.co'
          />
          <meta
            property='og:title'
            content='Acciones de solidaridad y consumo sostenible | nibi'
          />
          <meta
            property='og:type'
            content='website'
          />
          <meta
            property='og:description'
            content='Encuentra campañas de donación, voluntariados y experiencias solidarias. Gana puntos nibi y consume sostenible con nuestras marcas aliadas.'
          />
          <meta
            property='fb:app_id'
            content='207412983912631'
          />
          <title>
            Acciones de solidaridad y consumo sostenible | nibi
          </title>
          <link
            rel='canonical'
            href='https://nibi.com.co'
          />
          <meta
            name='description'
            content='Encuentra campañas de donación, voluntariados y experiencias solidarias. Gana puntos nibi y consume sostenible con nuestras marcas aliadas.'
          />
        </Helmet>

        <ScrollToTop>
          <div className='App'>
            {!hideNavbar && (
              <OverlayTrigger
                placement='top'
                overlay={<Tooltip>Soporte</Tooltip>}>
                <a
                  id='chatButton'
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://wa.me/573152930296?text=Necesito%20ayuda%20con%20nibi'>
                  <i
                    className='fa fa-whatsapp'
                    style={{ marginTop: '16px' }}
                  />
                </a>
              </OverlayTrigger>
            )}

            {<NavbarMain hideNavbar={hideNavbar} />}

            <div
              className='cartPanel'
              style={
                showShoppingCart
                  ? !isMobile
                    ? { width: '45%' }
                    : { width: '70%' }
                  : { width: '0px' }
              }>
              {showShoppingCart && <ShoppingCart />}
            </div>

            <div
              onClick={() => setShowShoppingCart(false)}
              className='cartOverlay'
              style={
                showShoppingCart
                  ? { display: 'block' }
                  : { display: 'none' }
              }
            />

            {this.props.authenticated &&
              !isEnterprise &&
              !isFoundation &&
              user &&
              !user.emailVerified && (
                <EmailValidationWarningComponent
                  email={user.email}
                  userId={userId}
                  userType={'USER'}
                  isToast={true}
                />
              )}

            <div style={{ minHeight: '75vh' }}>
              <Suspense fallback={<div>Cargando...</div>}>
                <Switch>
                  <Route
                    path={['/explora', '/emergencias', '/salvemosEl2020']}
                    render={(props) => (
                      <Explora
                        {...props}
                        token={token}
                        userId={userId}
                      />
                    )}
                  />
                  <Route
                    path={[
                      '/programas/resultados/:name',
                      '/resultados/:name',
                    ]}
                    component={Results}
                  />
                  <Route
                    path='/programas/:name'
                    component={Program}
                  />
                  <Route
                    path='/voluntariado_digital/:name'
                    component={VirtualProgram}
                  />

                  <Route
                    path='/landing/:id/:amount?/:recurring?'
                    component={FoundationMultipurposeLanding}
                  />
                  <Route
                    path='/fundaciones/id/:name'
                    component={FoundationProfile}
                  />
                  <Route
                    path='/fundaciones/resultadosPlan/:name/'
                    component={FoundationPlanResults}
                  />
                  <Route
                    path='/comercios/id/:name'
                    component={CommerceProfile}
                  />
                  <Route
                    path='/fundaciones2'
                    component={FoundationsList}
                  />
                  <Route
                    path='/fundaciones'
                    component={FoundationsLanding}
                  />
                  <Route
                    path='/comercios'
                    component={EnterprisesList}
                  />
                  <Route
                    path='/beneficios'
                    component={BenefitsList}
                  />
                  <Route
                    path='/sobre-nibi'
                    component={About}
                  />
                  <Route
                    path='/password_reset'
                    component={PasswordResetComponent}
                  />
                  <Route
                    path='/recover_password'
                    component={RecoverPasswordComponent}
                  />
                  <Route
                    path='/blog'
                    exact
                    component={BlogListComponent}
                  />
                  <Route
                    path='/blog/detail/:id'
                    exact
                    component={BlogArticleComponent}
                  />
                  <Route
                    path={[
                      '/pago/resultado/:programName',
                      '/pago/resultado',
                    ]}
                    component={LandingPsePaymentComponent}
                  />
                  <Route
                    path={'/paymentResult/:id'}
                    component={PaymentResultComponent}
                  />
                  <Route
                    path='/login/cemex'
                    exact
                    component={LoginLandingComponent}
                  />
                  <Route
                    path='/login/cemex/2'
                    exact
                    component={Login2Component}
                  />
                  <Route
                    path='/cemex/categorias'
                    exact
                    component={CategoriasComponent}
                  />
                  <Route
                    path='/cemex/categorias/item'
                    exact
                    component={CreateItemLoginComponent}
                  />
                  <Route
                    path='/cemex/form'
                    exact
                    component={UserDataFormComponent}
                  />
                  <Route
                    path='/cemex/ayudas'
                    exact
                    component={AyudasComponent}
                  />
                  <Route
                    path='/cemex/ayudas/crear'
                    exact
                    component={CreateAyudaComponent}
                  />
                  <Route
                    path='/cemex/mercado'
                    exact
                    component={MercadoComponent}
                  />
                  <Route
                    path='/cemex/mercado/crear'
                    exact
                    component={CreateItemComponent}
                  />
                  <Route
                    path='/cemex/perfil'
                    exact
                    component={ProfileComponent}
                  />
                  <Route
                    path='/login'
                    exact
                    component={LoginPageComponent}
                  />
                  <Route
                    path='/pago/recurrente'
                    exact
                    component={RecurringPaymentPseComponent}
                  />
                  <Route
                    path='/pago/suscripcion'
                    exact
                    component={SubscriptionPaymentLanding}
                  />
                  <Route
                    path='/chat'
                    component={Chat}
                  />
                  <Route
                    path='/top/empresas'
                    exact
                    component={BusinessLeaderBoardComponent}
                  />
                  <Route
                    path='/top/empleados'
                    exact
                    component={EmployeeLeaderBoardComponent}
                  />
                  <Route
                    path='/aportes/explora'
                    exact
                    component={ExplorarComponent}
                  />
                  <Route
                    path='/empresas/beneficios'
                    exact
                    component={BusinessBenefitsComponent}
                  />
                  <Route
                    path='/pago-exitoso'
                    component={LandingSuccessComponent}
                  />
                  <Route
                    path='/carrito'
                    component={ShoppingCartComponent}
                  />
                  <Route
                    path='/registro/usuario/:userId/:code'
                    component={RegisterLandingComponent}
                  />
                  <Route
                    path='/info/organizaciones'
                    component={FoundationsSaasInfo}
                  />
                  <Route
                    path='/comparative/:FoundationId/:value'
                    component={Comparative}
                  />
                  <Route
                    path='/info/comercios'
                    component={EnterprisesSaasInfo}
                  />
                  <Route
                    path='/info/empresas'
                    component={BusinessSaasInfo}
                  />
                  <Route
                    path='/politicas-nibi/privacidad'
                    component={PrivacyPolicy}
                  />
                  <Route
                    path='/politicas-nibi'
                    component={TermsConditions}
                  />
                  <Route
                    path='/financiero/formatos'
                    component={AccountingFileTemplates}
                  />
                  <Route
                    path='/suscripciones'
                    component={SubscriptionOverview}
                  />
                  <Route
                    path='/buscar'
                    component={SearchComponent}
                  />
                  <Route
                    path='/productos/:id'
                    component={BenefitInfoComponent}
                  />
                  <Route
                    path='/validacion'
                    render={(props) => (
                      <EmailValidationComponent
                        {...props}
                        updateUserSession={this.updateUserSession}
                      />
                    )}
                  />
                  <Route
                    path='/mediamaratonvirtual'
                    component={RaceLandingComponent}
                  />
                  <Route
                    path='/palapachafest'
                    component={FestivalComponent}
                  />
                  <Route
                    path='/nibify'
                    component={Nibify}
                  />
                  <Route
                    path='/ext/pagos/fp'
                    component={PaymentExtensionFutbolPazifico}
                  />
                  <Route
                    path='/ext/pagos/ri'
                    component={PaymentExtensionRehabilitacionIntegral}
                  />
                  <Route
                    path='/ext/pagos/mqtl/:orderId'
                    component={PaymentExtensionTresLetras}
                  />
                  <Route
                    path='/ext/parceros/mqtl/:userId'
                    component={ParcerosMQTL}
                  />
                  <Route
                    path='/carreravirtual'
                    exact
                    render={(props) => (
                      <RaceLandingComponent2 {...props} />
                    )}
                  />
                  <Route
                    path='/carreradelossuenos'
                    exact
                    render={(props) => (
                      <RaceLanding2021Component {...props} />
                    )}
                  />
                  <Route
                    path='/organizaciones/usuarios'
                    component={FoundationUserRegister}
                  />
                  <Route
                    path='/dashboardFoundation'
                    render={(props) => (
                      <DashboardFoundation
                        {...props}
                        dashboardTab={dashboardTab}
                      />
                    )}
                  />
                  {dashboardRoute}
                  <Route
                    path='/impact-info/:id'
                    component={ImpactProfile}
                  />
                  <Route
                    path={['/:session?', '/home']}
                    exact
                    render={(props) => (
                      <Homepage
                        {...props}
                        token={token}
                        userId={userId}
                      />
                    )}
                  />
                </Switch>
              </Suspense>
            </div>
            {!hideNavbar && <Footer />}

            <UpgradeBillingPlanModalComponent />
          </div>
        </ScrollToTop>
        {!hideNavbar && <PushNotifications />}
      </Router>
    );
  }
}

const App = connect(select, mapDispatchToProps)(ConnectedApp);

export default App;
