import React, { useState, useEffect } from 'react';
import { Alert, Col, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import AddressInfoIcon from '../Extras/AddressInfoIcon';
import AppSpinner from '../Extras/AppSpinner';
import RedeemNibisReminder from '../Extras/RedeemNibisReminder';
import { formatMoney } from '../utils/formatMoney';
import { wompiBaseUrl, wompiPublicKey } from '../utils/paymentKeys';
import './styles/nequiPayment.scss';

const select = state => {
  return {
    user: state.auth.user,
    token: state.auth.token
  };
};

function ConnectedNequiPayment(props) {

  const [loading, setLoading] = useState(true);
  const [creatingTransaction, setCreatingTransaction] = useState(false);
  const [validated, setValidated] = useState(false);
  const [docType, setDocType] = useState(props.user && props.user.identificationType ? props.user.identificationType : 'CC');
  const [docNumber, setDocNumber] = useState(props.user && props.user.identification ? props.user.identification : '');
  const [referredBy, setReferredBy] = useState(props.user && props.user.referredBy ? props.user.referredBy : '');
  const [phone, setPhone] = useState(props.user && props.user.cellPhone ? props.user.cellPhone : '');
  const [contactMethod, setContactMethod] = useState('EMAIL');
  const [acceptanceToken, setAcceptanceToken] = useState(null);
  const [permalink, setPermalink] = useState(null);
  const [transactionStatus, setTransactionStatus] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [userCity, setUserCity] = useState(props.user && props.user.donationCity ? props.user.donationCity : '');
  const [userAddress, setUserAddress] = useState(props.user && props.user.donationAddress ? props.user.donationAddress : '');
  const showContactMethod = props.user && !props.user.contactMethod;
  let interval;

  useEffect(() => {
    getWompiData();
  }, []);

  useEffect(() => {
    if (acceptanceToken && permalink) setLoading(false);
  }, [acceptanceToken, permalink]);

  useEffect(() => {
    if (transactionStatus) {
      setCreatingTransaction(false);

      if (transactionStatus === 'SUCCESS' && props.onTransactionSuccess) props.onTransactionSuccess();

    }
  }, [transactionStatus]);

  useEffect(() => {
    if (transactionId) interval = setInterval(checkTransaction, 2000);
  }, [transactionId]);

  const getWompiData = async () => {
    const acceptanceTokenResp = await fetch(`${wompiBaseUrl}/merchants/${wompiPublicKey}`);
    const acceptanceTokenData = await acceptanceTokenResp.json();

    const { data } = acceptanceTokenData;

    if (data) {
      const presignedData = data.presigned_acceptance;

      setAcceptanceToken(presignedData.acceptance_token);
      setPermalink(presignedData.permalink);
    }
  };

  const checkTransaction = async () => {

    if (!transactionStatus) {
      try {

        const transactionResponse = await fetch(`${wompiBaseUrl}/transactions/${transactionId}`);
        const transaction = await transactionResponse.json();

        const { data } = transaction;

        if (data && data.status !== 'PENDING') {
          clearInterval(interval);
          setTransactionStatus(data.status === 'APPROVED' ? 'SUCCESS' : 'ERROR');
        }

      } catch (err) {
        clearInterval(interval);
        setTransactionStatus('ERROR');
      }
    }

  };

  const handleSubmit = async event => {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);

    const form = event.currentTarget;

    if (form.checkValidity()) {

      if (props.gaTrackEventPayment) props.gaTrackEventPayment();

      setCreatingTransaction(true);
      const transactionId = await createTransaction();

      if (transactionId) setTransactionId(transactionId);
    }
  };

  const createTransaction = async () => {

    const {
      paymentType,
      amount,
      user,
      amountToPay,
      hasDiscountCode
    } = props;

    const reference = await createPayment();

    const body = {
      acceptance_token: acceptanceToken,
      currency: 'COP',
      customer_email: user.email,
      payment_method: {
        type: 'NEQUI',
        phone_number: `${phone}`
      },
      reference
    };

    if (paymentType === 'DONATION') {

      body.amount_in_cents = parseInt(`${!hasDiscountCode ? amount : amountToPay}00`);

    } else if (paymentType === 'CART') {

      body.amount_in_cents = parseInt(`${amount}00`);

    }

    const transactionResponse = await fetch(`${wompiBaseUrl}/transactions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${wompiPublicKey}`
      },
      body: JSON.stringify(body)
    });

    const transactionData = await transactionResponse.json();

    if (!transactionData.data) {
      setTransactionStatus('ERROR');
    } else {
      return transactionData.data.id;
    }

  };

  const createPayment = async () => {

    const {
      user,
      paymentType,
      token,
      amount,
      amountToPay,
      programId,
      foundationId,
      campaign,
      hasDiscountCode,
      discountCode,
      deliveryInfo,
      items,
      products,
      palapacha,
      detail
    } = props;

    if (paymentType === 'DONATION') {

      const paymentResponse = await fetch('/payments/payment_gateway/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
        body: JSON.stringify({
          programId,
          foundationId,
          amount,
          paymentMethod: 'NEQUI',
          userIdentification: docNumber,
          userIdentificationType: docType,
          cellPhone: phone,
          contactMethod: showContactMethod ? contactMethod : '',
          campaign,
          referredBy,
          discountCode,
          hasDiscountCode,
          amountPayed: amountToPay,
          userCity,
          userAddress,
          deliveryInfo: deliveryInfo && deliveryInfo.street + " " + (deliveryInfo.extra ? deliveryInfo.extra : '') + " " +
          (deliveryInfo.area ? deliveryInfo.area : '') + ", " + deliveryInfo.city + ", " + deliveryInfo.state + ", " +
          deliveryInfo.country + ", " + deliveryInfo.phone,
          detail: palapacha ? 'palapacha' : detail, 
        })
      });

      const paymentData = await paymentResponse.json();
      const { reference } = paymentData;

      return reference;

    } else if (paymentType === 'CART') {

      const body = {
        userId: user._id,
        items,
        products,
        totalAmount: amount,
        paymentMethod: 'NEQUI',
        userIdentification: docNumber,
        userIdentificationType: docType,
        userCity,
        userAddress,
        cellPhone: phone,
        contactMethod: showContactMethod ? contactMethod : '',
        campaign
      };

      if (products.length > 0 && deliveryInfo) {
        body.deliveryInfo =
          deliveryInfo.street + ' ' +
          (deliveryInfo.extra ? deliveryInfo.extra : '') + ' ' +
          (deliveryInfo.area ? deliveryInfo.area : '') + ', ' +
          deliveryInfo.city + ', ' +
          deliveryInfo.state + ', ' +
          deliveryInfo.country + ', ' +
          deliveryInfo.phone;
      }

      const paymentResponse = await fetch('/shoppingCartItems/createCartPayment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
        body: JSON.stringify(body)
      });

      const paymentData = await paymentResponse.json();
      const { reference } = paymentData;

      return reference;
    }
  };

  if (loading || creatingTransaction) {
    return (
      <div className="nequi-payment-component">
        <AppSpinner />
        {
          creatingTransaction &&
          <h4 className="transaction-message">
            Estamos procesando la transacción.
            Recibirás una notificación de Nequi en tu celular para aprobar o rechazar la transacción.
          </h4>
        }
      </div>
    );
  }

  const { paymentType, amount } = props;

  if (transactionStatus) {
    return (
      <div className="nequi-payment-component">
        <h3 className="transaction-message transaction-status-message">
          {
            transactionStatus === 'SUCCESS' ?
            `Recibimos tu ${paymentType === 'DONATION' ? 'donación' : 'pago'} por un valor de $${formatMoney(amount)} COP. Gracias por usar Nibi, la transacción fue exitosa.` :
            'Error al procesar la transacción. Inténtalo nuevamente o comunícate con nosotros.'
          }
        </h3>
      </div>
    );
  }

  return (
    <div className="nequi-payment-component">
      <Alert variant="info">
        Recuerda que tienes que contar con la app Nequi instalada en tu celular antes de completar este pago.
      </Alert>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        {
          props.paymentType === 'DONATION' &&
          <Form.Row>
            <Form.Group as={Col} lg={6} xs={12}>
              <Form.Label>¿Tienes código de referido?</Form.Label>
              <Form.Control
                type="text"
                placeholder="Código referido"
                name="referredBy"
                value={props.user.referredBy ? props.user.referredBy : referredBy}
                onChange={event => setReferredBy(event.target.value)}
                disabled={props.user.referredBy ? true : false}
              />
            </Form.Group>
          </Form.Row>
        }

        <Form.Row>
          <Form.Group as={Col} lg={4} xs={12}>
            <Form.Label>Tipo de documento</Form.Label>
            <Form.Control
              as="select"
              value={docType}
              onChange={event => setDocType(event.target.value)}
              required
            >
              <option value="CC">CC</option>
              <option value="NIT">NIT</option>
              <option value="CE">CE</option>
              <option value="Otro">Otro</option>
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} lg={8} xs={12}>
            <Form.Label>Número de documento</Form.Label>
            <Form.Control
              type="text"
              value={docNumber}
              onChange={event => setDocNumber(event.target.value)}
              placeholder="Documento"
              required
            />
          </Form.Group>
          <Form.Group as={Col} md={6} xs={12}>
            <Form.Label>
              Ciudad <AddressInfoIcon />
            </Form.Label>
            <Form.Control
              type="text"
              value={userCity}
              onChange={event => setUserCity(event.target.value)}
              placeholder="Ciudad"
              required
            />
          </Form.Group>
          <Form.Group as={Col} md={6} xs={12}>
            <Form.Label>
              Dirección <AddressInfoIcon />
            </Form.Label>
            <Form.Control
              type="text"
              value={userAddress}
              onChange={event => setUserAddress(event.target.value)}
              placeholder="Dirección"
              required
            />
          </Form.Group>
          <Form.Group as={Col} lg={showContactMethod ? 4 : 12} xs={12}>
            <Form.Label>Número de celular</Form.Label>
            <Form.Control
              type="phone"
              value={phone}
              onChange={event => setPhone(event.target.value)}
              placeholder="Número de celular"
              required
            />
          </Form.Group>
          {
            showContactMethod &&
            <Form.Group as={Col} lg={8} xs={12}>
              <Form.Label>¿Por dónde te gustaría recibir la información de tu impacto?</Form.Label>
              <Form.Control
                as="select"
                value={contactMethod}
                onChange={event => setContactMethod(event.target.value)}
              >
                <option value="WHATS_APP">WhatsApp</option>
                <option value="CELL_PHONE">Llamada</option>
                <option value="EMAIL">Correo</option>
              </Form.Control>
            </Form.Group>
          }
          <Col xs={12}>
            <div className="custom-control custom-checkbox">
              <input type="checkbox" className="custom-control-input" id="permalinkCheckBox1" required />
              <label className="custom-control-label" htmlFor="permalinkCheckBox1">
                Acepto haber leído los <a href={permalink} target="_blank" rel="noopener noreferrer">términos y condiciones y la política de privacidad</a> para hacer este pago.
              </label>
            </div>
          </Col>
          {
            paymentType === 'DONATION' &&
            <Col xs={12}>
              <RedeemNibisReminder />
            </Col>
          }

          <button className="nibi-btn" type="submit">
            Continuar
          </button>

        </Form.Row>
      </Form>
    </div>
  );

}

const NequiPayment = connect(select)(ConnectedNequiPayment);

export default NequiPayment;
