import React, { Component } from 'react';
import { connect } from 'react-redux';
import AppSpinner from '../Extras/AppSpinner';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import AddressesComponent from '../ShoppingCart/AddressesComponent';
import { redirectBaseUrl } from '../utils/paymentKeys';
import AddressInfoIcon from '../Extras/AddressInfoIcon';

const select = state => {
  return {
    user: state.auth.user,
    userId: state.auth.userId,
    token: state.auth.token,
    campaign: state.campaign.campaign
  };
};

class ConnectedCartWalletPaymentComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      processingTransaction: false,
      status: '',
      formValidated: false,
      userIdentification: props.user && props.user.identification ? props.user.identification : '',
      userIdentificationType: props.user && props.user.identificationType ? props.user.identificationType : 'CC',
      cellPhone: props.user && props.user.cellPhone ? props.user.cellPhone : '',
      contactMethod: 'EMAIL',
      deliveryInfo: null,
      deliveryInfoArray: [],
      showModal: false,
      userCity: props.user && props.user.donationCity ? props.user.donationCity : '',
      userAddress: props.user && props.user.donationAddress ? props.user.donationAddress : ''
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleShowModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  async getAddresses() {
    const addresses = await fetch('/addresses/user/' + this.props.user._id);
    const deliveryInfoArray = await addresses.json();
    this.setState({ deliveryInfoArray });
  }

  async deleteAddress(id) {
    const prom = await fetch('/addresses/delete', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id
      })
    });

    const json = await prom.json();

    if (json.success) this.getAddresses();
  }

  async componentDidMount() {
    await this.getAddresses();
    this.setState({ isLoading: false });
  }

  async createPayment() {

    const {
      userIdentification,
      userIdentificationType,
      deliveryInfo,
      cellPhone,
      contactMethod,
      userCity,
      userAddress
    } = this.state;
    const {
      token,
      user,
      items,
      products,
      totalAmount,
      campaign
    } = this.props;
    const showContactMethod = this.props.user && !this.props.user.contactMethod;

    const body = {
      userId: user._id,
      items,
      products,
      totalAmount,
      paymentMethod: 'WALLET_PAYMENT',
      userIdentification,
      userIdentificationType,
      userCity,
      userAddress,
      cellPhone,
      contactMethod: showContactMethod ? contactMethod : '',
      campaign
    };

    if (products.length > 0) {
      body.deliveryInfo =
        deliveryInfo.street + ' ' +
        (deliveryInfo.extra ? deliveryInfo.extra : '') + ' ' +
        (deliveryInfo.area ? deliveryInfo.area : '') + ', ' +
        deliveryInfo.city + ', ' +
        deliveryInfo.state + ', ' +
        deliveryInfo.country + ', ' +
        deliveryInfo.phone;
    }

    const paymentResponse = await fetch('/shoppingCartItems/createCartPayment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(body)
    });

    const paymentData = await paymentResponse.json();
    const { reference } = paymentData;

    return reference;
  }

  async handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    this.setState({ formValidated: true });

    if (form.checkValidity()) {
      this.setState({ processingTransaction: true });

      const { token, userId, totalAmount } = this.props;
      const shoppingCartPaymentId = await this.createPayment();
      const paymentResponse = await fetch('/payments/wallet/cart', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token,
          userId,
          shoppingCartPaymentId
        })
      });
      const data = await paymentResponse.json();

      if (data.success) window.location = `${redirectBaseUrl}/pago-exitoso/?amount=${totalAmount}&t=0`;
      else this.setState({ processingTransaction: false, status: 'ERROR' });

    }
  }

  render() {

    const { user, totalAmount, products } = this.props;
    const walletAmount = user && user.walletAmount ? user.walletAmount : 0;
    const {
      isLoading,
      processingTransaction,
      status,
      formValidated,
      userIdentification,
      userIdentificationType,
      cellPhone,
      contactMethod,
      showModal,
      deliveryInfoArray,
      userCity,
      userAddress
    } = this.state;
    const showContactMethod = this.props.user && !this.props.user.contactMethod;

    if (isLoading) return (
      <div className="pse-transaction-spinner">
        <AppSpinner />
      </div>
    );

    if (processingTransaction) return (
      <div className="pse-transaction-spinner">
        <AppSpinner />
        <h4 className="message">Procesando transacción, por favor espera</h4>
      </div>
    );

    if (status) {
      return (
        <div className="landing-pse-payment-component">
          <Container>
            <Row>
              <Col>
                <h1 className="title">
                  {
                    status === 'APPROVED' ? 'Transacción aprobada' : 'Error en la transacción'
                  }
                </h1>
                <h4 className="message">
                  {
                    status === 'APPROVED' ? 'Transacción exitosa' : 'Error al procesar la transacción. Inténtalo nuevamente.'
                  }
                </h4>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }

    return (
      <div className="pse-payment-component">
        <Form noValidate validated={formValidated} onSubmit={this.handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} lg={6}>
              <Form.Label>Monto disponible</Form.Label>
              <Form.Control
                type="text"
                disabled={true}
                value={walletAmount}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6}>
              <Form.Label>Tipo de documento</Form.Label>
              <Form.Control
                name="userIdentificationType"
                as="select"
                value={userIdentificationType}
                onChange={this.handleInputChange}
              >
                <option value="CC">CC</option>
                <option value="NIT">NIT</option>
                <option value="CE">CE</option>
                <option value="Otro">Otro</option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} lg={6}>
              <Form.Label>Número de documento</Form.Label>
              <Form.Control
                type="text"
                placeholder="Documento"
                name="userIdentification"
                value={userIdentification}
                onChange={this.handleInputChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Ingresa tu documento.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} lg={6}>
              <Form.Label>Número de teléfono</Form.Label>
              <Form.Control
                type="text"
                placeholder="Teléfono"
                name="cellPhone"
                value={cellPhone}
                onChange={this.handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} xs={12}>
              <Form.Label>
                Ciudad <AddressInfoIcon />
              </Form.Label>
              <Form.Control
                name="userCity"
                type="text"
                placeholder="Ciudad"
                value={userCity}
                onChange={this.handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} xs={12}>
              <Form.Label>
                Dirección <AddressInfoIcon />
              </Form.Label>
              <Form.Control
                name="userAddress"
                type="text"
                placeholder="Dirección"
                value={userAddress}
                onChange={this.handleInputChange}
                required
              />
            </Form.Group>

            {
              showContactMethod &&
              <>
                <Form.Group as={Col} lg={6}>
                  <Form.Label>¿Por dónde te gustaría recibir la información de tu impacto?</Form.Label>
                  <Form.Control
                    as="select"
                    name="contactMethod"
                    value={contactMethod}
                    onChange={this.handleInputChange}
                  >
                    <option value="WHATS_APP">WhatsApp</option>
                    <option value="CELL_PHONE">Llamada</option>
                    <option value="EMAIL">Correo</option>
                  </Form.Control>
                </Form.Group>
                <Col xs={6}></Col>
              </>
            }

            {
              products.length > 0 &&
              <Col xs={12}>
                <Form.Label><small>Elegir dirección de envío</small></Form.Label>
                <Form.Row>
                  {
                    deliveryInfoArray.map((address, i) => {
                      return (
                        <Col xs={2} key={i} className="addressOption">
                          <fieldset>
                            <i
                              onClick={() => this.deleteAddress(address._id)}
                              className="fa fa-trash deleteAddress"
                              style={{ color: 'firebrick', cursor: 'pointer' }}
                            />
                            <div><small><strong>{address.short}</strong></small></div>
                            <div className="addressText">{address.country}</div>
                            <div className="addressText">{address.state}</div>
                            <div className="addressText">{address.city}</div>
                            <div className="addressText">{address.area}</div>
                            <div className="addressText">{address.street}</div>
                            <div className="addressText">{address.extra}</div>
                            <div className="addressText">{address.phone}</div>
                            <Form.Check type="radio" aria-label="choose address radio" name="formAddressRadio" className="center" onChange={() => this.setState({ deliveryInfo: address })} />
                          </fieldset>
                        </Col>
                      )
                    })
                  }
                  <Col xs={2}>
                    <Button variant="outline-primary" onClick={this.handleShowModal} style={{ height: "100%", width: "100%" }}>
                      Agregar dirección de envío
                    </Button>
                  </Col>
                </Form.Row>
              </Col>
            }

            {/* <Col xs={12}>
              <Button variant="outline-primary" onClick={this.handleShowModal}>
                Agregar dirección de envío
              </Button>
            </Col> */}

            {
              walletAmount >= parseInt(totalAmount) ?
                <Button variant="primary" className="btn-modal" type="submit">
                  Continuar
                </Button> :
                <p style={{ textAlign: 'center' }}>
                  Fondos insuficientes. <a href="/dashboardUser/billetera">Recargar billetera</a>
                </p>
            }
          </Form.Row>
        </Form>
        <Modal show={showModal} onHide={this.handleCloseModal} size="lg" centered>
          <Modal.Header closeButton>
            <Modal.Title>Agrega una dirección</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddressesComponent userId={this.props.user._id} refreshAddresses={() => this.getAddresses()} closeModal={this.handleCloseModal} />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const CartWalletPaymentComponent = connect(select)(ConnectedCartWalletPaymentComponent);

export default CartWalletPaymentComponent;
