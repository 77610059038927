export const daneCodes = [
  {
    "id": 0,
    "zip_code": 110111,
    "dane_code": 11001000,
    "name_depto": "BOGOTÁ D.C.",
    "name_city": "BOGOTÁ"
  },
  {
    "id": 1,
    "zip_code": 50001,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 2,
    "zip_code": 50002,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 3,
    "zip_code": 50003,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 4,
    "zip_code": 50004,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 5,
    "zip_code": 50005,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 6,
    "zip_code": 50006,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 7,
    "zip_code": 50007,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 8,
    "zip_code": 50010,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 9,
    "zip_code": 50011,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 10,
    "zip_code": 50012,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 11,
    "zip_code": 50013,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 12,
    "zip_code": 50014,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 13,
    "zip_code": 50015,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 14,
    "zip_code": 50016,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 15,
    "zip_code": 50017,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 16,
    "zip_code": 50018,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 17,
    "zip_code": 50020,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 18,
    "zip_code": 50021,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 19,
    "zip_code": 50022,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 20,
    "zip_code": 50023,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 21,
    "zip_code": 50024,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 22,
    "zip_code": 50025,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 23,
    "zip_code": 50026,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 24,
    "zip_code": 50027,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 25,
    "zip_code": 50028,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 26,
    "zip_code": 50029,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 27,
    "zip_code": 50030,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 28,
    "zip_code": 50031,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 29,
    "zip_code": 50032,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 30,
    "zip_code": 50033,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 31,
    "zip_code": 50034,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 32,
    "zip_code": 50035,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 33,
    "zip_code": 50036,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 34,
    "zip_code": 50037,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 35,
    "zip_code": 50040,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 36,
    "zip_code": 50041,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 37,
    "zip_code": 50042,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 38,
    "zip_code": 50043,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 39,
    "zip_code": 50044,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 40,
    "zip_code": 50047,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 41,
    "zip_code": 50048,
    "dane_code": 5001000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MEDELLIN"
  },
  {
    "id": 42,
    "zip_code": 51010,
    "dane_code": 5664000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SAN PEDRO DE LOS MILAGROS"
  },
  {
    "id": 43,
    "zip_code": 51017,
    "dane_code": 5664000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SAN PEDRO DE LOS MILAGROS"
  },
  {
    "id": 44,
    "zip_code": 51020,
    "dane_code": 5079000,
    "name_depto": "ANTIOQUIA",
    "name_city": "BARBOSA"
  },
  {
    "id": 45,
    "zip_code": 51027,
    "dane_code": 5079000,
    "name_depto": "ANTIOQUIA",
    "name_city": "BARBOSA"
  },
  {
    "id": 46,
    "zip_code": 51028,
    "dane_code": 5079000,
    "name_depto": "ANTIOQUIA",
    "name_city": "BARBOSA"
  },
  {
    "id": 47,
    "zip_code": 51030,
    "dane_code": 5308000,
    "name_depto": "ANTIOQUIA",
    "name_city": "GIRARDOTA"
  },
  {
    "id": 48,
    "zip_code": 51037,
    "dane_code": 5308000,
    "name_depto": "ANTIOQUIA",
    "name_city": "GIRARDOTA"
  },
  {
    "id": 49,
    "zip_code": 51038,
    "dane_code": 5308000,
    "name_depto": "ANTIOQUIA",
    "name_city": "GIRARDOTA"
  },
  {
    "id": 50,
    "zip_code": 51040,
    "dane_code": 5212000,
    "name_depto": "ANTIOQUIA",
    "name_city": "COPACABANA"
  },
  {
    "id": 51,
    "zip_code": 51047,
    "dane_code": 5212000,
    "name_depto": "ANTIOQUIA",
    "name_city": "COPACABANA"
  },
  {
    "id": 52,
    "zip_code": 51048,
    "dane_code": 5212000,
    "name_depto": "ANTIOQUIA",
    "name_city": "COPACABANA"
  },
  {
    "id": 53,
    "zip_code": 51050,
    "dane_code": 5088000,
    "name_depto": "ANTIOQUIA",
    "name_city": "BELLO"
  },
  {
    "id": 54,
    "zip_code": 51051,
    "dane_code": 5088000,
    "name_depto": "ANTIOQUIA",
    "name_city": "BELLO"
  },
  {
    "id": 55,
    "zip_code": 51052,
    "dane_code": 5088000,
    "name_depto": "ANTIOQUIA",
    "name_city": "BELLO"
  },
  {
    "id": 56,
    "zip_code": 51053,
    "dane_code": 5088000,
    "name_depto": "ANTIOQUIA",
    "name_city": "BELLO"
  },
  {
    "id": 57,
    "zip_code": 51054,
    "dane_code": 5088000,
    "name_depto": "ANTIOQUIA",
    "name_city": "BELLO"
  },
  {
    "id": 58,
    "zip_code": 51057,
    "dane_code": 5088000,
    "name_depto": "ANTIOQUIA",
    "name_city": "BELLO"
  },
  {
    "id": 59,
    "zip_code": 51058,
    "dane_code": 5088000,
    "name_depto": "ANTIOQUIA",
    "name_city": "BELLO"
  },
  {
    "id": 60,
    "zip_code": 51059,
    "dane_code": 5088000,
    "name_depto": "ANTIOQUIA",
    "name_city": "BELLO"
  },
  {
    "id": 61,
    "zip_code": 51070,
    "dane_code": 5656000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SAN JERONIMO"
  },
  {
    "id": 62,
    "zip_code": 51077,
    "dane_code": 5656000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SAN JERONIMO"
  },
  {
    "id": 63,
    "zip_code": 51410,
    "dane_code": 5658000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SAN JOSE DE LA MONTA√ëA"
  },
  {
    "id": 64,
    "zip_code": 51417,
    "dane_code": 5658000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SAN JOSE DE LA MONTA√ëA"
  },
  {
    "id": 65,
    "zip_code": 51420,
    "dane_code": 5086000,
    "name_depto": "ANTIOQUIA",
    "name_city": "BELMIRA"
  },
  {
    "id": 66,
    "zip_code": 51427,
    "dane_code": 5086000,
    "name_depto": "ANTIOQUIA",
    "name_city": "BELMIRA"
  },
  {
    "id": 67,
    "zip_code": 51430,
    "dane_code": 5264000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ENTRERRIOS"
  },
  {
    "id": 68,
    "zip_code": 51437,
    "dane_code": 5264000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ENTRERRIOS"
  },
  {
    "id": 69,
    "zip_code": 51440,
    "dane_code": 5761000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SOPETRAN"
  },
  {
    "id": 70,
    "zip_code": 51447,
    "dane_code": 5761000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SOPETRAN"
  },
  {
    "id": 71,
    "zip_code": 51448,
    "dane_code": 5761000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SOPETRAN"
  },
  {
    "id": 72,
    "zip_code": 51450,
    "dane_code": 5501000,
    "name_depto": "ANTIOQUIA",
    "name_city": "OLAYA"
  },
  {
    "id": 73,
    "zip_code": 51457,
    "dane_code": 5501000,
    "name_depto": "ANTIOQUIA",
    "name_city": "OLAYA"
  },
  {
    "id": 74,
    "zip_code": 51460,
    "dane_code": 5411000,
    "name_depto": "ANTIOQUIA",
    "name_city": "LIBORINA"
  },
  {
    "id": 75,
    "zip_code": 51467,
    "dane_code": 5411000,
    "name_depto": "ANTIOQUIA",
    "name_city": "LIBORINA"
  },
  {
    "id": 76,
    "zip_code": 51468,
    "dane_code": 5411000,
    "name_depto": "ANTIOQUIA",
    "name_city": "LIBORINA"
  },
  {
    "id": 77,
    "zip_code": 51810,
    "dane_code": 5038000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ANGOSTURA"
  },
  {
    "id": 78,
    "zip_code": 51817,
    "dane_code": 5038000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ANGOSTURA"
  },
  {
    "id": 79,
    "zip_code": 51818,
    "dane_code": 5038000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ANGOSTURA"
  },
  {
    "id": 80,
    "zip_code": 51820,
    "dane_code": 5315000,
    "name_depto": "ANTIOQUIA",
    "name_city": "GUADALUPE"
  },
  {
    "id": 81,
    "zip_code": 51827,
    "dane_code": 5315000,
    "name_depto": "ANTIOQUIA",
    "name_city": "GUADALUPE"
  },
  {
    "id": 82,
    "zip_code": 51830,
    "dane_code": 5310000,
    "name_depto": "ANTIOQUIA",
    "name_city": "GOMEZ PLATA"
  },
  {
    "id": 83,
    "zip_code": 51837,
    "dane_code": 5310000,
    "name_depto": "ANTIOQUIA",
    "name_city": "GOMEZ PLATA"
  },
  {
    "id": 84,
    "zip_code": 51838,
    "dane_code": 5310000,
    "name_depto": "ANTIOQUIA",
    "name_city": "GOMEZ PLATA"
  },
  {
    "id": 85,
    "zip_code": 51840,
    "dane_code": 5150000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CAROLINA"
  },
  {
    "id": 86,
    "zip_code": 51847,
    "dane_code": 5150000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CAROLINA"
  },
  {
    "id": 87,
    "zip_code": 51850,
    "dane_code": 5237000,
    "name_depto": "ANTIOQUIA",
    "name_city": "DON MATIAS"
  },
  {
    "id": 88,
    "zip_code": 51857,
    "dane_code": 5237000,
    "name_depto": "ANTIOQUIA",
    "name_city": "DON MATIAS"
  },
  {
    "id": 89,
    "zip_code": 51858,
    "dane_code": 5237000,
    "name_depto": "ANTIOQUIA",
    "name_city": "DON MATIAS"
  },
  {
    "id": 90,
    "zip_code": 51860,
    "dane_code": 5686000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SANTA ROSA DE OSOS"
  },
  {
    "id": 91,
    "zip_code": 51867,
    "dane_code": 5686000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SANTA ROSA DE OSOS"
  },
  {
    "id": 92,
    "zip_code": 51868,
    "dane_code": 5686000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SANTA ROSA DE OSOS"
  },
  {
    "id": 93,
    "zip_code": 52010,
    "dane_code": 5854000,
    "name_depto": "ANTIOQUIA",
    "name_city": "VALDIVIA"
  },
  {
    "id": 94,
    "zip_code": 52017,
    "dane_code": 5854000,
    "name_depto": "ANTIOQUIA",
    "name_city": "VALDIVIA"
  },
  {
    "id": 95,
    "zip_code": 52018,
    "dane_code": 5854000,
    "name_depto": "ANTIOQUIA",
    "name_city": "VALDIVIA"
  },
  {
    "id": 96,
    "zip_code": 52020,
    "dane_code": 5134000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CAMPAMENTO"
  },
  {
    "id": 97,
    "zip_code": 52027,
    "dane_code": 5134000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CAMPAMENTO"
  },
  {
    "id": 98,
    "zip_code": 52030,
    "dane_code": 5887000,
    "name_depto": "ANTIOQUIA",
    "name_city": "YARUMAL"
  },
  {
    "id": 99,
    "zip_code": 52037,
    "dane_code": 5887000,
    "name_depto": "ANTIOQUIA",
    "name_city": "YARUMAL"
  },
  {
    "id": 100,
    "zip_code": 52038,
    "dane_code": 5887000,
    "name_depto": "ANTIOQUIA",
    "name_city": "YARUMAL"
  },
  {
    "id": 101,
    "zip_code": 52040,
    "dane_code": 5647000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SAN ANDRES DE CUERQUIA"
  },
  {
    "id": 102,
    "zip_code": 52047,
    "dane_code": 5647000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SAN ANDRES DE CUERQUIA"
  },
  {
    "id": 103,
    "zip_code": 52050,
    "dane_code": 5819000,
    "name_depto": "ANTIOQUIA",
    "name_city": "TOLEDO"
  },
  {
    "id": 104,
    "zip_code": 52057,
    "dane_code": 5819000,
    "name_depto": "ANTIOQUIA",
    "name_city": "TOLEDO"
  },
  {
    "id": 105,
    "zip_code": 52060,
    "dane_code": 5107000,
    "name_depto": "ANTIOQUIA",
    "name_city": "BRICE√ëO"
  },
  {
    "id": 106,
    "zip_code": 52067,
    "dane_code": 5107000,
    "name_depto": "ANTIOQUIA",
    "name_city": "BRICE√ëO"
  },
  {
    "id": 107,
    "zip_code": 52068,
    "dane_code": 5107000,
    "name_depto": "ANTIOQUIA",
    "name_city": "BRICE√ëO"
  },
  {
    "id": 108,
    "zip_code": 52070,
    "dane_code": 5361000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ITUANGO"
  },
  {
    "id": 109,
    "zip_code": 52077,
    "dane_code": 5361000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ITUANGO"
  },
  {
    "id": 110,
    "zip_code": 52078,
    "dane_code": 5361000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ITUANGO"
  },
  {
    "id": 111,
    "zip_code": 52079,
    "dane_code": 5361000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ITUANGO"
  },
  {
    "id": 112,
    "zip_code": 52410,
    "dane_code": 5154000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CAUCASIA"
  },
  {
    "id": 113,
    "zip_code": 52417,
    "dane_code": 5154000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CAUCASIA"
  },
  {
    "id": 114,
    "zip_code": 52418,
    "dane_code": 5154000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CAUCASIA"
  },
  {
    "id": 115,
    "zip_code": 52420,
    "dane_code": 5495000,
    "name_depto": "ANTIOQUIA",
    "name_city": "NECHI"
  },
  {
    "id": 116,
    "zip_code": 52427,
    "dane_code": 5495000,
    "name_depto": "ANTIOQUIA",
    "name_city": "NECHI"
  },
  {
    "id": 117,
    "zip_code": 52428,
    "dane_code": 5495000,
    "name_depto": "ANTIOQUIA",
    "name_city": "NECHI"
  },
  {
    "id": 118,
    "zip_code": 52430,
    "dane_code": 5250000,
    "name_depto": "ANTIOQUIA",
    "name_city": "EL BAGRE"
  },
  {
    "id": 119,
    "zip_code": 52437,
    "dane_code": 5250000,
    "name_depto": "ANTIOQUIA",
    "name_city": "EL BAGRE"
  },
  {
    "id": 120,
    "zip_code": 52438,
    "dane_code": 5250000,
    "name_depto": "ANTIOQUIA",
    "name_city": "EL BAGRE"
  },
  {
    "id": 121,
    "zip_code": 52440,
    "dane_code": 5895000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ZARAGOZA"
  },
  {
    "id": 122,
    "zip_code": 52447,
    "dane_code": 5895000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ZARAGOZA"
  },
  {
    "id": 123,
    "zip_code": 52448,
    "dane_code": 5895000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ZARAGOZA"
  },
  {
    "id": 124,
    "zip_code": 52450,
    "dane_code": 5120000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CACERES"
  },
  {
    "id": 125,
    "zip_code": 52457,
    "dane_code": 5120000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CACERES"
  },
  {
    "id": 126,
    "zip_code": 52458,
    "dane_code": 5120000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CACERES"
  },
  {
    "id": 127,
    "zip_code": 52460,
    "dane_code": 5790000,
    "name_depto": "ANTIOQUIA",
    "name_city": "TARAZA"
  },
  {
    "id": 128,
    "zip_code": 52467,
    "dane_code": 5790000,
    "name_depto": "ANTIOQUIA",
    "name_city": "TARAZA"
  },
  {
    "id": 129,
    "zip_code": 52468,
    "dane_code": 5790000,
    "name_depto": "ANTIOQUIA",
    "name_city": "TARAZA"
  },
  {
    "id": 130,
    "zip_code": 52810,
    "dane_code": 5736000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SEGOVIA"
  },
  {
    "id": 131,
    "zip_code": 52817,
    "dane_code": 5736000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SEGOVIA"
  },
  {
    "id": 132,
    "zip_code": 52818,
    "dane_code": 5736000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SEGOVIA"
  },
  {
    "id": 133,
    "zip_code": 52820,
    "dane_code": 5604000,
    "name_depto": "ANTIOQUIA",
    "name_city": "REMEDIOS"
  },
  {
    "id": 134,
    "zip_code": 52827,
    "dane_code": 5604000,
    "name_depto": "ANTIOQUIA",
    "name_city": "REMEDIOS"
  },
  {
    "id": 135,
    "zip_code": 52828,
    "dane_code": 5604000,
    "name_depto": "ANTIOQUIA",
    "name_city": "REMEDIOS"
  },
  {
    "id": 136,
    "zip_code": 52830,
    "dane_code": 5858000,
    "name_depto": "ANTIOQUIA",
    "name_city": "VEGACHI"
  },
  {
    "id": 137,
    "zip_code": 52837,
    "dane_code": 5858000,
    "name_depto": "ANTIOQUIA",
    "name_city": "VEGACHI"
  },
  {
    "id": 138,
    "zip_code": 52840,
    "dane_code": 5031000,
    "name_depto": "ANTIOQUIA",
    "name_city": "AMALFI"
  },
  {
    "id": 139,
    "zip_code": 52847,
    "dane_code": 5031000,
    "name_depto": "ANTIOQUIA",
    "name_city": "AMALFI"
  },
  {
    "id": 140,
    "zip_code": 52848,
    "dane_code": 5031000,
    "name_depto": "ANTIOQUIA",
    "name_city": "AMALFI"
  },
  {
    "id": 141,
    "zip_code": 52850,
    "dane_code": 5040000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ANORI"
  },
  {
    "id": 142,
    "zip_code": 52857,
    "dane_code": 5040000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ANORI"
  },
  {
    "id": 143,
    "zip_code": 53010,
    "dane_code": 5885000,
    "name_depto": "ANTIOQUIA",
    "name_city": "YALI"
  },
  {
    "id": 144,
    "zip_code": 53017,
    "dane_code": 5885000,
    "name_depto": "ANTIOQUIA",
    "name_city": "YALI"
  },
  {
    "id": 145,
    "zip_code": 53020,
    "dane_code": 5890000,
    "name_depto": "ANTIOQUIA",
    "name_city": "YOLOMBO"
  },
  {
    "id": 146,
    "zip_code": 53027,
    "dane_code": 5890000,
    "name_depto": "ANTIOQUIA",
    "name_city": "YOLOMBO"
  },
  {
    "id": 147,
    "zip_code": 53028,
    "dane_code": 5890000,
    "name_depto": "ANTIOQUIA",
    "name_city": "YOLOMBO"
  },
  {
    "id": 148,
    "zip_code": 53030,
    "dane_code": 5670000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SAN ROQUE"
  },
  {
    "id": 149,
    "zip_code": 53037,
    "dane_code": 5670000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SAN ROQUE"
  },
  {
    "id": 150,
    "zip_code": 53040,
    "dane_code": 5690000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SANTO DOMINGO"
  },
  {
    "id": 151,
    "zip_code": 53047,
    "dane_code": 5690000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SANTO DOMINGO"
  },
  {
    "id": 152,
    "zip_code": 53048,
    "dane_code": 5690000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SANTO DOMINGO"
  },
  {
    "id": 153,
    "zip_code": 53050,
    "dane_code": 5190000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CISNEROS"
  },
  {
    "id": 154,
    "zip_code": 53057,
    "dane_code": 5190000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CISNEROS"
  },
  {
    "id": 155,
    "zip_code": 53410,
    "dane_code": 5893000,
    "name_depto": "ANTIOQUIA",
    "name_city": "YONDO"
  },
  {
    "id": 156,
    "zip_code": 53417,
    "dane_code": 5893000,
    "name_depto": "ANTIOQUIA",
    "name_city": "YONDO"
  },
  {
    "id": 157,
    "zip_code": 53418,
    "dane_code": 5893000,
    "name_depto": "ANTIOQUIA",
    "name_city": "YONDO"
  },
  {
    "id": 158,
    "zip_code": 53420,
    "dane_code": 5579000,
    "name_depto": "ANTIOQUIA",
    "name_city": "PUERTO BERRIO"
  },
  {
    "id": 159,
    "zip_code": 53427,
    "dane_code": 5579000,
    "name_depto": "ANTIOQUIA",
    "name_city": "PUERTO BERRIO"
  },
  {
    "id": 160,
    "zip_code": 53428,
    "dane_code": 5579000,
    "name_depto": "ANTIOQUIA",
    "name_city": "PUERTO BERRIO"
  },
  {
    "id": 161,
    "zip_code": 53430,
    "dane_code": 5585000,
    "name_depto": "ANTIOQUIA",
    "name_city": "PUERTO NARE"
  },
  {
    "id": 162,
    "zip_code": 53437,
    "dane_code": 5585000,
    "name_depto": "ANTIOQUIA",
    "name_city": "PUERTO NARE"
  },
  {
    "id": 163,
    "zip_code": 53440,
    "dane_code": 5591000,
    "name_depto": "ANTIOQUIA",
    "name_city": "PUERTO TRIUNFO"
  },
  {
    "id": 164,
    "zip_code": 53447,
    "dane_code": 5591000,
    "name_depto": "ANTIOQUIA",
    "name_city": "PUERTO TRIUNFO"
  },
  {
    "id": 165,
    "zip_code": 53448,
    "dane_code": 5591000,
    "name_depto": "ANTIOQUIA",
    "name_city": "PUERTO TRIUNFO"
  },
  {
    "id": 166,
    "zip_code": 53450,
    "dane_code": 5142000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CARACOLI"
  },
  {
    "id": 167,
    "zip_code": 53457,
    "dane_code": 5142000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CARACOLI"
  },
  {
    "id": 168,
    "zip_code": 53460,
    "dane_code": 5425000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MACEO"
  },
  {
    "id": 169,
    "zip_code": 53467,
    "dane_code": 5425000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MACEO"
  },
  {
    "id": 170,
    "zip_code": 53810,
    "dane_code": 5206000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CONCEPCION"
  },
  {
    "id": 171,
    "zip_code": 53817,
    "dane_code": 5206000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CONCEPCION"
  },
  {
    "id": 172,
    "zip_code": 53820,
    "dane_code": 5021000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ALEJANDRIA"
  },
  {
    "id": 173,
    "zip_code": 53827,
    "dane_code": 5021000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ALEJANDRIA"
  },
  {
    "id": 174,
    "zip_code": 53830,
    "dane_code": 5667000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SAN RAFAEL"
  },
  {
    "id": 175,
    "zip_code": 53837,
    "dane_code": 5667000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SAN RAFAEL"
  },
  {
    "id": 176,
    "zip_code": 53838,
    "dane_code": 5667000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SAN RAFAEL"
  },
  {
    "id": 177,
    "zip_code": 53840,
    "dane_code": 5321000,
    "name_depto": "ANTIOQUIA",
    "name_city": "GUATAPE"
  },
  {
    "id": 178,
    "zip_code": 53847,
    "dane_code": 5321000,
    "name_depto": "ANTIOQUIA",
    "name_city": "GUATAPE"
  },
  {
    "id": 179,
    "zip_code": 53850,
    "dane_code": 5541000,
    "name_depto": "ANTIOQUIA",
    "name_city": "PE√ëOL"
  },
  {
    "id": 180,
    "zip_code": 53857,
    "dane_code": 5541000,
    "name_depto": "ANTIOQUIA",
    "name_city": "PE√ëOL"
  },
  {
    "id": 181,
    "zip_code": 54010,
    "dane_code": 5674000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SAN VICENTE"
  },
  {
    "id": 182,
    "zip_code": 54017,
    "dane_code": 5674000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SAN VICENTE"
  },
  {
    "id": 183,
    "zip_code": 54018,
    "dane_code": 5674000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SAN VICENTE"
  },
  {
    "id": 184,
    "zip_code": 54020,
    "dane_code": 5440000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MARINILLA"
  },
  {
    "id": 185,
    "zip_code": 54027,
    "dane_code": 5440000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MARINILLA"
  },
  {
    "id": 186,
    "zip_code": 54028,
    "dane_code": 5440000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MARINILLA"
  },
  {
    "id": 187,
    "zip_code": 54030,
    "dane_code": 5148000,
    "name_depto": "ANTIOQUIA",
    "name_city": "EL CARMEN DE VIBORAL"
  },
  {
    "id": 188,
    "zip_code": 54037,
    "dane_code": 5148000,
    "name_depto": "ANTIOQUIA",
    "name_city": "EL CARMEN DE VIBORAL"
  },
  {
    "id": 189,
    "zip_code": 54038,
    "dane_code": 5148000,
    "name_depto": "ANTIOQUIA",
    "name_city": "EL CARMEN DE VIBORAL"
  },
  {
    "id": 190,
    "zip_code": 54040,
    "dane_code": 5615000,
    "name_depto": "ANTIOQUIA",
    "name_city": "RIONEGRO"
  },
  {
    "id": 191,
    "zip_code": 54047,
    "dane_code": 5615000,
    "name_depto": "ANTIOQUIA",
    "name_city": "RIONEGRO"
  },
  {
    "id": 192,
    "zip_code": 54048,
    "dane_code": 5615000,
    "name_depto": "ANTIOQUIA",
    "name_city": "RIONEGRO"
  },
  {
    "id": 193,
    "zip_code": 54050,
    "dane_code": 5318000,
    "name_depto": "ANTIOQUIA",
    "name_city": "GUARNE"
  },
  {
    "id": 194,
    "zip_code": 54057,
    "dane_code": 5318000,
    "name_depto": "ANTIOQUIA",
    "name_city": "GUARNE"
  },
  {
    "id": 195,
    "zip_code": 54058,
    "dane_code": 5318000,
    "name_depto": "ANTIOQUIA",
    "name_city": "GUARNE"
  },
  {
    "id": 196,
    "zip_code": 54410,
    "dane_code": 5313000,
    "name_depto": "ANTIOQUIA",
    "name_city": "GRANADA"
  },
  {
    "id": 197,
    "zip_code": 54417,
    "dane_code": 5313000,
    "name_depto": "ANTIOQUIA",
    "name_city": "GRANADA"
  },
  {
    "id": 198,
    "zip_code": 54420,
    "dane_code": 5649000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SAN CARLOS"
  },
  {
    "id": 199,
    "zip_code": 54427,
    "dane_code": 5649000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SAN CARLOS"
  },
  {
    "id": 200,
    "zip_code": 54428,
    "dane_code": 5649000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SAN CARLOS"
  },
  {
    "id": 201,
    "zip_code": 54430,
    "dane_code": 5660000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SAN LUIS"
  },
  {
    "id": 202,
    "zip_code": 54437,
    "dane_code": 5660000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SAN LUIS"
  },
  {
    "id": 203,
    "zip_code": 54438,
    "dane_code": 5660000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SAN LUIS"
  },
  {
    "id": 204,
    "zip_code": 54440,
    "dane_code": 5197000,
    "name_depto": "ANTIOQUIA",
    "name_city": "COCORNA"
  },
  {
    "id": 205,
    "zip_code": 54447,
    "dane_code": 5197000,
    "name_depto": "ANTIOQUIA",
    "name_city": "COCORNA"
  },
  {
    "id": 206,
    "zip_code": 54448,
    "dane_code": 5197000,
    "name_depto": "ANTIOQUIA",
    "name_city": "COCORNA"
  },
  {
    "id": 207,
    "zip_code": 54450,
    "dane_code": 5697000,
    "name_depto": "ANTIOQUIA",
    "name_city": "EL SANTUARIO"
  },
  {
    "id": 208,
    "zip_code": 54457,
    "dane_code": 5697000,
    "name_depto": "ANTIOQUIA",
    "name_city": "EL SANTUARIO"
  },
  {
    "id": 209,
    "zip_code": 54810,
    "dane_code": 5652000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SAN FRANCISCO"
  },
  {
    "id": 210,
    "zip_code": 54817,
    "dane_code": 5652000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SAN FRANCISCO"
  },
  {
    "id": 211,
    "zip_code": 54818,
    "dane_code": 5652000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SAN FRANCISCO"
  },
  {
    "id": 212,
    "zip_code": 54820,
    "dane_code": 5756000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SONSON"
  },
  {
    "id": 213,
    "zip_code": 54827,
    "dane_code": 5756000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SONSON"
  },
  {
    "id": 214,
    "zip_code": 54828,
    "dane_code": 5756000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SONSON"
  },
  {
    "id": 215,
    "zip_code": 54829,
    "dane_code": 5756000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SONSON"
  },
  {
    "id": 216,
    "zip_code": 54830,
    "dane_code": 5055000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ARGELIA"
  },
  {
    "id": 217,
    "zip_code": 54837,
    "dane_code": 5055000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ARGELIA"
  },
  {
    "id": 218,
    "zip_code": 54838,
    "dane_code": 5055000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ARGELIA"
  },
  {
    "id": 219,
    "zip_code": 54840,
    "dane_code": 5483000,
    "name_depto": "ANTIOQUIA",
    "name_city": "NARI√ëO"
  },
  {
    "id": 220,
    "zip_code": 54847,
    "dane_code": 5483000,
    "name_depto": "ANTIOQUIA",
    "name_city": "NARI√ëO"
  },
  {
    "id": 221,
    "zip_code": 54848,
    "dane_code": 5483000,
    "name_depto": "ANTIOQUIA",
    "name_city": "NARI√ëO"
  },
  {
    "id": 222,
    "zip_code": 55010,
    "dane_code": 5376000,
    "name_depto": "ANTIOQUIA",
    "name_city": "LA CEJA"
  },
  {
    "id": 223,
    "zip_code": 55017,
    "dane_code": 5376000,
    "name_depto": "ANTIOQUIA",
    "name_city": "LA CEJA"
  },
  {
    "id": 224,
    "zip_code": 55018,
    "dane_code": 5376000,
    "name_depto": "ANTIOQUIA",
    "name_city": "LA CEJA"
  },
  {
    "id": 225,
    "zip_code": 55020,
    "dane_code": 5400000,
    "name_depto": "ANTIOQUIA",
    "name_city": "LA UNION"
  },
  {
    "id": 226,
    "zip_code": 55027,
    "dane_code": 5400000,
    "name_depto": "ANTIOQUIA",
    "name_city": "LA UNION"
  },
  {
    "id": 227,
    "zip_code": 55028,
    "dane_code": 5400000,
    "name_depto": "ANTIOQUIA",
    "name_city": "LA UNION"
  },
  {
    "id": 228,
    "zip_code": 55030,
    "dane_code": 5002000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ABEJORRAL"
  },
  {
    "id": 229,
    "zip_code": 55037,
    "dane_code": 5002000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ABEJORRAL"
  },
  {
    "id": 230,
    "zip_code": 55038,
    "dane_code": 5002000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ABEJORRAL"
  },
  {
    "id": 231,
    "zip_code": 55040,
    "dane_code": 5467000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MONTEBELLO"
  },
  {
    "id": 232,
    "zip_code": 55047,
    "dane_code": 5467000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MONTEBELLO"
  },
  {
    "id": 233,
    "zip_code": 55048,
    "dane_code": 5467000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MONTEBELLO"
  },
  {
    "id": 234,
    "zip_code": 55050,
    "dane_code": 5679000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SANTA BARBARA"
  },
  {
    "id": 235,
    "zip_code": 55057,
    "dane_code": 5679000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SANTA BARBARA"
  },
  {
    "id": 236,
    "zip_code": 55058,
    "dane_code": 5679000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SANTA BARBARA"
  },
  {
    "id": 237,
    "zip_code": 55060,
    "dane_code": 5390000,
    "name_depto": "ANTIOQUIA",
    "name_city": "LA PINTADA"
  },
  {
    "id": 238,
    "zip_code": 55067,
    "dane_code": 5390000,
    "name_depto": "ANTIOQUIA",
    "name_city": "LA PINTADA"
  },
  {
    "id": 239,
    "zip_code": 55068,
    "dane_code": 5390000,
    "name_depto": "ANTIOQUIA",
    "name_city": "LA PINTADA"
  },
  {
    "id": 240,
    "zip_code": 55070,
    "dane_code": 5282000,
    "name_depto": "ANTIOQUIA",
    "name_city": "FREDONIA"
  },
  {
    "id": 241,
    "zip_code": 55077,
    "dane_code": 5282000,
    "name_depto": "ANTIOQUIA",
    "name_city": "FREDONIA"
  },
  {
    "id": 242,
    "zip_code": 55078,
    "dane_code": 5282000,
    "name_depto": "ANTIOQUIA",
    "name_city": "FREDONIA"
  },
  {
    "id": 243,
    "zip_code": 55410,
    "dane_code": 5360000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ITAG√úI"
  },
  {
    "id": 244,
    "zip_code": 55411,
    "dane_code": 5360000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ITAG√úI"
  },
  {
    "id": 245,
    "zip_code": 55412,
    "dane_code": 5360000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ITAG√úI"
  },
  {
    "id": 246,
    "zip_code": 55413,
    "dane_code": 5360000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ITAG√úI"
  },
  {
    "id": 247,
    "zip_code": 55417,
    "dane_code": 5360000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ITAG√úI"
  },
  {
    "id": 248,
    "zip_code": 55420,
    "dane_code": 5266000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ENVIGADO"
  },
  {
    "id": 249,
    "zip_code": 55421,
    "dane_code": 5266000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ENVIGADO"
  },
  {
    "id": 250,
    "zip_code": 55422,
    "dane_code": 5266000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ENVIGADO"
  },
  {
    "id": 251,
    "zip_code": 55427,
    "dane_code": 5266000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ENVIGADO"
  },
  {
    "id": 252,
    "zip_code": 55428,
    "dane_code": 5266000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ENVIGADO"
  },
  {
    "id": 253,
    "zip_code": 55430,
    "dane_code": 5607000,
    "name_depto": "ANTIOQUIA",
    "name_city": "RETIRO"
  },
  {
    "id": 254,
    "zip_code": 55437,
    "dane_code": 5607000,
    "name_depto": "ANTIOQUIA",
    "name_city": "RETIRO"
  },
  {
    "id": 255,
    "zip_code": 55438,
    "dane_code": 5607000,
    "name_depto": "ANTIOQUIA",
    "name_city": "RETIRO"
  },
  {
    "id": 256,
    "zip_code": 55440,
    "dane_code": 5129000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CALDAS"
  },
  {
    "id": 257,
    "zip_code": 55447,
    "dane_code": 5129000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CALDAS"
  },
  {
    "id": 258,
    "zip_code": 55448,
    "dane_code": 5129000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CALDAS"
  },
  {
    "id": 259,
    "zip_code": 55450,
    "dane_code": 5631000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SABANETA"
  },
  {
    "id": 260,
    "zip_code": 55457,
    "dane_code": 5631000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SABANETA"
  },
  {
    "id": 261,
    "zip_code": 55460,
    "dane_code": 5380000,
    "name_depto": "ANTIOQUIA",
    "name_city": "LA ESTRELLA"
  },
  {
    "id": 262,
    "zip_code": 55467,
    "dane_code": 5380000,
    "name_depto": "ANTIOQUIA",
    "name_city": "LA ESTRELLA"
  },
  {
    "id": 263,
    "zip_code": 55468,
    "dane_code": 5380000,
    "name_depto": "ANTIOQUIA",
    "name_city": "LA ESTRELLA"
  },
  {
    "id": 264,
    "zip_code": 55810,
    "dane_code": 5240000,
    "name_depto": "ANTIOQUIA",
    "name_city": "EBEJICO"
  },
  {
    "id": 265,
    "zip_code": 55817,
    "dane_code": 5240000,
    "name_depto": "ANTIOQUIA",
    "name_city": "EBEJICO"
  },
  {
    "id": 266,
    "zip_code": 55818,
    "dane_code": 5240000,
    "name_depto": "ANTIOQUIA",
    "name_city": "EBEJICO"
  },
  {
    "id": 267,
    "zip_code": 55820,
    "dane_code": 5347000,
    "name_depto": "ANTIOQUIA",
    "name_city": "HELICONIA"
  },
  {
    "id": 268,
    "zip_code": 55827,
    "dane_code": 5347000,
    "name_depto": "ANTIOQUIA",
    "name_city": "HELICONIA"
  },
  {
    "id": 269,
    "zip_code": 55830,
    "dane_code": 5036000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ANGELOPOLIS"
  },
  {
    "id": 270,
    "zip_code": 55837,
    "dane_code": 5036000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ANGELOPOLIS"
  },
  {
    "id": 271,
    "zip_code": 55840,
    "dane_code": 5030000,
    "name_depto": "ANTIOQUIA",
    "name_city": "AMAGA"
  },
  {
    "id": 272,
    "zip_code": 55847,
    "dane_code": 5030000,
    "name_depto": "ANTIOQUIA",
    "name_city": "AMAGA"
  },
  {
    "id": 273,
    "zip_code": 55848,
    "dane_code": 5030000,
    "name_depto": "ANTIOQUIA",
    "name_city": "AMAGA"
  },
  {
    "id": 274,
    "zip_code": 55850,
    "dane_code": 5809000,
    "name_depto": "ANTIOQUIA",
    "name_city": "TITIRIBI"
  },
  {
    "id": 275,
    "zip_code": 55857,
    "dane_code": 5809000,
    "name_depto": "ANTIOQUIA",
    "name_city": "TITIRIBI"
  },
  {
    "id": 276,
    "zip_code": 55858,
    "dane_code": 5809000,
    "name_depto": "ANTIOQUIA",
    "name_city": "TITIRIBI"
  },
  {
    "id": 277,
    "zip_code": 55860,
    "dane_code": 5059000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ARMENIA"
  },
  {
    "id": 278,
    "zip_code": 55867,
    "dane_code": 5059000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ARMENIA"
  },
  {
    "id": 279,
    "zip_code": 56010,
    "dane_code": 5368000,
    "name_depto": "ANTIOQUIA",
    "name_city": "JERICO"
  },
  {
    "id": 280,
    "zip_code": 56017,
    "dane_code": 5368000,
    "name_depto": "ANTIOQUIA",
    "name_city": "JERICO"
  },
  {
    "id": 281,
    "zip_code": 56020,
    "dane_code": 5789000,
    "name_depto": "ANTIOQUIA",
    "name_city": "TAMESIS"
  },
  {
    "id": 282,
    "zip_code": 56027,
    "dane_code": 5789000,
    "name_depto": "ANTIOQUIA",
    "name_city": "TAMESIS"
  },
  {
    "id": 283,
    "zip_code": 56028,
    "dane_code": 5789000,
    "name_depto": "ANTIOQUIA",
    "name_city": "TAMESIS"
  },
  {
    "id": 284,
    "zip_code": 56030,
    "dane_code": 5856000,
    "name_depto": "ANTIOQUIA",
    "name_city": "VALPARAISO"
  },
  {
    "id": 285,
    "zip_code": 56037,
    "dane_code": 5856000,
    "name_depto": "ANTIOQUIA",
    "name_city": "VALPARAISO"
  },
  {
    "id": 286,
    "zip_code": 56040,
    "dane_code": 5145000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CARAMANTA"
  },
  {
    "id": 287,
    "zip_code": 56047,
    "dane_code": 5145000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CARAMANTA"
  },
  {
    "id": 288,
    "zip_code": 56050,
    "dane_code": 5364000,
    "name_depto": "ANTIOQUIA",
    "name_city": "JARDIN"
  },
  {
    "id": 289,
    "zip_code": 56057,
    "dane_code": 5364000,
    "name_depto": "ANTIOQUIA",
    "name_city": "JARDIN"
  },
  {
    "id": 290,
    "zip_code": 56060,
    "dane_code": 5034000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ANDES"
  },
  {
    "id": 291,
    "zip_code": 56067,
    "dane_code": 5034000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ANDES"
  },
  {
    "id": 292,
    "zip_code": 56068,
    "dane_code": 5034000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ANDES"
  },
  {
    "id": 293,
    "zip_code": 56070,
    "dane_code": 5091000,
    "name_depto": "ANTIOQUIA",
    "name_city": "BETANIA"
  },
  {
    "id": 294,
    "zip_code": 56077,
    "dane_code": 5091000,
    "name_depto": "ANTIOQUIA",
    "name_city": "BETANIA"
  },
  {
    "id": 295,
    "zip_code": 56410,
    "dane_code": 5209000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CONCORDIA"
  },
  {
    "id": 296,
    "zip_code": 56417,
    "dane_code": 5209000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CONCORDIA"
  },
  {
    "id": 297,
    "zip_code": 56420,
    "dane_code": 5861000,
    "name_depto": "ANTIOQUIA",
    "name_city": "VENECIA"
  },
  {
    "id": 298,
    "zip_code": 56427,
    "dane_code": 5861000,
    "name_depto": "ANTIOQUIA",
    "name_city": "VENECIA"
  },
  {
    "id": 299,
    "zip_code": 56430,
    "dane_code": 5792000,
    "name_depto": "ANTIOQUIA",
    "name_city": "TARSO"
  },
  {
    "id": 300,
    "zip_code": 56437,
    "dane_code": 5792000,
    "name_depto": "ANTIOQUIA",
    "name_city": "TARSO"
  },
  {
    "id": 301,
    "zip_code": 56438,
    "dane_code": 5792000,
    "name_depto": "ANTIOQUIA",
    "name_city": "TARSO"
  },
  {
    "id": 302,
    "zip_code": 56440,
    "dane_code": 5576000,
    "name_depto": "ANTIOQUIA",
    "name_city": "PUEBLORRICO"
  },
  {
    "id": 303,
    "zip_code": 56447,
    "dane_code": 5576000,
    "name_depto": "ANTIOQUIA",
    "name_city": "PUEBLORRICO"
  },
  {
    "id": 304,
    "zip_code": 56450,
    "dane_code": 5353000,
    "name_depto": "ANTIOQUIA",
    "name_city": "HISPANIA"
  },
  {
    "id": 305,
    "zip_code": 56457,
    "dane_code": 5353000,
    "name_depto": "ANTIOQUIA",
    "name_city": "HISPANIA"
  },
  {
    "id": 306,
    "zip_code": 56460,
    "dane_code": 5101000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CIUDAD BOLIVAR"
  },
  {
    "id": 307,
    "zip_code": 56467,
    "dane_code": 5101000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CIUDAD BOLIVAR"
  },
  {
    "id": 308,
    "zip_code": 56468,
    "dane_code": 5101000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CIUDAD BOLIVAR"
  },
  {
    "id": 309,
    "zip_code": 56470,
    "dane_code": 5642000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SALGAR"
  },
  {
    "id": 310,
    "zip_code": 56477,
    "dane_code": 5642000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SALGAR"
  },
  {
    "id": 311,
    "zip_code": 56478,
    "dane_code": 5642000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SALGAR"
  },
  {
    "id": 312,
    "zip_code": 56810,
    "dane_code": 5475000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MURINDO"
  },
  {
    "id": 313,
    "zip_code": 56817,
    "dane_code": 5475000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MURINDO"
  },
  {
    "id": 314,
    "zip_code": 56818,
    "dane_code": 5475000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MURINDO"
  },
  {
    "id": 315,
    "zip_code": 56820,
    "dane_code": 5873000,
    "name_depto": "ANTIOQUIA",
    "name_city": "VIGIA DEL FUERTE"
  },
  {
    "id": 316,
    "zip_code": 56827,
    "dane_code": 5873000,
    "name_depto": "ANTIOQUIA",
    "name_city": "VIGIA DEL FUERTE"
  },
  {
    "id": 317,
    "zip_code": 56828,
    "dane_code": 5873000,
    "name_depto": "ANTIOQUIA",
    "name_city": "VIGIA DEL FUERTE"
  },
  {
    "id": 318,
    "zip_code": 56830,
    "dane_code": 5847000,
    "name_depto": "ANTIOQUIA",
    "name_city": "URRAO"
  },
  {
    "id": 319,
    "zip_code": 56837,
    "dane_code": 5847000,
    "name_depto": "ANTIOQUIA",
    "name_city": "URRAO"
  },
  {
    "id": 320,
    "zip_code": 56838,
    "dane_code": 5847000,
    "name_depto": "ANTIOQUIA",
    "name_city": "URRAO"
  },
  {
    "id": 321,
    "zip_code": 56840,
    "dane_code": 5125000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CAICEDO"
  },
  {
    "id": 322,
    "zip_code": 56847,
    "dane_code": 5125000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CAICEDO"
  },
  {
    "id": 323,
    "zip_code": 56850,
    "dane_code": 5044000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ANZA"
  },
  {
    "id": 324,
    "zip_code": 56857,
    "dane_code": 5044000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ANZA"
  },
  {
    "id": 325,
    "zip_code": 56858,
    "dane_code": 5044000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ANZA"
  },
  {
    "id": 326,
    "zip_code": 56860,
    "dane_code": 5093000,
    "name_depto": "ANTIOQUIA",
    "name_city": "BETULIA"
  },
  {
    "id": 327,
    "zip_code": 56867,
    "dane_code": 5093000,
    "name_depto": "ANTIOQUIA",
    "name_city": "BETULIA"
  },
  {
    "id": 328,
    "zip_code": 56868,
    "dane_code": 5093000,
    "name_depto": "ANTIOQUIA",
    "name_city": "BETULIA"
  },
  {
    "id": 329,
    "zip_code": 57010,
    "dane_code": 5543000,
    "name_depto": "ANTIOQUIA",
    "name_city": "PEQUE"
  },
  {
    "id": 330,
    "zip_code": 57017,
    "dane_code": 5543000,
    "name_depto": "ANTIOQUIA",
    "name_city": "PEQUE"
  },
  {
    "id": 331,
    "zip_code": 57018,
    "dane_code": 5543000,
    "name_depto": "ANTIOQUIA",
    "name_city": "PEQUE"
  },
  {
    "id": 332,
    "zip_code": 57020,
    "dane_code": 5628000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SABANALARGA"
  },
  {
    "id": 333,
    "zip_code": 57027,
    "dane_code": 5628000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SABANALARGA"
  },
  {
    "id": 334,
    "zip_code": 57028,
    "dane_code": 5628000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SABANALARGA"
  },
  {
    "id": 335,
    "zip_code": 57030,
    "dane_code": 5113000,
    "name_depto": "ANTIOQUIA",
    "name_city": "BURITICA"
  },
  {
    "id": 336,
    "zip_code": 57037,
    "dane_code": 5113000,
    "name_depto": "ANTIOQUIA",
    "name_city": "BURITICA"
  },
  {
    "id": 337,
    "zip_code": 57038,
    "dane_code": 5113000,
    "name_depto": "ANTIOQUIA",
    "name_city": "BURITICA"
  },
  {
    "id": 338,
    "zip_code": 57040,
    "dane_code": 5306000,
    "name_depto": "ANTIOQUIA",
    "name_city": "GIRALDO"
  },
  {
    "id": 339,
    "zip_code": 57047,
    "dane_code": 5306000,
    "name_depto": "ANTIOQUIA",
    "name_city": "GIRALDO"
  },
  {
    "id": 340,
    "zip_code": 57050,
    "dane_code": 5042000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SANTAFE DE ANTIOQUIA"
  },
  {
    "id": 341,
    "zip_code": 57057,
    "dane_code": 5042000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SANTAFE DE ANTIOQUIA"
  },
  {
    "id": 342,
    "zip_code": 57058,
    "dane_code": 5042000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SANTAFE DE ANTIOQUIA"
  },
  {
    "id": 343,
    "zip_code": 57060,
    "dane_code": 5138000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CA√ëASGORDAS"
  },
  {
    "id": 344,
    "zip_code": 57067,
    "dane_code": 5138000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CA√ëASGORDAS"
  },
  {
    "id": 345,
    "zip_code": 57068,
    "dane_code": 5138000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CA√ëASGORDAS"
  },
  {
    "id": 346,
    "zip_code": 57410,
    "dane_code": 5172000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CHIGORODO"
  },
  {
    "id": 347,
    "zip_code": 57417,
    "dane_code": 5172000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CHIGORODO"
  },
  {
    "id": 348,
    "zip_code": 57418,
    "dane_code": 5172000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CHIGORODO"
  },
  {
    "id": 349,
    "zip_code": 57420,
    "dane_code": 5480000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MUTATA"
  },
  {
    "id": 350,
    "zip_code": 57427,
    "dane_code": 5480000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MUTATA"
  },
  {
    "id": 351,
    "zip_code": 57428,
    "dane_code": 5480000,
    "name_depto": "ANTIOQUIA",
    "name_city": "MUTATA"
  },
  {
    "id": 352,
    "zip_code": 57430,
    "dane_code": 5234000,
    "name_depto": "ANTIOQUIA",
    "name_city": "DABEIBA"
  },
  {
    "id": 353,
    "zip_code": 57437,
    "dane_code": 5234000,
    "name_depto": "ANTIOQUIA",
    "name_city": "DABEIBA"
  },
  {
    "id": 354,
    "zip_code": 57438,
    "dane_code": 5234000,
    "name_depto": "ANTIOQUIA",
    "name_city": "DABEIBA"
  },
  {
    "id": 355,
    "zip_code": 57440,
    "dane_code": 5842000,
    "name_depto": "ANTIOQUIA",
    "name_city": "URAMITA"
  },
  {
    "id": 356,
    "zip_code": 57447,
    "dane_code": 5842000,
    "name_depto": "ANTIOQUIA",
    "name_city": "URAMITA"
  },
  {
    "id": 357,
    "zip_code": 57450,
    "dane_code": 5284000,
    "name_depto": "ANTIOQUIA",
    "name_city": "FRONTINO"
  },
  {
    "id": 358,
    "zip_code": 57457,
    "dane_code": 5284000,
    "name_depto": "ANTIOQUIA",
    "name_city": "FRONTINO"
  },
  {
    "id": 359,
    "zip_code": 57458,
    "dane_code": 5284000,
    "name_depto": "ANTIOQUIA",
    "name_city": "FRONTINO"
  },
  {
    "id": 360,
    "zip_code": 57460,
    "dane_code": 5004000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ABRIAQUI"
  },
  {
    "id": 361,
    "zip_code": 57467,
    "dane_code": 5004000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ABRIAQUI"
  },
  {
    "id": 362,
    "zip_code": 57810,
    "dane_code": 5659000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SAN JUAN DE URABA"
  },
  {
    "id": 363,
    "zip_code": 57817,
    "dane_code": 5659000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SAN JUAN DE URABA"
  },
  {
    "id": 364,
    "zip_code": 57820,
    "dane_code": 5051000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ARBOLETES"
  },
  {
    "id": 365,
    "zip_code": 57827,
    "dane_code": 5051000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ARBOLETES"
  },
  {
    "id": 366,
    "zip_code": 57828,
    "dane_code": 5051000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ARBOLETES"
  },
  {
    "id": 367,
    "zip_code": 57829,
    "dane_code": 5051000,
    "name_depto": "ANTIOQUIA",
    "name_city": "ARBOLETES"
  },
  {
    "id": 368,
    "zip_code": 57830,
    "dane_code": 5665000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SAN PEDRO DE URABA"
  },
  {
    "id": 369,
    "zip_code": 57837,
    "dane_code": 5665000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SAN PEDRO DE URABA"
  },
  {
    "id": 370,
    "zip_code": 57838,
    "dane_code": 5665000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SAN PEDRO DE URABA"
  },
  {
    "id": 371,
    "zip_code": 57839,
    "dane_code": 5665000,
    "name_depto": "ANTIOQUIA",
    "name_city": "SAN PEDRO DE URABA"
  },
  {
    "id": 372,
    "zip_code": 57840,
    "dane_code": 5045000,
    "name_depto": "ANTIOQUIA",
    "name_city": "APARTADO"
  },
  {
    "id": 373,
    "zip_code": 57841,
    "dane_code": 5045000,
    "name_depto": "ANTIOQUIA",
    "name_city": "APARTADO"
  },
  {
    "id": 374,
    "zip_code": 57847,
    "dane_code": 5045000,
    "name_depto": "ANTIOQUIA",
    "name_city": "APARTADO"
  },
  {
    "id": 375,
    "zip_code": 57850,
    "dane_code": 5147000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CAREPA"
  },
  {
    "id": 376,
    "zip_code": 57857,
    "dane_code": 5147000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CAREPA"
  },
  {
    "id": 377,
    "zip_code": 57858,
    "dane_code": 5147000,
    "name_depto": "ANTIOQUIA",
    "name_city": "CAREPA"
  },
  {
    "id": 378,
    "zip_code": 57860,
    "dane_code": 5837000,
    "name_depto": "ANTIOQUIA",
    "name_city": "TURBO"
  },
  {
    "id": 379,
    "zip_code": 57867,
    "dane_code": 5837000,
    "name_depto": "ANTIOQUIA",
    "name_city": "TURBO"
  },
  {
    "id": 380,
    "zip_code": 57868,
    "dane_code": 5837000,
    "name_depto": "ANTIOQUIA",
    "name_city": "TURBO"
  },
  {
    "id": 381,
    "zip_code": 57869,
    "dane_code": 5837000,
    "name_depto": "ANTIOQUIA",
    "name_city": "TURBO"
  },
  {
    "id": 382,
    "zip_code": 57870,
    "dane_code": 5490000,
    "name_depto": "ANTIOQUIA",
    "name_city": "NECOCLI"
  },
  {
    "id": 383,
    "zip_code": 57877,
    "dane_code": 5490000,
    "name_depto": "ANTIOQUIA",
    "name_city": "NECOCLI"
  },
  {
    "id": 384,
    "zip_code": 57878,
    "dane_code": 5490000,
    "name_depto": "ANTIOQUIA",
    "name_city": "NECOCLI"
  },
  {
    "id": 385,
    "zip_code": 80001,
    "dane_code": 8001000,
    "name_depto": "ATLANTICO",
    "name_city": "BARRANQUILLA"
  },
  {
    "id": 386,
    "zip_code": 80002,
    "dane_code": 8001000,
    "name_depto": "ATLANTICO",
    "name_city": "BARRANQUILLA"
  },
  {
    "id": 387,
    "zip_code": 80003,
    "dane_code": 8001000,
    "name_depto": "ATLANTICO",
    "name_city": "BARRANQUILLA"
  },
  {
    "id": 388,
    "zip_code": 80004,
    "dane_code": 8001000,
    "name_depto": "ATLANTICO",
    "name_city": "BARRANQUILLA"
  },
  {
    "id": 389,
    "zip_code": 80005,
    "dane_code": 8001000,
    "name_depto": "ATLANTICO",
    "name_city": "BARRANQUILLA"
  },
  {
    "id": 390,
    "zip_code": 80006,
    "dane_code": 8001000,
    "name_depto": "ATLANTICO",
    "name_city": "BARRANQUILLA"
  },
  {
    "id": 391,
    "zip_code": 80007,
    "dane_code": 8001000,
    "name_depto": "ATLANTICO",
    "name_city": "BARRANQUILLA"
  },
  {
    "id": 392,
    "zip_code": 80010,
    "dane_code": 8001000,
    "name_depto": "ATLANTICO",
    "name_city": "BARRANQUILLA"
  },
  {
    "id": 393,
    "zip_code": 80011,
    "dane_code": 8001000,
    "name_depto": "ATLANTICO",
    "name_city": "BARRANQUILLA"
  },
  {
    "id": 394,
    "zip_code": 80012,
    "dane_code": 8001000,
    "name_depto": "ATLANTICO",
    "name_city": "BARRANQUILLA"
  },
  {
    "id": 395,
    "zip_code": 80013,
    "dane_code": 8001000,
    "name_depto": "ATLANTICO",
    "name_city": "BARRANQUILLA"
  },
  {
    "id": 396,
    "zip_code": 80014,
    "dane_code": 8001000,
    "name_depto": "ATLANTICO",
    "name_city": "BARRANQUILLA"
  },
  {
    "id": 397,
    "zip_code": 80015,
    "dane_code": 8001000,
    "name_depto": "ATLANTICO",
    "name_city": "BARRANQUILLA"
  },
  {
    "id": 398,
    "zip_code": 80016,
    "dane_code": 8001000,
    "name_depto": "ATLANTICO",
    "name_city": "BARRANQUILLA"
  },
  {
    "id": 399,
    "zip_code": 80020,
    "dane_code": 8001000,
    "name_depto": "ATLANTICO",
    "name_city": "BARRANQUILLA"
  },
  {
    "id": 400,
    "zip_code": 81001,
    "dane_code": 8573000,
    "name_depto": "ATLANTICO",
    "name_city": "PUERTO COLOMBIA"
  },
  {
    "id": 401,
    "zip_code": 81007,
    "dane_code": 8573000,
    "name_depto": "ATLANTICO",
    "name_city": "PUERTO COLOMBIA"
  },
  {
    "id": 402,
    "zip_code": 81008,
    "dane_code": 8573000,
    "name_depto": "ATLANTICO",
    "name_city": "PUERTO COLOMBIA"
  },
  {
    "id": 403,
    "zip_code": 81020,
    "dane_code": 8832000,
    "name_depto": "ATLANTICO",
    "name_city": "TUBARA"
  },
  {
    "id": 404,
    "zip_code": 81027,
    "dane_code": 8832000,
    "name_depto": "ATLANTICO",
    "name_city": "TUBARA"
  },
  {
    "id": 405,
    "zip_code": 81028,
    "dane_code": 8832000,
    "name_depto": "ATLANTICO",
    "name_city": "TUBARA"
  },
  {
    "id": 406,
    "zip_code": 81040,
    "dane_code": 8372000,
    "name_depto": "ATLANTICO",
    "name_city": "JUAN DE ACOSTA"
  },
  {
    "id": 407,
    "zip_code": 81047,
    "dane_code": 8372000,
    "name_depto": "ATLANTICO",
    "name_city": "JUAN DE ACOSTA"
  },
  {
    "id": 408,
    "zip_code": 81048,
    "dane_code": 8372000,
    "name_depto": "ATLANTICO",
    "name_city": "JUAN DE ACOSTA"
  },
  {
    "id": 409,
    "zip_code": 81060,
    "dane_code": 8549000,
    "name_depto": "ATLANTICO",
    "name_city": "PIOJO"
  },
  {
    "id": 410,
    "zip_code": 81067,
    "dane_code": 8549000,
    "name_depto": "ATLANTICO",
    "name_city": "PIOJO"
  },
  {
    "id": 411,
    "zip_code": 82001,
    "dane_code": 8296000,
    "name_depto": "ATLANTICO",
    "name_city": "GALAPA"
  },
  {
    "id": 412,
    "zip_code": 82007,
    "dane_code": 8296000,
    "name_depto": "ATLANTICO",
    "name_city": "GALAPA"
  },
  {
    "id": 413,
    "zip_code": 82020,
    "dane_code": 8078000,
    "name_depto": "ATLANTICO",
    "name_city": "BARANOA"
  },
  {
    "id": 414,
    "zip_code": 82027,
    "dane_code": 8078000,
    "name_depto": "ATLANTICO",
    "name_city": "BARANOA"
  },
  {
    "id": 415,
    "zip_code": 82028,
    "dane_code": 8078000,
    "name_depto": "ATLANTICO",
    "name_city": "BARANOA"
  },
  {
    "id": 416,
    "zip_code": 82040,
    "dane_code": 8558000,
    "name_depto": "ATLANTICO",
    "name_city": "POLONUEVO"
  },
  {
    "id": 417,
    "zip_code": 82047,
    "dane_code": 8558000,
    "name_depto": "ATLANTICO",
    "name_city": "POLONUEVO"
  },
  {
    "id": 418,
    "zip_code": 82060,
    "dane_code": 8849000,
    "name_depto": "ATLANTICO",
    "name_city": "USIACURI"
  },
  {
    "id": 419,
    "zip_code": 82067,
    "dane_code": 8849000,
    "name_depto": "ATLANTICO",
    "name_city": "USIACURI"
  },
  {
    "id": 420,
    "zip_code": 83001,
    "dane_code": 8758000,
    "name_depto": "ATLANTICO",
    "name_city": "SOLEDAD"
  },
  {
    "id": 421,
    "zip_code": 83002,
    "dane_code": 8758000,
    "name_depto": "ATLANTICO",
    "name_city": "SOLEDAD"
  },
  {
    "id": 422,
    "zip_code": 83003,
    "dane_code": 8758000,
    "name_depto": "ATLANTICO",
    "name_city": "SOLEDAD"
  },
  {
    "id": 423,
    "zip_code": 83004,
    "dane_code": 8758000,
    "name_depto": "ATLANTICO",
    "name_city": "SOLEDAD"
  },
  {
    "id": 424,
    "zip_code": 83005,
    "dane_code": 8758000,
    "name_depto": "ATLANTICO",
    "name_city": "SOLEDAD"
  },
  {
    "id": 425,
    "zip_code": 83006,
    "dane_code": 8758000,
    "name_depto": "ATLANTICO",
    "name_city": "SOLEDAD"
  },
  {
    "id": 426,
    "zip_code": 83007,
    "dane_code": 8758000,
    "name_depto": "ATLANTICO",
    "name_city": "SOLEDAD"
  },
  {
    "id": 427,
    "zip_code": 83010,
    "dane_code": 8758000,
    "name_depto": "ATLANTICO",
    "name_city": "SOLEDAD"
  },
  {
    "id": 428,
    "zip_code": 83020,
    "dane_code": 8433000,
    "name_depto": "ATLANTICO",
    "name_city": "MALAMBO"
  },
  {
    "id": 429,
    "zip_code": 83021,
    "dane_code": 8433000,
    "name_depto": "ATLANTICO",
    "name_city": "MALAMBO"
  },
  {
    "id": 430,
    "zip_code": 83027,
    "dane_code": 8433000,
    "name_depto": "ATLANTICO",
    "name_city": "MALAMBO"
  },
  {
    "id": 431,
    "zip_code": 83028,
    "dane_code": 8433000,
    "name_depto": "ATLANTICO",
    "name_city": "MALAMBO"
  },
  {
    "id": 432,
    "zip_code": 83040,
    "dane_code": 8634000,
    "name_depto": "ATLANTICO",
    "name_city": "SABANAGRANDE"
  },
  {
    "id": 433,
    "zip_code": 83047,
    "dane_code": 8634000,
    "name_depto": "ATLANTICO",
    "name_city": "SABANAGRANDE"
  },
  {
    "id": 434,
    "zip_code": 83060,
    "dane_code": 8685000,
    "name_depto": "ATLANTICO",
    "name_city": "SANTO TOMAS"
  },
  {
    "id": 435,
    "zip_code": 83067,
    "dane_code": 8685000,
    "name_depto": "ATLANTICO",
    "name_city": "SANTO TOMAS"
  },
  {
    "id": 436,
    "zip_code": 83080,
    "dane_code": 8520000,
    "name_depto": "ATLANTICO",
    "name_city": "PALMAR DE VARELA"
  },
  {
    "id": 437,
    "zip_code": 83087,
    "dane_code": 8520000,
    "name_depto": "ATLANTICO",
    "name_city": "PALMAR DE VARELA"
  },
  {
    "id": 438,
    "zip_code": 84001,
    "dane_code": 8560000,
    "name_depto": "ATLANTICO",
    "name_city": "PONEDERA"
  },
  {
    "id": 439,
    "zip_code": 84007,
    "dane_code": 8560000,
    "name_depto": "ATLANTICO",
    "name_city": "PONEDERA"
  },
  {
    "id": 440,
    "zip_code": 84008,
    "dane_code": 8560000,
    "name_depto": "ATLANTICO",
    "name_city": "PONEDERA"
  },
  {
    "id": 441,
    "zip_code": 84020,
    "dane_code": 8141000,
    "name_depto": "ATLANTICO",
    "name_city": "CANDELARIA"
  },
  {
    "id": 442,
    "zip_code": 84027,
    "dane_code": 8141000,
    "name_depto": "ATLANTICO",
    "name_city": "CANDELARIA"
  },
  {
    "id": 443,
    "zip_code": 84040,
    "dane_code": 8137000,
    "name_depto": "ATLANTICO",
    "name_city": "CAMPO DE LA CRUZ"
  },
  {
    "id": 444,
    "zip_code": 84047,
    "dane_code": 8137000,
    "name_depto": "ATLANTICO",
    "name_city": "CAMPO DE LA CRUZ"
  },
  {
    "id": 445,
    "zip_code": 84060,
    "dane_code": 8770000,
    "name_depto": "ATLANTICO",
    "name_city": "SUAN"
  },
  {
    "id": 446,
    "zip_code": 84067,
    "dane_code": 8770000,
    "name_depto": "ATLANTICO",
    "name_city": "SUAN"
  },
  {
    "id": 447,
    "zip_code": 84080,
    "dane_code": 8675000,
    "name_depto": "ATLANTICO",
    "name_city": "SANTA LUCIA"
  },
  {
    "id": 448,
    "zip_code": 84087,
    "dane_code": 8675000,
    "name_depto": "ATLANTICO",
    "name_city": "SANTA LUCIA"
  },
  {
    "id": 449,
    "zip_code": 85001,
    "dane_code": 8638000,
    "name_depto": "ATLANTICO",
    "name_city": "SABANALARGA"
  },
  {
    "id": 450,
    "zip_code": 85007,
    "dane_code": 8638000,
    "name_depto": "ATLANTICO",
    "name_city": "SABANALARGA"
  },
  {
    "id": 451,
    "zip_code": 85008,
    "dane_code": 8638000,
    "name_depto": "ATLANTICO",
    "name_city": "SABANALARGA"
  },
  {
    "id": 452,
    "zip_code": 85009,
    "dane_code": 8638000,
    "name_depto": "ATLANTICO",
    "name_city": "SABANALARGA"
  },
  {
    "id": 453,
    "zip_code": 85020,
    "dane_code": 8436000,
    "name_depto": "ATLANTICO",
    "name_city": "MANATI"
  },
  {
    "id": 454,
    "zip_code": 85027,
    "dane_code": 8436000,
    "name_depto": "ATLANTICO",
    "name_city": "MANATI"
  },
  {
    "id": 455,
    "zip_code": 85040,
    "dane_code": 8606000,
    "name_depto": "ATLANTICO",
    "name_city": "REPELON"
  },
  {
    "id": 456,
    "zip_code": 85047,
    "dane_code": 8606000,
    "name_depto": "ATLANTICO",
    "name_city": "REPELON"
  },
  {
    "id": 457,
    "zip_code": 85048,
    "dane_code": 8606000,
    "name_depto": "ATLANTICO",
    "name_city": "REPELON"
  },
  {
    "id": 458,
    "zip_code": 85060,
    "dane_code": 8421000,
    "name_depto": "ATLANTICO",
    "name_city": "LURUACO"
  },
  {
    "id": 459,
    "zip_code": 85067,
    "dane_code": 8421000,
    "name_depto": "ATLANTICO",
    "name_city": "LURUACO"
  },
  {
    "id": 460,
    "zip_code": 85068,
    "dane_code": 8421000,
    "name_depto": "ATLANTICO",
    "name_city": "LURUACO"
  },
  {
    "id": 542,
    "zip_code": 130001,
    "dane_code": 13001000,
    "name_depto": "BOLIVAR",
    "name_city": "CARTAGENA"
  },
  {
    "id": 543,
    "zip_code": 130002,
    "dane_code": 13001000,
    "name_depto": "BOLIVAR",
    "name_city": "CARTAGENA"
  },
  {
    "id": 544,
    "zip_code": 130003,
    "dane_code": 13001000,
    "name_depto": "BOLIVAR",
    "name_city": "CARTAGENA"
  },
  {
    "id": 545,
    "zip_code": 130004,
    "dane_code": 13001000,
    "name_depto": "BOLIVAR",
    "name_city": "CARTAGENA"
  },
  {
    "id": 546,
    "zip_code": 130005,
    "dane_code": 13001000,
    "name_depto": "BOLIVAR",
    "name_city": "CARTAGENA"
  },
  {
    "id": 547,
    "zip_code": 130006,
    "dane_code": 13001000,
    "name_depto": "BOLIVAR",
    "name_city": "CARTAGENA"
  },
  {
    "id": 548,
    "zip_code": 130007,
    "dane_code": 13001000,
    "name_depto": "BOLIVAR",
    "name_city": "CARTAGENA"
  },
  {
    "id": 549,
    "zip_code": 130008,
    "dane_code": 13001000,
    "name_depto": "BOLIVAR",
    "name_city": "CARTAGENA"
  },
  {
    "id": 550,
    "zip_code": 130009,
    "dane_code": 13001000,
    "name_depto": "BOLIVAR",
    "name_city": "CARTAGENA"
  },
  {
    "id": 551,
    "zip_code": 130010,
    "dane_code": 13001000,
    "name_depto": "BOLIVAR",
    "name_city": "CARTAGENA"
  },
  {
    "id": 552,
    "zip_code": 130011,
    "dane_code": 13001000,
    "name_depto": "BOLIVAR",
    "name_city": "CARTAGENA"
  },
  {
    "id": 553,
    "zip_code": 130012,
    "dane_code": 13001000,
    "name_depto": "BOLIVAR",
    "name_city": "CARTAGENA"
  },
  {
    "id": 554,
    "zip_code": 130013,
    "dane_code": 13001000,
    "name_depto": "BOLIVAR",
    "name_city": "CARTAGENA"
  },
  {
    "id": 555,
    "zip_code": 130014,
    "dane_code": 13001000,
    "name_depto": "BOLIVAR",
    "name_city": "CARTAGENA"
  },
  {
    "id": 556,
    "zip_code": 130015,
    "dane_code": 13001000,
    "name_depto": "BOLIVAR",
    "name_city": "CARTAGENA"
  },
  {
    "id": 557,
    "zip_code": 130017,
    "dane_code": 13001000,
    "name_depto": "BOLIVAR",
    "name_city": "CARTAGENA"
  },
  {
    "id": 558,
    "zip_code": 130018,
    "dane_code": 13001000,
    "name_depto": "BOLIVAR",
    "name_city": "CARTAGENA"
  },
  {
    "id": 559,
    "zip_code": 130019,
    "dane_code": 13001000,
    "name_depto": "BOLIVAR",
    "name_city": "CARTAGENA"
  },
  {
    "id": 560,
    "zip_code": 130027,
    "dane_code": 13001000,
    "name_depto": "BOLIVAR",
    "name_city": "CARTAGENA"
  },
  {
    "id": 561,
    "zip_code": 130501,
    "dane_code": 13673000,
    "name_depto": "BOLIVAR",
    "name_city": "SANTA CATALINA"
  },
  {
    "id": 562,
    "zip_code": 130507,
    "dane_code": 13673000,
    "name_depto": "BOLIVAR",
    "name_city": "SANTA CATALINA"
  },
  {
    "id": 563,
    "zip_code": 130508,
    "dane_code": 13673000,
    "name_depto": "BOLIVAR",
    "name_city": "SANTA CATALINA"
  },
  {
    "id": 564,
    "zip_code": 130510,
    "dane_code": 13222000,
    "name_depto": "BOLIVAR",
    "name_city": "CLEMENCIA"
  },
  {
    "id": 565,
    "zip_code": 130517,
    "dane_code": 13222000,
    "name_depto": "BOLIVAR",
    "name_city": "CLEMENCIA"
  },
  {
    "id": 566,
    "zip_code": 130520,
    "dane_code": 13683000,
    "name_depto": "BOLIVAR",
    "name_city": "SANTA ROSA"
  },
  {
    "id": 567,
    "zip_code": 130527,
    "dane_code": 13683000,
    "name_depto": "BOLIVAR",
    "name_city": "SANTA ROSA"
  },
  {
    "id": 568,
    "zip_code": 130528,
    "dane_code": 13683000,
    "name_depto": "BOLIVAR",
    "name_city": "SANTA ROSA"
  },
  {
    "id": 569,
    "zip_code": 130530,
    "dane_code": 13873000,
    "name_depto": "BOLIVAR",
    "name_city": "VILLANUEVA"
  },
  {
    "id": 570,
    "zip_code": 130537,
    "dane_code": 13873000,
    "name_depto": "BOLIVAR",
    "name_city": "VILLANUEVA"
  },
  {
    "id": 571,
    "zip_code": 130540,
    "dane_code": 13647000,
    "name_depto": "BOLIVAR",
    "name_city": "SAN ESTANISLAO"
  },
  {
    "id": 572,
    "zip_code": 130547,
    "dane_code": 13647000,
    "name_depto": "BOLIVAR",
    "name_city": "SAN ESTANISLAO"
  },
  {
    "id": 573,
    "zip_code": 131001,
    "dane_code": 13836000,
    "name_depto": "BOLIVAR",
    "name_city": "TURBACO"
  },
  {
    "id": 574,
    "zip_code": 131007,
    "dane_code": 13836000,
    "name_depto": "BOLIVAR",
    "name_city": "TURBACO"
  },
  {
    "id": 575,
    "zip_code": 131008,
    "dane_code": 13836000,
    "name_depto": "BOLIVAR",
    "name_city": "TURBACO"
  },
  {
    "id": 576,
    "zip_code": 131010,
    "dane_code": 13838000,
    "name_depto": "BOLIVAR",
    "name_city": "TURBANA"
  },
  {
    "id": 577,
    "zip_code": 131017,
    "dane_code": 13838000,
    "name_depto": "BOLIVAR",
    "name_city": "TURBANA"
  },
  {
    "id": 578,
    "zip_code": 131020,
    "dane_code": 13052000,
    "name_depto": "BOLIVAR",
    "name_city": "ARJONA"
  },
  {
    "id": 579,
    "zip_code": 131027,
    "dane_code": 13052000,
    "name_depto": "BOLIVAR",
    "name_city": "ARJONA"
  },
  {
    "id": 580,
    "zip_code": 131028,
    "dane_code": 13052000,
    "name_depto": "BOLIVAR",
    "name_city": "ARJONA"
  },
  {
    "id": 581,
    "zip_code": 131029,
    "dane_code": 13052000,
    "name_depto": "BOLIVAR",
    "name_city": "ARJONA"
  },
  {
    "id": 582,
    "zip_code": 131040,
    "dane_code": 13433000,
    "name_depto": "BOLIVAR",
    "name_city": "MAHATES"
  },
  {
    "id": 583,
    "zip_code": 131047,
    "dane_code": 13433000,
    "name_depto": "BOLIVAR",
    "name_city": "MAHATES"
  },
  {
    "id": 584,
    "zip_code": 131048,
    "dane_code": 13433000,
    "name_depto": "BOLIVAR",
    "name_city": "MAHATES"
  },
  {
    "id": 585,
    "zip_code": 131060,
    "dane_code": 13442000,
    "name_depto": "BOLIVAR",
    "name_city": "MARIA LA BAJA"
  },
  {
    "id": 586,
    "zip_code": 131067,
    "dane_code": 13442000,
    "name_depto": "BOLIVAR",
    "name_city": "MARIA LA BAJA"
  },
  {
    "id": 587,
    "zip_code": 131068,
    "dane_code": 13442000,
    "name_depto": "BOLIVAR",
    "name_city": "MARIA LA BAJA"
  },
  {
    "id": 588,
    "zip_code": 131069,
    "dane_code": 13442000,
    "name_depto": "BOLIVAR",
    "name_city": "MARIA LA BAJA"
  },
  {
    "id": 589,
    "zip_code": 131077,
    "dane_code": 13442000,
    "name_depto": "BOLIVAR",
    "name_city": "MARIA LA BAJA"
  },
  {
    "id": 590,
    "zip_code": 131501,
    "dane_code": 13760000,
    "name_depto": "BOLIVAR",
    "name_city": "SOPLAVIENTO"
  },
  {
    "id": 591,
    "zip_code": 131507,
    "dane_code": 13760000,
    "name_depto": "BOLIVAR",
    "name_city": "SOPLAVIENTO"
  },
  {
    "id": 592,
    "zip_code": 131520,
    "dane_code": 13620000,
    "name_depto": "BOLIVAR",
    "name_city": "SAN CRISTOBAL"
  },
  {
    "id": 593,
    "zip_code": 131527,
    "dane_code": 13620000,
    "name_depto": "BOLIVAR",
    "name_city": "SAN CRISTOBAL"
  },
  {
    "id": 594,
    "zip_code": 131540,
    "dane_code": 13140000,
    "name_depto": "BOLIVAR",
    "name_city": "CALAMAR"
  },
  {
    "id": 595,
    "zip_code": 131547,
    "dane_code": 13140000,
    "name_depto": "BOLIVAR",
    "name_city": "CALAMAR"
  },
  {
    "id": 596,
    "zip_code": 131548,
    "dane_code": 13140000,
    "name_depto": "BOLIVAR",
    "name_city": "CALAMAR"
  },
  {
    "id": 597,
    "zip_code": 131560,
    "dane_code": 13062000,
    "name_depto": "BOLIVAR",
    "name_city": "ARROYOHONDO"
  },
  {
    "id": 598,
    "zip_code": 131567,
    "dane_code": 13062000,
    "name_depto": "BOLIVAR",
    "name_city": "ARROYOHONDO"
  },
  {
    "id": 599,
    "zip_code": 132001,
    "dane_code": 13248000,
    "name_depto": "BOLIVAR",
    "name_city": "EL GUAMO"
  },
  {
    "id": 600,
    "zip_code": 132007,
    "dane_code": 13248000,
    "name_depto": "BOLIVAR",
    "name_city": "EL GUAMO"
  },
  {
    "id": 601,
    "zip_code": 132010,
    "dane_code": 13657000,
    "name_depto": "BOLIVAR",
    "name_city": "SAN JUAN NEPOMUCENO"
  },
  {
    "id": 602,
    "zip_code": 132017,
    "dane_code": 13657000,
    "name_depto": "BOLIVAR",
    "name_city": "SAN JUAN NEPOMUCENO"
  },
  {
    "id": 603,
    "zip_code": 132018,
    "dane_code": 13657000,
    "name_depto": "BOLIVAR",
    "name_city": "SAN JUAN NEPOMUCENO"
  },
  {
    "id": 604,
    "zip_code": 132030,
    "dane_code": 13654000,
    "name_depto": "BOLIVAR",
    "name_city": "SAN JACINTO"
  },
  {
    "id": 605,
    "zip_code": 132037,
    "dane_code": 13654000,
    "name_depto": "BOLIVAR",
    "name_city": "SAN JACINTO"
  },
  {
    "id": 606,
    "zip_code": 132040,
    "dane_code": 13894000,
    "name_depto": "BOLIVAR",
    "name_city": "ZAMBRANO"
  },
  {
    "id": 607,
    "zip_code": 132047,
    "dane_code": 13894000,
    "name_depto": "BOLIVAR",
    "name_city": "ZAMBRANO"
  },
  {
    "id": 608,
    "zip_code": 132050,
    "dane_code": 13244000,
    "name_depto": "BOLIVAR",
    "name_city": "EL CARMEN DE BOLIVAR"
  },
  {
    "id": 609,
    "zip_code": 132057,
    "dane_code": 13244000,
    "name_depto": "BOLIVAR",
    "name_city": "EL CARMEN DE BOLIVAR"
  },
  {
    "id": 610,
    "zip_code": 132058,
    "dane_code": 13244000,
    "name_depto": "BOLIVAR",
    "name_city": "EL CARMEN DE BOLIVAR"
  },
  {
    "id": 611,
    "zip_code": 132059,
    "dane_code": 13244000,
    "name_depto": "BOLIVAR",
    "name_city": "EL CARMEN DE BOLIVAR"
  },
  {
    "id": 612,
    "zip_code": 132501,
    "dane_code": 13212000,
    "name_depto": "BOLIVAR",
    "name_city": "CORDOBA"
  },
  {
    "id": 613,
    "zip_code": 132507,
    "dane_code": 13212000,
    "name_depto": "BOLIVAR",
    "name_city": "CORDOBA"
  },
  {
    "id": 614,
    "zip_code": 132508,
    "dane_code": 13212000,
    "name_depto": "BOLIVAR",
    "name_city": "CORDOBA"
  },
  {
    "id": 615,
    "zip_code": 132511,
    "dane_code": 13430000,
    "name_depto": "BOLIVAR",
    "name_city": "MAGANGUE"
  },
  {
    "id": 616,
    "zip_code": 132512,
    "dane_code": 13430000,
    "name_depto": "BOLIVAR",
    "name_city": "MAGANGUE"
  },
  {
    "id": 617,
    "zip_code": 132517,
    "dane_code": 13430000,
    "name_depto": "BOLIVAR",
    "name_city": "MAGANGUE"
  },
  {
    "id": 618,
    "zip_code": 132518,
    "dane_code": 13430000,
    "name_depto": "BOLIVAR",
    "name_city": "MAGANGUE"
  },
  {
    "id": 619,
    "zip_code": 132519,
    "dane_code": 13430000,
    "name_depto": "BOLIVAR",
    "name_city": "MAGANGUE"
  },
  {
    "id": 620,
    "zip_code": 132527,
    "dane_code": 13430000,
    "name_depto": "BOLIVAR",
    "name_city": "MAGANGUE"
  },
  {
    "id": 621,
    "zip_code": 132540,
    "dane_code": 13780000,
    "name_depto": "BOLIVAR",
    "name_city": "TALAIGUA NUEVO"
  },
  {
    "id": 622,
    "zip_code": 132547,
    "dane_code": 13780000,
    "name_depto": "BOLIVAR",
    "name_city": "TALAIGUA NUEVO"
  },
  {
    "id": 623,
    "zip_code": 132548,
    "dane_code": 13780000,
    "name_depto": "BOLIVAR",
    "name_city": "TALAIGUA NUEVO"
  },
  {
    "id": 624,
    "zip_code": 132550,
    "dane_code": 13188000,
    "name_depto": "BOLIVAR",
    "name_city": "CICUCO"
  },
  {
    "id": 625,
    "zip_code": 132557,
    "dane_code": 13188000,
    "name_depto": "BOLIVAR",
    "name_city": "CICUCO"
  },
  {
    "id": 626,
    "zip_code": 132560,
    "dane_code": 13468000,
    "name_depto": "BOLIVAR",
    "name_city": "MOMPOS"
  },
  {
    "id": 627,
    "zip_code": 132567,
    "dane_code": 13468000,
    "name_depto": "BOLIVAR",
    "name_city": "MOMPOS"
  },
  {
    "id": 628,
    "zip_code": 132568,
    "dane_code": 13468000,
    "name_depto": "BOLIVAR",
    "name_city": "MOMPOS"
  },
  {
    "id": 629,
    "zip_code": 133001,
    "dane_code": 13650000,
    "name_depto": "BOLIVAR",
    "name_city": "SAN FERNANDO"
  },
  {
    "id": 630,
    "zip_code": 133007,
    "dane_code": 13650000,
    "name_depto": "BOLIVAR",
    "name_city": "SAN FERNANDO"
  },
  {
    "id": 631,
    "zip_code": 133008,
    "dane_code": 13650000,
    "name_depto": "BOLIVAR",
    "name_city": "SAN FERNANDO"
  },
  {
    "id": 632,
    "zip_code": 133020,
    "dane_code": 13440000,
    "name_depto": "BOLIVAR",
    "name_city": "MARGARITA"
  },
  {
    "id": 633,
    "zip_code": 133027,
    "dane_code": 13440000,
    "name_depto": "BOLIVAR",
    "name_city": "MARGARITA"
  },
  {
    "id": 634,
    "zip_code": 133028,
    "dane_code": 13440000,
    "name_depto": "BOLIVAR",
    "name_city": "MARGARITA"
  },
  {
    "id": 635,
    "zip_code": 133040,
    "dane_code": 13300000,
    "name_depto": "BOLIVAR",
    "name_city": "HATILLO DE LOBA"
  },
  {
    "id": 636,
    "zip_code": 133047,
    "dane_code": 13300000,
    "name_depto": "BOLIVAR",
    "name_city": "HATILLO DE LOBA"
  },
  {
    "id": 637,
    "zip_code": 133048,
    "dane_code": 13300000,
    "name_depto": "BOLIVAR",
    "name_city": "HATILLO DE LOBA"
  },
  {
    "id": 638,
    "zip_code": 133049,
    "dane_code": 13300000,
    "name_depto": "BOLIVAR",
    "name_city": "HATILLO DE LOBA"
  },
  {
    "id": 639,
    "zip_code": 133501,
    "dane_code": 13030000,
    "name_depto": "BOLIVAR",
    "name_city": "ALTOS DEL ROSARIO"
  },
  {
    "id": 640,
    "zip_code": 133507,
    "dane_code": 13030000,
    "name_depto": "BOLIVAR",
    "name_city": "ALTOS DEL ROSARIO"
  },
  {
    "id": 641,
    "zip_code": 133508,
    "dane_code": 13030000,
    "name_depto": "BOLIVAR",
    "name_city": "ALTOS DEL ROSARIO"
  },
  {
    "id": 642,
    "zip_code": 133510,
    "dane_code": 13074000,
    "name_depto": "BOLIVAR",
    "name_city": "BARRANCO DE LOBA"
  },
  {
    "id": 643,
    "zip_code": 133517,
    "dane_code": 13074000,
    "name_depto": "BOLIVAR",
    "name_city": "BARRANCO DE LOBA"
  },
  {
    "id": 644,
    "zip_code": 133518,
    "dane_code": 13074000,
    "name_depto": "BOLIVAR",
    "name_city": "BARRANCO DE LOBA"
  },
  {
    "id": 645,
    "zip_code": 133530,
    "dane_code": 13667000,
    "name_depto": "BOLIVAR",
    "name_city": "SAN MARTIN DE LOBA"
  },
  {
    "id": 646,
    "zip_code": 133537,
    "dane_code": 13667000,
    "name_depto": "BOLIVAR",
    "name_city": "SAN MARTIN DE LOBA"
  },
  {
    "id": 647,
    "zip_code": 133538,
    "dane_code": 13667000,
    "name_depto": "BOLIVAR",
    "name_city": "SAN MARTIN DE LOBA"
  },
  {
    "id": 648,
    "zip_code": 133550,
    "dane_code": 13268000,
    "name_depto": "BOLIVAR",
    "name_city": "EL PE√ëON"
  },
  {
    "id": 649,
    "zip_code": 133557,
    "dane_code": 13268000,
    "name_depto": "BOLIVAR",
    "name_city": "EL PE√ëON"
  },
  {
    "id": 650,
    "zip_code": 133558,
    "dane_code": 13268000,
    "name_depto": "BOLIVAR",
    "name_city": "EL PE√ëON"
  },
  {
    "id": 651,
    "zip_code": 133560,
    "dane_code": 13580000,
    "name_depto": "BOLIVAR",
    "name_city": "REGIDOR"
  },
  {
    "id": 652,
    "zip_code": 133567,
    "dane_code": 13580000,
    "name_depto": "BOLIVAR",
    "name_city": "REGIDOR"
  },
  {
    "id": 653,
    "zip_code": 134001,
    "dane_code": 13549000,
    "name_depto": "BOLIVAR",
    "name_city": "PINILLOS"
  },
  {
    "id": 654,
    "zip_code": 134007,
    "dane_code": 13549000,
    "name_depto": "BOLIVAR",
    "name_city": "PINILLOS"
  },
  {
    "id": 655,
    "zip_code": 134008,
    "dane_code": 13549000,
    "name_depto": "BOLIVAR",
    "name_city": "PINILLOS"
  },
  {
    "id": 656,
    "zip_code": 134020,
    "dane_code": 13006000,
    "name_depto": "BOLIVAR",
    "name_city": "ACHI"
  },
  {
    "id": 657,
    "zip_code": 134027,
    "dane_code": 13006000,
    "name_depto": "BOLIVAR",
    "name_city": "ACHI"
  },
  {
    "id": 658,
    "zip_code": 134028,
    "dane_code": 13006000,
    "name_depto": "BOLIVAR",
    "name_city": "ACHI"
  },
  {
    "id": 659,
    "zip_code": 134040,
    "dane_code": 13810000,
    "name_depto": "BOLIVAR",
    "name_city": "TIQUISIO"
  },
  {
    "id": 660,
    "zip_code": 134047,
    "dane_code": 13810000,
    "name_depto": "BOLIVAR",
    "name_city": "TIQUISIO"
  },
  {
    "id": 661,
    "zip_code": 134048,
    "dane_code": 13810000,
    "name_depto": "BOLIVAR",
    "name_city": "TIQUISIO"
  },
  {
    "id": 662,
    "zip_code": 134060,
    "dane_code": 13655000,
    "name_depto": "BOLIVAR",
    "name_city": "SAN JACINTO DEL CAUCA"
  },
  {
    "id": 663,
    "zip_code": 134067,
    "dane_code": 13655000,
    "name_depto": "BOLIVAR",
    "name_city": "SAN JACINTO DEL CAUCA"
  },
  {
    "id": 664,
    "zip_code": 134068,
    "dane_code": 13655000,
    "name_depto": "BOLIVAR",
    "name_city": "SAN JACINTO DEL CAUCA"
  },
  {
    "id": 665,
    "zip_code": 134070,
    "dane_code": 13458000,
    "name_depto": "BOLIVAR",
    "name_city": "MONTECRISTO"
  },
  {
    "id": 666,
    "zip_code": 134077,
    "dane_code": 13458000,
    "name_depto": "BOLIVAR",
    "name_city": "MONTECRISTO"
  },
  {
    "id": 667,
    "zip_code": 134501,
    "dane_code": 13600000,
    "name_depto": "BOLIVAR",
    "name_city": "RIO VIEJO"
  },
  {
    "id": 668,
    "zip_code": 134507,
    "dane_code": 13600000,
    "name_depto": "BOLIVAR",
    "name_city": "RIO VIEJO"
  },
  {
    "id": 669,
    "zip_code": 134510,
    "dane_code": 13490000,
    "name_depto": "BOLIVAR",
    "name_city": "NOROSI"
  },
  {
    "id": 670,
    "zip_code": 134517,
    "dane_code": 13490000,
    "name_depto": "BOLIVAR",
    "name_city": "NOROSI"
  },
  {
    "id": 671,
    "zip_code": 134520,
    "dane_code": 13042000,
    "name_depto": "BOLIVAR",
    "name_city": "ARENAL"
  },
  {
    "id": 672,
    "zip_code": 134527,
    "dane_code": 13042000,
    "name_depto": "BOLIVAR",
    "name_city": "ARENAL"
  },
  {
    "id": 673,
    "zip_code": 134540,
    "dane_code": 13473000,
    "name_depto": "BOLIVAR",
    "name_city": "MORALES"
  },
  {
    "id": 674,
    "zip_code": 134547,
    "dane_code": 13473000,
    "name_depto": "BOLIVAR",
    "name_city": "MORALES"
  },
  {
    "id": 675,
    "zip_code": 134548,
    "dane_code": 13473000,
    "name_depto": "BOLIVAR",
    "name_city": "MORALES"
  },
  {
    "id": 676,
    "zip_code": 135001,
    "dane_code": 13688000,
    "name_depto": "BOLIVAR",
    "name_city": "SANTA ROSA DEL SUR"
  },
  {
    "id": 677,
    "zip_code": 135007,
    "dane_code": 13688000,
    "name_depto": "BOLIVAR",
    "name_city": "SANTA ROSA DEL SUR"
  },
  {
    "id": 678,
    "zip_code": 135008,
    "dane_code": 13688000,
    "name_depto": "BOLIVAR",
    "name_city": "SANTA ROSA DEL SUR"
  },
  {
    "id": 679,
    "zip_code": 135020,
    "dane_code": 13744000,
    "name_depto": "BOLIVAR",
    "name_city": "SIMITI"
  },
  {
    "id": 680,
    "zip_code": 135027,
    "dane_code": 13744000,
    "name_depto": "BOLIVAR",
    "name_city": "SIMITI"
  },
  {
    "id": 681,
    "zip_code": 135028,
    "dane_code": 13744000,
    "name_depto": "BOLIVAR",
    "name_city": "SIMITI"
  },
  {
    "id": 682,
    "zip_code": 135040,
    "dane_code": 13670000,
    "name_depto": "BOLIVAR",
    "name_city": "SAN PABLO"
  },
  {
    "id": 683,
    "zip_code": 135047,
    "dane_code": 13670000,
    "name_depto": "BOLIVAR",
    "name_city": "SAN PABLO"
  },
  {
    "id": 684,
    "zip_code": 135048,
    "dane_code": 13670000,
    "name_depto": "BOLIVAR",
    "name_city": "SAN PABLO"
  },
  {
    "id": 685,
    "zip_code": 135060,
    "dane_code": 13160000,
    "name_depto": "BOLIVAR",
    "name_city": "CANTAGALLO"
  },
  {
    "id": 686,
    "zip_code": 135067,
    "dane_code": 13160000,
    "name_depto": "BOLIVAR",
    "name_city": "CANTAGALLO"
  },
  {
    "id": 687,
    "zip_code": 150001,
    "dane_code": 15001000,
    "name_depto": "BOYACA",
    "name_city": "TUNJA"
  },
  {
    "id": 688,
    "zip_code": 150002,
    "dane_code": 15001000,
    "name_depto": "BOYACA",
    "name_city": "TUNJA"
  },
  {
    "id": 689,
    "zip_code": 150003,
    "dane_code": 15001000,
    "name_depto": "BOYACA",
    "name_city": "TUNJA"
  },
  {
    "id": 690,
    "zip_code": 150007,
    "dane_code": 15001000,
    "name_depto": "BOYACA",
    "name_city": "TUNJA"
  },
  {
    "id": 691,
    "zip_code": 150008,
    "dane_code": 15001000,
    "name_depto": "BOYACA",
    "name_city": "TUNJA"
  },
  {
    "id": 692,
    "zip_code": 150009,
    "dane_code": 15001000,
    "name_depto": "BOYACA",
    "name_city": "TUNJA"
  },
  {
    "id": 693,
    "zip_code": 150201,
    "dane_code": 15204000,
    "name_depto": "BOYACA",
    "name_city": "COMBITA"
  },
  {
    "id": 694,
    "zip_code": 150207,
    "dane_code": 15204000,
    "name_depto": "BOYACA",
    "name_city": "COMBITA"
  },
  {
    "id": 695,
    "zip_code": 150208,
    "dane_code": 15204000,
    "name_depto": "BOYACA",
    "name_city": "COMBITA"
  },
  {
    "id": 696,
    "zip_code": 150220,
    "dane_code": 15500000,
    "name_depto": "BOYACA",
    "name_city": "OICATA"
  },
  {
    "id": 697,
    "zip_code": 150227,
    "dane_code": 15500000,
    "name_depto": "BOYACA",
    "name_city": "OICATA"
  },
  {
    "id": 698,
    "zip_code": 150240,
    "dane_code": 15187000,
    "name_depto": "BOYACA",
    "name_city": "CHIVATA"
  },
  {
    "id": 699,
    "zip_code": 150247,
    "dane_code": 15187000,
    "name_depto": "BOYACA",
    "name_city": "CHIVATA"
  },
  {
    "id": 700,
    "zip_code": 150260,
    "dane_code": 15814000,
    "name_depto": "BOYACA",
    "name_city": "TOCA"
  },
  {
    "id": 701,
    "zip_code": 150267,
    "dane_code": 15814000,
    "name_depto": "BOYACA",
    "name_city": "TOCA"
  },
  {
    "id": 702,
    "zip_code": 150268,
    "dane_code": 15814000,
    "name_depto": "BOYACA",
    "name_city": "TOCA"
  },
  {
    "id": 703,
    "zip_code": 150401,
    "dane_code": 15837000,
    "name_depto": "BOYACA",
    "name_city": "TUTA"
  },
  {
    "id": 704,
    "zip_code": 150407,
    "dane_code": 15837000,
    "name_depto": "BOYACA",
    "name_city": "TUTA"
  },
  {
    "id": 705,
    "zip_code": 150408,
    "dane_code": 15837000,
    "name_depto": "BOYACA",
    "name_city": "TUTA"
  },
  {
    "id": 706,
    "zip_code": 150420,
    "dane_code": 15763000,
    "name_depto": "BOYACA",
    "name_city": "SOTAQUIRA"
  },
  {
    "id": 707,
    "zip_code": 150427,
    "dane_code": 15763000,
    "name_depto": "BOYACA",
    "name_city": "SOTAQUIRA"
  },
  {
    "id": 708,
    "zip_code": 150428,
    "dane_code": 15763000,
    "name_depto": "BOYACA",
    "name_city": "SOTAQUIRA"
  },
  {
    "id": 709,
    "zip_code": 150440,
    "dane_code": 15516000,
    "name_depto": "BOYACA",
    "name_city": "PAIPA"
  },
  {
    "id": 710,
    "zip_code": 150447,
    "dane_code": 15516000,
    "name_depto": "BOYACA",
    "name_city": "PAIPA"
  },
  {
    "id": 711,
    "zip_code": 150448,
    "dane_code": 15516000,
    "name_depto": "BOYACA",
    "name_city": "PAIPA"
  },
  {
    "id": 712,
    "zip_code": 150449,
    "dane_code": 15516000,
    "name_depto": "BOYACA",
    "name_city": "PAIPA"
  },
  {
    "id": 713,
    "zip_code": 150461,
    "dane_code": 15238000,
    "name_depto": "BOYACA",
    "name_city": "DUITAMA"
  },
  {
    "id": 714,
    "zip_code": 150462,
    "dane_code": 15238000,
    "name_depto": "BOYACA",
    "name_city": "DUITAMA"
  },
  {
    "id": 715,
    "zip_code": 150467,
    "dane_code": 15238000,
    "name_depto": "BOYACA",
    "name_city": "DUITAMA"
  },
  {
    "id": 716,
    "zip_code": 150468,
    "dane_code": 15238000,
    "name_depto": "BOYACA",
    "name_city": "DUITAMA"
  },
  {
    "id": 717,
    "zip_code": 150469,
    "dane_code": 15238000,
    "name_depto": "BOYACA",
    "name_city": "DUITAMA"
  },
  {
    "id": 718,
    "zip_code": 150477,
    "dane_code": 15238000,
    "name_depto": "BOYACA",
    "name_city": "DUITAMA"
  },
  {
    "id": 719,
    "zip_code": 150480,
    "dane_code": 15693000,
    "name_depto": "BOYACA",
    "name_city": "SANTA ROSA DE VITERBO"
  },
  {
    "id": 720,
    "zip_code": 150487,
    "dane_code": 15693000,
    "name_depto": "BOYACA",
    "name_city": "SANTA ROSA DE VITERBO"
  },
  {
    "id": 721,
    "zip_code": 150488,
    "dane_code": 15693000,
    "name_depto": "BOYACA",
    "name_city": "SANTA ROSA DE VITERBO"
  },
  {
    "id": 722,
    "zip_code": 150601,
    "dane_code": 15276000,
    "name_depto": "BOYACA",
    "name_city": "FLORESTA"
  },
  {
    "id": 723,
    "zip_code": 150607,
    "dane_code": 15276000,
    "name_depto": "BOYACA",
    "name_city": "FLORESTA"
  },
  {
    "id": 724,
    "zip_code": 150610,
    "dane_code": 15092000,
    "name_depto": "BOYACA",
    "name_city": "BETEITIVA"
  },
  {
    "id": 725,
    "zip_code": 150617,
    "dane_code": 15092000,
    "name_depto": "BOYACA",
    "name_city": "BETEITIVA"
  },
  {
    "id": 726,
    "zip_code": 150620,
    "dane_code": 15162000,
    "name_depto": "BOYACA",
    "name_city": "CERINZA"
  },
  {
    "id": 727,
    "zip_code": 150627,
    "dane_code": 15162000,
    "name_depto": "BOYACA",
    "name_city": "CERINZA"
  },
  {
    "id": 728,
    "zip_code": 150640,
    "dane_code": 15087000,
    "name_depto": "BOYACA",
    "name_city": "BELEN"
  },
  {
    "id": 729,
    "zip_code": 150647,
    "dane_code": 15087000,
    "name_depto": "BOYACA",
    "name_city": "BELEN"
  },
  {
    "id": 730,
    "zip_code": 150660,
    "dane_code": 15839000,
    "name_depto": "BOYACA",
    "name_city": "TUTAZA"
  },
  {
    "id": 731,
    "zip_code": 150667,
    "dane_code": 15839000,
    "name_depto": "BOYACA",
    "name_city": "TUTAZA"
  },
  {
    "id": 732,
    "zip_code": 150680,
    "dane_code": 15537000,
    "name_depto": "BOYACA",
    "name_city": "PAZ DE RIO"
  },
  {
    "id": 733,
    "zip_code": 150687,
    "dane_code": 15537000,
    "name_depto": "BOYACA",
    "name_city": "PAZ DE RIO"
  },
  {
    "id": 734,
    "zip_code": 150801,
    "dane_code": 15723000,
    "name_depto": "BOYACA",
    "name_city": "SATIVASUR"
  },
  {
    "id": 735,
    "zip_code": 150807,
    "dane_code": 15723000,
    "name_depto": "BOYACA",
    "name_city": "SATIVASUR"
  },
  {
    "id": 736,
    "zip_code": 150820,
    "dane_code": 15720000,
    "name_depto": "BOYACA",
    "name_city": "SATIVANORTE"
  },
  {
    "id": 737,
    "zip_code": 150827,
    "dane_code": 15720000,
    "name_depto": "BOYACA",
    "name_city": "SATIVANORTE"
  },
  {
    "id": 738,
    "zip_code": 150840,
    "dane_code": 15368000,
    "name_depto": "BOYACA",
    "name_city": "JERICO"
  },
  {
    "id": 739,
    "zip_code": 150847,
    "dane_code": 15368000,
    "name_depto": "BOYACA",
    "name_city": "JERICO"
  },
  {
    "id": 740,
    "zip_code": 150860,
    "dane_code": 15403000,
    "name_depto": "BOYACA",
    "name_city": "LA UVITA"
  },
  {
    "id": 741,
    "zip_code": 150867,
    "dane_code": 15403000,
    "name_depto": "BOYACA",
    "name_city": "LA UVITA"
  },
  {
    "id": 742,
    "zip_code": 150880,
    "dane_code": 15774000,
    "name_depto": "BOYACA",
    "name_city": "SUSACON"
  },
  {
    "id": 743,
    "zip_code": 150887,
    "dane_code": 15774000,
    "name_depto": "BOYACA",
    "name_city": "SUSACON"
  },
  {
    "id": 744,
    "zip_code": 151001,
    "dane_code": 15753000,
    "name_depto": "BOYACA",
    "name_city": "SOATA"
  },
  {
    "id": 745,
    "zip_code": 151007,
    "dane_code": 15753000,
    "name_depto": "BOYACA",
    "name_city": "SOATA"
  },
  {
    "id": 746,
    "zip_code": 151020,
    "dane_code": 15810000,
    "name_depto": "BOYACA",
    "name_city": "TIPACOQUE"
  },
  {
    "id": 747,
    "zip_code": 151027,
    "dane_code": 15810000,
    "name_depto": "BOYACA",
    "name_city": "TIPACOQUE"
  },
  {
    "id": 748,
    "zip_code": 151040,
    "dane_code": 15218000,
    "name_depto": "BOYACA",
    "name_city": "COVARACHIA"
  },
  {
    "id": 749,
    "zip_code": 151047,
    "dane_code": 15218000,
    "name_depto": "BOYACA",
    "name_city": "COVARACHIA"
  },
  {
    "id": 750,
    "zip_code": 151060,
    "dane_code": 15097000,
    "name_depto": "BOYACA",
    "name_city": "BOAVITA"
  },
  {
    "id": 751,
    "zip_code": 151067,
    "dane_code": 15097000,
    "name_depto": "BOYACA",
    "name_city": "BOAVITA"
  },
  {
    "id": 752,
    "zip_code": 151201,
    "dane_code": 15673000,
    "name_depto": "BOYACA",
    "name_city": "SAN MATEO"
  },
  {
    "id": 753,
    "zip_code": 151207,
    "dane_code": 15673000,
    "name_depto": "BOYACA",
    "name_city": "SAN MATEO"
  },
  {
    "id": 754,
    "zip_code": 151220,
    "dane_code": 15317000,
    "name_depto": "BOYACA",
    "name_city": "GUACAMAYAS"
  },
  {
    "id": 755,
    "zip_code": 151227,
    "dane_code": 15317000,
    "name_depto": "BOYACA",
    "name_city": "GUACAMAYAS"
  },
  {
    "id": 756,
    "zip_code": 151240,
    "dane_code": 15248000,
    "name_depto": "BOYACA",
    "name_city": "EL ESPINO"
  },
  {
    "id": 757,
    "zip_code": 151247,
    "dane_code": 15248000,
    "name_depto": "BOYACA",
    "name_city": "EL ESPINO"
  },
  {
    "id": 758,
    "zip_code": 151260,
    "dane_code": 15522000,
    "name_depto": "BOYACA",
    "name_city": "PANQUEBA"
  },
  {
    "id": 759,
    "zip_code": 151267,
    "dane_code": 15522000,
    "name_depto": "BOYACA",
    "name_city": "PANQUEBA"
  },
  {
    "id": 760,
    "zip_code": 151280,
    "dane_code": 15244000,
    "name_depto": "BOYACA",
    "name_city": "EL COCUY"
  },
  {
    "id": 761,
    "zip_code": 151287,
    "dane_code": 15244000,
    "name_depto": "BOYACA",
    "name_city": "EL COCUY"
  },
  {
    "id": 762,
    "zip_code": 151401,
    "dane_code": 15180000,
    "name_depto": "BOYACA",
    "name_city": "CHISCAS"
  },
  {
    "id": 763,
    "zip_code": 151407,
    "dane_code": 15180000,
    "name_depto": "BOYACA",
    "name_city": "CHISCAS"
  },
  {
    "id": 764,
    "zip_code": 151420,
    "dane_code": 15223000,
    "name_depto": "BOYACA",
    "name_city": "CUBARA"
  },
  {
    "id": 765,
    "zip_code": 151427,
    "dane_code": 15223000,
    "name_depto": "BOYACA",
    "name_city": "CUBARA"
  },
  {
    "id": 766,
    "zip_code": 151440,
    "dane_code": 15332000,
    "name_depto": "BOYACA",
    "name_city": "G√úICAN"
  },
  {
    "id": 767,
    "zip_code": 151447,
    "dane_code": 15332000,
    "name_depto": "BOYACA",
    "name_city": "G√úICAN"
  },
  {
    "id": 768,
    "zip_code": 151448,
    "dane_code": 15332000,
    "name_depto": "BOYACA",
    "name_city": "G√úICAN"
  },
  {
    "id": 769,
    "zip_code": 151601,
    "dane_code": 15183000,
    "name_depto": "BOYACA",
    "name_city": "CHITA"
  },
  {
    "id": 770,
    "zip_code": 151607,
    "dane_code": 15183000,
    "name_depto": "BOYACA",
    "name_city": "CHITA"
  },
  {
    "id": 771,
    "zip_code": 151608,
    "dane_code": 15183000,
    "name_depto": "BOYACA",
    "name_city": "CHITA"
  },
  {
    "id": 772,
    "zip_code": 151609,
    "dane_code": 15183000,
    "name_depto": "BOYACA",
    "name_city": "CHITA"
  },
  {
    "id": 773,
    "zip_code": 151620,
    "dane_code": 15755000,
    "name_depto": "BOYACA",
    "name_city": "SOCOTA"
  },
  {
    "id": 774,
    "zip_code": 151627,
    "dane_code": 15755000,
    "name_depto": "BOYACA",
    "name_city": "SOCOTA"
  },
  {
    "id": 775,
    "zip_code": 151628,
    "dane_code": 15755000,
    "name_depto": "BOYACA",
    "name_city": "SOCOTA"
  },
  {
    "id": 776,
    "zip_code": 151640,
    "dane_code": 15757000,
    "name_depto": "BOYACA",
    "name_city": "SOCHA"
  },
  {
    "id": 777,
    "zip_code": 151647,
    "dane_code": 15757000,
    "name_depto": "BOYACA",
    "name_city": "SOCHA"
  },
  {
    "id": 778,
    "zip_code": 151660,
    "dane_code": 15790000,
    "name_depto": "BOYACA",
    "name_city": "TASCO"
  },
  {
    "id": 779,
    "zip_code": 151667,
    "dane_code": 15790000,
    "name_depto": "BOYACA",
    "name_city": "TASCO"
  },
  {
    "id": 780,
    "zip_code": 151801,
    "dane_code": 15550000,
    "name_depto": "BOYACA",
    "name_city": "PISBA"
  },
  {
    "id": 781,
    "zip_code": 151807,
    "dane_code": 15550000,
    "name_depto": "BOYACA",
    "name_city": "PISBA"
  },
  {
    "id": 782,
    "zip_code": 151820,
    "dane_code": 15533000,
    "name_depto": "BOYACA",
    "name_city": "PAYA"
  },
  {
    "id": 783,
    "zip_code": 151827,
    "dane_code": 15533000,
    "name_depto": "BOYACA",
    "name_city": "PAYA"
  },
  {
    "id": 784,
    "zip_code": 151840,
    "dane_code": 15377000,
    "name_depto": "BOYACA",
    "name_city": "LABRANZAGRANDE"
  },
  {
    "id": 785,
    "zip_code": 151847,
    "dane_code": 15377000,
    "name_depto": "BOYACA",
    "name_city": "LABRANZAGRANDE"
  },
  {
    "id": 786,
    "zip_code": 152001,
    "dane_code": 15464000,
    "name_depto": "BOYACA",
    "name_city": "MONGUA"
  },
  {
    "id": 787,
    "zip_code": 152007,
    "dane_code": 15464000,
    "name_depto": "BOYACA",
    "name_city": "MONGUA"
  },
  {
    "id": 788,
    "zip_code": 152020,
    "dane_code": 15296000,
    "name_depto": "BOYACA",
    "name_city": "GAMEZA"
  },
  {
    "id": 789,
    "zip_code": 152027,
    "dane_code": 15296000,
    "name_depto": "BOYACA",
    "name_city": "GAMEZA"
  },
  {
    "id": 790,
    "zip_code": 152040,
    "dane_code": 15820000,
    "name_depto": "BOYACA",
    "name_city": "TOPAGA"
  },
  {
    "id": 791,
    "zip_code": 152047,
    "dane_code": 15820000,
    "name_depto": "BOYACA",
    "name_city": "TOPAGA"
  },
  {
    "id": 792,
    "zip_code": 152060,
    "dane_code": 15215000,
    "name_depto": "BOYACA",
    "name_city": "CORRALES"
  },
  {
    "id": 793,
    "zip_code": 152067,
    "dane_code": 15215000,
    "name_depto": "BOYACA",
    "name_city": "CORRALES"
  },
  {
    "id": 794,
    "zip_code": 152080,
    "dane_code": 15114000,
    "name_depto": "BOYACA",
    "name_city": "BUSBANZA"
  },
  {
    "id": 795,
    "zip_code": 152087,
    "dane_code": 15114000,
    "name_depto": "BOYACA",
    "name_city": "BUSBANZA"
  },
  {
    "id": 796,
    "zip_code": 152201,
    "dane_code": 15466000,
    "name_depto": "BOYACA",
    "name_city": "MONGUI"
  },
  {
    "id": 797,
    "zip_code": 152207,
    "dane_code": 15466000,
    "name_depto": "BOYACA",
    "name_city": "MONGUI"
  },
  {
    "id": 798,
    "zip_code": 152210,
    "dane_code": 15759000,
    "name_depto": "BOYACA",
    "name_city": "SOGAMOSO"
  },
  {
    "id": 799,
    "zip_code": 152211,
    "dane_code": 15759000,
    "name_depto": "BOYACA",
    "name_city": "SOGAMOSO"
  },
  {
    "id": 800,
    "zip_code": 152217,
    "dane_code": 15759000,
    "name_depto": "BOYACA",
    "name_city": "SOGAMOSO"
  },
  {
    "id": 801,
    "zip_code": 152218,
    "dane_code": 15759000,
    "name_depto": "BOYACA",
    "name_city": "SOGAMOSO"
  },
  {
    "id": 802,
    "zip_code": 152219,
    "dane_code": 15759000,
    "name_depto": "BOYACA",
    "name_city": "SOGAMOSO"
  },
  {
    "id": 803,
    "zip_code": 152230,
    "dane_code": 15226000,
    "name_depto": "BOYACA",
    "name_city": "CUITIVA"
  },
  {
    "id": 804,
    "zip_code": 152237,
    "dane_code": 15226000,
    "name_depto": "BOYACA",
    "name_city": "CUITIVA"
  },
  {
    "id": 805,
    "zip_code": 152240,
    "dane_code": 15362000,
    "name_depto": "BOYACA",
    "name_city": "IZA"
  },
  {
    "id": 806,
    "zip_code": 152247,
    "dane_code": 15362000,
    "name_depto": "BOYACA",
    "name_city": "IZA"
  },
  {
    "id": 807,
    "zip_code": 152250,
    "dane_code": 15272000,
    "name_depto": "BOYACA",
    "name_city": "FIRAVITOBA"
  },
  {
    "id": 808,
    "zip_code": 152257,
    "dane_code": 15272000,
    "name_depto": "BOYACA",
    "name_city": "FIRAVITOBA"
  },
  {
    "id": 809,
    "zip_code": 152260,
    "dane_code": 15806000,
    "name_depto": "BOYACA",
    "name_city": "TIBASOSA"
  },
  {
    "id": 810,
    "zip_code": 152267,
    "dane_code": 15806000,
    "name_depto": "BOYACA",
    "name_city": "TIBASOSA"
  },
  {
    "id": 811,
    "zip_code": 152268,
    "dane_code": 15806000,
    "name_depto": "BOYACA",
    "name_city": "TIBASOSA"
  },
  {
    "id": 812,
    "zip_code": 152280,
    "dane_code": 15491000,
    "name_depto": "BOYACA",
    "name_city": "NOBSA"
  },
  {
    "id": 813,
    "zip_code": 152287,
    "dane_code": 15491000,
    "name_depto": "BOYACA",
    "name_city": "NOBSA"
  },
  {
    "id": 814,
    "zip_code": 152288,
    "dane_code": 15491000,
    "name_depto": "BOYACA",
    "name_city": "NOBSA"
  },
  {
    "id": 815,
    "zip_code": 152401,
    "dane_code": 15518000,
    "name_depto": "BOYACA",
    "name_city": "PAJARITO"
  },
  {
    "id": 816,
    "zip_code": 152407,
    "dane_code": 15518000,
    "name_depto": "BOYACA",
    "name_city": "PAJARITO"
  },
  {
    "id": 817,
    "zip_code": 152420,
    "dane_code": 15047000,
    "name_depto": "BOYACA",
    "name_city": "AQUITANIA"
  },
  {
    "id": 818,
    "zip_code": 152427,
    "dane_code": 15047000,
    "name_depto": "BOYACA",
    "name_city": "AQUITANIA"
  },
  {
    "id": 819,
    "zip_code": 152428,
    "dane_code": 15047000,
    "name_depto": "BOYACA",
    "name_city": "AQUITANIA"
  },
  {
    "id": 820,
    "zip_code": 152429,
    "dane_code": 15047000,
    "name_depto": "BOYACA",
    "name_city": "AQUITANIA"
  },
  {
    "id": 821,
    "zip_code": 152440,
    "dane_code": 15822000,
    "name_depto": "BOYACA",
    "name_city": "TOTA"
  },
  {
    "id": 822,
    "zip_code": 152447,
    "dane_code": 15822000,
    "name_depto": "BOYACA",
    "name_city": "TOTA"
  },
  {
    "id": 823,
    "zip_code": 152448,
    "dane_code": 15822000,
    "name_depto": "BOYACA",
    "name_city": "TOTA"
  },
  {
    "id": 824,
    "zip_code": 152460,
    "dane_code": 15542000,
    "name_depto": "BOYACA",
    "name_city": "PESCA"
  },
  {
    "id": 825,
    "zip_code": 152467,
    "dane_code": 15542000,
    "name_depto": "BOYACA",
    "name_city": "PESCA"
  },
  {
    "id": 826,
    "zip_code": 152468,
    "dane_code": 15542000,
    "name_depto": "BOYACA",
    "name_city": "PESCA"
  },
  {
    "id": 827,
    "zip_code": 152469,
    "dane_code": 15542000,
    "name_depto": "BOYACA",
    "name_city": "PESCA"
  },
  {
    "id": 828,
    "zip_code": 152601,
    "dane_code": 15660000,
    "name_depto": "BOYACA",
    "name_city": "SAN EDUARDO"
  },
  {
    "id": 829,
    "zip_code": 152607,
    "dane_code": 15660000,
    "name_depto": "BOYACA",
    "name_city": "SAN EDUARDO"
  },
  {
    "id": 830,
    "zip_code": 152610,
    "dane_code": 15090000,
    "name_depto": "BOYACA",
    "name_city": "BERBEO"
  },
  {
    "id": 831,
    "zip_code": 152617,
    "dane_code": 15090000,
    "name_depto": "BOYACA",
    "name_city": "BERBEO"
  },
  {
    "id": 832,
    "zip_code": 152620,
    "dane_code": 15514000,
    "name_depto": "BOYACA",
    "name_city": "PAEZ"
  },
  {
    "id": 833,
    "zip_code": 152627,
    "dane_code": 15514000,
    "name_depto": "BOYACA",
    "name_city": "PAEZ"
  },
  {
    "id": 834,
    "zip_code": 152640,
    "dane_code": 15135000,
    "name_depto": "BOYACA",
    "name_city": "CAMPOHERMOSO"
  },
  {
    "id": 835,
    "zip_code": 152647,
    "dane_code": 15135000,
    "name_depto": "BOYACA",
    "name_city": "CAMPOHERMOSO"
  },
  {
    "id": 836,
    "zip_code": 152660,
    "dane_code": 15455000,
    "name_depto": "BOYACA",
    "name_city": "MIRAFLORES"
  },
  {
    "id": 837,
    "zip_code": 152667,
    "dane_code": 15455000,
    "name_depto": "BOYACA",
    "name_city": "MIRAFLORES"
  },
  {
    "id": 838,
    "zip_code": 152680,
    "dane_code": 15897000,
    "name_depto": "BOYACA",
    "name_city": "ZETAQUIRA"
  },
  {
    "id": 839,
    "zip_code": 152687,
    "dane_code": 15897000,
    "name_depto": "BOYACA",
    "name_city": "ZETAQUIRA"
  },
  {
    "id": 840,
    "zip_code": 152801,
    "dane_code": 15667000,
    "name_depto": "BOYACA",
    "name_city": "SAN LUIS DE GACENO"
  },
  {
    "id": 841,
    "zip_code": 152807,
    "dane_code": 15667000,
    "name_depto": "BOYACA",
    "name_city": "SAN LUIS DE GACENO"
  },
  {
    "id": 842,
    "zip_code": 152820,
    "dane_code": 15690000,
    "name_depto": "BOYACA",
    "name_city": "SANTA MARIA"
  },
  {
    "id": 843,
    "zip_code": 152827,
    "dane_code": 15690000,
    "name_depto": "BOYACA",
    "name_city": "SANTA MARIA"
  },
  {
    "id": 844,
    "zip_code": 152840,
    "dane_code": 15425000,
    "name_depto": "BOYACA",
    "name_city": "MACANAL"
  },
  {
    "id": 845,
    "zip_code": 152847,
    "dane_code": 15425000,
    "name_depto": "BOYACA",
    "name_city": "MACANAL"
  },
  {
    "id": 846,
    "zip_code": 152860,
    "dane_code": 15299000,
    "name_depto": "BOYACA",
    "name_city": "GARAGOA"
  },
  {
    "id": 847,
    "zip_code": 152867,
    "dane_code": 15299000,
    "name_depto": "BOYACA",
    "name_city": "GARAGOA"
  },
  {
    "id": 848,
    "zip_code": 153001,
    "dane_code": 15236000,
    "name_depto": "BOYACA",
    "name_city": "CHIVOR"
  },
  {
    "id": 849,
    "zip_code": 153007,
    "dane_code": 15236000,
    "name_depto": "BOYACA",
    "name_city": "CHIVOR"
  },
  {
    "id": 850,
    "zip_code": 153020,
    "dane_code": 15022000,
    "name_depto": "BOYACA",
    "name_city": "ALMEIDA"
  },
  {
    "id": 851,
    "zip_code": 153027,
    "dane_code": 15022000,
    "name_depto": "BOYACA",
    "name_city": "ALMEIDA"
  },
  {
    "id": 852,
    "zip_code": 153030,
    "dane_code": 15761000,
    "name_depto": "BOYACA",
    "name_city": "SOMONDOCO"
  },
  {
    "id": 853,
    "zip_code": 153037,
    "dane_code": 15761000,
    "name_depto": "BOYACA",
    "name_city": "SOMONDOCO"
  },
  {
    "id": 854,
    "zip_code": 153040,
    "dane_code": 15325000,
    "name_depto": "BOYACA",
    "name_city": "GUAYATA"
  },
  {
    "id": 855,
    "zip_code": 153047,
    "dane_code": 15325000,
    "name_depto": "BOYACA",
    "name_city": "GUAYATA"
  },
  {
    "id": 856,
    "zip_code": 153050,
    "dane_code": 15322000,
    "name_depto": "BOYACA",
    "name_city": "GUATEQUE"
  },
  {
    "id": 857,
    "zip_code": 153057,
    "dane_code": 15322000,
    "name_depto": "BOYACA",
    "name_city": "GUATEQUE"
  },
  {
    "id": 858,
    "zip_code": 153060,
    "dane_code": 15778000,
    "name_depto": "BOYACA",
    "name_city": "SUTATENZA"
  },
  {
    "id": 859,
    "zip_code": 153067,
    "dane_code": 15778000,
    "name_depto": "BOYACA",
    "name_city": "SUTATENZA"
  },
  {
    "id": 860,
    "zip_code": 153201,
    "dane_code": 15798000,
    "name_depto": "BOYACA",
    "name_city": "TENZA"
  },
  {
    "id": 861,
    "zip_code": 153207,
    "dane_code": 15798000,
    "name_depto": "BOYACA",
    "name_city": "TENZA"
  },
  {
    "id": 862,
    "zip_code": 153210,
    "dane_code": 15511000,
    "name_depto": "BOYACA",
    "name_city": "PACHAVITA"
  },
  {
    "id": 863,
    "zip_code": 153217,
    "dane_code": 15511000,
    "name_depto": "BOYACA",
    "name_city": "PACHAVITA"
  },
  {
    "id": 864,
    "zip_code": 153220,
    "dane_code": 15380000,
    "name_depto": "BOYACA",
    "name_city": "LA CAPILLA"
  },
  {
    "id": 865,
    "zip_code": 153227,
    "dane_code": 15380000,
    "name_depto": "BOYACA",
    "name_city": "LA CAPILLA"
  },
  {
    "id": 866,
    "zip_code": 153240,
    "dane_code": 15842000,
    "name_depto": "BOYACA",
    "name_city": "UMBITA"
  },
  {
    "id": 867,
    "zip_code": 153247,
    "dane_code": 15842000,
    "name_depto": "BOYACA",
    "name_city": "UMBITA"
  },
  {
    "id": 868,
    "zip_code": 153248,
    "dane_code": 15842000,
    "name_depto": "BOYACA",
    "name_city": "UMBITA"
  },
  {
    "id": 869,
    "zip_code": 153260,
    "dane_code": 15804000,
    "name_depto": "BOYACA",
    "name_city": "TIBANA"
  },
  {
    "id": 870,
    "zip_code": 153267,
    "dane_code": 15804000,
    "name_depto": "BOYACA",
    "name_city": "TIBANA"
  },
  {
    "id": 871,
    "zip_code": 153268,
    "dane_code": 15804000,
    "name_depto": "BOYACA",
    "name_city": "TIBANA"
  },
  {
    "id": 872,
    "zip_code": 153280,
    "dane_code": 15172000,
    "name_depto": "BOYACA",
    "name_city": "CHINAVITA"
  },
  {
    "id": 873,
    "zip_code": 153287,
    "dane_code": 15172000,
    "name_depto": "BOYACA",
    "name_city": "CHINAVITA"
  },
  {
    "id": 874,
    "zip_code": 153401,
    "dane_code": 15599000,
    "name_depto": "BOYACA",
    "name_city": "RAMIRIQUI"
  },
  {
    "id": 875,
    "zip_code": 153407,
    "dane_code": 15599000,
    "name_depto": "BOYACA",
    "name_city": "RAMIRIQUI"
  },
  {
    "id": 876,
    "zip_code": 153408,
    "dane_code": 15599000,
    "name_depto": "BOYACA",
    "name_city": "RAMIRIQUI"
  },
  {
    "id": 877,
    "zip_code": 153420,
    "dane_code": 15621000,
    "name_depto": "BOYACA",
    "name_city": "RONDON"
  },
  {
    "id": 878,
    "zip_code": 153427,
    "dane_code": 15621000,
    "name_depto": "BOYACA",
    "name_city": "RONDON"
  },
  {
    "id": 879,
    "zip_code": 153440,
    "dane_code": 15189000,
    "name_depto": "BOYACA",
    "name_city": "CIENEGA"
  },
  {
    "id": 880,
    "zip_code": 153447,
    "dane_code": 15189000,
    "name_depto": "BOYACA",
    "name_city": "CIENEGA"
  },
  {
    "id": 881,
    "zip_code": 153450,
    "dane_code": 15879000,
    "name_depto": "BOYACA",
    "name_city": "VIRACACHA"
  },
  {
    "id": 882,
    "zip_code": 153457,
    "dane_code": 15879000,
    "name_depto": "BOYACA",
    "name_city": "VIRACACHA"
  },
  {
    "id": 883,
    "zip_code": 153460,
    "dane_code": 15740000,
    "name_depto": "BOYACA",
    "name_city": "SIACHOQUE"
  },
  {
    "id": 884,
    "zip_code": 153467,
    "dane_code": 15740000,
    "name_depto": "BOYACA",
    "name_city": "SIACHOQUE"
  },
  {
    "id": 885,
    "zip_code": 153468,
    "dane_code": 15740000,
    "name_depto": "BOYACA",
    "name_city": "SIACHOQUE"
  },
  {
    "id": 886,
    "zip_code": 153480,
    "dane_code": 15764000,
    "name_depto": "BOYACA",
    "name_city": "SORACA"
  },
  {
    "id": 887,
    "zip_code": 153487,
    "dane_code": 15764000,
    "name_depto": "BOYACA",
    "name_city": "SORACA"
  },
  {
    "id": 888,
    "zip_code": 153601,
    "dane_code": 15367000,
    "name_depto": "BOYACA",
    "name_city": "JENESANO"
  },
  {
    "id": 889,
    "zip_code": 153607,
    "dane_code": 15367000,
    "name_depto": "BOYACA",
    "name_city": "JENESANO"
  },
  {
    "id": 890,
    "zip_code": 153608,
    "dane_code": 15367000,
    "name_depto": "BOYACA",
    "name_city": "JENESANO"
  },
  {
    "id": 891,
    "zip_code": 153610,
    "dane_code": 15104000,
    "name_depto": "BOYACA",
    "name_city": "BOYACA"
  },
  {
    "id": 892,
    "zip_code": 153617,
    "dane_code": 15104000,
    "name_depto": "BOYACA",
    "name_city": "BOYACA"
  },
  {
    "id": 893,
    "zip_code": 153620,
    "dane_code": 15494000,
    "name_depto": "BOYACA",
    "name_city": "NUEVO COLON"
  },
  {
    "id": 894,
    "zip_code": 153627,
    "dane_code": 15494000,
    "name_depto": "BOYACA",
    "name_city": "NUEVO COLON"
  },
  {
    "id": 895,
    "zip_code": 153630,
    "dane_code": 15835000,
    "name_depto": "BOYACA",
    "name_city": "TURMEQUE"
  },
  {
    "id": 896,
    "zip_code": 153637,
    "dane_code": 15835000,
    "name_depto": "BOYACA",
    "name_city": "TURMEQUE"
  },
  {
    "id": 897,
    "zip_code": 153640,
    "dane_code": 15861000,
    "name_depto": "BOYACA",
    "name_city": "VENTAQUEMADA"
  },
  {
    "id": 898,
    "zip_code": 153647,
    "dane_code": 15861000,
    "name_depto": "BOYACA",
    "name_city": "VENTAQUEMADA"
  },
  {
    "id": 899,
    "zip_code": 153648,
    "dane_code": 15861000,
    "name_depto": "BOYACA",
    "name_city": "VENTAQUEMADA"
  },
  {
    "id": 900,
    "zip_code": 153649,
    "dane_code": 15861000,
    "name_depto": "BOYACA",
    "name_city": "VENTAQUEMADA"
  },
  {
    "id": 901,
    "zip_code": 153660,
    "dane_code": 15646000,
    "name_depto": "BOYACA",
    "name_city": "SAMACA"
  },
  {
    "id": 902,
    "zip_code": 153667,
    "dane_code": 15646000,
    "name_depto": "BOYACA",
    "name_city": "SAMACA"
  },
  {
    "id": 903,
    "zip_code": 153668,
    "dane_code": 15646000,
    "name_depto": "BOYACA",
    "name_city": "SAMACA"
  },
  {
    "id": 904,
    "zip_code": 153801,
    "dane_code": 15600000,
    "name_depto": "BOYACA",
    "name_city": "RAQUIRA"
  },
  {
    "id": 905,
    "zip_code": 153807,
    "dane_code": 15600000,
    "name_depto": "BOYACA",
    "name_city": "RAQUIRA"
  },
  {
    "id": 906,
    "zip_code": 153808,
    "dane_code": 15600000,
    "name_depto": "BOYACA",
    "name_city": "RAQUIRA"
  },
  {
    "id": 907,
    "zip_code": 153809,
    "dane_code": 15600000,
    "name_depto": "BOYACA",
    "name_city": "RAQUIRA"
  },
  {
    "id": 908,
    "zip_code": 153820,
    "dane_code": 15676000,
    "name_depto": "BOYACA",
    "name_city": "SAN MIGUEL DE SEMA"
  },
  {
    "id": 909,
    "zip_code": 153827,
    "dane_code": 15676000,
    "name_depto": "BOYACA",
    "name_city": "SAN MIGUEL DE SEMA"
  },
  {
    "id": 910,
    "zip_code": 153840,
    "dane_code": 15808000,
    "name_depto": "BOYACA",
    "name_city": "TINJACA"
  },
  {
    "id": 911,
    "zip_code": 153847,
    "dane_code": 15808000,
    "name_depto": "BOYACA",
    "name_city": "TINJACA"
  },
  {
    "id": 912,
    "zip_code": 153860,
    "dane_code": 15776000,
    "name_depto": "BOYACA",
    "name_city": "SUTAMARCHAN"
  },
  {
    "id": 913,
    "zip_code": 153867,
    "dane_code": 15776000,
    "name_depto": "BOYACA",
    "name_city": "SUTAMARCHAN"
  },
  {
    "id": 914,
    "zip_code": 153880,
    "dane_code": 15638000,
    "name_depto": "BOYACA",
    "name_city": "SACHICA"
  },
  {
    "id": 915,
    "zip_code": 153887,
    "dane_code": 15638000,
    "name_depto": "BOYACA",
    "name_city": "SACHICA"
  },
  {
    "id": 916,
    "zip_code": 154001,
    "dane_code": 15407000,
    "name_depto": "BOYACA",
    "name_city": "VILLA DE LEYVA"
  },
  {
    "id": 917,
    "zip_code": 154007,
    "dane_code": 15407000,
    "name_depto": "BOYACA",
    "name_city": "VILLA DE LEYVA"
  },
  {
    "id": 918,
    "zip_code": 154020,
    "dane_code": 15232000,
    "name_depto": "BOYACA",
    "name_city": "CHIQUIZA"
  },
  {
    "id": 919,
    "zip_code": 154027,
    "dane_code": 15232000,
    "name_depto": "BOYACA",
    "name_city": "CHIQUIZA"
  },
  {
    "id": 920,
    "zip_code": 154040,
    "dane_code": 15762000,
    "name_depto": "BOYACA",
    "name_city": "SORA"
  },
  {
    "id": 921,
    "zip_code": 154047,
    "dane_code": 15762000,
    "name_depto": "BOYACA",
    "name_city": "SORA"
  },
  {
    "id": 922,
    "zip_code": 154060,
    "dane_code": 15224000,
    "name_depto": "BOYACA",
    "name_city": "CUCAITA"
  },
  {
    "id": 923,
    "zip_code": 154067,
    "dane_code": 15224000,
    "name_depto": "BOYACA",
    "name_city": "CUCAITA"
  },
  {
    "id": 924,
    "zip_code": 154080,
    "dane_code": 15476000,
    "name_depto": "BOYACA",
    "name_city": "MOTAVITA"
  },
  {
    "id": 925,
    "zip_code": 154087,
    "dane_code": 15476000,
    "name_depto": "BOYACA",
    "name_city": "MOTAVITA"
  },
  {
    "id": 926,
    "zip_code": 154201,
    "dane_code": 15051000,
    "name_depto": "BOYACA",
    "name_city": "ARCABUCO"
  },
  {
    "id": 927,
    "zip_code": 154207,
    "dane_code": 15051000,
    "name_depto": "BOYACA",
    "name_city": "ARCABUCO"
  },
  {
    "id": 928,
    "zip_code": 154220,
    "dane_code": 15293000,
    "name_depto": "BOYACA",
    "name_city": "GACHANTIVA"
  },
  {
    "id": 929,
    "zip_code": 154227,
    "dane_code": 15293000,
    "name_depto": "BOYACA",
    "name_city": "GACHANTIVA"
  },
  {
    "id": 930,
    "zip_code": 154240,
    "dane_code": 15696000,
    "name_depto": "BOYACA",
    "name_city": "SANTA SOFIA"
  },
  {
    "id": 931,
    "zip_code": 154247,
    "dane_code": 15696000,
    "name_depto": "BOYACA",
    "name_city": "SANTA SOFIA"
  },
  {
    "id": 932,
    "zip_code": 154260,
    "dane_code": 15469000,
    "name_depto": "BOYACA",
    "name_city": "MONIQUIRA"
  },
  {
    "id": 933,
    "zip_code": 154267,
    "dane_code": 15469000,
    "name_depto": "BOYACA",
    "name_city": "MONIQUIRA"
  },
  {
    "id": 934,
    "zip_code": 154268,
    "dane_code": 15469000,
    "name_depto": "BOYACA",
    "name_city": "MONIQUIRA"
  },
  {
    "id": 935,
    "zip_code": 154269,
    "dane_code": 15469000,
    "name_depto": "BOYACA",
    "name_city": "MONIQUIRA"
  },
  {
    "id": 936,
    "zip_code": 154401,
    "dane_code": 15816000,
    "name_depto": "BOYACA",
    "name_city": "TOG√úI"
  },
  {
    "id": 937,
    "zip_code": 154407,
    "dane_code": 15816000,
    "name_depto": "BOYACA",
    "name_city": "TOG√úI"
  },
  {
    "id": 938,
    "zip_code": 154420,
    "dane_code": 15185000,
    "name_depto": "BOYACA",
    "name_city": "CHITARAQUE"
  },
  {
    "id": 939,
    "zip_code": 154427,
    "dane_code": 15185000,
    "name_depto": "BOYACA",
    "name_city": "CHITARAQUE"
  },
  {
    "id": 940,
    "zip_code": 154428,
    "dane_code": 15185000,
    "name_depto": "BOYACA",
    "name_city": "CHITARAQUE"
  },
  {
    "id": 941,
    "zip_code": 154440,
    "dane_code": 15686000,
    "name_depto": "BOYACA",
    "name_city": "SANTANA"
  },
  {
    "id": 942,
    "zip_code": 154447,
    "dane_code": 15686000,
    "name_depto": "BOYACA",
    "name_city": "SANTANA"
  },
  {
    "id": 943,
    "zip_code": 154448,
    "dane_code": 15686000,
    "name_depto": "BOYACA",
    "name_city": "SANTANA"
  },
  {
    "id": 944,
    "zip_code": 154460,
    "dane_code": 15664000,
    "name_depto": "BOYACA",
    "name_city": "SAN JOSE DE PARE"
  },
  {
    "id": 945,
    "zip_code": 154467,
    "dane_code": 15664000,
    "name_depto": "BOYACA",
    "name_city": "SAN JOSE DE PARE"
  },
  {
    "id": 946,
    "zip_code": 154601,
    "dane_code": 15632000,
    "name_depto": "BOYACA",
    "name_city": "SABOYA"
  },
  {
    "id": 947,
    "zip_code": 154607,
    "dane_code": 15632000,
    "name_depto": "BOYACA",
    "name_city": "SABOYA"
  },
  {
    "id": 948,
    "zip_code": 154608,
    "dane_code": 15632000,
    "name_depto": "BOYACA",
    "name_city": "SABOYA"
  },
  {
    "id": 949,
    "zip_code": 154609,
    "dane_code": 15632000,
    "name_depto": "BOYACA",
    "name_city": "SABOYA"
  },
  {
    "id": 950,
    "zip_code": 154617,
    "dane_code": 15632000,
    "name_depto": "BOYACA",
    "name_city": "SABOYA"
  },
  {
    "id": 951,
    "zip_code": 154640,
    "dane_code": 15176000,
    "name_depto": "BOYACA",
    "name_city": "CHIQUINQUIRA"
  },
  {
    "id": 952,
    "zip_code": 154647,
    "dane_code": 15176000,
    "name_depto": "BOYACA",
    "name_city": "CHIQUINQUIRA"
  },
  {
    "id": 953,
    "zip_code": 154648,
    "dane_code": 15176000,
    "name_depto": "BOYACA",
    "name_city": "CHIQUINQUIRA"
  },
  {
    "id": 954,
    "zip_code": 154660,
    "dane_code": 15131000,
    "name_depto": "BOYACA",
    "name_city": "CALDAS"
  },
  {
    "id": 955,
    "zip_code": 154667,
    "dane_code": 15131000,
    "name_depto": "BOYACA",
    "name_city": "CALDAS"
  },
  {
    "id": 956,
    "zip_code": 154670,
    "dane_code": 15106000,
    "name_depto": "BOYACA",
    "name_city": "BRICE√ëO"
  },
  {
    "id": 957,
    "zip_code": 154677,
    "dane_code": 15106000,
    "name_depto": "BOYACA",
    "name_city": "BRICE√ëO"
  },
  {
    "id": 958,
    "zip_code": 154680,
    "dane_code": 15832000,
    "name_depto": "BOYACA",
    "name_city": "TUNUNGUA"
  },
  {
    "id": 959,
    "zip_code": 154687,
    "dane_code": 15832000,
    "name_depto": "BOYACA",
    "name_city": "TUNUNGUA"
  },
  {
    "id": 960,
    "zip_code": 154801,
    "dane_code": 15531000,
    "name_depto": "BOYACA",
    "name_city": "PAUNA"
  },
  {
    "id": 961,
    "zip_code": 154807,
    "dane_code": 15531000,
    "name_depto": "BOYACA",
    "name_city": "PAUNA"
  },
  {
    "id": 962,
    "zip_code": 154808,
    "dane_code": 15531000,
    "name_depto": "BOYACA",
    "name_city": "PAUNA"
  },
  {
    "id": 963,
    "zip_code": 154820,
    "dane_code": 15442000,
    "name_depto": "BOYACA",
    "name_city": "MARIPI"
  },
  {
    "id": 964,
    "zip_code": 154827,
    "dane_code": 15442000,
    "name_depto": "BOYACA",
    "name_city": "MARIPI"
  },
  {
    "id": 965,
    "zip_code": 154828,
    "dane_code": 15442000,
    "name_depto": "BOYACA",
    "name_city": "MARIPI"
  },
  {
    "id": 966,
    "zip_code": 154840,
    "dane_code": 15109000,
    "name_depto": "BOYACA",
    "name_city": "BUENAVISTA"
  },
  {
    "id": 967,
    "zip_code": 154847,
    "dane_code": 15109000,
    "name_depto": "BOYACA",
    "name_city": "BUENAVISTA"
  },
  {
    "id": 968,
    "zip_code": 154848,
    "dane_code": 15109000,
    "name_depto": "BOYACA",
    "name_city": "BUENAVISTA"
  },
  {
    "id": 969,
    "zip_code": 154860,
    "dane_code": 15212000,
    "name_depto": "BOYACA",
    "name_city": "COPER"
  },
  {
    "id": 970,
    "zip_code": 154867,
    "dane_code": 15212000,
    "name_depto": "BOYACA",
    "name_city": "COPER"
  },
  {
    "id": 971,
    "zip_code": 154880,
    "dane_code": 15480000,
    "name_depto": "BOYACA",
    "name_city": "MUZO"
  },
  {
    "id": 972,
    "zip_code": 154887,
    "dane_code": 15480000,
    "name_depto": "BOYACA",
    "name_city": "MUZO"
  },
  {
    "id": 973,
    "zip_code": 155001,
    "dane_code": 15401000,
    "name_depto": "BOYACA",
    "name_city": "LA VICTORIA"
  },
  {
    "id": 974,
    "zip_code": 155007,
    "dane_code": 15401000,
    "name_depto": "BOYACA",
    "name_city": "LA VICTORIA"
  },
  {
    "id": 975,
    "zip_code": 155020,
    "dane_code": 15580000,
    "name_depto": "BOYACA",
    "name_city": "QUIPAMA"
  },
  {
    "id": 976,
    "zip_code": 155027,
    "dane_code": 15580000,
    "name_depto": "BOYACA",
    "name_city": "QUIPAMA"
  },
  {
    "id": 977,
    "zip_code": 155028,
    "dane_code": 15580000,
    "name_depto": "BOYACA",
    "name_city": "QUIPAMA"
  },
  {
    "id": 978,
    "zip_code": 155040,
    "dane_code": 15681000,
    "name_depto": "BOYACA",
    "name_city": "SAN PABLO DE BORBUR"
  },
  {
    "id": 979,
    "zip_code": 155047,
    "dane_code": 15681000,
    "name_depto": "BOYACA",
    "name_city": "SAN PABLO DE BORBUR"
  },
  {
    "id": 980,
    "zip_code": 155048,
    "dane_code": 15681000,
    "name_depto": "BOYACA",
    "name_city": "SAN PABLO DE BORBUR"
  },
  {
    "id": 981,
    "zip_code": 155060,
    "dane_code": 15507000,
    "name_depto": "BOYACA",
    "name_city": "OTANCHE"
  },
  {
    "id": 982,
    "zip_code": 155067,
    "dane_code": 15507000,
    "name_depto": "BOYACA",
    "name_city": "OTANCHE"
  },
  {
    "id": 983,
    "zip_code": 155068,
    "dane_code": 15507000,
    "name_depto": "BOYACA",
    "name_city": "OTANCHE"
  },
  {
    "id": 984,
    "zip_code": 155201,
    "dane_code": 15572000,
    "name_depto": "BOYACA",
    "name_city": "PUERTO BOYACA"
  },
  {
    "id": 985,
    "zip_code": 155207,
    "dane_code": 15572000,
    "name_depto": "BOYACA",
    "name_city": "PUERTO BOYACA"
  },
  {
    "id": 986,
    "zip_code": 155208,
    "dane_code": 15572000,
    "name_depto": "BOYACA",
    "name_city": "PUERTO BOYACA"
  },
  {
    "id": 987,
    "zip_code": 155209,
    "dane_code": 15572000,
    "name_depto": "BOYACA",
    "name_city": "PUERTO BOYACA"
  },
  {
    "id": 988,
    "zip_code": 155217,
    "dane_code": 15572000,
    "name_depto": "BOYACA",
    "name_city": "PUERTO BOYACA"
  },
  {
    "id": 989,
    "zip_code": 155218,
    "dane_code": 15572000,
    "name_depto": "BOYACA",
    "name_city": "PUERTO BOYACA"
  },
  {
    "id": 990,
    "zip_code": 155219,
    "dane_code": 15572000,
    "name_depto": "BOYACA",
    "name_city": "PUERTO BOYACA"
  },
  {
    "id": 991,
    "zip_code": 170001,
    "dane_code": 17001000,
    "name_depto": "CALDAS",
    "name_city": "MANIZALES"
  },
  {
    "id": 992,
    "zip_code": 170002,
    "dane_code": 17001000,
    "name_depto": "CALDAS",
    "name_city": "MANIZALES"
  },
  {
    "id": 993,
    "zip_code": 170003,
    "dane_code": 17001000,
    "name_depto": "CALDAS",
    "name_city": "MANIZALES"
  },
  {
    "id": 994,
    "zip_code": 170004,
    "dane_code": 17001000,
    "name_depto": "CALDAS",
    "name_city": "MANIZALES"
  },
  {
    "id": 995,
    "zip_code": 170006,
    "dane_code": 17001000,
    "name_depto": "CALDAS",
    "name_city": "MANIZALES"
  },
  {
    "id": 996,
    "zip_code": 170007,
    "dane_code": 17001000,
    "name_depto": "CALDAS",
    "name_city": "MANIZALES"
  },
  {
    "id": 997,
    "zip_code": 170008,
    "dane_code": 17001000,
    "name_depto": "CALDAS",
    "name_city": "MANIZALES"
  },
  {
    "id": 998,
    "zip_code": 170009,
    "dane_code": 17001000,
    "name_depto": "CALDAS",
    "name_city": "MANIZALES"
  },
  {
    "id": 999,
    "zip_code": 170017,
    "dane_code": 17001000,
    "name_depto": "CALDAS",
    "name_city": "MANIZALES"
  },
  {
    "id": 1000,
    "zip_code": 171001,
    "dane_code": 17486000,
    "name_depto": "CALDAS",
    "name_city": "NEIRA"
  },
  {
    "id": 1001,
    "zip_code": 171007,
    "dane_code": 17486000,
    "name_depto": "CALDAS",
    "name_city": "NEIRA"
  },
  {
    "id": 1002,
    "zip_code": 171008,
    "dane_code": 17486000,
    "name_depto": "CALDAS",
    "name_city": "NEIRA"
  },
  {
    "id": 1003,
    "zip_code": 171020,
    "dane_code": 17272000,
    "name_depto": "CALDAS",
    "name_city": "FILADELFIA"
  },
  {
    "id": 1004,
    "zip_code": 171027,
    "dane_code": 17272000,
    "name_depto": "CALDAS",
    "name_city": "FILADELFIA"
  },
  {
    "id": 1005,
    "zip_code": 171028,
    "dane_code": 17272000,
    "name_depto": "CALDAS",
    "name_city": "FILADELFIA"
  },
  {
    "id": 1006,
    "zip_code": 171040,
    "dane_code": 17050000,
    "name_depto": "CALDAS",
    "name_city": "ARANZAZU"
  },
  {
    "id": 1007,
    "zip_code": 171047,
    "dane_code": 17050000,
    "name_depto": "CALDAS",
    "name_city": "ARANZAZU"
  },
  {
    "id": 1008,
    "zip_code": 172001,
    "dane_code": 17653000,
    "name_depto": "CALDAS",
    "name_city": "SALAMINA"
  },
  {
    "id": 1009,
    "zip_code": 172007,
    "dane_code": 17653000,
    "name_depto": "CALDAS",
    "name_city": "SALAMINA"
  },
  {
    "id": 1010,
    "zip_code": 172008,
    "dane_code": 17653000,
    "name_depto": "CALDAS",
    "name_city": "SALAMINA"
  },
  {
    "id": 1011,
    "zip_code": 172009,
    "dane_code": 17653000,
    "name_depto": "CALDAS",
    "name_city": "SALAMINA"
  },
  {
    "id": 1012,
    "zip_code": 172020,
    "dane_code": 17013000,
    "name_depto": "CALDAS",
    "name_city": "AGUADAS"
  },
  {
    "id": 1013,
    "zip_code": 172027,
    "dane_code": 17013000,
    "name_depto": "CALDAS",
    "name_city": "AGUADAS"
  },
  {
    "id": 1014,
    "zip_code": 172028,
    "dane_code": 17013000,
    "name_depto": "CALDAS",
    "name_city": "AGUADAS"
  },
  {
    "id": 1015,
    "zip_code": 172029,
    "dane_code": 17013000,
    "name_depto": "CALDAS",
    "name_city": "AGUADAS"
  },
  {
    "id": 1016,
    "zip_code": 172040,
    "dane_code": 17513000,
    "name_depto": "CALDAS",
    "name_city": "PACORA"
  },
  {
    "id": 1017,
    "zip_code": 172047,
    "dane_code": 17513000,
    "name_depto": "CALDAS",
    "name_city": "PACORA"
  },
  {
    "id": 1018,
    "zip_code": 172048,
    "dane_code": 17513000,
    "name_depto": "CALDAS",
    "name_city": "PACORA"
  },
  {
    "id": 1019,
    "zip_code": 172060,
    "dane_code": 17388000,
    "name_depto": "CALDAS",
    "name_city": "LA MERCED"
  },
  {
    "id": 1020,
    "zip_code": 172067,
    "dane_code": 17388000,
    "name_depto": "CALDAS",
    "name_city": "LA MERCED"
  },
  {
    "id": 1021,
    "zip_code": 173001,
    "dane_code": 17446000,
    "name_depto": "CALDAS",
    "name_city": "MARULANDA"
  },
  {
    "id": 1022,
    "zip_code": 173007,
    "dane_code": 17446000,
    "name_depto": "CALDAS",
    "name_city": "MARULANDA"
  },
  {
    "id": 1023,
    "zip_code": 173020,
    "dane_code": 17433000,
    "name_depto": "CALDAS",
    "name_city": "MANZANARES"
  },
  {
    "id": 1024,
    "zip_code": 173027,
    "dane_code": 17433000,
    "name_depto": "CALDAS",
    "name_city": "MANZANARES"
  },
  {
    "id": 1025,
    "zip_code": 173028,
    "dane_code": 17433000,
    "name_depto": "CALDAS",
    "name_city": "MANZANARES"
  },
  {
    "id": 1026,
    "zip_code": 173040,
    "dane_code": 17444000,
    "name_depto": "CALDAS",
    "name_city": "MARQUETALIA"
  },
  {
    "id": 1027,
    "zip_code": 173047,
    "dane_code": 17444000,
    "name_depto": "CALDAS",
    "name_city": "MARQUETALIA"
  },
  {
    "id": 1028,
    "zip_code": 173048,
    "dane_code": 17444000,
    "name_depto": "CALDAS",
    "name_city": "MARQUETALIA"
  },
  {
    "id": 1029,
    "zip_code": 173060,
    "dane_code": 17541000,
    "name_depto": "CALDAS",
    "name_city": "PENSILVANIA"
  },
  {
    "id": 1030,
    "zip_code": 173067,
    "dane_code": 17541000,
    "name_depto": "CALDAS",
    "name_city": "PENSILVANIA"
  },
  {
    "id": 1031,
    "zip_code": 173068,
    "dane_code": 17541000,
    "name_depto": "CALDAS",
    "name_city": "PENSILVANIA"
  },
  {
    "id": 1032,
    "zip_code": 173069,
    "dane_code": 17541000,
    "name_depto": "CALDAS",
    "name_city": "PENSILVANIA"
  },
  {
    "id": 1033,
    "zip_code": 174001,
    "dane_code": 17662000,
    "name_depto": "CALDAS",
    "name_city": "SAMANA"
  },
  {
    "id": 1034,
    "zip_code": 174007,
    "dane_code": 17662000,
    "name_depto": "CALDAS",
    "name_city": "SAMANA"
  },
  {
    "id": 1035,
    "zip_code": 174008,
    "dane_code": 17662000,
    "name_depto": "CALDAS",
    "name_city": "SAMANA"
  },
  {
    "id": 1036,
    "zip_code": 174009,
    "dane_code": 17662000,
    "name_depto": "CALDAS",
    "name_city": "SAMANA"
  },
  {
    "id": 1037,
    "zip_code": 174030,
    "dane_code": 17867000,
    "name_depto": "CALDAS",
    "name_city": "VICTORIA"
  },
  {
    "id": 1038,
    "zip_code": 174037,
    "dane_code": 17867000,
    "name_depto": "CALDAS",
    "name_city": "VICTORIA"
  },
  {
    "id": 1039,
    "zip_code": 174038,
    "dane_code": 17867000,
    "name_depto": "CALDAS",
    "name_city": "VICTORIA"
  },
  {
    "id": 1040,
    "zip_code": 175001,
    "dane_code": 17495000,
    "name_depto": "CALDAS",
    "name_city": "NORCASIA"
  },
  {
    "id": 1041,
    "zip_code": 175007,
    "dane_code": 17495000,
    "name_depto": "CALDAS",
    "name_city": "NORCASIA"
  },
  {
    "id": 1042,
    "zip_code": 175030,
    "dane_code": 17380000,
    "name_depto": "CALDAS",
    "name_city": "LA DORADA"
  },
  {
    "id": 1043,
    "zip_code": 175031,
    "dane_code": 17380000,
    "name_depto": "CALDAS",
    "name_city": "LA DORADA"
  },
  {
    "id": 1044,
    "zip_code": 175037,
    "dane_code": 17380000,
    "name_depto": "CALDAS",
    "name_city": "LA DORADA"
  },
  {
    "id": 1045,
    "zip_code": 175038,
    "dane_code": 17380000,
    "name_depto": "CALDAS",
    "name_city": "LA DORADA"
  },
  {
    "id": 1046,
    "zip_code": 176001,
    "dane_code": 17873000,
    "name_depto": "CALDAS",
    "name_city": "VILLAMARIA"
  },
  {
    "id": 1047,
    "zip_code": 176007,
    "dane_code": 17873000,
    "name_depto": "CALDAS",
    "name_city": "VILLAMARIA"
  },
  {
    "id": 1048,
    "zip_code": 176008,
    "dane_code": 17873000,
    "name_depto": "CALDAS",
    "name_city": "VILLAMARIA"
  },
  {
    "id": 1049,
    "zip_code": 176020,
    "dane_code": 17174000,
    "name_depto": "CALDAS",
    "name_city": "CHINCHINA"
  },
  {
    "id": 1050,
    "zip_code": 176027,
    "dane_code": 17174000,
    "name_depto": "CALDAS",
    "name_city": "CHINCHINA"
  },
  {
    "id": 1051,
    "zip_code": 176028,
    "dane_code": 17174000,
    "name_depto": "CALDAS",
    "name_city": "CHINCHINA"
  },
  {
    "id": 1052,
    "zip_code": 176040,
    "dane_code": 17524000,
    "name_depto": "CALDAS",
    "name_city": "PALESTINA"
  },
  {
    "id": 1053,
    "zip_code": 176047,
    "dane_code": 17524000,
    "name_depto": "CALDAS",
    "name_city": "PALESTINA"
  },
  {
    "id": 1054,
    "zip_code": 176048,
    "dane_code": 17524000,
    "name_depto": "CALDAS",
    "name_city": "PALESTINA"
  },
  {
    "id": 1055,
    "zip_code": 177001,
    "dane_code": 17088000,
    "name_depto": "CALDAS",
    "name_city": "BELALCAZAR"
  },
  {
    "id": 1056,
    "zip_code": 177007,
    "dane_code": 17088000,
    "name_depto": "CALDAS",
    "name_city": "BELALCAZAR"
  },
  {
    "id": 1057,
    "zip_code": 177020,
    "dane_code": 17877000,
    "name_depto": "CALDAS",
    "name_city": "VITERBO"
  },
  {
    "id": 1058,
    "zip_code": 177027,
    "dane_code": 17877000,
    "name_depto": "CALDAS",
    "name_city": "VITERBO"
  },
  {
    "id": 1059,
    "zip_code": 177040,
    "dane_code": 17665000,
    "name_depto": "CALDAS",
    "name_city": "SAN JOSE"
  },
  {
    "id": 1060,
    "zip_code": 177047,
    "dane_code": 17665000,
    "name_depto": "CALDAS",
    "name_city": "SAN JOSE"
  },
  {
    "id": 1061,
    "zip_code": 177060,
    "dane_code": 17616000,
    "name_depto": "CALDAS",
    "name_city": "RISARALDA"
  },
  {
    "id": 1062,
    "zip_code": 177067,
    "dane_code": 17616000,
    "name_depto": "CALDAS",
    "name_city": "RISARALDA"
  },
  {
    "id": 1063,
    "zip_code": 177068,
    "dane_code": 17616000,
    "name_depto": "CALDAS",
    "name_city": "RISARALDA"
  },
  {
    "id": 1064,
    "zip_code": 177080,
    "dane_code": 17042000,
    "name_depto": "CALDAS",
    "name_city": "ANSERMA"
  },
  {
    "id": 1065,
    "zip_code": 177087,
    "dane_code": 17042000,
    "name_depto": "CALDAS",
    "name_city": "ANSERMA"
  },
  {
    "id": 1066,
    "zip_code": 177088,
    "dane_code": 17042000,
    "name_depto": "CALDAS",
    "name_city": "ANSERMA"
  },
  {
    "id": 1067,
    "zip_code": 177089,
    "dane_code": 17042000,
    "name_depto": "CALDAS",
    "name_city": "ANSERMA"
  },
  {
    "id": 1068,
    "zip_code": 178001,
    "dane_code": 17442000,
    "name_depto": "CALDAS",
    "name_city": "MARMATO"
  },
  {
    "id": 1069,
    "zip_code": 178007,
    "dane_code": 17442000,
    "name_depto": "CALDAS",
    "name_city": "MARMATO"
  },
  {
    "id": 1070,
    "zip_code": 178020,
    "dane_code": 17777000,
    "name_depto": "CALDAS",
    "name_city": "SUPIA"
  },
  {
    "id": 1071,
    "zip_code": 178027,
    "dane_code": 17777000,
    "name_depto": "CALDAS",
    "name_city": "SUPIA"
  },
  {
    "id": 1072,
    "zip_code": 178028,
    "dane_code": 17777000,
    "name_depto": "CALDAS",
    "name_city": "SUPIA"
  },
  {
    "id": 1073,
    "zip_code": 178040,
    "dane_code": 17614000,
    "name_depto": "CALDAS",
    "name_city": "RIOSUCIO"
  },
  {
    "id": 1074,
    "zip_code": 178047,
    "dane_code": 17614000,
    "name_depto": "CALDAS",
    "name_city": "RIOSUCIO"
  },
  {
    "id": 1075,
    "zip_code": 178048,
    "dane_code": 17614000,
    "name_depto": "CALDAS",
    "name_city": "RIOSUCIO"
  },
  {
    "id": 1076,
    "zip_code": 178049,
    "dane_code": 17614000,
    "name_depto": "CALDAS",
    "name_city": "RIOSUCIO"
  },
  {
    "id": 1077,
    "zip_code": 178057,
    "dane_code": 17614000,
    "name_depto": "CALDAS",
    "name_city": "RIOSUCIO"
  },
  {
    "id": 1078,
    "zip_code": 180001,
    "dane_code": 18001000,
    "name_depto": "CAQUETA",
    "name_city": "FLORENCIA"
  },
  {
    "id": 1079,
    "zip_code": 180002,
    "dane_code": 18001000,
    "name_depto": "CAQUETA",
    "name_city": "FLORENCIA"
  },
  {
    "id": 1080,
    "zip_code": 180007,
    "dane_code": 18001000,
    "name_depto": "CAQUETA",
    "name_city": "FLORENCIA"
  },
  {
    "id": 1081,
    "zip_code": 180008,
    "dane_code": 18001000,
    "name_depto": "CAQUETA",
    "name_city": "FLORENCIA"
  },
  {
    "id": 1082,
    "zip_code": 180009,
    "dane_code": 18001000,
    "name_depto": "CAQUETA",
    "name_city": "FLORENCIA"
  },
  {
    "id": 1083,
    "zip_code": 180017,
    "dane_code": 18001000,
    "name_depto": "CAQUETA",
    "name_city": "FLORENCIA"
  },
  {
    "id": 1084,
    "zip_code": 181010,
    "dane_code": 18247000,
    "name_depto": "CAQUETA",
    "name_city": "EL DONCELLO"
  },
  {
    "id": 1085,
    "zip_code": 181017,
    "dane_code": 18247000,
    "name_depto": "CAQUETA",
    "name_city": "EL DONCELLO"
  },
  {
    "id": 1086,
    "zip_code": 181018,
    "dane_code": 18247000,
    "name_depto": "CAQUETA",
    "name_city": "EL DONCELLO"
  },
  {
    "id": 1087,
    "zip_code": 181019,
    "dane_code": 18247000,
    "name_depto": "CAQUETA",
    "name_city": "EL DONCELLO"
  },
  {
    "id": 1088,
    "zip_code": 181030,
    "dane_code": 18256000,
    "name_depto": "CAQUETA",
    "name_city": "EL PAUJIL"
  },
  {
    "id": 1089,
    "zip_code": 181037,
    "dane_code": 18256000,
    "name_depto": "CAQUETA",
    "name_city": "EL PAUJIL"
  },
  {
    "id": 1090,
    "zip_code": 181038,
    "dane_code": 18256000,
    "name_depto": "CAQUETA",
    "name_city": "EL PAUJIL"
  },
  {
    "id": 1091,
    "zip_code": 181039,
    "dane_code": 18256000,
    "name_depto": "CAQUETA",
    "name_city": "EL PAUJIL"
  },
  {
    "id": 1092,
    "zip_code": 181050,
    "dane_code": 18410000,
    "name_depto": "CAQUETA",
    "name_city": "LA MONTA√ëITA"
  },
  {
    "id": 1093,
    "zip_code": 181057,
    "dane_code": 18410000,
    "name_depto": "CAQUETA",
    "name_city": "LA MONTA√ëITA"
  },
  {
    "id": 1094,
    "zip_code": 181058,
    "dane_code": 18410000,
    "name_depto": "CAQUETA",
    "name_city": "LA MONTA√ëITA"
  },
  {
    "id": 1095,
    "zip_code": 181059,
    "dane_code": 18410000,
    "name_depto": "CAQUETA",
    "name_city": "LA MONTA√ëITA"
  },
  {
    "id": 1096,
    "zip_code": 181067,
    "dane_code": 18410000,
    "name_depto": "CAQUETA",
    "name_city": "LA MONTA√ëITA"
  },
  {
    "id": 1097,
    "zip_code": 182010,
    "dane_code": 18753000,
    "name_depto": "CAQUETA",
    "name_city": "SAN VICENTE DEL CAGUAN"
  },
  {
    "id": 1098,
    "zip_code": 182017,
    "dane_code": 18753000,
    "name_depto": "CAQUETA",
    "name_city": "SAN VICENTE DEL CAGUAN"
  },
  {
    "id": 1099,
    "zip_code": 182018,
    "dane_code": 18753000,
    "name_depto": "CAQUETA",
    "name_city": "SAN VICENTE DEL CAGUAN"
  },
  {
    "id": 1100,
    "zip_code": 182019,
    "dane_code": 18753000,
    "name_depto": "CAQUETA",
    "name_city": "SAN VICENTE DEL CAGUAN"
  },
  {
    "id": 1101,
    "zip_code": 182050,
    "dane_code": 18592000,
    "name_depto": "CAQUETA",
    "name_city": "PUERTO RICO"
  },
  {
    "id": 1102,
    "zip_code": 182057,
    "dane_code": 18592000,
    "name_depto": "CAQUETA",
    "name_city": "PUERTO RICO"
  },
  {
    "id": 1103,
    "zip_code": 182058,
    "dane_code": 18592000,
    "name_depto": "CAQUETA",
    "name_city": "PUERTO RICO"
  },
  {
    "id": 1104,
    "zip_code": 182059,
    "dane_code": 18592000,
    "name_depto": "CAQUETA",
    "name_city": "PUERTO RICO"
  },
  {
    "id": 1105,
    "zip_code": 183010,
    "dane_code": 18150000,
    "name_depto": "CAQUETA",
    "name_city": "CARTAGENA DEL CHAIRA"
  },
  {
    "id": 1106,
    "zip_code": 183017,
    "dane_code": 18150000,
    "name_depto": "CAQUETA",
    "name_city": "CARTAGENA DEL CHAIRA"
  },
  {
    "id": 1107,
    "zip_code": 183018,
    "dane_code": 18150000,
    "name_depto": "CAQUETA",
    "name_city": "CARTAGENA DEL CHAIRA"
  },
  {
    "id": 1108,
    "zip_code": 183019,
    "dane_code": 18150000,
    "name_depto": "CAQUETA",
    "name_city": "CARTAGENA DEL CHAIRA"
  },
  {
    "id": 1109,
    "zip_code": 183027,
    "dane_code": 18150000,
    "name_depto": "CAQUETA",
    "name_city": "CARTAGENA DEL CHAIRA"
  },
  {
    "id": 1110,
    "zip_code": 184010,
    "dane_code": 18756000,
    "name_depto": "CAQUETA",
    "name_city": "SOLANO"
  },
  {
    "id": 1111,
    "zip_code": 184017,
    "dane_code": 18756000,
    "name_depto": "CAQUETA",
    "name_city": "SOLANO"
  },
  {
    "id": 1112,
    "zip_code": 184018,
    "dane_code": 18756000,
    "name_depto": "CAQUETA",
    "name_city": "SOLANO"
  },
  {
    "id": 1113,
    "zip_code": 184019,
    "dane_code": 18756000,
    "name_depto": "CAQUETA",
    "name_city": "SOLANO"
  },
  {
    "id": 1114,
    "zip_code": 185010,
    "dane_code": 18479000,
    "name_depto": "CAQUETA",
    "name_city": "MORELIA"
  },
  {
    "id": 1115,
    "zip_code": 185017,
    "dane_code": 18479000,
    "name_depto": "CAQUETA",
    "name_city": "MORELIA"
  },
  {
    "id": 1116,
    "zip_code": 185018,
    "dane_code": 18479000,
    "name_depto": "CAQUETA",
    "name_city": "MORELIA"
  },
  {
    "id": 1117,
    "zip_code": 185030,
    "dane_code": 18460000,
    "name_depto": "CAQUETA",
    "name_city": "MILAN"
  },
  {
    "id": 1118,
    "zip_code": 185037,
    "dane_code": 18460000,
    "name_depto": "CAQUETA",
    "name_city": "MILAN"
  },
  {
    "id": 1119,
    "zip_code": 185038,
    "dane_code": 18460000,
    "name_depto": "CAQUETA",
    "name_city": "MILAN"
  },
  {
    "id": 1120,
    "zip_code": 185050,
    "dane_code": 18860000,
    "name_depto": "CAQUETA",
    "name_city": "VALPARAISO"
  },
  {
    "id": 1121,
    "zip_code": 185057,
    "dane_code": 18860000,
    "name_depto": "CAQUETA",
    "name_city": "VALPARAISO"
  },
  {
    "id": 1122,
    "zip_code": 185058,
    "dane_code": 18860000,
    "name_depto": "CAQUETA",
    "name_city": "VALPARAISO"
  },
  {
    "id": 1123,
    "zip_code": 185070,
    "dane_code": 18785000,
    "name_depto": "CAQUETA",
    "name_city": "SOLITA"
  },
  {
    "id": 1124,
    "zip_code": 185077,
    "dane_code": 18785000,
    "name_depto": "CAQUETA",
    "name_city": "SOLITA"
  },
  {
    "id": 1125,
    "zip_code": 185078,
    "dane_code": 18785000,
    "name_depto": "CAQUETA",
    "name_city": "SOLITA"
  },
  {
    "id": 1126,
    "zip_code": 186010,
    "dane_code": 18094000,
    "name_depto": "CAQUETA",
    "name_city": "BELEN DE LOS ANDAQUIES"
  },
  {
    "id": 1127,
    "zip_code": 186017,
    "dane_code": 18094000,
    "name_depto": "CAQUETA",
    "name_city": "BELEN DE LOS ANDAQUIES"
  },
  {
    "id": 1128,
    "zip_code": 186018,
    "dane_code": 18094000,
    "name_depto": "CAQUETA",
    "name_city": "BELEN DE LOS ANDAQUIES"
  },
  {
    "id": 1129,
    "zip_code": 186030,
    "dane_code": 18029000,
    "name_depto": "CAQUETA",
    "name_city": "ALBANIA"
  },
  {
    "id": 1130,
    "zip_code": 186037,
    "dane_code": 18029000,
    "name_depto": "CAQUETA",
    "name_city": "ALBANIA"
  },
  {
    "id": 1131,
    "zip_code": 186038,
    "dane_code": 18029000,
    "name_depto": "CAQUETA",
    "name_city": "ALBANIA"
  },
  {
    "id": 1132,
    "zip_code": 186050,
    "dane_code": 18205000,
    "name_depto": "CAQUETA",
    "name_city": "CURILLO"
  },
  {
    "id": 1133,
    "zip_code": 186057,
    "dane_code": 18205000,
    "name_depto": "CAQUETA",
    "name_city": "CURILLO"
  },
  {
    "id": 1134,
    "zip_code": 186058,
    "dane_code": 18205000,
    "name_depto": "CAQUETA",
    "name_city": "CURILLO"
  },
  {
    "id": 1135,
    "zip_code": 186059,
    "dane_code": 18205000,
    "name_depto": "CAQUETA",
    "name_city": "CURILLO"
  },
  {
    "id": 1136,
    "zip_code": 186070,
    "dane_code": 18610000,
    "name_depto": "CAQUETA",
    "name_city": "SAN JOSE DEL FRAGUA"
  },
  {
    "id": 1137,
    "zip_code": 186077,
    "dane_code": 18610000,
    "name_depto": "CAQUETA",
    "name_city": "SAN JOSE DEL FRAGUA"
  },
  {
    "id": 1138,
    "zip_code": 186078,
    "dane_code": 18610000,
    "name_depto": "CAQUETA",
    "name_city": "SAN JOSE DEL FRAGUA"
  },
  {
    "id": 1139,
    "zip_code": 190001,
    "dane_code": 19001000,
    "name_depto": "CAUCA",
    "name_city": "POPAYAN"
  },
  {
    "id": 1140,
    "zip_code": 190002,
    "dane_code": 19001000,
    "name_depto": "CAUCA",
    "name_city": "POPAYAN"
  },
  {
    "id": 1141,
    "zip_code": 190003,
    "dane_code": 19001000,
    "name_depto": "CAUCA",
    "name_city": "POPAYAN"
  },
  {
    "id": 1142,
    "zip_code": 190004,
    "dane_code": 19001000,
    "name_depto": "CAUCA",
    "name_city": "POPAYAN"
  },
  {
    "id": 1143,
    "zip_code": 190007,
    "dane_code": 19001000,
    "name_depto": "CAUCA",
    "name_city": "POPAYAN"
  },
  {
    "id": 1144,
    "zip_code": 190008,
    "dane_code": 19001000,
    "name_depto": "CAUCA",
    "name_city": "POPAYAN"
  },
  {
    "id": 1145,
    "zip_code": 190009,
    "dane_code": 19001000,
    "name_depto": "CAUCA",
    "name_city": "POPAYAN"
  },
  {
    "id": 1146,
    "zip_code": 190017,
    "dane_code": 19001000,
    "name_depto": "CAUCA",
    "name_city": "POPAYAN"
  },
  {
    "id": 1147,
    "zip_code": 190018,
    "dane_code": 19001000,
    "name_depto": "CAUCA",
    "name_city": "POPAYAN"
  },
  {
    "id": 1148,
    "zip_code": 190501,
    "dane_code": 19130000,
    "name_depto": "CAUCA",
    "name_city": "CAJIBIO"
  },
  {
    "id": 1149,
    "zip_code": 190507,
    "dane_code": 19130000,
    "name_depto": "CAUCA",
    "name_city": "CAJIBIO"
  },
  {
    "id": 1150,
    "zip_code": 190508,
    "dane_code": 19130000,
    "name_depto": "CAUCA",
    "name_city": "CAJIBIO"
  },
  {
    "id": 1151,
    "zip_code": 190509,
    "dane_code": 19130000,
    "name_depto": "CAUCA",
    "name_city": "CAJIBIO"
  },
  {
    "id": 1152,
    "zip_code": 190517,
    "dane_code": 19130000,
    "name_depto": "CAUCA",
    "name_city": "CAJIBIO"
  },
  {
    "id": 1153,
    "zip_code": 190518,
    "dane_code": 19130000,
    "name_depto": "CAUCA",
    "name_city": "CAJIBIO"
  },
  {
    "id": 1154,
    "zip_code": 190530,
    "dane_code": 19548000,
    "name_depto": "CAUCA",
    "name_city": "PIENDAMO"
  },
  {
    "id": 1155,
    "zip_code": 190537,
    "dane_code": 19548000,
    "name_depto": "CAUCA",
    "name_city": "PIENDAMO"
  },
  {
    "id": 1156,
    "zip_code": 190538,
    "dane_code": 19548000,
    "name_depto": "CAUCA",
    "name_city": "PIENDAMO"
  },
  {
    "id": 1157,
    "zip_code": 190539,
    "dane_code": 19548000,
    "name_depto": "CAUCA",
    "name_city": "PIENDAMO"
  },
  {
    "id": 1158,
    "zip_code": 190547,
    "dane_code": 19548000,
    "name_depto": "CAUCA",
    "name_city": "PIENDAMO"
  },
  {
    "id": 1159,
    "zip_code": 190548,
    "dane_code": 19548000,
    "name_depto": "CAUCA",
    "name_city": "PIENDAMO"
  },
  {
    "id": 1160,
    "zip_code": 190550,
    "dane_code": 19473000,
    "name_depto": "CAUCA",
    "name_city": "MORALES"
  },
  {
    "id": 1161,
    "zip_code": 190557,
    "dane_code": 19473000,
    "name_depto": "CAUCA",
    "name_city": "MORALES"
  },
  {
    "id": 1162,
    "zip_code": 190558,
    "dane_code": 19473000,
    "name_depto": "CAUCA",
    "name_city": "MORALES"
  },
  {
    "id": 1163,
    "zip_code": 190559,
    "dane_code": 19473000,
    "name_depto": "CAUCA",
    "name_city": "MORALES"
  },
  {
    "id": 1164,
    "zip_code": 190567,
    "dane_code": 19473000,
    "name_depto": "CAUCA",
    "name_city": "MORALES"
  },
  {
    "id": 1165,
    "zip_code": 190580,
    "dane_code": 19780000,
    "name_depto": "CAUCA",
    "name_city": "SUAREZ"
  },
  {
    "id": 1166,
    "zip_code": 190587,
    "dane_code": 19780000,
    "name_depto": "CAUCA",
    "name_city": "SUAREZ"
  },
  {
    "id": 1167,
    "zip_code": 190588,
    "dane_code": 19780000,
    "name_depto": "CAUCA",
    "name_city": "SUAREZ"
  },
  {
    "id": 1168,
    "zip_code": 190589,
    "dane_code": 19780000,
    "name_depto": "CAUCA",
    "name_city": "SUAREZ"
  },
  {
    "id": 1169,
    "zip_code": 190597,
    "dane_code": 19780000,
    "name_depto": "CAUCA",
    "name_city": "SUAREZ"
  },
  {
    "id": 1170,
    "zip_code": 191001,
    "dane_code": 19110000,
    "name_depto": "CAUCA",
    "name_city": "BUENOS AIRES"
  },
  {
    "id": 1171,
    "zip_code": 191007,
    "dane_code": 19110000,
    "name_depto": "CAUCA",
    "name_city": "BUENOS AIRES"
  },
  {
    "id": 1172,
    "zip_code": 191008,
    "dane_code": 19110000,
    "name_depto": "CAUCA",
    "name_city": "BUENOS AIRES"
  },
  {
    "id": 1173,
    "zip_code": 191009,
    "dane_code": 19110000,
    "name_depto": "CAUCA",
    "name_city": "BUENOS AIRES"
  },
  {
    "id": 1174,
    "zip_code": 191017,
    "dane_code": 19110000,
    "name_depto": "CAUCA",
    "name_city": "BUENOS AIRES"
  },
  {
    "id": 1175,
    "zip_code": 191030,
    "dane_code": 19698000,
    "name_depto": "CAUCA",
    "name_city": "SANTANDER DE QUILICHAO"
  },
  {
    "id": 1176,
    "zip_code": 191037,
    "dane_code": 19698000,
    "name_depto": "CAUCA",
    "name_city": "SANTANDER DE QUILICHAO"
  },
  {
    "id": 1177,
    "zip_code": 191038,
    "dane_code": 19698000,
    "name_depto": "CAUCA",
    "name_city": "SANTANDER DE QUILICHAO"
  },
  {
    "id": 1178,
    "zip_code": 191039,
    "dane_code": 19698000,
    "name_depto": "CAUCA",
    "name_city": "SANTANDER DE QUILICHAO"
  },
  {
    "id": 1179,
    "zip_code": 191047,
    "dane_code": 19698000,
    "name_depto": "CAUCA",
    "name_city": "SANTANDER DE QUILICHAO"
  },
  {
    "id": 1180,
    "zip_code": 191048,
    "dane_code": 19698000,
    "name_depto": "CAUCA",
    "name_city": "SANTANDER DE QUILICHAO"
  },
  {
    "id": 1181,
    "zip_code": 191049,
    "dane_code": 19698000,
    "name_depto": "CAUCA",
    "name_city": "SANTANDER DE QUILICHAO"
  },
  {
    "id": 1182,
    "zip_code": 191060,
    "dane_code": 19845000,
    "name_depto": "CAUCA",
    "name_city": "VILLA RICA"
  },
  {
    "id": 1183,
    "zip_code": 191067,
    "dane_code": 19845000,
    "name_depto": "CAUCA",
    "name_city": "VILLA RICA"
  },
  {
    "id": 1184,
    "zip_code": 191070,
    "dane_code": 19142000,
    "name_depto": "CAUCA",
    "name_city": "CALOTO"
  },
  {
    "id": 1185,
    "zip_code": 191077,
    "dane_code": 19142000,
    "name_depto": "CAUCA",
    "name_city": "CALOTO"
  },
  {
    "id": 1186,
    "zip_code": 191078,
    "dane_code": 19142000,
    "name_depto": "CAUCA",
    "name_city": "CALOTO"
  },
  {
    "id": 1187,
    "zip_code": 191079,
    "dane_code": 19142000,
    "name_depto": "CAUCA",
    "name_city": "CALOTO"
  },
  {
    "id": 1188,
    "zip_code": 191087,
    "dane_code": 19300000,
    "name_depto": "CAUCA",
    "name_city": "CALOTO"
  },
  {
    "id": 1189,
    "zip_code": 191088,
    "dane_code": 19142000,
    "name_depto": "CAUCA",
    "name_city": "CALOTO"
  },
  {
    "id": 1190,
    "zip_code": 191501,
    "dane_code": 19573000,
    "name_depto": "CAUCA",
    "name_city": "PUERTO TEJADA"
  },
  {
    "id": 1191,
    "zip_code": 191507,
    "dane_code": 19573000,
    "name_depto": "CAUCA",
    "name_city": "PUERTO TEJADA"
  },
  {
    "id": 1192,
    "zip_code": 191520,
    "dane_code": 19455000,
    "name_depto": "CAUCA",
    "name_city": "MIRANDA"
  },
  {
    "id": 1193,
    "zip_code": 191527,
    "dane_code": 19455000,
    "name_depto": "CAUCA",
    "name_city": "MIRANDA"
  },
  {
    "id": 1194,
    "zip_code": 191528,
    "dane_code": 19455000,
    "name_depto": "CAUCA",
    "name_city": "MIRANDA"
  },
  {
    "id": 1195,
    "zip_code": 191529,
    "dane_code": 19455000,
    "name_depto": "CAUCA",
    "name_city": "MIRANDA"
  },
  {
    "id": 1196,
    "zip_code": 191540,
    "dane_code": 19513000,
    "name_depto": "CAUCA",
    "name_city": "PADILLA"
  },
  {
    "id": 1197,
    "zip_code": 191547,
    "dane_code": 19513000,
    "name_depto": "CAUCA",
    "name_city": "PADILLA"
  },
  {
    "id": 1198,
    "zip_code": 191560,
    "dane_code": 19212000,
    "name_depto": "CAUCA",
    "name_city": "CORINTO"
  },
  {
    "id": 1199,
    "zip_code": 191567,
    "dane_code": 19212000,
    "name_depto": "CAUCA",
    "name_city": "CORINTO"
  },
  {
    "id": 1200,
    "zip_code": 191568,
    "dane_code": 19212000,
    "name_depto": "CAUCA",
    "name_city": "CORINTO"
  },
  {
    "id": 1201,
    "zip_code": 191569,
    "dane_code": 19212000,
    "name_depto": "CAUCA",
    "name_city": "CORINTO"
  },
  {
    "id": 1202,
    "zip_code": 192001,
    "dane_code": 19821000,
    "name_depto": "CAUCA",
    "name_city": "TORIBIO"
  },
  {
    "id": 1203,
    "zip_code": 192007,
    "dane_code": 19821000,
    "name_depto": "CAUCA",
    "name_city": "TORIBIO"
  },
  {
    "id": 1204,
    "zip_code": 192008,
    "dane_code": 19821000,
    "name_depto": "CAUCA",
    "name_city": "TORIBIO"
  },
  {
    "id": 1205,
    "zip_code": 192009,
    "dane_code": 19821000,
    "name_depto": "CAUCA",
    "name_city": "TORIBIO"
  },
  {
    "id": 1206,
    "zip_code": 192020,
    "dane_code": 19364000,
    "name_depto": "CAUCA",
    "name_city": "JAMBALO"
  },
  {
    "id": 1207,
    "zip_code": 192027,
    "dane_code": 19364000,
    "name_depto": "CAUCA",
    "name_city": "JAMBALO"
  },
  {
    "id": 1208,
    "zip_code": 192028,
    "dane_code": 19364000,
    "name_depto": "CAUCA",
    "name_city": "JAMBALO"
  },
  {
    "id": 1209,
    "zip_code": 192029,
    "dane_code": 19364000,
    "name_depto": "CAUCA",
    "name_city": "JAMBALO"
  },
  {
    "id": 1210,
    "zip_code": 192040,
    "dane_code": 19137000,
    "name_depto": "CAUCA",
    "name_city": "CALDONO"
  },
  {
    "id": 1211,
    "zip_code": 192047,
    "dane_code": 19137000,
    "name_depto": "CAUCA",
    "name_city": "CALDONO"
  },
  {
    "id": 1212,
    "zip_code": 192048,
    "dane_code": 19137000,
    "name_depto": "CAUCA",
    "name_city": "CALDONO"
  },
  {
    "id": 1213,
    "zip_code": 192049,
    "dane_code": 19137000,
    "name_depto": "CAUCA",
    "name_city": "CALDONO"
  },
  {
    "id": 1214,
    "zip_code": 192057,
    "dane_code": 19137000,
    "name_depto": "CAUCA",
    "name_city": "CALDONO"
  },
  {
    "id": 1215,
    "zip_code": 192070,
    "dane_code": 19743000,
    "name_depto": "CAUCA",
    "name_city": "SILVIA"
  },
  {
    "id": 1216,
    "zip_code": 192077,
    "dane_code": 19743000,
    "name_depto": "CAUCA",
    "name_city": "SILVIA"
  },
  {
    "id": 1217,
    "zip_code": 192078,
    "dane_code": 19743000,
    "name_depto": "CAUCA",
    "name_city": "SILVIA"
  },
  {
    "id": 1218,
    "zip_code": 192079,
    "dane_code": 19743000,
    "name_depto": "CAUCA",
    "name_city": "SILVIA"
  },
  {
    "id": 1219,
    "zip_code": 192087,
    "dane_code": 19743000,
    "name_depto": "CAUCA",
    "name_city": "SILVIA"
  },
  {
    "id": 1220,
    "zip_code": 192501,
    "dane_code": 19517000,
    "name_depto": "CAUCA",
    "name_city": "PAEZ"
  },
  {
    "id": 1221,
    "zip_code": 192507,
    "dane_code": 19517000,
    "name_depto": "CAUCA",
    "name_city": "PAEZ"
  },
  {
    "id": 1222,
    "zip_code": 192508,
    "dane_code": 19517000,
    "name_depto": "CAUCA",
    "name_city": "PAEZ"
  },
  {
    "id": 1223,
    "zip_code": 192509,
    "dane_code": 19517000,
    "name_depto": "CAUCA",
    "name_city": "PAEZ"
  },
  {
    "id": 1224,
    "zip_code": 192517,
    "dane_code": 19517000,
    "name_depto": "CAUCA",
    "name_city": "PAEZ"
  },
  {
    "id": 1225,
    "zip_code": 192518,
    "dane_code": 19517000,
    "name_depto": "CAUCA",
    "name_city": "PAEZ"
  },
  {
    "id": 1226,
    "zip_code": 192531,
    "dane_code": 19355000,
    "name_depto": "CAUCA",
    "name_city": "INZA"
  },
  {
    "id": 1227,
    "zip_code": 192537,
    "dane_code": 19355000,
    "name_depto": "CAUCA",
    "name_city": "INZA"
  },
  {
    "id": 1228,
    "zip_code": 192538,
    "dane_code": 19355000,
    "name_depto": "CAUCA",
    "name_city": "INZA"
  },
  {
    "id": 1229,
    "zip_code": 192539,
    "dane_code": 19355000,
    "name_depto": "CAUCA",
    "name_city": "INZA"
  },
  {
    "id": 1230,
    "zip_code": 192547,
    "dane_code": 19355000,
    "name_depto": "CAUCA",
    "name_city": "INZA"
  },
  {
    "id": 1231,
    "zip_code": 192548,
    "dane_code": 19355000,
    "name_depto": "CAUCA",
    "name_city": "INZA"
  },
  {
    "id": 1232,
    "zip_code": 192570,
    "dane_code": 19824000,
    "name_depto": "CAUCA",
    "name_city": "TOTORO"
  },
  {
    "id": 1233,
    "zip_code": 192577,
    "dane_code": 19824000,
    "name_depto": "CAUCA",
    "name_city": "TOTORO"
  },
  {
    "id": 1234,
    "zip_code": 192578,
    "dane_code": 19824000,
    "name_depto": "CAUCA",
    "name_city": "TOTORO"
  },
  {
    "id": 1235,
    "zip_code": 192579,
    "dane_code": 19824000,
    "name_depto": "CAUCA",
    "name_city": "TOTORO"
  },
  {
    "id": 1236,
    "zip_code": 193001,
    "dane_code": 19585000,
    "name_depto": "CAUCA",
    "name_city": "PURACE"
  },
  {
    "id": 1237,
    "zip_code": 193007,
    "dane_code": 19585000,
    "name_depto": "CAUCA",
    "name_city": "PURACE"
  },
  {
    "id": 1238,
    "zip_code": 193008,
    "dane_code": 19585000,
    "name_depto": "CAUCA",
    "name_city": "PURACE"
  },
  {
    "id": 1239,
    "zip_code": 193009,
    "dane_code": 19585000,
    "name_depto": "CAUCA",
    "name_city": "PURACE"
  },
  {
    "id": 1240,
    "zip_code": 193501,
    "dane_code": 19760000,
    "name_depto": "CAUCA",
    "name_city": "SOTARA"
  },
  {
    "id": 1241,
    "zip_code": 193507,
    "dane_code": 19760000,
    "name_depto": "CAUCA",
    "name_city": "SOTARA"
  },
  {
    "id": 1242,
    "zip_code": 193508,
    "dane_code": 19760000,
    "name_depto": "CAUCA",
    "name_city": "SOTARA"
  },
  {
    "id": 1243,
    "zip_code": 193520,
    "dane_code": 19807000,
    "name_depto": "CAUCA",
    "name_city": "TIMBIO"
  },
  {
    "id": 1244,
    "zip_code": 193527,
    "dane_code": 19807000,
    "name_depto": "CAUCA",
    "name_city": "TIMBIO"
  },
  {
    "id": 1245,
    "zip_code": 193528,
    "dane_code": 19807000,
    "name_depto": "CAUCA",
    "name_city": "TIMBIO"
  },
  {
    "id": 1246,
    "zip_code": 193529,
    "dane_code": 19807000,
    "name_depto": "CAUCA",
    "name_city": "TIMBIO"
  },
  {
    "id": 1247,
    "zip_code": 193537,
    "dane_code": 19807000,
    "name_depto": "CAUCA",
    "name_city": "TIMBIO"
  },
  {
    "id": 1248,
    "zip_code": 193550,
    "dane_code": 19622000,
    "name_depto": "CAUCA",
    "name_city": "ROSAS"
  },
  {
    "id": 1249,
    "zip_code": 193557,
    "dane_code": 19622000,
    "name_depto": "CAUCA",
    "name_city": "ROSAS"
  },
  {
    "id": 1250,
    "zip_code": 193558,
    "dane_code": 19622000,
    "name_depto": "CAUCA",
    "name_city": "ROSAS"
  },
  {
    "id": 1251,
    "zip_code": 193570,
    "dane_code": 19256000,
    "name_depto": "CAUCA",
    "name_city": "EL TAMBO"
  },
  {
    "id": 1252,
    "zip_code": 193577,
    "dane_code": 19256000,
    "name_depto": "CAUCA",
    "name_city": "EL TAMBO"
  },
  {
    "id": 1253,
    "zip_code": 193578,
    "dane_code": 19256000,
    "name_depto": "CAUCA",
    "name_city": "EL TAMBO"
  },
  {
    "id": 1254,
    "zip_code": 193579,
    "dane_code": 19256000,
    "name_depto": "CAUCA",
    "name_city": "EL TAMBO"
  },
  {
    "id": 1255,
    "zip_code": 193587,
    "dane_code": 19256000,
    "name_depto": "CAUCA",
    "name_city": "EL TAMBO"
  },
  {
    "id": 1256,
    "zip_code": 193588,
    "dane_code": 19256000,
    "name_depto": "CAUCA",
    "name_city": "EL TAMBO"
  },
  {
    "id": 1257,
    "zip_code": 193589,
    "dane_code": 19256000,
    "name_depto": "CAUCA",
    "name_city": "EL TAMBO"
  },
  {
    "id": 1258,
    "zip_code": 193597,
    "dane_code": 19256000,
    "name_depto": "CAUCA",
    "name_city": "EL TAMBO"
  },
  {
    "id": 1259,
    "zip_code": 194001,
    "dane_code": 19392000,
    "name_depto": "CAUCA",
    "name_city": "LA SIERRA"
  },
  {
    "id": 1260,
    "zip_code": 194007,
    "dane_code": 19392000,
    "name_depto": "CAUCA",
    "name_city": "LA SIERRA"
  },
  {
    "id": 1261,
    "zip_code": 194008,
    "dane_code": 19392000,
    "name_depto": "CAUCA",
    "name_city": "LA SIERRA"
  },
  {
    "id": 1262,
    "zip_code": 194020,
    "dane_code": 19397000,
    "name_depto": "CAUCA",
    "name_city": "LA VEGA"
  },
  {
    "id": 1263,
    "zip_code": 194027,
    "dane_code": 19397000,
    "name_depto": "CAUCA",
    "name_city": "LA VEGA"
  },
  {
    "id": 1264,
    "zip_code": 194028,
    "dane_code": 19397000,
    "name_depto": "CAUCA",
    "name_city": "LA VEGA"
  },
  {
    "id": 1265,
    "zip_code": 194029,
    "dane_code": 19397000,
    "name_depto": "CAUCA",
    "name_city": "LA VEGA"
  },
  {
    "id": 1266,
    "zip_code": 194037,
    "dane_code": 19397000,
    "name_depto": "CAUCA",
    "name_city": "LA VEGA"
  },
  {
    "id": 1267,
    "zip_code": 194038,
    "dane_code": 19397000,
    "name_depto": "CAUCA",
    "name_city": "LA VEGA"
  },
  {
    "id": 1268,
    "zip_code": 194060,
    "dane_code": 19785000,
    "name_depto": "CAUCA",
    "name_city": "SUCRE"
  },
  {
    "id": 1269,
    "zip_code": 194067,
    "dane_code": 19785000,
    "name_depto": "CAUCA",
    "name_city": "SUCRE"
  },
  {
    "id": 1270,
    "zip_code": 194068,
    "dane_code": 19785000,
    "name_depto": "CAUCA",
    "name_city": "SUCRE"
  },
  {
    "id": 1271,
    "zip_code": 194080,
    "dane_code": 19022000,
    "name_depto": "CAUCA",
    "name_city": "ALMAGUER"
  },
  {
    "id": 1272,
    "zip_code": 194087,
    "dane_code": 19022000,
    "name_depto": "CAUCA",
    "name_city": "ALMAGUER"
  },
  {
    "id": 1273,
    "zip_code": 194088,
    "dane_code": 19022000,
    "name_depto": "CAUCA",
    "name_city": "ALMAGUER"
  },
  {
    "id": 1274,
    "zip_code": 194089,
    "dane_code": 19022000,
    "name_depto": "CAUCA",
    "name_city": "ALMAGUER"
  },
  {
    "id": 1275,
    "zip_code": 194501,
    "dane_code": 19693000,
    "name_depto": "CAUCA",
    "name_city": "SAN SEBASTIAN"
  },
  {
    "id": 1276,
    "zip_code": 194507,
    "dane_code": 19693000,
    "name_depto": "CAUCA",
    "name_city": "SAN SEBASTIAN"
  },
  {
    "id": 1277,
    "zip_code": 194508,
    "dane_code": 19693000,
    "name_depto": "CAUCA",
    "name_city": "SAN SEBASTIAN"
  },
  {
    "id": 1278,
    "zip_code": 194509,
    "dane_code": 19693000,
    "name_depto": "CAUCA",
    "name_city": "SAN SEBASTIAN"
  },
  {
    "id": 1279,
    "zip_code": 194520,
    "dane_code": 13683000,
    "name_depto": "CAUCA",
    "name_city": "SANTA ROSA"
  },
  {
    "id": 1280,
    "zip_code": 194527,
    "dane_code": 13683000,
    "name_depto": "CAUCA",
    "name_city": "SANTA ROSA"
  },
  {
    "id": 1281,
    "zip_code": 194528,
    "dane_code": 13683000,
    "name_depto": "CAUCA",
    "name_city": "SANTA ROSA"
  },
  {
    "id": 1282,
    "zip_code": 194550,
    "dane_code": 19533000,
    "name_depto": "CAUCA",
    "name_city": "PIAMONTE"
  },
  {
    "id": 1283,
    "zip_code": 194557,
    "dane_code": 19533000,
    "name_depto": "CAUCA",
    "name_city": "PIAMONTE"
  },
  {
    "id": 1284,
    "zip_code": 194558,
    "dane_code": 19533000,
    "name_depto": "CAUCA",
    "name_city": "PIAMONTE"
  },
  {
    "id": 1285,
    "zip_code": 195001,
    "dane_code": 19100000,
    "name_depto": "CAUCA",
    "name_city": "BOLIVAR"
  },
  {
    "id": 1286,
    "zip_code": 195007,
    "dane_code": 19100000,
    "name_depto": "CAUCA",
    "name_city": "BOLIVAR"
  },
  {
    "id": 1287,
    "zip_code": 195008,
    "dane_code": 19100000,
    "name_depto": "CAUCA",
    "name_city": "BOLIVAR"
  },
  {
    "id": 1288,
    "zip_code": 195009,
    "dane_code": 19100000,
    "name_depto": "CAUCA",
    "name_city": "BOLIVAR"
  },
  {
    "id": 1289,
    "zip_code": 195017,
    "dane_code": 19100000,
    "name_depto": "CAUCA",
    "name_city": "BOLIVAR"
  },
  {
    "id": 1290,
    "zip_code": 195018,
    "dane_code": 19100000,
    "name_depto": "CAUCA",
    "name_city": "BOLIVAR"
  },
  {
    "id": 1291,
    "zip_code": 195019,
    "dane_code": 19100000,
    "name_depto": "CAUCA",
    "name_city": "BOLIVAR"
  },
  {
    "id": 1292,
    "zip_code": 195040,
    "dane_code": 19290000,
    "name_depto": "CAUCA",
    "name_city": "FLORENCIA"
  },
  {
    "id": 1293,
    "zip_code": 195047,
    "dane_code": 19290000,
    "name_depto": "CAUCA",
    "name_city": "FLORENCIA"
  },
  {
    "id": 1294,
    "zip_code": 195060,
    "dane_code": 19450000,
    "name_depto": "CAUCA",
    "name_city": "MERCADERES"
  },
  {
    "id": 1295,
    "zip_code": 195067,
    "dane_code": 19450000,
    "name_depto": "CAUCA",
    "name_city": "MERCADERES"
  },
  {
    "id": 1296,
    "zip_code": 195068,
    "dane_code": 19450000,
    "name_depto": "CAUCA",
    "name_city": "MERCADERES"
  },
  {
    "id": 1297,
    "zip_code": 195069,
    "dane_code": 19450000,
    "name_depto": "CAUCA",
    "name_city": "MERCADERES"
  },
  {
    "id": 1298,
    "zip_code": 195501,
    "dane_code": 19532000,
    "name_depto": "CAUCA",
    "name_city": "PATIA"
  },
  {
    "id": 1299,
    "zip_code": 195507,
    "dane_code": 19532000,
    "name_depto": "CAUCA",
    "name_city": "PATIA"
  },
  {
    "id": 1300,
    "zip_code": 195508,
    "dane_code": 19532000,
    "name_depto": "CAUCA",
    "name_city": "PATIA"
  },
  {
    "id": 1301,
    "zip_code": 195509,
    "dane_code": 19532000,
    "name_depto": "CAUCA",
    "name_city": "PATIA"
  },
  {
    "id": 1302,
    "zip_code": 195530,
    "dane_code": 19075000,
    "name_depto": "CAUCA",
    "name_city": "BALBOA"
  },
  {
    "id": 1303,
    "zip_code": 195537,
    "dane_code": 19075000,
    "name_depto": "CAUCA",
    "name_city": "BALBOA"
  },
  {
    "id": 1304,
    "zip_code": 195538,
    "dane_code": 19075000,
    "name_depto": "CAUCA",
    "name_city": "BALBOA"
  },
  {
    "id": 1305,
    "zip_code": 195539,
    "dane_code": 19075000,
    "name_depto": "CAUCA",
    "name_city": "BALBOA"
  },
  {
    "id": 1306,
    "zip_code": 195547,
    "dane_code": 19075000,
    "name_depto": "CAUCA",
    "name_city": "BALBOA"
  },
  {
    "id": 1307,
    "zip_code": 195560,
    "dane_code": 19050000,
    "name_depto": "CAUCA",
    "name_city": "ARGELIA"
  },
  {
    "id": 1308,
    "zip_code": 195567,
    "dane_code": 19050000,
    "name_depto": "CAUCA",
    "name_city": "ARGELIA"
  },
  {
    "id": 1309,
    "zip_code": 196001,
    "dane_code": 19318000,
    "name_depto": "CAUCA",
    "name_city": "GUAPI"
  },
  {
    "id": 1310,
    "zip_code": 196007,
    "dane_code": 19318000,
    "name_depto": "CAUCA",
    "name_city": "GUAPI"
  },
  {
    "id": 1311,
    "zip_code": 196008,
    "dane_code": 19318000,
    "name_depto": "CAUCA",
    "name_city": "GUAPI"
  },
  {
    "id": 1312,
    "zip_code": 196009,
    "dane_code": 19318000,
    "name_depto": "CAUCA",
    "name_city": "GUAPI"
  },
  {
    "id": 1313,
    "zip_code": 196030,
    "dane_code": 19809000,
    "name_depto": "CAUCA",
    "name_city": "TIMBIQUI"
  },
  {
    "id": 1314,
    "zip_code": 196037,
    "dane_code": 19809000,
    "name_depto": "CAUCA",
    "name_city": "TIMBIQUI"
  },
  {
    "id": 1315,
    "zip_code": 196038,
    "dane_code": 19809000,
    "name_depto": "CAUCA",
    "name_city": "TIMBIQUI"
  },
  {
    "id": 1316,
    "zip_code": 196060,
    "dane_code": 19418000,
    "name_depto": "CAUCA",
    "name_city": "LOPEZ"
  },
  {
    "id": 1317,
    "zip_code": 196067,
    "dane_code": 19418000,
    "name_depto": "CAUCA",
    "name_city": "LOPEZ"
  },
  {
    "id": 1318,
    "zip_code": 196068,
    "dane_code": 19418000,
    "name_depto": "CAUCA",
    "name_city": "LOPEZ"
  },
  {
    "id": 1319,
    "zip_code": 200001,
    "dane_code": 20001000,
    "name_depto": "CESAR",
    "name_city": "VALLEDUPAR"
  },
  {
    "id": 1320,
    "zip_code": 200002,
    "dane_code": 20001000,
    "name_depto": "CESAR",
    "name_city": "VALLEDUPAR"
  },
  {
    "id": 1321,
    "zip_code": 200003,
    "dane_code": 20001000,
    "name_depto": "CESAR",
    "name_city": "VALLEDUPAR"
  },
  {
    "id": 1322,
    "zip_code": 200004,
    "dane_code": 20001000,
    "name_depto": "CESAR",
    "name_city": "VALLEDUPAR"
  },
  {
    "id": 1323,
    "zip_code": 200005,
    "dane_code": 20001000,
    "name_depto": "CESAR",
    "name_city": "VALLEDUPAR"
  },
  {
    "id": 1324,
    "zip_code": 200007,
    "dane_code": 20001000,
    "name_depto": "CESAR",
    "name_city": "VALLEDUPAR"
  },
  {
    "id": 1325,
    "zip_code": 200008,
    "dane_code": 20001000,
    "name_depto": "CESAR",
    "name_city": "VALLEDUPAR"
  },
  {
    "id": 1326,
    "zip_code": 200009,
    "dane_code": 20001000,
    "name_depto": "CESAR",
    "name_city": "VALLEDUPAR"
  },
  {
    "id": 1327,
    "zip_code": 200017,
    "dane_code": 20001000,
    "name_depto": "CESAR",
    "name_city": "VALLEDUPAR"
  },
  {
    "id": 1328,
    "zip_code": 200018,
    "dane_code": 20001000,
    "name_depto": "CESAR",
    "name_city": "VALLEDUPAR"
  },
  {
    "id": 1329,
    "zip_code": 201001,
    "dane_code": 20570000,
    "name_depto": "CESAR",
    "name_city": "PUEBLO BELLO"
  },
  {
    "id": 1330,
    "zip_code": 201007,
    "dane_code": 20570000,
    "name_depto": "CESAR",
    "name_city": "PUEBLO BELLO"
  },
  {
    "id": 1331,
    "zip_code": 201008,
    "dane_code": 20570000,
    "name_depto": "CESAR",
    "name_city": "PUEBLO BELLO"
  },
  {
    "id": 1332,
    "zip_code": 201010,
    "dane_code": 20238000,
    "name_depto": "CESAR",
    "name_city": "EL COPEY"
  },
  {
    "id": 1333,
    "zip_code": 201017,
    "dane_code": 20238000,
    "name_depto": "CESAR",
    "name_city": "EL COPEY"
  },
  {
    "id": 1334,
    "zip_code": 201018,
    "dane_code": 20238000,
    "name_depto": "CESAR",
    "name_city": "EL COPEY"
  },
  {
    "id": 1335,
    "zip_code": 201020,
    "dane_code": 20060000,
    "name_depto": "CESAR",
    "name_city": "BOSCONIA"
  },
  {
    "id": 1336,
    "zip_code": 201027,
    "dane_code": 20060000,
    "name_depto": "CESAR",
    "name_city": "BOSCONIA"
  },
  {
    "id": 1337,
    "zip_code": 201030,
    "dane_code": 20250000,
    "name_depto": "CESAR",
    "name_city": "EL PASO"
  },
  {
    "id": 1338,
    "zip_code": 201037,
    "dane_code": 20250000,
    "name_depto": "CESAR",
    "name_city": "EL PASO"
  },
  {
    "id": 1339,
    "zip_code": 201038,
    "dane_code": 20250000,
    "name_depto": "CESAR",
    "name_city": "EL PASO"
  },
  {
    "id": 1340,
    "zip_code": 201040,
    "dane_code": 20032000,
    "name_depto": "CESAR",
    "name_city": "ASTREA"
  },
  {
    "id": 1341,
    "zip_code": 201047,
    "dane_code": 20032000,
    "name_depto": "CESAR",
    "name_city": "ASTREA"
  },
  {
    "id": 1342,
    "zip_code": 201048,
    "dane_code": 20032000,
    "name_depto": "CESAR",
    "name_city": "ASTREA"
  },
  {
    "id": 1343,
    "zip_code": 201050,
    "dane_code": 20175000,
    "name_depto": "CESAR",
    "name_city": "CHIMICHAGUA"
  },
  {
    "id": 1344,
    "zip_code": 201057,
    "dane_code": 20175000,
    "name_depto": "CESAR",
    "name_city": "CHIMICHAGUA"
  },
  {
    "id": 1345,
    "zip_code": 201058,
    "dane_code": 20175000,
    "name_depto": "CESAR",
    "name_city": "CHIMICHAGUA"
  },
  {
    "id": 1346,
    "zip_code": 202001,
    "dane_code": 44560000,
    "name_depto": "CESAR",
    "name_city": "MANAURE"
  },
  {
    "id": 1347,
    "zip_code": 202007,
    "dane_code": 44560000,
    "name_depto": "CESAR",
    "name_city": "MANAURE"
  },
  {
    "id": 1348,
    "zip_code": 202010,
    "dane_code": 20621000,
    "name_depto": "CESAR",
    "name_city": "LA PAZ"
  },
  {
    "id": 1349,
    "zip_code": 202017,
    "dane_code": 20621000,
    "name_depto": "CESAR",
    "name_city": "LA PAZ"
  },
  {
    "id": 1350,
    "zip_code": 202018,
    "dane_code": 20621000,
    "name_depto": "CESAR",
    "name_city": "LA PAZ"
  },
  {
    "id": 1351,
    "zip_code": 202030,
    "dane_code": 20750000,
    "name_depto": "CESAR",
    "name_city": "SAN DIEGO"
  },
  {
    "id": 1352,
    "zip_code": 202037,
    "dane_code": 20750000,
    "name_depto": "CESAR",
    "name_city": "SAN DIEGO"
  },
  {
    "id": 1353,
    "zip_code": 202038,
    "dane_code": 20750000,
    "name_depto": "CESAR",
    "name_city": "SAN DIEGO"
  },
  {
    "id": 1354,
    "zip_code": 202050,
    "dane_code": 20013000,
    "name_depto": "CESAR",
    "name_city": "AGUSTIN CODAZZI"
  },
  {
    "id": 1355,
    "zip_code": 202057,
    "dane_code": 20013000,
    "name_depto": "CESAR",
    "name_city": "AGUSTIN CODAZZI"
  },
  {
    "id": 1356,
    "zip_code": 202058,
    "dane_code": 20013000,
    "name_depto": "CESAR",
    "name_city": "AGUSTIN CODAZZI"
  },
  {
    "id": 1357,
    "zip_code": 203001,
    "dane_code": 20045000,
    "name_depto": "CESAR",
    "name_city": "BECERRIL"
  },
  {
    "id": 1358,
    "zip_code": 203007,
    "dane_code": 20045000,
    "name_depto": "CESAR",
    "name_city": "BECERRIL"
  },
  {
    "id": 1359,
    "zip_code": 203020,
    "dane_code": 20400000,
    "name_depto": "CESAR",
    "name_city": "LA JAGUA DE IBIRICO"
  },
  {
    "id": 1360,
    "zip_code": 203027,
    "dane_code": 20400000,
    "name_depto": "CESAR",
    "name_city": "LA JAGUA DE IBIRICO"
  },
  {
    "id": 1361,
    "zip_code": 203040,
    "dane_code": 20178000,
    "name_depto": "CESAR",
    "name_city": "CHIRIGUANA"
  },
  {
    "id": 1362,
    "zip_code": 203047,
    "dane_code": 20178000,
    "name_depto": "CESAR",
    "name_city": "CHIRIGUANA"
  },
  {
    "id": 1363,
    "zip_code": 203048,
    "dane_code": 20178000,
    "name_depto": "CESAR",
    "name_city": "CHIRIGUANA"
  },
  {
    "id": 1364,
    "zip_code": 203060,
    "dane_code": 20228000,
    "name_depto": "CESAR",
    "name_city": "CURUMANI"
  },
  {
    "id": 1365,
    "zip_code": 203067,
    "dane_code": 20228000,
    "name_depto": "CESAR",
    "name_city": "CURUMANI"
  },
  {
    "id": 1366,
    "zip_code": 204001,
    "dane_code": 20517000,
    "name_depto": "CESAR",
    "name_city": "PAILITAS"
  },
  {
    "id": 1367,
    "zip_code": 204007,
    "dane_code": 20517000,
    "name_depto": "CESAR",
    "name_city": "PAILITAS"
  },
  {
    "id": 1368,
    "zip_code": 204020,
    "dane_code": 20787000,
    "name_depto": "CESAR",
    "name_city": "TAMALAMEQUE"
  },
  {
    "id": 1369,
    "zip_code": 204027,
    "dane_code": 20787000,
    "name_depto": "CESAR",
    "name_city": "TAMALAMEQUE"
  },
  {
    "id": 1370,
    "zip_code": 204028,
    "dane_code": 20787000,
    "name_depto": "CESAR",
    "name_city": "TAMALAMEQUE"
  },
  {
    "id": 1371,
    "zip_code": 204040,
    "dane_code": 20550000,
    "name_depto": "CESAR",
    "name_city": "PELAYA"
  },
  {
    "id": 1372,
    "zip_code": 204047,
    "dane_code": 20550000,
    "name_depto": "CESAR",
    "name_city": "PELAYA"
  },
  {
    "id": 1373,
    "zip_code": 204060,
    "dane_code": 20383000,
    "name_depto": "CESAR",
    "name_city": "LA GLORIA"
  },
  {
    "id": 1374,
    "zip_code": 204067,
    "dane_code": 20383000,
    "name_depto": "CESAR",
    "name_city": "LA GLORIA"
  },
  {
    "id": 1375,
    "zip_code": 204068,
    "dane_code": 20383000,
    "name_depto": "CESAR",
    "name_city": "LA GLORIA"
  },
  {
    "id": 1376,
    "zip_code": 205001,
    "dane_code": 20295000,
    "name_depto": "CESAR",
    "name_city": "GAMARRA"
  },
  {
    "id": 1377,
    "zip_code": 205007,
    "dane_code": 20295000,
    "name_depto": "CESAR",
    "name_city": "GAMARRA"
  },
  {
    "id": 1378,
    "zip_code": 205008,
    "dane_code": 20295000,
    "name_depto": "CESAR",
    "name_city": "GAMARRA"
  },
  {
    "id": 1379,
    "zip_code": 205010,
    "dane_code": 20011000,
    "name_depto": "CESAR",
    "name_city": "AGUACHICA"
  },
  {
    "id": 1380,
    "zip_code": 205017,
    "dane_code": 20011000,
    "name_depto": "CESAR",
    "name_city": "AGUACHICA"
  },
  {
    "id": 1381,
    "zip_code": 205018,
    "dane_code": 20011000,
    "name_depto": "CESAR",
    "name_city": "AGUACHICA"
  },
  {
    "id": 1382,
    "zip_code": 205030,
    "dane_code": 20310000,
    "name_depto": "CESAR",
    "name_city": "GONZALEZ"
  },
  {
    "id": 1383,
    "zip_code": 205037,
    "dane_code": 20310000,
    "name_depto": "CESAR",
    "name_city": "GONZALEZ"
  },
  {
    "id": 1384,
    "zip_code": 205040,
    "dane_code": 20614000,
    "name_depto": "CESAR",
    "name_city": "RIO DE ORO"
  },
  {
    "id": 1385,
    "zip_code": 205047,
    "dane_code": 20614000,
    "name_depto": "CESAR",
    "name_city": "RIO DE ORO"
  },
  {
    "id": 1386,
    "zip_code": 205048,
    "dane_code": 20614000,
    "name_depto": "CESAR",
    "name_city": "RIO DE ORO"
  },
  {
    "id": 1387,
    "zip_code": 205050,
    "dane_code": 20770000,
    "name_depto": "CESAR",
    "name_city": "SAN MARTIN"
  },
  {
    "id": 1388,
    "zip_code": 205057,
    "dane_code": 20770000,
    "name_depto": "CESAR",
    "name_city": "SAN MARTIN"
  },
  {
    "id": 1389,
    "zip_code": 205058,
    "dane_code": 20770000,
    "name_depto": "CESAR",
    "name_city": "SAN MARTIN"
  },
  {
    "id": 1390,
    "zip_code": 205059,
    "dane_code": 20770000,
    "name_depto": "CESAR",
    "name_city": "SAN MARTIN"
  },
  {
    "id": 1391,
    "zip_code": 205070,
    "dane_code": 20710000,
    "name_depto": "CESAR",
    "name_city": "SAN ALBERTO"
  },
  {
    "id": 1392,
    "zip_code": 205077,
    "dane_code": 20710000,
    "name_depto": "CESAR",
    "name_city": "SAN ALBERTO"
  },
  {
    "id": 1393,
    "zip_code": 205078,
    "dane_code": 20710000,
    "name_depto": "CESAR",
    "name_city": "SAN ALBERTO"
  },
  {
    "id": 1394,
    "zip_code": 230001,
    "dane_code": 23001000,
    "name_depto": "CORDOBA",
    "name_city": "MONTERIA"
  },
  {
    "id": 1395,
    "zip_code": 230002,
    "dane_code": 23001000,
    "name_depto": "CORDOBA",
    "name_city": "MONTERIA"
  },
  {
    "id": 1396,
    "zip_code": 230003,
    "dane_code": 23001000,
    "name_depto": "CORDOBA",
    "name_city": "MONTERIA"
  },
  {
    "id": 1397,
    "zip_code": 230004,
    "dane_code": 23001000,
    "name_depto": "CORDOBA",
    "name_city": "MONTERIA"
  },
  {
    "id": 1398,
    "zip_code": 230007,
    "dane_code": 23001000,
    "name_depto": "CORDOBA",
    "name_city": "MONTERIA"
  },
  {
    "id": 1399,
    "zip_code": 230008,
    "dane_code": 23001000,
    "name_depto": "CORDOBA",
    "name_city": "MONTERIA"
  },
  {
    "id": 1400,
    "zip_code": 230009,
    "dane_code": 23001000,
    "name_depto": "CORDOBA",
    "name_city": "MONTERIA"
  },
  {
    "id": 1401,
    "zip_code": 230017,
    "dane_code": 23001000,
    "name_depto": "CORDOBA",
    "name_city": "MONTERIA"
  },
  {
    "id": 1402,
    "zip_code": 230018,
    "dane_code": 23001000,
    "name_depto": "CORDOBA",
    "name_city": "MONTERIA"
  },
  {
    "id": 1403,
    "zip_code": 230019,
    "dane_code": 23001000,
    "name_depto": "CORDOBA",
    "name_city": "MONTERIA"
  },
  {
    "id": 1404,
    "zip_code": 230027,
    "dane_code": 23001000,
    "name_depto": "CORDOBA",
    "name_city": "MONTERIA"
  },
  {
    "id": 1405,
    "zip_code": 230028,
    "dane_code": 23001000,
    "name_depto": "CORDOBA",
    "name_city": "MONTERIA"
  },
  {
    "id": 1406,
    "zip_code": 230029,
    "dane_code": 23001000,
    "name_depto": "CORDOBA",
    "name_city": "MONTERIA"
  },
  {
    "id": 1407,
    "zip_code": 230501,
    "dane_code": 23300000,
    "name_depto": "CORDOBA",
    "name_city": "COTORRA"
  },
  {
    "id": 1408,
    "zip_code": 230507,
    "dane_code": 23300000,
    "name_depto": "CORDOBA",
    "name_city": "COTORRA"
  },
  {
    "id": 1409,
    "zip_code": 230520,
    "dane_code": 23686000,
    "name_depto": "CORDOBA",
    "name_city": "SAN PELAYO"
  },
  {
    "id": 1410,
    "zip_code": 230527,
    "dane_code": 23686000,
    "name_depto": "CORDOBA",
    "name_city": "SAN PELAYO"
  },
  {
    "id": 1411,
    "zip_code": 230528,
    "dane_code": 23686000,
    "name_depto": "CORDOBA",
    "name_city": "SAN PELAYO"
  },
  {
    "id": 1412,
    "zip_code": 230529,
    "dane_code": 23686000,
    "name_depto": "CORDOBA",
    "name_city": "SAN PELAYO"
  },
  {
    "id": 1413,
    "zip_code": 230537,
    "dane_code": 23686000,
    "name_depto": "CORDOBA",
    "name_city": "SAN PELAYO"
  },
  {
    "id": 1414,
    "zip_code": 230538,
    "dane_code": 23686000,
    "name_depto": "CORDOBA",
    "name_city": "SAN PELAYO"
  },
  {
    "id": 1415,
    "zip_code": 230550,
    "dane_code": 23162000,
    "name_depto": "CORDOBA",
    "name_city": "CERETE"
  },
  {
    "id": 1416,
    "zip_code": 230557,
    "dane_code": 23162000,
    "name_depto": "CORDOBA",
    "name_city": "CERETE"
  },
  {
    "id": 1417,
    "zip_code": 230558,
    "dane_code": 23162000,
    "name_depto": "CORDOBA",
    "name_city": "CERETE"
  },
  {
    "id": 1418,
    "zip_code": 230559,
    "dane_code": 23162000,
    "name_depto": "CORDOBA",
    "name_city": "CERETE"
  },
  {
    "id": 1419,
    "zip_code": 231001,
    "dane_code": 23500000,
    "name_depto": "CORDOBA",
    "name_city": "MO√ëITOS"
  },
  {
    "id": 1420,
    "zip_code": 231007,
    "dane_code": 23500000,
    "name_depto": "CORDOBA",
    "name_city": "MO√ëITOS"
  },
  {
    "id": 1421,
    "zip_code": 231008,
    "dane_code": 23500000,
    "name_depto": "CORDOBA",
    "name_city": "MO√ëITOS"
  },
  {
    "id": 1422,
    "zip_code": 231020,
    "dane_code": 23417000,
    "name_depto": "CORDOBA",
    "name_city": "LORICA"
  },
  {
    "id": 1423,
    "zip_code": 231027,
    "dane_code": 23417000,
    "name_depto": "CORDOBA",
    "name_city": "LORICA"
  },
  {
    "id": 1424,
    "zip_code": 231028,
    "dane_code": 23417000,
    "name_depto": "CORDOBA",
    "name_city": "LORICA"
  },
  {
    "id": 1425,
    "zip_code": 231029,
    "dane_code": 23417000,
    "name_depto": "CORDOBA",
    "name_city": "LORICA"
  },
  {
    "id": 1426,
    "zip_code": 231037,
    "dane_code": 23417000,
    "name_depto": "CORDOBA",
    "name_city": "LORICA"
  },
  {
    "id": 1427,
    "zip_code": 231038,
    "dane_code": 23417000,
    "name_depto": "CORDOBA",
    "name_city": "LORICA"
  },
  {
    "id": 1428,
    "zip_code": 231039,
    "dane_code": 23417000,
    "name_depto": "CORDOBA",
    "name_city": "LORICA"
  },
  {
    "id": 1429,
    "zip_code": 231047,
    "dane_code": 23417000,
    "name_depto": "CORDOBA",
    "name_city": "LORICA"
  },
  {
    "id": 1430,
    "zip_code": 231048,
    "dane_code": 23417000,
    "name_depto": "CORDOBA",
    "name_city": "LORICA"
  },
  {
    "id": 1431,
    "zip_code": 231501,
    "dane_code": 23675000,
    "name_depto": "CORDOBA",
    "name_city": "SAN BERNARDO DEL VIENTO"
  },
  {
    "id": 1432,
    "zip_code": 231507,
    "dane_code": 23675000,
    "name_depto": "CORDOBA",
    "name_city": "SAN BERNARDO DEL VIENTO"
  },
  {
    "id": 1433,
    "zip_code": 231508,
    "dane_code": 23675000,
    "name_depto": "CORDOBA",
    "name_city": "SAN BERNARDO DEL VIENTO"
  },
  {
    "id": 1434,
    "zip_code": 231509,
    "dane_code": 23675000,
    "name_depto": "CORDOBA",
    "name_city": "SAN BERNARDO DEL VIENTO"
  },
  {
    "id": 1435,
    "zip_code": 231520,
    "dane_code": 23672000,
    "name_depto": "CORDOBA",
    "name_city": "SAN ANTERO"
  },
  {
    "id": 1436,
    "zip_code": 231527,
    "dane_code": 23672000,
    "name_depto": "CORDOBA",
    "name_city": "SAN ANTERO"
  },
  {
    "id": 1437,
    "zip_code": 231528,
    "dane_code": 23672000,
    "name_depto": "CORDOBA",
    "name_city": "SAN ANTERO"
  },
  {
    "id": 1438,
    "zip_code": 231540,
    "dane_code": 23586000,
    "name_depto": "CORDOBA",
    "name_city": "PURISIMA"
  },
  {
    "id": 1439,
    "zip_code": 231547,
    "dane_code": 23586000,
    "name_depto": "CORDOBA",
    "name_city": "PURISIMA"
  },
  {
    "id": 1440,
    "zip_code": 232001,
    "dane_code": 23464000,
    "name_depto": "CORDOBA",
    "name_city": "MOMIL"
  },
  {
    "id": 1441,
    "zip_code": 232007,
    "dane_code": 23464000,
    "name_depto": "CORDOBA",
    "name_city": "MOMIL"
  },
  {
    "id": 1442,
    "zip_code": 232008,
    "dane_code": 23464000,
    "name_depto": "CORDOBA",
    "name_city": "MOMIL"
  },
  {
    "id": 1443,
    "zip_code": 232010,
    "dane_code": 23168000,
    "name_depto": "CORDOBA",
    "name_city": "CHIMA"
  },
  {
    "id": 1444,
    "zip_code": 232017,
    "dane_code": 23168000,
    "name_depto": "CORDOBA",
    "name_city": "CHIMA"
  },
  {
    "id": 1445,
    "zip_code": 232018,
    "dane_code": 23168000,
    "name_depto": "CORDOBA",
    "name_city": "CHIMA"
  },
  {
    "id": 1446,
    "zip_code": 232020,
    "dane_code": 23815000,
    "name_depto": "CORDOBA",
    "name_city": "TUCHIN"
  },
  {
    "id": 1447,
    "zip_code": 232027,
    "dane_code": 23815000,
    "name_depto": "CORDOBA",
    "name_city": "TUCHIN"
  },
  {
    "id": 1448,
    "zip_code": 232030,
    "dane_code": 23670000,
    "name_depto": "CORDOBA",
    "name_city": "SAN ANDRES SOTAVENTO"
  },
  {
    "id": 1449,
    "zip_code": 232038,
    "dane_code": 23670000,
    "name_depto": "CORDOBA",
    "name_city": "SAN ANDRES SOTAVENTO"
  },
  {
    "id": 1450,
    "zip_code": 232039,
    "dane_code": 23670000,
    "name_depto": "CORDOBA",
    "name_city": "SAN ANDRES SOTAVENTO"
  },
  {
    "id": 1451,
    "zip_code": 232050,
    "dane_code": 23182000,
    "name_depto": "CORDOBA",
    "name_city": "CHINU"
  },
  {
    "id": 1452,
    "zip_code": 232057,
    "dane_code": 23182000,
    "name_depto": "CORDOBA",
    "name_city": "CHINU"
  },
  {
    "id": 1453,
    "zip_code": 232058,
    "dane_code": 23182000,
    "name_depto": "CORDOBA",
    "name_city": "CHINU"
  },
  {
    "id": 1454,
    "zip_code": 232059,
    "dane_code": 23182000,
    "name_depto": "CORDOBA",
    "name_city": "CHINU"
  },
  {
    "id": 1455,
    "zip_code": 232501,
    "dane_code": 23678015,
    "name_depto": "CORDOBA",
    "name_city": "SAN CARLOS"
  },
  {
    "id": 1456,
    "zip_code": 232507,
    "dane_code": 23678015,
    "name_depto": "CORDOBA",
    "name_city": "SAN CARLOS"
  },
  {
    "id": 1457,
    "zip_code": 232508,
    "dane_code": 23678015,
    "name_depto": "CORDOBA",
    "name_city": "SAN CARLOS"
  },
  {
    "id": 1458,
    "zip_code": 232509,
    "dane_code": 23678015,
    "name_depto": "CORDOBA",
    "name_city": "SAN CARLOS"
  },
  {
    "id": 1459,
    "zip_code": 232517,
    "dane_code": 23678015,
    "name_depto": "CORDOBA",
    "name_city": "SAN CARLOS"
  },
  {
    "id": 1460,
    "zip_code": 232520,
    "dane_code": 23189000,
    "name_depto": "CORDOBA",
    "name_city": "CIENAGA DE ORO"
  },
  {
    "id": 1461,
    "zip_code": 232527,
    "dane_code": 23189000,
    "name_depto": "CORDOBA",
    "name_city": "CIENAGA DE ORO"
  },
  {
    "id": 1462,
    "zip_code": 232528,
    "dane_code": 23189000,
    "name_depto": "CORDOBA",
    "name_city": "CIENAGA DE ORO"
  },
  {
    "id": 1463,
    "zip_code": 232529,
    "dane_code": 23189000,
    "name_depto": "CORDOBA",
    "name_city": "CIENAGA DE ORO"
  },
  {
    "id": 1464,
    "zip_code": 232537,
    "dane_code": 23189000,
    "name_depto": "CORDOBA",
    "name_city": "CIENAGA DE ORO"
  },
  {
    "id": 1465,
    "zip_code": 232538,
    "dane_code": 23189000,
    "name_depto": "CORDOBA",
    "name_city": "CIENAGA DE ORO"
  },
  {
    "id": 1466,
    "zip_code": 232540,
    "dane_code": 23660000,
    "name_depto": "CORDOBA",
    "name_city": "SAHAGUN"
  },
  {
    "id": 1467,
    "zip_code": 232547,
    "dane_code": 23660000,
    "name_depto": "CORDOBA",
    "name_city": "SAHAGUN"
  },
  {
    "id": 1468,
    "zip_code": 232548,
    "dane_code": 23660000,
    "name_depto": "CORDOBA",
    "name_city": "SAHAGUN"
  },
  {
    "id": 1469,
    "zip_code": 232549,
    "dane_code": 23660000,
    "name_depto": "CORDOBA",
    "name_city": "SAHAGUN"
  },
  {
    "id": 1470,
    "zip_code": 232557,
    "dane_code": 23660000,
    "name_depto": "CORDOBA",
    "name_city": "SAHAGUN"
  },
  {
    "id": 1471,
    "zip_code": 232558,
    "dane_code": 23660000,
    "name_depto": "CORDOBA",
    "name_city": "SAHAGUN"
  },
  {
    "id": 1472,
    "zip_code": 232559,
    "dane_code": 23660000,
    "name_depto": "CORDOBA",
    "name_city": "SAHAGUN"
  },
  {
    "id": 1473,
    "zip_code": 233001,
    "dane_code": 23570000,
    "name_depto": "CORDOBA",
    "name_city": "PUEBLO NUEVO"
  },
  {
    "id": 1474,
    "zip_code": 233007,
    "dane_code": 23570000,
    "name_depto": "CORDOBA",
    "name_city": "PUEBLO NUEVO"
  },
  {
    "id": 1475,
    "zip_code": 233008,
    "dane_code": 23570000,
    "name_depto": "CORDOBA",
    "name_city": "PUEBLO NUEVO"
  },
  {
    "id": 1476,
    "zip_code": 233009,
    "dane_code": 23570000,
    "name_depto": "CORDOBA",
    "name_city": "PUEBLO NUEVO"
  },
  {
    "id": 1477,
    "zip_code": 233020,
    "dane_code": 23079000,
    "name_depto": "CORDOBA",
    "name_city": "BUENAVISTA"
  },
  {
    "id": 1478,
    "zip_code": 233027,
    "dane_code": 23079000,
    "name_depto": "CORDOBA",
    "name_city": "BUENAVISTA"
  },
  {
    "id": 1479,
    "zip_code": 233028,
    "dane_code": 23079000,
    "name_depto": "CORDOBA",
    "name_city": "BUENAVISTA"
  },
  {
    "id": 1480,
    "zip_code": 233040,
    "dane_code": 23555000,
    "name_depto": "CORDOBA",
    "name_city": "PLANETA RICA"
  },
  {
    "id": 1481,
    "zip_code": 233047,
    "dane_code": 23555000,
    "name_depto": "CORDOBA",
    "name_city": "PLANETA RICA"
  },
  {
    "id": 1482,
    "zip_code": 233048,
    "dane_code": 23555000,
    "name_depto": "CORDOBA",
    "name_city": "PLANETA RICA"
  },
  {
    "id": 1483,
    "zip_code": 233049,
    "dane_code": 23555000,
    "name_depto": "CORDOBA",
    "name_city": "PLANETA RICA"
  },
  {
    "id": 1484,
    "zip_code": 233057,
    "dane_code": 23555000,
    "name_depto": "CORDOBA",
    "name_city": "PLANETA RICA"
  },
  {
    "id": 1485,
    "zip_code": 233501,
    "dane_code": 23350000,
    "name_depto": "CORDOBA",
    "name_city": "LA APARTADA"
  },
  {
    "id": 1486,
    "zip_code": 233507,
    "dane_code": 23350000,
    "name_depto": "CORDOBA",
    "name_city": "LA APARTADA"
  },
  {
    "id": 1487,
    "zip_code": 233530,
    "dane_code": 23068000,
    "name_depto": "CORDOBA",
    "name_city": "AYAPEL"
  },
  {
    "id": 1488,
    "zip_code": 233537,
    "dane_code": 23068000,
    "name_depto": "CORDOBA",
    "name_city": "AYAPEL"
  },
  {
    "id": 1489,
    "zip_code": 233538,
    "dane_code": 23068000,
    "name_depto": "CORDOBA",
    "name_city": "AYAPEL"
  },
  {
    "id": 1490,
    "zip_code": 233539,
    "dane_code": 23068000,
    "name_depto": "CORDOBA",
    "name_city": "AYAPEL"
  },
  {
    "id": 1491,
    "zip_code": 234001,
    "dane_code": 23466000,
    "name_depto": "CORDOBA",
    "name_city": "MONTELIBANO"
  },
  {
    "id": 1492,
    "zip_code": 234007,
    "dane_code": 23466000,
    "name_depto": "CORDOBA",
    "name_city": "MONTELIBANO"
  },
  {
    "id": 1493,
    "zip_code": 234008,
    "dane_code": 23466000,
    "name_depto": "CORDOBA",
    "name_city": "MONTELIBANO"
  },
  {
    "id": 1494,
    "zip_code": 234010,
    "dane_code": 23682000,
    "name_depto": "CORDOBA",
    "name_city": "SAN JOSE DE URE"
  },
  {
    "id": 1495,
    "zip_code": 234017,
    "dane_code": 23682000,
    "name_depto": "CORDOBA",
    "name_city": "SAN JOSE DE URE"
  },
  {
    "id": 1496,
    "zip_code": 234030,
    "dane_code": 23580000,
    "name_depto": "CORDOBA",
    "name_city": "PUERTO LIBERTADOR"
  },
  {
    "id": 1497,
    "zip_code": 234037,
    "dane_code": 23580000,
    "name_depto": "CORDOBA",
    "name_city": "PUERTO LIBERTADOR"
  },
  {
    "id": 1498,
    "zip_code": 234038,
    "dane_code": 23580000,
    "name_depto": "CORDOBA",
    "name_city": "PUERTO LIBERTADOR"
  },
  {
    "id": 1499,
    "zip_code": 234039,
    "dane_code": 23580000,
    "name_depto": "CORDOBA",
    "name_city": "PUERTO LIBERTADOR"
  },
  {
    "id": 1500,
    "zip_code": 234501,
    "dane_code": 23807000,
    "name_depto": "CORDOBA",
    "name_city": "TIERRALTA"
  },
  {
    "id": 1501,
    "zip_code": 234507,
    "dane_code": 23807000,
    "name_depto": "CORDOBA",
    "name_city": "TIERRALTA"
  },
  {
    "id": 1502,
    "zip_code": 234508,
    "dane_code": 23807000,
    "name_depto": "CORDOBA",
    "name_city": "TIERRALTA"
  },
  {
    "id": 1503,
    "zip_code": 234509,
    "dane_code": 23807000,
    "name_depto": "CORDOBA",
    "name_city": "TIERRALTA"
  },
  {
    "id": 1504,
    "zip_code": 234517,
    "dane_code": 23807000,
    "name_depto": "CORDOBA",
    "name_city": "TIERRALTA"
  },
  {
    "id": 1505,
    "zip_code": 234518,
    "dane_code": 23807000,
    "name_depto": "CORDOBA",
    "name_city": "TIERRALTA"
  },
  {
    "id": 1506,
    "zip_code": 234530,
    "dane_code": 23855000,
    "name_depto": "CORDOBA",
    "name_city": "VALENCIA"
  },
  {
    "id": 1507,
    "zip_code": 234537,
    "dane_code": 23855000,
    "name_depto": "CORDOBA",
    "name_city": "VALENCIA"
  },
  {
    "id": 1508,
    "zip_code": 234538,
    "dane_code": 23855000,
    "name_depto": "CORDOBA",
    "name_city": "VALENCIA"
  },
  {
    "id": 1509,
    "zip_code": 234539,
    "dane_code": 23855000,
    "name_depto": "CORDOBA",
    "name_city": "VALENCIA"
  },
  {
    "id": 1510,
    "zip_code": 235001,
    "dane_code": 23574000,
    "name_depto": "CORDOBA",
    "name_city": "PUERTO ESCONDIDO"
  },
  {
    "id": 1511,
    "zip_code": 235007,
    "dane_code": 23574000,
    "name_depto": "CORDOBA",
    "name_city": "PUERTO ESCONDIDO"
  },
  {
    "id": 1512,
    "zip_code": 235008,
    "dane_code": 23574000,
    "name_depto": "CORDOBA",
    "name_city": "PUERTO ESCONDIDO"
  },
  {
    "id": 1513,
    "zip_code": 235020,
    "dane_code": 23419000,
    "name_depto": "CORDOBA",
    "name_city": "LOS CORDOBAS"
  },
  {
    "id": 1514,
    "zip_code": 235027,
    "dane_code": 23419000,
    "name_depto": "CORDOBA",
    "name_city": "LOS CORDOBAS"
  },
  {
    "id": 1515,
    "zip_code": 235028,
    "dane_code": 23419000,
    "name_depto": "CORDOBA",
    "name_city": "LOS CORDOBAS"
  },
  {
    "id": 1516,
    "zip_code": 235040,
    "dane_code": 23090000,
    "name_depto": "CORDOBA",
    "name_city": "CANALETE"
  },
  {
    "id": 1517,
    "zip_code": 235047,
    "dane_code": 23090000,
    "name_depto": "CORDOBA",
    "name_city": "CANALETE"
  },
  {
    "id": 1518,
    "zip_code": 235048,
    "dane_code": 23090000,
    "name_depto": "CORDOBA",
    "name_city": "CANALETE"
  },
  {
    "id": 1519,
    "zip_code": 250001,
    "dane_code": 25175000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "CHIA"
  },
  {
    "id": 1520,
    "zip_code": 250002,
    "dane_code": 25175000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "CHIA"
  },
  {
    "id": 1521,
    "zip_code": 250007,
    "dane_code": 25175000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "CHIA"
  },
  {
    "id": 1522,
    "zip_code": 250008,
    "dane_code": 25175000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "CHIA"
  },
  {
    "id": 1523,
    "zip_code": 250010,
    "dane_code": 25214000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "COTA"
  },
  {
    "id": 1524,
    "zip_code": 250017,
    "dane_code": 25214000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "COTA"
  },
  {
    "id": 1525,
    "zip_code": 250020,
    "dane_code": 25286000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "FUNZA"
  },
  {
    "id": 1526,
    "zip_code": 250027,
    "dane_code": 25286000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "FUNZA"
  },
  {
    "id": 1527,
    "zip_code": 250030,
    "dane_code": 25430000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "MADRID"
  },
  {
    "id": 1528,
    "zip_code": 250037,
    "dane_code": 25430000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "MADRID"
  },
  {
    "id": 1529,
    "zip_code": 250038,
    "dane_code": 25430000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "MADRID"
  },
  {
    "id": 1530,
    "zip_code": 250040,
    "dane_code": 25473000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "MOSQUERA"
  },
  {
    "id": 1531,
    "zip_code": 250047,
    "dane_code": 25473000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "MOSQUERA"
  },
  {
    "id": 1532,
    "zip_code": 250051,
    "dane_code": 25754000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SOACHA"
  },
  {
    "id": 1533,
    "zip_code": 250052,
    "dane_code": 25754000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SOACHA"
  },
  {
    "id": 1534,
    "zip_code": 250053,
    "dane_code": 25754000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SOACHA"
  },
  {
    "id": 1535,
    "zip_code": 250054,
    "dane_code": 25754000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SOACHA"
  },
  {
    "id": 1536,
    "zip_code": 250055,
    "dane_code": 25754000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SOACHA"
  },
  {
    "id": 1537,
    "zip_code": 250057,
    "dane_code": 25754000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SOACHA"
  },
  {
    "id": 1538,
    "zip_code": 250058,
    "dane_code": 25754000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SOACHA"
  },
  {
    "id": 1539,
    "zip_code": 250070,
    "dane_code": 25740000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SIBATE"
  },
  {
    "id": 1540,
    "zip_code": 250077,
    "dane_code": 25740000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SIBATE"
  },
  {
    "id": 1541,
    "zip_code": 250078,
    "dane_code": 25740000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SIBATE"
  },
  {
    "id": 1542,
    "zip_code": 250201,
    "dane_code": 25799000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "TENJO"
  },
  {
    "id": 1543,
    "zip_code": 250207,
    "dane_code": 25799000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "TENJO"
  },
  {
    "id": 1544,
    "zip_code": 250208,
    "dane_code": 25799000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "TENJO"
  },
  {
    "id": 1545,
    "zip_code": 250210,
    "dane_code": 25260000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "EL ROSAL"
  },
  {
    "id": 1546,
    "zip_code": 250217,
    "dane_code": 25260000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "EL ROSAL"
  },
  {
    "id": 1547,
    "zip_code": 250220,
    "dane_code": 25769000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SUBACHOQUE"
  },
  {
    "id": 1548,
    "zip_code": 250227,
    "dane_code": 25769000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SUBACHOQUE"
  },
  {
    "id": 1549,
    "zip_code": 250228,
    "dane_code": 25769000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SUBACHOQUE"
  },
  {
    "id": 1550,
    "zip_code": 250230,
    "dane_code": 25785000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "TABIO"
  },
  {
    "id": 1551,
    "zip_code": 250237,
    "dane_code": 25785000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "TABIO"
  },
  {
    "id": 1552,
    "zip_code": 250240,
    "dane_code": 25126000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "CAJICA"
  },
  {
    "id": 1553,
    "zip_code": 250247,
    "dane_code": 25126000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "CAJICA"
  },
  {
    "id": 1554,
    "zip_code": 250251,
    "dane_code": 25899000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "ZIPAQUIRA"
  },
  {
    "id": 1555,
    "zip_code": 250252,
    "dane_code": 25899000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "ZIPAQUIRA"
  },
  {
    "id": 1556,
    "zip_code": 250257,
    "dane_code": 25899000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "ZIPAQUIRA"
  },
  {
    "id": 1557,
    "zip_code": 250258,
    "dane_code": 25899000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "ZIPAQUIRA"
  },
  {
    "id": 1558,
    "zip_code": 250401,
    "dane_code": 25200000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "COGUA"
  },
  {
    "id": 1559,
    "zip_code": 250407,
    "dane_code": 25200000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "COGUA"
  },
  {
    "id": 1560,
    "zip_code": 250408,
    "dane_code": 25200000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "COGUA"
  },
  {
    "id": 1561,
    "zip_code": 250410,
    "dane_code": 25793000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "TAUSA"
  },
  {
    "id": 1562,
    "zip_code": 250417,
    "dane_code": 25793000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "TAUSA"
  },
  {
    "id": 1563,
    "zip_code": 250418,
    "dane_code": 25793000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "TAUSA"
  },
  {
    "id": 1564,
    "zip_code": 250420,
    "dane_code": 25154000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "CARMEN DE CARUPA"
  },
  {
    "id": 1565,
    "zip_code": 250427,
    "dane_code": 25154000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "CARMEN DE CARUPA"
  },
  {
    "id": 1566,
    "zip_code": 250428,
    "dane_code": 25154000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "CARMEN DE CARUPA"
  },
  {
    "id": 1567,
    "zip_code": 250430,
    "dane_code": 25843000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "VILLA DE SAN DIEGO DE UBATE"
  },
  {
    "id": 1568,
    "zip_code": 250437,
    "dane_code": 25843000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "VILLA DE SAN DIEGO DE UBATE"
  },
  {
    "id": 1569,
    "zip_code": 250440,
    "dane_code": 25781000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SUTATAUSA"
  },
  {
    "id": 1570,
    "zip_code": 250447,
    "dane_code": 25781000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SUTATAUSA"
  },
  {
    "id": 1571,
    "zip_code": 250450,
    "dane_code": 25224000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "CUCUNUBA"
  },
  {
    "id": 1572,
    "zip_code": 250457,
    "dane_code": 25224000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "CUCUNUBA"
  },
  {
    "id": 1573,
    "zip_code": 250601,
    "dane_code": 25407000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "LENGUAZAQUE"
  },
  {
    "id": 1574,
    "zip_code": 250607,
    "dane_code": 25407000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "LENGUAZAQUE"
  },
  {
    "id": 1575,
    "zip_code": 250608,
    "dane_code": 25407000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "LENGUAZAQUE"
  },
  {
    "id": 1576,
    "zip_code": 250610,
    "dane_code": 25317000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GUACHETA"
  },
  {
    "id": 1577,
    "zip_code": 250617,
    "dane_code": 25317000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GUACHETA"
  },
  {
    "id": 1578,
    "zip_code": 250618,
    "dane_code": 25317000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GUACHETA"
  },
  {
    "id": 1579,
    "zip_code": 250620,
    "dane_code": 25288000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "FUQUENE"
  },
  {
    "id": 1580,
    "zip_code": 250627,
    "dane_code": 25288000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "FUQUENE"
  },
  {
    "id": 1581,
    "zip_code": 250630,
    "dane_code": 25779000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SUSA"
  },
  {
    "id": 1582,
    "zip_code": 250637,
    "dane_code": 25779000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SUSA"
  },
  {
    "id": 1583,
    "zip_code": 250640,
    "dane_code": 25745000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SIMIJACA"
  },
  {
    "id": 1584,
    "zip_code": 250647,
    "dane_code": 25745000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SIMIJACA"
  },
  {
    "id": 1585,
    "zip_code": 250801,
    "dane_code": 25183000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "CHOCONTA"
  },
  {
    "id": 1586,
    "zip_code": 250807,
    "dane_code": 25183000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "CHOCONTA"
  },
  {
    "id": 1587,
    "zip_code": 250808,
    "dane_code": 25183000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "CHOCONTA"
  },
  {
    "id": 1588,
    "zip_code": 250810,
    "dane_code": 25873000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "VILLAPINZON"
  },
  {
    "id": 1589,
    "zip_code": 250817,
    "dane_code": 25873000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "VILLAPINZON"
  },
  {
    "id": 1590,
    "zip_code": 250818,
    "dane_code": 25873000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "VILLAPINZON"
  },
  {
    "id": 1591,
    "zip_code": 250820,
    "dane_code": 25807000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "TIBIRITA"
  },
  {
    "id": 1592,
    "zip_code": 250827,
    "dane_code": 25807000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "TIBIRITA"
  },
  {
    "id": 1593,
    "zip_code": 250830,
    "dane_code": 25436000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "MANTA"
  },
  {
    "id": 1594,
    "zip_code": 250837,
    "dane_code": 25436000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "MANTA"
  },
  {
    "id": 1595,
    "zip_code": 250840,
    "dane_code": 25426000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "MACHETA"
  },
  {
    "id": 1596,
    "zip_code": 250847,
    "dane_code": 25426000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "MACHETA"
  },
  {
    "id": 1597,
    "zip_code": 250848,
    "dane_code": 25426000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "MACHETA"
  },
  {
    "id": 1598,
    "zip_code": 251001,
    "dane_code": 25758000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SOPO"
  },
  {
    "id": 1599,
    "zip_code": 251007,
    "dane_code": 25758000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SOPO"
  },
  {
    "id": 1600,
    "zip_code": 251008,
    "dane_code": 25758000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SOPO"
  },
  {
    "id": 1601,
    "zip_code": 251010,
    "dane_code": 25817000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "TOCANCIPA"
  },
  {
    "id": 1602,
    "zip_code": 251017,
    "dane_code": 25817000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "TOCANCIPA"
  },
  {
    "id": 1603,
    "zip_code": 251020,
    "dane_code": 25295000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GACHANCIPA"
  },
  {
    "id": 1604,
    "zip_code": 251027,
    "dane_code": 25295000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GACHANCIPA"
  },
  {
    "id": 1605,
    "zip_code": 251030,
    "dane_code": 25486000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "NEMOCON"
  },
  {
    "id": 1606,
    "zip_code": 251037,
    "dane_code": 25486000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "NEMOCON"
  },
  {
    "id": 1607,
    "zip_code": 251038,
    "dane_code": 25486000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "NEMOCON"
  },
  {
    "id": 1608,
    "zip_code": 251040,
    "dane_code": 25772000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SUESCA"
  },
  {
    "id": 1609,
    "zip_code": 251047,
    "dane_code": 25772000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SUESCA"
  },
  {
    "id": 1610,
    "zip_code": 251048,
    "dane_code": 25772000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SUESCA"
  },
  {
    "id": 1611,
    "zip_code": 251050,
    "dane_code": 25736000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SESQUILE"
  },
  {
    "id": 1612,
    "zip_code": 251057,
    "dane_code": 25736000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SESQUILE"
  },
  {
    "id": 1613,
    "zip_code": 251058,
    "dane_code": 25736000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SESQUILE"
  },
  {
    "id": 1614,
    "zip_code": 251060,
    "dane_code": 25326000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GUATAVITA"
  },
  {
    "id": 1615,
    "zip_code": 251067,
    "dane_code": 25326000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GUATAVITA"
  },
  {
    "id": 1616,
    "zip_code": 251201,
    "dane_code": 25377000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "LA CALERA"
  },
  {
    "id": 1617,
    "zip_code": 251207,
    "dane_code": 25377000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "LA CALERA"
  },
  {
    "id": 1618,
    "zip_code": 251208,
    "dane_code": 25377000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "LA CALERA"
  },
  {
    "id": 1619,
    "zip_code": 251210,
    "dane_code": 25322000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GUASCA"
  },
  {
    "id": 1620,
    "zip_code": 251217,
    "dane_code": 25322000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GUASCA"
  },
  {
    "id": 1621,
    "zip_code": 251218,
    "dane_code": 25322000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GUASCA"
  },
  {
    "id": 1622,
    "zip_code": 251220,
    "dane_code": 25372000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "JUNIN"
  },
  {
    "id": 1623,
    "zip_code": 251227,
    "dane_code": 25372000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "JUNIN"
  },
  {
    "id": 1624,
    "zip_code": 251230,
    "dane_code": 25297000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GACHETA"
  },
  {
    "id": 1625,
    "zip_code": 251237,
    "dane_code": 25297000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GACHETA"
  },
  {
    "id": 1626,
    "zip_code": 251238,
    "dane_code": 25297000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GACHETA"
  },
  {
    "id": 1627,
    "zip_code": 251240,
    "dane_code": 25299000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GAMA"
  },
  {
    "id": 1628,
    "zip_code": 251247,
    "dane_code": 25299000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GAMA"
  },
  {
    "id": 1629,
    "zip_code": 251250,
    "dane_code": 25293000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GACHALA"
  },
  {
    "id": 1630,
    "zip_code": 251257,
    "dane_code": 25293000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GACHALA"
  },
  {
    "id": 1631,
    "zip_code": 251260,
    "dane_code": 25839000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "UBALA"
  },
  {
    "id": 1632,
    "zip_code": 251267,
    "dane_code": 25839000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "UBALA"
  },
  {
    "id": 1633,
    "zip_code": 251268,
    "dane_code": 25839000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "UBALA"
  },
  {
    "id": 1634,
    "zip_code": 251401,
    "dane_code": 25530000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "PARATEBUENO"
  },
  {
    "id": 1635,
    "zip_code": 251407,
    "dane_code": 25530000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "PARATEBUENO"
  },
  {
    "id": 1636,
    "zip_code": 251420,
    "dane_code": 25438000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "MEDINA"
  },
  {
    "id": 1637,
    "zip_code": 251427,
    "dane_code": 25438000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "MEDINA"
  },
  {
    "id": 1638,
    "zip_code": 251428,
    "dane_code": 25438000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "MEDINA"
  },
  {
    "id": 1639,
    "zip_code": 251601,
    "dane_code": 25841000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "UBAQUE"
  },
  {
    "id": 1640,
    "zip_code": 251607,
    "dane_code": 25841000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "UBAQUE"
  },
  {
    "id": 1641,
    "zip_code": 251620,
    "dane_code": 25181000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "CHOACHI"
  },
  {
    "id": 1642,
    "zip_code": 251627,
    "dane_code": 25181000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "CHOACHI"
  },
  {
    "id": 1643,
    "zip_code": 251628,
    "dane_code": 25181000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "CHOACHI"
  },
  {
    "id": 1644,
    "zip_code": 251640,
    "dane_code": 25279000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "FOMEQUE"
  },
  {
    "id": 1645,
    "zip_code": 251647,
    "dane_code": 25279000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "FOMEQUE"
  },
  {
    "id": 1646,
    "zip_code": 251648,
    "dane_code": 25279000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "FOMEQUE"
  },
  {
    "id": 1647,
    "zip_code": 251801,
    "dane_code": 25178000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "CHIPAQUE"
  },
  {
    "id": 1648,
    "zip_code": 251807,
    "dane_code": 25178000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "CHIPAQUE"
  },
  {
    "id": 1649,
    "zip_code": 251808,
    "dane_code": 25178000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "CHIPAQUE"
  },
  {
    "id": 1650,
    "zip_code": 251810,
    "dane_code": 25845000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "UNE"
  },
  {
    "id": 1651,
    "zip_code": 251817,
    "dane_code": 25845000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "UNE"
  },
  {
    "id": 1652,
    "zip_code": 251820,
    "dane_code": 25151000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "CAQUEZA"
  },
  {
    "id": 1653,
    "zip_code": 251827,
    "dane_code": 25151000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "CAQUEZA"
  },
  {
    "id": 1654,
    "zip_code": 251830,
    "dane_code": 25281000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "FOSCA"
  },
  {
    "id": 1655,
    "zip_code": 251837,
    "dane_code": 25281000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "FOSCA"
  },
  {
    "id": 1656,
    "zip_code": 251840,
    "dane_code": 25594000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "QUETAME"
  },
  {
    "id": 1657,
    "zip_code": 251847,
    "dane_code": 25594000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "QUETAME"
  },
  {
    "id": 1658,
    "zip_code": 251850,
    "dane_code": 25335000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GUAYABETAL"
  },
  {
    "id": 1659,
    "zip_code": 251857,
    "dane_code": 25335000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GUAYABETAL"
  },
  {
    "id": 1660,
    "zip_code": 251860,
    "dane_code": 25339000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GUTIERREZ"
  },
  {
    "id": 1661,
    "zip_code": 251867,
    "dane_code": 25339000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GUTIERREZ"
  },
  {
    "id": 1662,
    "zip_code": 252001,
    "dane_code": 25053000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "ARBELAEZ"
  },
  {
    "id": 1663,
    "zip_code": 252007,
    "dane_code": 25053000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "ARBELAEZ"
  },
  {
    "id": 1664,
    "zip_code": 252008,
    "dane_code": 25053000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "ARBELAEZ"
  },
  {
    "id": 1665,
    "zip_code": 252010,
    "dane_code": 25524000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "PANDI"
  },
  {
    "id": 1666,
    "zip_code": 252017,
    "dane_code": 25524000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "PANDI"
  },
  {
    "id": 1667,
    "zip_code": 252020,
    "dane_code": 25649000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SAN BERNARDO"
  },
  {
    "id": 1668,
    "zip_code": 252027,
    "dane_code": 25649000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SAN BERNARDO"
  },
  {
    "id": 1669,
    "zip_code": 252028,
    "dane_code": 25649000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SAN BERNARDO"
  },
  {
    "id": 1670,
    "zip_code": 252030,
    "dane_code": 25506000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "VENECIA"
  },
  {
    "id": 1671,
    "zip_code": 252037,
    "dane_code": 25506000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "VENECIA"
  },
  {
    "id": 1672,
    "zip_code": 252040,
    "dane_code": 25120000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "CABRERA"
  },
  {
    "id": 1673,
    "zip_code": 252047,
    "dane_code": 25120000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "CABRERA"
  },
  {
    "id": 1674,
    "zip_code": 252201,
    "dane_code": 25535000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "PASCA"
  },
  {
    "id": 1675,
    "zip_code": 252207,
    "dane_code": 25535000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "PASCA"
  },
  {
    "id": 1676,
    "zip_code": 252208,
    "dane_code": 25535000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "PASCA"
  },
  {
    "id": 1677,
    "zip_code": 252211,
    "dane_code": 25290000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "FUSAGASUGA"
  },
  {
    "id": 1678,
    "zip_code": 252212,
    "dane_code": 25290000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "FUSAGASUGA"
  },
  {
    "id": 1679,
    "zip_code": 252217,
    "dane_code": 25290000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "FUSAGASUGA"
  },
  {
    "id": 1680,
    "zip_code": 252218,
    "dane_code": 25290000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "FUSAGASUGA"
  },
  {
    "id": 1681,
    "zip_code": 252219,
    "dane_code": 25290000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "FUSAGASUGA"
  },
  {
    "id": 1682,
    "zip_code": 252230,
    "dane_code": 25805000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "TIBACUY"
  },
  {
    "id": 1683,
    "zip_code": 252237,
    "dane_code": 25805000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "TIBACUY"
  },
  {
    "id": 1684,
    "zip_code": 252240,
    "dane_code": 25743000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SILVANIA"
  },
  {
    "id": 1685,
    "zip_code": 252247,
    "dane_code": 25743000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SILVANIA"
  },
  {
    "id": 1686,
    "zip_code": 252248,
    "dane_code": 25743000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SILVANIA"
  },
  {
    "id": 1687,
    "zip_code": 252250,
    "dane_code": 25312000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GRANADA"
  },
  {
    "id": 1688,
    "zip_code": 252257,
    "dane_code": 25312000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GRANADA"
  },
  {
    "id": 1689,
    "zip_code": 252401,
    "dane_code": 25488000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "NILO"
  },
  {
    "id": 1690,
    "zip_code": 252407,
    "dane_code": 25488000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "NILO"
  },
  {
    "id": 1691,
    "zip_code": 252408,
    "dane_code": 25488000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "NILO"
  },
  {
    "id": 1692,
    "zip_code": 252410,
    "dane_code": 25612000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "RICAURTE"
  },
  {
    "id": 1693,
    "zip_code": 252417,
    "dane_code": 25612000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "RICAURTE"
  },
  {
    "id": 1694,
    "zip_code": 252431,
    "dane_code": 25307000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GIRARDOT"
  },
  {
    "id": 1695,
    "zip_code": 252432,
    "dane_code": 25307000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GIRARDOT"
  },
  {
    "id": 1696,
    "zip_code": 252437,
    "dane_code": 25307000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GIRARDOT"
  },
  {
    "id": 1697,
    "zip_code": 252601,
    "dane_code": 25386000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "LA MESA"
  },
  {
    "id": 1698,
    "zip_code": 252607,
    "dane_code": 25386000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "LA MESA"
  },
  {
    "id": 1699,
    "zip_code": 252608,
    "dane_code": 25386000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "LA MESA"
  },
  {
    "id": 1700,
    "zip_code": 252610,
    "dane_code": 25797000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "TENA"
  },
  {
    "id": 1701,
    "zip_code": 252617,
    "dane_code": 25797000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "TENA"
  },
  {
    "id": 1702,
    "zip_code": 252620,
    "dane_code": 25645000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SAN ANTONIO DEL TEQUENDAMA"
  },
  {
    "id": 1703,
    "zip_code": 252627,
    "dane_code": 25645000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SAN ANTONIO DEL TEQUENDAMA"
  },
  {
    "id": 1704,
    "zip_code": 252630,
    "dane_code": 25245000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "EL COLEGIO"
  },
  {
    "id": 1705,
    "zip_code": 252637,
    "dane_code": 25245000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "EL COLEGIO"
  },
  {
    "id": 1706,
    "zip_code": 252638,
    "dane_code": 25245000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "EL COLEGIO"
  },
  {
    "id": 1707,
    "zip_code": 252640,
    "dane_code": 25035000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "ANAPOIMA"
  },
  {
    "id": 1708,
    "zip_code": 252647,
    "dane_code": 25035000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "ANAPOIMA"
  },
  {
    "id": 1709,
    "zip_code": 252648,
    "dane_code": 25035000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "ANAPOIMA"
  },
  {
    "id": 1710,
    "zip_code": 252650,
    "dane_code": 25599000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "APULO"
  },
  {
    "id": 1711,
    "zip_code": 252657,
    "dane_code": 25599000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "APULO"
  },
  {
    "id": 1712,
    "zip_code": 252660,
    "dane_code": 25878000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "VIOTA"
  },
  {
    "id": 1713,
    "zip_code": 252667,
    "dane_code": 25878000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "VIOTA"
  },
  {
    "id": 1714,
    "zip_code": 252668,
    "dane_code": 25878000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "VIOTA"
  },
  {
    "id": 1715,
    "zip_code": 252801,
    "dane_code": 25580000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "PULI"
  },
  {
    "id": 1716,
    "zip_code": 252807,
    "dane_code": 25580000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "PULI"
  },
  {
    "id": 1717,
    "zip_code": 252810,
    "dane_code": 25368000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "JERUSALEN"
  },
  {
    "id": 1718,
    "zip_code": 252817,
    "dane_code": 25368000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "JERUSALEN"
  },
  {
    "id": 1719,
    "zip_code": 252820,
    "dane_code": 25324000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GUATAQUI"
  },
  {
    "id": 1720,
    "zip_code": 252827,
    "dane_code": 25324000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GUATAQUI"
  },
  {
    "id": 1721,
    "zip_code": 252830,
    "dane_code": 25483000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "NARI√ëO"
  },
  {
    "id": 1722,
    "zip_code": 252837,
    "dane_code": 25483000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "NARI√ëO"
  },
  {
    "id": 1723,
    "zip_code": 252840,
    "dane_code": 25815000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "TOCAIMA"
  },
  {
    "id": 1724,
    "zip_code": 252847,
    "dane_code": 25815000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "TOCAIMA"
  },
  {
    "id": 1725,
    "zip_code": 252848,
    "dane_code": 25815000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "TOCAIMA"
  },
  {
    "id": 1726,
    "zip_code": 252850,
    "dane_code": 25001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "AGUA DE DIOS"
  },
  {
    "id": 1727,
    "zip_code": 252857,
    "dane_code": 25001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "AGUA DE DIOS"
  },
  {
    "id": 1728,
    "zip_code": 253001,
    "dane_code": 25099000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOJACA"
  },
  {
    "id": 1729,
    "zip_code": 253007,
    "dane_code": 25099000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOJACA"
  },
  {
    "id": 1730,
    "zip_code": 253010,
    "dane_code": 25898000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "ZIPACON"
  },
  {
    "id": 1731,
    "zip_code": 253017,
    "dane_code": 25898000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "ZIPACON"
  },
  {
    "id": 1732,
    "zip_code": 253020,
    "dane_code": 25123000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "CACHIPAY"
  },
  {
    "id": 1733,
    "zip_code": 253027,
    "dane_code": 25123000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "CACHIPAY"
  },
  {
    "id": 1734,
    "zip_code": 253030,
    "dane_code": 25596000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "QUIPILE"
  },
  {
    "id": 1735,
    "zip_code": 253037,
    "dane_code": 25596000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "QUIPILE"
  },
  {
    "id": 1736,
    "zip_code": 253040,
    "dane_code": 25040000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "ANOLAIMA"
  },
  {
    "id": 1737,
    "zip_code": 253047,
    "dane_code": 25040000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "ANOLAIMA"
  },
  {
    "id": 1738,
    "zip_code": 253048,
    "dane_code": 25040000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "ANOLAIMA"
  },
  {
    "id": 1739,
    "zip_code": 253051,
    "dane_code": 25269000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "FACATATIVA"
  },
  {
    "id": 1740,
    "zip_code": 253052,
    "dane_code": 25269000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "FACATATIVA"
  },
  {
    "id": 1741,
    "zip_code": 253057,
    "dane_code": 25269000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "FACATATIVA"
  },
  {
    "id": 1742,
    "zip_code": 253058,
    "dane_code": 25269000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "FACATATIVA"
  },
  {
    "id": 1743,
    "zip_code": 253201,
    "dane_code": 25019000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "ALBAN"
  },
  {
    "id": 1744,
    "zip_code": 253207,
    "dane_code": 25019000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "ALBAN"
  },
  {
    "id": 1745,
    "zip_code": 253210,
    "dane_code": 25328000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GUAYABAL DE SIQUIMA"
  },
  {
    "id": 1746,
    "zip_code": 253217,
    "dane_code": 25328000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GUAYABAL DE SIQUIMA"
  },
  {
    "id": 1747,
    "zip_code": 253220,
    "dane_code": 25095000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BITUIMA"
  },
  {
    "id": 1748,
    "zip_code": 253227,
    "dane_code": 25095000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BITUIMA"
  },
  {
    "id": 1749,
    "zip_code": 253230,
    "dane_code": 25867000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "VIANI"
  },
  {
    "id": 1750,
    "zip_code": 253237,
    "dane_code": 25867000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "VIANI"
  },
  {
    "id": 1751,
    "zip_code": 253240,
    "dane_code": 25168000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "CHAGUANI"
  },
  {
    "id": 1752,
    "zip_code": 253247,
    "dane_code": 25168000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "CHAGUANI"
  },
  {
    "id": 1753,
    "zip_code": 253250,
    "dane_code": 25662000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SAN JUAN DE RIO SECO"
  },
  {
    "id": 1754,
    "zip_code": 253257,
    "dane_code": 25662000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SAN JUAN DE RIO SECO"
  },
  {
    "id": 1755,
    "zip_code": 253258,
    "dane_code": 25662000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SAN JUAN DE RIO SECO"
  },
  {
    "id": 1756,
    "zip_code": 253260,
    "dane_code": 25086000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BELTRAN"
  },
  {
    "id": 1757,
    "zip_code": 253267,
    "dane_code": 25086000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BELTRAN"
  },
  {
    "id": 1758,
    "zip_code": 253401,
    "dane_code": 25718000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SASAIMA"
  },
  {
    "id": 1759,
    "zip_code": 253407,
    "dane_code": 25718000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SASAIMA"
  },
  {
    "id": 1760,
    "zip_code": 253408,
    "dane_code": 25718000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SASAIMA"
  },
  {
    "id": 1761,
    "zip_code": 253410,
    "dane_code": 25875000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "VILLETA"
  },
  {
    "id": 1762,
    "zip_code": 253417,
    "dane_code": 25875000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "VILLETA"
  },
  {
    "id": 1763,
    "zip_code": 253418,
    "dane_code": 25875000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "VILLETA"
  },
  {
    "id": 1764,
    "zip_code": 253420,
    "dane_code": 25592000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "QUEBRADANEGRA"
  },
  {
    "id": 1765,
    "zip_code": 253427,
    "dane_code": 25592000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "QUEBRADANEGRA"
  },
  {
    "id": 1766,
    "zip_code": 253430,
    "dane_code": 25851000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "UTICA"
  },
  {
    "id": 1767,
    "zip_code": 253437,
    "dane_code": 25851000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "UTICA"
  },
  {
    "id": 1768,
    "zip_code": 253440,
    "dane_code": 25320000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GUADUAS"
  },
  {
    "id": 1769,
    "zip_code": 253447,
    "dane_code": 25320000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GUADUAS"
  },
  {
    "id": 1770,
    "zip_code": 253448,
    "dane_code": 25320000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GUADUAS"
  },
  {
    "id": 1771,
    "zip_code": 253449,
    "dane_code": 25320000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "GUADUAS"
  },
  {
    "id": 1772,
    "zip_code": 253460,
    "dane_code": 25148000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "CAPARRAPI"
  },
  {
    "id": 1773,
    "zip_code": 253467,
    "dane_code": 25148000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "CAPARRAPI"
  },
  {
    "id": 1774,
    "zip_code": 253468,
    "dane_code": 25148000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "CAPARRAPI"
  },
  {
    "id": 1775,
    "zip_code": 253480,
    "dane_code": 25572000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "PUERTO SALGAR"
  },
  {
    "id": 1776,
    "zip_code": 253487,
    "dane_code": 25572000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "PUERTO SALGAR"
  },
  {
    "id": 1777,
    "zip_code": 253601,
    "dane_code": 25658000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SAN FRANCISCO"
  },
  {
    "id": 1778,
    "zip_code": 253607,
    "dane_code": 25658000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SAN FRANCISCO"
  },
  {
    "id": 1779,
    "zip_code": 253608,
    "dane_code": 25658000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SAN FRANCISCO"
  },
  {
    "id": 1780,
    "zip_code": 253610,
    "dane_code": 25402000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "LA VEGA"
  },
  {
    "id": 1781,
    "zip_code": 253617,
    "dane_code": 25402000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "LA VEGA"
  },
  {
    "id": 1782,
    "zip_code": 253618,
    "dane_code": 25402000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "LA VEGA"
  },
  {
    "id": 1783,
    "zip_code": 253620,
    "dane_code": 25491000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "NOCAIMA"
  },
  {
    "id": 1784,
    "zip_code": 253627,
    "dane_code": 25491000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "NOCAIMA"
  },
  {
    "id": 1785,
    "zip_code": 253630,
    "dane_code": 25489000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "NIMAIMA"
  },
  {
    "id": 1786,
    "zip_code": 253637,
    "dane_code": 25489000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "NIMAIMA"
  },
  {
    "id": 1787,
    "zip_code": 253640,
    "dane_code": 25398000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "LA PE√ëA"
  },
  {
    "id": 1788,
    "zip_code": 253647,
    "dane_code": 25398000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "LA PE√ëA"
  },
  {
    "id": 1789,
    "zip_code": 253648,
    "dane_code": 25398000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "LA PE√ëA"
  },
  {
    "id": 1790,
    "zip_code": 253650,
    "dane_code": 25862000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "VERGARA"
  },
  {
    "id": 1791,
    "zip_code": 253657,
    "dane_code": 25862000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "VERGARA"
  },
  {
    "id": 1792,
    "zip_code": 253658,
    "dane_code": 25862000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "VERGARA"
  },
  {
    "id": 1793,
    "zip_code": 253660,
    "dane_code": 25777000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SUPATA"
  },
  {
    "id": 1794,
    "zip_code": 253667,
    "dane_code": 25777000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SUPATA"
  },
  {
    "id": 1795,
    "zip_code": 253801,
    "dane_code": 25394000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "LA PALMA"
  },
  {
    "id": 1796,
    "zip_code": 253807,
    "dane_code": 25394000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "LA PALMA"
  },
  {
    "id": 1797,
    "zip_code": 253808,
    "dane_code": 25394000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "LA PALMA"
  },
  {
    "id": 1798,
    "zip_code": 253820,
    "dane_code": 25823000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "TOPAIPI"
  },
  {
    "id": 1799,
    "zip_code": 253827,
    "dane_code": 25823000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "TOPAIPI"
  },
  {
    "id": 1800,
    "zip_code": 253840,
    "dane_code": 25885000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "YACOPI"
  },
  {
    "id": 1801,
    "zip_code": 253847,
    "dane_code": 25885000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "YACOPI"
  },
  {
    "id": 1802,
    "zip_code": 253848,
    "dane_code": 25885000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "YACOPI"
  },
  {
    "id": 1803,
    "zip_code": 254001,
    "dane_code": 25513000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "PACHO"
  },
  {
    "id": 1804,
    "zip_code": 254007,
    "dane_code": 25513000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "PACHO"
  },
  {
    "id": 1805,
    "zip_code": 254008,
    "dane_code": 25513000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "PACHO"
  },
  {
    "id": 1806,
    "zip_code": 254020,
    "dane_code": 25258000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "EL PE√ëON"
  },
  {
    "id": 1807,
    "zip_code": 254027,
    "dane_code": 25258000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "EL PE√ëON"
  },
  {
    "id": 1808,
    "zip_code": 254030,
    "dane_code": 25871000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "VILLAGOMEZ"
  },
  {
    "id": 1809,
    "zip_code": 254037,
    "dane_code": 25871000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "VILLAGOMEZ"
  },
  {
    "id": 1810,
    "zip_code": 254040,
    "dane_code": 25518000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "PAIME"
  },
  {
    "id": 1811,
    "zip_code": 254047,
    "dane_code": 25518000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "PAIME"
  },
  {
    "id": 1812,
    "zip_code": 254050,
    "dane_code": 25653000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SAN CAYETANO"
  },
  {
    "id": 1813,
    "zip_code": 254057,
    "dane_code": 25653000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "SAN CAYETANO"
  },
  {
    "id": 1814,
    "zip_code": 270001,
    "dane_code": 27001000,
    "name_depto": "CHOCO",
    "name_city": "QUIBDO"
  },
  {
    "id": 1815,
    "zip_code": 270002,
    "dane_code": 27001000,
    "name_depto": "CHOCO",
    "name_city": "QUIBDO"
  },
  {
    "id": 1816,
    "zip_code": 270007,
    "dane_code": 27001000,
    "name_depto": "CHOCO",
    "name_city": "QUIBDO"
  },
  {
    "id": 1817,
    "zip_code": 270008,
    "dane_code": 27001000,
    "name_depto": "CHOCO",
    "name_city": "QUIBDO"
  },
  {
    "id": 1818,
    "zip_code": 270009,
    "dane_code": 27001000,
    "name_depto": "CHOCO",
    "name_city": "QUIBDO"
  },
  {
    "id": 1819,
    "zip_code": 270070,
    "dane_code": 27425000,
    "name_depto": "CHOCO",
    "name_city": "MEDIO ATRATO"
  },
  {
    "id": 1820,
    "zip_code": 270077,
    "dane_code": 27425000,
    "name_depto": "CHOCO",
    "name_city": "MEDIO ATRATO"
  },
  {
    "id": 1821,
    "zip_code": 270078,
    "dane_code": 27425000,
    "name_depto": "CHOCO",
    "name_city": "MEDIO ATRATO"
  },
  {
    "id": 1822,
    "zip_code": 271010,
    "dane_code": 27245000,
    "name_depto": "CHOCO",
    "name_city": "EL CARMEN DE ATRATO"
  },
  {
    "id": 1823,
    "zip_code": 271017,
    "dane_code": 27245000,
    "name_depto": "CHOCO",
    "name_city": "EL CARMEN DE ATRATO"
  },
  {
    "id": 1824,
    "zip_code": 271018,
    "dane_code": 27245000,
    "name_depto": "CHOCO",
    "name_city": "EL CARMEN DE ATRATO"
  },
  {
    "id": 1825,
    "zip_code": 271030,
    "dane_code": 27413000,
    "name_depto": "CHOCO",
    "name_city": "LLORO"
  },
  {
    "id": 1826,
    "zip_code": 271037,
    "dane_code": 27413000,
    "name_depto": "CHOCO",
    "name_city": "LLORO"
  },
  {
    "id": 1827,
    "zip_code": 271050,
    "dane_code": 27073000,
    "name_depto": "CHOCO",
    "name_city": "BAGADO"
  },
  {
    "id": 1828,
    "zip_code": 271057,
    "dane_code": 27073000,
    "name_depto": "CHOCO",
    "name_city": "BAGADO"
  },
  {
    "id": 1829,
    "zip_code": 271058,
    "dane_code": 27073000,
    "name_depto": "CHOCO",
    "name_city": "BAGADO"
  },
  {
    "id": 1830,
    "zip_code": 271070,
    "dane_code": 27787000,
    "name_depto": "CHOCO",
    "name_city": "TADO"
  },
  {
    "id": 1831,
    "zip_code": 271077,
    "dane_code": 27787000,
    "name_depto": "CHOCO",
    "name_city": "TADO"
  },
  {
    "id": 1832,
    "zip_code": 272010,
    "dane_code": 27050000,
    "name_depto": "CHOCO",
    "name_city": "ATRATO"
  },
  {
    "id": 1833,
    "zip_code": 272017,
    "dane_code": 27050000,
    "name_depto": "CHOCO",
    "name_city": "ATRATO"
  },
  {
    "id": 1834,
    "zip_code": 272018,
    "dane_code": 27050000,
    "name_depto": "CHOCO",
    "name_city": "ATRATO"
  },
  {
    "id": 1835,
    "zip_code": 272020,
    "dane_code": 27160000,
    "name_depto": "CHOCO",
    "name_city": "CERTEGUI"
  },
  {
    "id": 1836,
    "zip_code": 272027,
    "dane_code": 27160000,
    "name_depto": "CHOCO",
    "name_city": "CERTEGUI"
  },
  {
    "id": 1837,
    "zip_code": 272030,
    "dane_code": 27810000,
    "name_depto": "CHOCO",
    "name_city": "UNION PANAMERICANA"
  },
  {
    "id": 1838,
    "zip_code": 272037,
    "dane_code": 27810000,
    "name_depto": "CHOCO",
    "name_city": "UNION PANAMERICANA"
  },
  {
    "id": 1839,
    "zip_code": 272040,
    "dane_code": 27135000,
    "name_depto": "CHOCO",
    "name_city": "EL CANTON DEL SAN PABLO"
  },
  {
    "id": 1840,
    "zip_code": 272047,
    "dane_code": 27135000,
    "name_depto": "CHOCO",
    "name_city": "EL CANTON DEL SAN PABLO"
  },
  {
    "id": 1841,
    "zip_code": 272048,
    "dane_code": 27135000,
    "name_depto": "CHOCO",
    "name_city": "EL CANTON DEL SAN PABLO"
  },
  {
    "id": 1842,
    "zip_code": 272050,
    "dane_code": 27600000,
    "name_depto": "CHOCO",
    "name_city": "RIO QUITO"
  },
  {
    "id": 1843,
    "zip_code": 272057,
    "dane_code": 27600000,
    "name_depto": "CHOCO",
    "name_city": "RIO QUITO"
  },
  {
    "id": 1844,
    "zip_code": 272058,
    "dane_code": 27600000,
    "name_depto": "CHOCO",
    "name_city": "RIO QUITO"
  },
  {
    "id": 1845,
    "zip_code": 273010,
    "dane_code": 27580000,
    "name_depto": "CHOCO",
    "name_city": "RIO IRO"
  },
  {
    "id": 1846,
    "zip_code": 273017,
    "dane_code": 27580000,
    "name_depto": "CHOCO",
    "name_city": "RIO IRO"
  },
  {
    "id": 1847,
    "zip_code": 273030,
    "dane_code": 27205000,
    "name_depto": "CHOCO",
    "name_city": "CONDOTO"
  },
  {
    "id": 1848,
    "zip_code": 273037,
    "dane_code": 27205000,
    "name_depto": "CHOCO",
    "name_city": "CONDOTO"
  },
  {
    "id": 1849,
    "zip_code": 273038,
    "dane_code": 27205000,
    "name_depto": "CHOCO",
    "name_city": "CONDOTO"
  },
  {
    "id": 1850,
    "zip_code": 273050,
    "dane_code": 27491000,
    "name_depto": "CHOCO",
    "name_city": "NOVITA"
  },
  {
    "id": 1851,
    "zip_code": 273057,
    "dane_code": 27491000,
    "name_depto": "CHOCO",
    "name_city": "NOVITA"
  },
  {
    "id": 1852,
    "zip_code": 273058,
    "dane_code": 27491000,
    "name_depto": "CHOCO",
    "name_city": "NOVITA"
  },
  {
    "id": 1853,
    "zip_code": 273070,
    "dane_code": 27660000,
    "name_depto": "CHOCO",
    "name_city": "SAN JOSE DEL PALMAR"
  },
  {
    "id": 1854,
    "zip_code": 273077,
    "dane_code": 27660000,
    "name_depto": "CHOCO",
    "name_city": "SAN JOSE DEL PALMAR"
  },
  {
    "id": 1855,
    "zip_code": 273078,
    "dane_code": 27660000,
    "name_depto": "CHOCO",
    "name_city": "SAN JOSE DEL PALMAR"
  },
  {
    "id": 1856,
    "zip_code": 274010,
    "dane_code": 27361000,
    "name_depto": "CHOCO",
    "name_city": "ISTMINA"
  },
  {
    "id": 1857,
    "zip_code": 274017,
    "dane_code": 27361000,
    "name_depto": "CHOCO",
    "name_city": "ISTMINA"
  },
  {
    "id": 1858,
    "zip_code": 274018,
    "dane_code": 27361000,
    "name_depto": "CHOCO",
    "name_city": "ISTMINA"
  },
  {
    "id": 1859,
    "zip_code": 274019,
    "dane_code": 27361000,
    "name_depto": "CHOCO",
    "name_city": "ISTMINA"
  },
  {
    "id": 1860,
    "zip_code": 274030,
    "dane_code": 27450000,
    "name_depto": "CHOCO",
    "name_city": "MEDIO SAN JUAN"
  },
  {
    "id": 1861,
    "zip_code": 274037,
    "dane_code": 27450000,
    "name_depto": "CHOCO",
    "name_city": "MEDIO SAN JUAN"
  },
  {
    "id": 1862,
    "zip_code": 274038,
    "dane_code": 27450000,
    "name_depto": "CHOCO",
    "name_city": "MEDIO SAN JUAN"
  },
  {
    "id": 1863,
    "zip_code": 274039,
    "dane_code": 27450000,
    "name_depto": "CHOCO",
    "name_city": "MEDIO SAN JUAN"
  },
  {
    "id": 1864,
    "zip_code": 274050,
    "dane_code": 27745000,
    "name_depto": "CHOCO",
    "name_city": "SIPI"
  },
  {
    "id": 1865,
    "zip_code": 274057,
    "dane_code": 27745000,
    "name_depto": "CHOCO",
    "name_city": "SIPI"
  },
  {
    "id": 1866,
    "zip_code": 274058,
    "dane_code": 27745000,
    "name_depto": "CHOCO",
    "name_city": "SIPI"
  },
  {
    "id": 1867,
    "zip_code": 275010,
    "dane_code": 27430000,
    "name_depto": "CHOCO",
    "name_city": "MEDIO BAUDO"
  },
  {
    "id": 1868,
    "zip_code": 275017,
    "dane_code": 27430000,
    "name_depto": "CHOCO",
    "name_city": "MEDIO BAUDO"
  },
  {
    "id": 1869,
    "zip_code": 275018,
    "dane_code": 27430000,
    "name_depto": "CHOCO",
    "name_city": "MEDIO BAUDO"
  },
  {
    "id": 1870,
    "zip_code": 275030,
    "dane_code": 27077000,
    "name_depto": "CHOCO",
    "name_city": "BAJO BAUDO"
  },
  {
    "id": 1871,
    "zip_code": 275037,
    "dane_code": 27077000,
    "name_depto": "CHOCO",
    "name_city": "BAJO BAUDO"
  },
  {
    "id": 1872,
    "zip_code": 275038,
    "dane_code": 27077000,
    "name_depto": "CHOCO",
    "name_city": "BAJO BAUDO"
  },
  {
    "id": 1873,
    "zip_code": 275050,
    "dane_code": 27250000,
    "name_depto": "CHOCO",
    "name_city": "EL LITORAL DEL SAN JUAN"
  },
  {
    "id": 1874,
    "zip_code": 275057,
    "dane_code": 27250000,
    "name_depto": "CHOCO",
    "name_city": "EL LITORAL DEL SAN JUAN"
  },
  {
    "id": 1875,
    "zip_code": 275058,
    "dane_code": 27250000,
    "name_depto": "CHOCO",
    "name_city": "EL LITORAL DEL SAN JUAN"
  },
  {
    "id": 1876,
    "zip_code": 276010,
    "dane_code": 27372000,
    "name_depto": "CHOCO",
    "name_city": "JURADO"
  },
  {
    "id": 1877,
    "zip_code": 276017,
    "dane_code": 27372000,
    "name_depto": "CHOCO",
    "name_city": "JURADO"
  },
  {
    "id": 1878,
    "zip_code": 276018,
    "dane_code": 27372000,
    "name_depto": "CHOCO",
    "name_city": "JURADO"
  },
  {
    "id": 1879,
    "zip_code": 276019,
    "dane_code": 27372000,
    "name_depto": "CHOCO",
    "name_city": "JURADO"
  },
  {
    "id": 1880,
    "zip_code": 276030,
    "dane_code": 27075000,
    "name_depto": "CHOCO",
    "name_city": "BAHIA SOLANO"
  },
  {
    "id": 1881,
    "zip_code": 276037,
    "dane_code": 27075000,
    "name_depto": "CHOCO",
    "name_city": "BAHIA SOLANO"
  },
  {
    "id": 1882,
    "zip_code": 276038,
    "dane_code": 27075000,
    "name_depto": "CHOCO",
    "name_city": "BAHIA SOLANO"
  },
  {
    "id": 1883,
    "zip_code": 276050,
    "dane_code": 27495000,
    "name_depto": "CHOCO",
    "name_city": "NUQUI"
  },
  {
    "id": 1884,
    "zip_code": 276057,
    "dane_code": 27495000,
    "name_depto": "CHOCO",
    "name_city": "NUQUI"
  },
  {
    "id": 1885,
    "zip_code": 276058,
    "dane_code": 27495000,
    "name_depto": "CHOCO",
    "name_city": "NUQUI"
  },
  {
    "id": 1886,
    "zip_code": 276070,
    "dane_code": 27025000,
    "name_depto": "CHOCO",
    "name_city": "ALTO BAUDO"
  },
  {
    "id": 1887,
    "zip_code": 276077,
    "dane_code": 27025000,
    "name_depto": "CHOCO",
    "name_city": "ALTO BAUDO"
  },
  {
    "id": 1888,
    "zip_code": 276078,
    "dane_code": 27025000,
    "name_depto": "CHOCO",
    "name_city": "ALTO BAUDO"
  },
  {
    "id": 1889,
    "zip_code": 277010,
    "dane_code": 27615000,
    "name_depto": "CHOCO",
    "name_city": "RIOSUCIO"
  },
  {
    "id": 1890,
    "zip_code": 277017,
    "dane_code": 27615000,
    "name_depto": "CHOCO",
    "name_city": "RIOSUCIO"
  },
  {
    "id": 1891,
    "zip_code": 277018,
    "dane_code": 27615000,
    "name_depto": "CHOCO",
    "name_city": "RIOSUCIO"
  },
  {
    "id": 1892,
    "zip_code": 277030,
    "dane_code": 27150000,
    "name_depto": "CHOCO",
    "name_city": "CARMEN DEL DARIEN"
  },
  {
    "id": 1893,
    "zip_code": 277037,
    "dane_code": 27150000,
    "name_depto": "CHOCO",
    "name_city": "CARMEN DEL DARIEN"
  },
  {
    "id": 1894,
    "zip_code": 277038,
    "dane_code": 27150000,
    "name_depto": "CHOCO",
    "name_city": "CARMEN DEL DARIEN"
  },
  {
    "id": 1895,
    "zip_code": 277050,
    "dane_code": 27099000,
    "name_depto": "CHOCO",
    "name_city": "BOJAYA"
  },
  {
    "id": 1896,
    "zip_code": 277057,
    "dane_code": 27099000,
    "name_depto": "CHOCO",
    "name_city": "BOJAYA"
  },
  {
    "id": 1897,
    "zip_code": 278010,
    "dane_code": 27006000,
    "name_depto": "CHOCO",
    "name_city": "ACANDI"
  },
  {
    "id": 1898,
    "zip_code": 278017,
    "dane_code": 27006000,
    "name_depto": "CHOCO",
    "name_city": "ACANDI"
  },
  {
    "id": 1899,
    "zip_code": 278018,
    "dane_code": 27006000,
    "name_depto": "CHOCO",
    "name_city": "ACANDI"
  },
  {
    "id": 1900,
    "zip_code": 278030,
    "dane_code": 27800000,
    "name_depto": "CHOCO",
    "name_city": "UNGUIA"
  },
  {
    "id": 1901,
    "zip_code": 278037,
    "dane_code": 27800000,
    "name_depto": "CHOCO",
    "name_city": "UNGUIA"
  },
  {
    "id": 1902,
    "zip_code": 278038,
    "dane_code": 27800000,
    "name_depto": "CHOCO",
    "name_city": "UNGUIA"
  },
  {
    "id": 1903,
    "zip_code": 278050,
    "dane_code": 27615000,
    "name_depto": "CHOCO",
    "name_city": "RIOSUCIO"
  },
  {
    "id": 1904,
    "zip_code": 278057,
    "dane_code": 27615000,
    "name_depto": "CHOCO",
    "name_city": "RIOSUCIO"
  },
  {
    "id": 1905,
    "zip_code": 278058,
    "dane_code": 27615000,
    "name_depto": "CHOCO",
    "name_city": "RIOSUCIO"
  },
  {
    "id": 1906,
    "zip_code": 410001,
    "dane_code": 41001000,
    "name_depto": "HUILA",
    "name_city": "NEIVA"
  },
  {
    "id": 1907,
    "zip_code": 410002,
    "dane_code": 41001000,
    "name_depto": "HUILA",
    "name_city": "NEIVA"
  },
  {
    "id": 1908,
    "zip_code": 410003,
    "dane_code": 41001000,
    "name_depto": "HUILA",
    "name_city": "NEIVA"
  },
  {
    "id": 1909,
    "zip_code": 410004,
    "dane_code": 41001000,
    "name_depto": "HUILA",
    "name_city": "NEIVA"
  },
  {
    "id": 1910,
    "zip_code": 410005,
    "dane_code": 41001000,
    "name_depto": "HUILA",
    "name_city": "NEIVA"
  },
  {
    "id": 1911,
    "zip_code": 410006,
    "dane_code": 41001000,
    "name_depto": "HUILA",
    "name_city": "NEIVA"
  },
  {
    "id": 1912,
    "zip_code": 410007,
    "dane_code": 41001000,
    "name_depto": "HUILA",
    "name_city": "NEIVA"
  },
  {
    "id": 1913,
    "zip_code": 410008,
    "dane_code": 41001000,
    "name_depto": "HUILA",
    "name_city": "NEIVA"
  },
  {
    "id": 1914,
    "zip_code": 410009,
    "dane_code": 41001000,
    "name_depto": "HUILA",
    "name_city": "NEIVA"
  },
  {
    "id": 1915,
    "zip_code": 410010,
    "dane_code": 41001000,
    "name_depto": "HUILA",
    "name_city": "NEIVA"
  },
  {
    "id": 1916,
    "zip_code": 410017,
    "dane_code": 41001000,
    "name_depto": "HUILA",
    "name_city": "NEIVA"
  },
  {
    "id": 1917,
    "zip_code": 410018,
    "dane_code": 41001000,
    "name_depto": "HUILA",
    "name_city": "NEIVA"
  },
  {
    "id": 1918,
    "zip_code": 411001,
    "dane_code": 41016000,
    "name_depto": "HUILA",
    "name_city": "AIPE"
  },
  {
    "id": 1919,
    "zip_code": 411007,
    "dane_code": 41016000,
    "name_depto": "HUILA",
    "name_city": "AIPE"
  },
  {
    "id": 1920,
    "zip_code": 411008,
    "dane_code": 41016000,
    "name_depto": "HUILA",
    "name_city": "AIPE"
  },
  {
    "id": 1921,
    "zip_code": 411020,
    "dane_code": 41872000,
    "name_depto": "HUILA",
    "name_city": "VILLAVIEJA"
  },
  {
    "id": 1922,
    "zip_code": 411027,
    "dane_code": 41872000,
    "name_depto": "HUILA",
    "name_city": "VILLAVIEJA"
  },
  {
    "id": 1923,
    "zip_code": 411028,
    "dane_code": 41872000,
    "name_depto": "HUILA",
    "name_city": "VILLAVIEJA"
  },
  {
    "id": 1924,
    "zip_code": 411040,
    "dane_code": 41799000,
    "name_depto": "HUILA",
    "name_city": "TELLO"
  },
  {
    "id": 1925,
    "zip_code": 411047,
    "dane_code": 41799000,
    "name_depto": "HUILA",
    "name_city": "TELLO"
  },
  {
    "id": 1926,
    "zip_code": 411048,
    "dane_code": 41799000,
    "name_depto": "HUILA",
    "name_city": "TELLO"
  },
  {
    "id": 1927,
    "zip_code": 411060,
    "dane_code": 41078000,
    "name_depto": "HUILA",
    "name_city": "BARAYA"
  },
  {
    "id": 1928,
    "zip_code": 411067,
    "dane_code": 41078000,
    "name_depto": "HUILA",
    "name_city": "BARAYA"
  },
  {
    "id": 1929,
    "zip_code": 411080,
    "dane_code": 41206000,
    "name_depto": "HUILA",
    "name_city": "COLOMBIA"
  },
  {
    "id": 1930,
    "zip_code": 411087,
    "dane_code": 41206000,
    "name_depto": "HUILA",
    "name_city": "COLOMBIA"
  },
  {
    "id": 1931,
    "zip_code": 411088,
    "dane_code": 41206000,
    "name_depto": "HUILA",
    "name_city": "COLOMBIA"
  },
  {
    "id": 1932,
    "zip_code": 412001,
    "dane_code": 41524000,
    "name_depto": "HUILA",
    "name_city": "PALERMO"
  },
  {
    "id": 1933,
    "zip_code": 412007,
    "dane_code": 41524000,
    "name_depto": "HUILA",
    "name_city": "PALERMO"
  },
  {
    "id": 1934,
    "zip_code": 412008,
    "dane_code": 41524000,
    "name_depto": "HUILA",
    "name_city": "PALERMO"
  },
  {
    "id": 1935,
    "zip_code": 412020,
    "dane_code": 41676000,
    "name_depto": "HUILA",
    "name_city": "SANTA MARIA"
  },
  {
    "id": 1936,
    "zip_code": 412027,
    "dane_code": 41676000,
    "name_depto": "HUILA",
    "name_city": "SANTA MARIA"
  },
  {
    "id": 1937,
    "zip_code": 412040,
    "dane_code": 41801000,
    "name_depto": "HUILA",
    "name_city": "TERUEL"
  },
  {
    "id": 1938,
    "zip_code": 412047,
    "dane_code": 41801000,
    "name_depto": "HUILA",
    "name_city": "TERUEL"
  },
  {
    "id": 1939,
    "zip_code": 412060,
    "dane_code": 41357000,
    "name_depto": "HUILA",
    "name_city": "IQUIRA"
  },
  {
    "id": 1940,
    "zip_code": 412067,
    "dane_code": 41357000,
    "name_depto": "HUILA",
    "name_city": "IQUIRA"
  },
  {
    "id": 1941,
    "zip_code": 412068,
    "dane_code": 41357000,
    "name_depto": "HUILA",
    "name_city": "IQUIRA"
  },
  {
    "id": 1942,
    "zip_code": 412080,
    "dane_code": 41885000,
    "name_depto": "HUILA",
    "name_city": "YAGUARA"
  },
  {
    "id": 1943,
    "zip_code": 412087,
    "dane_code": 41885000,
    "name_depto": "HUILA",
    "name_city": "YAGUARA"
  },
  {
    "id": 1944,
    "zip_code": 413001,
    "dane_code": 41615000,
    "name_depto": "HUILA",
    "name_city": "RIVERA"
  },
  {
    "id": 1945,
    "zip_code": 413007,
    "dane_code": 41615000,
    "name_depto": "HUILA",
    "name_city": "RIVERA"
  },
  {
    "id": 1946,
    "zip_code": 413008,
    "dane_code": 41615000,
    "name_depto": "HUILA",
    "name_city": "RIVERA"
  },
  {
    "id": 1947,
    "zip_code": 413020,
    "dane_code": 41132000,
    "name_depto": "HUILA",
    "name_city": "CAMPOALEGRE"
  },
  {
    "id": 1948,
    "zip_code": 413027,
    "dane_code": 41132000,
    "name_depto": "HUILA",
    "name_city": "CAMPOALEGRE"
  },
  {
    "id": 1949,
    "zip_code": 413028,
    "dane_code": 41132000,
    "name_depto": "HUILA",
    "name_city": "CAMPOALEGRE"
  },
  {
    "id": 1950,
    "zip_code": 413040,
    "dane_code": 41020000,
    "name_depto": "HUILA",
    "name_city": "ALGECIRAS"
  },
  {
    "id": 1951,
    "zip_code": 413047,
    "dane_code": 41020000,
    "name_depto": "HUILA",
    "name_city": "ALGECIRAS"
  },
  {
    "id": 1952,
    "zip_code": 413048,
    "dane_code": 41020000,
    "name_depto": "HUILA",
    "name_city": "ALGECIRAS"
  },
  {
    "id": 1953,
    "zip_code": 413060,
    "dane_code": 41349000,
    "name_depto": "HUILA",
    "name_city": "HOBO"
  },
  {
    "id": 1954,
    "zip_code": 413067,
    "dane_code": 41349000,
    "name_depto": "HUILA",
    "name_city": "HOBO"
  },
  {
    "id": 1955,
    "zip_code": 414001,
    "dane_code": 41306000,
    "name_depto": "HUILA",
    "name_city": "GIGANTE"
  },
  {
    "id": 1956,
    "zip_code": 414007,
    "dane_code": 41306000,
    "name_depto": "HUILA",
    "name_city": "GIGANTE"
  },
  {
    "id": 1957,
    "zip_code": 414008,
    "dane_code": 41306000,
    "name_depto": "HUILA",
    "name_city": "GIGANTE"
  },
  {
    "id": 1958,
    "zip_code": 414009,
    "dane_code": 41306000,
    "name_depto": "HUILA",
    "name_city": "GIGANTE"
  },
  {
    "id": 1959,
    "zip_code": 414020,
    "dane_code": 41298000,
    "name_depto": "HUILA",
    "name_city": "GARZON"
  },
  {
    "id": 1960,
    "zip_code": 414027,
    "dane_code": 41298000,
    "name_depto": "HUILA",
    "name_city": "GARZON"
  },
  {
    "id": 1961,
    "zip_code": 414028,
    "dane_code": 41298000,
    "name_depto": "HUILA",
    "name_city": "GARZON"
  },
  {
    "id": 1962,
    "zip_code": 414029,
    "dane_code": 41298000,
    "name_depto": "HUILA",
    "name_city": "GARZON"
  },
  {
    "id": 1963,
    "zip_code": 414040,
    "dane_code": 41013000,
    "name_depto": "HUILA",
    "name_city": "AGRADO"
  },
  {
    "id": 1964,
    "zip_code": 414047,
    "dane_code": 41013000,
    "name_depto": "HUILA",
    "name_city": "AGRADO"
  },
  {
    "id": 1965,
    "zip_code": 414060,
    "dane_code": 41548000,
    "name_depto": "HUILA",
    "name_city": "PITAL"
  },
  {
    "id": 1966,
    "zip_code": 414067,
    "dane_code": 41548000,
    "name_depto": "HUILA",
    "name_city": "PITAL"
  },
  {
    "id": 1967,
    "zip_code": 414068,
    "dane_code": 41548000,
    "name_depto": "HUILA",
    "name_city": "PITAL"
  },
  {
    "id": 1968,
    "zip_code": 415001,
    "dane_code": 41797000,
    "name_depto": "HUILA",
    "name_city": "TESALIA"
  },
  {
    "id": 1969,
    "zip_code": 415007,
    "dane_code": 41797000,
    "name_depto": "HUILA",
    "name_city": "TESALIA"
  },
  {
    "id": 1970,
    "zip_code": 415008,
    "dane_code": 41797000,
    "name_depto": "HUILA",
    "name_city": "TESALIA"
  },
  {
    "id": 1971,
    "zip_code": 415020,
    "dane_code": 41483000,
    "name_depto": "HUILA",
    "name_city": "NATAGA"
  },
  {
    "id": 1972,
    "zip_code": 415027,
    "dane_code": 41483000,
    "name_depto": "HUILA",
    "name_city": "NATAGA"
  },
  {
    "id": 1973,
    "zip_code": 415040,
    "dane_code": 41518000,
    "name_depto": "HUILA",
    "name_city": "PAICOL"
  },
  {
    "id": 1974,
    "zip_code": 415047,
    "dane_code": 41518000,
    "name_depto": "HUILA",
    "name_city": "PAICOL"
  },
  {
    "id": 1975,
    "zip_code": 415060,
    "dane_code": 41396000,
    "name_depto": "HUILA",
    "name_city": "LA PLATA"
  },
  {
    "id": 1976,
    "zip_code": 415068,
    "dane_code": 41396000,
    "name_depto": "HUILA",
    "name_city": "LA PLATA"
  },
  {
    "id": 1977,
    "zip_code": 415069,
    "dane_code": 41396000,
    "name_depto": "HUILA",
    "name_city": "LA PLATA"
  },
  {
    "id": 1978,
    "zip_code": 415077,
    "dane_code": 41396000,
    "name_depto": "HUILA",
    "name_city": "LA PLATA"
  },
  {
    "id": 1979,
    "zip_code": 415078,
    "dane_code": 41396000,
    "name_depto": "HUILA",
    "name_city": "LA PLATA"
  },
  {
    "id": 1980,
    "zip_code": 415080,
    "dane_code": 41378000,
    "name_depto": "HUILA",
    "name_city": "LA ARGENTINA"
  },
  {
    "id": 1981,
    "zip_code": 415087,
    "dane_code": 41378000,
    "name_depto": "HUILA",
    "name_city": "LA ARGENTINA"
  },
  {
    "id": 1982,
    "zip_code": 415088,
    "dane_code": 41378000,
    "name_depto": "HUILA",
    "name_city": "LA ARGENTINA"
  },
  {
    "id": 1983,
    "zip_code": 416001,
    "dane_code": 41791000,
    "name_depto": "HUILA",
    "name_city": "TARQUI"
  },
  {
    "id": 1984,
    "zip_code": 416007,
    "dane_code": 41791000,
    "name_depto": "HUILA",
    "name_city": "TARQUI"
  },
  {
    "id": 1985,
    "zip_code": 416020,
    "dane_code": 41026000,
    "name_depto": "HUILA",
    "name_city": "ALTAMIRA"
  },
  {
    "id": 1986,
    "zip_code": 416027,
    "dane_code": 41026000,
    "name_depto": "HUILA",
    "name_city": "ALTAMIRA"
  },
  {
    "id": 1987,
    "zip_code": 416040,
    "dane_code": 41319000,
    "name_depto": "HUILA",
    "name_city": "GUADALUPE"
  },
  {
    "id": 1988,
    "zip_code": 416047,
    "dane_code": 41319000,
    "name_depto": "HUILA",
    "name_city": "GUADALUPE"
  },
  {
    "id": 1989,
    "zip_code": 416048,
    "dane_code": 41319000,
    "name_depto": "HUILA",
    "name_city": "GUADALUPE"
  },
  {
    "id": 1990,
    "zip_code": 416067,
    "dane_code": 41396000,
    "name_depto": "HUILA",
    "name_city": "LA PLATA"
  },
  {
    "id": 1991,
    "zip_code": 416080,
    "dane_code": 41770000,
    "name_depto": "HUILA",
    "name_city": "SUAZA"
  },
  {
    "id": 1992,
    "zip_code": 416087,
    "dane_code": 41770000,
    "name_depto": "HUILA",
    "name_city": "SUAZA"
  },
  {
    "id": 1993,
    "zip_code": 416088,
    "dane_code": 41770000,
    "name_depto": "HUILA",
    "name_city": "SUAZA"
  },
  {
    "id": 1994,
    "zip_code": 416089,
    "dane_code": 41770000,
    "name_depto": "HUILA",
    "name_city": "SUAZA"
  },
  {
    "id": 1995,
    "zip_code": 417001,
    "dane_code": 41244000,
    "name_depto": "HUILA",
    "name_city": "ELIAS"
  },
  {
    "id": 1996,
    "zip_code": 417007,
    "dane_code": 41244000,
    "name_depto": "HUILA",
    "name_city": "ELIAS"
  },
  {
    "id": 1997,
    "zip_code": 417010,
    "dane_code": 41807000,
    "name_depto": "HUILA",
    "name_city": "TIMANA"
  },
  {
    "id": 1998,
    "zip_code": 417017,
    "dane_code": 41807000,
    "name_depto": "HUILA",
    "name_city": "TIMANA"
  },
  {
    "id": 1999,
    "zip_code": 417018,
    "dane_code": 41807000,
    "name_depto": "HUILA",
    "name_city": "TIMANA"
  },
  {
    "id": 2000,
    "zip_code": 417030,
    "dane_code": 41551000,
    "name_depto": "HUILA",
    "name_city": "PITALITO"
  },
  {
    "id": 2001,
    "zip_code": 417037,
    "dane_code": 41551000,
    "name_depto": "HUILA",
    "name_city": "PITALITO"
  },
  {
    "id": 2002,
    "zip_code": 417038,
    "dane_code": 41551000,
    "name_depto": "HUILA",
    "name_city": "PITALITO"
  },
  {
    "id": 2003,
    "zip_code": 417039,
    "dane_code": 41551000,
    "name_depto": "HUILA",
    "name_city": "PITALITO"
  },
  {
    "id": 2004,
    "zip_code": 417047,
    "dane_code": 41551000,
    "name_depto": "HUILA",
    "name_city": "PITALITO"
  },
  {
    "id": 2005,
    "zip_code": 417048,
    "dane_code": 41551000,
    "name_depto": "HUILA",
    "name_city": "PITALITO"
  },
  {
    "id": 2006,
    "zip_code": 417060,
    "dane_code": 41530000,
    "name_depto": "HUILA",
    "name_city": "PALESTINA"
  },
  {
    "id": 2007,
    "zip_code": 417067,
    "dane_code": 41530000,
    "name_depto": "HUILA",
    "name_city": "PALESTINA"
  },
  {
    "id": 2008,
    "zip_code": 417070,
    "dane_code": 41006000,
    "name_depto": "HUILA",
    "name_city": "ACEVEDO"
  },
  {
    "id": 2009,
    "zip_code": 417077,
    "dane_code": 41006000,
    "name_depto": "HUILA",
    "name_city": "ACEVEDO"
  },
  {
    "id": 2010,
    "zip_code": 417078,
    "dane_code": 41006000,
    "name_depto": "HUILA",
    "name_city": "ACEVEDO"
  },
  {
    "id": 2011,
    "zip_code": 417079,
    "dane_code": 41006000,
    "name_depto": "HUILA",
    "name_city": "ACEVEDO"
  },
  {
    "id": 2012,
    "zip_code": 417087,
    "dane_code": 41006000,
    "name_depto": "HUILA",
    "name_city": "ACEVEDO"
  },
  {
    "id": 2013,
    "zip_code": 417088,
    "dane_code": 41006000,
    "name_depto": "HUILA",
    "name_city": "ACEVEDO"
  },
  {
    "id": 2014,
    "zip_code": 418001,
    "dane_code": 41503000,
    "name_depto": "HUILA",
    "name_city": "OPORAPA"
  },
  {
    "id": 2015,
    "zip_code": 418007,
    "dane_code": 41503000,
    "name_depto": "HUILA",
    "name_city": "OPORAPA"
  },
  {
    "id": 2016,
    "zip_code": 418008,
    "dane_code": 41503000,
    "name_depto": "HUILA",
    "name_city": "OPORAPA"
  },
  {
    "id": 2017,
    "zip_code": 418020,
    "dane_code": 41660000,
    "name_depto": "HUILA",
    "name_city": "SALADOBLANCO"
  },
  {
    "id": 2018,
    "zip_code": 418027,
    "dane_code": 41660000,
    "name_depto": "HUILA",
    "name_city": "SALADOBLANCO"
  },
  {
    "id": 2019,
    "zip_code": 418028,
    "dane_code": 41660000,
    "name_depto": "HUILA",
    "name_city": "SALADOBLANCO"
  },
  {
    "id": 2020,
    "zip_code": 418040,
    "dane_code": 41359000,
    "name_depto": "HUILA",
    "name_city": "ISNOS"
  },
  {
    "id": 2021,
    "zip_code": 418047,
    "dane_code": 41359000,
    "name_depto": "HUILA",
    "name_city": "ISNOS"
  },
  {
    "id": 2022,
    "zip_code": 418048,
    "dane_code": 41359000,
    "name_depto": "HUILA",
    "name_city": "ISNOS"
  },
  {
    "id": 2023,
    "zip_code": 418049,
    "dane_code": 41359000,
    "name_depto": "HUILA",
    "name_city": "ISNOS"
  },
  {
    "id": 2024,
    "zip_code": 418060,
    "dane_code": 41668000,
    "name_depto": "HUILA",
    "name_city": "SAN AGUSTIN"
  },
  {
    "id": 2025,
    "zip_code": 418067,
    "dane_code": 41668000,
    "name_depto": "HUILA",
    "name_city": "SAN AGUSTIN"
  },
  {
    "id": 2026,
    "zip_code": 418068,
    "dane_code": 41668000,
    "name_depto": "HUILA",
    "name_city": "SAN AGUSTIN"
  },
  {
    "id": 2027,
    "zip_code": 418069,
    "dane_code": 41668000,
    "name_depto": "HUILA",
    "name_city": "SAN AGUSTIN"
  },
  {
    "id": 2028,
    "zip_code": 418077,
    "dane_code": 41668000,
    "name_depto": "HUILA",
    "name_city": "SAN AGUSTIN"
  },
  {
    "id": 2029,
    "zip_code": 440001,
    "dane_code": 44001000,
    "name_depto": "LA GUAJIRA",
    "name_city": "RIOHACHA"
  },
  {
    "id": 2030,
    "zip_code": 440002,
    "dane_code": 44001000,
    "name_depto": "LA GUAJIRA",
    "name_city": "RIOHACHA"
  },
  {
    "id": 2031,
    "zip_code": 440003,
    "dane_code": 44001000,
    "name_depto": "LA GUAJIRA",
    "name_city": "RIOHACHA"
  },
  {
    "id": 2032,
    "zip_code": 440007,
    "dane_code": 44001000,
    "name_depto": "LA GUAJIRA",
    "name_city": "RIOHACHA"
  },
  {
    "id": 2033,
    "zip_code": 440008,
    "dane_code": 44001000,
    "name_depto": "LA GUAJIRA",
    "name_city": "RIOHACHA"
  },
  {
    "id": 2034,
    "zip_code": 440009,
    "dane_code": 44001000,
    "name_depto": "LA GUAJIRA",
    "name_city": "RIOHACHA"
  },
  {
    "id": 2035,
    "zip_code": 440017,
    "dane_code": 44001000,
    "name_depto": "LA GUAJIRA",
    "name_city": "RIOHACHA"
  },
  {
    "id": 2036,
    "zip_code": 441001,
    "dane_code": 44560009,
    "name_depto": "LA GUAJIRA",
    "name_city": "MANAURE"
  },
  {
    "id": 2037,
    "zip_code": 441007,
    "dane_code": 44560009,
    "name_depto": "LA GUAJIRA",
    "name_city": "MANAURE"
  },
  {
    "id": 2038,
    "zip_code": 441008,
    "dane_code": 44560009,
    "name_depto": "LA GUAJIRA",
    "name_city": "MANAURE"
  },
  {
    "id": 2039,
    "zip_code": 441009,
    "dane_code": 44560009,
    "name_depto": "LA GUAJIRA",
    "name_city": "MANAURE"
  },
  {
    "id": 2040,
    "zip_code": 441017,
    "dane_code": 44560009,
    "name_depto": "LA GUAJIRA",
    "name_city": "MANAURE"
  },
  {
    "id": 2041,
    "zip_code": 441018,
    "dane_code": 44560009,
    "name_depto": "LA GUAJIRA",
    "name_city": "MANAURE"
  },
  {
    "id": 2042,
    "zip_code": 441020,
    "dane_code": 44847000,
    "name_depto": "LA GUAJIRA",
    "name_city": "URIBIA"
  },
  {
    "id": 2043,
    "zip_code": 441027,
    "dane_code": 44847000,
    "name_depto": "LA GUAJIRA",
    "name_city": "URIBIA"
  },
  {
    "id": 2044,
    "zip_code": 441028,
    "dane_code": 44847000,
    "name_depto": "LA GUAJIRA",
    "name_city": "URIBIA"
  },
  {
    "id": 2045,
    "zip_code": 441029,
    "dane_code": 44847000,
    "name_depto": "LA GUAJIRA",
    "name_city": "URIBIA"
  },
  {
    "id": 2046,
    "zip_code": 441037,
    "dane_code": 44847000,
    "name_depto": "LA GUAJIRA",
    "name_city": "URIBIA"
  },
  {
    "id": 2047,
    "zip_code": 441038,
    "dane_code": 44847000,
    "name_depto": "LA GUAJIRA",
    "name_city": "URIBIA"
  },
  {
    "id": 2048,
    "zip_code": 441039,
    "dane_code": 44847000,
    "name_depto": "LA GUAJIRA",
    "name_city": "URIBIA"
  },
  {
    "id": 2049,
    "zip_code": 441047,
    "dane_code": 44847000,
    "name_depto": "LA GUAJIRA",
    "name_city": "URIBIA"
  },
  {
    "id": 2050,
    "zip_code": 441048,
    "dane_code": 44847000,
    "name_depto": "LA GUAJIRA",
    "name_city": "URIBIA"
  },
  {
    "id": 2051,
    "zip_code": 441049,
    "dane_code": 44847000,
    "name_depto": "LA GUAJIRA",
    "name_city": "URIBIA"
  },
  {
    "id": 2052,
    "zip_code": 441057,
    "dane_code": 44847000,
    "name_depto": "LA GUAJIRA",
    "name_city": "URIBIA"
  },
  {
    "id": 2053,
    "zip_code": 441058,
    "dane_code": 44847000,
    "name_depto": "LA GUAJIRA",
    "name_city": "URIBIA"
  },
  {
    "id": 2054,
    "zip_code": 441059,
    "dane_code": 44847000,
    "name_depto": "LA GUAJIRA",
    "name_city": "URIBIA"
  },
  {
    "id": 2055,
    "zip_code": 442001,
    "dane_code": 44430000,
    "name_depto": "LA GUAJIRA",
    "name_city": "MAICAO"
  },
  {
    "id": 2056,
    "zip_code": 442007,
    "dane_code": 44430000,
    "name_depto": "LA GUAJIRA",
    "name_city": "MAICAO"
  },
  {
    "id": 2057,
    "zip_code": 442008,
    "dane_code": 44430000,
    "name_depto": "LA GUAJIRA",
    "name_city": "MAICAO"
  },
  {
    "id": 2058,
    "zip_code": 442009,
    "dane_code": 44430000,
    "name_depto": "LA GUAJIRA",
    "name_city": "MAICAO"
  },
  {
    "id": 2059,
    "zip_code": 443001,
    "dane_code": 44035000,
    "name_depto": "LA GUAJIRA",
    "name_city": "ALBANIA"
  },
  {
    "id": 2060,
    "zip_code": 443007,
    "dane_code": 44035000,
    "name_depto": "LA GUAJIRA",
    "name_city": "ALBANIA"
  },
  {
    "id": 2061,
    "zip_code": 443008,
    "dane_code": 44035000,
    "name_depto": "LA GUAJIRA",
    "name_city": "ALBANIA"
  },
  {
    "id": 2062,
    "zip_code": 443009,
    "dane_code": 44035000,
    "name_depto": "LA GUAJIRA",
    "name_city": "ALBANIA"
  },
  {
    "id": 2063,
    "zip_code": 443020,
    "dane_code": 44378000,
    "name_depto": "LA GUAJIRA",
    "name_city": "HATONUEVO"
  },
  {
    "id": 2064,
    "zip_code": 443027,
    "dane_code": 44378000,
    "name_depto": "LA GUAJIRA",
    "name_city": "HATONUEVO"
  },
  {
    "id": 2065,
    "zip_code": 443028,
    "dane_code": 44378000,
    "name_depto": "LA GUAJIRA",
    "name_city": "HATONUEVO"
  },
  {
    "id": 2066,
    "zip_code": 443040,
    "dane_code": 44078000,
    "name_depto": "LA GUAJIRA",
    "name_city": "BARRANCAS"
  },
  {
    "id": 2067,
    "zip_code": 443047,
    "dane_code": 44078000,
    "name_depto": "LA GUAJIRA",
    "name_city": "BARRANCAS"
  },
  {
    "id": 2068,
    "zip_code": 443048,
    "dane_code": 44078000,
    "name_depto": "LA GUAJIRA",
    "name_city": "BARRANCAS"
  },
  {
    "id": 2069,
    "zip_code": 443049,
    "dane_code": 44078000,
    "name_depto": "LA GUAJIRA",
    "name_city": "BARRANCAS"
  },
  {
    "id": 2070,
    "zip_code": 444001,
    "dane_code": 44098000,
    "name_depto": "LA GUAJIRA",
    "name_city": "DISTRACCION"
  },
  {
    "id": 2071,
    "zip_code": 444007,
    "dane_code": 44098000,
    "name_depto": "LA GUAJIRA",
    "name_city": "DISTRACCION"
  },
  {
    "id": 2072,
    "zip_code": 444008,
    "dane_code": 44098000,
    "name_depto": "LA GUAJIRA",
    "name_city": "DISTRACCION"
  },
  {
    "id": 2073,
    "zip_code": 444010,
    "dane_code": 44279000,
    "name_depto": "LA GUAJIRA",
    "name_city": "FONSECA"
  },
  {
    "id": 2074,
    "zip_code": 444017,
    "dane_code": 44279000,
    "name_depto": "LA GUAJIRA",
    "name_city": "FONSECA"
  },
  {
    "id": 2075,
    "zip_code": 444018,
    "dane_code": 44279000,
    "name_depto": "LA GUAJIRA",
    "name_city": "FONSECA"
  },
  {
    "id": 2076,
    "zip_code": 444030,
    "dane_code": 44650000,
    "name_depto": "LA GUAJIRA",
    "name_city": "SAN JUAN DEL CESAR"
  },
  {
    "id": 2077,
    "zip_code": 444037,
    "dane_code": 44650000,
    "name_depto": "LA GUAJIRA",
    "name_city": "SAN JUAN DEL CESAR"
  },
  {
    "id": 2078,
    "zip_code": 444038,
    "dane_code": 44650000,
    "name_depto": "LA GUAJIRA",
    "name_city": "SAN JUAN DEL CESAR"
  },
  {
    "id": 2079,
    "zip_code": 444050,
    "dane_code": 44110000,
    "name_depto": "LA GUAJIRA",
    "name_city": "EL MOLINO"
  },
  {
    "id": 2080,
    "zip_code": 444057,
    "dane_code": 44110000,
    "name_depto": "LA GUAJIRA",
    "name_city": "EL MOLINO"
  },
  {
    "id": 2081,
    "zip_code": 445001,
    "dane_code": 44874000,
    "name_depto": "LA GUAJIRA",
    "name_city": "VILLANUEVA"
  },
  {
    "id": 2082,
    "zip_code": 445007,
    "dane_code": 44874000,
    "name_depto": "LA GUAJIRA",
    "name_city": "VILLANUEVA"
  },
  {
    "id": 2083,
    "zip_code": 445008,
    "dane_code": 44874000,
    "name_depto": "LA GUAJIRA",
    "name_city": "VILLANUEVA"
  },
  {
    "id": 2084,
    "zip_code": 445020,
    "dane_code": 44855000,
    "name_depto": "LA GUAJIRA",
    "name_city": "URUMITA"
  },
  {
    "id": 2085,
    "zip_code": 445027,
    "dane_code": 44855000,
    "name_depto": "LA GUAJIRA",
    "name_city": "URUMITA"
  },
  {
    "id": 2086,
    "zip_code": 445040,
    "dane_code": 44420000,
    "name_depto": "LA GUAJIRA",
    "name_city": "LA JAGUA DEL PILAR"
  },
  {
    "id": 2087,
    "zip_code": 445047,
    "dane_code": 44420000,
    "name_depto": "LA GUAJIRA",
    "name_city": "LA JAGUA DEL PILAR"
  },
  {
    "id": 2088,
    "zip_code": 446001,
    "dane_code": 44090000,
    "name_depto": "LA GUAJIRA",
    "name_city": "DIBULLA"
  },
  {
    "id": 2089,
    "zip_code": 446007,
    "dane_code": 44090000,
    "name_depto": "LA GUAJIRA",
    "name_city": "DIBULLA"
  },
  {
    "id": 2090,
    "zip_code": 446008,
    "dane_code": 44090000,
    "name_depto": "LA GUAJIRA",
    "name_city": "DIBULLA"
  },
  {
    "id": 2091,
    "zip_code": 446009,
    "dane_code": 44090000,
    "name_depto": "LA GUAJIRA",
    "name_city": "DIBULLA"
  },
  {
    "id": 2092,
    "zip_code": 446017,
    "dane_code": 44090000,
    "name_depto": "LA GUAJIRA",
    "name_city": "DIBULLA"
  },
  {
    "id": 2093,
    "zip_code": 470001,
    "dane_code": 47001000,
    "name_depto": "MAGDALENA",
    "name_city": "SANTA MARTA"
  },
  {
    "id": 2094,
    "zip_code": 470002,
    "dane_code": 47001000,
    "name_depto": "MAGDALENA",
    "name_city": "SANTA MARTA"
  },
  {
    "id": 2095,
    "zip_code": 470003,
    "dane_code": 47001000,
    "name_depto": "MAGDALENA",
    "name_city": "SANTA MARTA"
  },
  {
    "id": 2096,
    "zip_code": 470004,
    "dane_code": 47001000,
    "name_depto": "MAGDALENA",
    "name_city": "SANTA MARTA"
  },
  {
    "id": 2097,
    "zip_code": 470005,
    "dane_code": 47001000,
    "name_depto": "MAGDALENA",
    "name_city": "SANTA MARTA"
  },
  {
    "id": 2098,
    "zip_code": 470006,
    "dane_code": 47001000,
    "name_depto": "MAGDALENA",
    "name_city": "SANTA MARTA"
  },
  {
    "id": 2099,
    "zip_code": 470007,
    "dane_code": 47001000,
    "name_depto": "MAGDALENA",
    "name_city": "SANTA MARTA"
  },
  {
    "id": 2100,
    "zip_code": 470008,
    "dane_code": 47001000,
    "name_depto": "MAGDALENA",
    "name_city": "SANTA MARTA"
  },
  {
    "id": 2101,
    "zip_code": 470009,
    "dane_code": 47001000,
    "name_depto": "MAGDALENA",
    "name_city": "SANTA MARTA"
  },
  {
    "id": 2102,
    "zip_code": 470017,
    "dane_code": 47001000,
    "name_depto": "MAGDALENA",
    "name_city": "SANTA MARTA"
  },
  {
    "id": 2103,
    "zip_code": 472001,
    "dane_code": 47053000,
    "name_depto": "MAGDALENA",
    "name_city": "ARACATACA"
  },
  {
    "id": 2104,
    "zip_code": 472007,
    "dane_code": 47053000,
    "name_depto": "MAGDALENA",
    "name_city": "ARACATACA"
  },
  {
    "id": 2105,
    "zip_code": 472008,
    "dane_code": 47053000,
    "name_depto": "MAGDALENA",
    "name_city": "ARACATACA"
  },
  {
    "id": 2106,
    "zip_code": 472020,
    "dane_code": 47288000,
    "name_depto": "MAGDALENA",
    "name_city": "FUNDACION"
  },
  {
    "id": 2107,
    "zip_code": 472027,
    "dane_code": 47288000,
    "name_depto": "MAGDALENA",
    "name_city": "FUNDACION"
  },
  {
    "id": 2108,
    "zip_code": 472028,
    "dane_code": 47288000,
    "name_depto": "MAGDALENA",
    "name_city": "FUNDACION"
  },
  {
    "id": 2109,
    "zip_code": 472040,
    "dane_code": 47030000,
    "name_depto": "MAGDALENA",
    "name_city": "ALGARROBO"
  },
  {
    "id": 2110,
    "zip_code": 472047,
    "dane_code": 47030000,
    "name_depto": "MAGDALENA",
    "name_city": "ALGARROBO"
  },
  {
    "id": 2111,
    "zip_code": 473001,
    "dane_code": 47692000,
    "name_depto": "MAGDALENA",
    "name_city": "SAN SEBASTIAN DE BUENAVISTA"
  },
  {
    "id": 2112,
    "zip_code": 473007,
    "dane_code": 47692000,
    "name_depto": "MAGDALENA",
    "name_city": "SAN SEBASTIAN DE BUENAVISTA"
  },
  {
    "id": 2113,
    "zip_code": 473008,
    "dane_code": 47692000,
    "name_depto": "MAGDALENA",
    "name_city": "SAN SEBASTIAN DE BUENAVISTA"
  },
  {
    "id": 2114,
    "zip_code": 473020,
    "dane_code": 47318000,
    "name_depto": "MAGDALENA",
    "name_city": "GUAMAL"
  },
  {
    "id": 2115,
    "zip_code": 473027,
    "dane_code": 47318000,
    "name_depto": "MAGDALENA",
    "name_city": "GUAMAL"
  },
  {
    "id": 2116,
    "zip_code": 473028,
    "dane_code": 47318000,
    "name_depto": "MAGDALENA",
    "name_city": "GUAMAL"
  },
  {
    "id": 2117,
    "zip_code": 473029,
    "dane_code": 47318000,
    "name_depto": "MAGDALENA",
    "name_city": "GUAMAL"
  },
  {
    "id": 2118,
    "zip_code": 473040,
    "dane_code": 47245000,
    "name_depto": "MAGDALENA",
    "name_city": "EL BANCO"
  },
  {
    "id": 2119,
    "zip_code": 473047,
    "dane_code": 47245000,
    "name_depto": "MAGDALENA",
    "name_city": "EL BANCO"
  },
  {
    "id": 2120,
    "zip_code": 473048,
    "dane_code": 47245000,
    "name_depto": "MAGDALENA",
    "name_city": "EL BANCO"
  },
  {
    "id": 2121,
    "zip_code": 473049,
    "dane_code": 47245000,
    "name_depto": "MAGDALENA",
    "name_city": "EL BANCO"
  },
  {
    "id": 2122,
    "zip_code": 474001,
    "dane_code": 47720000,
    "name_depto": "MAGDALENA",
    "name_city": "SANTA BARBARA DE PINTO"
  },
  {
    "id": 2123,
    "zip_code": 474007,
    "dane_code": 47720000,
    "name_depto": "MAGDALENA",
    "name_city": "SANTA BARBARA DE PINTO"
  },
  {
    "id": 2124,
    "zip_code": 474020,
    "dane_code": 47707000,
    "name_depto": "MAGDALENA",
    "name_city": "SANTA ANA"
  },
  {
    "id": 2125,
    "zip_code": 474027,
    "dane_code": 47707000,
    "name_depto": "MAGDALENA",
    "name_city": "SANTA ANA"
  },
  {
    "id": 2126,
    "zip_code": 474028,
    "dane_code": 47707000,
    "name_depto": "MAGDALENA",
    "name_city": "SANTA ANA"
  },
  {
    "id": 2127,
    "zip_code": 474040,
    "dane_code": 47545000,
    "name_depto": "MAGDALENA",
    "name_city": "PIJI√ëO DEL CARMEN"
  },
  {
    "id": 2128,
    "zip_code": 474047,
    "dane_code": 47545000,
    "name_depto": "MAGDALENA",
    "name_city": "PIJI√ëO DEL CARMEN"
  },
  {
    "id": 2129,
    "zip_code": 474048,
    "dane_code": 47545000,
    "name_depto": "MAGDALENA",
    "name_city": "PIJI√ëO DEL CARMEN"
  },
  {
    "id": 2130,
    "zip_code": 474060,
    "dane_code": 47703000,
    "name_depto": "MAGDALENA",
    "name_city": "SAN ZENON"
  },
  {
    "id": 2131,
    "zip_code": 474067,
    "dane_code": 47703000,
    "name_depto": "MAGDALENA",
    "name_city": "SAN ZENON"
  },
  {
    "id": 2132,
    "zip_code": 474068,
    "dane_code": 47703000,
    "name_depto": "MAGDALENA",
    "name_city": "SAN ZENON"
  },
  {
    "id": 2133,
    "zip_code": 475001,
    "dane_code": 47660000,
    "name_depto": "MAGDALENA",
    "name_city": "SABANAS DE SAN ANGEL"
  },
  {
    "id": 2134,
    "zip_code": 475007,
    "dane_code": 47660000,
    "name_depto": "MAGDALENA",
    "name_city": "SABANAS DE SAN ANGEL"
  },
  {
    "id": 2135,
    "zip_code": 475008,
    "dane_code": 47660000,
    "name_depto": "MAGDALENA",
    "name_city": "SABANAS DE SAN ANGEL"
  },
  {
    "id": 2136,
    "zip_code": 475010,
    "dane_code": 47058000,
    "name_depto": "MAGDALENA",
    "name_city": "ARIGUANI"
  },
  {
    "id": 2137,
    "zip_code": 475017,
    "dane_code": 47058000,
    "name_depto": "MAGDALENA",
    "name_city": "ARIGUANI"
  },
  {
    "id": 2138,
    "zip_code": 475018,
    "dane_code": 47058000,
    "name_depto": "MAGDALENA",
    "name_city": "ARIGUANI"
  },
  {
    "id": 2139,
    "zip_code": 475019,
    "dane_code": 47058000,
    "name_depto": "MAGDALENA",
    "name_city": "ARIGUANI"
  },
  {
    "id": 2140,
    "zip_code": 475020,
    "dane_code": 47460000,
    "name_depto": "MAGDALENA",
    "name_city": "NUEVA GRANADA"
  },
  {
    "id": 2141,
    "zip_code": 475027,
    "dane_code": 47460000,
    "name_depto": "MAGDALENA",
    "name_city": "NUEVA GRANADA"
  },
  {
    "id": 2142,
    "zip_code": 475028,
    "dane_code": 47460000,
    "name_depto": "MAGDALENA",
    "name_city": "NUEVA GRANADA"
  },
  {
    "id": 2143,
    "zip_code": 475030,
    "dane_code": 47555000,
    "name_depto": "MAGDALENA",
    "name_city": "PLATO"
  },
  {
    "id": 2144,
    "zip_code": 475037,
    "dane_code": 47555000,
    "name_depto": "MAGDALENA",
    "name_city": "PLATO"
  },
  {
    "id": 2145,
    "zip_code": 475038,
    "dane_code": 47555000,
    "name_depto": "MAGDALENA",
    "name_city": "PLATO"
  },
  {
    "id": 2146,
    "zip_code": 475050,
    "dane_code": 47798000,
    "name_depto": "MAGDALENA",
    "name_city": "TENERIFE"
  },
  {
    "id": 2147,
    "zip_code": 475057,
    "dane_code": 47798000,
    "name_depto": "MAGDALENA",
    "name_city": "TENERIFE"
  },
  {
    "id": 2148,
    "zip_code": 476001,
    "dane_code": 47258000,
    "name_depto": "MAGDALENA",
    "name_city": "EL PI√ëON"
  },
  {
    "id": 2149,
    "zip_code": 476007,
    "dane_code": 47258000,
    "name_depto": "MAGDALENA",
    "name_city": "EL PI√ëON"
  },
  {
    "id": 2150,
    "zip_code": 476008,
    "dane_code": 47258000,
    "name_depto": "MAGDALENA",
    "name_city": "EL PI√ëON"
  },
  {
    "id": 2151,
    "zip_code": 476020,
    "dane_code": 47161000,
    "name_depto": "MAGDALENA",
    "name_city": "CERRO SAN ANTONIO"
  },
  {
    "id": 2152,
    "zip_code": 476027,
    "dane_code": 47161000,
    "name_depto": "MAGDALENA",
    "name_city": "CERRO SAN ANTONIO"
  },
  {
    "id": 2153,
    "zip_code": 476030,
    "dane_code": 47205000,
    "name_depto": "MAGDALENA",
    "name_city": "CONCORDIA"
  },
  {
    "id": 2154,
    "zip_code": 476037,
    "dane_code": 47205000,
    "name_depto": "MAGDALENA",
    "name_city": "CONCORDIA"
  },
  {
    "id": 2155,
    "zip_code": 476040,
    "dane_code": 47541000,
    "name_depto": "MAGDALENA",
    "name_city": "PEDRAZA"
  },
  {
    "id": 2156,
    "zip_code": 476047,
    "dane_code": 47541000,
    "name_depto": "MAGDALENA",
    "name_city": "PEDRAZA"
  },
  {
    "id": 2157,
    "zip_code": 476048,
    "dane_code": 47541000,
    "name_depto": "MAGDALENA",
    "name_city": "PEDRAZA"
  },
  {
    "id": 2158,
    "zip_code": 476050,
    "dane_code": 47960000,
    "name_depto": "MAGDALENA",
    "name_city": "ZAPAYAN"
  },
  {
    "id": 2159,
    "zip_code": 476057,
    "dane_code": 47960000,
    "name_depto": "MAGDALENA",
    "name_city": "ZAPAYAN"
  },
  {
    "id": 2160,
    "zip_code": 476058,
    "dane_code": 47960000,
    "name_depto": "MAGDALENA",
    "name_city": "ZAPAYAN"
  },
  {
    "id": 2161,
    "zip_code": 476060,
    "dane_code": 47170000,
    "name_depto": "MAGDALENA",
    "name_city": "CHIBOLO"
  },
  {
    "id": 2162,
    "zip_code": 476067,
    "dane_code": 47170000,
    "name_depto": "MAGDALENA",
    "name_city": "CHIBOLO"
  },
  {
    "id": 2163,
    "zip_code": 476068,
    "dane_code": 47170000,
    "name_depto": "MAGDALENA",
    "name_city": "CHIBOLO"
  },
  {
    "id": 2164,
    "zip_code": 477001,
    "dane_code": 47745000,
    "name_depto": "MAGDALENA",
    "name_city": "SITIONUEVO"
  },
  {
    "id": 2165,
    "zip_code": 477007,
    "dane_code": 47745000,
    "name_depto": "MAGDALENA",
    "name_city": "SITIONUEVO"
  },
  {
    "id": 2166,
    "zip_code": 477008,
    "dane_code": 47745000,
    "name_depto": "MAGDALENA",
    "name_city": "SITIONUEVO"
  },
  {
    "id": 2167,
    "zip_code": 477009,
    "dane_code": 47745000,
    "name_depto": "MAGDALENA",
    "name_city": "SITIONUEVO"
  },
  {
    "id": 2168,
    "zip_code": 477020,
    "dane_code": 47605000,
    "name_depto": "MAGDALENA",
    "name_city": "REMOLINO"
  },
  {
    "id": 2169,
    "zip_code": 477027,
    "dane_code": 47605000,
    "name_depto": "MAGDALENA",
    "name_city": "REMOLINO"
  },
  {
    "id": 2170,
    "zip_code": 477040,
    "dane_code": 47675000,
    "name_depto": "MAGDALENA",
    "name_city": "SALAMINA"
  },
  {
    "id": 2171,
    "zip_code": 477047,
    "dane_code": 47675000,
    "name_depto": "MAGDALENA",
    "name_city": "SALAMINA"
  },
  {
    "id": 2172,
    "zip_code": 477050,
    "dane_code": 47551000,
    "name_depto": "MAGDALENA",
    "name_city": "PIVIJAY"
  },
  {
    "id": 2173,
    "zip_code": 477057,
    "dane_code": 47551000,
    "name_depto": "MAGDALENA",
    "name_city": "PIVIJAY"
  },
  {
    "id": 2174,
    "zip_code": 477058,
    "dane_code": 47551000,
    "name_depto": "MAGDALENA",
    "name_city": "PIVIJAY"
  },
  {
    "id": 2175,
    "zip_code": 478001,
    "dane_code": 47189000,
    "name_depto": "MAGDALENA",
    "name_city": "CIENAGA"
  },
  {
    "id": 2176,
    "zip_code": 478002,
    "dane_code": 47189000,
    "name_depto": "MAGDALENA",
    "name_city": "CIENAGA"
  },
  {
    "id": 2177,
    "zip_code": 478007,
    "dane_code": 47189000,
    "name_depto": "MAGDALENA",
    "name_city": "CIENAGA"
  },
  {
    "id": 2178,
    "zip_code": 478008,
    "dane_code": 47189000,
    "name_depto": "MAGDALENA",
    "name_city": "CIENAGA"
  },
  {
    "id": 2179,
    "zip_code": 478020,
    "dane_code": 47980000,
    "name_depto": "MAGDALENA",
    "name_city": "ZONA BANANERA"
  },
  {
    "id": 2180,
    "zip_code": 478027,
    "dane_code": 47980000,
    "name_depto": "MAGDALENA",
    "name_city": "ZONA BANANERA"
  },
  {
    "id": 2181,
    "zip_code": 478028,
    "dane_code": 47980000,
    "name_depto": "MAGDALENA",
    "name_city": "ZONA BANANERA"
  },
  {
    "id": 2182,
    "zip_code": 478029,
    "dane_code": 47980000,
    "name_depto": "MAGDALENA",
    "name_city": "ZONA BANANERA"
  },
  {
    "id": 2183,
    "zip_code": 478037,
    "dane_code": 47980000,
    "name_depto": "MAGDALENA",
    "name_city": "ZONA BANANERA"
  },
  {
    "id": 2184,
    "zip_code": 478040,
    "dane_code": 47570000,
    "name_depto": "MAGDALENA",
    "name_city": "PUEBLOVIEJO"
  },
  {
    "id": 2185,
    "zip_code": 478047,
    "dane_code": 47570000,
    "name_depto": "MAGDALENA",
    "name_city": "PUEBLOVIEJO"
  },
  {
    "id": 2186,
    "zip_code": 478048,
    "dane_code": 47570000,
    "name_depto": "MAGDALENA",
    "name_city": "PUEBLOVIEJO"
  },
  {
    "id": 2187,
    "zip_code": 478060,
    "dane_code": 47268000,
    "name_depto": "MAGDALENA",
    "name_city": "EL RETEN"
  },
  {
    "id": 2188,
    "zip_code": 478067,
    "dane_code": 47268000,
    "name_depto": "MAGDALENA",
    "name_city": "EL RETEN"
  },
  {
    "id": 2189,
    "zip_code": 500001,
    "dane_code": 50001000,
    "name_depto": "META",
    "name_city": "VILLAVICENCIO"
  },
  {
    "id": 2190,
    "zip_code": 500002,
    "dane_code": 50001000,
    "name_depto": "META",
    "name_city": "VILLAVICENCIO"
  },
  {
    "id": 2191,
    "zip_code": 500003,
    "dane_code": 50001000,
    "name_depto": "META",
    "name_city": "VILLAVICENCIO"
  },
  {
    "id": 2192,
    "zip_code": 500004,
    "dane_code": 50001000,
    "name_depto": "META",
    "name_city": "VILLAVICENCIO"
  },
  {
    "id": 2193,
    "zip_code": 500005,
    "dane_code": 50001000,
    "name_depto": "META",
    "name_city": "VILLAVICENCIO"
  },
  {
    "id": 2194,
    "zip_code": 500007,
    "dane_code": 50001000,
    "name_depto": "META",
    "name_city": "VILLAVICENCIO"
  },
  {
    "id": 2195,
    "zip_code": 500008,
    "dane_code": 50001000,
    "name_depto": "META",
    "name_city": "VILLAVICENCIO"
  },
  {
    "id": 2196,
    "zip_code": 500009,
    "dane_code": 50001000,
    "name_depto": "META",
    "name_city": "VILLAVICENCIO"
  },
  {
    "id": 2197,
    "zip_code": 500017,
    "dane_code": 50001000,
    "name_depto": "META",
    "name_city": "VILLAVICENCIO"
  },
  {
    "id": 2198,
    "zip_code": 501001,
    "dane_code": 50110000,
    "name_depto": "META",
    "name_city": "BARRANCA DE UPIA"
  },
  {
    "id": 2199,
    "zip_code": 501007,
    "dane_code": 50110000,
    "name_depto": "META",
    "name_city": "BARRANCA DE UPIA"
  },
  {
    "id": 2200,
    "zip_code": 501011,
    "dane_code": 50124000,
    "name_depto": "META",
    "name_city": "CABUYARO"
  },
  {
    "id": 2201,
    "zip_code": 501017,
    "dane_code": 50124000,
    "name_depto": "META",
    "name_city": "CABUYARO"
  },
  {
    "id": 2202,
    "zip_code": 501018,
    "dane_code": 50124000,
    "name_depto": "META",
    "name_city": "CABUYARO"
  },
  {
    "id": 2203,
    "zip_code": 501021,
    "dane_code": 50226000,
    "name_depto": "META",
    "name_city": "CUMARAL"
  },
  {
    "id": 2204,
    "zip_code": 501027,
    "dane_code": 50226000,
    "name_depto": "META",
    "name_city": "CUMARAL"
  },
  {
    "id": 2205,
    "zip_code": 501028,
    "dane_code": 50226000,
    "name_depto": "META",
    "name_city": "CUMARAL"
  },
  {
    "id": 2206,
    "zip_code": 501031,
    "dane_code": 50606000,
    "name_depto": "META",
    "name_city": "RESTREPO"
  },
  {
    "id": 2207,
    "zip_code": 501037,
    "dane_code": 50606000,
    "name_depto": "META",
    "name_city": "RESTREPO"
  },
  {
    "id": 2208,
    "zip_code": 501038,
    "dane_code": 50606000,
    "name_depto": "META",
    "name_city": "RESTREPO"
  },
  {
    "id": 2209,
    "zip_code": 501041,
    "dane_code": 50245000,
    "name_depto": "META",
    "name_city": "EL CALVARIO"
  },
  {
    "id": 2210,
    "zip_code": 501047,
    "dane_code": 50245000,
    "name_depto": "META",
    "name_city": "EL CALVARIO"
  },
  {
    "id": 2211,
    "zip_code": 501051,
    "dane_code": 50686000,
    "name_depto": "META",
    "name_city": "SAN JUANITO"
  },
  {
    "id": 2212,
    "zip_code": 501057,
    "dane_code": 50686000,
    "name_depto": "META",
    "name_city": "SAN JUANITO"
  },
  {
    "id": 2213,
    "zip_code": 502001,
    "dane_code": 50573000,
    "name_depto": "META",
    "name_city": "PUERTO LOPEZ"
  },
  {
    "id": 2214,
    "zip_code": 502007,
    "dane_code": 50573000,
    "name_depto": "META",
    "name_city": "PUERTO LOPEZ"
  },
  {
    "id": 2215,
    "zip_code": 502008,
    "dane_code": 50573000,
    "name_depto": "META",
    "name_city": "PUERTO LOPEZ"
  },
  {
    "id": 2216,
    "zip_code": 502009,
    "dane_code": 50573000,
    "name_depto": "META",
    "name_city": "PUERTO LOPEZ"
  },
  {
    "id": 2217,
    "zip_code": 502041,
    "dane_code": 50568000,
    "name_depto": "META",
    "name_city": "PUERTO GAITAN"
  },
  {
    "id": 2218,
    "zip_code": 502047,
    "dane_code": 50568000,
    "name_depto": "META",
    "name_city": "PUERTO GAITAN"
  },
  {
    "id": 2219,
    "zip_code": 502048,
    "dane_code": 50568000,
    "name_depto": "META",
    "name_city": "PUERTO GAITAN"
  },
  {
    "id": 2220,
    "zip_code": 502049,
    "dane_code": 50568000,
    "name_depto": "META",
    "name_city": "PUERTO GAITAN"
  },
  {
    "id": 2221,
    "zip_code": 502057,
    "dane_code": 50568000,
    "name_depto": "META",
    "name_city": "PUERTO GAITAN"
  },
  {
    "id": 2222,
    "zip_code": 502058,
    "dane_code": 50568000,
    "name_depto": "META",
    "name_city": "PUERTO GAITAN"
  },
  {
    "id": 2223,
    "zip_code": 502059,
    "dane_code": 50568000,
    "name_depto": "META",
    "name_city": "PUERTO GAITAN"
  },
  {
    "id": 2224,
    "zip_code": 503001,
    "dane_code": 50577000,
    "name_depto": "META",
    "name_city": "PUERTO LLERAS"
  },
  {
    "id": 2225,
    "zip_code": 503007,
    "dane_code": 50577000,
    "name_depto": "META",
    "name_city": "PUERTO LLERAS"
  },
  {
    "id": 2226,
    "zip_code": 503008,
    "dane_code": 50577000,
    "name_depto": "META",
    "name_city": "PUERTO LLERAS"
  },
  {
    "id": 2227,
    "zip_code": 503021,
    "dane_code": 50325000,
    "name_depto": "META",
    "name_city": "MAPIRIPAN"
  },
  {
    "id": 2228,
    "zip_code": 503027,
    "dane_code": 50325000,
    "name_depto": "META",
    "name_city": "MAPIRIPAN"
  },
  {
    "id": 2229,
    "zip_code": 503028,
    "dane_code": 50325000,
    "name_depto": "META",
    "name_city": "MAPIRIPAN"
  },
  {
    "id": 2230,
    "zip_code": 503029,
    "dane_code": 50325000,
    "name_depto": "META",
    "name_city": "MAPIRIPAN"
  },
  {
    "id": 2231,
    "zip_code": 503037,
    "dane_code": 50325000,
    "name_depto": "META",
    "name_city": "MAPIRIPAN"
  },
  {
    "id": 2232,
    "zip_code": 503041,
    "dane_code": 50450000,
    "name_depto": "META",
    "name_city": "PUERTO CONCORDIA"
  },
  {
    "id": 2233,
    "zip_code": 503047,
    "dane_code": 50450000,
    "name_depto": "META",
    "name_city": "PUERTO CONCORDIA"
  },
  {
    "id": 2234,
    "zip_code": 503061,
    "dane_code": 50590000,
    "name_depto": "META",
    "name_city": "PUERTO RICO"
  },
  {
    "id": 2235,
    "zip_code": 503067,
    "dane_code": 50590000,
    "name_depto": "META",
    "name_city": "PUERTO RICO"
  },
  {
    "id": 2236,
    "zip_code": 503068,
    "dane_code": 50590000,
    "name_depto": "META",
    "name_city": "PUERTO RICO"
  },
  {
    "id": 2237,
    "zip_code": 504001,
    "dane_code": 50313000,
    "name_depto": "META",
    "name_city": "GRANADA"
  },
  {
    "id": 2238,
    "zip_code": 504007,
    "dane_code": 50313000,
    "name_depto": "META",
    "name_city": "GRANADA"
  },
  {
    "id": 2239,
    "zip_code": 504008,
    "dane_code": 50313000,
    "name_depto": "META",
    "name_city": "GRANADA"
  },
  {
    "id": 2240,
    "zip_code": 504021,
    "dane_code": 50287000,
    "name_depto": "META",
    "name_city": "FUENTE DE ORO"
  },
  {
    "id": 2241,
    "zip_code": 504027,
    "dane_code": 50287000,
    "name_depto": "META",
    "name_city": "FUENTE DE ORO"
  },
  {
    "id": 2242,
    "zip_code": 504028,
    "dane_code": 50287000,
    "name_depto": "META",
    "name_city": "FUENTE DE ORO"
  },
  {
    "id": 2243,
    "zip_code": 504041,
    "dane_code": 50683000,
    "name_depto": "META",
    "name_city": "SAN JUAN DE ARAMA"
  },
  {
    "id": 2244,
    "zip_code": 504047,
    "dane_code": 50683000,
    "name_depto": "META",
    "name_city": "SAN JUAN DE ARAMA"
  },
  {
    "id": 2245,
    "zip_code": 504048,
    "dane_code": 50683000,
    "name_depto": "META",
    "name_city": "SAN JUAN DE ARAMA"
  },
  {
    "id": 2246,
    "zip_code": 504061,
    "dane_code": 50711000,
    "name_depto": "META",
    "name_city": "VISTAHERMOSA"
  },
  {
    "id": 2247,
    "zip_code": 504067,
    "dane_code": 50711000,
    "name_depto": "META",
    "name_city": "VISTAHERMOSA"
  },
  {
    "id": 2248,
    "zip_code": 504068,
    "dane_code": 50711000,
    "name_depto": "META",
    "name_city": "VISTAHERMOSA"
  },
  {
    "id": 2249,
    "zip_code": 505001,
    "dane_code": 50330000,
    "name_depto": "META",
    "name_city": "MESETAS"
  },
  {
    "id": 2250,
    "zip_code": 505007,
    "dane_code": 50330000,
    "name_depto": "META",
    "name_city": "MESETAS"
  },
  {
    "id": 2251,
    "zip_code": 505008,
    "dane_code": 50330000,
    "name_depto": "META",
    "name_city": "MESETAS"
  },
  {
    "id": 2252,
    "zip_code": 505021,
    "dane_code": 50350000,
    "name_depto": "META",
    "name_city": "LA MACARENA"
  },
  {
    "id": 2253,
    "zip_code": 505027,
    "dane_code": 50350000,
    "name_depto": "META",
    "name_city": "LA MACARENA"
  },
  {
    "id": 2254,
    "zip_code": 505028,
    "dane_code": 50350000,
    "name_depto": "META",
    "name_city": "LA MACARENA"
  },
  {
    "id": 2255,
    "zip_code": 505041,
    "dane_code": 50370000,
    "name_depto": "META",
    "name_city": "URIBE"
  },
  {
    "id": 2256,
    "zip_code": 505047,
    "dane_code": 50370000,
    "name_depto": "META",
    "name_city": "URIBE"
  },
  {
    "id": 2257,
    "zip_code": 505048,
    "dane_code": 50370000,
    "name_depto": "META",
    "name_city": "URIBE"
  },
  {
    "id": 2258,
    "zip_code": 506001,
    "dane_code": 50223000,
    "name_depto": "META",
    "name_city": "CUBARRAL"
  },
  {
    "id": 2259,
    "zip_code": 506007,
    "dane_code": 50223000,
    "name_depto": "META",
    "name_city": "CUBARRAL"
  },
  {
    "id": 2260,
    "zip_code": 506008,
    "dane_code": 50223000,
    "name_depto": "META",
    "name_city": "CUBARRAL"
  },
  {
    "id": 2261,
    "zip_code": 506021,
    "dane_code": 50270000,
    "name_depto": "META",
    "name_city": "EL DORADO"
  },
  {
    "id": 2262,
    "zip_code": 506027,
    "dane_code": 50270000,
    "name_depto": "META",
    "name_city": "EL DORADO"
  },
  {
    "id": 2263,
    "zip_code": 506041,
    "dane_code": 50251000,
    "name_depto": "META",
    "name_city": "EL CASTILLO"
  },
  {
    "id": 2264,
    "zip_code": 506047,
    "dane_code": 50251000,
    "name_depto": "META",
    "name_city": "EL CASTILLO"
  },
  {
    "id": 2265,
    "zip_code": 506048,
    "dane_code": 50251000,
    "name_depto": "META",
    "name_city": "EL CASTILLO"
  },
  {
    "id": 2266,
    "zip_code": 506061,
    "dane_code": 50400000,
    "name_depto": "META",
    "name_city": "LEJANIAS"
  },
  {
    "id": 2267,
    "zip_code": 506067,
    "dane_code": 50400000,
    "name_depto": "META",
    "name_city": "LEJANIAS"
  },
  {
    "id": 2268,
    "zip_code": 506068,
    "dane_code": 50400000,
    "name_depto": "META",
    "name_city": "LEJANIAS"
  },
  {
    "id": 2269,
    "zip_code": 507001,
    "dane_code": 50006000,
    "name_depto": "META",
    "name_city": "ACACIAS"
  },
  {
    "id": 2270,
    "zip_code": 507007,
    "dane_code": 50006000,
    "name_depto": "META",
    "name_city": "ACACIAS"
  },
  {
    "id": 2271,
    "zip_code": 507008,
    "dane_code": 50006000,
    "name_depto": "META",
    "name_city": "ACACIAS"
  },
  {
    "id": 2272,
    "zip_code": 507009,
    "dane_code": 50006000,
    "name_depto": "META",
    "name_city": "ACACIAS"
  },
  {
    "id": 2273,
    "zip_code": 507011,
    "dane_code": 50680000,
    "name_depto": "META",
    "name_city": "SAN CARLOS DE GUAROA"
  },
  {
    "id": 2274,
    "zip_code": 507017,
    "dane_code": 50006000,
    "name_depto": "META",
    "name_city": "ACACIAS"
  },
  {
    "id": 2275,
    "zip_code": 507019,
    "dane_code": 50680000,
    "name_depto": "META",
    "name_city": "SAN CARLOS DE GUAROA"
  },
  {
    "id": 2276,
    "zip_code": 507021,
    "dane_code": 50689000,
    "name_depto": "META",
    "name_city": "SAN MARTIN"
  },
  {
    "id": 2277,
    "zip_code": 507027,
    "dane_code": 50689000,
    "name_depto": "META",
    "name_city": "SAN MARTIN"
  },
  {
    "id": 2278,
    "zip_code": 507028,
    "dane_code": 50689000,
    "name_depto": "META",
    "name_city": "SAN MARTIN"
  },
  {
    "id": 2279,
    "zip_code": 507029,
    "dane_code": 50689000,
    "name_depto": "META",
    "name_city": "SAN MARTIN"
  },
  {
    "id": 2280,
    "zip_code": 507037,
    "dane_code": 50689000,
    "name_depto": "META",
    "name_city": "SAN MARTIN"
  },
  {
    "id": 2281,
    "zip_code": 507041,
    "dane_code": 50150000,
    "name_depto": "META",
    "name_city": "CASTILLA LA NUEVA"
  },
  {
    "id": 2282,
    "zip_code": 507047,
    "dane_code": 50150000,
    "name_depto": "META",
    "name_city": "CASTILLA LA NUEVA"
  },
  {
    "id": 2283,
    "zip_code": 507048,
    "dane_code": 50150000,
    "name_depto": "META",
    "name_city": "CASTILLA LA NUEVA"
  },
  {
    "id": 2284,
    "zip_code": 507051,
    "dane_code": 50318000,
    "name_depto": "META",
    "name_city": "GUAMAL"
  },
  {
    "id": 2285,
    "zip_code": 507057,
    "dane_code": 50318000,
    "name_depto": "META",
    "name_city": "GUAMAL"
  },
  {
    "id": 2286,
    "zip_code": 507058,
    "dane_code": 50318000,
    "name_depto": "META",
    "name_city": "GUAMAL"
  },
  {
    "id": 2287,
    "zip_code": 520001,
    "dane_code": 52001000,
    "name_depto": "NARI√ëO",
    "name_city": "PASTO"
  },
  {
    "id": 2288,
    "zip_code": 520002,
    "dane_code": 52001000,
    "name_depto": "NARI√ëO",
    "name_city": "PASTO"
  },
  {
    "id": 2289,
    "zip_code": 520003,
    "dane_code": 52001000,
    "name_depto": "NARI√ëO",
    "name_city": "PASTO"
  },
  {
    "id": 2290,
    "zip_code": 520004,
    "dane_code": 52001000,
    "name_depto": "NARI√ëO",
    "name_city": "PASTO"
  },
  {
    "id": 2291,
    "zip_code": 520006,
    "dane_code": 52001000,
    "name_depto": "NARI√ëO",
    "name_city": "PASTO"
  },
  {
    "id": 2292,
    "zip_code": 520007,
    "dane_code": 52001000,
    "name_depto": "NARI√ëO",
    "name_city": "PASTO"
  },
  {
    "id": 2293,
    "zip_code": 520008,
    "dane_code": 52001000,
    "name_depto": "NARI√ëO",
    "name_city": "PASTO"
  },
  {
    "id": 2294,
    "zip_code": 520009,
    "dane_code": 52001000,
    "name_depto": "NARI√ëO",
    "name_city": "PASTO"
  },
  {
    "id": 2295,
    "zip_code": 520010,
    "dane_code": 52001000,
    "name_depto": "NARI√ëO",
    "name_city": "PASTO"
  },
  {
    "id": 2296,
    "zip_code": 520017,
    "dane_code": 52001000,
    "name_depto": "NARI√ëO",
    "name_city": "PASTO"
  },
  {
    "id": 2297,
    "zip_code": 520018,
    "dane_code": 52001000,
    "name_depto": "NARI√ëO",
    "name_city": "PASTO"
  },
  {
    "id": 2298,
    "zip_code": 520019,
    "dane_code": 52001000,
    "name_depto": "NARI√ëO",
    "name_city": "PASTO"
  },
  {
    "id": 2299,
    "zip_code": 520027,
    "dane_code": 52001000,
    "name_depto": "NARI√ëO",
    "name_city": "PASTO"
  },
  {
    "id": 2300,
    "zip_code": 520028,
    "dane_code": 52001000,
    "name_depto": "NARI√ëO",
    "name_city": "PASTO"
  },
  {
    "id": 2301,
    "zip_code": 520029,
    "dane_code": 52001000,
    "name_depto": "NARI√ëO",
    "name_city": "PASTO"
  },
  {
    "id": 2302,
    "zip_code": 520037,
    "dane_code": 52001000,
    "name_depto": "NARI√ëO",
    "name_city": "PASTO"
  },
  {
    "id": 2303,
    "zip_code": 520038,
    "dane_code": 52001000,
    "name_depto": "NARI√ëO",
    "name_city": "PASTO"
  },
  {
    "id": 2304,
    "zip_code": 520039,
    "dane_code": 52001000,
    "name_depto": "NARI√ëO",
    "name_city": "PASTO"
  },
  {
    "id": 2305,
    "zip_code": 520501,
    "dane_code": 52110000,
    "name_depto": "NARI√ëO",
    "name_city": "BUESACO"
  },
  {
    "id": 2306,
    "zip_code": 520507,
    "dane_code": 52110000,
    "name_depto": "NARI√ëO",
    "name_city": "BUESACO"
  },
  {
    "id": 2307,
    "zip_code": 520508,
    "dane_code": 52110000,
    "name_depto": "NARI√ëO",
    "name_city": "BUESACO"
  },
  {
    "id": 2308,
    "zip_code": 520509,
    "dane_code": 52110000,
    "name_depto": "NARI√ëO",
    "name_city": "BUESACO"
  },
  {
    "id": 2309,
    "zip_code": 520517,
    "dane_code": 52110000,
    "name_depto": "NARI√ëO",
    "name_city": "BUESACO"
  },
  {
    "id": 2310,
    "zip_code": 520518,
    "dane_code": 52110000,
    "name_depto": "NARI√ëO",
    "name_city": "BUESACO"
  },
  {
    "id": 2311,
    "zip_code": 520530,
    "dane_code": 52258000,
    "name_depto": "NARI√ëO",
    "name_city": "EL TABLON DE GOMEZ"
  },
  {
    "id": 2312,
    "zip_code": 520537,
    "dane_code": 52258000,
    "name_depto": "NARI√ëO",
    "name_city": "EL TABLON DE GOMEZ"
  },
  {
    "id": 2313,
    "zip_code": 520538,
    "dane_code": 52258000,
    "name_depto": "NARI√ëO",
    "name_city": "EL TABLON DE GOMEZ"
  },
  {
    "id": 2314,
    "zip_code": 520539,
    "dane_code": 52258000,
    "name_depto": "NARI√ëO",
    "name_city": "EL TABLON DE GOMEZ"
  },
  {
    "id": 2315,
    "zip_code": 520557,
    "dane_code": 52019000,
    "name_depto": "NARI√ëO",
    "name_city": "SAN JOSE DE ALBAN"
  },
  {
    "id": 2316,
    "zip_code": 520570,
    "dane_code": 52051000,
    "name_depto": "NARI√ëO",
    "name_city": "ARBOLEDA - BERRUECOS"
  },
  {
    "id": 2317,
    "zip_code": 520577,
    "dane_code": 52051000,
    "name_depto": "NARI√ëO",
    "name_city": "ARBOLEDA - BERRUECOS"
  },
  {
    "id": 2318,
    "zip_code": 520578,
    "dane_code": 52051000,
    "name_depto": "NARI√ëO",
    "name_city": "ARBOLEDA - BERRUECOS"
  },
  {
    "id": 2319,
    "zip_code": 521001,
    "dane_code": 52685000,
    "name_depto": "NARI√ëO",
    "name_city": "SAN BERNARDO"
  },
  {
    "id": 2320,
    "zip_code": 521007,
    "dane_code": 52685000,
    "name_depto": "NARI√ëO",
    "name_city": "SAN BERNARDO"
  },
  {
    "id": 2321,
    "zip_code": 521020,
    "dane_code": 52378000,
    "name_depto": "NARI√ëO",
    "name_city": "LA CRUZ"
  },
  {
    "id": 2322,
    "zip_code": 521027,
    "dane_code": 52378000,
    "name_depto": "NARI√ëO",
    "name_city": "LA CRUZ"
  },
  {
    "id": 2323,
    "zip_code": 521028,
    "dane_code": 52378000,
    "name_depto": "NARI√ëO",
    "name_city": "LA CRUZ"
  },
  {
    "id": 2324,
    "zip_code": 521040,
    "dane_code": 52693000,
    "name_depto": "NARI√ëO",
    "name_city": "SAN PABLO"
  },
  {
    "id": 2325,
    "zip_code": 521047,
    "dane_code": 52693000,
    "name_depto": "NARI√ëO",
    "name_city": "SAN PABLO"
  },
  {
    "id": 2326,
    "zip_code": 521048,
    "dane_code": 52693000,
    "name_depto": "NARI√ëO",
    "name_city": "SAN PABLO"
  },
  {
    "id": 2327,
    "zip_code": 521050,
    "dane_code": 52019000,
    "name_depto": "NARI√ëO",
    "name_city": "SAN JOSE DE ALBAN"
  },
  {
    "id": 2328,
    "zip_code": 521060,
    "dane_code": 52203000,
    "name_depto": "NARI√ëO",
    "name_city": "COLON"
  },
  {
    "id": 2329,
    "zip_code": 521067,
    "dane_code": 52203000,
    "name_depto": "NARI√ëO",
    "name_city": "COLON"
  },
  {
    "id": 2330,
    "zip_code": 521080,
    "dane_code": 52083000,
    "name_depto": "NARI√ëO",
    "name_city": "BELEN"
  },
  {
    "id": 2331,
    "zip_code": 521087,
    "dane_code": 52083000,
    "name_depto": "NARI√ëO",
    "name_city": "BELEN"
  },
  {
    "id": 2332,
    "zip_code": 521501,
    "dane_code": 52694000,
    "name_depto": "NARI√ëO",
    "name_city": "SAN PEDRO DE CARTAGO"
  },
  {
    "id": 2333,
    "zip_code": 521507,
    "dane_code": 52694000,
    "name_depto": "NARI√ëO",
    "name_city": "SAN PEDRO DE CARTAGO"
  },
  {
    "id": 2334,
    "zip_code": 521508,
    "dane_code": 52694000,
    "name_depto": "NARI√ëO",
    "name_city": "SAN PEDRO DE CARTAGO"
  },
  {
    "id": 2335,
    "zip_code": 521520,
    "dane_code": 52399000,
    "name_depto": "NARI√ëO",
    "name_city": "LA UNION"
  },
  {
    "id": 2336,
    "zip_code": 521527,
    "dane_code": 52399000,
    "name_depto": "NARI√ëO",
    "name_city": "LA UNION"
  },
  {
    "id": 2337,
    "zip_code": 521528,
    "dane_code": 52399000,
    "name_depto": "NARI√ëO",
    "name_city": "LA UNION"
  },
  {
    "id": 2338,
    "zip_code": 521529,
    "dane_code": 52399000,
    "name_depto": "NARI√ëO",
    "name_city": "LA UNION"
  },
  {
    "id": 2339,
    "zip_code": 521540,
    "dane_code": 52687000,
    "name_depto": "NARI√ëO",
    "name_city": "SAN LORENZO"
  },
  {
    "id": 2340,
    "zip_code": 521547,
    "dane_code": 52687000,
    "name_depto": "NARI√ëO",
    "name_city": "SAN LORENZO"
  },
  {
    "id": 2341,
    "zip_code": 521548,
    "dane_code": 52687000,
    "name_depto": "NARI√ëO",
    "name_city": "SAN LORENZO"
  },
  {
    "id": 2342,
    "zip_code": 521549,
    "dane_code": 52687000,
    "name_depto": "NARI√ëO",
    "name_city": "SAN LORENZO"
  },
  {
    "id": 2343,
    "zip_code": 521560,
    "dane_code": 52786000,
    "name_depto": "NARI√ëO",
    "name_city": "TAMINANGO"
  },
  {
    "id": 2344,
    "zip_code": 521567,
    "dane_code": 52786000,
    "name_depto": "NARI√ëO",
    "name_city": "TAMINANGO"
  },
  {
    "id": 2345,
    "zip_code": 521568,
    "dane_code": 52786000,
    "name_depto": "NARI√ëO",
    "name_city": "TAMINANGO"
  },
  {
    "id": 2346,
    "zip_code": 521569,
    "dane_code": 52786000,
    "name_depto": "NARI√ëO",
    "name_city": "TAMINANGO"
  },
  {
    "id": 2347,
    "zip_code": 522001,
    "dane_code": 52240000,
    "name_depto": "NARI√ëO",
    "name_city": "CHACHAG√úI"
  },
  {
    "id": 2348,
    "zip_code": 522007,
    "dane_code": 52240000,
    "name_depto": "NARI√ëO",
    "name_city": "CHACHAG√úI"
  },
  {
    "id": 2349,
    "zip_code": 522020,
    "dane_code": 52480000,
    "name_depto": "NARI√ëO",
    "name_city": "NARI√ëO"
  },
  {
    "id": 2350,
    "zip_code": 522027,
    "dane_code": 52480000,
    "name_depto": "NARI√ëO",
    "name_city": "NARI√ëO"
  },
  {
    "id": 2351,
    "zip_code": 522040,
    "dane_code": 52381000,
    "name_depto": "NARI√ëO",
    "name_city": "LA FLORIDA"
  },
  {
    "id": 2352,
    "zip_code": 522047,
    "dane_code": 52381000,
    "name_depto": "NARI√ëO",
    "name_city": "LA FLORIDA"
  },
  {
    "id": 2353,
    "zip_code": 522048,
    "dane_code": 52381000,
    "name_depto": "NARI√ëO",
    "name_city": "LA FLORIDA"
  },
  {
    "id": 2354,
    "zip_code": 522060,
    "dane_code": 19256000,
    "name_depto": "NARI√ëO",
    "name_city": "EL TAMBO"
  },
  {
    "id": 2355,
    "zip_code": 522067,
    "dane_code": 19256000,
    "name_depto": "NARI√ëO",
    "name_city": "EL TAMBO"
  },
  {
    "id": 2356,
    "zip_code": 522068,
    "dane_code": 19256000,
    "name_depto": "NARI√ëO",
    "name_city": "EL TAMBO"
  },
  {
    "id": 2357,
    "zip_code": 522080,
    "dane_code": 52254000,
    "name_depto": "NARI√ëO",
    "name_city": "EL PE√ëOL"
  },
  {
    "id": 2358,
    "zip_code": 522087,
    "dane_code": 52254000,
    "name_depto": "NARI√ëO",
    "name_city": "EL PE√ëOL"
  },
  {
    "id": 2359,
    "zip_code": 522088,
    "dane_code": 52254000,
    "name_depto": "NARI√ëO",
    "name_city": "EL PE√ëOL"
  },
  {
    "id": 2360,
    "zip_code": 522501,
    "dane_code": 52411000,
    "name_depto": "NARI√ëO",
    "name_city": "LINARES"
  },
  {
    "id": 2361,
    "zip_code": 522507,
    "dane_code": 52411000,
    "name_depto": "NARI√ëO",
    "name_city": "LINARES"
  },
  {
    "id": 2362,
    "zip_code": 522508,
    "dane_code": 52411000,
    "name_depto": "NARI√ëO",
    "name_city": "LINARES"
  },
  {
    "id": 2363,
    "zip_code": 522520,
    "dane_code": 52683000,
    "name_depto": "NARI√ëO",
    "name_city": "SANDONA"
  },
  {
    "id": 2364,
    "zip_code": 522527,
    "dane_code": 52683000,
    "name_depto": "NARI√ëO",
    "name_city": "SANDONA"
  },
  {
    "id": 2365,
    "zip_code": 522540,
    "dane_code": 52207000,
    "name_depto": "NARI√ëO",
    "name_city": "CONSACA"
  },
  {
    "id": 2366,
    "zip_code": 522547,
    "dane_code": 52207000,
    "name_depto": "NARI√ëO",
    "name_city": "CONSACA"
  },
  {
    "id": 2367,
    "zip_code": 522548,
    "dane_code": 52207000,
    "name_depto": "NARI√ëO",
    "name_city": "CONSACA"
  },
  {
    "id": 2368,
    "zip_code": 523001,
    "dane_code": 52885000,
    "name_depto": "NARI√ëO",
    "name_city": "YACUANQUER"
  },
  {
    "id": 2369,
    "zip_code": 523007,
    "dane_code": 52885000,
    "name_depto": "NARI√ëO",
    "name_city": "YACUANQUER"
  },
  {
    "id": 2370,
    "zip_code": 523008,
    "dane_code": 52885000,
    "name_depto": "NARI√ëO",
    "name_city": "YACUANQUER"
  },
  {
    "id": 2371,
    "zip_code": 523020,
    "dane_code": 52354000,
    "name_depto": "NARI√ëO",
    "name_city": "IMUES"
  },
  {
    "id": 2372,
    "zip_code": 523027,
    "dane_code": 52354000,
    "name_depto": "NARI√ëO",
    "name_city": "IMUES"
  },
  {
    "id": 2373,
    "zip_code": 523028,
    "dane_code": 52354000,
    "name_depto": "NARI√ëO",
    "name_city": "IMUES"
  },
  {
    "id": 2374,
    "zip_code": 523040,
    "dane_code": 52506000,
    "name_depto": "NARI√ëO",
    "name_city": "OSPINA"
  },
  {
    "id": 2375,
    "zip_code": 523047,
    "dane_code": 52506000,
    "name_depto": "NARI√ëO",
    "name_city": "OSPINA"
  },
  {
    "id": 2376,
    "zip_code": 523060,
    "dane_code": 52352000,
    "name_depto": "NARI√ëO",
    "name_city": "ILES"
  },
  {
    "id": 2377,
    "zip_code": 523067,
    "dane_code": 52352000,
    "name_depto": "NARI√ëO",
    "name_city": "ILES"
  },
  {
    "id": 2378,
    "zip_code": 523068,
    "dane_code": 52352000,
    "name_depto": "NARI√ëO",
    "name_city": "ILES"
  },
  {
    "id": 2379,
    "zip_code": 523080,
    "dane_code": 52210000,
    "name_depto": "NARI√ëO",
    "name_city": "CONTADERO"
  },
  {
    "id": 2380,
    "zip_code": 523087,
    "dane_code": 52210000,
    "name_depto": "NARI√ëO",
    "name_city": "CONTADERO"
  },
  {
    "id": 2381,
    "zip_code": 523501,
    "dane_code": 52788000,
    "name_depto": "NARI√ëO",
    "name_city": "TANGUA"
  },
  {
    "id": 2382,
    "zip_code": 523507,
    "dane_code": 52788000,
    "name_depto": "NARI√ëO",
    "name_city": "TANGUA"
  },
  {
    "id": 2383,
    "zip_code": 523508,
    "dane_code": 52788000,
    "name_depto": "NARI√ëO",
    "name_city": "TANGUA"
  },
  {
    "id": 2384,
    "zip_code": 523520,
    "dane_code": 52287000,
    "name_depto": "NARI√ëO",
    "name_city": "FUNES"
  },
  {
    "id": 2385,
    "zip_code": 523527,
    "dane_code": 52287000,
    "name_depto": "NARI√ëO",
    "name_city": "FUNES"
  },
  {
    "id": 2386,
    "zip_code": 523528,
    "dane_code": 52287000,
    "name_depto": "NARI√ëO",
    "name_city": "FUNES"
  },
  {
    "id": 2387,
    "zip_code": 523540,
    "dane_code": 52573000,
    "name_depto": "NARI√ëO",
    "name_city": "PUERRES"
  },
  {
    "id": 2388,
    "zip_code": 523547,
    "dane_code": 52573000,
    "name_depto": "NARI√ëO",
    "name_city": "PUERRES"
  },
  {
    "id": 2389,
    "zip_code": 523548,
    "dane_code": 52573000,
    "name_depto": "NARI√ëO",
    "name_city": "PUERRES"
  },
  {
    "id": 2390,
    "zip_code": 524001,
    "dane_code": 52215000,
    "name_depto": "NARI√ëO",
    "name_city": "CORDOBA"
  },
  {
    "id": 2391,
    "zip_code": 524007,
    "dane_code": 52215000,
    "name_depto": "NARI√ëO",
    "name_city": "CORDOBA"
  },
  {
    "id": 2392,
    "zip_code": 524008,
    "dane_code": 52215000,
    "name_depto": "NARI√ëO",
    "name_city": "CORDOBA"
  },
  {
    "id": 2393,
    "zip_code": 524009,
    "dane_code": 52215000,
    "name_depto": "NARI√ëO",
    "name_city": "CORDOBA"
  },
  {
    "id": 2394,
    "zip_code": 524030,
    "dane_code": 52560000,
    "name_depto": "NARI√ëO",
    "name_city": "POTOSI"
  },
  {
    "id": 2395,
    "zip_code": 524037,
    "dane_code": 52560000,
    "name_depto": "NARI√ëO",
    "name_city": "POTOSI"
  },
  {
    "id": 2396,
    "zip_code": 524038,
    "dane_code": 52560000,
    "name_depto": "NARI√ëO",
    "name_city": "POTOSI"
  },
  {
    "id": 2397,
    "zip_code": 524039,
    "dane_code": 52560000,
    "name_depto": "NARI√ëO",
    "name_city": "POTOSI"
  },
  {
    "id": 2398,
    "zip_code": 524060,
    "dane_code": 52356000,
    "name_depto": "NARI√ëO",
    "name_city": "IPIALES"
  },
  {
    "id": 2399,
    "zip_code": 524061,
    "dane_code": 52356000,
    "name_depto": "NARI√ëO",
    "name_city": "IPIALES"
  },
  {
    "id": 2400,
    "zip_code": 524067,
    "dane_code": 52356000,
    "name_depto": "NARI√ëO",
    "name_city": "IPIALES"
  },
  {
    "id": 2401,
    "zip_code": 524068,
    "dane_code": 52356000,
    "name_depto": "NARI√ëO",
    "name_city": "IPIALES"
  },
  {
    "id": 2402,
    "zip_code": 524069,
    "dane_code": 52356000,
    "name_depto": "NARI√ëO",
    "name_city": "IPIALES"
  },
  {
    "id": 2403,
    "zip_code": 524077,
    "dane_code": 52356000,
    "name_depto": "NARI√ëO",
    "name_city": "IPIALES"
  },
  {
    "id": 2404,
    "zip_code": 524078,
    "dane_code": 52356000,
    "name_depto": "NARI√ëO",
    "name_city": "IPIALES"
  },
  {
    "id": 2405,
    "zip_code": 524501,
    "dane_code": 52323000,
    "name_depto": "NARI√ëO",
    "name_city": "GUALMATAN"
  },
  {
    "id": 2406,
    "zip_code": 524507,
    "dane_code": 52323000,
    "name_depto": "NARI√ëO",
    "name_city": "GUALMATAN"
  },
  {
    "id": 2407,
    "zip_code": 524520,
    "dane_code": 52585000,
    "name_depto": "NARI√ëO",
    "name_city": "PUPIALES"
  },
  {
    "id": 2408,
    "zip_code": 524527,
    "dane_code": 52585000,
    "name_depto": "NARI√ëO",
    "name_city": "PUPIALES"
  },
  {
    "id": 2409,
    "zip_code": 524528,
    "dane_code": 52585000,
    "name_depto": "NARI√ëO",
    "name_city": "PUPIALES"
  },
  {
    "id": 2410,
    "zip_code": 524540,
    "dane_code": 52022000,
    "name_depto": "NARI√ëO",
    "name_city": "ALDANA"
  },
  {
    "id": 2411,
    "zip_code": 524547,
    "dane_code": 52022000,
    "name_depto": "NARI√ëO",
    "name_city": "ALDANA"
  },
  {
    "id": 2412,
    "zip_code": 524560,
    "dane_code": 52224000,
    "name_depto": "NARI√ëO",
    "name_city": "CUASPUD"
  },
  {
    "id": 2413,
    "zip_code": 524567,
    "dane_code": 52224000,
    "name_depto": "NARI√ëO",
    "name_city": "CUASPUD"
  },
  {
    "id": 2414,
    "zip_code": 524580,
    "dane_code": 52317000,
    "name_depto": "NARI√ëO",
    "name_city": "GUACHUCAL"
  },
  {
    "id": 2415,
    "zip_code": 524587,
    "dane_code": 52317000,
    "name_depto": "NARI√ëO",
    "name_city": "GUACHUCAL"
  },
  {
    "id": 2416,
    "zip_code": 524588,
    "dane_code": 52317000,
    "name_depto": "NARI√ëO",
    "name_city": "GUACHUCAL"
  },
  {
    "id": 2417,
    "zip_code": 525001,
    "dane_code": 52227000,
    "name_depto": "NARI√ëO",
    "name_city": "CUMBAL"
  },
  {
    "id": 2418,
    "zip_code": 525007,
    "dane_code": 52227000,
    "name_depto": "NARI√ëO",
    "name_city": "CUMBAL"
  },
  {
    "id": 2419,
    "zip_code": 525008,
    "dane_code": 52227000,
    "name_depto": "NARI√ëO",
    "name_city": "CUMBAL"
  },
  {
    "id": 2420,
    "zip_code": 525009,
    "dane_code": 52227000,
    "name_depto": "NARI√ëO",
    "name_city": "CUMBAL"
  },
  {
    "id": 2421,
    "zip_code": 525030,
    "dane_code": 52612000,
    "name_depto": "NARI√ëO",
    "name_city": "RICAURTE"
  },
  {
    "id": 2422,
    "zip_code": 525037,
    "dane_code": 52612000,
    "name_depto": "NARI√ëO",
    "name_city": "RICAURTE"
  },
  {
    "id": 2423,
    "zip_code": 525038,
    "dane_code": 52612000,
    "name_depto": "NARI√ëO",
    "name_city": "RICAURTE"
  },
  {
    "id": 2424,
    "zip_code": 525039,
    "dane_code": 52612000,
    "name_depto": "NARI√ëO",
    "name_city": "RICAURTE"
  },
  {
    "id": 2425,
    "zip_code": 525047,
    "dane_code": 52612000,
    "name_depto": "NARI√ëO",
    "name_city": "RICAURTE"
  },
  {
    "id": 2426,
    "zip_code": 525060,
    "dane_code": 52435000,
    "name_depto": "NARI√ëO",
    "name_city": "MALLAMA"
  },
  {
    "id": 2427,
    "zip_code": 525067,
    "dane_code": 52435000,
    "name_depto": "NARI√ëO",
    "name_city": "MALLAMA"
  },
  {
    "id": 2428,
    "zip_code": 525068,
    "dane_code": 52435000,
    "name_depto": "NARI√ëO",
    "name_city": "MALLAMA"
  },
  {
    "id": 2429,
    "zip_code": 525069,
    "dane_code": 52435000,
    "name_depto": "NARI√ëO",
    "name_city": "MALLAMA"
  },
  {
    "id": 2430,
    "zip_code": 525501,
    "dane_code": 52320000,
    "name_depto": "NARI√ëO",
    "name_city": "GUAITARILLA"
  },
  {
    "id": 2431,
    "zip_code": 525507,
    "dane_code": 52320000,
    "name_depto": "NARI√ëO",
    "name_city": "GUAITARILLA"
  },
  {
    "id": 2432,
    "zip_code": 525508,
    "dane_code": 52320000,
    "name_depto": "NARI√ëO",
    "name_city": "GUAITARILLA"
  },
  {
    "id": 2433,
    "zip_code": 525520,
    "dane_code": 52838000,
    "name_depto": "NARI√ëO",
    "name_city": "TUQUERRES"
  },
  {
    "id": 2434,
    "zip_code": 525527,
    "dane_code": 52838000,
    "name_depto": "NARI√ëO",
    "name_city": "TUQUERRES"
  },
  {
    "id": 2435,
    "zip_code": 525528,
    "dane_code": 52838000,
    "name_depto": "NARI√ëO",
    "name_city": "TUQUERRES"
  },
  {
    "id": 2436,
    "zip_code": 525529,
    "dane_code": 52838000,
    "name_depto": "NARI√ëO",
    "name_city": "TUQUERRES"
  },
  {
    "id": 2437,
    "zip_code": 525537,
    "dane_code": 52838000,
    "name_depto": "NARI√ëO",
    "name_city": "TUQUERRES"
  },
  {
    "id": 2438,
    "zip_code": 525550,
    "dane_code": 52720000,
    "name_depto": "NARI√ëO",
    "name_city": "SAPUYES"
  },
  {
    "id": 2439,
    "zip_code": 525557,
    "dane_code": 52720000,
    "name_depto": "NARI√ëO",
    "name_city": "SAPUYES"
  },
  {
    "id": 2440,
    "zip_code": 525558,
    "dane_code": 52720000,
    "name_depto": "NARI√ëO",
    "name_city": "SAPUYES"
  },
  {
    "id": 2441,
    "zip_code": 525570,
    "dane_code": 52699000,
    "name_depto": "NARI√ëO",
    "name_city": "SANTACRUZ"
  },
  {
    "id": 2442,
    "zip_code": 525577,
    "dane_code": 52699000,
    "name_depto": "NARI√ëO",
    "name_city": "SANTACRUZ"
  },
  {
    "id": 2443,
    "zip_code": 525578,
    "dane_code": 52699000,
    "name_depto": "NARI√ëO",
    "name_city": "SANTACRUZ"
  },
  {
    "id": 2444,
    "zip_code": 525579,
    "dane_code": 52699000,
    "name_depto": "NARI√ëO",
    "name_city": "SANTACRUZ"
  },
  {
    "id": 2445,
    "zip_code": 526001,
    "dane_code": 52036000,
    "name_depto": "NARI√ëO",
    "name_city": "ANCUYA"
  },
  {
    "id": 2446,
    "zip_code": 526007,
    "dane_code": 52036000,
    "name_depto": "NARI√ëO",
    "name_city": "ANCUYA"
  },
  {
    "id": 2447,
    "zip_code": 526020,
    "dane_code": 52565000,
    "name_depto": "NARI√ëO",
    "name_city": "PROVIDENCIA"
  },
  {
    "id": 2448,
    "zip_code": 526027,
    "dane_code": 52565000,
    "name_depto": "NARI√ëO",
    "name_city": "PROVIDENCIA"
  },
  {
    "id": 2449,
    "zip_code": 526040,
    "dane_code": 52678000,
    "name_depto": "NARI√ëO",
    "name_city": "SAMANIEGO"
  },
  {
    "id": 2450,
    "zip_code": 526047,
    "dane_code": 52678000,
    "name_depto": "NARI√ëO",
    "name_city": "SAMANIEGO"
  },
  {
    "id": 2451,
    "zip_code": 526048,
    "dane_code": 52678000,
    "name_depto": "NARI√ëO",
    "name_city": "SAMANIEGO"
  },
  {
    "id": 2452,
    "zip_code": 526049,
    "dane_code": 52678000,
    "name_depto": "NARI√ëO",
    "name_city": "SAMANIEGO"
  },
  {
    "id": 2453,
    "zip_code": 526057,
    "dane_code": 52678000,
    "name_depto": "NARI√ëO",
    "name_city": "SAMANIEGO"
  },
  {
    "id": 2454,
    "zip_code": 526058,
    "dane_code": 52678000,
    "name_depto": "NARI√ëO",
    "name_city": "SAMANIEGO"
  },
  {
    "id": 2455,
    "zip_code": 526501,
    "dane_code": 52385000,
    "name_depto": "NARI√ëO",
    "name_city": "LA LLANADA"
  },
  {
    "id": 2456,
    "zip_code": 526507,
    "dane_code": 52385000,
    "name_depto": "NARI√ëO",
    "name_city": "LA LLANADA"
  },
  {
    "id": 2457,
    "zip_code": 526520,
    "dane_code": 52418000,
    "name_depto": "NARI√ëO",
    "name_city": "LOS ANDES"
  },
  {
    "id": 2458,
    "zip_code": 526527,
    "dane_code": 52418000,
    "name_depto": "NARI√ëO",
    "name_city": "LOS ANDES"
  },
  {
    "id": 2459,
    "zip_code": 526528,
    "dane_code": 52418000,
    "name_depto": "NARI√ëO",
    "name_city": "LOS ANDES"
  },
  {
    "id": 2460,
    "zip_code": 526529,
    "dane_code": 52418000,
    "name_depto": "NARI√ëO",
    "name_city": "LOS ANDES"
  },
  {
    "id": 2461,
    "zip_code": 526560,
    "dane_code": 52233000,
    "name_depto": "NARI√ëO",
    "name_city": "CUMBITARA"
  },
  {
    "id": 2462,
    "zip_code": 526567,
    "dane_code": 52233000,
    "name_depto": "NARI√ëO",
    "name_city": "CUMBITARA"
  },
  {
    "id": 2463,
    "zip_code": 526568,
    "dane_code": 52233000,
    "name_depto": "NARI√ëO",
    "name_city": "CUMBITARA"
  },
  {
    "id": 2464,
    "zip_code": 527001,
    "dane_code": 52540000,
    "name_depto": "NARI√ëO",
    "name_city": "POLICARPA"
  },
  {
    "id": 2465,
    "zip_code": 527007,
    "dane_code": 52540000,
    "name_depto": "NARI√ëO",
    "name_city": "POLICARPA"
  },
  {
    "id": 2466,
    "zip_code": 527008,
    "dane_code": 52540000,
    "name_depto": "NARI√ëO",
    "name_city": "POLICARPA"
  },
  {
    "id": 2467,
    "zip_code": 527009,
    "dane_code": 52540000,
    "name_depto": "NARI√ëO",
    "name_city": "POLICARPA"
  },
  {
    "id": 2468,
    "zip_code": 527030,
    "dane_code": 52256000,
    "name_depto": "NARI√ëO",
    "name_city": "EL ROSARIO"
  },
  {
    "id": 2469,
    "zip_code": 527037,
    "dane_code": 52256000,
    "name_depto": "NARI√ëO",
    "name_city": "EL ROSARIO"
  },
  {
    "id": 2470,
    "zip_code": 527038,
    "dane_code": 52256000,
    "name_depto": "NARI√ëO",
    "name_city": "EL ROSARIO"
  },
  {
    "id": 2471,
    "zip_code": 527039,
    "dane_code": 52256000,
    "name_depto": "NARI√ëO",
    "name_city": "EL ROSARIO"
  },
  {
    "id": 2472,
    "zip_code": 527060,
    "dane_code": 52405000,
    "name_depto": "NARI√ëO",
    "name_city": "LEIVA"
  },
  {
    "id": 2473,
    "zip_code": 527067,
    "dane_code": 52405000,
    "name_depto": "NARI√ëO",
    "name_city": "LEIVA"
  },
  {
    "id": 2474,
    "zip_code": 527068,
    "dane_code": 52405000,
    "name_depto": "NARI√ëO",
    "name_city": "LEIVA"
  },
  {
    "id": 2475,
    "zip_code": 527501,
    "dane_code": 52696000,
    "name_depto": "NARI√ëO",
    "name_city": "SANTA BARBARA"
  },
  {
    "id": 2476,
    "zip_code": 527507,
    "dane_code": 52696000,
    "name_depto": "NARI√ëO",
    "name_city": "SANTA BARBARA"
  },
  {
    "id": 2477,
    "zip_code": 527508,
    "dane_code": 52696000,
    "name_depto": "NARI√ëO",
    "name_city": "SANTA BARBARA"
  },
  {
    "id": 2478,
    "zip_code": 527520,
    "dane_code": 52250000,
    "name_depto": "NARI√ëO",
    "name_city": "EL CHARCO"
  },
  {
    "id": 2479,
    "zip_code": 527527,
    "dane_code": 52250000,
    "name_depto": "NARI√ëO",
    "name_city": "EL CHARCO"
  },
  {
    "id": 2480,
    "zip_code": 527528,
    "dane_code": 52250000,
    "name_depto": "NARI√ëO",
    "name_city": "EL CHARCO"
  },
  {
    "id": 2481,
    "zip_code": 527529,
    "dane_code": 52250000,
    "name_depto": "NARI√ëO",
    "name_city": "EL CHARCO"
  },
  {
    "id": 2482,
    "zip_code": 527537,
    "dane_code": 52250000,
    "name_depto": "NARI√ëO",
    "name_city": "EL CHARCO"
  },
  {
    "id": 2483,
    "zip_code": 527540,
    "dane_code": 52390000,
    "name_depto": "NARI√ëO",
    "name_city": "LA TOLA"
  },
  {
    "id": 2484,
    "zip_code": 527547,
    "dane_code": 52390000,
    "name_depto": "NARI√ëO",
    "name_city": "LA TOLA"
  },
  {
    "id": 2485,
    "zip_code": 527548,
    "dane_code": 52390000,
    "name_depto": "NARI√ëO",
    "name_city": "LA TOLA"
  },
  {
    "id": 2486,
    "zip_code": 527560,
    "dane_code": 52490000,
    "name_depto": "NARI√ëO",
    "name_city": "OLAYA HERRERA"
  },
  {
    "id": 2487,
    "zip_code": 527567,
    "dane_code": 52490000,
    "name_depto": "NARI√ëO",
    "name_city": "OLAYA HERRERA"
  },
  {
    "id": 2488,
    "zip_code": 527568,
    "dane_code": 52490000,
    "name_depto": "NARI√ëO",
    "name_city": "OLAYA HERRERA"
  },
  {
    "id": 2489,
    "zip_code": 527569,
    "dane_code": 52490000,
    "name_depto": "NARI√ëO",
    "name_city": "OLAYA HERRERA"
  },
  {
    "id": 2490,
    "zip_code": 527580,
    "dane_code": 52473000,
    "name_depto": "NARI√ëO",
    "name_city": "MOSQUERA"
  },
  {
    "id": 2491,
    "zip_code": 527587,
    "dane_code": 52473000,
    "name_depto": "NARI√ëO",
    "name_city": "MOSQUERA"
  },
  {
    "id": 2492,
    "zip_code": 527588,
    "dane_code": 52473000,
    "name_depto": "NARI√ëO",
    "name_city": "MOSQUERA"
  },
  {
    "id": 2493,
    "zip_code": 527589,
    "dane_code": 52473000,
    "name_depto": "NARI√ëO",
    "name_city": "MOSQUERA"
  },
  {
    "id": 2494,
    "zip_code": 528001,
    "dane_code": 52427000,
    "name_depto": "NARI√ëO",
    "name_city": "MAG√úI"
  },
  {
    "id": 2495,
    "zip_code": 528007,
    "dane_code": 52427000,
    "name_depto": "NARI√ëO",
    "name_city": "MAG√úI"
  },
  {
    "id": 2496,
    "zip_code": 528008,
    "dane_code": 52427000,
    "name_depto": "NARI√ëO",
    "name_city": "MAG√úI"
  },
  {
    "id": 2497,
    "zip_code": 528009,
    "dane_code": 52427000,
    "name_depto": "NARI√ëO",
    "name_city": "MAG√úI"
  },
  {
    "id": 2498,
    "zip_code": 528017,
    "dane_code": 52427000,
    "name_depto": "NARI√ëO",
    "name_city": "MAG√úI"
  },
  {
    "id": 2499,
    "zip_code": 528030,
    "dane_code": 52621000,
    "name_depto": "NARI√ëO",
    "name_city": "ROBERTO PAYAN"
  },
  {
    "id": 2500,
    "zip_code": 528037,
    "dane_code": 52621000,
    "name_depto": "NARI√ëO",
    "name_city": "ROBERTO PAYAN"
  },
  {
    "id": 2501,
    "zip_code": 528038,
    "dane_code": 52621000,
    "name_depto": "NARI√ëO",
    "name_city": "ROBERTO PAYAN"
  },
  {
    "id": 2502,
    "zip_code": 528039,
    "dane_code": 52621000,
    "name_depto": "NARI√ëO",
    "name_city": "ROBERTO PAYAN"
  },
  {
    "id": 2503,
    "zip_code": 528047,
    "dane_code": 52621000,
    "name_depto": "NARI√ëO",
    "name_city": "ROBERTO PAYAN"
  },
  {
    "id": 2504,
    "zip_code": 528060,
    "dane_code": 52079000,
    "name_depto": "NARI√ëO",
    "name_city": "BARBACOAS"
  },
  {
    "id": 2505,
    "zip_code": 528067,
    "dane_code": 52079000,
    "name_depto": "NARI√ëO",
    "name_city": "BARBACOAS"
  },
  {
    "id": 2506,
    "zip_code": 528068,
    "dane_code": 52079000,
    "name_depto": "NARI√ëO",
    "name_city": "BARBACOAS"
  },
  {
    "id": 2507,
    "zip_code": 528069,
    "dane_code": 52079000,
    "name_depto": "NARI√ëO",
    "name_city": "BARBACOAS"
  },
  {
    "id": 2508,
    "zip_code": 528077,
    "dane_code": 52079000,
    "name_depto": "NARI√ëO",
    "name_city": "BARBACOAS"
  },
  {
    "id": 2509,
    "zip_code": 528078,
    "dane_code": 52079000,
    "name_depto": "NARI√ëO",
    "name_city": "BARBACOAS"
  },
  {
    "id": 2510,
    "zip_code": 528501,
    "dane_code": 52835000,
    "name_depto": "NARI√ëO",
    "name_city": "SAN ANDRES DE TUMACO"
  },
  {
    "id": 2511,
    "zip_code": 528502,
    "dane_code": 52835000,
    "name_depto": "NARI√ëO",
    "name_city": "SAN ANDRES DE TUMACO"
  },
  {
    "id": 2512,
    "zip_code": 528503,
    "dane_code": 52835000,
    "name_depto": "NARI√ëO",
    "name_city": "SAN ANDRES DE TUMACO"
  },
  {
    "id": 2513,
    "zip_code": 528507,
    "dane_code": 52835000,
    "name_depto": "NARI√ëO",
    "name_city": "SAN ANDRES DE TUMACO"
  },
  {
    "id": 2514,
    "zip_code": 528508,
    "dane_code": 52835000,
    "name_depto": "NARI√ëO",
    "name_city": "SAN ANDRES DE TUMACO"
  },
  {
    "id": 2515,
    "zip_code": 528509,
    "dane_code": 52835000,
    "name_depto": "NARI√ëO",
    "name_city": "SAN ANDRES DE TUMACO"
  },
  {
    "id": 2516,
    "zip_code": 528517,
    "dane_code": 52835000,
    "name_depto": "NARI√ëO",
    "name_city": "SAN ANDRES DE TUMACO"
  },
  {
    "id": 2517,
    "zip_code": 528518,
    "dane_code": 52835000,
    "name_depto": "NARI√ëO",
    "name_city": "SAN ANDRES DE TUMACO"
  },
  {
    "id": 2518,
    "zip_code": 528519,
    "dane_code": 52835000,
    "name_depto": "NARI√ëO",
    "name_city": "SAN ANDRES DE TUMACO"
  },
  {
    "id": 2519,
    "zip_code": 528527,
    "dane_code": 52835000,
    "name_depto": "NARI√ëO",
    "name_city": "SAN ANDRES DE TUMACO"
  },
  {
    "id": 2520,
    "zip_code": 528528,
    "dane_code": 52835000,
    "name_depto": "NARI√ëO",
    "name_city": "SAN ANDRES DE TUMACO"
  },
  {
    "id": 2521,
    "zip_code": 528529,
    "dane_code": 52835000,
    "name_depto": "NARI√ëO",
    "name_city": "SAN ANDRES DE TUMACO"
  },
  {
    "id": 2522,
    "zip_code": 528537,
    "dane_code": 52835000,
    "name_depto": "NARI√ëO",
    "name_city": "SAN ANDRES DE TUMACO"
  },
  {
    "id": 2523,
    "zip_code": 528538,
    "dane_code": 52835000,
    "name_depto": "NARI√ëO",
    "name_city": "SAN ANDRES DE TUMACO"
  },
  {
    "id": 2524,
    "zip_code": 528539,
    "dane_code": 52835000,
    "name_depto": "NARI√ëO",
    "name_city": "SAN ANDRES DE TUMACO"
  },
  {
    "id": 2525,
    "zip_code": 528560,
    "dane_code": 52520000,
    "name_depto": "NARI√ëO",
    "name_city": "FRANCISCO PIZARRO"
  },
  {
    "id": 2526,
    "zip_code": 528567,
    "dane_code": 52520000,
    "name_depto": "NARI√ëO",
    "name_city": "FRANCISCO PIZARRO"
  },
  {
    "id": 2527,
    "zip_code": 528568,
    "dane_code": 52520000,
    "name_depto": "NARI√ëO",
    "name_city": "FRANCISCO PIZARRO"
  },
  {
    "id": 2528,
    "zip_code": 540001,
    "dane_code": 54001000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "CUCUTA"
  },
  {
    "id": 2529,
    "zip_code": 540002,
    "dane_code": 54001000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "CUCUTA"
  },
  {
    "id": 2530,
    "zip_code": 540003,
    "dane_code": 54001000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "CUCUTA"
  },
  {
    "id": 2531,
    "zip_code": 540004,
    "dane_code": 54001000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "CUCUTA"
  },
  {
    "id": 2532,
    "zip_code": 540005,
    "dane_code": 54001000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "CUCUTA"
  },
  {
    "id": 2533,
    "zip_code": 540006,
    "dane_code": 54001000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "CUCUTA"
  },
  {
    "id": 2534,
    "zip_code": 540007,
    "dane_code": 54001000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "CUCUTA"
  },
  {
    "id": 2535,
    "zip_code": 540008,
    "dane_code": 54001000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "CUCUTA"
  },
  {
    "id": 2536,
    "zip_code": 540010,
    "dane_code": 54001000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "CUCUTA"
  },
  {
    "id": 2537,
    "zip_code": 540011,
    "dane_code": 54001000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "CUCUTA"
  },
  {
    "id": 2538,
    "zip_code": 540013,
    "dane_code": 54001000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "CUCUTA"
  },
  {
    "id": 2539,
    "zip_code": 540017,
    "dane_code": 54001000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "CUCUTA"
  },
  {
    "id": 2540,
    "zip_code": 540018,
    "dane_code": 54001000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "CUCUTA"
  },
  {
    "id": 2541,
    "zip_code": 540019,
    "dane_code": 54001000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "CUCUTA"
  },
  {
    "id": 2542,
    "zip_code": 541010,
    "dane_code": 54405000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "LOS PATIOS"
  },
  {
    "id": 2543,
    "zip_code": 541017,
    "dane_code": 54405000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "LOS PATIOS"
  },
  {
    "id": 2544,
    "zip_code": 541018,
    "dane_code": 54405000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "LOS PATIOS"
  },
  {
    "id": 2545,
    "zip_code": 541030,
    "dane_code": 54874000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "VILLA DEL ROSARIO"
  },
  {
    "id": 2546,
    "zip_code": 541038,
    "dane_code": 54874000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "VILLA DEL ROSARIO"
  },
  {
    "id": 2547,
    "zip_code": 541050,
    "dane_code": 54599000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "RAGONVALIA"
  },
  {
    "id": 2548,
    "zip_code": 541057,
    "dane_code": 54599000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "RAGONVALIA"
  },
  {
    "id": 2549,
    "zip_code": 541070,
    "dane_code": 54172000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "CHINACOTA"
  },
  {
    "id": 2550,
    "zip_code": 541077,
    "dane_code": 54172000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "CHINACOTA"
  },
  {
    "id": 2551,
    "zip_code": 542010,
    "dane_code": 54347000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "HERRAN"
  },
  {
    "id": 2552,
    "zip_code": 542017,
    "dane_code": 54347000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "HERRAN"
  },
  {
    "id": 2553,
    "zip_code": 542018,
    "dane_code": 54347000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "HERRAN"
  },
  {
    "id": 2554,
    "zip_code": 542030,
    "dane_code": 54820000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "TOLEDO"
  },
  {
    "id": 2555,
    "zip_code": 542037,
    "dane_code": 54820000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "TOLEDO"
  },
  {
    "id": 2556,
    "zip_code": 542038,
    "dane_code": 54820000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "TOLEDO"
  },
  {
    "id": 2557,
    "zip_code": 542050,
    "dane_code": 54377000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "LABATECA"
  },
  {
    "id": 2558,
    "zip_code": 542057,
    "dane_code": 54377000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "LABATECA"
  },
  {
    "id": 2559,
    "zip_code": 542058,
    "dane_code": 54377000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "LABATECA"
  },
  {
    "id": 2560,
    "zip_code": 543010,
    "dane_code": 54099000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "BOCHALEMA"
  },
  {
    "id": 2561,
    "zip_code": 543017,
    "dane_code": 54099000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "BOCHALEMA"
  },
  {
    "id": 2562,
    "zip_code": 543018,
    "dane_code": 54099000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "BOCHALEMA"
  },
  {
    "id": 2563,
    "zip_code": 543030,
    "dane_code": 54520000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "PAMPLONITA"
  },
  {
    "id": 2564,
    "zip_code": 543037,
    "dane_code": 54520000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "PAMPLONITA"
  },
  {
    "id": 2565,
    "zip_code": 543038,
    "dane_code": 54520000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "PAMPLONITA"
  },
  {
    "id": 2566,
    "zip_code": 543050,
    "dane_code": 54518000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "PAMPLONA"
  },
  {
    "id": 2567,
    "zip_code": 543057,
    "dane_code": 54518000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "PAMPLONA"
  },
  {
    "id": 2568,
    "zip_code": 543058,
    "dane_code": 54518000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "PAMPLONA"
  },
  {
    "id": 2569,
    "zip_code": 544010,
    "dane_code": 54125000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "CACOTA"
  },
  {
    "id": 2570,
    "zip_code": 544017,
    "dane_code": 54125000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "CACOTA"
  },
  {
    "id": 2571,
    "zip_code": 544030,
    "dane_code": 54174000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "CHITAGA"
  },
  {
    "id": 2572,
    "zip_code": 544037,
    "dane_code": 54174000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "CHITAGA"
  },
  {
    "id": 2573,
    "zip_code": 544038,
    "dane_code": 54174000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "CHITAGA"
  },
  {
    "id": 2574,
    "zip_code": 544050,
    "dane_code": 54743000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "SILOS"
  },
  {
    "id": 2575,
    "zip_code": 544057,
    "dane_code": 54743000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "SILOS"
  },
  {
    "id": 2576,
    "zip_code": 544058,
    "dane_code": 54743000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "SILOS"
  },
  {
    "id": 2577,
    "zip_code": 544070,
    "dane_code": 54480000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "MUTISCUA"
  },
  {
    "id": 2578,
    "zip_code": 544077,
    "dane_code": 54480000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "MUTISCUA"
  },
  {
    "id": 2579,
    "zip_code": 544510,
    "dane_code": 54239000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "DURANIA"
  },
  {
    "id": 2580,
    "zip_code": 544517,
    "dane_code": 54239000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "DURANIA"
  },
  {
    "id": 2581,
    "zip_code": 544520,
    "dane_code": 54223000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "CUCUTILLA"
  },
  {
    "id": 2582,
    "zip_code": 544527,
    "dane_code": 54223000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "CUCUTILLA"
  },
  {
    "id": 2583,
    "zip_code": 544528,
    "dane_code": 54223000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "CUCUTILLA"
  },
  {
    "id": 2584,
    "zip_code": 544529,
    "dane_code": 54223000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "CUCUTILLA"
  },
  {
    "id": 2585,
    "zip_code": 544550,
    "dane_code": 54051000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "ARBOLEDAS"
  },
  {
    "id": 2586,
    "zip_code": 544557,
    "dane_code": 54051000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "ARBOLEDAS"
  },
  {
    "id": 2587,
    "zip_code": 544558,
    "dane_code": 54051000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "ARBOLEDAS"
  },
  {
    "id": 2588,
    "zip_code": 544570,
    "dane_code": 54660000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "SALAZAR"
  },
  {
    "id": 2589,
    "zip_code": 544577,
    "dane_code": 54660000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "SALAZAR"
  },
  {
    "id": 2590,
    "zip_code": 544578,
    "dane_code": 54660000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "SALAZAR"
  },
  {
    "id": 2591,
    "zip_code": 545010,
    "dane_code": 54673000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "SAN CAYETANO"
  },
  {
    "id": 2592,
    "zip_code": 545017,
    "dane_code": 54673000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "SAN CAYETANO"
  },
  {
    "id": 2593,
    "zip_code": 545030,
    "dane_code": 54680000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "SANTIAGO"
  },
  {
    "id": 2594,
    "zip_code": 545037,
    "dane_code": 54680000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "SANTIAGO"
  },
  {
    "id": 2595,
    "zip_code": 545050,
    "dane_code": 54313000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "GRAMALOTE"
  },
  {
    "id": 2596,
    "zip_code": 545057,
    "dane_code": 54313000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "GRAMALOTE"
  },
  {
    "id": 2597,
    "zip_code": 545070,
    "dane_code": 54418000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "LOURDES"
  },
  {
    "id": 2598,
    "zip_code": 545077,
    "dane_code": 54418000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "LOURDES"
  },
  {
    "id": 2599,
    "zip_code": 545510,
    "dane_code": 54261000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "EL ZULIA"
  },
  {
    "id": 2600,
    "zip_code": 545517,
    "dane_code": 54261000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "EL ZULIA"
  },
  {
    "id": 2601,
    "zip_code": 545518,
    "dane_code": 54261000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "EL ZULIA"
  },
  {
    "id": 2602,
    "zip_code": 545530,
    "dane_code": 54720000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "SARDINATA"
  },
  {
    "id": 2603,
    "zip_code": 545537,
    "dane_code": 54720000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "SARDINATA"
  },
  {
    "id": 2604,
    "zip_code": 545538,
    "dane_code": 54720000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "SARDINATA"
  },
  {
    "id": 2605,
    "zip_code": 545539,
    "dane_code": 54720000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "SARDINATA"
  },
  {
    "id": 2606,
    "zip_code": 545550,
    "dane_code": 54109000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "BUCARASICA"
  },
  {
    "id": 2607,
    "zip_code": 545557,
    "dane_code": 54109000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "BUCARASICA"
  },
  {
    "id": 2608,
    "zip_code": 545558,
    "dane_code": 54109000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "BUCARASICA"
  },
  {
    "id": 2609,
    "zip_code": 546010,
    "dane_code": 54871000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "VILLA CARO"
  },
  {
    "id": 2610,
    "zip_code": 546017,
    "dane_code": 54871000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "VILLA CARO"
  },
  {
    "id": 2611,
    "zip_code": 546030,
    "dane_code": 54128000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "CACHIRA"
  },
  {
    "id": 2612,
    "zip_code": 546037,
    "dane_code": 54128000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "CACHIRA"
  },
  {
    "id": 2613,
    "zip_code": 546038,
    "dane_code": 54128000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "CACHIRA"
  },
  {
    "id": 2614,
    "zip_code": 546050,
    "dane_code": 54385000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "LA ESPERANZA"
  },
  {
    "id": 2615,
    "zip_code": 546057,
    "dane_code": 54385000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "LA ESPERANZA"
  },
  {
    "id": 2616,
    "zip_code": 546058,
    "dane_code": 54385000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "LA ESPERANZA"
  },
  {
    "id": 2617,
    "zip_code": 546070,
    "dane_code": 54003000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "ABREGO"
  },
  {
    "id": 2618,
    "zip_code": 546077,
    "dane_code": 54003000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "ABREGO"
  },
  {
    "id": 2619,
    "zip_code": 546078,
    "dane_code": 54003000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "ABREGO"
  },
  {
    "id": 2620,
    "zip_code": 546079,
    "dane_code": 54003000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "ABREGO"
  },
  {
    "id": 2621,
    "zip_code": 546510,
    "dane_code": 54344000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "HACARI"
  },
  {
    "id": 2622,
    "zip_code": 546517,
    "dane_code": 54344000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "HACARI"
  },
  {
    "id": 2623,
    "zip_code": 546530,
    "dane_code": 54398000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "LA PLAYA"
  },
  {
    "id": 2624,
    "zip_code": 546537,
    "dane_code": 54398000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "LA PLAYA"
  },
  {
    "id": 2625,
    "zip_code": 546551,
    "dane_code": 54498000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "OCA√ëA"
  },
  {
    "id": 2626,
    "zip_code": 546552,
    "dane_code": 54498000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "OCA√ëA"
  },
  {
    "id": 2627,
    "zip_code": 546557,
    "dane_code": 54498000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "OCA√ëA"
  },
  {
    "id": 2628,
    "zip_code": 546558,
    "dane_code": 54498000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "OCA√ëA"
  },
  {
    "id": 2629,
    "zip_code": 546559,
    "dane_code": 54498000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "OCA√ëA"
  },
  {
    "id": 2630,
    "zip_code": 547010,
    "dane_code": 54670000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "SAN CALIXTO"
  },
  {
    "id": 2631,
    "zip_code": 547017,
    "dane_code": 54670000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "SAN CALIXTO"
  },
  {
    "id": 2632,
    "zip_code": 547018,
    "dane_code": 54670000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "SAN CALIXTO"
  },
  {
    "id": 2633,
    "zip_code": 547030,
    "dane_code": 54800000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "TEORAMA"
  },
  {
    "id": 2634,
    "zip_code": 547037,
    "dane_code": 54800000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "TEORAMA"
  },
  {
    "id": 2635,
    "zip_code": 547038,
    "dane_code": 54800000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "TEORAMA"
  },
  {
    "id": 2636,
    "zip_code": 547039,
    "dane_code": 54800000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "TEORAMA"
  },
  {
    "id": 2637,
    "zip_code": 547050,
    "dane_code": 54206000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "CONVENCION"
  },
  {
    "id": 2638,
    "zip_code": 547057,
    "dane_code": 54206000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "CONVENCION"
  },
  {
    "id": 2639,
    "zip_code": 547058,
    "dane_code": 54206000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "CONVENCION"
  },
  {
    "id": 2640,
    "zip_code": 547059,
    "dane_code": 54206000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "CONVENCION"
  },
  {
    "id": 2641,
    "zip_code": 547070,
    "dane_code": 54245000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "EL CARMEN"
  },
  {
    "id": 2642,
    "zip_code": 547077,
    "dane_code": 54245000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "EL CARMEN"
  },
  {
    "id": 2643,
    "zip_code": 547078,
    "dane_code": 54245000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "EL CARMEN"
  },
  {
    "id": 2644,
    "zip_code": 547079,
    "dane_code": 54245000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "EL CARMEN"
  },
  {
    "id": 2645,
    "zip_code": 548010,
    "dane_code": 54810000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "TIBU"
  },
  {
    "id": 2646,
    "zip_code": 548017,
    "dane_code": 54810000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "TIBU"
  },
  {
    "id": 2647,
    "zip_code": 548018,
    "dane_code": 54810000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "TIBU"
  },
  {
    "id": 2648,
    "zip_code": 548030,
    "dane_code": 54553000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "PUERTO SANTANDER"
  },
  {
    "id": 2649,
    "zip_code": 548037,
    "dane_code": 54553000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "PUERTO SANTANDER"
  },
  {
    "id": 2650,
    "zip_code": 548050,
    "dane_code": 54250000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "EL TARRA"
  },
  {
    "id": 2651,
    "zip_code": 548057,
    "dane_code": 54250000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "EL TARRA"
  },
  {
    "id": 2652,
    "zip_code": 548058,
    "dane_code": 54250000,
    "name_depto": "NORTE DE SANTANDER",
    "name_city": "EL TARRA"
  },
  {
    "id": 2653,
    "zip_code": 630001,
    "dane_code": 63001000,
    "name_depto": "QUINDIO",
    "name_city": "ARMENIA"
  },
  {
    "id": 2654,
    "zip_code": 630002,
    "dane_code": 63001000,
    "name_depto": "QUINDIO",
    "name_city": "ARMENIA"
  },
  {
    "id": 2655,
    "zip_code": 630003,
    "dane_code": 63001000,
    "name_depto": "QUINDIO",
    "name_city": "ARMENIA"
  },
  {
    "id": 2656,
    "zip_code": 630004,
    "dane_code": 63001000,
    "name_depto": "QUINDIO",
    "name_city": "ARMENIA"
  },
  {
    "id": 2657,
    "zip_code": 630007,
    "dane_code": 63001000,
    "name_depto": "QUINDIO",
    "name_city": "ARMENIA"
  },
  {
    "id": 2658,
    "zip_code": 630008,
    "dane_code": 63001000,
    "name_depto": "QUINDIO",
    "name_city": "ARMENIA"
  },
  {
    "id": 2659,
    "zip_code": 631001,
    "dane_code": 63190000,
    "name_depto": "QUINDIO",
    "name_city": "CIRCASIA"
  },
  {
    "id": 2660,
    "zip_code": 631007,
    "dane_code": 63190000,
    "name_depto": "QUINDIO",
    "name_city": "CIRCASIA"
  },
  {
    "id": 2661,
    "zip_code": 631008,
    "dane_code": 63190000,
    "name_depto": "QUINDIO",
    "name_city": "CIRCASIA"
  },
  {
    "id": 2662,
    "zip_code": 631020,
    "dane_code": 63690000,
    "name_depto": "QUINDIO",
    "name_city": "SALENTO"
  },
  {
    "id": 2663,
    "zip_code": 631027,
    "dane_code": 63690000,
    "name_depto": "QUINDIO",
    "name_city": "SALENTO"
  },
  {
    "id": 2664,
    "zip_code": 631028,
    "dane_code": 63690000,
    "name_depto": "QUINDIO",
    "name_city": "SALENTO"
  },
  {
    "id": 2665,
    "zip_code": 632001,
    "dane_code": 63130000,
    "name_depto": "QUINDIO",
    "name_city": "CALARCA"
  },
  {
    "id": 2666,
    "zip_code": 632007,
    "dane_code": 63130000,
    "name_depto": "QUINDIO",
    "name_city": "CALARCA"
  },
  {
    "id": 2667,
    "zip_code": 632008,
    "dane_code": 63130000,
    "name_depto": "QUINDIO",
    "name_city": "CALARCA"
  },
  {
    "id": 2668,
    "zip_code": 632020,
    "dane_code": 63212000,
    "name_depto": "QUINDIO",
    "name_city": "CORDOBA"
  },
  {
    "id": 2669,
    "zip_code": 632027,
    "dane_code": 63212000,
    "name_depto": "QUINDIO",
    "name_city": "CORDOBA"
  },
  {
    "id": 2670,
    "zip_code": 632040,
    "dane_code": 63111000,
    "name_depto": "QUINDIO",
    "name_city": "BUENAVISTA"
  },
  {
    "id": 2671,
    "zip_code": 632047,
    "dane_code": 63111000,
    "name_depto": "QUINDIO",
    "name_city": "BUENAVISTA"
  },
  {
    "id": 2672,
    "zip_code": 632060,
    "dane_code": 63548000,
    "name_depto": "QUINDIO",
    "name_city": "PIJAO"
  },
  {
    "id": 2673,
    "zip_code": 632067,
    "dane_code": 63548000,
    "name_depto": "QUINDIO",
    "name_city": "PIJAO"
  },
  {
    "id": 2674,
    "zip_code": 632080,
    "dane_code": 63302000,
    "name_depto": "QUINDIO",
    "name_city": "GENOVA"
  },
  {
    "id": 2675,
    "zip_code": 632087,
    "dane_code": 63302000,
    "name_depto": "QUINDIO",
    "name_city": "GENOVA"
  },
  {
    "id": 2676,
    "zip_code": 633001,
    "dane_code": 63470000,
    "name_depto": "QUINDIO",
    "name_city": "MONTENEGRO"
  },
  {
    "id": 2677,
    "zip_code": 633007,
    "dane_code": 63470000,
    "name_depto": "QUINDIO",
    "name_city": "MONTENEGRO"
  },
  {
    "id": 2678,
    "zip_code": 633008,
    "dane_code": 63470000,
    "name_depto": "QUINDIO",
    "name_city": "MONTENEGRO"
  },
  {
    "id": 2679,
    "zip_code": 633020,
    "dane_code": 63401000,
    "name_depto": "QUINDIO",
    "name_city": "LA TEBAIDA"
  },
  {
    "id": 2680,
    "zip_code": 633027,
    "dane_code": 63401000,
    "name_depto": "QUINDIO",
    "name_city": "LA TEBAIDA"
  },
  {
    "id": 2681,
    "zip_code": 634001,
    "dane_code": 63272000,
    "name_depto": "QUINDIO",
    "name_city": "FILANDIA"
  },
  {
    "id": 2682,
    "zip_code": 634007,
    "dane_code": 63272000,
    "name_depto": "QUINDIO",
    "name_city": "FILANDIA"
  },
  {
    "id": 2683,
    "zip_code": 634008,
    "dane_code": 63272000,
    "name_depto": "QUINDIO",
    "name_city": "FILANDIA"
  },
  {
    "id": 2684,
    "zip_code": 634020,
    "dane_code": 63594000,
    "name_depto": "QUINDIO",
    "name_city": "QUIMBAYA"
  },
  {
    "id": 2685,
    "zip_code": 634027,
    "dane_code": 63594000,
    "name_depto": "QUINDIO",
    "name_city": "QUIMBAYA"
  },
  {
    "id": 2686,
    "zip_code": 660001,
    "dane_code": 66001000,
    "name_depto": "RISARALDA",
    "name_city": "PEREIRA"
  },
  {
    "id": 2687,
    "zip_code": 660002,
    "dane_code": 66001000,
    "name_depto": "RISARALDA",
    "name_city": "PEREIRA"
  },
  {
    "id": 2688,
    "zip_code": 660003,
    "dane_code": 66001000,
    "name_depto": "RISARALDA",
    "name_city": "PEREIRA"
  },
  {
    "id": 2689,
    "zip_code": 660004,
    "dane_code": 66001000,
    "name_depto": "RISARALDA",
    "name_city": "PEREIRA"
  },
  {
    "id": 2690,
    "zip_code": 660005,
    "dane_code": 66001000,
    "name_depto": "RISARALDA",
    "name_city": "PEREIRA"
  },
  {
    "id": 2691,
    "zip_code": 660006,
    "dane_code": 66001000,
    "name_depto": "RISARALDA",
    "name_city": "PEREIRA"
  },
  {
    "id": 2692,
    "zip_code": 660007,
    "dane_code": 66001000,
    "name_depto": "RISARALDA",
    "name_city": "PEREIRA"
  },
  {
    "id": 2693,
    "zip_code": 660008,
    "dane_code": 66001000,
    "name_depto": "RISARALDA",
    "name_city": "PEREIRA"
  },
  {
    "id": 2694,
    "zip_code": 660009,
    "dane_code": 66001000,
    "name_depto": "RISARALDA",
    "name_city": "PEREIRA"
  },
  {
    "id": 2695,
    "zip_code": 660017,
    "dane_code": 66001000,
    "name_depto": "RISARALDA",
    "name_city": "PEREIRA"
  },
  {
    "id": 2696,
    "zip_code": 661001,
    "dane_code": 66170000,
    "name_depto": "RISARALDA",
    "name_city": "DOSQUEBRADAS"
  },
  {
    "id": 2697,
    "zip_code": 661002,
    "dane_code": 66170000,
    "name_depto": "RISARALDA",
    "name_city": "DOSQUEBRADAS"
  },
  {
    "id": 2698,
    "zip_code": 661007,
    "dane_code": 66170000,
    "name_depto": "RISARALDA",
    "name_city": "DOSQUEBRADAS"
  },
  {
    "id": 2699,
    "zip_code": 661008,
    "dane_code": 66170000,
    "name_depto": "RISARALDA",
    "name_city": "DOSQUEBRADAS"
  },
  {
    "id": 2700,
    "zip_code": 661020,
    "dane_code": 66682000,
    "name_depto": "RISARALDA",
    "name_city": "SANTA ROSA DE CABAL"
  },
  {
    "id": 2701,
    "zip_code": 661027,
    "dane_code": 66682000,
    "name_depto": "RISARALDA",
    "name_city": "SANTA ROSA DE CABAL"
  },
  {
    "id": 2702,
    "zip_code": 661028,
    "dane_code": 66682000,
    "name_depto": "RISARALDA",
    "name_city": "SANTA ROSA DE CABAL"
  },
  {
    "id": 2703,
    "zip_code": 661040,
    "dane_code": 66440000,
    "name_depto": "RISARALDA",
    "name_city": "MARSELLA"
  },
  {
    "id": 2704,
    "zip_code": 661047,
    "dane_code": 66440000,
    "name_depto": "RISARALDA",
    "name_city": "MARSELLA"
  },
  {
    "id": 2705,
    "zip_code": 661048,
    "dane_code": 66440000,
    "name_depto": "RISARALDA",
    "name_city": "MARSELLA"
  },
  {
    "id": 2706,
    "zip_code": 662001,
    "dane_code": 66400000,
    "name_depto": "RISARALDA",
    "name_city": "LA VIRGINIA"
  },
  {
    "id": 2707,
    "zip_code": 662007,
    "dane_code": 66400000,
    "name_depto": "RISARALDA",
    "name_city": "LA VIRGINIA"
  },
  {
    "id": 2708,
    "zip_code": 662010,
    "dane_code": 66075000,
    "name_depto": "RISARALDA",
    "name_city": "BALBOA"
  },
  {
    "id": 2709,
    "zip_code": 662017,
    "dane_code": 66075000,
    "name_depto": "RISARALDA",
    "name_city": "BALBOA"
  },
  {
    "id": 2710,
    "zip_code": 662030,
    "dane_code": 66383000,
    "name_depto": "RISARALDA",
    "name_city": "LA CELIA"
  },
  {
    "id": 2711,
    "zip_code": 662037,
    "dane_code": 66383000,
    "name_depto": "RISARALDA",
    "name_city": "LA CELIA"
  },
  {
    "id": 2712,
    "zip_code": 663001,
    "dane_code": 66687000,
    "name_depto": "RISARALDA",
    "name_city": "SANTUARIO"
  },
  {
    "id": 2713,
    "zip_code": 663007,
    "dane_code": 66687000,
    "name_depto": "RISARALDA",
    "name_city": "SANTUARIO"
  },
  {
    "id": 2714,
    "zip_code": 663008,
    "dane_code": 66687000,
    "name_depto": "RISARALDA",
    "name_city": "SANTUARIO"
  },
  {
    "id": 2715,
    "zip_code": 663011,
    "dane_code": 66572000,
    "name_depto": "RISARALDA",
    "name_city": "PUEBLO RICO"
  },
  {
    "id": 2716,
    "zip_code": 663017,
    "dane_code": 66572000,
    "name_depto": "RISARALDA",
    "name_city": "PUEBLO RICO"
  },
  {
    "id": 2717,
    "zip_code": 663018,
    "dane_code": 66572000,
    "name_depto": "RISARALDA",
    "name_city": "PUEBLO RICO"
  },
  {
    "id": 2718,
    "zip_code": 663030,
    "dane_code": 66045000,
    "name_depto": "RISARALDA",
    "name_city": "APIA"
  },
  {
    "id": 2719,
    "zip_code": 663037,
    "dane_code": 66045000,
    "name_depto": "RISARALDA",
    "name_city": "APIA"
  },
  {
    "id": 2720,
    "zip_code": 663038,
    "dane_code": 66045000,
    "name_depto": "RISARALDA",
    "name_city": "APIA"
  },
  {
    "id": 2721,
    "zip_code": 664001,
    "dane_code": 66594000,
    "name_depto": "RISARALDA",
    "name_city": "QUINCHIA"
  },
  {
    "id": 2722,
    "zip_code": 664007,
    "dane_code": 66594000,
    "name_depto": "RISARALDA",
    "name_city": "QUINCHIA"
  },
  {
    "id": 2723,
    "zip_code": 664008,
    "dane_code": 66594000,
    "name_depto": "RISARALDA",
    "name_city": "QUINCHIA"
  },
  {
    "id": 2724,
    "zip_code": 664010,
    "dane_code": 66318000,
    "name_depto": "RISARALDA",
    "name_city": "GUATICA"
  },
  {
    "id": 2725,
    "zip_code": 664017,
    "dane_code": 66318000,
    "name_depto": "RISARALDA",
    "name_city": "GUATICA"
  },
  {
    "id": 2726,
    "zip_code": 664018,
    "dane_code": 66318000,
    "name_depto": "RISARALDA",
    "name_city": "GUATICA"
  },
  {
    "id": 2727,
    "zip_code": 664020,
    "dane_code": 66456000,
    "name_depto": "RISARALDA",
    "name_city": "MISTRATO"
  },
  {
    "id": 2728,
    "zip_code": 664027,
    "dane_code": 66456000,
    "name_depto": "RISARALDA",
    "name_city": "MISTRATO"
  },
  {
    "id": 2729,
    "zip_code": 664028,
    "dane_code": 66456000,
    "name_depto": "RISARALDA",
    "name_city": "MISTRATO"
  },
  {
    "id": 2730,
    "zip_code": 664040,
    "dane_code": 66088000,
    "name_depto": "RISARALDA",
    "name_city": "BELEN DE UMBRIA"
  },
  {
    "id": 2731,
    "zip_code": 664047,
    "dane_code": 66088000,
    "name_depto": "RISARALDA",
    "name_city": "BELEN DE UMBRIA"
  },
  {
    "id": 2732,
    "zip_code": 664048,
    "dane_code": 66088000,
    "name_depto": "RISARALDA",
    "name_city": "BELEN DE UMBRIA"
  },
  {
    "id": 2733,
    "zip_code": 680001,
    "dane_code": 68001000,
    "name_depto": "SANTANDER",
    "name_city": "BUCARAMANGA"
  },
  {
    "id": 2734,
    "zip_code": 680002,
    "dane_code": 68001000,
    "name_depto": "SANTANDER",
    "name_city": "BUCARAMANGA"
  },
  {
    "id": 2735,
    "zip_code": 680003,
    "dane_code": 68001000,
    "name_depto": "SANTANDER",
    "name_city": "BUCARAMANGA"
  },
  {
    "id": 2736,
    "zip_code": 680004,
    "dane_code": 68001000,
    "name_depto": "SANTANDER",
    "name_city": "BUCARAMANGA"
  },
  {
    "id": 2737,
    "zip_code": 680005,
    "dane_code": 68001000,
    "name_depto": "SANTANDER",
    "name_city": "BUCARAMANGA"
  },
  {
    "id": 2738,
    "zip_code": 680006,
    "dane_code": 68001000,
    "name_depto": "SANTANDER",
    "name_city": "BUCARAMANGA"
  },
  {
    "id": 2739,
    "zip_code": 680007,
    "dane_code": 68001000,
    "name_depto": "SANTANDER",
    "name_city": "BUCARAMANGA"
  },
  {
    "id": 2740,
    "zip_code": 680008,
    "dane_code": 68001000,
    "name_depto": "SANTANDER",
    "name_city": "BUCARAMANGA"
  },
  {
    "id": 2741,
    "zip_code": 680011,
    "dane_code": 68001000,
    "name_depto": "SANTANDER",
    "name_city": "BUCARAMANGA"
  },
  {
    "id": 2742,
    "zip_code": 680501,
    "dane_code": 68780000,
    "name_depto": "SANTANDER",
    "name_city": "SURATA"
  },
  {
    "id": 2743,
    "zip_code": 680507,
    "dane_code": 68780000,
    "name_depto": "SANTANDER",
    "name_city": "SURATA"
  },
  {
    "id": 2744,
    "zip_code": 680511,
    "dane_code": 68132000,
    "name_depto": "SANTANDER",
    "name_city": "CALIFORNIA"
  },
  {
    "id": 2745,
    "zip_code": 680517,
    "dane_code": 68132000,
    "name_depto": "SANTANDER",
    "name_city": "CALIFORNIA"
  },
  {
    "id": 2746,
    "zip_code": 680527,
    "dane_code": 68867000,
    "name_depto": "SANTANDER",
    "name_city": "VETAS"
  },
  {
    "id": 2747,
    "zip_code": 680531,
    "dane_code": 68867000,
    "name_depto": "SANTANDER",
    "name_city": "VETAS"
  },
  {
    "id": 2748,
    "zip_code": 680541,
    "dane_code": 68820000,
    "name_depto": "SANTANDER",
    "name_city": "TONA"
  },
  {
    "id": 2749,
    "zip_code": 680547,
    "dane_code": 68820000,
    "name_depto": "SANTANDER",
    "name_city": "TONA"
  },
  {
    "id": 2750,
    "zip_code": 680548,
    "dane_code": 68820000,
    "name_depto": "SANTANDER",
    "name_city": "TONA"
  },
  {
    "id": 2751,
    "zip_code": 680551,
    "dane_code": 68169000,
    "name_depto": "SANTANDER",
    "name_city": "CHARTA"
  },
  {
    "id": 2752,
    "zip_code": 680557,
    "dane_code": 68169000,
    "name_depto": "SANTANDER",
    "name_city": "CHARTA"
  },
  {
    "id": 2753,
    "zip_code": 680561,
    "dane_code": 68444000,
    "name_depto": "SANTANDER",
    "name_city": "MATANZA"
  },
  {
    "id": 2754,
    "zip_code": 680567,
    "dane_code": 68444000,
    "name_depto": "SANTANDER",
    "name_city": "MATANZA"
  },
  {
    "id": 2755,
    "zip_code": 680568,
    "dane_code": 68444000,
    "name_depto": "SANTANDER",
    "name_city": "MATANZA"
  },
  {
    "id": 2756,
    "zip_code": 681001,
    "dane_code": 68276000,
    "name_depto": "SANTANDER",
    "name_city": "FLORIDABLANCA"
  },
  {
    "id": 2757,
    "zip_code": 681002,
    "dane_code": 68276000,
    "name_depto": "SANTANDER",
    "name_city": "FLORIDABLANCA"
  },
  {
    "id": 2758,
    "zip_code": 681003,
    "dane_code": 68276000,
    "name_depto": "SANTANDER",
    "name_city": "FLORIDABLANCA"
  },
  {
    "id": 2759,
    "zip_code": 681004,
    "dane_code": 68276000,
    "name_depto": "SANTANDER",
    "name_city": "FLORIDABLANCA"
  },
  {
    "id": 2760,
    "zip_code": 681007,
    "dane_code": 68276000,
    "name_depto": "SANTANDER",
    "name_city": "FLORIDABLANCA"
  },
  {
    "id": 2761,
    "zip_code": 681008,
    "dane_code": 68276000,
    "name_depto": "SANTANDER",
    "name_city": "FLORIDABLANCA"
  },
  {
    "id": 2762,
    "zip_code": 681011,
    "dane_code": 68547000,
    "name_depto": "SANTANDER",
    "name_city": "PIEDECUESTA"
  },
  {
    "id": 2763,
    "zip_code": 681012,
    "dane_code": 68547000,
    "name_depto": "SANTANDER",
    "name_city": "PIEDECUESTA"
  },
  {
    "id": 2764,
    "zip_code": 681017,
    "dane_code": 68547000,
    "name_depto": "SANTANDER",
    "name_city": "PIEDECUESTA"
  },
  {
    "id": 2765,
    "zip_code": 681018,
    "dane_code": 68547000,
    "name_depto": "SANTANDER",
    "name_city": "PIEDECUESTA"
  },
  {
    "id": 2766,
    "zip_code": 681019,
    "dane_code": 68547000,
    "name_depto": "SANTANDER",
    "name_city": "PIEDECUESTA"
  },
  {
    "id": 2767,
    "zip_code": 681021,
    "dane_code": 68705000,
    "name_depto": "SANTANDER",
    "name_city": "SANTA BARBARA"
  },
  {
    "id": 2768,
    "zip_code": 681027,
    "dane_code": 68547000,
    "name_depto": "SANTANDER",
    "name_city": "PIEDECUESTA"
  },
  {
    "id": 2769,
    "zip_code": 681031,
    "dane_code": 68318000,
    "name_depto": "SANTANDER",
    "name_city": "GUACA"
  },
  {
    "id": 2770,
    "zip_code": 681037,
    "dane_code": 68705000,
    "name_depto": "SANTANDER",
    "name_city": "SANTA BARBARA"
  },
  {
    "id": 2771,
    "zip_code": 681038,
    "dane_code": 68705000,
    "name_depto": "SANTANDER",
    "name_city": "SANTA BARBARA"
  },
  {
    "id": 2772,
    "zip_code": 681047,
    "dane_code": 68318000,
    "name_depto": "SANTANDER",
    "name_city": "GUACA"
  },
  {
    "id": 2773,
    "zip_code": 681048,
    "dane_code": 68318000,
    "name_depto": "SANTANDER",
    "name_city": "GUACA"
  },
  {
    "id": 2774,
    "zip_code": 681501,
    "dane_code": 68162000,
    "name_depto": "SANTANDER",
    "name_city": "CERRITO"
  },
  {
    "id": 2775,
    "zip_code": 681507,
    "dane_code": 68162000,
    "name_depto": "SANTANDER",
    "name_city": "CERRITO"
  },
  {
    "id": 2776,
    "zip_code": 681508,
    "dane_code": 68162000,
    "name_depto": "SANTANDER",
    "name_city": "CERRITO"
  },
  {
    "id": 2777,
    "zip_code": 681509,
    "dane_code": 68162000,
    "name_depto": "SANTANDER",
    "name_city": "CERRITO"
  },
  {
    "id": 2778,
    "zip_code": 681511,
    "dane_code": 5206000,
    "name_depto": "SANTANDER",
    "name_city": "CONCEPCION"
  },
  {
    "id": 2779,
    "zip_code": 681517,
    "dane_code": 68207000,
    "name_depto": "SANTANDER",
    "name_city": "CONCEPCION"
  },
  {
    "id": 2780,
    "zip_code": 681518,
    "dane_code": 68207000,
    "name_depto": "SANTANDER",
    "name_city": "CONCEPCION"
  },
  {
    "id": 2781,
    "zip_code": 681519,
    "dane_code": 68207000,
    "name_depto": "SANTANDER",
    "name_city": "CONCEPCION"
  },
  {
    "id": 2782,
    "zip_code": 681521,
    "dane_code": 68152000,
    "name_depto": "SANTANDER",
    "name_city": "CARCASI"
  },
  {
    "id": 2783,
    "zip_code": 681527,
    "dane_code": 68152000,
    "name_depto": "SANTANDER",
    "name_city": "CARCASI"
  },
  {
    "id": 2784,
    "zip_code": 681528,
    "dane_code": 68152000,
    "name_depto": "SANTANDER",
    "name_city": "CARCASI"
  },
  {
    "id": 2785,
    "zip_code": 681529,
    "dane_code": 68152000,
    "name_depto": "SANTANDER",
    "name_city": "CARCASI"
  },
  {
    "id": 2786,
    "zip_code": 681531,
    "dane_code": 68425000,
    "name_depto": "SANTANDER",
    "name_city": "MACARAVITA"
  },
  {
    "id": 2787,
    "zip_code": 681537,
    "dane_code": 68425000,
    "name_depto": "SANTANDER",
    "name_city": "MACARAVITA"
  },
  {
    "id": 2788,
    "zip_code": 681538,
    "dane_code": 68425000,
    "name_depto": "SANTANDER",
    "name_city": "MACARAVITA"
  },
  {
    "id": 2789,
    "zip_code": 681541,
    "dane_code": 68147000,
    "name_depto": "SANTANDER",
    "name_city": "CAPITANEJO"
  },
  {
    "id": 2790,
    "zip_code": 681547,
    "dane_code": 68147000,
    "name_depto": "SANTANDER",
    "name_city": "CAPITANEJO"
  },
  {
    "id": 2791,
    "zip_code": 681548,
    "dane_code": 68147000,
    "name_depto": "SANTANDER",
    "name_city": "CAPITANEJO"
  },
  {
    "id": 2792,
    "zip_code": 681551,
    "dane_code": 68686000,
    "name_depto": "SANTANDER",
    "name_city": "SAN MIGUEL"
  },
  {
    "id": 2793,
    "zip_code": 681557,
    "dane_code": 68686000,
    "name_depto": "SANTANDER",
    "name_city": "SAN MIGUEL"
  },
  {
    "id": 2794,
    "zip_code": 681561,
    "dane_code": 68266000,
    "name_depto": "SANTANDER",
    "name_city": "ENCISO"
  },
  {
    "id": 2795,
    "zip_code": 681567,
    "dane_code": 68266000,
    "name_depto": "SANTANDER",
    "name_city": "ENCISO"
  },
  {
    "id": 2796,
    "zip_code": 682001,
    "dane_code": 68669000,
    "name_depto": "SANTANDER",
    "name_city": "SAN ANDRES"
  },
  {
    "id": 2797,
    "zip_code": 682007,
    "dane_code": 68669000,
    "name_depto": "SANTANDER",
    "name_city": "SAN ANDRES"
  },
  {
    "id": 2798,
    "zip_code": 682008,
    "dane_code": 68669000,
    "name_depto": "SANTANDER",
    "name_city": "SAN ANDRES"
  },
  {
    "id": 2799,
    "zip_code": 682009,
    "dane_code": 68669000,
    "name_depto": "SANTANDER",
    "name_city": "SAN ANDRES"
  },
  {
    "id": 2800,
    "zip_code": 682011,
    "dane_code": 68432000,
    "name_depto": "SANTANDER",
    "name_city": "MALAGA"
  },
  {
    "id": 2801,
    "zip_code": 682017,
    "dane_code": 68432000,
    "name_depto": "SANTANDER",
    "name_city": "MALAGA"
  },
  {
    "id": 2802,
    "zip_code": 682018,
    "dane_code": 68432000,
    "name_depto": "SANTANDER",
    "name_city": "MALAGA"
  },
  {
    "id": 2803,
    "zip_code": 682021,
    "dane_code": 68684000,
    "name_depto": "SANTANDER",
    "name_city": "SAN JOSE DE MIRANDA"
  },
  {
    "id": 2804,
    "zip_code": 682027,
    "dane_code": 68684000,
    "name_depto": "SANTANDER",
    "name_city": "SAN JOSE DE MIRANDA"
  },
  {
    "id": 2805,
    "zip_code": 682031,
    "dane_code": 68468000,
    "name_depto": "SANTANDER",
    "name_city": "MOLAGAVITA"
  },
  {
    "id": 2806,
    "zip_code": 682037,
    "dane_code": 68468000,
    "name_depto": "SANTANDER",
    "name_city": "MOLAGAVITA"
  },
  {
    "id": 2807,
    "zip_code": 682038,
    "dane_code": 68468000,
    "name_depto": "SANTANDER",
    "name_city": "MOLAGAVITA"
  },
  {
    "id": 2808,
    "zip_code": 682041,
    "dane_code": 68229000,
    "name_depto": "SANTANDER",
    "name_city": "CURITI"
  },
  {
    "id": 2809,
    "zip_code": 682047,
    "dane_code": 68229000,
    "name_depto": "SANTANDER",
    "name_city": "CURITI"
  },
  {
    "id": 2810,
    "zip_code": 682048,
    "dane_code": 68229000,
    "name_depto": "SANTANDER",
    "name_city": "CURITI"
  },
  {
    "id": 2811,
    "zip_code": 682051,
    "dane_code": 68051000,
    "name_depto": "SANTANDER",
    "name_city": "ARATOCA"
  },
  {
    "id": 2812,
    "zip_code": 682057,
    "dane_code": 68051000,
    "name_depto": "SANTANDER",
    "name_city": "ARATOCA"
  },
  {
    "id": 2813,
    "zip_code": 682058,
    "dane_code": 68051000,
    "name_depto": "SANTANDER",
    "name_city": "ARATOCA"
  },
  {
    "id": 2814,
    "zip_code": 682061,
    "dane_code": 68160000,
    "name_depto": "SANTANDER",
    "name_city": "CEPITA"
  },
  {
    "id": 2815,
    "zip_code": 682067,
    "dane_code": 68160000,
    "name_depto": "SANTANDER",
    "name_city": "CEPITA"
  },
  {
    "id": 2816,
    "zip_code": 682501,
    "dane_code": 68464000,
    "name_depto": "SANTANDER",
    "name_city": "MOGOTES"
  },
  {
    "id": 2817,
    "zip_code": 682507,
    "dane_code": 68464000,
    "name_depto": "SANTANDER",
    "name_city": "MOGOTES"
  },
  {
    "id": 2818,
    "zip_code": 682508,
    "dane_code": 68464000,
    "name_depto": "SANTANDER",
    "name_city": "MOGOTES"
  },
  {
    "id": 2819,
    "zip_code": 682509,
    "dane_code": 68464000,
    "name_depto": "SANTANDER",
    "name_city": "MOGOTES"
  },
  {
    "id": 2820,
    "zip_code": 682511,
    "dane_code": 68682000,
    "name_depto": "SANTANDER",
    "name_city": "SAN JOAQUIN"
  },
  {
    "id": 2821,
    "zip_code": 682517,
    "dane_code": 68682000,
    "name_depto": "SANTANDER",
    "name_city": "SAN JOAQUIN"
  },
  {
    "id": 2822,
    "zip_code": 682519,
    "dane_code": 68502000,
    "name_depto": "SANTANDER",
    "name_city": "ONZAGA"
  },
  {
    "id": 2823,
    "zip_code": 682521,
    "dane_code": 68502000,
    "name_depto": "SANTANDER",
    "name_city": "ONZAGA"
  },
  {
    "id": 2824,
    "zip_code": 682527,
    "dane_code": 68502000,
    "name_depto": "SANTANDER",
    "name_city": "ONZAGA"
  },
  {
    "id": 2825,
    "zip_code": 682528,
    "dane_code": 68502000,
    "name_depto": "SANTANDER",
    "name_city": "ONZAGA"
  },
  {
    "id": 2826,
    "zip_code": 682529,
    "dane_code": 68502000,
    "name_depto": "SANTANDER",
    "name_city": "ONZAGA"
  },
  {
    "id": 2827,
    "zip_code": 682531,
    "dane_code": 68217000,
    "name_depto": "SANTANDER",
    "name_city": "COROMORO"
  },
  {
    "id": 2828,
    "zip_code": 682537,
    "dane_code": 68217000,
    "name_depto": "SANTANDER",
    "name_city": "COROMORO"
  },
  {
    "id": 2829,
    "zip_code": 682538,
    "dane_code": 68217000,
    "name_depto": "SANTANDER",
    "name_city": "COROMORO"
  },
  {
    "id": 2830,
    "zip_code": 682539,
    "dane_code": 68217000,
    "name_depto": "SANTANDER",
    "name_city": "COROMORO"
  },
  {
    "id": 2831,
    "zip_code": 682541,
    "dane_code": 68264000,
    "name_depto": "SANTANDER",
    "name_city": "ENCINO"
  },
  {
    "id": 2832,
    "zip_code": 682547,
    "dane_code": 68264000,
    "name_depto": "SANTANDER",
    "name_city": "ENCINO"
  },
  {
    "id": 2833,
    "zip_code": 682548,
    "dane_code": 68264000,
    "name_depto": "SANTANDER",
    "name_city": "ENCINO"
  },
  {
    "id": 2834,
    "zip_code": 682549,
    "dane_code": 68264000,
    "name_depto": "SANTANDER",
    "name_city": "ENCINO"
  },
  {
    "id": 2835,
    "zip_code": 682551,
    "dane_code": 68167000,
    "name_depto": "SANTANDER",
    "name_city": "CHARALA"
  },
  {
    "id": 2836,
    "zip_code": 682557,
    "dane_code": 68167000,
    "name_depto": "SANTANDER",
    "name_city": "CHARALA"
  },
  {
    "id": 2837,
    "zip_code": 682558,
    "dane_code": 68167000,
    "name_depto": "SANTANDER",
    "name_city": "CHARALA"
  },
  {
    "id": 2838,
    "zip_code": 682559,
    "dane_code": 68167000,
    "name_depto": "SANTANDER",
    "name_city": "CHARALA"
  },
  {
    "id": 2839,
    "zip_code": 682561,
    "dane_code": 68498000,
    "name_depto": "SANTANDER",
    "name_city": "OCAMONTE"
  },
  {
    "id": 2840,
    "zip_code": 682567,
    "dane_code": 68498000,
    "name_depto": "SANTANDER",
    "name_city": "OCAMONTE"
  },
  {
    "id": 2841,
    "zip_code": 682568,
    "dane_code": 68498000,
    "name_depto": "SANTANDER",
    "name_city": "OCAMONTE"
  },
  {
    "id": 2842,
    "zip_code": 682571,
    "dane_code": 68855000,
    "name_depto": "SANTANDER",
    "name_city": "VALLE DE SAN JOSE"
  },
  {
    "id": 2843,
    "zip_code": 682577,
    "dane_code": 68855000,
    "name_depto": "SANTANDER",
    "name_city": "VALLE DE SAN JOSE"
  },
  {
    "id": 2844,
    "zip_code": 682578,
    "dane_code": 68855000,
    "name_depto": "SANTANDER",
    "name_city": "VALLE DE SAN JOSE"
  },
  {
    "id": 2845,
    "zip_code": 683001,
    "dane_code": 68176000,
    "name_depto": "SANTANDER",
    "name_city": "CHIMA"
  },
  {
    "id": 2846,
    "zip_code": 683007,
    "dane_code": 68176000,
    "name_depto": "SANTANDER",
    "name_city": "CHIMA"
  },
  {
    "id": 2847,
    "zip_code": 683011,
    "dane_code": 68322000,
    "name_depto": "SANTANDER",
    "name_city": "GUAPOTA"
  },
  {
    "id": 2848,
    "zip_code": 683017,
    "dane_code": 68322000,
    "name_depto": "SANTANDER",
    "name_city": "GUAPOTA"
  },
  {
    "id": 2849,
    "zip_code": 683019,
    "dane_code": 68500000,
    "name_depto": "SANTANDER",
    "name_city": "OIBA"
  },
  {
    "id": 2850,
    "zip_code": 683021,
    "dane_code": 68500000,
    "name_depto": "SANTANDER",
    "name_city": "OIBA"
  },
  {
    "id": 2851,
    "zip_code": 683027,
    "dane_code": 68500000,
    "name_depto": "SANTANDER",
    "name_city": "OIBA"
  },
  {
    "id": 2852,
    "zip_code": 683028,
    "dane_code": 68500000,
    "name_depto": "SANTANDER",
    "name_city": "OIBA"
  },
  {
    "id": 2853,
    "zip_code": 683029,
    "dane_code": 68500000,
    "name_depto": "SANTANDER",
    "name_city": "OIBA"
  },
  {
    "id": 2854,
    "zip_code": 683031,
    "dane_code": 68298000,
    "name_depto": "SANTANDER",
    "name_city": "GAMBITA"
  },
  {
    "id": 2855,
    "zip_code": 683037,
    "dane_code": 68298000,
    "name_depto": "SANTANDER",
    "name_city": "GAMBITA"
  },
  {
    "id": 2856,
    "zip_code": 683038,
    "dane_code": 68298000,
    "name_depto": "SANTANDER",
    "name_city": "GAMBITA"
  },
  {
    "id": 2857,
    "zip_code": 683039,
    "dane_code": 68298000,
    "name_depto": "SANTANDER",
    "name_city": "GAMBITA"
  },
  {
    "id": 2858,
    "zip_code": 683041,
    "dane_code": 68770000,
    "name_depto": "SANTANDER",
    "name_city": "SUAITA"
  },
  {
    "id": 2859,
    "zip_code": 683047,
    "dane_code": 68770000,
    "name_depto": "SANTANDER",
    "name_city": "SUAITA"
  },
  {
    "id": 2860,
    "zip_code": 683048,
    "dane_code": 68770000,
    "name_depto": "SANTANDER",
    "name_city": "SUAITA"
  },
  {
    "id": 2861,
    "zip_code": 683049,
    "dane_code": 68770000,
    "name_depto": "SANTANDER",
    "name_city": "SUAITA"
  },
  {
    "id": 2862,
    "zip_code": 683051,
    "dane_code": 5315000,
    "name_depto": "SANTANDER",
    "name_city": "GUADALUPE"
  },
  {
    "id": 2863,
    "zip_code": 683057,
    "dane_code": 5315000,
    "name_depto": "SANTANDER",
    "name_city": "GUADALUPE"
  },
  {
    "id": 2864,
    "zip_code": 683061,
    "dane_code": 68245000,
    "name_depto": "SANTANDER",
    "name_city": "EL GUACAMAYO"
  },
  {
    "id": 2865,
    "zip_code": 683067,
    "dane_code": 68245000,
    "name_depto": "SANTANDER",
    "name_city": "EL GUACAMAYO"
  },
  {
    "id": 2866,
    "zip_code": 683071,
    "dane_code": 68211000,
    "name_depto": "SANTANDER",
    "name_city": "CONTRATACION"
  },
  {
    "id": 2867,
    "zip_code": 683077,
    "dane_code": 68211000,
    "name_depto": "SANTANDER",
    "name_city": "CONTRATACION"
  },
  {
    "id": 2868,
    "zip_code": 683501,
    "dane_code": 68121000,
    "name_depto": "SANTANDER",
    "name_city": "CABRERA"
  },
  {
    "id": 2869,
    "zip_code": 683507,
    "dane_code": 68121000,
    "name_depto": "SANTANDER",
    "name_city": "CABRERA"
  },
  {
    "id": 2870,
    "zip_code": 683511,
    "dane_code": 68549000,
    "name_depto": "SANTANDER",
    "name_city": "PINCHOTE"
  },
  {
    "id": 2871,
    "zip_code": 683517,
    "dane_code": 68549000,
    "name_depto": "SANTANDER",
    "name_city": "PINCHOTE"
  },
  {
    "id": 2872,
    "zip_code": 683518,
    "dane_code": 68549000,
    "name_depto": "SANTANDER",
    "name_city": "PINCHOTE"
  },
  {
    "id": 2873,
    "zip_code": 683521,
    "dane_code": 68533000,
    "name_depto": "SANTANDER",
    "name_city": "PARAMO"
  },
  {
    "id": 2874,
    "zip_code": 683527,
    "dane_code": 68533000,
    "name_depto": "SANTANDER",
    "name_city": "PARAMO"
  },
  {
    "id": 2875,
    "zip_code": 683528,
    "dane_code": 68533000,
    "name_depto": "SANTANDER",
    "name_city": "PARAMO"
  },
  {
    "id": 2876,
    "zip_code": 683531,
    "dane_code": 68209000,
    "name_depto": "SANTANDER",
    "name_city": "CONFINES"
  },
  {
    "id": 2877,
    "zip_code": 683537,
    "dane_code": 68209000,
    "name_depto": "SANTANDER",
    "name_city": "CONFINES"
  },
  {
    "id": 2878,
    "zip_code": 683541,
    "dane_code": 68524000,
    "name_depto": "SANTANDER",
    "name_city": "PALMAS DEL SOCORRO"
  },
  {
    "id": 2879,
    "zip_code": 683547,
    "dane_code": 68524000,
    "name_depto": "SANTANDER",
    "name_city": "PALMAS DEL SOCORRO"
  },
  {
    "id": 2880,
    "zip_code": 683551,
    "dane_code": 68755000,
    "name_depto": "SANTANDER",
    "name_city": "SOCORRO"
  },
  {
    "id": 2881,
    "zip_code": 683557,
    "dane_code": 68755000,
    "name_depto": "SANTANDER",
    "name_city": "SOCORRO"
  },
  {
    "id": 2882,
    "zip_code": 683558,
    "dane_code": 68755000,
    "name_depto": "SANTANDER",
    "name_city": "SOCORRO"
  },
  {
    "id": 2883,
    "zip_code": 683561,
    "dane_code": 68745000,
    "name_depto": "SANTANDER",
    "name_city": "SIMACOTA"
  },
  {
    "id": 2884,
    "zip_code": 683567,
    "dane_code": 68745000,
    "name_depto": "SANTANDER",
    "name_city": "SIMACOTA"
  },
  {
    "id": 2885,
    "zip_code": 683568,
    "dane_code": 68745000,
    "name_depto": "SANTANDER",
    "name_city": "SIMACOTA"
  },
  {
    "id": 2886,
    "zip_code": 683569,
    "dane_code": 68745000,
    "name_depto": "SANTANDER",
    "name_city": "SIMACOTA"
  },
  {
    "id": 2887,
    "zip_code": 683571,
    "dane_code": 68344000,
    "name_depto": "SANTANDER",
    "name_city": "HATO"
  },
  {
    "id": 2888,
    "zip_code": 683577,
    "dane_code": 68745000,
    "name_depto": "SANTANDER",
    "name_city": "SIMACOTA"
  },
  {
    "id": 2889,
    "zip_code": 683578,
    "dane_code": 68344000,
    "name_depto": "SANTANDER",
    "name_city": "HATO"
  },
  {
    "id": 2890,
    "zip_code": 683581,
    "dane_code": 68522000,
    "name_depto": "SANTANDER",
    "name_city": "PALMAR"
  },
  {
    "id": 2891,
    "zip_code": 683587,
    "dane_code": 68522000,
    "name_depto": "SANTANDER",
    "name_city": "PALMAR"
  },
  {
    "id": 2892,
    "zip_code": 684001,
    "dane_code": 68418000,
    "name_depto": "SANTANDER",
    "name_city": "LOS SANTOS"
  },
  {
    "id": 2893,
    "zip_code": 684007,
    "dane_code": 68418000,
    "name_depto": "SANTANDER",
    "name_city": "LOS SANTOS"
  },
  {
    "id": 2894,
    "zip_code": 684008,
    "dane_code": 68418000,
    "name_depto": "SANTANDER",
    "name_city": "LOS SANTOS"
  },
  {
    "id": 2895,
    "zip_code": 684009,
    "dane_code": 68418000,
    "name_depto": "SANTANDER",
    "name_city": "LOS SANTOS"
  },
  {
    "id": 2896,
    "zip_code": 684011,
    "dane_code": 68370000,
    "name_depto": "SANTANDER",
    "name_city": "JORDAN"
  },
  {
    "id": 2897,
    "zip_code": 684017,
    "dane_code": 68370000,
    "name_depto": "SANTANDER",
    "name_city": "JORDAN"
  },
  {
    "id": 2898,
    "zip_code": 684021,
    "dane_code": 68872000,
    "name_depto": "SANTANDER",
    "name_city": "VILLANUEVA"
  },
  {
    "id": 2899,
    "zip_code": 684027,
    "dane_code": 68872000,
    "name_depto": "SANTANDER",
    "name_city": "VILLANUEVA"
  },
  {
    "id": 2900,
    "zip_code": 684028,
    "dane_code": 68872000,
    "name_depto": "SANTANDER",
    "name_city": "VILLANUEVA"
  },
  {
    "id": 2901,
    "zip_code": 684031,
    "dane_code": 68679000,
    "name_depto": "SANTANDER",
    "name_city": "SAN GIL"
  },
  {
    "id": 2902,
    "zip_code": 684037,
    "dane_code": 68679000,
    "name_depto": "SANTANDER",
    "name_city": "SAN GIL"
  },
  {
    "id": 2903,
    "zip_code": 684038,
    "dane_code": 68679000,
    "name_depto": "SANTANDER",
    "name_city": "SAN GIL"
  },
  {
    "id": 2904,
    "zip_code": 684039,
    "dane_code": 68679000,
    "name_depto": "SANTANDER",
    "name_city": "SAN GIL"
  },
  {
    "id": 2905,
    "zip_code": 684041,
    "dane_code": 68079000,
    "name_depto": "SANTANDER",
    "name_city": "BARICHARA"
  },
  {
    "id": 2906,
    "zip_code": 684047,
    "dane_code": 68079000,
    "name_depto": "SANTANDER",
    "name_city": "BARICHARA"
  },
  {
    "id": 2907,
    "zip_code": 684048,
    "dane_code": 68079000,
    "name_depto": "SANTANDER",
    "name_city": "BARICHARA"
  },
  {
    "id": 2908,
    "zip_code": 684051,
    "dane_code": 68296000,
    "name_depto": "SANTANDER",
    "name_city": "GALAN"
  },
  {
    "id": 2909,
    "zip_code": 684057,
    "dane_code": 68296000,
    "name_depto": "SANTANDER",
    "name_city": "GALAN"
  },
  {
    "id": 2910,
    "zip_code": 684058,
    "dane_code": 68296000,
    "name_depto": "SANTANDER",
    "name_city": "GALAN"
  },
  {
    "id": 2911,
    "zip_code": 684061,
    "dane_code": 68895000,
    "name_depto": "SANTANDER",
    "name_city": "ZAPATOCA"
  },
  {
    "id": 2912,
    "zip_code": 684067,
    "dane_code": 68895000,
    "name_depto": "SANTANDER",
    "name_city": "ZAPATOCA"
  },
  {
    "id": 2913,
    "zip_code": 684068,
    "dane_code": 68895000,
    "name_depto": "SANTANDER",
    "name_city": "ZAPATOCA"
  },
  {
    "id": 2914,
    "zip_code": 684069,
    "dane_code": 68895000,
    "name_depto": "SANTANDER",
    "name_city": "ZAPATOCA"
  },
  {
    "id": 2915,
    "zip_code": 684501,
    "dane_code": 68324000,
    "name_depto": "SANTANDER",
    "name_city": "GUAVATA"
  },
  {
    "id": 2916,
    "zip_code": 684507,
    "dane_code": 68324000,
    "name_depto": "SANTANDER",
    "name_city": "GUAVATA"
  },
  {
    "id": 2917,
    "zip_code": 684511,
    "dane_code": 68077000,
    "name_depto": "SANTANDER",
    "name_city": "BARBOSA"
  },
  {
    "id": 2918,
    "zip_code": 684517,
    "dane_code": 68077000,
    "name_depto": "SANTANDER",
    "name_city": "BARBOSA"
  },
  {
    "id": 2919,
    "zip_code": 684521,
    "dane_code": 68572000,
    "name_depto": "SANTANDER",
    "name_city": "PUENTE NACIONAL"
  },
  {
    "id": 2920,
    "zip_code": 684527,
    "dane_code": 68572000,
    "name_depto": "SANTANDER",
    "name_city": "PUENTE NACIONAL"
  },
  {
    "id": 2921,
    "zip_code": 684531,
    "dane_code": 68020000,
    "name_depto": "SANTANDER",
    "name_city": "ALBANIA"
  },
  {
    "id": 2922,
    "zip_code": 684537,
    "dane_code": 68020000,
    "name_depto": "SANTANDER",
    "name_city": "ALBANIA"
  },
  {
    "id": 2923,
    "zip_code": 684541,
    "dane_code": 68271000,
    "name_depto": "SANTANDER",
    "name_city": "FLORIAN"
  },
  {
    "id": 2924,
    "zip_code": 684547,
    "dane_code": 68271000,
    "name_depto": "SANTANDER",
    "name_city": "FLORIAN"
  },
  {
    "id": 2925,
    "zip_code": 684551,
    "dane_code": 68368000,
    "name_depto": "SANTANDER",
    "name_city": "JESUS MARIA"
  },
  {
    "id": 2926,
    "zip_code": 684557,
    "dane_code": 68368000,
    "name_depto": "SANTANDER",
    "name_city": "JESUS MARIA"
  },
  {
    "id": 2927,
    "zip_code": 685001,
    "dane_code": 68101000,
    "name_depto": "SANTANDER",
    "name_city": "BOLIVAR"
  },
  {
    "id": 2928,
    "zip_code": 685007,
    "dane_code": 68101000,
    "name_depto": "SANTANDER",
    "name_city": "BOLIVAR"
  },
  {
    "id": 2929,
    "zip_code": 685008,
    "dane_code": 68101000,
    "name_depto": "SANTANDER",
    "name_city": "BOLIVAR"
  },
  {
    "id": 2930,
    "zip_code": 685009,
    "dane_code": 68101000,
    "name_depto": "SANTANDER",
    "name_city": "BOLIVAR"
  },
  {
    "id": 2931,
    "zip_code": 685021,
    "dane_code": 68250000,
    "name_depto": "SANTANDER",
    "name_city": "EL PE√ëON"
  },
  {
    "id": 2932,
    "zip_code": 685027,
    "dane_code": 68250000,
    "name_depto": "SANTANDER",
    "name_city": "EL PE√ëON"
  },
  {
    "id": 2933,
    "zip_code": 685041,
    "dane_code": 68773000,
    "name_depto": "SANTANDER",
    "name_city": "SUCRE"
  },
  {
    "id": 2934,
    "zip_code": 685047,
    "dane_code": 68773000,
    "name_depto": "SANTANDER",
    "name_city": "SUCRE"
  },
  {
    "id": 2935,
    "zip_code": 685048,
    "dane_code": 68773000,
    "name_depto": "SANTANDER",
    "name_city": "SUCRE"
  },
  {
    "id": 2936,
    "zip_code": 685049,
    "dane_code": 68773000,
    "name_depto": "SANTANDER",
    "name_city": "SUCRE"
  },
  {
    "id": 2937,
    "zip_code": 685061,
    "dane_code": 68377000,
    "name_depto": "SANTANDER",
    "name_city": "LA BELLEZA"
  },
  {
    "id": 2938,
    "zip_code": 685067,
    "dane_code": 68377000,
    "name_depto": "SANTANDER",
    "name_city": "LA BELLEZA"
  },
  {
    "id": 2939,
    "zip_code": 685501,
    "dane_code": 68720000,
    "name_depto": "SANTANDER",
    "name_city": "SANTA HELENA DEL OPON"
  },
  {
    "id": 2940,
    "zip_code": 685507,
    "dane_code": 68720000,
    "name_depto": "SANTANDER",
    "name_city": "SANTA HELENA DEL OPON"
  },
  {
    "id": 2941,
    "zip_code": 685511,
    "dane_code": 68397000,
    "name_depto": "SANTANDER",
    "name_city": "LA PAZ"
  },
  {
    "id": 2942,
    "zip_code": 685517,
    "dane_code": 68397000,
    "name_depto": "SANTANDER",
    "name_city": "LA PAZ"
  },
  {
    "id": 2943,
    "zip_code": 685518,
    "dane_code": 68397000,
    "name_depto": "SANTANDER",
    "name_city": "LA PAZ"
  },
  {
    "id": 2944,
    "zip_code": 685521,
    "dane_code": 68013000,
    "name_depto": "SANTANDER",
    "name_city": "AGUADA"
  },
  {
    "id": 2945,
    "zip_code": 685527,
    "dane_code": 68013000,
    "name_depto": "SANTANDER",
    "name_city": "AGUADA"
  },
  {
    "id": 2946,
    "zip_code": 685531,
    "dane_code": 68673000,
    "name_depto": "SANTANDER",
    "name_city": "SAN BENITO"
  },
  {
    "id": 2947,
    "zip_code": 685537,
    "dane_code": 68673000,
    "name_depto": "SANTANDER",
    "name_city": "SAN BENITO"
  },
  {
    "id": 2948,
    "zip_code": 685541,
    "dane_code": 68327000,
    "name_depto": "SANTANDER",
    "name_city": "G√úEPSA"
  },
  {
    "id": 2949,
    "zip_code": 685547,
    "dane_code": 68327000,
    "name_depto": "SANTANDER",
    "name_city": "G√úEPSA"
  },
  {
    "id": 2950,
    "zip_code": 685551,
    "dane_code": 68179000,
    "name_depto": "SANTANDER",
    "name_city": "CHIPATA"
  },
  {
    "id": 2951,
    "zip_code": 685557,
    "dane_code": 68179000,
    "name_depto": "SANTANDER",
    "name_city": "CHIPATA"
  },
  {
    "id": 2952,
    "zip_code": 685561,
    "dane_code": 68861000,
    "name_depto": "SANTANDER",
    "name_city": "VELEZ"
  },
  {
    "id": 2953,
    "zip_code": 685567,
    "dane_code": 68861000,
    "name_depto": "SANTANDER",
    "name_city": "VELEZ"
  },
  {
    "id": 2954,
    "zip_code": 685568,
    "dane_code": 68861000,
    "name_depto": "SANTANDER",
    "name_city": "VELEZ"
  },
  {
    "id": 2955,
    "zip_code": 685569,
    "dane_code": 68861000,
    "name_depto": "SANTANDER",
    "name_city": "VELEZ"
  },
  {
    "id": 2956,
    "zip_code": 686001,
    "dane_code": 68573000,
    "name_depto": "SANTANDER",
    "name_city": "PUERTO PARRA"
  },
  {
    "id": 2957,
    "zip_code": 686007,
    "dane_code": 68573000,
    "name_depto": "SANTANDER",
    "name_city": "PUERTO PARRA"
  },
  {
    "id": 2958,
    "zip_code": 686008,
    "dane_code": 68573000,
    "name_depto": "SANTANDER",
    "name_city": "PUERTO PARRA"
  },
  {
    "id": 2959,
    "zip_code": 686021,
    "dane_code": 68385000,
    "name_depto": "SANTANDER",
    "name_city": "LANDAZURI"
  },
  {
    "id": 2960,
    "zip_code": 686027,
    "dane_code": 68385000,
    "name_depto": "SANTANDER",
    "name_city": "LANDAZURI"
  },
  {
    "id": 2961,
    "zip_code": 686028,
    "dane_code": 68385000,
    "name_depto": "SANTANDER",
    "name_city": "LANDAZURI"
  },
  {
    "id": 2962,
    "zip_code": 686029,
    "dane_code": 68385000,
    "name_depto": "SANTANDER",
    "name_city": "LANDAZURI"
  },
  {
    "id": 2963,
    "zip_code": 686041,
    "dane_code": 68190000,
    "name_depto": "SANTANDER",
    "name_city": "CIMITARRA"
  },
  {
    "id": 2964,
    "zip_code": 686047,
    "dane_code": 68190000,
    "name_depto": "SANTANDER",
    "name_city": "CIMITARRA"
  },
  {
    "id": 2965,
    "zip_code": 686048,
    "dane_code": 68190000,
    "name_depto": "SANTANDER",
    "name_city": "CIMITARRA"
  },
  {
    "id": 2966,
    "zip_code": 686049,
    "dane_code": 68190000,
    "name_depto": "SANTANDER",
    "name_city": "CIMITARRA"
  },
  {
    "id": 2967,
    "zip_code": 686057,
    "dane_code": 68190000,
    "name_depto": "SANTANDER",
    "name_city": "CIMITARRA"
  },
  {
    "id": 2968,
    "zip_code": 686058,
    "dane_code": 68190000,
    "name_depto": "SANTANDER",
    "name_city": "CIMITARRA"
  },
  {
    "id": 2969,
    "zip_code": 686501,
    "dane_code": 68092000,
    "name_depto": "SANTANDER",
    "name_city": "BETULIA"
  },
  {
    "id": 2970,
    "zip_code": 686507,
    "dane_code": 68092000,
    "name_depto": "SANTANDER",
    "name_city": "BETULIA"
  },
  {
    "id": 2971,
    "zip_code": 686508,
    "dane_code": 68092000,
    "name_depto": "SANTANDER",
    "name_city": "BETULIA"
  },
  {
    "id": 2972,
    "zip_code": 686531,
    "dane_code": 68689000,
    "name_depto": "SANTANDER",
    "name_city": "SAN VICENTE DE CHUCURI"
  },
  {
    "id": 2973,
    "zip_code": 686537,
    "dane_code": 68689000,
    "name_depto": "SANTANDER",
    "name_city": "SAN VICENTE DE CHUCURI"
  },
  {
    "id": 2974,
    "zip_code": 686538,
    "dane_code": 68689000,
    "name_depto": "SANTANDER",
    "name_city": "SAN VICENTE DE CHUCURI"
  },
  {
    "id": 2975,
    "zip_code": 686539,
    "dane_code": 68689000,
    "name_depto": "SANTANDER",
    "name_city": "SAN VICENTE DE CHUCURI"
  },
  {
    "id": 2976,
    "zip_code": 686547,
    "dane_code": 68689000,
    "name_depto": "SANTANDER",
    "name_city": "SAN VICENTE DE CHUCURI"
  },
  {
    "id": 2977,
    "zip_code": 686561,
    "dane_code": 68235000,
    "name_depto": "SANTANDER",
    "name_city": "EL CARMEN DE CHUCURI"
  },
  {
    "id": 2978,
    "zip_code": 686567,
    "dane_code": 68235000,
    "name_depto": "SANTANDER",
    "name_city": "EL CARMEN DE CHUCURI"
  },
  {
    "id": 2979,
    "zip_code": 686568,
    "dane_code": 68235000,
    "name_depto": "SANTANDER",
    "name_city": "EL CARMEN DE CHUCURI"
  },
  {
    "id": 2980,
    "zip_code": 686569,
    "dane_code": 68235000,
    "name_depto": "SANTANDER",
    "name_city": "EL CARMEN DE CHUCURI"
  },
  {
    "id": 2981,
    "zip_code": 686577,
    "dane_code": 68235000,
    "name_depto": "SANTANDER",
    "name_city": "EL CARMEN DE CHUCURI"
  },
  {
    "id": 2982,
    "zip_code": 687001,
    "dane_code": 68655000,
    "name_depto": "SANTANDER",
    "name_city": "SABANA DE TORRES"
  },
  {
    "id": 2983,
    "zip_code": 687007,
    "dane_code": 68655000,
    "name_depto": "SANTANDER",
    "name_city": "SABANA DE TORRES"
  },
  {
    "id": 2984,
    "zip_code": 687008,
    "dane_code": 68655000,
    "name_depto": "SANTANDER",
    "name_city": "SABANA DE TORRES"
  },
  {
    "id": 2985,
    "zip_code": 687031,
    "dane_code": 68081000,
    "name_depto": "SANTANDER",
    "name_city": "BARRANCABERMEJA"
  },
  {
    "id": 2986,
    "zip_code": 687032,
    "dane_code": 68081000,
    "name_depto": "SANTANDER",
    "name_city": "BARRANCABERMEJA"
  },
  {
    "id": 2987,
    "zip_code": 687033,
    "dane_code": 68081000,
    "name_depto": "SANTANDER",
    "name_city": "BARRANCABERMEJA"
  },
  {
    "id": 2988,
    "zip_code": 687037,
    "dane_code": 68081000,
    "name_depto": "SANTANDER",
    "name_city": "BARRANCABERMEJA"
  },
  {
    "id": 2989,
    "zip_code": 687038,
    "dane_code": 68081000,
    "name_depto": "SANTANDER",
    "name_city": "BARRANCABERMEJA"
  },
  {
    "id": 2990,
    "zip_code": 687039,
    "dane_code": 68081000,
    "name_depto": "SANTANDER",
    "name_city": "BARRANCABERMEJA"
  },
  {
    "id": 2991,
    "zip_code": 687047,
    "dane_code": 68081000,
    "name_depto": "SANTANDER",
    "name_city": "BARRANCABERMEJA"
  },
  {
    "id": 2992,
    "zip_code": 687061,
    "dane_code": 68575000,
    "name_depto": "SANTANDER",
    "name_city": "PUERTO WILCHES"
  },
  {
    "id": 2993,
    "zip_code": 687067,
    "dane_code": 68575000,
    "name_depto": "SANTANDER",
    "name_city": "PUERTO WILCHES"
  },
  {
    "id": 2994,
    "zip_code": 687068,
    "dane_code": 68575000,
    "name_depto": "SANTANDER",
    "name_city": "PUERTO WILCHES"
  },
  {
    "id": 2995,
    "zip_code": 687069,
    "dane_code": 68575000,
    "name_depto": "SANTANDER",
    "name_city": "PUERTO WILCHES"
  },
  {
    "id": 2996,
    "zip_code": 687501,
    "dane_code": 68255000,
    "name_depto": "SANTANDER",
    "name_city": "EL PLAYON"
  },
  {
    "id": 2997,
    "zip_code": 687507,
    "dane_code": 68255000,
    "name_depto": "SANTANDER",
    "name_city": "EL PLAYON"
  },
  {
    "id": 2998,
    "zip_code": 687508,
    "dane_code": 68255000,
    "name_depto": "SANTANDER",
    "name_city": "EL PLAYON"
  },
  {
    "id": 2999,
    "zip_code": 687509,
    "dane_code": 68255000,
    "name_depto": "SANTANDER",
    "name_city": "EL PLAYON"
  },
  {
    "id": 3000,
    "zip_code": 687511,
    "dane_code": 68615000,
    "name_depto": "SANTANDER",
    "name_city": "RIONEGRO"
  },
  {
    "id": 3001,
    "zip_code": 687517,
    "dane_code": 68615000,
    "name_depto": "SANTANDER",
    "name_city": "RIONEGRO"
  },
  {
    "id": 3002,
    "zip_code": 687518,
    "dane_code": 68615000,
    "name_depto": "SANTANDER",
    "name_city": "RIONEGRO"
  },
  {
    "id": 3003,
    "zip_code": 687519,
    "dane_code": 68615000,
    "name_depto": "SANTANDER",
    "name_city": "RIONEGRO"
  },
  {
    "id": 3004,
    "zip_code": 687527,
    "dane_code": 68615000,
    "name_depto": "SANTANDER",
    "name_city": "RIONEGRO"
  },
  {
    "id": 3005,
    "zip_code": 687541,
    "dane_code": 68307000,
    "name_depto": "SANTANDER",
    "name_city": "GIRON"
  },
  {
    "id": 3006,
    "zip_code": 687542,
    "dane_code": 68307000,
    "name_depto": "SANTANDER",
    "name_city": "GIRON"
  },
  {
    "id": 3007,
    "zip_code": 687547,
    "dane_code": 68307000,
    "name_depto": "SANTANDER",
    "name_city": "GIRON"
  },
  {
    "id": 3008,
    "zip_code": 687548,
    "dane_code": 68307000,
    "name_depto": "SANTANDER",
    "name_city": "GIRON"
  },
  {
    "id": 3009,
    "zip_code": 687549,
    "dane_code": 68307000,
    "name_depto": "SANTANDER",
    "name_city": "GIRON"
  },
  {
    "id": 3010,
    "zip_code": 687557,
    "dane_code": 68307000,
    "name_depto": "SANTANDER",
    "name_city": "GIRON"
  },
  {
    "id": 3011,
    "zip_code": 687558,
    "dane_code": 68307000,
    "name_depto": "SANTANDER",
    "name_city": "GIRON"
  },
  {
    "id": 3012,
    "zip_code": 687571,
    "dane_code": 68406000,
    "name_depto": "SANTANDER",
    "name_city": "LEBRIJA"
  },
  {
    "id": 3013,
    "zip_code": 687577,
    "dane_code": 68406000,
    "name_depto": "SANTANDER",
    "name_city": "LEBRIJA"
  },
  {
    "id": 3014,
    "zip_code": 687578,
    "dane_code": 68406000,
    "name_depto": "SANTANDER",
    "name_city": "LEBRIJA"
  },
  {
    "id": 3015,
    "zip_code": 687579,
    "dane_code": 68406000,
    "name_depto": "SANTANDER",
    "name_city": "LEBRIJA"
  },
  {
    "id": 3016,
    "zip_code": 687587,
    "dane_code": 68406000,
    "name_depto": "SANTANDER",
    "name_city": "LEBRIJA"
  },
  {
    "id": 3017,
    "zip_code": 700001,
    "dane_code": 70001000,
    "name_depto": "SUCRE",
    "name_city": "SINCELEJO"
  },
  {
    "id": 3018,
    "zip_code": 700002,
    "dane_code": 70001000,
    "name_depto": "SUCRE",
    "name_city": "SINCELEJO"
  },
  {
    "id": 3019,
    "zip_code": 700003,
    "dane_code": 70001000,
    "name_depto": "SUCRE",
    "name_city": "SINCELEJO"
  },
  {
    "id": 3020,
    "zip_code": 700007,
    "dane_code": 70001000,
    "name_depto": "SUCRE",
    "name_city": "SINCELEJO"
  },
  {
    "id": 3021,
    "zip_code": 700008,
    "dane_code": 70001000,
    "name_depto": "SUCRE",
    "name_city": "SINCELEJO"
  },
  {
    "id": 3022,
    "zip_code": 700009,
    "dane_code": 70001000,
    "name_depto": "SUCRE",
    "name_city": "SINCELEJO"
  },
  {
    "id": 3023,
    "zip_code": 700017,
    "dane_code": 70001000,
    "name_depto": "SUCRE",
    "name_city": "SINCELEJO"
  },
  {
    "id": 3024,
    "zip_code": 701010,
    "dane_code": 70230000,
    "name_depto": "SUCRE",
    "name_city": "CHALAN"
  },
  {
    "id": 3025,
    "zip_code": 701017,
    "dane_code": 70230000,
    "name_depto": "SUCRE",
    "name_city": "CHALAN"
  },
  {
    "id": 3026,
    "zip_code": 701030,
    "dane_code": 70508000,
    "name_depto": "SUCRE",
    "name_city": "OVEJAS"
  },
  {
    "id": 3027,
    "zip_code": 701037,
    "dane_code": 70508000,
    "name_depto": "SUCRE",
    "name_city": "OVEJAS"
  },
  {
    "id": 3028,
    "zip_code": 701038,
    "dane_code": 70508000,
    "name_depto": "SUCRE",
    "name_city": "OVEJAS"
  },
  {
    "id": 3029,
    "zip_code": 701050,
    "dane_code": 70418000,
    "name_depto": "SUCRE",
    "name_city": "LOS PALMITOS"
  },
  {
    "id": 3030,
    "zip_code": 701057,
    "dane_code": 70418000,
    "name_depto": "SUCRE",
    "name_city": "LOS PALMITOS"
  },
  {
    "id": 3031,
    "zip_code": 701058,
    "dane_code": 70418000,
    "name_depto": "SUCRE",
    "name_city": "LOS PALMITOS"
  },
  {
    "id": 3032,
    "zip_code": 701070,
    "dane_code": 70473000,
    "name_depto": "SUCRE",
    "name_city": "MORROA"
  },
  {
    "id": 3033,
    "zip_code": 701077,
    "dane_code": 70473000,
    "name_depto": "SUCRE",
    "name_city": "MORROA"
  },
  {
    "id": 3034,
    "zip_code": 701078,
    "dane_code": 70473000,
    "name_depto": "SUCRE",
    "name_city": "MORROA"
  },
  {
    "id": 3035,
    "zip_code": 702010,
    "dane_code": 70717000,
    "name_depto": "SUCRE",
    "name_city": "SAN PEDRO"
  },
  {
    "id": 3036,
    "zip_code": 702017,
    "dane_code": 70717000,
    "name_depto": "SUCRE",
    "name_city": "SAN PEDRO"
  },
  {
    "id": 3037,
    "zip_code": 702018,
    "dane_code": 70717000,
    "name_depto": "SUCRE",
    "name_city": "SAN PEDRO"
  },
  {
    "id": 3038,
    "zip_code": 702030,
    "dane_code": 70110000,
    "name_depto": "SUCRE",
    "name_city": "BUENAVISTA"
  },
  {
    "id": 3039,
    "zip_code": 702037,
    "dane_code": 70110000,
    "name_depto": "SUCRE",
    "name_city": "BUENAVISTA"
  },
  {
    "id": 3040,
    "zip_code": 702050,
    "dane_code": 70235000,
    "name_depto": "SUCRE",
    "name_city": "GALERAS"
  },
  {
    "id": 3041,
    "zip_code": 702057,
    "dane_code": 70235000,
    "name_depto": "SUCRE",
    "name_city": "GALERAS"
  },
  {
    "id": 3042,
    "zip_code": 702070,
    "dane_code": 70742000,
    "name_depto": "SUCRE",
    "name_city": "SAN LUIS DE SINCE"
  },
  {
    "id": 3043,
    "zip_code": 702077,
    "dane_code": 70742000,
    "name_depto": "SUCRE",
    "name_city": "SAN LUIS DE SINCE"
  },
  {
    "id": 3044,
    "zip_code": 702078,
    "dane_code": 70742000,
    "name_depto": "SUCRE",
    "name_city": "SAN LUIS DE SINCE"
  },
  {
    "id": 3045,
    "zip_code": 703010,
    "dane_code": 70678000,
    "name_depto": "SUCRE",
    "name_city": "SAN BENITO ABAD"
  },
  {
    "id": 3046,
    "zip_code": 703017,
    "dane_code": 70678000,
    "name_depto": "SUCRE",
    "name_city": "SAN BENITO ABAD"
  },
  {
    "id": 3047,
    "zip_code": 703018,
    "dane_code": 70678000,
    "name_depto": "SUCRE",
    "name_city": "SAN BENITO ABAD"
  },
  {
    "id": 3048,
    "zip_code": 703030,
    "dane_code": 70771000,
    "name_depto": "SUCRE",
    "name_city": "SUCRE"
  },
  {
    "id": 3049,
    "zip_code": 703037,
    "dane_code": 70771000,
    "name_depto": "SUCRE",
    "name_city": "SUCRE"
  },
  {
    "id": 3050,
    "zip_code": 703038,
    "dane_code": 70771000,
    "name_depto": "SUCRE",
    "name_city": "SUCRE"
  },
  {
    "id": 3051,
    "zip_code": 703050,
    "dane_code": 70429000,
    "name_depto": "SUCRE",
    "name_city": "MAJAGUAL"
  },
  {
    "id": 3052,
    "zip_code": 703057,
    "dane_code": 70429000,
    "name_depto": "SUCRE",
    "name_city": "MAJAGUAL"
  },
  {
    "id": 3053,
    "zip_code": 703058,
    "dane_code": 70429000,
    "name_depto": "SUCRE",
    "name_city": "MAJAGUAL"
  },
  {
    "id": 3054,
    "zip_code": 703070,
    "dane_code": 70265000,
    "name_depto": "SUCRE",
    "name_city": "GUARANDA"
  },
  {
    "id": 3055,
    "zip_code": 703077,
    "dane_code": 70265000,
    "name_depto": "SUCRE",
    "name_city": "GUARANDA"
  },
  {
    "id": 3056,
    "zip_code": 703078,
    "dane_code": 70265000,
    "name_depto": "SUCRE",
    "name_city": "GUARANDA"
  },
  {
    "id": 3057,
    "zip_code": 704010,
    "dane_code": 70124000,
    "name_depto": "SUCRE",
    "name_city": "CAIMITO"
  },
  {
    "id": 3058,
    "zip_code": 704017,
    "dane_code": 70124000,
    "name_depto": "SUCRE",
    "name_city": "CAIMITO"
  },
  {
    "id": 3059,
    "zip_code": 704018,
    "dane_code": 70124000,
    "name_depto": "SUCRE",
    "name_city": "CAIMITO"
  },
  {
    "id": 3060,
    "zip_code": 704030,
    "dane_code": 70708000,
    "name_depto": "SUCRE",
    "name_city": "SAN MARCOS"
  },
  {
    "id": 3061,
    "zip_code": 704037,
    "dane_code": 70708000,
    "name_depto": "SUCRE",
    "name_city": "SAN MARCOS"
  },
  {
    "id": 3062,
    "zip_code": 704038,
    "dane_code": 70708000,
    "name_depto": "SUCRE",
    "name_city": "SAN MARCOS"
  },
  {
    "id": 3063,
    "zip_code": 704050,
    "dane_code": 70400000,
    "name_depto": "SUCRE",
    "name_city": "LA UNION"
  },
  {
    "id": 3064,
    "zip_code": 704057,
    "dane_code": 70400000,
    "name_depto": "SUCRE",
    "name_city": "LA UNION"
  },
  {
    "id": 3065,
    "zip_code": 705010,
    "dane_code": 70702000,
    "name_depto": "SUCRE",
    "name_city": "SAN JUAN DE BETULIA"
  },
  {
    "id": 3066,
    "zip_code": 705017,
    "dane_code": 70702000,
    "name_depto": "SUCRE",
    "name_city": "SAN JUAN DE BETULIA"
  },
  {
    "id": 3067,
    "zip_code": 705018,
    "dane_code": 70702000,
    "name_depto": "SUCRE",
    "name_city": "SAN JUAN DE BETULIA"
  },
  {
    "id": 3068,
    "zip_code": 705030,
    "dane_code": 70215000,
    "name_depto": "SUCRE",
    "name_city": "COROZAL"
  },
  {
    "id": 3069,
    "zip_code": 705037,
    "dane_code": 70215000,
    "name_depto": "SUCRE",
    "name_city": "COROZAL"
  },
  {
    "id": 3070,
    "zip_code": 705038,
    "dane_code": 70215000,
    "name_depto": "SUCRE",
    "name_city": "COROZAL"
  },
  {
    "id": 3071,
    "zip_code": 705039,
    "dane_code": 70215000,
    "name_depto": "SUCRE",
    "name_city": "COROZAL"
  },
  {
    "id": 3072,
    "zip_code": 705050,
    "dane_code": 70233000,
    "name_depto": "SUCRE",
    "name_city": "EL ROBLE"
  },
  {
    "id": 3073,
    "zip_code": 705057,
    "dane_code": 70233000,
    "name_depto": "SUCRE",
    "name_city": "EL ROBLE"
  },
  {
    "id": 3074,
    "zip_code": 705058,
    "dane_code": 70233000,
    "name_depto": "SUCRE",
    "name_city": "EL ROBLE"
  },
  {
    "id": 3075,
    "zip_code": 705070,
    "dane_code": 70670000,
    "name_depto": "SUCRE",
    "name_city": "SAMPUES"
  },
  {
    "id": 3076,
    "zip_code": 705077,
    "dane_code": 70670000,
    "name_depto": "SUCRE",
    "name_city": "SAMPUES"
  },
  {
    "id": 3077,
    "zip_code": 705078,
    "dane_code": 70670000,
    "name_depto": "SUCRE",
    "name_city": "SAMPUES"
  },
  {
    "id": 3078,
    "zip_code": 705079,
    "dane_code": 70670000,
    "name_depto": "SUCRE",
    "name_city": "SAMPUES"
  },
  {
    "id": 3079,
    "zip_code": 706010,
    "dane_code": 70820000,
    "name_depto": "SUCRE",
    "name_city": "SANTIAGO DE TOLU"
  },
  {
    "id": 3080,
    "zip_code": 706017,
    "dane_code": 70820000,
    "name_depto": "SUCRE",
    "name_city": "SANTIAGO DE TOLU"
  },
  {
    "id": 3081,
    "zip_code": 706018,
    "dane_code": 70820000,
    "name_depto": "SUCRE",
    "name_city": "SANTIAGO DE TOLU"
  },
  {
    "id": 3082,
    "zip_code": 706030,
    "dane_code": 70523000,
    "name_depto": "SUCRE",
    "name_city": "PALMITO"
  },
  {
    "id": 3083,
    "zip_code": 706037,
    "dane_code": 70523000,
    "name_depto": "SUCRE",
    "name_city": "PALMITO"
  },
  {
    "id": 3084,
    "zip_code": 706050,
    "dane_code": 70221000,
    "name_depto": "SUCRE",
    "name_city": "COVE√ëAS"
  },
  {
    "id": 3085,
    "zip_code": 706057,
    "dane_code": 70221000,
    "name_depto": "SUCRE",
    "name_city": "COVE√ëAS"
  },
  {
    "id": 3086,
    "zip_code": 707010,
    "dane_code": 70713000,
    "name_depto": "SUCRE",
    "name_city": "SAN ONOFRE"
  },
  {
    "id": 3087,
    "zip_code": 707017,
    "dane_code": 70713000,
    "name_depto": "SUCRE",
    "name_city": "SAN ONOFRE"
  },
  {
    "id": 3088,
    "zip_code": 707018,
    "dane_code": 70713000,
    "name_depto": "SUCRE",
    "name_city": "SAN ONOFRE"
  },
  {
    "id": 3089,
    "zip_code": 707030,
    "dane_code": 70204000,
    "name_depto": "SUCRE",
    "name_city": "COLOSO"
  },
  {
    "id": 3090,
    "zip_code": 707037,
    "dane_code": 70204000,
    "name_depto": "SUCRE",
    "name_city": "COLOSO"
  },
  {
    "id": 3091,
    "zip_code": 707050,
    "dane_code": 70823000,
    "name_depto": "SUCRE",
    "name_city": "TOLU VIEJO"
  },
  {
    "id": 3092,
    "zip_code": 707057,
    "dane_code": 70823000,
    "name_depto": "SUCRE",
    "name_city": "TOLU VIEJO"
  },
  {
    "id": 3093,
    "zip_code": 730001,
    "dane_code": 73001000,
    "name_depto": "TOLIMA",
    "name_city": "IBAGUE"
  },
  {
    "id": 3094,
    "zip_code": 730002,
    "dane_code": 73001000,
    "name_depto": "TOLIMA",
    "name_city": "IBAGUE"
  },
  {
    "id": 3095,
    "zip_code": 730003,
    "dane_code": 73001000,
    "name_depto": "TOLIMA",
    "name_city": "IBAGUE"
  },
  {
    "id": 3096,
    "zip_code": 730004,
    "dane_code": 73001000,
    "name_depto": "TOLIMA",
    "name_city": "IBAGUE"
  },
  {
    "id": 3097,
    "zip_code": 730005,
    "dane_code": 73001000,
    "name_depto": "TOLIMA",
    "name_city": "IBAGUE"
  },
  {
    "id": 3098,
    "zip_code": 730006,
    "dane_code": 73001000,
    "name_depto": "TOLIMA",
    "name_city": "IBAGUE"
  },
  {
    "id": 3099,
    "zip_code": 730007,
    "dane_code": 73001000,
    "name_depto": "TOLIMA",
    "name_city": "IBAGUE"
  },
  {
    "id": 3100,
    "zip_code": 730008,
    "dane_code": 73001000,
    "name_depto": "TOLIMA",
    "name_city": "IBAGUE"
  },
  {
    "id": 3101,
    "zip_code": 730009,
    "dane_code": 73001000,
    "name_depto": "TOLIMA",
    "name_city": "IBAGUE"
  },
  {
    "id": 3102,
    "zip_code": 730010,
    "dane_code": 73001000,
    "name_depto": "TOLIMA",
    "name_city": "IBAGUE"
  },
  {
    "id": 3103,
    "zip_code": 730017,
    "dane_code": 73001000,
    "name_depto": "TOLIMA",
    "name_city": "IBAGUE"
  },
  {
    "id": 3104,
    "zip_code": 730018,
    "dane_code": 73001000,
    "name_depto": "TOLIMA",
    "name_city": "IBAGUE"
  },
  {
    "id": 3105,
    "zip_code": 730019,
    "dane_code": 73001000,
    "name_depto": "TOLIMA",
    "name_city": "IBAGUE"
  },
  {
    "id": 3106,
    "zip_code": 730501,
    "dane_code": 73547000,
    "name_depto": "TOLIMA",
    "name_city": "PIEDRAS"
  },
  {
    "id": 3107,
    "zip_code": 730507,
    "dane_code": 73547000,
    "name_depto": "TOLIMA",
    "name_city": "PIEDRAS"
  },
  {
    "id": 3108,
    "zip_code": 730520,
    "dane_code": 73026000,
    "name_depto": "TOLIMA",
    "name_city": "ALVARADO"
  },
  {
    "id": 3109,
    "zip_code": 730527,
    "dane_code": 73026000,
    "name_depto": "TOLIMA",
    "name_city": "ALVARADO"
  },
  {
    "id": 3110,
    "zip_code": 730528,
    "dane_code": 73026000,
    "name_depto": "TOLIMA",
    "name_city": "ALVARADO"
  },
  {
    "id": 3111,
    "zip_code": 730540,
    "dane_code": 73043000,
    "name_depto": "TOLIMA",
    "name_city": "ANZOATEGUI"
  },
  {
    "id": 3112,
    "zip_code": 730547,
    "dane_code": 73043000,
    "name_depto": "TOLIMA",
    "name_city": "ANZOATEGUI"
  },
  {
    "id": 3113,
    "zip_code": 730548,
    "dane_code": 73043000,
    "name_depto": "TOLIMA",
    "name_city": "ANZOATEGUI"
  },
  {
    "id": 3114,
    "zip_code": 730560,
    "dane_code": 73686000,
    "name_depto": "TOLIMA",
    "name_city": "SANTA ISABEL"
  },
  {
    "id": 3115,
    "zip_code": 730567,
    "dane_code": 73686000,
    "name_depto": "TOLIMA",
    "name_city": "SANTA ISABEL"
  },
  {
    "id": 3116,
    "zip_code": 730568,
    "dane_code": 73686000,
    "name_depto": "TOLIMA",
    "name_city": "SANTA ISABEL"
  },
  {
    "id": 3117,
    "zip_code": 730580,
    "dane_code": 73861000,
    "name_depto": "TOLIMA",
    "name_city": "VENADILLO"
  },
  {
    "id": 3118,
    "zip_code": 730587,
    "dane_code": 73861000,
    "name_depto": "TOLIMA",
    "name_city": "VENADILLO"
  },
  {
    "id": 3119,
    "zip_code": 730588,
    "dane_code": 73861000,
    "name_depto": "TOLIMA",
    "name_city": "VENADILLO"
  },
  {
    "id": 3120,
    "zip_code": 731001,
    "dane_code": 73030000,
    "name_depto": "TOLIMA",
    "name_city": "AMBALEMA"
  },
  {
    "id": 3121,
    "zip_code": 731007,
    "dane_code": 73030000,
    "name_depto": "TOLIMA",
    "name_city": "AMBALEMA"
  },
  {
    "id": 3122,
    "zip_code": 731020,
    "dane_code": 73408000,
    "name_depto": "TOLIMA",
    "name_city": "LERIDA"
  },
  {
    "id": 3123,
    "zip_code": 731027,
    "dane_code": 73408000,
    "name_depto": "TOLIMA",
    "name_city": "LERIDA"
  },
  {
    "id": 3124,
    "zip_code": 731040,
    "dane_code": 73411000,
    "name_depto": "TOLIMA",
    "name_city": "LIBANO"
  },
  {
    "id": 3125,
    "zip_code": 731047,
    "dane_code": 73411000,
    "name_depto": "TOLIMA",
    "name_city": "LIBANO"
  },
  {
    "id": 3126,
    "zip_code": 731048,
    "dane_code": 73411000,
    "name_depto": "TOLIMA",
    "name_city": "LIBANO"
  },
  {
    "id": 3127,
    "zip_code": 731060,
    "dane_code": 73461000,
    "name_depto": "TOLIMA",
    "name_city": "MURILLO"
  },
  {
    "id": 3128,
    "zip_code": 731067,
    "dane_code": 73461000,
    "name_depto": "TOLIMA",
    "name_city": "MURILLO"
  },
  {
    "id": 3129,
    "zip_code": 731501,
    "dane_code": 73870000,
    "name_depto": "TOLIMA",
    "name_city": "VILLAHERMOSA"
  },
  {
    "id": 3130,
    "zip_code": 731507,
    "dane_code": 73870000,
    "name_depto": "TOLIMA",
    "name_city": "VILLAHERMOSA"
  },
  {
    "id": 3131,
    "zip_code": 731508,
    "dane_code": 73870000,
    "name_depto": "TOLIMA",
    "name_city": "VILLAHERMOSA"
  },
  {
    "id": 3132,
    "zip_code": 731520,
    "dane_code": 73152000,
    "name_depto": "TOLIMA",
    "name_city": "CASABIANCA"
  },
  {
    "id": 3133,
    "zip_code": 731527,
    "dane_code": 73152000,
    "name_depto": "TOLIMA",
    "name_city": "CASABIANCA"
  },
  {
    "id": 3134,
    "zip_code": 731528,
    "dane_code": 73152000,
    "name_depto": "TOLIMA",
    "name_city": "CASABIANCA"
  },
  {
    "id": 3135,
    "zip_code": 731540,
    "dane_code": 73347000,
    "name_depto": "TOLIMA",
    "name_city": "HERVEO"
  },
  {
    "id": 3136,
    "zip_code": 731547,
    "dane_code": 73347000,
    "name_depto": "TOLIMA",
    "name_city": "HERVEO"
  },
  {
    "id": 3137,
    "zip_code": 731548,
    "dane_code": 73347000,
    "name_depto": "TOLIMA",
    "name_city": "HERVEO"
  },
  {
    "id": 3138,
    "zip_code": 731560,
    "dane_code": 73283000,
    "name_depto": "TOLIMA",
    "name_city": "FRESNO"
  },
  {
    "id": 3139,
    "zip_code": 731567,
    "dane_code": 73283000,
    "name_depto": "TOLIMA",
    "name_city": "FRESNO"
  },
  {
    "id": 3140,
    "zip_code": 731568,
    "dane_code": 73283000,
    "name_depto": "TOLIMA",
    "name_city": "FRESNO"
  },
  {
    "id": 3141,
    "zip_code": 731580,
    "dane_code": 73520000,
    "name_depto": "TOLIMA",
    "name_city": "PALOCABILDO"
  },
  {
    "id": 3142,
    "zip_code": 731587,
    "dane_code": 73520000,
    "name_depto": "TOLIMA",
    "name_city": "PALOCABILDO"
  },
  {
    "id": 3143,
    "zip_code": 732001,
    "dane_code": 73270000,
    "name_depto": "TOLIMA",
    "name_city": "FALAN"
  },
  {
    "id": 3144,
    "zip_code": 732007,
    "dane_code": 73270000,
    "name_depto": "TOLIMA",
    "name_city": "FALAN"
  },
  {
    "id": 3145,
    "zip_code": 732008,
    "dane_code": 73270000,
    "name_depto": "TOLIMA",
    "name_city": "FALAN"
  },
  {
    "id": 3146,
    "zip_code": 732020,
    "dane_code": 73443000,
    "name_depto": "TOLIMA",
    "name_city": "MARIQUITA"
  },
  {
    "id": 3147,
    "zip_code": 732027,
    "dane_code": 73443000,
    "name_depto": "TOLIMA",
    "name_city": "MARIQUITA"
  },
  {
    "id": 3148,
    "zip_code": 732028,
    "dane_code": 73443000,
    "name_depto": "TOLIMA",
    "name_city": "MARIQUITA"
  },
  {
    "id": 3149,
    "zip_code": 732040,
    "dane_code": 73349000,
    "name_depto": "TOLIMA",
    "name_city": "HONDA"
  },
  {
    "id": 3150,
    "zip_code": 732047,
    "dane_code": 73349000,
    "name_depto": "TOLIMA",
    "name_city": "HONDA"
  },
  {
    "id": 3151,
    "zip_code": 732048,
    "dane_code": 73349000,
    "name_depto": "TOLIMA",
    "name_city": "HONDA"
  },
  {
    "id": 3152,
    "zip_code": 732060,
    "dane_code": 73055000,
    "name_depto": "TOLIMA",
    "name_city": "ARMERO"
  },
  {
    "id": 3153,
    "zip_code": 732067,
    "dane_code": 73055000,
    "name_depto": "TOLIMA",
    "name_city": "ARMERO"
  },
  {
    "id": 3154,
    "zip_code": 732068,
    "dane_code": 73055000,
    "name_depto": "TOLIMA",
    "name_city": "ARMERO"
  },
  {
    "id": 3155,
    "zip_code": 732501,
    "dane_code": 73124000,
    "name_depto": "TOLIMA",
    "name_city": "CAJAMARCA"
  },
  {
    "id": 3156,
    "zip_code": 732507,
    "dane_code": 73124000,
    "name_depto": "TOLIMA",
    "name_city": "CAJAMARCA"
  },
  {
    "id": 3157,
    "zip_code": 732508,
    "dane_code": 73124000,
    "name_depto": "TOLIMA",
    "name_city": "CAJAMARCA"
  },
  {
    "id": 3158,
    "zip_code": 733001,
    "dane_code": 73678000,
    "name_depto": "TOLIMA",
    "name_city": "SAN LUIS"
  },
  {
    "id": 3159,
    "zip_code": 733007,
    "dane_code": 73678000,
    "name_depto": "TOLIMA",
    "name_city": "SAN LUIS"
  },
  {
    "id": 3160,
    "zip_code": 733008,
    "dane_code": 73678000,
    "name_depto": "TOLIMA",
    "name_city": "SAN LUIS"
  },
  {
    "id": 3161,
    "zip_code": 733020,
    "dane_code": 73854000,
    "name_depto": "TOLIMA",
    "name_city": "VALLE DE SAN JUAN"
  },
  {
    "id": 3162,
    "zip_code": 733027,
    "dane_code": 73854000,
    "name_depto": "TOLIMA",
    "name_city": "VALLE DE SAN JUAN"
  },
  {
    "id": 3163,
    "zip_code": 733040,
    "dane_code": 73624000,
    "name_depto": "TOLIMA",
    "name_city": "ROVIRA"
  },
  {
    "id": 3164,
    "zip_code": 733047,
    "dane_code": 73624000,
    "name_depto": "TOLIMA",
    "name_city": "ROVIRA"
  },
  {
    "id": 3165,
    "zip_code": 733048,
    "dane_code": 73624000,
    "name_depto": "TOLIMA",
    "name_city": "ROVIRA"
  },
  {
    "id": 3166,
    "zip_code": 733501,
    "dane_code": 73200000,
    "name_depto": "TOLIMA",
    "name_city": "COELLO"
  },
  {
    "id": 3167,
    "zip_code": 733507,
    "dane_code": 73200000,
    "name_depto": "TOLIMA",
    "name_city": "COELLO"
  },
  {
    "id": 3168,
    "zip_code": 733508,
    "dane_code": 73200000,
    "name_depto": "TOLIMA",
    "name_city": "COELLO"
  },
  {
    "id": 3169,
    "zip_code": 733510,
    "dane_code": 73275000,
    "name_depto": "TOLIMA",
    "name_city": "FLANDES"
  },
  {
    "id": 3170,
    "zip_code": 733517,
    "dane_code": 73275000,
    "name_depto": "TOLIMA",
    "name_city": "FLANDES"
  },
  {
    "id": 3171,
    "zip_code": 733520,
    "dane_code": 73268000,
    "name_depto": "TOLIMA",
    "name_city": "ESPINAL"
  },
  {
    "id": 3172,
    "zip_code": 733527,
    "dane_code": 73268000,
    "name_depto": "TOLIMA",
    "name_city": "ESPINAL"
  },
  {
    "id": 3173,
    "zip_code": 733528,
    "dane_code": 73268000,
    "name_depto": "TOLIMA",
    "name_city": "ESPINAL"
  },
  {
    "id": 3174,
    "zip_code": 733529,
    "dane_code": 73268000,
    "name_depto": "TOLIMA",
    "name_city": "ESPINAL"
  },
  {
    "id": 3175,
    "zip_code": 733540,
    "dane_code": 73319000,
    "name_depto": "TOLIMA",
    "name_city": "GUAMO"
  },
  {
    "id": 3176,
    "zip_code": 733547,
    "dane_code": 73319000,
    "name_depto": "TOLIMA",
    "name_city": "GUAMO"
  },
  {
    "id": 3177,
    "zip_code": 733548,
    "dane_code": 73319000,
    "name_depto": "TOLIMA",
    "name_city": "GUAMO"
  },
  {
    "id": 3178,
    "zip_code": 733549,
    "dane_code": 73319000,
    "name_depto": "TOLIMA",
    "name_city": "GUAMO"
  },
  {
    "id": 3179,
    "zip_code": 733557,
    "dane_code": 73319000,
    "name_depto": "TOLIMA",
    "name_city": "GUAMO"
  },
  {
    "id": 3180,
    "zip_code": 733558,
    "dane_code": 73319000,
    "name_depto": "TOLIMA",
    "name_city": "GUAMO"
  },
  {
    "id": 3181,
    "zip_code": 733570,
    "dane_code": 73671000,
    "name_depto": "TOLIMA",
    "name_city": "SALDA√ëA"
  },
  {
    "id": 3182,
    "zip_code": 733577,
    "dane_code": 73671000,
    "name_depto": "TOLIMA",
    "name_city": "SALDA√ëA"
  },
  {
    "id": 3183,
    "zip_code": 733578,
    "dane_code": 73671000,
    "name_depto": "TOLIMA",
    "name_city": "SALDA√ëA"
  },
  {
    "id": 3184,
    "zip_code": 733580,
    "dane_code": 73770000,
    "name_depto": "TOLIMA",
    "name_city": "SUAREZ"
  },
  {
    "id": 3185,
    "zip_code": 733587,
    "dane_code": 73770000,
    "name_depto": "TOLIMA",
    "name_city": "SUAREZ"
  },
  {
    "id": 3186,
    "zip_code": 733588,
    "dane_code": 73770000,
    "name_depto": "TOLIMA",
    "name_city": "SUAREZ"
  },
  {
    "id": 3187,
    "zip_code": 733590,
    "dane_code": 73148000,
    "name_depto": "TOLIMA",
    "name_city": "CARMEN DE APICALA"
  },
  {
    "id": 3188,
    "zip_code": 733597,
    "dane_code": 73148000,
    "name_depto": "TOLIMA",
    "name_city": "CARMEN DE APICALA"
  },
  {
    "id": 3189,
    "zip_code": 734001,
    "dane_code": 73449000,
    "name_depto": "TOLIMA",
    "name_city": "MELGAR"
  },
  {
    "id": 3190,
    "zip_code": 734007,
    "dane_code": 73449000,
    "name_depto": "TOLIMA",
    "name_city": "MELGAR"
  },
  {
    "id": 3191,
    "zip_code": 734020,
    "dane_code": 73352000,
    "name_depto": "TOLIMA",
    "name_city": "ICONONZO"
  },
  {
    "id": 3192,
    "zip_code": 734027,
    "dane_code": 73352000,
    "name_depto": "TOLIMA",
    "name_city": "ICONONZO"
  },
  {
    "id": 3193,
    "zip_code": 734028,
    "dane_code": 73352000,
    "name_depto": "TOLIMA",
    "name_city": "ICONONZO"
  },
  {
    "id": 3194,
    "zip_code": 734040,
    "dane_code": 73226000,
    "name_depto": "TOLIMA",
    "name_city": "CUNDAY"
  },
  {
    "id": 3195,
    "zip_code": 734047,
    "dane_code": 73226000,
    "name_depto": "TOLIMA",
    "name_city": "CUNDAY"
  },
  {
    "id": 3196,
    "zip_code": 734048,
    "dane_code": 73226000,
    "name_depto": "TOLIMA",
    "name_city": "CUNDAY"
  },
  {
    "id": 3197,
    "zip_code": 734060,
    "dane_code": 73873000,
    "name_depto": "TOLIMA",
    "name_city": "VILLARRICA"
  },
  {
    "id": 3198,
    "zip_code": 734067,
    "dane_code": 73873000,
    "name_depto": "TOLIMA",
    "name_city": "VILLARRICA"
  },
  {
    "id": 3199,
    "zip_code": 734068,
    "dane_code": 73873000,
    "name_depto": "TOLIMA",
    "name_city": "VILLARRICA"
  },
  {
    "id": 3200,
    "zip_code": 734501,
    "dane_code": 73585000,
    "name_depto": "TOLIMA",
    "name_city": "PURIFICACION"
  },
  {
    "id": 3201,
    "zip_code": 734507,
    "dane_code": 73585000,
    "name_depto": "TOLIMA",
    "name_city": "PURIFICACION"
  },
  {
    "id": 3202,
    "zip_code": 734508,
    "dane_code": 73585000,
    "name_depto": "TOLIMA",
    "name_city": "PURIFICACION"
  },
  {
    "id": 3203,
    "zip_code": 734509,
    "dane_code": 73585000,
    "name_depto": "TOLIMA",
    "name_city": "PURIFICACION"
  },
  {
    "id": 3204,
    "zip_code": 734520,
    "dane_code": 73563000,
    "name_depto": "TOLIMA",
    "name_city": "PRADO"
  },
  {
    "id": 3205,
    "zip_code": 734527,
    "dane_code": 73563000,
    "name_depto": "TOLIMA",
    "name_city": "PRADO"
  },
  {
    "id": 3206,
    "zip_code": 734528,
    "dane_code": 73563000,
    "name_depto": "TOLIMA",
    "name_city": "PRADO"
  },
  {
    "id": 3207,
    "zip_code": 734540,
    "dane_code": 73236000,
    "name_depto": "TOLIMA",
    "name_city": "DOLORES"
  },
  {
    "id": 3208,
    "zip_code": 734547,
    "dane_code": 73236000,
    "name_depto": "TOLIMA",
    "name_city": "DOLORES"
  },
  {
    "id": 3209,
    "zip_code": 734560,
    "dane_code": 73024000,
    "name_depto": "TOLIMA",
    "name_city": "ALPUJARRA"
  },
  {
    "id": 3210,
    "zip_code": 734567,
    "dane_code": 73024000,
    "name_depto": "TOLIMA",
    "name_city": "ALPUJARRA"
  },
  {
    "id": 3211,
    "zip_code": 735001,
    "dane_code": 73483000,
    "name_depto": "TOLIMA",
    "name_city": "NATAGAIMA"
  },
  {
    "id": 3212,
    "zip_code": 735007,
    "dane_code": 73483000,
    "name_depto": "TOLIMA",
    "name_city": "NATAGAIMA"
  },
  {
    "id": 3213,
    "zip_code": 735008,
    "dane_code": 73483000,
    "name_depto": "TOLIMA",
    "name_city": "NATAGAIMA"
  },
  {
    "id": 3214,
    "zip_code": 735020,
    "dane_code": 73217000,
    "name_depto": "TOLIMA",
    "name_city": "COYAIMA"
  },
  {
    "id": 3215,
    "zip_code": 735027,
    "dane_code": 73217000,
    "name_depto": "TOLIMA",
    "name_city": "COYAIMA"
  },
  {
    "id": 3216,
    "zip_code": 735028,
    "dane_code": 73217000,
    "name_depto": "TOLIMA",
    "name_city": "COYAIMA"
  },
  {
    "id": 3217,
    "zip_code": 735029,
    "dane_code": 73217000,
    "name_depto": "TOLIMA",
    "name_city": "COYAIMA"
  },
  {
    "id": 3218,
    "zip_code": 735037,
    "dane_code": 73217000,
    "name_depto": "TOLIMA",
    "name_city": "COYAIMA"
  },
  {
    "id": 3219,
    "zip_code": 735050,
    "dane_code": 73067000,
    "name_depto": "TOLIMA",
    "name_city": "ATACO"
  },
  {
    "id": 3220,
    "zip_code": 735057,
    "dane_code": 73067000,
    "name_depto": "TOLIMA",
    "name_city": "ATACO"
  },
  {
    "id": 3221,
    "zip_code": 735058,
    "dane_code": 73067000,
    "name_depto": "TOLIMA",
    "name_city": "ATACO"
  },
  {
    "id": 3222,
    "zip_code": 735070,
    "dane_code": 73555000,
    "name_depto": "TOLIMA",
    "name_city": "PLANADAS"
  },
  {
    "id": 3223,
    "zip_code": 735077,
    "dane_code": 73555000,
    "name_depto": "TOLIMA",
    "name_city": "PLANADAS"
  },
  {
    "id": 3224,
    "zip_code": 735078,
    "dane_code": 73555000,
    "name_depto": "TOLIMA",
    "name_city": "PLANADAS"
  },
  {
    "id": 3225,
    "zip_code": 735501,
    "dane_code": 73504000,
    "name_depto": "TOLIMA",
    "name_city": "ORTEGA"
  },
  {
    "id": 3226,
    "zip_code": 735507,
    "dane_code": 73504000,
    "name_depto": "TOLIMA",
    "name_city": "ORTEGA"
  },
  {
    "id": 3227,
    "zip_code": 735508,
    "dane_code": 73504000,
    "name_depto": "TOLIMA",
    "name_city": "ORTEGA"
  },
  {
    "id": 3228,
    "zip_code": 735509,
    "dane_code": 73504000,
    "name_depto": "TOLIMA",
    "name_city": "ORTEGA"
  },
  {
    "id": 3229,
    "zip_code": 735517,
    "dane_code": 73504000,
    "name_depto": "TOLIMA",
    "name_city": "ORTEGA"
  },
  {
    "id": 3230,
    "zip_code": 735530,
    "dane_code": 73675000,
    "name_depto": "TOLIMA",
    "name_city": "SAN ANTONIO"
  },
  {
    "id": 3231,
    "zip_code": 735537,
    "dane_code": 73675000,
    "name_depto": "TOLIMA",
    "name_city": "SAN ANTONIO"
  },
  {
    "id": 3232,
    "zip_code": 735538,
    "dane_code": 73675000,
    "name_depto": "TOLIMA",
    "name_city": "SAN ANTONIO"
  },
  {
    "id": 3233,
    "zip_code": 735550,
    "dane_code": 73622000,
    "name_depto": "TOLIMA",
    "name_city": "RONCESVALLES"
  },
  {
    "id": 3234,
    "zip_code": 735557,
    "dane_code": 73622000,
    "name_depto": "TOLIMA",
    "name_city": "RONCESVALLES"
  },
  {
    "id": 3235,
    "zip_code": 735560,
    "dane_code": 73168000,
    "name_depto": "TOLIMA",
    "name_city": "CHAPARRAL"
  },
  {
    "id": 3236,
    "zip_code": 735567,
    "dane_code": 73168000,
    "name_depto": "TOLIMA",
    "name_city": "CHAPARRAL"
  },
  {
    "id": 3237,
    "zip_code": 735568,
    "dane_code": 73168000,
    "name_depto": "TOLIMA",
    "name_city": "CHAPARRAL"
  },
  {
    "id": 3238,
    "zip_code": 735569,
    "dane_code": 73168000,
    "name_depto": "TOLIMA",
    "name_city": "CHAPARRAL"
  },
  {
    "id": 3239,
    "zip_code": 735580,
    "dane_code": 73616000,
    "name_depto": "TOLIMA",
    "name_city": "RIOBLANCO"
  },
  {
    "id": 3240,
    "zip_code": 735587,
    "dane_code": 73616000,
    "name_depto": "TOLIMA",
    "name_city": "RIOBLANCO"
  },
  {
    "id": 3241,
    "zip_code": 735588,
    "dane_code": 73616000,
    "name_depto": "TOLIMA",
    "name_city": "RIOBLANCO"
  },
  {
    "id": 3242,
    "zip_code": 735589,
    "dane_code": 73616000,
    "name_depto": "TOLIMA",
    "name_city": "RIOBLANCO"
  },
  {
    "id": 3243,
    "zip_code": 760001,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3244,
    "zip_code": 760002,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3245,
    "zip_code": 760003,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3246,
    "zip_code": 760004,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3247,
    "zip_code": 760006,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3248,
    "zip_code": 760007,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3249,
    "zip_code": 760008,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3250,
    "zip_code": 760009,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3251,
    "zip_code": 760010,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3252,
    "zip_code": 760011,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3253,
    "zip_code": 760012,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3254,
    "zip_code": 760013,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3255,
    "zip_code": 760014,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3256,
    "zip_code": 760015,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3257,
    "zip_code": 760016,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3258,
    "zip_code": 760020,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3259,
    "zip_code": 760021,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3260,
    "zip_code": 760022,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3261,
    "zip_code": 760023,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3262,
    "zip_code": 760024,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3263,
    "zip_code": 760025,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3264,
    "zip_code": 760026,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3265,
    "zip_code": 760030,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3266,
    "zip_code": 760031,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3267,
    "zip_code": 760032,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3268,
    "zip_code": 760033,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3269,
    "zip_code": 760034,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3270,
    "zip_code": 760035,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3271,
    "zip_code": 760036,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3272,
    "zip_code": 760040,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3273,
    "zip_code": 760041,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3274,
    "zip_code": 760042,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3275,
    "zip_code": 760043,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3276,
    "zip_code": 760044,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3277,
    "zip_code": 760045,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3278,
    "zip_code": 760046,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3279,
    "zip_code": 760050,
    "dane_code": 76001000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALI"
  },
  {
    "id": 3280,
    "zip_code": 760501,
    "dane_code": 76892000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "YUMBO"
  },
  {
    "id": 3281,
    "zip_code": 760502,
    "dane_code": 76892000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "YUMBO"
  },
  {
    "id": 3282,
    "zip_code": 760507,
    "dane_code": 76892000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "YUMBO"
  },
  {
    "id": 3283,
    "zip_code": 760508,
    "dane_code": 76892000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "YUMBO"
  },
  {
    "id": 3284,
    "zip_code": 760510,
    "dane_code": 76377000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "LA CUMBRE"
  },
  {
    "id": 3285,
    "zip_code": 760517,
    "dane_code": 76377000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "LA CUMBRE"
  },
  {
    "id": 3286,
    "zip_code": 760518,
    "dane_code": 76377000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "LA CUMBRE"
  },
  {
    "id": 3287,
    "zip_code": 760520,
    "dane_code": 76233000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "DAGUA"
  },
  {
    "id": 3288,
    "zip_code": 760527,
    "dane_code": 76233000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "DAGUA"
  },
  {
    "id": 3289,
    "zip_code": 760528,
    "dane_code": 76233000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "DAGUA"
  },
  {
    "id": 3290,
    "zip_code": 760529,
    "dane_code": 76233000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "DAGUA"
  },
  {
    "id": 3291,
    "zip_code": 760530,
    "dane_code": 76126000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALIMA"
  },
  {
    "id": 3292,
    "zip_code": 760537,
    "dane_code": 76126000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALIMA"
  },
  {
    "id": 3293,
    "zip_code": 760538,
    "dane_code": 76126000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CALIMA"
  },
  {
    "id": 3294,
    "zip_code": 760540,
    "dane_code": 76606000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "RESTREPO"
  },
  {
    "id": 3295,
    "zip_code": 760547,
    "dane_code": 76606000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "RESTREPO"
  },
  {
    "id": 3296,
    "zip_code": 760548,
    "dane_code": 76606000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "RESTREPO"
  },
  {
    "id": 3297,
    "zip_code": 760550,
    "dane_code": 76869000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "VIJES"
  },
  {
    "id": 3298,
    "zip_code": 760557,
    "dane_code": 76869000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "VIJES"
  },
  {
    "id": 3299,
    "zip_code": 761001,
    "dane_code": 76100000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "BOLIVAR"
  },
  {
    "id": 3300,
    "zip_code": 761007,
    "dane_code": 76100000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "BOLIVAR"
  },
  {
    "id": 3301,
    "zip_code": 761008,
    "dane_code": 76100000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "BOLIVAR"
  },
  {
    "id": 3302,
    "zip_code": 761020,
    "dane_code": 76828000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "TRUJILLO"
  },
  {
    "id": 3303,
    "zip_code": 761027,
    "dane_code": 76828000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "TRUJILLO"
  },
  {
    "id": 3304,
    "zip_code": 761028,
    "dane_code": 76828000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "TRUJILLO"
  },
  {
    "id": 3305,
    "zip_code": 761030,
    "dane_code": 76616000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "RIOFRIO"
  },
  {
    "id": 3306,
    "zip_code": 761037,
    "dane_code": 76616000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "RIOFRIO"
  },
  {
    "id": 3307,
    "zip_code": 761038,
    "dane_code": 76616000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "RIOFRIO"
  },
  {
    "id": 3308,
    "zip_code": 761040,
    "dane_code": 76890000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "YOTOCO"
  },
  {
    "id": 3309,
    "zip_code": 761047,
    "dane_code": 76890000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "YOTOCO"
  },
  {
    "id": 3310,
    "zip_code": 761048,
    "dane_code": 76890000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "YOTOCO"
  },
  {
    "id": 3311,
    "zip_code": 761501,
    "dane_code": 76246000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "EL CAIRO"
  },
  {
    "id": 3312,
    "zip_code": 761507,
    "dane_code": 76246000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "EL CAIRO"
  },
  {
    "id": 3313,
    "zip_code": 761508,
    "dane_code": 76246000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "EL CAIRO"
  },
  {
    "id": 3314,
    "zip_code": 761510,
    "dane_code": 76054000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "ARGELIA"
  },
  {
    "id": 3315,
    "zip_code": 761517,
    "dane_code": 76054000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "ARGELIA"
  },
  {
    "id": 3316,
    "zip_code": 761520,
    "dane_code": 76823000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "TORO"
  },
  {
    "id": 3317,
    "zip_code": 761527,
    "dane_code": 76823000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "TORO"
  },
  {
    "id": 3318,
    "zip_code": 761528,
    "dane_code": 76823000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "TORO"
  },
  {
    "id": 3319,
    "zip_code": 761530,
    "dane_code": 76863000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "VERSALLES"
  },
  {
    "id": 3320,
    "zip_code": 761537,
    "dane_code": 76863000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "VERSALLES"
  },
  {
    "id": 3321,
    "zip_code": 761540,
    "dane_code": 76400000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "LA UNION"
  },
  {
    "id": 3322,
    "zip_code": 761547,
    "dane_code": 76400000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "LA UNION"
  },
  {
    "id": 3323,
    "zip_code": 761548,
    "dane_code": 76400000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "LA UNION"
  },
  {
    "id": 3324,
    "zip_code": 761550,
    "dane_code": 76622000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "ROLDANILLO"
  },
  {
    "id": 3325,
    "zip_code": 761557,
    "dane_code": 76622000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "ROLDANILLO"
  },
  {
    "id": 3326,
    "zip_code": 761558,
    "dane_code": 76622000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "ROLDANILLO"
  },
  {
    "id": 3327,
    "zip_code": 761560,
    "dane_code": 76250000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "EL DOVIO"
  },
  {
    "id": 3328,
    "zip_code": 761567,
    "dane_code": 76250000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "EL DOVIO"
  },
  {
    "id": 3329,
    "zip_code": 762001,
    "dane_code": 76243000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "EL AGUILA"
  },
  {
    "id": 3330,
    "zip_code": 762007,
    "dane_code": 76243000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "EL AGUILA"
  },
  {
    "id": 3331,
    "zip_code": 762008,
    "dane_code": 76243000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "EL AGUILA"
  },
  {
    "id": 3332,
    "zip_code": 762010,
    "dane_code": 76041000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "ANSERMANUEVO"
  },
  {
    "id": 3333,
    "zip_code": 762017,
    "dane_code": 76041000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "ANSERMANUEVO"
  },
  {
    "id": 3334,
    "zip_code": 762018,
    "dane_code": 76041000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "ANSERMANUEVO"
  },
  {
    "id": 3335,
    "zip_code": 762021,
    "dane_code": 76147000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CARTAGO"
  },
  {
    "id": 3336,
    "zip_code": 762022,
    "dane_code": 76147000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CARTAGO"
  },
  {
    "id": 3337,
    "zip_code": 762027,
    "dane_code": 76147000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CARTAGO"
  },
  {
    "id": 3338,
    "zip_code": 762030,
    "dane_code": 76845000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "ULLOA"
  },
  {
    "id": 3339,
    "zip_code": 762037,
    "dane_code": 76845000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "ULLOA"
  },
  {
    "id": 3340,
    "zip_code": 762040,
    "dane_code": 76020000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "ALCALA"
  },
  {
    "id": 3341,
    "zip_code": 762047,
    "dane_code": 76020000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "ALCALA"
  },
  {
    "id": 3342,
    "zip_code": 762501,
    "dane_code": 76497000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "OBANDO"
  },
  {
    "id": 3343,
    "zip_code": 762507,
    "dane_code": 76497000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "OBANDO"
  },
  {
    "id": 3344,
    "zip_code": 762510,
    "dane_code": 76403000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "LA VICTORIA"
  },
  {
    "id": 3345,
    "zip_code": 762517,
    "dane_code": 76403000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "LA VICTORIA"
  },
  {
    "id": 3346,
    "zip_code": 762520,
    "dane_code": 76895000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "ZARZAL"
  },
  {
    "id": 3347,
    "zip_code": 762527,
    "dane_code": 76895000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "ZARZAL"
  },
  {
    "id": 3348,
    "zip_code": 762528,
    "dane_code": 76895000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "ZARZAL"
  },
  {
    "id": 3349,
    "zip_code": 762530,
    "dane_code": 76736000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "SEVILLA"
  },
  {
    "id": 3350,
    "zip_code": 762537,
    "dane_code": 76736000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "SEVILLA"
  },
  {
    "id": 3351,
    "zip_code": 762538,
    "dane_code": 76736000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "SEVILLA"
  },
  {
    "id": 3352,
    "zip_code": 762540,
    "dane_code": 76122000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CAICEDONIA"
  },
  {
    "id": 3353,
    "zip_code": 762547,
    "dane_code": 76122000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CAICEDONIA"
  },
  {
    "id": 3354,
    "zip_code": 762548,
    "dane_code": 76122000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CAICEDONIA"
  },
  {
    "id": 3355,
    "zip_code": 763001,
    "dane_code": 76113000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "BUGALAGRANDE"
  },
  {
    "id": 3356,
    "zip_code": 763007,
    "dane_code": 76113000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "BUGALAGRANDE"
  },
  {
    "id": 3357,
    "zip_code": 763008,
    "dane_code": 76113000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "BUGALAGRANDE"
  },
  {
    "id": 3358,
    "zip_code": 763010,
    "dane_code": 76036000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "ANDALUCIA"
  },
  {
    "id": 3359,
    "zip_code": 763017,
    "dane_code": 76036000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "ANDALUCIA"
  },
  {
    "id": 3360,
    "zip_code": 763021,
    "dane_code": 76834000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "TULUA"
  },
  {
    "id": 3361,
    "zip_code": 763022,
    "dane_code": 76834000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "TULUA"
  },
  {
    "id": 3362,
    "zip_code": 763023,
    "dane_code": 76834000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "TULUA"
  },
  {
    "id": 3363,
    "zip_code": 763027,
    "dane_code": 76834000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "TULUA"
  },
  {
    "id": 3364,
    "zip_code": 763028,
    "dane_code": 76834000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "TULUA"
  },
  {
    "id": 3365,
    "zip_code": 763029,
    "dane_code": 76834000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "TULUA"
  },
  {
    "id": 3366,
    "zip_code": 763030,
    "dane_code": 76670000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "SAN PEDRO"
  },
  {
    "id": 3367,
    "zip_code": 763037,
    "dane_code": 76670000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "SAN PEDRO"
  },
  {
    "id": 3368,
    "zip_code": 763038,
    "dane_code": 76670000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "SAN PEDRO"
  },
  {
    "id": 3369,
    "zip_code": 763041,
    "dane_code": 76111000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "GUADALAJARA DE BUGA"
  },
  {
    "id": 3370,
    "zip_code": 763042,
    "dane_code": 76111000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "GUADALAJARA DE BUGA"
  },
  {
    "id": 3371,
    "zip_code": 763047,
    "dane_code": 76111000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "GUADALAJARA DE BUGA"
  },
  {
    "id": 3372,
    "zip_code": 763048,
    "dane_code": 76111000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "GUADALAJARA DE BUGA"
  },
  {
    "id": 3373,
    "zip_code": 763049,
    "dane_code": 76111000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "GUADALAJARA DE BUGA"
  },
  {
    "id": 3374,
    "zip_code": 763501,
    "dane_code": 76318000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "GUACARI"
  },
  {
    "id": 3375,
    "zip_code": 763507,
    "dane_code": 76318000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "GUACARI"
  },
  {
    "id": 3376,
    "zip_code": 763508,
    "dane_code": 76318000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "GUACARI"
  },
  {
    "id": 3377,
    "zip_code": 763510,
    "dane_code": 76306000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "GINEBRA"
  },
  {
    "id": 3378,
    "zip_code": 763517,
    "dane_code": 76306000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "GINEBRA"
  },
  {
    "id": 3379,
    "zip_code": 763518,
    "dane_code": 76306000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "GINEBRA"
  },
  {
    "id": 3380,
    "zip_code": 763520,
    "dane_code": 76248000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "EL CERRITO"
  },
  {
    "id": 3381,
    "zip_code": 763527,
    "dane_code": 76248000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "EL CERRITO"
  },
  {
    "id": 3382,
    "zip_code": 763528,
    "dane_code": 76248000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "EL CERRITO"
  },
  {
    "id": 3383,
    "zip_code": 763531,
    "dane_code": 76520000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "PALMIRA"
  },
  {
    "id": 3384,
    "zip_code": 763532,
    "dane_code": 76520000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "PALMIRA"
  },
  {
    "id": 3385,
    "zip_code": 763533,
    "dane_code": 76520000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "PALMIRA"
  },
  {
    "id": 3386,
    "zip_code": 763537,
    "dane_code": 76520000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "PALMIRA"
  },
  {
    "id": 3387,
    "zip_code": 763538,
    "dane_code": 76520000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "PALMIRA"
  },
  {
    "id": 3388,
    "zip_code": 763539,
    "dane_code": 76520000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "PALMIRA"
  },
  {
    "id": 3389,
    "zip_code": 763547,
    "dane_code": 76520000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "PALMIRA"
  },
  {
    "id": 3390,
    "zip_code": 763550,
    "dane_code": 76563000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "PRADERA"
  },
  {
    "id": 3391,
    "zip_code": 763557,
    "dane_code": 76563000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "PRADERA"
  },
  {
    "id": 3392,
    "zip_code": 763558,
    "dane_code": 76563000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "PRADERA"
  },
  {
    "id": 3393,
    "zip_code": 763560,
    "dane_code": 76275000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "FLORIDA"
  },
  {
    "id": 3394,
    "zip_code": 763567,
    "dane_code": 76275000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "FLORIDA"
  },
  {
    "id": 3395,
    "zip_code": 763568,
    "dane_code": 76275000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "FLORIDA"
  },
  {
    "id": 3396,
    "zip_code": 763570,
    "dane_code": 76130000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CANDELARIA"
  },
  {
    "id": 3397,
    "zip_code": 763577,
    "dane_code": 76130000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CANDELARIA"
  },
  {
    "id": 3398,
    "zip_code": 763578,
    "dane_code": 76130000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CANDELARIA"
  },
  {
    "id": 3399,
    "zip_code": 763579,
    "dane_code": 76130000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "CANDELARIA"
  },
  {
    "id": 3400,
    "zip_code": 764001,
    "dane_code": 76364000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "JAMUNDI"
  },
  {
    "id": 3401,
    "zip_code": 764007,
    "dane_code": 76364000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "JAMUNDI"
  },
  {
    "id": 3402,
    "zip_code": 764008,
    "dane_code": 76364000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "JAMUNDI"
  },
  {
    "id": 3403,
    "zip_code": 764501,
    "dane_code": 76109000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "BUENAVENTURA"
  },
  {
    "id": 3404,
    "zip_code": 764502,
    "dane_code": 76109000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "BUENAVENTURA"
  },
  {
    "id": 3405,
    "zip_code": 764503,
    "dane_code": 76109000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "BUENAVENTURA"
  },
  {
    "id": 3406,
    "zip_code": 764504,
    "dane_code": 76109000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "BUENAVENTURA"
  },
  {
    "id": 3407,
    "zip_code": 764507,
    "dane_code": 76109000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "BUENAVENTURA"
  },
  {
    "id": 3408,
    "zip_code": 764508,
    "dane_code": 76109000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "BUENAVENTURA"
  },
  {
    "id": 3409,
    "zip_code": 764509,
    "dane_code": 76109000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "BUENAVENTURA"
  },
  {
    "id": 3410,
    "zip_code": 764517,
    "dane_code": 76109000,
    "name_depto": "VALLE DEL CAUCA",
    "name_city": "BUENAVENTURA"
  },
  {
    "id": 3411,
    "zip_code": 810001,
    "dane_code": 81001000,
    "name_depto": "ARAUCA",
    "name_city": "ARAUCA"
  },
  {
    "id": 3412,
    "zip_code": 810007,
    "dane_code": 81001000,
    "name_depto": "ARAUCA",
    "name_city": "ARAUCA"
  },
  {
    "id": 3413,
    "zip_code": 810008,
    "dane_code": 81001000,
    "name_depto": "ARAUCA",
    "name_city": "ARAUCA"
  },
  {
    "id": 3414,
    "zip_code": 810009,
    "dane_code": 81001000,
    "name_depto": "ARAUCA",
    "name_city": "ARAUCA"
  },
  {
    "id": 3415,
    "zip_code": 812010,
    "dane_code": 81220000,
    "name_depto": "ARAUCA",
    "name_city": "CRAVO NORTE"
  },
  {
    "id": 3416,
    "zip_code": 812017,
    "dane_code": 81220000,
    "name_depto": "ARAUCA",
    "name_city": "CRAVO NORTE"
  },
  {
    "id": 3417,
    "zip_code": 812018,
    "dane_code": 81220000,
    "name_depto": "ARAUCA",
    "name_city": "CRAVO NORTE"
  },
  {
    "id": 3418,
    "zip_code": 812019,
    "dane_code": 81220000,
    "name_depto": "ARAUCA",
    "name_city": "CRAVO NORTE"
  },
  {
    "id": 3419,
    "zip_code": 813010,
    "dane_code": 81591000,
    "name_depto": "ARAUCA",
    "name_city": "PUERTO RONDON"
  },
  {
    "id": 3420,
    "zip_code": 813017,
    "dane_code": 81591000,
    "name_depto": "ARAUCA",
    "name_city": "PUERTO RONDON"
  },
  {
    "id": 3421,
    "zip_code": 813018,
    "dane_code": 81591000,
    "name_depto": "ARAUCA",
    "name_city": "PUERTO RONDON"
  },
  {
    "id": 3422,
    "zip_code": 814010,
    "dane_code": 81794000,
    "name_depto": "ARAUCA",
    "name_city": "TAME"
  },
  {
    "id": 3423,
    "zip_code": 814017,
    "dane_code": 81794000,
    "name_depto": "ARAUCA",
    "name_city": "TAME"
  },
  {
    "id": 3424,
    "zip_code": 814018,
    "dane_code": 81794000,
    "name_depto": "ARAUCA",
    "name_city": "TAME"
  },
  {
    "id": 3425,
    "zip_code": 814050,
    "dane_code": 81300000,
    "name_depto": "ARAUCA",
    "name_city": "FORTUL"
  },
  {
    "id": 3426,
    "zip_code": 814057,
    "dane_code": 81300000,
    "name_depto": "ARAUCA",
    "name_city": "FORTUL"
  },
  {
    "id": 3427,
    "zip_code": 814058,
    "dane_code": 81300000,
    "name_depto": "ARAUCA",
    "name_city": "FORTUL"
  },
  {
    "id": 3428,
    "zip_code": 815010,
    "dane_code": 81736000,
    "name_depto": "ARAUCA",
    "name_city": "SARAVENA"
  },
  {
    "id": 3429,
    "zip_code": 815017,
    "dane_code": 81736000,
    "name_depto": "ARAUCA",
    "name_city": "SARAVENA"
  },
  {
    "id": 3430,
    "zip_code": 815018,
    "dane_code": 81736000,
    "name_depto": "ARAUCA",
    "name_city": "SARAVENA"
  },
  {
    "id": 3431,
    "zip_code": 816010,
    "dane_code": 81065000,
    "name_depto": "ARAUCA",
    "name_city": "ARAUQUITA"
  },
  {
    "id": 3432,
    "zip_code": 816017,
    "dane_code": 81065000,
    "name_depto": "ARAUCA",
    "name_city": "ARAUQUITA"
  },
  {
    "id": 3433,
    "zip_code": 816018,
    "dane_code": 81065000,
    "name_depto": "ARAUCA",
    "name_city": "ARAUQUITA"
  },
  {
    "id": 3434,
    "zip_code": 816019,
    "dane_code": 81065000,
    "name_depto": "ARAUCA",
    "name_city": "ARAUQUITA"
  },
  {
    "id": 3435,
    "zip_code": 850001,
    "dane_code": 85001000,
    "name_depto": "CASANARE",
    "name_city": "YOPAL"
  },
  {
    "id": 3436,
    "zip_code": 850002,
    "dane_code": 85001000,
    "name_depto": "CASANARE",
    "name_city": "YOPAL"
  },
  {
    "id": 3437,
    "zip_code": 850007,
    "dane_code": 85001000,
    "name_depto": "CASANARE",
    "name_city": "YOPAL"
  },
  {
    "id": 3438,
    "zip_code": 850008,
    "dane_code": 85001000,
    "name_depto": "CASANARE",
    "name_city": "YOPAL"
  },
  {
    "id": 3439,
    "zip_code": 850009,
    "dane_code": 85001000,
    "name_depto": "CASANARE",
    "name_city": "YOPAL"
  },
  {
    "id": 3440,
    "zip_code": 851010,
    "dane_code": 85136000,
    "name_depto": "CASANARE",
    "name_city": "LA SALINA"
  },
  {
    "id": 3441,
    "zip_code": 851017,
    "dane_code": 85136000,
    "name_depto": "CASANARE",
    "name_city": "LA SALINA"
  },
  {
    "id": 3442,
    "zip_code": 851030,
    "dane_code": 85315000,
    "name_depto": "CASANARE",
    "name_city": "SACAMA"
  },
  {
    "id": 3443,
    "zip_code": 851037,
    "dane_code": 85315000,
    "name_depto": "CASANARE",
    "name_city": "SACAMA"
  },
  {
    "id": 3444,
    "zip_code": 851038,
    "dane_code": 85315000,
    "name_depto": "CASANARE",
    "name_city": "SACAMA"
  },
  {
    "id": 3445,
    "zip_code": 851050,
    "dane_code": 85400000,
    "name_depto": "CASANARE",
    "name_city": "TAMARA"
  },
  {
    "id": 3446,
    "zip_code": 851057,
    "dane_code": 85400000,
    "name_depto": "CASANARE",
    "name_city": "TAMARA"
  },
  {
    "id": 3447,
    "zip_code": 851058,
    "dane_code": 85400000,
    "name_depto": "CASANARE",
    "name_city": "TAMARA"
  },
  {
    "id": 3448,
    "zip_code": 851070,
    "dane_code": 85225000,
    "name_depto": "CASANARE",
    "name_city": "NUNCHIA"
  },
  {
    "id": 3449,
    "zip_code": 851077,
    "dane_code": 85225000,
    "name_depto": "CASANARE",
    "name_city": "NUNCHIA"
  },
  {
    "id": 3450,
    "zip_code": 851078,
    "dane_code": 85225000,
    "name_depto": "CASANARE",
    "name_city": "NUNCHIA"
  },
  {
    "id": 3451,
    "zip_code": 852010,
    "dane_code": 85125000,
    "name_depto": "CASANARE",
    "name_city": "HATO COROZAL"
  },
  {
    "id": 3452,
    "zip_code": 852017,
    "dane_code": 85125000,
    "name_depto": "CASANARE",
    "name_city": "HATO COROZAL"
  },
  {
    "id": 3453,
    "zip_code": 852018,
    "dane_code": 85125000,
    "name_depto": "CASANARE",
    "name_city": "HATO COROZAL"
  },
  {
    "id": 3454,
    "zip_code": 852019,
    "dane_code": 85125000,
    "name_depto": "CASANARE",
    "name_city": "HATO COROZAL"
  },
  {
    "id": 3455,
    "zip_code": 852030,
    "dane_code": 85250000,
    "name_depto": "CASANARE",
    "name_city": "PAZ DE ARIPORO"
  },
  {
    "id": 3456,
    "zip_code": 852037,
    "dane_code": 85250000,
    "name_depto": "CASANARE",
    "name_city": "PAZ DE ARIPORO"
  },
  {
    "id": 3457,
    "zip_code": 852038,
    "dane_code": 85250000,
    "name_depto": "CASANARE",
    "name_city": "PAZ DE ARIPORO"
  },
  {
    "id": 3458,
    "zip_code": 852039,
    "dane_code": 85250000,
    "name_depto": "CASANARE",
    "name_city": "PAZ DE ARIPORO"
  },
  {
    "id": 3459,
    "zip_code": 852050,
    "dane_code": 85263000,
    "name_depto": "CASANARE",
    "name_city": "PORE"
  },
  {
    "id": 3460,
    "zip_code": 852057,
    "dane_code": 85263000,
    "name_depto": "CASANARE",
    "name_city": "PORE"
  },
  {
    "id": 3461,
    "zip_code": 852058,
    "dane_code": 85263000,
    "name_depto": "CASANARE",
    "name_city": "PORE"
  },
  {
    "id": 3462,
    "zip_code": 853010,
    "dane_code": 85430000,
    "name_depto": "CASANARE",
    "name_city": "TRINIDAD"
  },
  {
    "id": 3463,
    "zip_code": 853017,
    "dane_code": 85430000,
    "name_depto": "CASANARE",
    "name_city": "TRINIDAD"
  },
  {
    "id": 3464,
    "zip_code": 853018,
    "dane_code": 85430000,
    "name_depto": "CASANARE",
    "name_city": "TRINIDAD"
  },
  {
    "id": 3465,
    "zip_code": 853019,
    "dane_code": 85430000,
    "name_depto": "CASANARE",
    "name_city": "TRINIDAD"
  },
  {
    "id": 3466,
    "zip_code": 853030,
    "dane_code": 85325000,
    "name_depto": "CASANARE",
    "name_city": "SAN LUIS DE PALENQUE"
  },
  {
    "id": 3467,
    "zip_code": 853037,
    "dane_code": 85325000,
    "name_depto": "CASANARE",
    "name_city": "SAN LUIS DE PALENQUE"
  },
  {
    "id": 3468,
    "zip_code": 853038,
    "dane_code": 85325000,
    "name_depto": "CASANARE",
    "name_city": "SAN LUIS DE PALENQUE"
  },
  {
    "id": 3469,
    "zip_code": 853039,
    "dane_code": 85325000,
    "name_depto": "CASANARE",
    "name_city": "SAN LUIS DE PALENQUE"
  },
  {
    "id": 3470,
    "zip_code": 853050,
    "dane_code": 85230000,
    "name_depto": "CASANARE",
    "name_city": "OROCUE"
  },
  {
    "id": 3471,
    "zip_code": 853057,
    "dane_code": 85230000,
    "name_depto": "CASANARE",
    "name_city": "OROCUE"
  },
  {
    "id": 3472,
    "zip_code": 853058,
    "dane_code": 85230000,
    "name_depto": "CASANARE",
    "name_city": "OROCUE"
  },
  {
    "id": 3473,
    "zip_code": 853059,
    "dane_code": 85230000,
    "name_depto": "CASANARE",
    "name_city": "OROCUE"
  },
  {
    "id": 3474,
    "zip_code": 854010,
    "dane_code": 85139000,
    "name_depto": "CASANARE",
    "name_city": "MANI"
  },
  {
    "id": 3475,
    "zip_code": 854017,
    "dane_code": 85139000,
    "name_depto": "CASANARE",
    "name_city": "MANI"
  },
  {
    "id": 3476,
    "zip_code": 854018,
    "dane_code": 85139000,
    "name_depto": "CASANARE",
    "name_city": "MANI"
  },
  {
    "id": 3477,
    "zip_code": 854019,
    "dane_code": 85139000,
    "name_depto": "CASANARE",
    "name_city": "MANI"
  },
  {
    "id": 3478,
    "zip_code": 854030,
    "dane_code": 85410000,
    "name_depto": "CASANARE",
    "name_city": "TAURAMENA"
  },
  {
    "id": 3479,
    "zip_code": 854037,
    "dane_code": 85410000,
    "name_depto": "CASANARE",
    "name_city": "TAURAMENA"
  },
  {
    "id": 3480,
    "zip_code": 854038,
    "dane_code": 85410000,
    "name_depto": "CASANARE",
    "name_city": "TAURAMENA"
  },
  {
    "id": 3481,
    "zip_code": 854039,
    "dane_code": 85410000,
    "name_depto": "CASANARE",
    "name_city": "TAURAMENA"
  },
  {
    "id": 3482,
    "zip_code": 855010,
    "dane_code": 85162000,
    "name_depto": "CASANARE",
    "name_city": "MONTERREY"
  },
  {
    "id": 3483,
    "zip_code": 855017,
    "dane_code": 85162000,
    "name_depto": "CASANARE",
    "name_city": "MONTERREY"
  },
  {
    "id": 3484,
    "zip_code": 855018,
    "dane_code": 85162000,
    "name_depto": "CASANARE",
    "name_city": "MONTERREY"
  },
  {
    "id": 3485,
    "zip_code": 855030,
    "dane_code": 85440000,
    "name_depto": "CASANARE",
    "name_city": "VILLANUEVA"
  },
  {
    "id": 3486,
    "zip_code": 855037,
    "dane_code": 85440000,
    "name_depto": "CASANARE",
    "name_city": "VILLANUEVA"
  },
  {
    "id": 3487,
    "zip_code": 855038,
    "dane_code": 85440000,
    "name_depto": "CASANARE",
    "name_city": "VILLANUEVA"
  },
  {
    "id": 3488,
    "zip_code": 855039,
    "dane_code": 85440000,
    "name_depto": "CASANARE",
    "name_city": "VILLANUEVA"
  },
  {
    "id": 3489,
    "zip_code": 855050,
    "dane_code": 85300000,
    "name_depto": "CASANARE",
    "name_city": "SABANALARGA"
  },
  {
    "id": 3490,
    "zip_code": 855057,
    "dane_code": 85300000,
    "name_depto": "CASANARE",
    "name_city": "SABANALARGA"
  },
  {
    "id": 3491,
    "zip_code": 855058,
    "dane_code": 85300000,
    "name_depto": "CASANARE",
    "name_city": "SABANALARGA"
  },
  {
    "id": 3492,
    "zip_code": 856010,
    "dane_code": 85010000,
    "name_depto": "CASANARE",
    "name_city": "AGUAZUL"
  },
  {
    "id": 3493,
    "zip_code": 856017,
    "dane_code": 85010000,
    "name_depto": "CASANARE",
    "name_city": "AGUAZUL"
  },
  {
    "id": 3494,
    "zip_code": 856018,
    "dane_code": 85010000,
    "name_depto": "CASANARE",
    "name_city": "AGUAZUL"
  },
  {
    "id": 3495,
    "zip_code": 856019,
    "dane_code": 85010000,
    "name_depto": "CASANARE",
    "name_city": "AGUAZUL"
  },
  {
    "id": 3496,
    "zip_code": 856030,
    "dane_code": 85015000,
    "name_depto": "CASANARE",
    "name_city": "CHAMEZA"
  },
  {
    "id": 3497,
    "zip_code": 856037,
    "dane_code": 85015000,
    "name_depto": "CASANARE",
    "name_city": "CHAMEZA"
  },
  {
    "id": 3498,
    "zip_code": 856038,
    "dane_code": 85015000,
    "name_depto": "CASANARE",
    "name_city": "CHAMEZA"
  },
  {
    "id": 3499,
    "zip_code": 856050,
    "dane_code": 85279000,
    "name_depto": "CASANARE",
    "name_city": "RECETOR"
  },
  {
    "id": 3500,
    "zip_code": 856057,
    "dane_code": 85279000,
    "name_depto": "CASANARE",
    "name_city": "RECETOR"
  },
  {
    "id": 3501,
    "zip_code": 856058,
    "dane_code": 85279000,
    "name_depto": "CASANARE",
    "name_city": "RECETOR"
  },
  {
    "id": 3502,
    "zip_code": 860001,
    "dane_code": 86001000,
    "name_depto": "PUTUMAYO",
    "name_city": "MOCOA"
  },
  {
    "id": 3503,
    "zip_code": 860007,
    "dane_code": 86001000,
    "name_depto": "PUTUMAYO",
    "name_city": "MOCOA"
  },
  {
    "id": 3504,
    "zip_code": 860008,
    "dane_code": 86001000,
    "name_depto": "PUTUMAYO",
    "name_city": "MOCOA"
  },
  {
    "id": 3505,
    "zip_code": 861001,
    "dane_code": 86755000,
    "name_depto": "PUTUMAYO",
    "name_city": "SAN FRANCISCO"
  },
  {
    "id": 3506,
    "zip_code": 861007,
    "dane_code": 86755000,
    "name_depto": "PUTUMAYO",
    "name_city": "SAN FRANCISCO"
  },
  {
    "id": 3507,
    "zip_code": 861008,
    "dane_code": 86755000,
    "name_depto": "PUTUMAYO",
    "name_city": "SAN FRANCISCO"
  },
  {
    "id": 3508,
    "zip_code": 861020,
    "dane_code": 86749000,
    "name_depto": "PUTUMAYO",
    "name_city": "SIBUNDOY"
  },
  {
    "id": 3509,
    "zip_code": 861027,
    "dane_code": 86749000,
    "name_depto": "PUTUMAYO",
    "name_city": "SIBUNDOY"
  },
  {
    "id": 3510,
    "zip_code": 861040,
    "dane_code": 86219000,
    "name_depto": "PUTUMAYO",
    "name_city": "COLON"
  },
  {
    "id": 3511,
    "zip_code": 861047,
    "dane_code": 86219000,
    "name_depto": "PUTUMAYO",
    "name_city": "COLON"
  },
  {
    "id": 3512,
    "zip_code": 861060,
    "dane_code": 86760000,
    "name_depto": "PUTUMAYO",
    "name_city": "SANTIAGO"
  },
  {
    "id": 3513,
    "zip_code": 861067,
    "dane_code": 86760000,
    "name_depto": "PUTUMAYO",
    "name_city": "SANTIAGO"
  },
  {
    "id": 3514,
    "zip_code": 861068,
    "dane_code": 86760000,
    "name_depto": "PUTUMAYO",
    "name_city": "SANTIAGO"
  },
  {
    "id": 3515,
    "zip_code": 861080,
    "dane_code": 86885000,
    "name_depto": "PUTUMAYO",
    "name_city": "VILLAGARZON"
  },
  {
    "id": 3516,
    "zip_code": 861087,
    "dane_code": 86885000,
    "name_depto": "PUTUMAYO",
    "name_city": "VILLAGARZON"
  },
  {
    "id": 3517,
    "zip_code": 861088,
    "dane_code": 86885000,
    "name_depto": "PUTUMAYO",
    "name_city": "VILLAGARZON"
  },
  {
    "id": 3518,
    "zip_code": 862001,
    "dane_code": 86320000,
    "name_depto": "PUTUMAYO",
    "name_city": "ORITO"
  },
  {
    "id": 3519,
    "zip_code": 862007,
    "dane_code": 86320000,
    "name_depto": "PUTUMAYO",
    "name_city": "ORITO"
  },
  {
    "id": 3520,
    "zip_code": 862008,
    "dane_code": 86320000,
    "name_depto": "PUTUMAYO",
    "name_city": "ORITO"
  },
  {
    "id": 3521,
    "zip_code": 862020,
    "dane_code": 86865000,
    "name_depto": "PUTUMAYO",
    "name_city": "VALLE DEL GUAMUEZ"
  },
  {
    "id": 3522,
    "zip_code": 862027,
    "dane_code": 86865000,
    "name_depto": "PUTUMAYO",
    "name_city": "VALLE DEL GUAMUEZ"
  },
  {
    "id": 3523,
    "zip_code": 862028,
    "dane_code": 86865000,
    "name_depto": "PUTUMAYO",
    "name_city": "VALLE DEL GUAMUEZ"
  },
  {
    "id": 3524,
    "zip_code": 862040,
    "dane_code": 86757000,
    "name_depto": "PUTUMAYO",
    "name_city": "SAN MIGUEL"
  },
  {
    "id": 3525,
    "zip_code": 862047,
    "dane_code": 86757000,
    "name_depto": "PUTUMAYO",
    "name_city": "SAN MIGUEL"
  },
  {
    "id": 3526,
    "zip_code": 862060,
    "dane_code": 86568000,
    "name_depto": "PUTUMAYO",
    "name_city": "PUERTO ASIS"
  },
  {
    "id": 3527,
    "zip_code": 862067,
    "dane_code": 86568000,
    "name_depto": "PUTUMAYO",
    "name_city": "PUERTO ASIS"
  },
  {
    "id": 3528,
    "zip_code": 862068,
    "dane_code": 86568000,
    "name_depto": "PUTUMAYO",
    "name_city": "PUERTO ASIS"
  },
  {
    "id": 3529,
    "zip_code": 862069,
    "dane_code": 86568000,
    "name_depto": "PUTUMAYO",
    "name_city": "PUERTO ASIS"
  },
  {
    "id": 3530,
    "zip_code": 862080,
    "dane_code": 86569000,
    "name_depto": "PUTUMAYO",
    "name_city": "PUERTO CAICEDO"
  },
  {
    "id": 3531,
    "zip_code": 862087,
    "dane_code": 86569000,
    "name_depto": "PUTUMAYO",
    "name_city": "PUERTO CAICEDO"
  },
  {
    "id": 3532,
    "zip_code": 863001,
    "dane_code": 86571000,
    "name_depto": "PUTUMAYO",
    "name_city": "PUERTO GUZMAN"
  },
  {
    "id": 3533,
    "zip_code": 863007,
    "dane_code": 86571000,
    "name_depto": "PUTUMAYO",
    "name_city": "PUERTO GUZMAN"
  },
  {
    "id": 3534,
    "zip_code": 863008,
    "dane_code": 86571000,
    "name_depto": "PUTUMAYO",
    "name_city": "PUERTO GUZMAN"
  },
  {
    "id": 3535,
    "zip_code": 864001,
    "dane_code": 86573000,
    "name_depto": "PUTUMAYO",
    "name_city": "LEGUIZAMO"
  },
  {
    "id": 3536,
    "zip_code": 864007,
    "dane_code": 86573000,
    "name_depto": "PUTUMAYO",
    "name_city": "LEGUIZAMO"
  },
  {
    "id": 3537,
    "zip_code": 864008,
    "dane_code": 86573000,
    "name_depto": "PUTUMAYO",
    "name_city": "LEGUIZAMO"
  },
  {
    "id": 3538,
    "zip_code": 864009,
    "dane_code": 86573000,
    "name_depto": "PUTUMAYO",
    "name_city": "LEGUIZAMO"
  },
  {
    "id": 3539,
    "zip_code": 880001,
    "dane_code": 88001000,
    "name_depto": "ARCHIPIELAGO DE SAN ANDRES",
    "name_city": "SAN ANDRES"
  },
  {
    "id": 3540,
    "zip_code": 880007,
    "dane_code": 88001000,
    "name_depto": "ARCHIPIELAGO DE SAN ANDRES",
    "name_city": "SAN ANDRES"
  },
  {
    "id": 3541,
    "zip_code": 880008,
    "dane_code": 88001000,
    "name_depto": "ARCHIPIELAGO DE SAN ANDRES",
    "name_city": "SAN ANDRES"
  },
  {
    "id": 3542,
    "zip_code": 880020,
    "dane_code": 88564000,
    "name_depto": "ARCHIPIELAGO DE SAN ANDRES",
    "name_city": "PROVIDENCIA"
  },
  {
    "id": 3543,
    "zip_code": 880027,
    "dane_code": 88564000,
    "name_depto": "ARCHIPIELAGO DE SAN ANDRES",
    "name_city": "PROVIDENCIA"
  },
  {
    "id": 3544,
    "zip_code": 880028,
    "dane_code": 88564000,
    "name_depto": "ARCHIPIELAGO DE SAN ANDRES",
    "name_city": "PROVIDENCIA"
  },
  {
    "id": 3545,
    "zip_code": 910001,
    "dane_code": 91001000,
    "name_depto": "AMAZONAS",
    "name_city": "LETICIA"
  },
  {
    "id": 3546,
    "zip_code": 910007,
    "dane_code": 91001000,
    "name_depto": "AMAZONAS",
    "name_city": "LETICIA"
  },
  {
    "id": 3547,
    "zip_code": 910008,
    "dane_code": 91001000,
    "name_depto": "AMAZONAS",
    "name_city": "LETICIA"
  },
  {
    "id": 3548,
    "zip_code": 911010,
    "dane_code": 91540000,
    "name_depto": "AMAZONAS",
    "name_city": "PUERTO NARI√ëO"
  },
  {
    "id": 3549,
    "zip_code": 911017,
    "dane_code": 91540000,
    "name_depto": "AMAZONAS",
    "name_city": "PUERTO NARI√ëO"
  },
  {
    "id": 3550,
    "zip_code": 911018,
    "dane_code": 91540000,
    "name_depto": "AMAZONAS",
    "name_city": "PUERTO NARI√ëO"
  },
  {
    "id": 3551,
    "zip_code": 911030,
    "dane_code": 91798000,
    "name_depto": "AMAZONAS",
    "name_city": "TARAPACA"
  },
  {
    "id": 3552,
    "zip_code": 911037,
    "dane_code": 91798000,
    "name_depto": "AMAZONAS",
    "name_city": "TARAPACA"
  },
  {
    "id": 3553,
    "zip_code": 911038,
    "dane_code": 91798000,
    "name_depto": "AMAZONAS",
    "name_city": "TARAPACA"
  },
  {
    "id": 3554,
    "zip_code": 911039,
    "dane_code": 91798000,
    "name_depto": "AMAZONAS",
    "name_city": "TARAPACA"
  },
  {
    "id": 3555,
    "zip_code": 912010,
    "dane_code": 91536000,
    "name_depto": "AMAZONAS",
    "name_city": "PUERTO ARICA"
  },
  {
    "id": 3556,
    "zip_code": 912017,
    "dane_code": 91536000,
    "name_depto": "AMAZONAS",
    "name_city": "PUERTO ARICA"
  },
  {
    "id": 3557,
    "zip_code": 912018,
    "dane_code": 91536000,
    "name_depto": "AMAZONAS",
    "name_city": "PUERTO ARICA"
  },
  {
    "id": 3558,
    "zip_code": 912019,
    "dane_code": 91536000,
    "name_depto": "AMAZONAS",
    "name_city": "PUERTO ARICA"
  },
  {
    "id": 3559,
    "zip_code": 913010,
    "dane_code": 91263000,
    "name_depto": "AMAZONAS",
    "name_city": "EL ENCANTO"
  },
  {
    "id": 3560,
    "zip_code": 913017,
    "dane_code": 91263000,
    "name_depto": "AMAZONAS",
    "name_city": "EL ENCANTO"
  },
  {
    "id": 3561,
    "zip_code": 913018,
    "dane_code": 91263000,
    "name_depto": "AMAZONAS",
    "name_city": "EL ENCANTO"
  },
  {
    "id": 3562,
    "zip_code": 913019,
    "dane_code": 91263000,
    "name_depto": "AMAZONAS",
    "name_city": "EL ENCANTO"
  },
  {
    "id": 3563,
    "zip_code": 913050,
    "dane_code": 91530000,
    "name_depto": "AMAZONAS",
    "name_city": "PUERTO ALEGRIA"
  },
  {
    "id": 3564,
    "zip_code": 913057,
    "dane_code": 91530000,
    "name_depto": "AMAZONAS",
    "name_city": "PUERTO ALEGRIA"
  },
  {
    "id": 3565,
    "zip_code": 914050,
    "dane_code": 91405000,
    "name_depto": "AMAZONAS",
    "name_city": "LA CHORRERA"
  },
  {
    "id": 3566,
    "zip_code": 914057,
    "dane_code": 91405000,
    "name_depto": "AMAZONAS",
    "name_city": "LA CHORRERA"
  },
  {
    "id": 3567,
    "zip_code": 915010,
    "dane_code": 91669000,
    "name_depto": "AMAZONAS",
    "name_city": "PUERTO SANTANDER"
  },
  {
    "id": 3568,
    "zip_code": 915017,
    "dane_code": 91669000,
    "name_depto": "AMAZONAS",
    "name_city": "PUERTO SANTANDER"
  },
  {
    "id": 3569,
    "zip_code": 915018,
    "dane_code": 91669000,
    "name_depto": "AMAZONAS",
    "name_city": "PUERTO SANTANDER"
  },
  {
    "id": 3570,
    "zip_code": 915019,
    "dane_code": 91669000,
    "name_depto": "AMAZONAS",
    "name_city": "PUERTO SANTANDER"
  },
  {
    "id": 3571,
    "zip_code": 916017,
    "dane_code": 91430000,
    "name_depto": "AMAZONAS",
    "name_city": "LA VICTORIA"
  },
  {
    "id": 3572,
    "zip_code": 916057,
    "dane_code": 91460000,
    "name_depto": "AMAZONAS",
    "name_city": "MIRITI - PARANA"
  },
  {
    "id": 3573,
    "zip_code": 916058,
    "dane_code": 91460000,
    "name_depto": "AMAZONAS",
    "name_city": "MIRITI - PARANA"
  },
  {
    "id": 3574,
    "zip_code": 917010,
    "dane_code": 91407000,
    "name_depto": "AMAZONAS",
    "name_city": "LA PEDRERA"
  },
  {
    "id": 3575,
    "zip_code": 917017,
    "dane_code": 91407000,
    "name_depto": "AMAZONAS",
    "name_city": "LA PEDRERA"
  },
  {
    "id": 3576,
    "zip_code": 917018,
    "dane_code": 91407000,
    "name_depto": "AMAZONAS",
    "name_city": "LA PEDRERA"
  },
  {
    "id": 3577,
    "zip_code": 940001,
    "dane_code": 94001000,
    "name_depto": "GUAINIA",
    "name_city": "INIRIDA"
  },
  {
    "id": 3578,
    "zip_code": 940007,
    "dane_code": 94001000,
    "name_depto": "GUAINIA",
    "name_city": "INIRIDA"
  },
  {
    "id": 3579,
    "zip_code": 940008,
    "dane_code": 94001000,
    "name_depto": "GUAINIA",
    "name_city": "INIRIDA"
  },
  {
    "id": 3580,
    "zip_code": 940009,
    "dane_code": 94001000,
    "name_depto": "GUAINIA",
    "name_city": "INIRIDA"
  },
  {
    "id": 3581,
    "zip_code": 940017,
    "dane_code": 94001000,
    "name_depto": "GUAINIA",
    "name_city": "INIRIDA"
  },
  {
    "id": 3582,
    "zip_code": 940018,
    "dane_code": 94001000,
    "name_depto": "GUAINIA",
    "name_city": "INIRIDA"
  },
  {
    "id": 3583,
    "zip_code": 940019,
    "dane_code": 94001000,
    "name_depto": "GUAINIA",
    "name_city": "INIRIDA"
  },
  {
    "id": 3584,
    "zip_code": 940027,
    "dane_code": 94001000,
    "name_depto": "GUAINIA",
    "name_city": "INIRIDA"
  },
  {
    "id": 3585,
    "zip_code": 940028,
    "dane_code": 94001000,
    "name_depto": "GUAINIA",
    "name_city": "INIRIDA"
  },
  {
    "id": 3586,
    "zip_code": 941010,
    "dane_code": 94886000,
    "name_depto": "GUAINIA",
    "name_city": "CACAHUAL"
  },
  {
    "id": 3587,
    "zip_code": 941017,
    "dane_code": 94886000,
    "name_depto": "GUAINIA",
    "name_city": "CACAHUAL"
  },
  {
    "id": 3588,
    "zip_code": 941018,
    "dane_code": 94886000,
    "name_depto": "GUAINIA",
    "name_city": "CACAHUAL"
  },
  {
    "id": 3589,
    "zip_code": 941037,
    "dane_code": 94884000,
    "name_depto": "GUAINIA",
    "name_city": "PUERTO COLOMBIA"
  },
  {
    "id": 3590,
    "zip_code": 941038,
    "dane_code": 94884000,
    "name_depto": "GUAINIA",
    "name_city": "PUERTO COLOMBIA"
  },
  {
    "id": 3591,
    "zip_code": 941039,
    "dane_code": 94884000,
    "name_depto": "GUAINIA",
    "name_city": "PUERTO COLOMBIA"
  },
  {
    "id": 3592,
    "zip_code": 941047,
    "dane_code": 94884000,
    "name_depto": "GUAINIA",
    "name_city": "PUERTO COLOMBIA"
  },
  {
    "id": 3593,
    "zip_code": 942010,
    "dane_code": 94883000,
    "name_depto": "GUAINIA",
    "name_city": "SAN FELIPE"
  },
  {
    "id": 3594,
    "zip_code": 942017,
    "dane_code": 94883000,
    "name_depto": "GUAINIA",
    "name_city": "SAN FELIPE"
  },
  {
    "id": 3595,
    "zip_code": 942018,
    "dane_code": 94883000,
    "name_depto": "GUAINIA",
    "name_city": "SAN FELIPE"
  },
  {
    "id": 3596,
    "zip_code": 942057,
    "dane_code": 94885000,
    "name_depto": "GUAINIA",
    "name_city": "LA GUADALUPE"
  },
  {
    "id": 3597,
    "zip_code": 943017,
    "dane_code": 94887000,
    "name_depto": "GUAINIA",
    "name_city": "PANA PANA"
  },
  {
    "id": 3598,
    "zip_code": 943018,
    "dane_code": 94887000,
    "name_depto": "GUAINIA",
    "name_city": "PANA PANA"
  },
  {
    "id": 3599,
    "zip_code": 943019,
    "dane_code": 94887000,
    "name_depto": "GUAINIA",
    "name_city": "PANA PANA"
  },
  {
    "id": 3600,
    "zip_code": 943057,
    "dane_code": 94888000,
    "name_depto": "GUAINIA",
    "name_city": "MORICHAL"
  },
  {
    "id": 3601,
    "zip_code": 943058,
    "dane_code": 94888000,
    "name_depto": "GUAINIA",
    "name_city": "MORICHAL"
  },
  {
    "id": 3602,
    "zip_code": 943059,
    "dane_code": 94888000,
    "name_depto": "GUAINIA",
    "name_city": "MORICHAL"
  },
  {
    "id": 3603,
    "zip_code": 943067,
    "dane_code": 94888000,
    "name_depto": "GUAINIA",
    "name_city": "MORICHAL"
  },
  {
    "id": 3604,
    "zip_code": 944010,
    "dane_code": 94343000,
    "name_depto": "GUAINIA",
    "name_city": "BARRANCO MINAS"
  },
  {
    "id": 3605,
    "zip_code": 944017,
    "dane_code": 94343000,
    "name_depto": "GUAINIA",
    "name_city": "BARRANCO MINAS"
  },
  {
    "id": 3606,
    "zip_code": 944018,
    "dane_code": 94343000,
    "name_depto": "GUAINIA",
    "name_city": "BARRANCO MINAS"
  },
  {
    "id": 3607,
    "zip_code": 944019,
    "dane_code": 94343000,
    "name_depto": "GUAINIA",
    "name_city": "BARRANCO MINAS"
  },
  {
    "id": 3608,
    "zip_code": 944057,
    "dane_code": 94663000,
    "name_depto": "GUAINIA",
    "name_city": "MAPIRIPANA"
  },
  {
    "id": 3609,
    "zip_code": 944058,
    "dane_code": 94663000,
    "name_depto": "GUAINIA",
    "name_city": "MAPIRIPANA"
  },
  {
    "id": 3610,
    "zip_code": 944059,
    "dane_code": 94663000,
    "name_depto": "GUAINIA",
    "name_city": "MAPIRIPANA"
  },
  {
    "id": 3611,
    "zip_code": 950001,
    "dane_code": 95001000,
    "name_depto": "GUAVIARE",
    "name_city": "SAN JOSE DEL GUAVIARE"
  },
  {
    "id": 3612,
    "zip_code": 950007,
    "dane_code": 95001000,
    "name_depto": "GUAVIARE",
    "name_city": "SAN JOSE DEL GUAVIARE"
  },
  {
    "id": 3613,
    "zip_code": 950008,
    "dane_code": 95001000,
    "name_depto": "GUAVIARE",
    "name_city": "SAN JOSE DEL GUAVIARE"
  },
  {
    "id": 3614,
    "zip_code": 950009,
    "dane_code": 95001000,
    "name_depto": "GUAVIARE",
    "name_city": "SAN JOSE DEL GUAVIARE"
  },
  {
    "id": 3615,
    "zip_code": 951001,
    "dane_code": 95025000,
    "name_depto": "GUAVIARE",
    "name_city": "EL RETORNO"
  },
  {
    "id": 3616,
    "zip_code": 951007,
    "dane_code": 95025000,
    "name_depto": "GUAVIARE",
    "name_city": "EL RETORNO"
  },
  {
    "id": 3617,
    "zip_code": 951008,
    "dane_code": 95025000,
    "name_depto": "GUAVIARE",
    "name_city": "EL RETORNO"
  },
  {
    "id": 3618,
    "zip_code": 951009,
    "dane_code": 95025000,
    "name_depto": "GUAVIARE",
    "name_city": "EL RETORNO"
  },
  {
    "id": 3619,
    "zip_code": 951017,
    "dane_code": 95025000,
    "name_depto": "GUAVIARE",
    "name_city": "EL RETORNO"
  },
  {
    "id": 3620,
    "zip_code": 952001,
    "dane_code": 95200000,
    "name_depto": "GUAVIARE",
    "name_city": "MIRAFLORES"
  },
  {
    "id": 3621,
    "zip_code": 952007,
    "dane_code": 95200000,
    "name_depto": "GUAVIARE",
    "name_city": "MIRAFLORES"
  },
  {
    "id": 3622,
    "zip_code": 952008,
    "dane_code": 95200000,
    "name_depto": "GUAVIARE",
    "name_city": "MIRAFLORES"
  },
  {
    "id": 3623,
    "zip_code": 952009,
    "dane_code": 95200000,
    "name_depto": "GUAVIARE",
    "name_city": "MIRAFLORES"
  },
  {
    "id": 3624,
    "zip_code": 952017,
    "dane_code": 95200000,
    "name_depto": "GUAVIARE",
    "name_city": "MIRAFLORES"
  },
  {
    "id": 3625,
    "zip_code": 953001,
    "dane_code": 95015000,
    "name_depto": "GUAVIARE",
    "name_city": "CALAMAR"
  },
  {
    "id": 3626,
    "zip_code": 953007,
    "dane_code": 95015000,
    "name_depto": "GUAVIARE",
    "name_city": "CALAMAR"
  },
  {
    "id": 3627,
    "zip_code": 953008,
    "dane_code": 95015000,
    "name_depto": "GUAVIARE",
    "name_city": "CALAMAR"
  },
  {
    "id": 3628,
    "zip_code": 953009,
    "dane_code": 95015000,
    "name_depto": "GUAVIARE",
    "name_city": "CALAMAR"
  },
  {
    "id": 3629,
    "zip_code": 953017,
    "dane_code": 95015000,
    "name_depto": "GUAVIARE",
    "name_city": "CALAMAR"
  },
  {
    "id": 3630,
    "zip_code": 953018,
    "dane_code": 95015000,
    "name_depto": "GUAVIARE",
    "name_city": "CALAMAR"
  },
  {
    "id": 3631,
    "zip_code": 970001,
    "dane_code": 97001000,
    "name_depto": "VAUPES",
    "name_city": "MITU"
  },
  {
    "id": 3632,
    "zip_code": 970007,
    "dane_code": 97001000,
    "name_depto": "VAUPES",
    "name_city": "MITU"
  },
  {
    "id": 3633,
    "zip_code": 970008,
    "dane_code": 97001000,
    "name_depto": "VAUPES",
    "name_city": "MITU"
  },
  {
    "id": 3634,
    "zip_code": 970009,
    "dane_code": 97001000,
    "name_depto": "VAUPES",
    "name_city": "MITU"
  },
  {
    "id": 3635,
    "zip_code": 971007,
    "dane_code": 97889000,
    "name_depto": "VAUPES",
    "name_city": "YAVARATE"
  },
  {
    "id": 3636,
    "zip_code": 971008,
    "dane_code": 97889000,
    "name_depto": "VAUPES",
    "name_city": "YAVARATE"
  },
  {
    "id": 3637,
    "zip_code": 972007,
    "dane_code": 97511000,
    "name_depto": "VAUPES",
    "name_city": "PACOA"
  },
  {
    "id": 3638,
    "zip_code": 972008,
    "dane_code": 97511000,
    "name_depto": "VAUPES",
    "name_city": "PACOA"
  },
  {
    "id": 3639,
    "zip_code": 972040,
    "dane_code": 97666000,
    "name_depto": "VAUPES",
    "name_city": "TARAIRA"
  },
  {
    "id": 3640,
    "zip_code": 972047,
    "dane_code": 97666000,
    "name_depto": "VAUPES",
    "name_city": "TARAIRA"
  },
  {
    "id": 3641,
    "zip_code": 972048,
    "dane_code": 97666000,
    "name_depto": "VAUPES",
    "name_city": "TARAIRA"
  },
  {
    "id": 3642,
    "zip_code": 973001,
    "dane_code": 97161000,
    "name_depto": "VAUPES",
    "name_city": "CARURU"
  },
  {
    "id": 3643,
    "zip_code": 973007,
    "dane_code": 97161000,
    "name_depto": "VAUPES",
    "name_city": "CARURU"
  },
  {
    "id": 3644,
    "zip_code": 973008,
    "dane_code": 97161000,
    "name_depto": "VAUPES",
    "name_city": "CARURU"
  },
  {
    "id": 3645,
    "zip_code": 973047,
    "dane_code": 97777000,
    "name_depto": "VAUPES",
    "name_city": "PAPUNAUA"
  },
  {
    "id": 3646,
    "zip_code": 973048,
    "dane_code": 97777000,
    "name_depto": "VAUPES",
    "name_city": "PAPUNAUA"
  },
  {
    "id": 3647,
    "zip_code": 990001,
    "dane_code": 99001000,
    "name_depto": "VICHADA",
    "name_city": "PUERTO CARRE√ëO"
  },
  {
    "id": 3648,
    "zip_code": 990007,
    "dane_code": 99001000,
    "name_depto": "VICHADA",
    "name_city": "PUERTO CARRE√ëO"
  },
  {
    "id": 3649,
    "zip_code": 990008,
    "dane_code": 99001000,
    "name_depto": "VICHADA",
    "name_city": "PUERTO CARRE√ëO"
  },
  {
    "id": 3650,
    "zip_code": 990009,
    "dane_code": 99001000,
    "name_depto": "VICHADA",
    "name_city": "PUERTO CARRE√ëO"
  },
  {
    "id": 3651,
    "zip_code": 990017,
    "dane_code": 99001000,
    "name_depto": "VICHADA",
    "name_city": "PUERTO CARRE√ëO"
  },
  {
    "id": 3652,
    "zip_code": 990018,
    "dane_code": 99001000,
    "name_depto": "VICHADA",
    "name_city": "PUERTO CARRE√ëO"
  },
  {
    "id": 3653,
    "zip_code": 991001,
    "dane_code": 99773000,
    "name_depto": "VICHADA",
    "name_city": "CUMARIBO"
  },
  {
    "id": 3654,
    "zip_code": 991007,
    "dane_code": 99773000,
    "name_depto": "VICHADA",
    "name_city": "CUMARIBO"
  },
  {
    "id": 3655,
    "zip_code": 991008,
    "dane_code": 99773000,
    "name_depto": "VICHADA",
    "name_city": "CUMARIBO"
  },
  {
    "id": 3656,
    "zip_code": 991009,
    "dane_code": 99773000,
    "name_depto": "VICHADA",
    "name_city": "CUMARIBO"
  },
  {
    "id": 3657,
    "zip_code": 991017,
    "dane_code": 99773000,
    "name_depto": "VICHADA",
    "name_city": "CUMARIBO"
  },
  {
    "id": 3658,
    "zip_code": 991018,
    "dane_code": 99773000,
    "name_depto": "VICHADA",
    "name_city": "CUMARIBO"
  },
  {
    "id": 3659,
    "zip_code": 991019,
    "dane_code": 99773000,
    "name_depto": "VICHADA",
    "name_city": "CUMARIBO"
  },
  {
    "id": 3660,
    "zip_code": 991027,
    "dane_code": 99773000,
    "name_depto": "VICHADA",
    "name_city": "CUMARIBO"
  },
  {
    "id": 3661,
    "zip_code": 991028,
    "dane_code": 99773000,
    "name_depto": "VICHADA",
    "name_city": "CUMARIBO"
  },
  {
    "id": 3662,
    "zip_code": 991029,
    "dane_code": 99773000,
    "name_depto": "VICHADA",
    "name_city": "CUMARIBO"
  },
  {
    "id": 3663,
    "zip_code": 991037,
    "dane_code": 99773000,
    "name_depto": "VICHADA",
    "name_city": "CUMARIBO"
  },
  {
    "id": 3664,
    "zip_code": 991038,
    "dane_code": 99773000,
    "name_depto": "VICHADA",
    "name_city": "CUMARIBO"
  },
  {
    "id": 3665,
    "zip_code": 991039,
    "dane_code": 99773000,
    "name_depto": "VICHADA",
    "name_city": "CUMARIBO"
  },
  {
    "id": 3666,
    "zip_code": 991047,
    "dane_code": 99773000,
    "name_depto": "VICHADA",
    "name_city": "CUMARIBO"
  },
  {
    "id": 3667,
    "zip_code": 991048,
    "dane_code": 99773000,
    "name_depto": "VICHADA",
    "name_city": "CUMARIBO"
  },
  {
    "id": 3668,
    "zip_code": 991049,
    "dane_code": 99773000,
    "name_depto": "VICHADA",
    "name_city": "CUMARIBO"
  },
  {
    "id": 3669,
    "zip_code": 991057,
    "dane_code": 99773000,
    "name_depto": "VICHADA",
    "name_city": "CUMARIBO"
  },
  {
    "id": 3670,
    "zip_code": 991058,
    "dane_code": 99773000,
    "name_depto": "VICHADA",
    "name_city": "CUMARIBO"
  },
  {
    "id": 3671,
    "zip_code": 991059,
    "dane_code": 99773000,
    "name_depto": "VICHADA",
    "name_city": "CUMARIBO"
  },
  {
    "id": 3672,
    "zip_code": 992001,
    "dane_code": 99524000,
    "name_depto": "VICHADA",
    "name_city": "LA PRIMAVERA"
  },
  {
    "id": 3673,
    "zip_code": 992007,
    "dane_code": 99524000,
    "name_depto": "VICHADA",
    "name_city": "LA PRIMAVERA"
  },
  {
    "id": 3674,
    "zip_code": 992008,
    "dane_code": 99524000,
    "name_depto": "VICHADA",
    "name_city": "LA PRIMAVERA"
  },
  {
    "id": 3675,
    "zip_code": 992009,
    "dane_code": 99524000,
    "name_depto": "VICHADA",
    "name_city": "LA PRIMAVERA"
  },
  {
    "id": 3676,
    "zip_code": 992017,
    "dane_code": 99524000,
    "name_depto": "VICHADA",
    "name_city": "LA PRIMAVERA"
  },
  {
    "id": 3677,
    "zip_code": 992018,
    "dane_code": 99524000,
    "name_depto": "VICHADA",
    "name_city": "LA PRIMAVERA"
  },
  {
    "id": 3678,
    "zip_code": 992050,
    "dane_code": 99624000,
    "name_depto": "VICHADA",
    "name_city": "SANTA ROSALIA"
  },
  {
    "id": 3679,
    "zip_code": 995007,
    "dane_code": 99624000,
    "name_depto": "VICHADA",
    "name_city": "SANTA ROSALIA"
  },
  {
    "id": 3680,
    "zip_code": 995008,
    "dane_code": 99624000,
    "name_depto": "VICHADA",
    "name_city": "SANTA ROSALIA"
  },
  {
    "id": 3681,
    "zip_code": 995009,
    "dane_code": 99624000,
    "name_depto": "VICHADA",
    "name_city": "SANTA ROSALIA"
  },
  {
    "id": 3682,
    "zip_code": 110111,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3683,
    "zip_code": 110121,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3684,
    "zip_code": 110131,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3685,
    "zip_code": 110141,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3686,
    "zip_code": 110151,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3687,
    "zip_code": 110211,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3688,
    "zip_code": 110221,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3689,
    "zip_code": 110231,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3690,
    "zip_code": 110311,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3691,
    "zip_code": 110321,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3692,
    "zip_code": 110411,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3693,
    "zip_code": 110421,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3694,
    "zip_code": 110431,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3695,
    "zip_code": 110441,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3696,
    "zip_code": 110511,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3697,
    "zip_code": 110521,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3698,
    "zip_code": 110531,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3699,
    "zip_code": 110541,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3700,
    "zip_code": 110551,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3701,
    "zip_code": 110561,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3702,
    "zip_code": 110571,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3703,
    "zip_code": 110611,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3704,
    "zip_code": 110621,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3705,
    "zip_code": 110711,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3706,
    "zip_code": 110721,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3707,
    "zip_code": 110731,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3708,
    "zip_code": 110741,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3709,
    "zip_code": 110811,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3710,
    "zip_code": 110821,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3711,
    "zip_code": 110831,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3712,
    "zip_code": 110841,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3713,
    "zip_code": 110851,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3714,
    "zip_code": 110861,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3715,
    "zip_code": 110871,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3716,
    "zip_code": 110881,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3717,
    "zip_code": 110911,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3718,
    "zip_code": 110921,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3719,
    "zip_code": 110931,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3720,
    "zip_code": 111011,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3721,
    "zip_code": 111021,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3722,
    "zip_code": 111031,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3723,
    "zip_code": 111041,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3724,
    "zip_code": 111051,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3725,
    "zip_code": 111061,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3727,
    "zip_code": 111111,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3728,
    "zip_code": 111121,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3729,
    "zip_code": 111131,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3730,
    "zip_code": 111141,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3731,
    "zip_code": 111151,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3732,
    "zip_code": 111156,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3733,
    "zip_code": 111161,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3734,
    "zip_code": 111166,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3735,
    "zip_code": 111171,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3736,
    "zip_code": 111176,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3737,
    "zip_code": 111211,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3738,
    "zip_code": 111221,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3739,
    "zip_code": 111311,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3740,
    "zip_code": 111321,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3741,
    "zip_code": 111411,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3742,
    "zip_code": 111511,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3743,
    "zip_code": 111611,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3744,
    "zip_code": 111621,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3745,
    "zip_code": 111631,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3746,
    "zip_code": 111711,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3747,
    "zip_code": 111811,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3748,
    "zip_code": 111821,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3749,
    "zip_code": 111831,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3750,
    "zip_code": 111841,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3751,
    "zip_code": 111911,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3752,
    "zip_code": 111921,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3753,
    "zip_code": 111931,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3754,
    "zip_code": 111941,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3755,
    "zip_code": 111951,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3756,
    "zip_code": 111961,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3757,
    "zip_code": 111971,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3758,
    "zip_code": 111981,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3759,
    "zip_code": 112011,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3760,
    "zip_code": 112021,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3761,
    "zip_code": 112031,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3762,
    "zip_code": 112041,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  },
  {
    "id": 3763,
    "zip_code": 111071,
    "dane_code": 11001000,
    "name_depto": "CUNDINAMARCA",
    "name_city": "BOGOTA"
  }
];