import React, { Component } from 'react';
import { OverlayTrigger, Tooltip, ProgressBar } from 'react-bootstrap';
import LazyLoad from 'react-lazy-load';
import { Link } from 'react-router-dom';
import { formatCOP } from '../utils/formatMoney';
import ods1 from '../img/Goal-01.png';
import ods2 from '../img/Goal-02.png';
import ods3 from '../img/Goal-03.png';
import ods4 from '../img/Goal-04.png';
import ods5 from '../img/Goal-05.png';
import ods6 from '../img/Goal-06.png';
import ods7 from '../img/Goal-07.png';
import ods8 from '../img/Goal-08.png';
import ods9 from '../img/Goal-09.png';
import ods10 from '../img/Goal-10.png';
import ods11 from '../img/Goal-11.png';
import ods12 from '../img/Goal-12.png';
import ods13 from '../img/Goal-13.png';
import ods14 from '../img/Goal-14.png';
import ods15 from '../img/Goal-15.png';
import ods16 from '../img/Goal-16.png';
import ods17 from '../img/Goal-17.png';
import './styles/programCard.scss';

const odsList = [
  'Fin de la Pobreza',
  'Hambre Cero',
  'Salud y Bienestar',
  'Educación de Calidad',
  'Igualdad de Género',
  'Agua Limpia y Saneamiento',
  'Energía Asequible y No Contaminante',
  'Trabajo Decente y Crecimiento Ecomómico',
  'Industria, Innovación e Infraestructura',
  'Reducción de las Desigualdades',
  'Ciudades y Comunidades Sostenibles',
  'Producción y Consumo Responsables',
  'Acción por el Clima',
  'Vida Submarina',
  'Vida de Ecosistemas Terrestres',
  'Paz, Justicia e Instituciones Sólidas',
  'Alianzas para Lograr los Objetivos',
];

class ProgramCardHome extends Component {

  convertToDays(date1, date2) {
    const firstDate = new Date(date1);
    const secondDate = new Date(date2);

    date1 = Date.UTC(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate());
    date2 = Date.UTC(secondDate.getFullYear(), secondDate.getMonth(), secondDate.getDate());

    var ms = Math.abs(date1 - date2);

    if (Math.floor(ms / 1000 / 60 / 60 / 24) === 0) { return 1; }

    else { return Math.floor(ms / 1000 / 60 / 60 / 24); } //floor should be unnecessary, but just in case
  }

  getFormatedDate(date) {
    let newDate = new Date(date);

    return newDate.getFullYear() + '-' + newDate.getMonth() + '-' + newDate.getDate();
  }

  // Temporary functions while not in db
  getIcon(desc) {
    if (desc === '1') return ods1;
    else if (desc === '2') return ods2;
    else if (desc === '3') return ods3;
    else if (desc === '4') return ods4;
    else if (desc === '5') return ods5;
    else if (desc === '6') return ods6;
    else if (desc === '7') return ods7;
    else if (desc === '8') return ods8;
    else if (desc === '9') return ods9;
    else if (desc === '10') return ods10;
    else if (desc === '11') return ods11;
    else if (desc === '12') return ods12;
    else if (desc === '13') return ods13;
    else if (desc === '14') return ods14;
    else if (desc === '15') return ods15;
    else if (desc === '16') return ods16;
    else if (desc === '17') return ods17;
    else return ods17;
  }

  render() {

    const { program } = this.props;
    const { totalRaised, totalDonors } = program;

    let limitedDescription = program.description;

    if (limitedDescription.length > 100) {
      limitedDescription = <div>
        {limitedDescription.substring(0, 100)}
        <Link className="highlight" to={`/programas/${encodeURIComponent(program.name.replace(/ /g, '_'))}`}> más...</Link>
      </div>;
    }

    const rating = (program.totalRatings > 0 ? (program.ratingSum / program.totalRatings).toFixed(1) : null);
    const daysRemaining = Math.round(((new Date(program.dateEnd)).getTime() - (new Date()).getTime()) / (1000 * 3600 * 24));

    return (
      <div className="programCardHome">
        <div className="programCardImgHome">
          {
            program.businessSelected &&
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  Campaña alineada con la estrategia de impacto
                </Tooltip>
              }
            >
              <img
                className="program-card-business-logo"
                src={program.businessLogo ? program.businessLogo : require('../img/user.png')}
                alt="Logotipo de la empresa a la cual esta asociado el usuario"
              />
            </OverlayTrigger>
          }

          <div className='programOds'>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  {odsList[parseInt(program.ods[0].value) - 1]}
                </Tooltip>
              }
            >
              <img src={this.getIcon(program.ods[0].value)} className='programOdsImg' alt="Objetivo de desarrollo sostenible impactado por el programa" />
            </OverlayTrigger>
          </div>

          {
            program.emergency && <div className='programEmergencyTag'>
              Covid-19
            </div>
          }

          <div className='programCardRatingTag'>
            {
              rating ?
                <i className="fas fa-star"><span style={{ fontFamily: 'Barlow' }}>{rating}</span></i>
                :
                <i className="far fa-star noRating"></i>
            }
          </div>

          <Link to={`/programas/${encodeURIComponent(program.name.replace(/ /g, '_'))}`}>
            <LazyLoad height='100%' offsetVertical={100}>
              <div style={{ backgroundImage: `url(${program.thumbnail})` }} className="cardImg" />
            </LazyLoad>
          </Link>

        </div>

        <div className="programCardContent" style={{ height: '150px' }}>
          <div className='programCardFoundationName'>
            <Link to={`/fundaciones/id/${encodeURIComponent(program.foundationName.replace(/ /g, '_'))}`}>
              <small>{program.foundationName}</small>
            </Link>
            <small style={{ marginLeft: '5px', marginRight: '5px' }}>•</small>
            <small>{program.location.substring(0, 20)}</small>
          </div>

          <Link to={`/programas/${encodeURIComponent(program.name.replace(/ /g, '_'))}`}>
            <div className="programCardProgramName">{program.name}</div>
          </Link>

          <div className='programCardProgramDescription'>
            {program.summary || limitedDescription}
          </div>

          <Link to={`/programas/${encodeURIComponent(program.name.replace(/ /g, '_'))}`}>
            <div className='programCardDetails'>Ver detalles <i className="fas fa-angle-right" /></div>
          </Link>

          {
            totalRaised && <>
              <div className='raisedInfo'>
                {formatCOP.format(totalRaised)}{program.totalGoal && <span> /  {formatCOP.format(program.totalGoal)}</span>}
              </div>

              {
                program.totalGoal ?
                  <ProgressBar className='donationsProgressBar' now={totalRaised / program.totalGoal * 100} />
                  :
                  <ProgressBar className='donationsProgressBar' now={totalRaised} />
              }

              <div style={{ height: '32px' }}>
                <div className='donationInfo'>{totalDonors === 1 ? <>{totalDonors} Donación realizada</> : <>{totalDonors} Donaciones realizadas</>}</div>
                {daysRemaining >= 0 && <div className='donationInfo'>{daysRemaining === 1 ? <>Queda {daysRemaining} día para aportar</> : <>Quedan {daysRemaining} días para aportar</>}</div>}
              </div>
            </>
          }

          <Link to={`/programas/${encodeURIComponent(program.name.replace(/ /g, '_'))}`}>
            <div style={{ width: '100%', textAlign: 'center' }}><button className='toProgramBtn'>{program.stage === 1 ? 'Donar' : program.stage === 4 ? 'Ver Resultados' : 'Ver Campaña'}</button></div>
          </Link>
        </div>
      </div>
    );
  }
}

export default ProgramCardHome;
