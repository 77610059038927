import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Modal } from 'react-bootstrap';
// import logoWompi from '../img/Wompi-logo.png';
import CartPsePaymentComponent from './CartPsePayment';
import CartCardPaymentComponent from './CartCardPayment';
import CartWalletPaymentComponent from '../WalletPayment/CartWalletPayment';
import NequiPayment from '../NequiPayment/NequiPayment';
import { withRouter } from 'react-router-dom';
import CashPayment from '../CashPayment/CashPayment';

// Create our number formatter.
var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'COP'
});

const select = state => {
  return {
    user: state.auth.user,
    userId: state.auth.userId,
    isFoundation: state.auth.isFoundation
  };
};

class ConnectedShoppingCardPayment extends Component {

  constructor(props) {
    super(props);

    const { totalAmount, language, deliveryInfo } = this.props;

    this.state = {
      paymentType: 0,
      totalAmount,
      paymentDone: false,
      cardNumberError: false,
      cardNumber: '',
      language,
      deliveryInfo
    };

  }

  handlePaymentChange(paymentType) {
    this.setState({ paymentType });
  }

  render() {
    const { paymentDone, totalAmount, paymentType } = this.state;
    const { userId, language, show, handleCloseModal, items, products, deliveryInfo, returnToCart } = this.props;

    const PAYMENT_RECEIVED_1 = (language === 'ES' ? 'Recibimos tu donación por un valor de COP' : "Nous avons reçu votre don d'une valeur de COP");
    const PAYMENT_RECEIVED_2 = (language === 'ES' ? '. Gracias por donar con Nibi, la transacción fue exitosa' : ". Merci d'avoir fait un don avec Nibi, la transaction a réussi");
    const PAYMENT_METHOD = (language === 'ES' ? 'Elige el método de pago' : "Choisis le mode de paiement");
    const CREDIT_CARD = (language === 'ES' ? 'Tarjeta de crédito' : "Carte de crédit");
    const PSE = (language === 'ES' ? 'Débito bancario (PSE)' : "Débit bancaire (PSE)");
    const SIGN_IN_WARNING = (language === 'ES' ? 'Debes iniciar sesión para poder inscribirte a una experiencia' : "Vous devez être connecté pour vous inscrire à une expérience");
    // const SIGN_UP = (language === 'ES' ? 'Inscríbete a' : "Inscrivez-vous pour");
    // const SECURE_PAYMENT = (language === 'ES' ? 'Pago 100% seguro con' : "Paiement 100% sécurisé avec");
    // const FOUNDATION_WARNING = (language === 'ES' ? 'No te puedes inscribir si eres una organización' : "Vous ne pouvez pas vous inscrire si vous êtes une fondation");
    // const ADD_TO_CART = (language === 'ES' ? 'Agregar al carrito de compras' : "Ajouter au panier");
    // const ADDED_TO_CART = (language === 'ES' ? 'Se ha agregado el ítem al carrito de compras ' : "L'article a été ajouté au panier. ");
    // const ADDED_TO_CART_2 = (language === 'ES' ? 'Puedes seguir buscando programas ' : "Vous pouvez continuer à chercher des programmes ");
    // const ADDED_TO_CART_3 = (language === 'ES' ? 'o ir a finalizar el pago ' : "ou aller finaliser le paiement ");

    if (paymentDone) {
      return (
        <Modal show={show} onHide={handleCloseModal} size="lg" centered>
          <h2 style={{ color: '#290044', textAlign: 'center', margin: '40px 0' }}>{PAYMENT_RECEIVED_1}{totalAmount}{PAYMENT_RECEIVED_2}</h2>
        </Modal>
      );
    }

    const choosePaymentType =
      <div style={{ textAlign: 'center', paddingBottom: '80px' }}>
        <br />
        <h4>{PAYMENT_METHOD}</h4>
        <br />
        <div className="paymentTypeCard" onClick={() => this.handlePaymentChange(1)}>
          <img src={require('../img/credit-card.png')} alt="Tarjeta de crédito" style={{ height: '50px' }} aria-hidden="true" />
          <h5>{CREDIT_CARD}</h5>
        </div>
        <div className="paymentTypeCard" onClick={() => this.handlePaymentChange(2)}>
          <img src={require('../img/pse.png')} alt="Débito bancario (PSE)" style={{ height: '50px' }} aria-hidden="true" />
          <h5>{PSE}</h5>
        </div>
        {/* <div className="paymentTypeCard" onClick={() => this.handlePaymentChange(4)}>
          <img src={require('../img/nequi.jpg')} alt="Nequi" style={{ height: '50px' }} aria-hidden="true" />
          <h5>Nequi</h5>
        </div>
        <div className="paymentTypeCard" onClick={() => this.handlePaymentChange(5)}>
          <img src={require('../img/bancolombia.jpg')} alt="Bancolombia" style={{ height: '50px' }} aria-hidden="true" />
          <h5>Efectivo</h5>
        </div>
        <div className="paymentTypeCard" onClick={() => this.handlePaymentChange(3)}>
          <img src={require('../img/wallet.svg')} alt="Billetera" style={{ height: '50px' }} aria-hidden="true" />
          <h5>Billetera</h5>
        </div> */}
      </div>;

    return (
      <Container style={{ marginTop: '100px', marginBottom: '100px' }}>
        <div style={{ textAlign: 'center' }}>
          <span style={{ float: 'left' }}>
            {
              paymentType === 0 ?
                <i className="fas fa-arrow-alt-circle-left" onClick={() => returnToCart()} /> :
                <i className="fas fa-arrow-alt-circle-left" onClick={() => this.handlePaymentChange(0)} />
            }
          </span>
          <span style={{ fontSize: '1.2em' }}>
            <strong>
              Realiza tu pago ({formatter.format(totalAmount)})
            </strong>
          </span>
        </div>
        <hr />
        <br />
        <div id="paymentScreen">
          {
            !userId ? SIGN_IN_WARNING
              : paymentType === 0 ? choosePaymentType
                : paymentType === 1 ?
                  <CartCardPaymentComponent
                    totalAmount={totalAmount}
                    items={items}
                    products={products}
                    deliveryInfo={deliveryInfo}
                  />
                  : paymentType === 2 ?
                    <CartPsePaymentComponent
                      totalAmount={totalAmount}
                      items={items}
                      products={products}
                      deliveryInfo={deliveryInfo}
                    />
                    : paymentType === 3 ?
                      <CartWalletPaymentComponent
                        totalAmount={totalAmount}
                        items={items}
                        products={products}
                      />
                      : paymentType === 4 ?
                        <NequiPayment
                          paymentType={'CART'}
                          amount={totalAmount}
                          items={items}
                          products={products}
                          onTransactionSuccess={() => this.props.history.push(`/pago-exitoso/?amount=${totalAmount}&t=0`)}
                        />
                        : paymentType === 5 ?
                          <CashPayment
                            paymentType={'CART'}
                            amount={totalAmount}
                            items={items}
                            products={products}
                          /> : null
          }
        </div>
      </Container>
    );
  }
}

const ShoppingCardPayment = connect(select)(ConnectedShoppingCardPayment);

export default withRouter(ShoppingCardPayment);
