import React from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

function AddressInfoIcon(props) {
  const {language} = props;
  const MESSAGE =
    language === "ES"
      ? "Te solicitamos esta información ya que las fundaciones la necesitan para reportar sus ingresos por donaciones frente a la DIAN."
      : "We ask for this information so the organization can report its income to the DIAN.";
  return (
    <span>
      <OverlayTrigger placement="top" overlay={<Tooltip>{MESSAGE}</Tooltip>}>
        <i
          className="fas fa-question-circle"
          style={{marginLeft: "5px", color: "#DADADA"}}
        />
      </OverlayTrigger>
    </span>
  );
}

export default AddressInfoIcon;
