const SET_CHAT_NOTIFICATION = 'nibi/auth/SET_CHAT_NOTIFICATION';
const SET_PUSH_NOTIFICATION = 'nibi/auth/SET_PUSH_NOTIFICATION';
const SET_UPDATE_NOTIFICATIONS = 'nibi/auth/SET_UPDATE_NOTIFICATIONS';
const SET_UPDATE_CHAT_MESSAGES = 'nibi/auth/SET_UPDATE_CHAT_MESSAGES';

const initialState = {
  chatNotification: false,
  chatNotificationNumber: 0,
  showPushNotification: false,
  updateNotifications: false,
  updateChatMessages: false
};

export default function reducer(state = initialState, action) {

  const payload = action.payload;

  switch (action.type) {
    case SET_CHAT_NOTIFICATION:
      return {
        ...state,
        chatNotification: payload.showNotifications || false,
        chatNotificationNumber: payload.number || 0
      };
    case SET_PUSH_NOTIFICATION:
      return {
        ...state,
        showPushNotification: payload.showNotifications || false
      };
    case SET_UPDATE_NOTIFICATIONS:
      return {
        ...state,
        updateNotifications: payload.updateNotifications || false
      };
    case SET_UPDATE_CHAT_MESSAGES:
      return {
        ...state,
        updateChatMessages: payload.updateChatMessages || false
      };
    default:
      return state;
  }
}

export function setChatNotification(payload) {
  return { type: SET_CHAT_NOTIFICATION, payload };
}

export function setPushNotification(payload) {
  return { type: SET_PUSH_NOTIFICATION, payload };
}

export function setUpdateNotifications(payload) {
  return { type: SET_UPDATE_NOTIFICATIONS, payload };
}

export function setUpdateChatMessages(payload) {
  return { type: SET_UPDATE_CHAT_MESSAGES, payload };
}
