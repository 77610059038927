// Production
export const BASE_URL =
  process.env.NODE_ENV === "production" && navigator.userAgent !== "ReactSnap"
    ? ""
    : "http://localhost:5000";

export const NIBI_URL =
  process.env.NODE_ENV === "production" && navigator.userAgent !== "ReactSnap"
    ? "https://nibi.com.co/"
    : "http://localhost:5000/";

export const FRONT_URL =
  process.env.NODE_ENV === "production" && navigator.userAgent !== "ReactSnap"
    ? "https://nibi.com.co"
    : "http://localhost:3000";
