import React from "react";
import "./styles/allies.scss";

function Foundations(props) {
  return (
    <div className="foundation-logos">
      <img
        src={require("../img/FoundationLanding/fundacion1x2.png")}
        alt="Logo Saving the Amazon"
      />
      <img
        src={require("../img/FoundationLanding/fundacion8x2.png")}
        alt="Logo Techo"
      />
      <img
        src={require("../img/FoundationLanding/fundacion2x2.png")}
        alt="Logo United for Colombia"
      />
      <img
        src={require("../img/FoundationLanding/fundacion3x2.png")}
        alt="Logo Somos CaPAZes"
      />
      <img
        src={require("../img/FoundationLanding/fundacion4x2.png")}
        alt="Logo Niñas sin Miedo"
      />
      <img
        src={require("../img/FoundationLanding/fundacion5x2.png")}
        alt="Logo Banco de Alimentos Cali"
      />
      <img
        src={require("../img/FoundationLanding/fundacion6x2.png")}
        alt="Logo Fundación Nutrinfantil"
      />
      <img
        src={require("../img/FoundationLanding/fundacion7x2.png")}
        alt="Logo Cumbres Blancas"
      />
    </div>
  );
}

export default Foundations;
