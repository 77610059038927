import React, { Component } from 'react';
import { setChatNotification, setPushNotification, setUpdateNotifications } from '../Redux/modules/chat';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './styles/pushNotifications.scss';
import './styles/achievementAnimation.scss';
import { Alert } from 'react-bootstrap';
import defaultImg from '../img/user.png';
import nibiCoinImg from '../img/nibi_coins.png';
import { formatMoney } from '../utils/formatMoney';

function mapDispatchToProps(dispatch) {
  return {
    setChatNotification: payload => dispatch(setChatNotification(payload)),
    setPushNotification: payload => dispatch(setPushNotification(payload)),
    setUpdateNotifications: update => dispatch(setUpdateNotifications(update))
  };
}

const select = state => {
  return {
    userId: state.auth.userId,
    user: state.auth.user,
    isFoundation: state.auth.isFoundation,
    showPushNotification: state.chat.showPushNotification,
    chatNotificationNumber: state.chat.chatNotificationNumber,
    updateNotifications: state.chat.updateNotifications
  };
};

const customStyles = {
  border: 'none',
  borderRadius: '30px',
  borderTopRightRadius: '0',
  paddingLeft: '10px',
  paddingTop: '5px',
  paddingBottom: '5px',
  boxShadow: '3px 3px 10px 0px rgba(0, 0, 0, 0.3)'
};

class ConnectedPushNotifications extends Component {

  constructor(props) {
    super(props);

    this.state = {
      notifications: [],
      closedAchievement: false,
      showAchievement: false
    };
  }

  componentDidMount() {
    if (this.props.userId) this.checkNewNotifications();
  }

  componentDidUpdate(prevProps) {
    const { userId, updateNotifications } = this.props;

    if (prevProps.userId !== userId && !userId) {
      this.setState({ notifications: [] });
    } else if (prevProps.userId !== userId || updateNotifications) {
      this.props.setUpdateNotifications({ updateNotifications: false });
      this.checkNewNotifications();
    }
  }

  hideNotification(id, updateCheckedDate) {

    fetch('/notifications/update/read', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ notificationId: id, updateCheckedDate })
    });

    const newArr = this.state.notifications.map(item => {
      if (item._id === id) return { ...item, showNotificationApp: false };
      return item;
    });

    this.setState({ notifications: newArr });
  }

  expandMessageNotification(id, messageId) {

    fetch('/chatMessage/update/read', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ messageId })
    });

    const newArr = this.state.notifications.map(item => {
      if (item._id === id) return { ...item, collapsedApp: false };
      return item;
    });

    this.setState({ notifications: newArr });
    this.props.setChatNotification({
      showNotifications: this.props.chatNotificationNumber > 1, number: this.props.chatNotificationNumber - 1
    });
  }

  expandNotification(id) {

    fetch('/notifications/update/read', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ notificationId: id })
    });

    const newArr = this.state.notifications.map(item => {
      if (item._id === id) return { ...item, collapsedApp: false };
      return item;
    });

    this.setState({ notifications: newArr });
  }

  donationNotificationClick(id) {
    this.hideNotification(id)
    this.props.history.push('/dashboardFoundation/programas');
  }

  async newMessage(senderId, recepientId, notificationId) {

    const chatPromise = await fetch('/chat/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        senderId,
        recepientId
      })
    });

    const chatResp = await chatPromise.json();

    if (chatResp.success) {
      this.props.history.push(`/chat?id=${chatResp.id}`);
      this.hideNotification(notificationId, true);
    }
  }

  async checkNewNotifications() {

    const { showAchievement, closedAchievement } = this.state;
    const { userId, isFoundation, user } = this.props;
    const id = isFoundation ? user._id : userId;

    if (userId) {

      const notificationsFetch = fetch(`/notifications/get/${id}`);
      const checkChat = fetch(`/chat/hasNewMessage/${id}`);

      const notificationsResp = await (await notificationsFetch).json();
      const chatResp = await (await checkChat).json();
      const notificationsArr = [];
      let showPushNotifications = false;
      let numNotifications = 0;

      if (notificationsResp.success) {
        const { notifications } = notificationsResp;

        notifications.forEach(item => {

          if (!showAchievement && item.type === 'ACHIEVEMENT') {
            this.setState({ showAchievement: item });
            setTimeout(() => {
              if (!closedAchievement) {
                this.hideNotification(item._id);
                this.setState({ closedAchievement: true });
              }
            }, 10000);
          }

          if (numNotifications < 5) {

            showPushNotifications = true;
            numNotifications++;

            notificationsArr.push({
              ...item,
              showNotificationApp: true,
              collapsedApp: true
            });

          }
        });
      }

      if (chatResp.success && chatResp.chats > 0) {

        const { chats } = chatResp;

        this.props.setChatNotification({
          showNotifications: true, number: chats > 9 ? '+9' : chats
        });

      }

      if (showPushNotifications) {
        this.setState({ notifications: notificationsArr }, () => {
          this.props.setPushNotification({ showNotifications: true });
        });
      }
    }
  }

  render() {

    const { notifications, closedAchievement, showAchievement } = this.state;
    const { showPushNotification, location, history, isFoundation } = this.props;
    const includesChat = location.pathname.includes('/chat');

    if (showPushNotification) {

      return (
        <div className="chat-push-notifications-component">
          {
            showAchievement &&
            <div
              onClick={() => {
                this.hideNotification(showAchievement._id);
                this.setState({ closedAchievement: true });
                history.push('/dashboardUser/perfil');
              }}
              className={`achievement-wrapper ${closedAchievement ? 'animationExit' : 'animation'}`}
            >
              <div className="achievement-super">
                <div className="achievement-body">
                  <p className="achievement-text">Logro desbloqueado</p>
                  <p className="achievement-subtext">{showAchievement.notificationText}</p>
                  <p className="achievement-subtext">Ver todos mis logros</p>
                </div>
                {
                  showAchievement.notificationImage &&
                  <div className='achievement-icon'>
                    <img src={showAchievement.notificationImage} alt={showAchievement.notificationText} />
                  </div>
                }
              </div>
            </div>
          }
          {
            isFoundation &&
            <div
              className='notificationsIcon'
              onClick={() => history.push('/dashboardFoundation/notificaciones')}>
              <i className="far fa-bell" />
            </div>
          }
          {
            notifications.filter(item => item.showNotificationApp).map(item => {
              const { type, extras } = item;

              if (type === 'MESSAGE' && !includesChat) {
                return (
                  <Alert
                    key={item._id}
                    onClose={() => this.hideNotification(item._id)}
                    variant="light"
                    style={customStyles}
                    dismissible
                  >
                    <div className="chat-push-notification" onClick={() => this.expandMessageNotification(item._id, item.messageId)}>
                      <div className="chat-push-notification-info">
                        {
                          item.collapsedApp && <span className="new-notification"></span>
                        }
                        <img src={item.notificationImage || defaultImg} alt='You have a new notification' />
                        <span>
                          <p className="notification-header">
                            Nuevo mensaje
                          </p>
                          <p className="sender-name">
                            {item.messageUserName}
                          </p>
                        </span>
                      </div>
                      {
                        !item.collapsedApp &&
                        <div className="notification-message-text">
                          <p>
                            {item.notificationText.substring(0, 300)}
                            {item.notificationText.length > 300 ? '...' : ''}
                          </p>
                          <span className="view-message">
                            <a href={`/chat?id=${item.messageChatId}`}>
                              Ver mensaje en el chat
                            </a>
                          </span>
                        </div>
                      }
                    </div>
                  </Alert>
                );
              }

              else if (type === 'COMMENT') {
                return (
                  <Alert
                    key={item._id}
                    onClose={() => this.hideNotification(item._id)}
                    variant="light"
                    style={customStyles}
                    dismissible
                  >
                    <div className="chat-push-notification" onClick={() => this.expandNotification(item._id)}>
                      <div className="chat-push-notification-info">
                        {
                          item.collapsedApp && <span className="new-notification"></span>
                        }
                        <i className="fas fa-comment-alt push-notification-icon" />
                        <span>
                          <p className="notification-header">
                            Tienes un nuevo comentario
                          </p>
                          <p className="sender-name">
                            <span className="black-text">en la campaña </span> {item.commentProgramName}
                          </p>
                        </span>
                      </div>
                      {
                        !item.collapsedApp &&
                        <div className="notification-message-text">
                          <p>
                            {item.notificationText.substring(0, 300)}
                            {item.notificationText.length > 300 ? '...' : ''}
                          </p>
                        </div>
                      }
                    </div>
                  </Alert>
                );
              }

              else if (type === 'NEW_USER_DONATION') {
                return (
                  <Alert
                    key={item._id}
                    onClose={() => this.hideNotification(item._id)}
                    variant="light"
                    style={customStyles}
                    dismissible
                  >
                    <div className="chat-push-notification">
                      <div className="chat-push-notification-info">
                        {
                          item.collapsedApp && <span className="new-notification"></span>
                        }
                        <i className="fas fa-user-plus push-notification-icon" style={{ paddingLeft: '6px', paddingRight: '6px' }} />
                        <span>
                          <p className="notification-header">
                            Tienes un nuevo donante
                          </p>
                          <p className="sender-name">
                            <span className="black-text">Puedes ver todos tus donantes haciendo </span>
                            <span className="notification-link" onClick={() => this.donationNotificationClick(item._id)}>
                              click aquí
                            </span>
                          </p>
                        </span>
                      </div>
                    </div>
                  </Alert>
                );
              }

              else if (type === 'NIBIS') {
                return (
                  <Alert
                    key={item._id}
                    onClose={() => this.hideNotification(item._id)}
                    variant="light"
                    style={customStyles}
                    dismissible
                  >
                    <div className="chat-push-notification" onClick={() => this.expandNotification(item._id)}>
                      <div className="chat-push-notification-info">
                        {
                          item.collapsedApp && <span className="new-notification"></span>
                        }
                        <img src={nibiCoinImg} style={{ paddingTop: '3px', paddingBottom: '3px' }} alt='Nibi coin' />
                        <span>
                          <p className="notification-header">
                            {
                              item.nibisType === 'GAINED' ?
                                <>¡Obtuviste {item.nibis} nibis!</> :
                                <>Redimiste {item.nibis * -1} nibis</>
                            }

                          </p>
                          <p className="sender-name">
                            <span className="black-text">Puedes ver todos tus nibis haciendo </span>
                            <a href="/dashboardUser/">click aquí</a>
                          </p>
                        </span>
                      </div>
                      {
                        !item.collapsedApp &&
                        <div className="notification-message-text">
                          <p>
                            {item.notificationText.substring(0, 300)}
                            {item.notificationText.length > 300 ? '...' : ''}
                          </p>
                        </div>
                      }
                    </div>
                  </Alert>
                );
              }

              else if (type === 'CASH_BACK') {
                return (
                  <Alert
                    key={item._id}
                    onClose={() => this.hideNotification(item._id)}
                    variant="light"
                    style={customStyles}
                    dismissible
                  >
                    <div className="chat-push-notification" onClick={() => this.expandNotification(item._id)}>
                      <div className="chat-push-notification-info">
                        {
                          item.collapsedApp && <span className="new-notification"></span>
                        }
                        <i className="fas fa-hand-holding-usd push-notification-icon" />
                        <span>
                          <p className="notification-header">
                            Nueva devolución en tu billetera por un valor de ${formatMoney(item.cashbackAmount)}
                          </p>
                        </span>
                      </div>
                      {
                        !item.collapsedApp &&
                        <div className="notification-message-text">
                          <p>
                            {item.notificationText}
                          </p>
                          <span className="view-message">
                            <a href="/dashboardUser/billetera">
                              Ir a mi billetera
                            </a>
                          </span>
                        </div>
                      }
                    </div>
                  </Alert>
                );
              }

              else if (type === 'EMAIL_VERIFIED') {
                return (
                  <Alert
                    key={item._id}
                    onClose={() => this.hideNotification(item._id)}
                    variant="light"
                    style={customStyles}
                    dismissible
                  >
                    <div className="chat-push-notification">
                      <div className="chat-push-notification-info">
                        {
                          item.collapsedApp && <span className="new-notification"></span>
                        }
                        <i className="fas fa-envelope-square push-notification-icon" />
                        <span>
                          <p className="notification-header">
                            Correo electrónico verificado
                          </p>
                        </span>
                      </div>
                    </div>
                  </Alert>
                );
              }

              else if (type === 'BIRTHDAY') {
                return (
                  <Alert
                    key={item._id}
                    onClose={() => this.hideNotification(item._id)}
                    variant="light"
                    style={customStyles}
                    dismissible
                  >
                    <div className="chat-push-notification">
                      <div className="chat-push-notification-info">
                        {
                          item.collapsedApp && <span className="new-notification"></span>
                        }
                        <i className="fas fa-birthday-cake push-notification-icon" />
                        <span>
                          <p className="notification-header">
                            {extras.userName} está de cumpleaños.
                          </p>
                          <p className="sender-name">
                            <span className="black-text">Escríbele para felicitarlo haciendo </span>
                            <span
                              className="notification-link"
                              onClick={() => this.newMessage(item.userId, extras.userId, item._id)}
                            >
                              click aquí
                            </span>
                          </p>
                        </span>
                      </div>
                    </div>
                  </Alert>
                );
              }

              else if (type === 'NEW_RECURRING_DONATION') {
                return (
                  <Alert
                    key={item._id}
                    onClose={() => this.hideNotification(item._id)}
                    variant="light"
                    style={customStyles}
                    dismissible
                  >
                    <div className="chat-push-notification">
                      <div className="chat-push-notification-info">
                        {
                          item.collapsedApp && <span className="new-notification"></span>
                        }
                        <i className="fas fa-user-plus push-notification-icon" style={{ paddingLeft: '6px', paddingRight: '6px' }} />
                        <span>
                          <p className="notification-header">
                            {extras.userName} es un nuevo donante mensual.
                            Corre a darle la bienvenida.
                          </p>
                          <p className="sender-name">
                            <span className="black-text">Puedes enviarle un mensaje haciendo </span>
                            <span
                              className="notification-link"
                              onClick={() => this.newMessage(item.userId, extras.userId, item._id)}
                            >
                              click aquí
                            </span>
                          </p>
                        </span>
                      </div>
                    </div>
                  </Alert>
                );
              }

              else if (type === 'DONATION_PAYMENT_DENIED') {
                return (
                  <Alert
                    key={item._id}
                    onClose={() => this.hideNotification(item._id)}
                    variant="light"
                    style={customStyles}
                    dismissible
                  >
                    <div className="chat-push-notification">
                      <div className="chat-push-notification-info">
                        {
                          item.collapsedApp && <span className="new-notification"></span>
                        }
                        <i className="fas fa-exclamation-triangle push-notification-icon" />
                        <span>
                          <p className="notification-header">
                            {extras.userName} tuvo problemas con su donación.
                          </p>
                          <p className="sender-name">
                            <span className="black-text">Puedes enviarle un mensaje haciendo </span>
                            <span
                              className="notification-link"
                              onClick={() => this.newMessage(item.userId, extras.userId, item._id)}
                            >
                              click aquí
                            </span>
                          </p>
                        </span>
                      </div>
                    </div>
                  </Alert>
                );
              }

              else if (type === 'RECURRING_DONATION_REMINDER') {
                return (
                  <Alert
                    key={item._id}
                    onClose={() => this.hideNotification(item._id)}
                    variant="light"
                    style={customStyles}
                    dismissible
                  >
                    <div className="chat-push-notification">
                      <div className="chat-push-notification-info">
                        {
                          item.collapsedApp && <span className="new-notification"></span>
                        }
                        <i className="fas fa-user-clock push-notification-icon" />
                        <span>
                          <p className="notification-header">
                            Recuérdale a {extras.userName} sobre su donación mensual.
                          </p>
                          <p className="sender-name">
                            <span className="black-text">Puedes enviarle un mensaje haciendo </span>
                            <span
                              className="notification-link"
                              onClick={() => this.newMessage(item.userId, extras.userId, item._id)}
                            >
                              click aquí
                            </span>
                          </p>
                        </span>
                      </div>
                    </div>
                  </Alert>
                );
              }

              else if (type === 'RECURRING_DONATION_ANNIVERSARY') {
                return (
                  <Alert
                    key={item._id}
                    onClose={() => this.hideNotification(item._id)}
                    variant="light"
                    style={customStyles}
                    dismissible
                  >
                    <div className="chat-push-notification">
                      <div className="chat-push-notification-info">
                        {
                          item.collapsedApp && <span className="new-notification"></span>
                        }
                        <i className="fas fa-calendar-day push-notification-icon" />
                        <span>
                          <p className="notification-header">
                            {extras.userName}
                            {
                              extras.anniversaryMonth === 2 ?
                                ' acaba de hacer su 2da donación mensual. Las primeras 3 son claves.' :
                                extras.anniversaryMonth === 3 ?
                                  ' acaba de hacer su 3ra donación mensual. Las primeras 3 son claves.' :
                                  extras.anniversaryMonth === 6 ?
                                    ' está cumpliendo 6 meses como tu donante mensual.' :
                                    extras.anniversaryMonth === 12 ?
                                      ' está cumpliendo 1 año como tu donante mensual.' : ''
                            }
                          </p>
                          <p className="sender-name">
                            <span className="black-text">Puedes enviarle un mensaje haciendo </span>
                            <span
                              className="notification-link"
                              onClick={() => this.newMessage(item.userId, extras.userId, item._id)}
                            >
                              click aquí
                            </span>
                          </p>
                        </span>
                      </div>
                    </div>
                  </Alert>
                );
              }

              else if (type === 'DONOR_AND_VOLUNTEER') {
                return (
                  <Alert
                    key={item._id}
                    onClose={() => this.hideNotification(item._id)}
                    variant="light"
                    style={customStyles}
                    dismissible
                  >
                    <div className="chat-push-notification">
                      <div className="chat-push-notification-info">
                        {
                          item.collapsedApp && <span className="new-notification"></span>
                        }
                        <i className="fas fa-people-carry push-notification-icon" />
                        <span>
                          <p className="notification-header">
                            {extras.userName} ahora es tu donante y voluntario.
                          </p>
                          <p className="sender-name">
                            <span className="black-text">Puedes enviarle un mensaje haciendo </span>
                            <span
                              className="notification-link"
                              onClick={() => this.newMessage(item.userId, extras.userId, item._id)}
                            >
                              click aquí
                            </span>
                          </p>
                        </span>
                      </div>
                    </div>
                  </Alert>
                );
              }

              else if (type === 'FIRST_TIME_VOLUNTEER') {
                return (
                  <Alert
                    key={item._id}
                    onClose={() => this.hideNotification(item._id)}
                    variant="light"
                    style={customStyles}
                    dismissible
                  >
                    <div className="chat-push-notification">
                      <div className="chat-push-notification-info">
                        {
                          item.collapsedApp && <span className="new-notification"></span>
                        }
                        <i className="fas fa-people-carry push-notification-icon" />
                        <span>
                          <p className="notification-header">
                            {extras.userName} ahora es tu voluntario. ¡Es su primer voluntariado!
                          </p>
                          <p className="sender-name">
                            <span className="black-text">Puedes enviarle un mensaje haciendo </span>
                            <span
                              className="notification-link"
                              onClick={() => this.newMessage(item.userId, extras.userId, item._id)}
                            >
                              click aquí
                            </span>
                          </p>
                        </span>
                      </div>
                    </div>
                  </Alert>
                );
              }

              else if (type === 'NEW_VOLUNTEER') {
                return (
                  <Alert
                    key={item._id}
                    onClose={() => this.hideNotification(item._id)}
                    variant="light"
                    style={customStyles}
                    dismissible
                  >
                    <div className="chat-push-notification">
                      <div className="chat-push-notification-info">
                        {
                          item.collapsedApp && <span className="new-notification"></span>
                        }
                        <i className="fas fa-people-carry push-notification-icon" />
                        <span>
                          <p className="notification-header">
                            {extras.userName} ahora es tu voluntario.
                          </p>
                          <p className="sender-name">
                            <span className="black-text">Puedes enviarle un mensaje haciendo </span>
                            <span
                              className="notification-link"
                              onClick={() => this.newMessage(item.userId, extras.userId, item._id)}
                            >
                              click aquí
                            </span>
                          </p>
                        </span>
                      </div>
                    </div>
                  </Alert>
                );
              }

              else if (type === 'RECURRING_DONATION_CANCELED') {
                return (
                  <Alert
                    key={item._id}
                    onClose={() => this.hideNotification(item._id)}
                    variant="light"
                    style={customStyles}
                    dismissible
                  >
                    <div className="chat-push-notification">
                      <div className="chat-push-notification-info">
                        {
                          item.collapsedApp && <span className="new-notification"></span>
                        }
                        <i className="fas fa-user-minus push-notification-icon" />
                        <span>
                          <p className="notification-header">
                            {extras.userName} acaba de terminar su donación mensual.
                          </p>
                          <p className="sender-name">
                            <span className="black-text">Puedes enviarle un mensaje haciendo </span>
                            <span
                              className="notification-link"
                              onClick={() => this.newMessage(item.userId, extras.userId, item._id)}
                            >
                              click aquí
                            </span>
                          </p>
                        </span>
                      </div>
                    </div>
                  </Alert>
                );
              }

              else return <></>;
            })
          }
        </div>
      );
    }

    return (
      <div className='chat-push-notifications-component'>
        {
          isFoundation &&
          <div
            className='notificationsIcon'
            onClick={() => history.push('/dashboardFoundation/notificaciones')}>
            <i className="far fa-bell" />
          </div>
        }
      </div>
    );
  }
}

const PushNotifications = connect(select, mapDispatchToProps)(ConnectedPushNotifications);

export default withRouter(PushNotifications);
