const SET_SHOW_BILLING_PLAN_UPGRADE_POPUP = 'nibi/billingPlanWarning/SET_SHOW_BILLING_PLAN_UPGRADE_POPUP';

const initialState = {
  showBillingPlanUpgradePopup: false,
  warningType: '',
  returnToPrevScreen: false
};

export default function reducer(state = initialState, action) {

  const payload = action.payload;

  switch (action.type) {
    case SET_SHOW_BILLING_PLAN_UPGRADE_POPUP:
      return {
        ...state,
        showBillingPlanUpgradePopup: payload.show,
        warningType: payload.type || '',
        returnToPrevScreen: payload.returnToPrevScreen || false
      };
    default:
      return state;
  }
}

export function setShowBillingPlanUpgradePopup(payload) {
  return { type: SET_SHOW_BILLING_PLAN_UPGRADE_POPUP, payload }
}

export const FOUNDATION_NEW_PROGRAM = 'FOUNDATION_NEW_PROGRAM';
export const FOUNDATION_UPDATE_PROGRAM = 'FOUNDATION_UPDATE_PROGRAM';
export const FOUNDATION_NEW_VIRTUAL_PROGRAM = 'FOUNDATION_NEW_VIRTUAL_PROGRAM';
export const FOUNDATION_UPDATE_VIRTUAL_PROGRAM = 'FOUNDATION_UPDATE_VIRTUAL_PROGRAM';
export const FOUNDATION_NEW_BENEFIT = 'FOUNDATION_NEW_BENEFIT';
export const FOUNDATION_UPDATE_BENEFIT = 'FOUNDATION_UPDATE_BENEFIT';
