import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Container, Form, Spinner } from 'react-bootstrap';
import { wompiBaseUrl, wompiPublicKey, redirectBaseUrl } from '../utils/paymentKeys';
import queryString from 'query-string';
import AppSpinner from '../Extras/AppSpinner';
import { connect } from 'react-redux';
import { formatMoney } from '../utils/formatMoney';
import './styles/styles.scss';

const select = state => {
  return {
    userId: state.auth.userId
  };
};

function ConnectedSubscriptionPaymentLandingComponent(props) {

  const [isLoading, setIsLoading] = useState(true);
  const [creatingTransaction, setCreatingTransaction] = useState(false);
  const [planName, setPlanName] = useState('');
  const [amount, setAmount] = useState(0);
  const [acceptanceToken, setAcceptanceToken] = useState('');
  const [permalink, setPermalink] = useState('');
  const [email, setEmail] = useState('');
  const [financialInstitutions, setFinancialInstitutions] = useState([]);
  const [docType, setDocType] = useState('CC');
  const [userDocument, setUserDocument] = useState('');
  const [userType, setUserType] = useState('0');
  const [institutionSelected, setInstitutionSelected] = useState('0');
  const [error, setError] = useState(false);
  const [formValidated, setFormValidated] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('');
  let pollingTransaction;

  useEffect(() => {

    const getData = async () => {
      const values = queryString.parse(props.location.search);
      const subscriptionId = values.id;
      const paymentId = values.ref;

      if (subscriptionId && paymentId) {

        const subscriptionFetch = fetch(`/subscriptions/info/${subscriptionId}/${paymentId}`);
        const acceptanceTokenFetch = fetch(`${wompiBaseUrl}/merchants/${wompiPublicKey}`);
        const financialInstitutionsFetch = fetch(`${wompiBaseUrl}/pse/financial_institutions`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${wompiPublicKey}`
          }
        });

        const acceptanceTokenResponse = await acceptanceTokenFetch;
        const acceptanceTokenData = await acceptanceTokenResponse.json();
        const { data } = acceptanceTokenData;

        if (data) {
          const presignedData = data.presigned_acceptance;

          setAcceptanceToken(presignedData.acceptance_token);
          setPermalink(presignedData.permalink);
        }

        const financialInstitutionsResponse = await financialInstitutionsFetch;
        const financialInstitutionsData = await financialInstitutionsResponse.json();

        if (financialInstitutionsData.data) setFinancialInstitutions(financialInstitutionsData.data);

        const subscriptionResponse = await subscriptionFetch;
        const subscriptionData = await subscriptionResponse.json();

        if (subscriptionData.success) {
          const { email, amount, planName, paymentSuccess, paymentStatus } = subscriptionData;

          setEmail(email);
          setAmount(amount);
          setPlanName(planName);

          if (paymentSuccess) setPaymentStatus(paymentStatus);
          else setError(true);

        } else {
          setError(true);
        }

      } else {
        setError(true);
      }

      setIsLoading(false);
    };

    getData();
  }, [props.location.search]);

  const createTransaction = async () => {

    const values = queryString.parse(props.location.search);
    const { ref } = values;

    const transactionResponse = await fetch(`${wompiBaseUrl}/transactions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${wompiPublicKey}`
      },
      body: JSON.stringify({
        acceptance_token: acceptanceToken,
        amount_in_cents: parseInt(amount + '00'),
        currency: 'COP',
        customer_email: email,
        payment_method_type: 'PSE',
        payment_method: {
          type: 'PSE',
          user_type: parseInt(userType),
          user_legal_id_type: docType,
          user_legal_id: userDocument,
          financial_institution_code: institutionSelected,
          payment_description: `Pago a través de Nibi, ref: ${ref}`
        },
        redirect_url: `${redirectBaseUrl}/pago/resultado`,
        reference: ref
      })
    });

    const transtactionData = await transactionResponse.json();
    const transactionId = transtactionData.data.id;

    return transactionId;
  };

  const checkTransaction = async transactionId => {

    const transactionResponse = await fetch(`${wompiBaseUrl}/transactions/${transactionId}`);
    const transaction = await transactionResponse.json();
    const { data } = transaction;

    if (data && data.payment_method && data.payment_method.extra && data.payment_method.extra.async_payment_url) {

      clearInterval(pollingTransaction);

      const paymentUrl = data.payment_method.extra.async_payment_url;
      if (window.location.href !== paymentUrl) window.location.href = paymentUrl;
    }

  };

  const handleSubmit = async event => {

    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    setFormValidated(true);

    if (institutionSelected === '0') alert('Selecciona tu banco.');

    if (form.checkValidity() && institutionSelected !== '0') {

      setCreatingTransaction(true);

      const transactionId = await createTransaction();

      pollingTransaction = setInterval(
        async () => {
          checkTransaction(transactionId);
        },
        2000
      );
    }
  };

  const { userId } = props;

  if (isLoading) return (<div className="subscription-payment-landing-component"><AppSpinner /></div>);

  if (!userId) {
    return (
      <div className="subscription-payment-landing-component">
        <Container>
          <Row>
            <Col>
              <h2 className="message" style={{ padding: '30px 0' }}>
                Por favor inicia sesión para proceder con tu pago.
              </h2>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  if (error) {
    return (
      <div className="subscription-payment-landing-component">
        <Container>
          <Row>
            <Col>
              <h2 className="message">
                Ocurrió un error, por favor inténtalo nuevamente o comunícate con nosotros.
              </h2>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  if (paymentStatus !== 'PENDING') {
    return (
      <div className="subscription-payment-landing-component">
        <Container>
          <Row>
            <Col>
              <h2 className="message">
                La transacción especificada ya fue procesada.
              </h2>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  return (
    <div className="subscription-payment-landing-component">
      <Container>
        <Row>
          <Col>
            <h4>
              Confirma los datos de pago:
            </h4>

            <Form noValidate validated={formValidated} onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} lg={6}>
                  <Form.Label>Plan</Form.Label>
                  <Form.Control
                    type="text"
                    value={planName}
                    disabled
                    required
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} lg={4}>
                  <Form.Label>Monto</Form.Label>
                  <Form.Control
                    type="text"
                    value={formatMoney(amount)}
                    disabled
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} lg={4}>
                  <Form.Label>Institución financiera</Form.Label>
                  <Form.Control
                    as="select"
                    className={institutionSelected === '0' ? 'invalid-value' : ''}
                    name="institutionSelected"
                    value={institutionSelected}
                    onChange={e => setInstitutionSelected(e.target.value)}
                    required
                  >
                    {
                      financialInstitutions.map(institution =>
                        <option
                          key={institution.financial_institution_code}
                          value={institution.financial_institution_code}>
                          {institution.financial_institution_name}
                        </option>
                      )
                    }
                  </Form.Control>

                </Form.Group>

                <Form.Group as={Col} lg={4}>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    name="email"
                    onChange={e => setEmail(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Ingresa un email válido.
                  </Form.Control.Feedback>
                </Form.Group>

              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} lg={4} xs={6}>
                  <Form.Label>Tipo de persona</Form.Label>
                  <Form.Control
                    name="userType"
                    as="select"
                    value={userType}
                    onChange={e => setUserType(e.target.value)}
                    required
                  >
                    <option value="0">Natural</option>
                    <option value="1">Jurídica</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} lg={4} xs={6}>
                  <Form.Label>Tipo de documento</Form.Label>
                  <Form.Control
                    name="docType"
                    as="select"
                    value={docType}
                    onChange={e => setDocType(e.target.value)}
                    required
                  >
                    <option value="CC">CC</option>
                    <option value="NIT">NIT</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} lg={4}>
                  <Form.Label>Número de documento</Form.Label>
                  <Form.Control
                    name="userDocument"
                    type="text"
                    placeholder="Documento"
                    value={userDocument}
                    onChange={e => setUserDocument(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Ingresa tu documento.
                  </Form.Control.Feedback>
                </Form.Group>

              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} xs={12}>
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id="permalinkCheckBox" required />
                    <label className="custom-control-label" htmlFor="permalinkCheckBox">
                      Acepto haber leído los <a href={permalink} target="_blank" rel="noopener noreferrer">términos y condiciones y la política de privacidad</a> para hacer este pago.
                    </label>
                  </div>
                </Form.Group>

                <Button
                  variant="primary"
                  className="btn-modal"
                  type="submit"
                  disabled={creatingTransaction}
                >
                  {
                    creatingTransaction ?
                      <Spinner size="sm" animation="border" variant="warning" /> :
                      'Confirmar'
                  }
                </Button>
              </Form.Row>
            </Form>

          </Col>
        </Row>
      </Container>
    </div>
  );

}

const SubscriptionPaymentLanding = connect(select)(ConnectedSubscriptionPaymentLandingComponent);

export default SubscriptionPaymentLanding;
