import React, { useState, useEffect } from 'react';
import { Col, Form, InputGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import { formatMoney } from '../utils/formatMoney';
import { wompiBaseUrl, wompiPublicKey } from '../utils/paymentKeys';
import { withRouter } from 'react-router';
import AppSpinner from '../Extras/AppSpinner';
import './styles/nibifyPayment.scss';

const select = state => {
  return {
    user: state.auth.user,
    userId: state.auth.userId,
    token: state.auth.token
  };
};

function ConnectedNibifyPayment(props) {

  const { user } = props;

  const [loading, setLoading] = useState(true);
  const [creatingTransaction, setCreatingTransaction] = useState(false);
  const [validated, setValidated] = useState(false);
  const [acceptanceToken, setAcceptanceToken] = useState(null);
  const [permalink, setPermalink] = useState(null);
  const [transactionStatus, setTransactionStatus] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [cardHolderName, setCardHolderName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardExpirationMonth, setCardExpirationMonth] = useState('');
  const [cardExpirationYear, setCardExpirationYear] = useState('');
  const [saveCard, setSaveCard] = useState(false);
  const [savedPaymentSource, setSavedPaymentSource] = useState('Ninguna');
  const [cvv, setCvv] = useState('');
  const [referredBy, setReferredBy] = useState('');
  const [userIdentification, setUserIdentification] = useState('');
  const [userIdentificationType, setUserIdentificationType] = useState('CC');
  const [cellPhone, setCellPhone] = useState('');
  const [userGender, setUserGender] = useState('M');
  const [userAddress, setUserAddress] = useState('');
  const [contactMethod, setContactMethod] = useState('EMAIL');
  const [userCity, setUserCity] = useState('');
  const [userAdditionalDetails, setUserAdditionalDetails] = useState('');
  let interval;

  useEffect(() => {
    getWompiData();
  }, []);

  useEffect(() => {
    if (user) {
      setUserIdentification(user.identification ? user.identification : '');
      setUserIdentificationType(user.identificationType ? user.identificationType : 'CC');
      setCellPhone(user.cellPhone ? user.cellPhone : '');
      setReferredBy(user.referredBy ? user.referredBy : '');
    }
  }, [user]);

  useEffect(() => {
    if (acceptanceToken && permalink) setLoading(false);
  }, [acceptanceToken, permalink]);

  useEffect(() => {
    if (transactionStatus) {
      setCreatingTransaction(false);

      if (transactionStatus === 'SUCCESS' && props.onTransactionSuccess) props.onTransactionSuccess();
    }
  }, [transactionStatus]);

  useEffect(() => {
    if (transactionId) interval = setInterval(checkTransaction, 2000);
  }, [transactionId]);

  const getWompiData = async () => {
    const acceptanceTokenResp = await fetch(`${wompiBaseUrl}/merchants/${wompiPublicKey}`);
    const acceptanceTokenData = await acceptanceTokenResp.json();
    const { data } = acceptanceTokenData;

    if (data) {
      const presignedData = data.presigned_acceptance;

      setAcceptanceToken(presignedData.acceptance_token);
      setPermalink(presignedData.permalink);
    }
  };

  const checkTransaction = async () => {
    if (!transactionStatus) {
      try {

        const transactionResponse = await fetch(`${wompiBaseUrl}/transactions/${transactionId}`);
        const transaction = await transactionResponse.json();

        const { data } = transaction;

        if (data && data.status !== 'PENDING') {
          clearInterval(interval);
          setTransactionStatus(data.status === 'APPROVED' ? 'SUCCESS' : 'ERROR');
        }

      } catch (err) {
        clearInterval(interval);
        setTransactionStatus('ERROR');
      }
    }
  };

  const createTransaction = async () => {

    const { amount, userId, user, foundationId, nibifyPlan } = props;

    if (savedPaymentSource === 'Ninguna') {
      const cardTokenId = await tokenizeCard();

      if (!cardTokenId) return;

      if (saveCard) {
        const cardToken = await tokenizeCard();

        fetch('/payments/save_card', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            token: cardToken,
            userId: userId,
            cardNumber: cardNumber.substring(cardNumber.length - 4),
            customer_email: user.email
          })
        });

      }

      const transactionResponse = await fetch('/nibify/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId,
          nibifyPlan,
          paymentMethod: 'CARD',
          amount,
          cardToken: cardTokenId,
          foundationId,
          userEmail: user.email,
          userGender,
          userPhone: cellPhone,
          userAddress,
          userIdentification,
          userIdentificationType,
          contactMethod,
          userCity,
          userAdditionalDetails,
          saveCard,
          cardNumber: cardNumber.trim().slice(-4),
          acceptance_token: acceptanceToken
        })
      });

      const transtactionData = await transactionResponse.json();

      if (transtactionData.success && transtactionData.data) return transtactionData.data.id;
      else setTransactionStatus('ERROR');

    } else {
      const transactionResponse = await fetch('/nibify/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId,
          nibifyPlan,
          paymentMethod: 'CARD',
          amount,
          savedPaymentSource,
          foundationId,
          userEmail: user.email,
          userGender,
          userPhone: cellPhone,
          userAddress,
          userIdentification,
          userIdentificationType,
          contactMethod,
          userCity,
          userAdditionalDetails,
          saveCard,
          cardNumber: cardNumber.trim().slice(-4),
          acceptance_token: acceptanceToken
        })
      });

      const transactionData = await transactionResponse.json();

      if (transactionData.success && transactionData.data) return transactionData.data.id;
      else setTransactionStatus('ERROR');
    }
  };

  const tokenizeCard = async () => {
    const tokenizeCardResponse = await fetch(`${wompiBaseUrl}/tokens/cards`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${wompiPublicKey}`
      },
      body: JSON.stringify({
        'number': cardNumber,
        'cvc': cvv,
        'exp_month': cardExpirationMonth,
        'exp_year': cardExpirationYear,
        'card_holder': cardHolderName
      })
    });

    const tokenCardData = await tokenizeCardResponse.json();

    if (tokenizeCardResponse.status === 201 && tokenCardData.status === 'CREATED') return tokenCardData.data.id;
    else setTransactionStatus('ERROR');
  };

  const handleSubmit = async event => {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);

    const form = event.currentTarget;

    if (form.checkValidity()) {
      setCreatingTransaction(true);
      const transactionId = await createTransaction();

      if (transactionId) setTransactionId(transactionId);
    }
  };

  if (loading || creatingTransaction) {
    return (
      <div className="card-payment-component">
        <AppSpinner />
        {
          creatingTransaction &&
          <h4 className="transaction-message" style={{ color: '#312067', textAlign: 'center' }}>
            Estamos procesando la transacción. Por favor espera.
          </h4>
        }
      </div>
    );
  }

  if (transactionStatus) {
    return (
      <div className="nibify-payment-component">
        <h3 className="transaction-message transaction-status-message" style={{ color: '#312067', textAlign: 'center' }}>
          {
            transactionStatus === 'SUCCESS' ?
            <>
              Gracias por usar nibi.<br />
              Recibimos tu pago por un valor de ${formatMoney(props.amount)} COP.
            </> :
            'Error al procesar la transacción. Inténtalo nuevamente o comunícate con nosotros.'
          }
        </h3>
      </div>
    );
  }

  const paymentSources = user.paymentSources || [];

  return (
    <div className="nibify-payment-component">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Row>
          <Col xs={12}>
            <h5>Datos del pago</h5>
          </Col>
          <Form.Group as={Col} lg={6}>
            <Form.Label>¿Tienes código de referido?</Form.Label>
            <Form.Control
              type="text"
              placeholder="Código referido"
              value={user.referredBy ? user.referredBy : referredBy}
              onChange={event => setReferredBy(event.target.value)}
              disabled={user.referredBy ? true : false}
            />
          </Form.Group>
          <Col xs={12}>
            <Form.Label>Tipo y número de documento</Form.Label>
          </Col>
          <Form.Group as={Col} xs={4}>
            <Form.Control
              as="select"
              value={userIdentificationType}
              onChange={event => setUserIdentificationType(event.target.value)}
            >
              <option value="CC">CC</option>
              <option value="NIT">NIT</option>
              <option value="CE">CE</option>
              <option value="Otro">Otro</option>
            </Form.Control>
          </Form.Group>

          <Form.Group as={Col} xs={8}>
            <Form.Control
              type="text"
              placeholder="Documento"
              value={userIdentification}
              onChange={event => setUserIdentification(event.target.value)}
              required
            />
          </Form.Group>
          {
            paymentSources.length > 0 &&
            <Form.Group as={Col} lg={6}>
              <Form.Label>Usar tarjeta guardada</Form.Label>
              <Form.Control
                as="select"
                name="savedPaymentSource"
                value={savedPaymentSource}
                onChange={event => setSavedPaymentSource(event.target.value)}
              >
                <option value="Ninguna">Ninguna</option>
                {
                  paymentSources.map((el, index) =>
                    <option key={index} value={el.paymentSourceId}>
                      **** **** **** {el.cardNumber}
                    </option>
                  )
                }
              </Form.Control>
            </Form.Group>
          }
        </Form.Row>
        {
          savedPaymentSource === 'Ninguna' &&
          <Form.Row>
            <Form.Group as={Col} lg={6}>
              <Form.Label>Nombre completo (como aparece en la tarjeta)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre Completo"
                value={cardHolderName}
                onChange={event => setCardHolderName(event.target.value)}
                required
              />
            </Form.Group>
            <Form.Group as={Col} lg={6}>
              <Form.Label>Número de la tarjeta</Form.Label>
              <Form.Control
                type="number"
                placeholder="0000 0000 0000 0000"
                value={cardNumber}
                onChange={event => setCardNumber(event.target.value)}
                required
              />
            </Form.Group>
          
            <Form.Group as={Col} lg={4}>
              <Form.Label>Fecha de expiración</Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  placeholder="MM"
                  min={1}
                  max={12}
                  value={cardExpirationMonth}
                  onChange={event => {
                    const { value } = event.target;
                    if (value.toString().length <= 2) setCardExpirationMonth(value);
                  }}
                  required
                />
                <Form.Control
                  type="number"
                  min={20}
                  placeholder="YY"
                  value={cardExpirationYear}
                  onChange={event => {
                    const { value } = event.target;
                    if (value.toString().length <= 2) setCardExpirationYear(value);
                  }}
                  required
                />
              </InputGroup>
            </Form.Group>

            <Form.Group as={Col} lg={4}>
              <Form.Label>Código de seguridad</Form.Label>
              <Form.Control
                type="number"
                value={cvv}
                onChange={event => setCvv(event.target.value)}
                required
              />
            </Form.Group>
          </Form.Row>
        }

        <Form.Row>
          <Col xs={12}>
            <h5>Datos adicionales</h5>
          </Col>

          <Form.Group as={Col} xs={12}>
            <Form.Label>Género</Form.Label>
            <Form.Control
              as="select"
              value={userGender}
              onChange={event => setUserGender(event.target.value)}
            >
              <option value="M">Masculino</option>
              <option value="F">Femenino</option>
              <option value="O">Otro</option>
            </Form.Control>
          </Form.Group>

          <Form.Group as={Col} md={6}>
            <Form.Label>Ciudad</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ciudad"
              value={userCity}
              onChange={event => setUserCity(event.target.value)}
              required
            />
          </Form.Group>

          <Form.Group as={Col} md={6}>
            <Form.Label>Dirección</Form.Label>
            <Form.Control
              type="text"
              placeholder="Dirección"
              value={userAddress}
              onChange={event => setUserAddress(event.target.value)}
              required
            />
          </Form.Group>

          <Form.Group as={Col} md={6}>
            <Form.Label>Detalles adicionales</Form.Label>
            <Form.Control
              type="text"
              placeholder="Detalles adicionales"
              value={userAdditionalDetails}
              onChange={event => setUserAdditionalDetails(event.target.value)}
            />
          </Form.Group>

          <Form.Group as={Col} lg={6}>
            <Form.Label>Celular</Form.Label>
            <Form.Control
              type="text"
              placeholder="Celular"
              value={cellPhone}
              onChange={event => setCellPhone(event.target.value)}
              required
            />
          </Form.Group>

          <Form.Group as={Col} xs={12}>
            <Form.Label>¿Cómo quieres conocer el impacto de tu donación?</Form.Label>
            <Form.Control
              as="select"
              value={contactMethod}
              onChange={event => setContactMethod(event.target.value)}
            >
              <option value="WHATS_APP">WhatsApp</option>
              <option value="CELL_PHONE">Llamada</option>
              <option value="EMAIL">Correo</option>
            </Form.Control>
          </Form.Group>

          {
            savedPaymentSource === 'Ninguna' &&
            <Form.Group as={Col} xs={12}>
              <div className="custom-control custom-checkbox">
                <input
                  id="saveCardCheckbox"
                  type="checkbox"
                  className="custom-control-input"
                  onChange={event => setSaveCard(event.target.checked)}
                  checked={saveCard}
                />
                <label className="custom-control-label" htmlFor="saveCardCheckbox">
                  Deseo guardar mi tarjeta de crédito para futuras transacciones.
                </label>
              </div>
            </Form.Group>
          }
        </Form.Row>

        <Form.Row>
          <Col xs={12}>
            <div className="custom-control custom-checkbox">
              <input type="checkbox" className="custom-control-input" id="permalinkCheckBox1" required />
              <label className="custom-control-label" htmlFor="permalinkCheckBox1">
                Acepto haber leído los <a href={permalink} target="_blank" rel="noopener noreferrer">términos y condiciones y la política de privacidad</a> para hacer este pago.
              </label>
            </div>
          </Col>

          <button className="payment-button" type="submit">
            Finalizar
          </button>
        </Form.Row>

      </Form>
    </div>
  );
}

const NibifyPayment = connect(select)(ConnectedNibifyPayment);

export default withRouter(NibifyPayment);
