import React from 'react';
import { useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import Slider from 'react-slick';
import { formatMoney } from '../utils/formatMoney';
import PaymentExtension from './PaymentExtension';
import img1 from './img/img1.jpg';
import img2 from './img/img2.png';
import img3 from './img/img3.png';
import img4 from './img/img4.png';
import img5 from './img/img5.png';
import './styles/futbol-pazifico.scss';

// const foundationId = '5e6832390149723a18478fe4';
// const foundationName = 'Fundación Redux';
// const options = [
//   { id: '606dda32481cff20c0f7c417', label: 'Plan 1', value: 'ESCUELA', amount: 30000 },
//   { id: '60ebd4068defeb4754f9f28e', label: 'Plan 2', value: 'ALTO_RENDIMIENTO', amount: 20000 }
// ];

const foundationId = '5f29d8c03dcfa65c787c3235';
const foundationName = 'Fundación Fútbol Pazifico';
const options = [
  { id: '61378ba89651b47f1c76792e', label: 'Apadrinar un niño escuela - $31.000 pesos mensuales', value: 'ESCUELA', amount: 31000 },
  { id: '613789849651b47f1c767929', label: 'Apadrinar un niño de alto rendimiento - $ 120.000 pesos mensuales', value: 'ALTO_RENDIMIENTO', amount: 120000 }
];

const photos = [img1, img2, img3, img4, img5];

function PaymentExtensionFutbolPazifico() {

  const settings = {
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: photos.length > 2 ? 3 : photos.length + 1,
    slidesToScroll: 3
  };

  const [amount, setAmount] = useState(options[0].amount);
  const [selectedDonation, setSelectedDonation] = useState(options[0]);
  const [qty, setQty] = useState(1);
  const [selectedSlide, setSelectedSlide] = useState(<img className='selectedSlideImage' src={photos[0]} alt="Imagen Fútbol Pazifico"/>);
  const [showPayment, setShowPayment] = useState(false);

  const handleChange = event => {
    const item = options.find(item => item.value === event.target.value);

    setSelectedDonation(item);
    setAmount(item.amount);
  };

  return (
    <div className="payment-extension-component-fp">
      <Container>
        <Row>
          {
            !showPayment &&
            <Col xs={5}>
              <>
                <div className="selectedSlideContainer">
                  {selectedSlide}
                </div>
                <div className="sliderContainer">
                  <Slider {...settings}>
                    {
                      photos.map((photo, i) => {
                        if (photo !== '') return (
                          <div
                            key={i} 
                            onClick={() => setSelectedSlide(<img className='selectedSlideImage' src={photo} alt="Imagen Fútbol Pazifico"/>)}
                          >
                            <img className='carouselImage' src={photo} alt="Imagen Fútbol Pazifico"/>
                          </div>
                        )
                        else return null;
                      })
                    }
                  </Slider>
                </div>
              </>
            </Col>
          }
          <Col xs={showPayment ? 12 : 7}>
            <div>
              <h2 className="title">APADRINA A UN NIÑO</h2>
              <span className="amount">${formatMoney(amount * qty)}</span>
              <hr />
            </div>
            {
              showPayment ?
              <PaymentExtension
                foundationId={foundationId}
                foundationName={foundationName}
                qty={qty}
                donationPlanId={selectedDonation.id}
                onBack={() => setShowPayment(false)}
              /> :
              <>
                <div>
                  <Form.Row>
                    <Form.Group as={Col} xs={10}>
                      <Form.Label>Plan</Form.Label>
                      <Form.Control
                        as="select"
                        value={selectedDonation.value}
                        onChange={handleChange}
                      >
                        {
                          options.map(item =>
                            <option key={item.value} value={item.value}>{item.label}</option>
                          )
                        }
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} xs={2}>
                      <Form.Label>Cantidad</Form.Label>
                      <Form.Control
                        type="number"
                        value={qty}
                        onChange={e => {
                          const { value } = e.target;
                          if (value >= 1) setQty(value);
                        }}
                      />
                    </Form.Group>
                  </Form.Row>
                </div>
                <div>
                  <button className="nibi-btn" onClick={() => setShowPayment(true)}>
                    Continuar
                  </button>
                </div>
                <div className="plan-description">
                  <h6 className="plan-title">Programa Escuela</h6>
                  <p>
                    Por $31.000 pesos mensuales le puedes cambiar la vida a un niño en Tumaco dándole la oportunidad de estudiar y jugar fútbol. Mes a mes te estaremos informando con fotos y videos de cómo estamos utilizando estos recursos para permitirle a estos niños construir su proyecto de vida lejos de la violencia
                  </p>
                </div>
                <div className="plan-description">
                  <h6 className="plan-title">Programa de Alto Rendimiento</h6>
                  <p>
                    Por $120.000 pesos mensuales apoyarás a un joven de alto rendimiento a conseguir su sueño de ser futbolista. Mes a mes te estaremos informando con fotos y videos cómo estamos utilizando estos recursos para permitirle a estos jóvenes construir su proyecto de vida lejos de la violencia
                  </p>
                </div>
              </>
            }
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PaymentExtensionFutbolPazifico;
