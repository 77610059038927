import React from "react";
import {Row, Col} from "react-bootstrap";
import "./styles/allies.scss";

function Awards(props) {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return !isMobile ? (
    <div>
      <Row>
        <Col md={3} xs={6}>
          <div className="awards-item">
            <a
              href="https://www.unwto.org/news/transforming-tourism-unwto-global-startup-competition-winners-announced"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("../img/FoundationLanding/award1.jpg")}
                alt="Global Startup Competition"
              />
            </a>
          </div>
        </Col>

        <Col md={3} xs={6}>
          <div className="awards-item">
            <a
              href="https://www.fundacionelnogal.org.co/premio-fundacion-el-nogal/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("../img/FoundationLanding/awards2x2.png")}
                alt="Premio Fundación El Nogal"
              />
            </a>
          </div>
        </Col>

        <Col md={3} xs={6}>
          <div className="awards-item">
            <a
              href="https://www.semana.com/empresas/confidencias-on-line/articulo/cuatro-emprendimientos-colombianos-iran-a-concurso-de-alibaba-en-china/294544/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("../img/FoundationLanding/awards3x2.png")}
                alt="Ganadores Alibaba Group"
              />
            </a>
          </div>
        </Col>

        <Col md={3} xs={6}>
          <div className="awards-item">
            <a
              href="https://administracion.uniandes.edu.co/noticias/nibi-uno-de-los-finalistas-en-iniciativa-social-skin-del-grupo-bolivar"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("../img/FoundationLanding/awards4x2.png")}
                alt="Finalistas Social Skin"
              />
            </a>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="awards-item">
            Ganadores internacionales en concurso de Naciones Unidas - 2021
          </p>
        </Col>
        <Col>
          <p className="awards-item">
            Ganadores nacionales del Premio Fundación El Nogal - 2021
          </p>
        </Col>
        <Col>
          <p className="awards-item">
            Ganadores nacionales en concurso de Alibaba Group - 2020
          </p>
        </Col>
        <Col>
          <p className="awards-item">
            Finalistas concurso Social Skin Fundación Bolívar Davivienda - 2019
          </p>
        </Col>
      </Row>
    </div>
  ) : (
    <div>
      <Col>
        <div className="awards-item">
          <a
            href="https://www.unwto.org/news/transforming-tourism-unwto-global-startup-competition-winners-announced"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require("../img/FoundationLanding/award1.jpg")}
              alt="Global Startup Competition"
            />
          </a>
        </div>
      </Col>
      <Col>
        <p className="awards-item">
          Ganadores internacionales en concurso de Naciones Unidas - 2021
        </p>
      </Col>
      <Col>
        <div className="awards-item">
          <a
            href="https://www.fundacionelnogal.org.co/premio-fundacion-el-nogal/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require("../img/FoundationLanding/awards2x2.png")}
              alt="Premio Fundación El Nogal"
            />
          </a>
        </div>
      </Col>
      <Col>
        <p className="awards-item">
          Ganadores nacionales del Premio Fundación El Nogal - 2021
        </p>
      </Col>
      <Col>
        <div className="awards-item">
          <a
            href="https://www.semana.com/empresas/confidencias-on-line/articulo/cuatro-emprendimientos-colombianos-iran-a-concurso-de-alibaba-en-china/294544/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require("../img/FoundationLanding/awards3x2.png")}
              alt="Ganadores Alibaba Group"
            />
          </a>
        </div>
      </Col>
      <Col>
        <p className="awards-item">
          Ganadores nacionales en concurso de Alibaba Group - 2020
        </p>
      </Col>
      <Col>
        <div className="awards-item">
          <a
            href="https://administracion.uniandes.edu.co/noticias/nibi-uno-de-los-finalistas-en-iniciativa-social-skin-del-grupo-bolivar"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require("../img/FoundationLanding/awards4x2.png")}
              alt="Finalistas Social Skin"
            />
          </a>
        </div>
      </Col>
      <Col>
        <p className="awards-item">
          Finalistas concurso Social Skin Fundación Bolívar Davivienda - 2019
        </p>
      </Col>
    </div>
  );
}

export default Awards;
