import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import './styles/shoppingCart.scss';
import { formatMoney } from '../utils/formatMoney';

function formatDate(date) {
  var newDate = new Date(date);
  var dd = newDate.getDate();
  var mm = newDate.getMonth() + 1; //January is 0!
  var yyyy = newDate.getFullYear();
  if (dd < 10) {
    dd = '0' + dd
  }
  if (mm < 10) {
    mm = '0' + mm
  }

  return newDate = dd + '-' + mm + '-' + yyyy;
}

class ShoppingCartItem extends React.Component {

  async handleDeleteItem(id) {
    const prom = await fetch('/shoppingCartItems/delete', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: id,
      }),
    });

    const json = await prom.json();

    if (json.success) {
      this.props.refresh();
    }
  }

  render() {

    const { item } = this.props;

    return (
      <Row>
        <Col md={2}>
          {
            item.program ?
              <Link to={`/programas/${encodeURIComponent(item.program.name.replace(/ /g, '_'))}`}>
                <div className="itemImgContainer">
                  <img src={item.program.thumbnail} className="itemImg" alt="Imagen del programa" />
                </div>
              </Link>
              :
              <div className="itemImgContainer">
                <img src={item.product.thumbnail} className="itemImg" alt="Imagen del producto" />
              </div>
          }
        </Col>
        <Col md={6}>
          <div className="itemName">
            {item.program ?
              <Link to={`/programas/${encodeURIComponent(item.program.name.replace(/ /g, '_'))}`}>
                {item.program.name}
              </Link>
              :
              item.product.name
            }

          </div>
          {item.note ?
            <div className="dateAdded">
              {item.note}
            </div>
            : null
          }
          <div className="dateAdded">
            Agregado el {formatDate(item.dateCreated)}
          </div>
          <div className="actionBar">

          </div>
        </Col>
        <Col md={4}>
          <div className="itemPrice">
            <div style={{ marginBottom: '10px' }}>
              COP {formatMoney(item.amount)} {item.nibis ? <span> + {item.nibis} <img src={require("../img/nibi_coins.png")} style={{ height: "20px" }} alt="Moneda Nibi" /></span> : null}
            </div>
            <Button variant="outline-danger" onClick={() => this.handleDeleteItem(item._id)}>
              <i
                className="fa fa-trash"
                style={{ color: 'firebrick', cursor: 'pointer' }}
              />
            </Button>
          </div>
        </Col>
      </Row>
    );
  }
}

export default ShoppingCartItem;
