import React from "react";
import "./styles/allies.scss";

function Media(props) {
  return (
    <div className="media-logos">
      <a
        href="https://caracol.com.co/radio/2020/11/13/comercial/1605288081_388510.html"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={require("../img/FoundationLanding/caracol.png")}
          style={{ height: "60px" }}
          alt="Logo Caracol Radio"
        />
      </a>
      <a
        href="https://www.semana.com/emprendimiento/articulo/salvemos-el-2020-la-campana-liderada-por-emprendedores-colombianos/306738/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={require("../img/FoundationLanding/semana.png")}
          alt="Logo Semana"
        />
      </a>
      <a
        href="https://www.elespectador.com/cromos/estilo-de-vida/como-donar-a-causas-sociales-sin-ser-estafado/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={require("../img/FoundationLanding/espectador.png")}
          alt="Logo El Espectador"
        />
      </a>
    </div>
  );
}

export default Media;
