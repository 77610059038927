let wompiBaseUrl, redirectBaseUrl, wompiPublicKey, epaycoBaseUrl, epaycoIsTest, epaycoPKey, epaycoPrivateKey, epaycoPublicKey;

if (process.env.NODE_ENV === 'production') {
    // Production

    wompiBaseUrl = 'https://production.wompi.co/v1';
    redirectBaseUrl = 'https://nibi.com.co';
    //wompiPublicKey = 'pub_prod_nUwz5ddiEk6A964dMtyGrzKOFOh78LKh';
    //Cuenta alterna
    wompiPublicKey = 'pub_prod_NLBmnw3o3NeUau4bTvvi4NpzjprGTnOf';

    epaycoBaseUrl = "https://apify.epayco.co";
    epaycoIsTest = false;

} else {
    // Sandbox.

    wompiBaseUrl = 'https://sandbox.wompi.co/v1';
    redirectBaseUrl = 'http://localhost:3000';
    wompiPublicKey = 'pub_test_a3eXgYUkLhCVHBkaGtfqXNImcKE75yZ8';

    epaycoBaseUrl = "https://apify.epayco.co";
    epaycoIsTest = true;
}

epaycoPKey = "a509574192e94aad063cc89160f43a3213a1334c";
epaycoPublicKey = "b1318567d307651240f02fc5a8942dda";
epaycoPrivateKey = "a161463c393b690ae2612705b95306d8";
let pciVaultUrl = "https://api.pcivault.io/v1";
let pciVaultUser = "Nibi";
let pciVaultPassword = "YEv#MT36hD";
let pciVaultPassphrase = "PRVS9H-eLU8fDMFtGXPbWlJJmgz_4oSz";
let caringPaypalKey = "Aec_BbHGbiq6rXm202ZAz9UMTSiPh39Vmk1fTRAo17qbKgWxo1kTaChPpwRS8Jbu6ZRqGfRW6DLhjDQ5";
let nibiPaypalKey = "Afw-3d_37RDnmEIqwuuhYgv0RMoX8IcoqDnkuSGwj78h4eFtXbTdRlB-39JWikrG7bSna0aBF6VJRjaI";

export { wompiBaseUrl, redirectBaseUrl, wompiPublicKey, epaycoBaseUrl, epaycoIsTest, epaycoPKey, epaycoPrivateKey, epaycoPublicKey, pciVaultUrl, pciVaultUser, pciVaultPassword, pciVaultPassphrase, caringPaypalKey, nibiPaypalKey };