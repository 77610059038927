import React from "react";
import "./styles/allies.scss";

function Allies(props) {
  return (
    <div className="partners">
      <img
        src={require("../img/FoundationLanding/Aliados/aliados1x2.png")}
        alt="Logo Makaia"
      />
      <img
        src={require("../img/FoundationLanding/Aliados/aliados2x2.png")}
        alt="Logo Caring for Colombia"
      />
      <img
        src={require("../img/FoundationLanding/Aliados/aliados3x2.png")}
        alt="Logo Uniandinos"
      />
      <img
        src={require("../img/FoundationLanding/Aliados/aliados4x2.png")}
        alt="Logo Camara Verde de Comercio"
      />
      <img
        src={require("../img/FoundationLanding/Aliados/aliados5x2.png")}
        style={{ height: "80px" }}
        alt="Logo Fundacion Grupo Bancolombia"
      />
      <img
        src={require("../img/FoundationLanding/Aliados/aliados6x2.png")}
        alt="Logo Fundación Bolívar Davivienda"
      />
      <img
        src={require("../img/FoundationLanding/Aliados/aliados7x2.png")}
        alt="Logo GivingTuesday"
      />
      <img
        src={require("../img/FoundationLanding/Aliados/aliados8x2.png")}
        style={{ height: "80px" }}
        alt="Logo ACI"
      />
      <img
        src={require("../img/FoundationLanding/Aliados/aliados9x2.png")}
        alt="Logo innpactia"
      />
      <img
        src={require("../img/FoundationLanding/Aliados/aliados10x2.png")}
        alt="Logo Voluntariado Uniandes"
      />
      <img
        src={require("../img/FoundationLanding/Aliados/aliados11x2.png")}
        alt="Logo Azaí Consultores"
      />
      <img
        src={require("../img/FoundationLanding/Aliados/aliados12x2.jpg")}
        alt="Logo Quantica"
      />
      <img
        src={require("../img/FoundationLanding/Aliados/aliados13x2.jpg")}
        alt="Logo PPU Legal"
      />
      <img
        src={require("../img/FoundationLanding/Aliados/aliados14x2.png")}
        alt="Logo Recon"
      />
    </div>
  );
}

export default Allies;
