/* global fbq */
import React, { Component } from 'react';
import './styles/RegisterModal.scss';
import { setInStorage } from '../utils/storage';
import { Spinner, Form, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { userLogin, setUser, setIsFoundation, setIsEnterprise, setUserId, setToken } from '../Redux/modules/auth';
import { wsConnect } from '../Redux/modules/websocket';
import wsUrl from '../utils/wsUrl';

function mapDispatchToProps(dispatch) {
  return {
    userLogin: () => dispatch(userLogin()),
    setUser: user => dispatch(setUser(user)),
    setUserId: userId => dispatch(setUserId(userId)),
    setIsFoundation: isFoundation => dispatch(setIsFoundation(isFoundation)),
    setIsEnterprise: isEnterprise => dispatch(setIsEnterprise(isEnterprise)),
    setToken: token => dispatch(setToken(token)),
    wsConnect: host => dispatch(wsConnect(host))
  };
}

class ConnectedFoundationEnterpriseRegisterComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      password: '',
      confPassword: '',
      isFoundation: props.isFoundation || false,
      isLoading: false,
      signupError: '',
      cellphone: "",
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSignUp = this.handleSignUp.bind(this);
    this.handleSignIn = this.handleSignIn.bind(this);
    this.updateUserSession = this.updateUserSession.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  async handleSubmit(event) {

    event.preventDefault();
    event.stopPropagation();

    this.setState({ formValidated: true, isLoading: true, signupError: '' });

    const form = event.currentTarget;
    const { password, confPassword, email, isFoundation } = this.state;
    const validPassword = password === confPassword;

    if (!validPassword) this.setState({ signupError: 'Las contraseñas deben coincidir.' });

    if (form.checkValidity() && validPassword) {

      const findUser = await fetch(`/users/find/email?email=${email.toLowerCase()}`);
      const findUserJson = await findUser.json();

      if (findUserJson.exists) {
        this.setState({ signupError: 'Ya existe un usuario con ese correo.' });
      } else {
        const success = await this.handleSignUp();
        if (!success) {
          this.setState({ signupError: 'Error al registrarse.' });
        }
        else {

          fbq('track', 'CompleteRegistration', { value: 0, currency: 'COP' });

          if (this.props.plan) window.location.href = '/suscripciones?plan=' + this.props.plan;
          else if (isFoundation) window.location.href = '/dashboardFoundation/perfil?welcome=true';
          else window.location.href = '/dashboardEnterprise/perfil';

          this.handleClose();
        }
      }
    }

    this.setState({ isLoading: false });

  }

  async updateUserSession(userId, isFoundation, isEnterprise, token) {

    this.props.setIsFoundation({ isFoundation });
    this.props.setIsEnterprise({ isEnterprise });
    this.props.setToken({ token });

    if (isEnterprise) {
      const dataEnterprise = await fetch('/enterprises/' + userId);
      const res = await dataEnterprise.json();

      this.props.setUser({ user: res });
    } else if (isFoundation) {
      const dataFoundation = await fetch('/foundations/foundation/' + userId);
      const res = await dataFoundation.json();

      const dataFoundationInfo = await fetch('/foundations/foundation/info/' + res.foundationId);
      const res2 = await dataFoundationInfo.json();

      this.props.setUser({ user: res2 });

    } else {
      const dataUser = await fetch('/users/user/' + userId);
      const res = await dataUser.json();

      this.props.setUser({ user: res });
    }

    this.props.setUserId({ userId });
    this.props.userLogin();
    this.props.wsConnect(`${wsUrl}/${token}`);
  }

  async handleSignIn() {

    const { email, password, isFoundation } = this.state;

    const fetchParams = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        password
      })
    };

    const fetchRoute = isFoundation ? '/foundations/signin' : '/enterprises/signin';

    const promSignin = await fetch(fetchRoute, fetchParams);
    const signinJson = await promSignin.json();

    if (signinJson) {
      setInStorage('nibi_app', { token: signinJson.token });
      await this.updateUserSession(signinJson.userId, signinJson.isFoundation, signinJson.isEnterprise, signinJson.token);
    }

  }

  async handleSignUp() {

    const {
      name,
      email,
      password,
      isFoundation,
      cellphone
    } = this.state;

    let prom;

    if (isFoundation) {
      prom = await fetch('/foundations/create/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name,
          email
        })
      });

      const json = await prom.json();

      if (json.success) {
        const prom2 = await fetch('/foundations/create/user', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            foundationId: json.foundationId,
            password,
            email
          })
        });

        const json2 = await prom2.json();

        if (json2.success) {
          await this.handleSignIn();

          fetch('/email/foundationRegistered', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              email,
              name,
              cellphone
            })
          });

        }

        return json2.success;

      } else {
        return json.success;
      }

    } else {
      prom = await fetch('/enterprises/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name,
          email,
          password
        })
      });

      const json = await prom.json();

      if (json.success) {
        await this.handleSignIn();

        fetch('/email/enterpriseRegistered', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email
          })
        });

      }

      return json.success;

    }
  }

  handleClose() {
    this.setState({
      name: '',
      email: '',
      password: '',
      confPassword: '',
      isFoundation: this.props.isFoundation || false,
      isLoading: false,
      signupError: ''
    });

    this.props.handleCloseRegisterModal();
  }

  render() {

    const {
      name,
      email,
      password,
      isFoundation,
      isLoading,
      signupError,
      formValidated,
      confPassword,
      cellphone
    } = this.state;

    return (
      <div>
        <Form noValidate validated={formValidated} onSubmit={this.handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} lg={12}>
              <Form.Control
                type="text"
                value={name}
                name="name"
                onChange={this.handleInputChange}
                placeholder={isFoundation ? 'Ingresa el nombre de la organización' : 'Ingresa el nombre del comercio'}
                required
              />
              <Form.Control.Feedback type="invalid">
                Ingresa un nombre válido.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} lg={12}>
              <Form.Control
                type="email"
                value={email}
                name="email"
                onChange={this.handleInputChange}
                placeholder="Correo electrónico"
                required
              />
              <Form.Control.Feedback type="invalid">
                Ingresa un email válido.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} lg={12}>
              <Form.Control
                type="cellphone"
                value={cellphone}
                name="cellphone"
                onChange={this.handleInputChange}
                placeholder="Número telefónico"
                required
              />
            </Form.Group>

            <Form.Group as={Col} lg={6}>
              <Form.Control
                type="password"
                value={password}
                name="password"
                onChange={this.handleInputChange}
                required
                placeholder="Contraseña"
              />
              <Form.Control.Feedback type="invalid">
                Ingresa una contraseña válida.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} lg={6}>
              <Form.Control
                type="password"
                value={confPassword}
                name="confPassword"
                onChange={this.handleInputChange}
                required
                placeholder="Confirmar contraseña"
              />
              <Form.Control.Feedback type="invalid">
                Ingresa una contraseña válida.
              </Form.Control.Feedback>
            </Form.Group>

          </Form.Row>

          <div className="login-btn-section">
            <span className="privacy-terms">
              <div>Para registrarte debes leer y aceptar</div> <br />

              <Form.Group controlId="formBasicCheckbox">
                <Form.Check inline type="checkbox" id="termsConditions" >
                  <Form.Check.Input inline type="checkbox" required />
                  <Form.Control.Feedback type="invalid">
                    Debes aceptar los términos y condiciones
                </Form.Control.Feedback>
                </Form.Check>
                <a
                  className="forgot-password"
                  href={
                    isFoundation ?
                      'https://docs.google.com/document/d/e/2PACX-1vQL_C-E-Pyh44L2cQa4H4EI-qR_u0XZPb10ZJfpYd96ck9Wm1vmFfqbdVUUFyWjHg/pub'
                      :
                      'https://docs.google.com/document/d/e/2PACX-1vTXkjl6X1DT7Up2kWbG6lggA1UWj-mdG73G2sif-HG1pJhiNZPf6CyQNPvR-exl0g/pub'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>los términos y condiciones</span>
                </a>
              </Form.Group>

              <Form.Group controlId="formBasicCheckbox">
                <Form.Check inline type="checkbox" id="privacy">
                  <Form.Check.Input inline type="checkbox" required />
                  <Form.Control.Feedback type="invalid">
                    Debes aceptar las políticas de privacidad
                </Form.Control.Feedback>
                </Form.Check>
                <a
                  className="forgot-password"
                  href='https://docs.google.com/document/d/e/2PACX-1vQAHdcqEsBdWYxpAXCWWTDBvl2AQxXAZE_qdxIaO7cfRE0Y7DR8VcvhBIERTHKvHQ/pub'
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>las políticas de tratamiento de datos</span>
                </a>
                {' '}y{' '}
                <a
                  className="forgot-password"
                  href='https://docs.google.com/document/d/e/2PACX-1vQ0iI3eL_Vc2t-xXKj0WzfPOywq986oIaGp6-Cot9LSPFzb9y0W-bg0GWuQS3w_Ag/pub'
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>solicitud de autorización</span>
                </a>
              </Form.Group>
            </span>

            <button type="submit" className="hexagon-btn modal-button">
              {
                isLoading ? <Spinner size="sm" animation="border" variant="dark" /> : 'Registrarse'
              }
            </button>
          </div>

          {
            signupError && <div className="signup-error">{signupError}</div>
          }

        </Form>
      </div>
    );
  }
}

const FoundationEnterpriseRegisterComponent = connect(null, mapDispatchToProps)(ConnectedFoundationEnterpriseRegisterComponent);

export default FoundationEnterpriseRegisterComponent;
