import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Row, Modal } from 'react-bootstrap';
import NibifyFilter from './NibifyFilter';
import queryString from 'query-string';
import { plans } from '../utils/nibify';
import { formatMoney } from '../utils/formatMoney';
import NibifyPayment from './NibifyPayment';
import ProgramCard from '../Home/ProgramCardHome';
import NibifyFoundationCard from './NibifyFoundationCard';
import ReactGA from 'react-ga';
import moment from 'moment';
import AppSpinner from '../Extras/AppSpinner';
import { setShowRegisterModal } from '../Redux/modules/auth';
import { connect } from 'react-redux';
import './styles/nibifyComponent.scss';

const trackingId = 'UA-130761574-1';
ReactGA.initialize(trackingId);

const select = state => {
  return {
    token: state.auth.token,
    userId: state.auth.userId,
    showRegisterModal: state.auth.showRegisterModal
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setShowRegisterModal: show => dispatch(setShowRegisterModal(show))
  };
}

function ConnectedNibify(props) {

  const { userId } = props;

  const values = queryString.parse(props.location.search);
  const planId = values.plan;

  const [tab, setTab] = useState(values.id ? 2 : 0);
  const [results, setResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [resultsType, setResultsType] = useState('ORGANIZATION');
  const [showMoreResults, setShowMoreResults] = useState(false);
  const [foundationId, setFoundationId] = useState(values.id ? values.id : '');
  const [foundation, setFoundation] = useState();
  const [loadingFoundationInfo, setLoadingFoundationInfo] = useState(true);
  const [paymentDone, setPaymentDone] = useState(false);
  const [showChangePlanModal, setShowChangePlanModal] = useState(values.id ? true : false);
  const [plan, setPlan] = useState(plans[planId]);

  useEffect(() => {
    if (tab === 0) {
      ReactGA.set({ page: '/nibify/causas' });
      ReactGA.pageview('/nibify/causas');
    }
  }, []);

  const getFoundationInfo = async () => {
    setLoadingFoundationInfo(true);

    const foundationProm = await fetch(`/foundations/foundation/info/${foundationId}`);
    const foundationJson = await foundationProm.json();

    setFoundation(foundationJson);
  };

  useEffect(() => {
    if (!userId) props.setShowRegisterModal({ show: true, registerUserType: 'USER' });
    else props.setShowRegisterModal({ show: false });
  }, [props.showRegisterModal, userId]);

  useEffect(() => {
    if (foundation) setLoadingFoundationInfo(false);
  }, [foundation]);

  useEffect(() => {
    if (foundationId) getFoundationInfo();
  }, [foundationId]);

  useEffect(() => {
    if (showResults) {
      setTab(1);

      ReactGA.set({ page: '/nibify/organizaciones' });
      ReactGA.pageview('/nibify/organizaciones');
    }
  }, [showResults]);

  useEffect(() => {
    if (tab === 2) window.scrollTo(0, 0);
  }, [tab]);

  const handleGetResults = (results, type) => {
    setResultsType(type);
    setResults(results);
    setShowResults(true);
  };

  const handleClick = foundationId => {
    setFoundationId(foundationId);
    setTab(2);

    ReactGA.set({ page: '/nibify/pago' });
    ReactGA.pageview('/nibify/pago');
  };

  const handleTransactionSuccess = () => {
    setPaymentDone(true);

    ReactGA.set({ page: '/nibify/suscripcion' });
    ReactGA.pageview('/nibify/suscripcion');
  };

  return (
    <div className="nibify-component">
      <Container>
        <Row>
          <Col xs={12}>
            <div className="nibify-nav">
              <div className="nibify-progress-container">
                <div className="progress-section" style={{ width: `${(tab * 100/3) + 100/3}%` }}></div>
              </div>
              <Form.Row>
                <Col>
                  <div className={`nibify-nav-label${tab === 0 ? ' active' : ''}`}>
                    Selecciona tu causa
                  </div>
                </Col>
                <Col>
                  <div className={`nibify-nav-label${tab === 1 ? ' active' : ''}`}>
                    Escoge una fundación
                  </div>
                </Col>
                <Col>
                  <div className={`nibify-nav-label${tab === 2 ? ' active' : ''}`}>
                    Realiza el pago
                  </div>
                </Col>
              </Form.Row>
            </div>

            <h5 className="nibify-title">
              {
                tab === 0 ? 'Encuentra tu fundación' :
                tab === 1 ? 'Selecciona tu fundación' :
                'Tu plan'
              }
            </h5>
          </Col>
          <Col xs={12}>
            {
              tab === 0 ?
              <NibifyFilter returnResults={(results, type) => handleGetResults(results, type)} />:
              tab === 1 ?
              <div>
                {
                  results.length > 0 &&
                  <div className="nibi-results">
                    <div className="goBack" onClick={() => {
                      setResults([]);
                      setResultsType('ORGANIZATION');
                      setShowResults(false);
                      setTab(0);
                    }}><i className="fas fa-chevron-left" /> Volver</div>
                    <Row>
                      {
                        results.slice(0, showMoreResults ? results.length : 3).map(result =>
                          <Col lg="4" key={result._id}>
                            {
                              resultsType === 'ORGANIZATION' ?
                              <NibifyFoundationCard foundation={result} handleClick={() => handleClick(result._id)} /> :
                              <ProgramCard program={result} />
                            }
                          </Col>
                        )
                      }
                    </Row>
                    {
                      !showMoreResults && results.length > 3 &&
                      <div className="showMoreOptions" onClick={() => setShowMoreResults(true)}><i className={`fas fa-chevron-down`} /> Ver más opciones</div>
                    }
                  </div>
                }
              </div> :
              loadingFoundationInfo ?
              <AppSpinner /> :
              plan &&
              <div>
                {
                  !paymentDone &&
                  <div className="plan-info">
                    <div className="plan-title">
                      <span className="plan-name">
                        Plan {plan.name}
                      </span>
                    </div>
                    <div className="plan-description">
                      <h5>Primer cargo: {moment(new Date()).format('DD/MM/YYYY')}</h5>
                      <h5>Cargo mensual: {(new Date()).getDate() <= 28 ? (new Date()).getDate() : 28} de cada mes.</h5>
                      <ul>
                        <li>Harás una donación a la fundación: {foundation.name}</li>
                        <li>{plan.description}</li>
                        <li>Puedes cancelar en cualquier momento.</li>
                      </ul>
                    </div>
                    <div className="change-plan">
                      <span className="plan-price">
                        ${formatMoney(plan.amount)}
                      </span>
                      <span onClick={() => setShowChangePlanModal(true)}>
                        Cambiar de plan
                      </span>
                    </div>
                  </div>
                }

                <h4 style={{ color: '#302466', fontWeight: 'bold', fontSize: '1.15rem' }}>
                  Método de pago
                </h4>

                <div className="payment-method">
                  <NibifyPayment
                    amount={plan.amount}
                    nibifyPlan={plan.id}
                    foundationId={foundationId}
                    onTransactionSuccess={handleTransactionSuccess}
                  />
                </div>
              </div>
            }
          </Col>
        </Row>
      </Container>

      <Modal show={showChangePlanModal} onHide={() => setShowChangePlanModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Escoge tu donación mensual</Modal.Title>
        </Modal.Header>
        <Modal.Body className="nibify-change-plan-modal">
          <Row>
            <Col md='4' sm='12'>
              <div className='plan-card'>
                <div className='plan-title'>Plan abeja bebe</div>
                <div className='plan-description'>Recibe 1 producto cada 2 meses</div>
                <div className='plan-cost'>$15.000</div>
                <button onClick={() => {
                  setPlan(plans['0']);
                  setShowChangePlanModal(false);
                }}>Seleccionar</button>
              </div>
            </Col>
            <Col md='4' sm='12'>
              <div className='plan-card' style={{ border: '1px solid #FFEAB1' }}>
                <div className='plan-title'>Plan abeja exploradora</div>
                <div className='plan-description'>Recibe 2 productos cada 2 meses</div>
                <div className='plan-cost'>$30.000</div>
                <button onClick={() => {
                  setPlan(plans['1']);
                  setShowChangePlanModal(false);
                }}>Seleccionar</button>
              </div>
            </Col>
            <Col md='4' sm='12'>
              <div className='plan-card' style={{ border: '1px solid #ED9800' }}>
                <div className='plan-title'>Plan abeja reina</div>
                <div className='plan-description'>Recibe 4 productos cada 2 meses</div>
                <div className='plan-cost'>$60.000</div>
                <button onClick={() => {
                  setPlan(plans['2']);
                  setShowChangePlanModal(false);
                }}>Seleccionar</button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );

}

const Nibify = connect(select, mapDispatchToProps)(ConnectedNibify);

export default Nibify;
