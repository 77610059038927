import React, { Component } from 'react';
import { Container, Row, Form, Button, Col, InputGroup } from 'react-bootstrap';
import { wompiBaseUrl, wompiPublicKey, redirectBaseUrl, pciVaultUrl, pciVaultPassphrase, pciVaultPassword, pciVaultUser, epaycoBaseUrl, epaycoIsTest, epaycoPKey, epaycoPrivateKey, epaycoPublicKey } from '../utils/paymentKeys';
import AppSpinner from '../Extras/AppSpinner';
import { connect } from 'react-redux';
import { formatCOP } from '../utils/formatMoney';
import AddressInfoIcon from '../Extras/AddressInfoIcon';
import './styles/shoppingCart.scss';

const select = state => {
  return {
    user: state.auth.user,
    token: state.auth.token,
    campaign: state.campaign.campaign
  };
};

class ConnectedCardPaymentComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      cardHolderName: '',
      cardHolderLastName: '',
      cardNumber: '',
      cvv: '',
      cardExpirationMonth: '',
      cardExpirationYear: '',
      installments: 1,
      acceptanceToken: '',
      cardToken: '',
      permalink: '',
      email: props.user.email,
      isLoading: true,
      formValidated: false,
      creatingTransaction: false,
      transactionStatus: '',
      saveCard: false,
      savedPaymentSource: 'Ninguna',
      userIdentification: props.user && props.user.identification ? props.user.identification : '',
      userIdentificationType: props.user && props.user.identificationType ? props.user.identificationType : 'CC',
      cellPhone: props.user && props.user.cellPhone ? props.user.cellPhone : '',
      contactMethod: 'EMAIL',
      deliveryInfo: this.props.deliveryInfo,
      userCity: props.user && props.user.donationCity ? props.user.donationCity : '',
      userAddress: props.user && props.user.donationAddress ? props.user.donationAddress : ''
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.tokenizeCard = this.tokenizeCard.bind(this);
    this.createCardPayment = this.createCardPayment.bind(this);
    this.createCardPayment = this.createCardPayment.bind(this);
    this.createTransactionEpayco = this.createTransactionEpayco.bind(this);
    this.checkTransaction = this.checkTransaction.bind(this);
    this.checkEpaycoTransaction = this.checkEpaycoTransaction.bind(this);
  }

  handleInputChange(e) {
    if ((e.target.name === "cardExpirationMonth" || e.target.name === "cardExpirationYear") && e.target.value.toString().length > 2) return;

    this.setState({ [e.target.name]: e.target.value.toString() });
  }

  async createCardPayment() {

    const {
      userIdentification,
      userIdentificationType,
      deliveryInfo,
      cellPhone,
      contactMethod,
      userCity,
      userAddress
    } = this.state;
    const showContactMethod = this.props.user && !this.props.user.contactMethod;
    const {
      token,
      user,
      items,
      products,
      totalAmount,
      campaign
    } = this.props;

    const body = {
      userId: user._id,
      items,
      products,
      totalAmount,
      paymentMethod: 'CARD',
      userIdentification,
      userIdentificationType,
      userCity,
      userAddress,
      cellPhone,
      contactMethod: showContactMethod ? contactMethod : '',
      campaign
    };

    if (products.length > 0 && deliveryInfo) {
      body.deliveryInfo =
        deliveryInfo.street + " " +
        (deliveryInfo.extra ? deliveryInfo.extra : '') + " " +
        (deliveryInfo.area ? deliveryInfo.area : '') + ", " +
        deliveryInfo.city + ", " +
        deliveryInfo.state + ", " +
        deliveryInfo.country + ", " +
        deliveryInfo.phone;
    }

    const paymentResponse = await fetch('/shoppingCartItems/createCartPayment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(body)
    });

    const paymentData = await paymentResponse.json();
    const { reference } = paymentData;

    return reference;
  }

  /* async createTransaction() {

    const { totalAmount, user } = this.props;
    const {
      acceptanceToken,
      installments,
      email,
      savedPaymentSource,
      saveCard,
      cardNumber
    } = this.state;

    if (savedPaymentSource === 'Ninguna') {
      const cardTokenId = await this.tokenizeCard();
      if (!cardTokenId) return;

      let saveCardFetch;

      if (saveCard) {
        const cardToken = await this.tokenizeCard();

        saveCardFetch = fetch('/payments/save_card', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            token: cardToken,
            userId: user._id,
            cardNumber: cardNumber.substring(cardNumber.length - 4),
            customer_email: user.email
          })
        });

      }

      const reference = await this.createCardPayment();

      const body = JSON.stringify({
        acceptance_token: acceptanceToken,
        amount_in_cents: parseInt(parseInt(totalAmount) + '00'),
        currency: 'COP',
        customer_email: email,
        payment_method: {
          type: 'CARD',
          installments: parseInt(installments),
          token: cardTokenId,
        },
        redirect_url: `${redirectBaseUrl}/pago/resultado`,
        reference
      });

      const transactionResponse = await fetch(`${wompiBaseUrl}/transactions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${wompiPublicKey}`
        },
        body
      });

      const transtactionData = await transactionResponse.json();
      if (transtactionData.data) {
        if (saveCardFetch) {
          const response = await saveCardFetch;
          await response.json();
        }

        return transtactionData.data.id;
      }
      else if (transtactionData.status === 422) {
        this.setState({ creatingTransaction: false })
        return alert("Datos de la tarjeta inválidos.");
      }
      else {
        this.setState({ creatingTransaction: false })
        return alert("Error al procesar la tarjeta.");
      }
    } else {
      const reference = await this.createCardPayment();

      const transactionResponse = await fetch('/payments/transaction/create/payment_source', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          amount: totalAmount,
          savedPaymentSource,
          userEmail: email,
          reference
        })
      });
      const transactionData = await transactionResponse.json();

      if (!transactionData.success) {
        this.setState({ creatingTransaction: false });
        return alert('Error al procesar la transacción.');
      }

      return transactionData.transactionId;
    }

  } */

  async getTokenApify(){
    const tokenApifyResponse = await fetch(`${epaycoBaseUrl}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic '+ new Buffer.from(epaycoPublicKey+":"+epaycoPrivateKey).toString('base64')
      }
    });

    const tokenApifyData = await tokenApifyResponse.json();
    return tokenApifyData.token;
  }

  async createTransactionEpayco() {

    const { totalAmount, user } = this.props;
    const {
      acceptanceToken,
      installments,
      email,
      savedPaymentSource,
      saveCard,
      cardNumber,
      cardExpirationMonth,
      cardExpirationYear,
      cardHolderLastName,
      cardHolderName,
      cvv,
      userIdentification,
      userIdentificationType,
      cellPhone,
    } = this.state;
    try{
      if (savedPaymentSource === 'Ninguna') {

        let saveCardFetch;
        if (saveCard) {
        const tokenizeCardResponse = await fetch(
          `${pciVaultUrl}/vault?user=NIBI&key=${pciVaultPassphrase}`,
          {
            method: "POST",
            body: JSON.stringify({
              card_expiry: `${cardExpirationMonth}-20${cardExpirationYear}`,
              card_holder: cardHolderName + " " + cardHolderLastName,
              card_number: cardNumber,
              cvv: cvv
            }),
            headers: {
              Authorization:
                "Basic " +
                new Buffer.from(pciVaultUser + ":" + pciVaultPassword).toString(
                  "base64"
                ),
              "Content-Type": "application/json"
            }
          }
        );
  
        const tokenCardData = await tokenizeCardResponse.json();
        if (tokenCardData.token) {
          saveCardFetch = fetch('/payments/save_card', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              token: tokenCardData.token,
              userId: user._id,
              cardNumber: cardNumber.substring(cardNumber.length - 4),
              customer_email: user.email
            })
          });
        }else{
          //Enviar log a BD
          await fetch('/nibiLogs/create', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              content: tokenCardData,
              userId: user._id,
              context:"Error al guardar tarjeta PCI pago en carrito"
            })
          });
        }
      }
  
        const reference = await this.createCardPayment();
  
        const token_apify = await this.getTokenApify();
  
        const body = JSON.stringify({
          value: `${parseInt(parseInt(totalAmount))}`,
          docType: userIdentificationType,
          docNumber: userIdentification,
          name: cardHolderName,
          lastName: cardHolderLastName,
          email: email,
          cellPhone: cellPhone,
          phone: cellPhone,
          cardNumber: cardNumber,
          cardExpYear: "20"+cardExpirationYear,
          cardExpMonth: cardExpirationMonth,
          cardCvc: cvv,
          dues: `${installments}`,
          redirect_url: `${redirectBaseUrl}/pago/resultado`,
          testMode: epaycoIsTest,
          extra1: reference,
          extra2: `Carrito de compras de usuario: ${this.props.user._id}`,
        });
  
        const transactionResponse = await fetch(`${epaycoBaseUrl}/payment/process`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token_apify}`
          },
          body
        });
  
        const transactionData = await transactionResponse.json();
        if (transactionData.data.transaction.data.estado === "Aceptada" && transactionData.data.transaction) {
          if (saveCardFetch) {
            const response = await saveCardFetch;
            await response.json();
          }
          if (saveCard) {
            if (transactionData.data.tokenCard.cardTokenId != "N/A" && transactionData.data.tokenCard.cardTokenId != "") {
              await fetch('/payments/save_epayco_card', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  token: transactionData.data.tokenCard.cardTokenId,
                  userId: this.props.user._id,
                  cardNumber: cardNumber.substring(cardNumber.length - 4),
                })
              });
            }
    
          }
          return transactionData.data.transaction.data.ref_payco;
        }else {
          this.setState({ creatingTransaction: false });
          //Enviar log a BD
          await fetch('/nibiLogs/create', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              content: transactionData,
              userId: user._id,
              context:"Transacción no aceptada en carrito de compras"
            })
          });
          return alert(transactionData.data.transaction.data.respuesta);
        }
      } else {
        const reference = await this.createCardPayment();
  
        const transactionResponse = await fetch('/payments/transaction/create/payment_source', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            amount: totalAmount,
            savedPaymentSource,
            userEmail: email,
            reference,
            userId:this.props.user._id,
            cardHolderName,
            cardHolderLastName,
            cellPhone,
            userIdentification,
            userIdentificationType,
          })
        });
        const transactionData = await transactionResponse.json();
  
        if (!transactionData.success) {
          this.setState({ creatingTransaction: false });
          //Enviar log a BD
          await fetch('/nibiLogs/create', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              content: transactionData,
              userId: this.props.user._id,
              context:"Error en /payments/transaction/create/payment_source desde carrito de compras"
            })
          });
          return alert('Error al procesar la transacción.');
        }
  
        return transactionData.transactionId;
      }
    }catch(e){
      this.setState({ creatingTransaction: false });
          //Enviar log a BD
          await fetch('/nibiLogs/create', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              content: e,
              userId: this.props.user._id,
              context:"Error en pago desde carrito de compras"
            })
          });
          return alert('Error al procesar la transacción.');
    }

  }

  async tokenizeCard() {

    const tokenizeCardResponse = await fetch(`${wompiBaseUrl}/tokens/cards`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${wompiPublicKey}`
      },
      body: JSON.stringify({
        "number": this.state.cardNumber,
        "cvc": this.state.cvv,
        "exp_month": this.state.cardExpirationMonth,
        "exp_year": this.state.cardExpirationYear,
        "card_holder": this.state.cardHolderName,
      }),
    });

    const tokenCardData = await tokenizeCardResponse.json();

    if (tokenizeCardResponse.status === 201 && tokenCardData.status === 'CREATED') { return tokenCardData.data.id }
    else if (tokenizeCardResponse.status === 422) {
      this.setState({ creatingTransaction: false });
      return alert("Datos de la tarjeta inválidos.");
    }
    else {
      this.setState({ creatingTransaction: false });
      return alert("Error al procesar la tarjeta.");
    }
  }

  async checkTransaction(transactionId) {

    const transactionResponse = await fetch(`${wompiBaseUrl}/transactions/${transactionId}`);
    const transaction = await transactionResponse.json();

    const { data } = transaction;

    if (data && data.status !== 'PENDING') {

      clearInterval(this.pollingTransaction);

      if (data.status === 'APPROVED') window.location = `${redirectBaseUrl}/pago-exitoso/?amount=${this.props.totalAmount}&t=0`;

      this.setState({ creatingTransaction: false, transactionStatus: data.status });
    }

  }

  async checkEpaycoTransaction(transactionId) {

    const token_apify = await this.getTokenApify();

    const transactionResponse = await fetch(`${epaycoBaseUrl}/payment/transaction`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token_apify}`
      },
      body: JSON.stringify({
        referencePayco: transactionId,
      })
    });
    const transaction = await transactionResponse.json();

    const { data } = transaction;
    if (data && data.response !== 'Pendiente') {
      clearInterval(this.pollingTransaction);
      window.location = `${redirectBaseUrl}/pago-exitoso/?amount=${this.props.totalAmount}&t=0`;
    }else{
      //Enviar log a BD
      await fetch('/nibiLogs/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          content: transaction,
          userId: this.props.user._id,
          context:"Transacción no aceptada en carrito de compras",
        })
      });
    }
    this.setState({ creatingTransaction: false, transactionStatus: data.response });

  }

  async handleSubmit(event) {

    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    this.setState({ formValidated: true });

    if (form.checkValidity()) {

      this.setState({ creatingTransaction: true });
      const transactionId = await this.createTransactionEpayco();

      if (!transactionId) return;

      this.pollingTransaction = setInterval(
        async () => {
          this.checkEpaycoTransaction(transactionId);
        },
        2000
      );
    }
  }

  async componentDidMount() {
    const acceptanceTokenFetch = fetch(`${wompiBaseUrl}/merchants/${wompiPublicKey}`);

    const acceptanceTokenResponse = await acceptanceTokenFetch;
    const acceptanceTokenData = await acceptanceTokenResponse.json();
    const { data } = acceptanceTokenData;

    if (data) {

      const presignedData = data.presigned_acceptance;

      this.setState({ acceptanceToken: presignedData.acceptance_token, permalink: presignedData.permalink });
    }

    this.setState({ isLoading: false });
  }

  render() {

    const {
      cardHolderName,
      cardHolderLastName,
      cardNumber,
      cardExpirationMonth,
      cardExpirationYear,
      cvv,
      installments,
      isLoading,
      creatingTransaction,
      permalink,
      formValidated,
      transactionStatus,
      saveCard,
      savedPaymentSource,
      userIdentificationType,
      userIdentification,
      cellPhone,
      contactMethod,
      userCity,
      userAddress
    } = this.state;
    const showContactMethod = this.props.user && !this.props.user.contactMethod;

    const paymentSources = this.props.user.creditCards || [];

    let title, message;

    if (transactionStatus === 'APPROVED') {
      title = 'Transacción exitosa';
      message = `Recibimos tu donación por un valor de ${formatCOP.format(this.props.totalAmount)}. Gracias por donar con Nibi, la transacción fue exitosa.`;
    } else if (transactionStatus === 'VOIDED') {
      title = 'Error en la transacción';
      message = `La transacción fue anulada por la pasarela de pagos. Por favor verifica la transacción con tu entidad bancaria o inténtalo nuevamente.`;
    } else if (transactionStatus === 'DECLINED') {
      title = 'Error en la transacción';
      message = `La transacción fue rechazada por la pasarela de pagos. Por favor verifica la transacción con tu entidad bancaria o inténtalo nuevamente.`;
    } else {
      title = 'Error en la transacción';
      message = 'Hubo un error al procesar la transacción. Por favor verifica la transacción con tu entidad bancaria o inténtalo nuevamente.';
    }

    if (isLoading) return <AppSpinner />;

    if (transactionStatus === 'APPROVED' || transactionStatus === 'VOIDED' || transactionStatus === 'DECLINED' || transactionStatus === 'ERROR') {
      return (
        <div className="landing-pse-payment-component">
          <Container>
            <Row>
              <Col>
                <h1 className="title">{title}</h1>
                <h4 className="message">{message}</h4>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }

    if (creatingTransaction) {
      return (
        <div className="pse-transaction-spinner">
          <AppSpinner />
          <h4 className="message">Por favor espera mientras procesamos la transacción</h4>
        </div>
      );
    }

    return (
      <div className="pse-payment-component">
        <Form noValidate validated={formValidated} onSubmit={this.handleSubmit}>
          {
            paymentSources.length > 0 &&
            <>
            <Form.Row>
              <Form.Group as={Col} lg={6}>
                <Form.Label>Usar tarjeta guardada</Form.Label>
                <Form.Control
                  as="select"
                  name="savedPaymentSource"
                  value={savedPaymentSource}
                  onChange={this.handleInputChange}
                >
                  <option value="Ninguna">Ninguna</option>
                  {
                    paymentSources.map((el, index) =>
                      <option key={index} value={el.paymentSourceId}>
                        **** **** **** {el.cardNumber}
                      </option>
                    )
                  }

                </Form.Control>
              </Form.Group>
            </Form.Row>
            </>
          }
          <Form.Row>
              <Form.Group as={Col} lg={6} xs={12}>
                  <Form.Label><small>Nombre(s)</small></Form.Label>
                  <Form.Control
                    name="cardHolderName"
                    type="text"
                    placeholder="Nombre(s)"
                    value={cardHolderName}
                    onChange={this.handleInputChange}
                    required
                  />
                </Form.Group>
                <Form.Group as={Col} lg={6} xs={12}>
                  <Form.Label><small>Apellido(s)</small></Form.Label>
                  <Form.Control
                    name="cardHolderLastName"
                    type="text"
                    placeholder="Apellido(s)"
                    value={cardHolderLastName}
                    onChange={this.handleInputChange}
                    required
                  />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} lg={4}>
                  <Form.Label><small>Número de Teléfono</small></Form.Label>
                  <Form.Control
                    name="cellPhone"
                    type="text"
                    placeholder="Teléfono"
                    value={cellPhone}
                    onChange={this.handleInputChange}
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} lg={4}>
                  <Form.Label><small>Tipo de documento</small></Form.Label>
                  <Form.Control name="userIdentificationType" as="select" value={userIdentificationType} onChange={this.handleInputChange}>
                    <option value="CC">CC</option>
                    <option value="NIT">NIT</option>
                    <option value="CE">CE</option>
                    <option value="Otro">Otro</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} lg={4}>
                  <Form.Label><small>Número de documento</small></Form.Label>
                  <Form.Control
                    name="userIdentification"
                    type="text"
                    placeholder="Documento"
                    value={userIdentification}
                    onChange={this.handleInputChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Ingresa tu documento.
                  </Form.Control.Feedback>
                </Form.Group>
            </Form.Row>
          {
            savedPaymentSource === 'Ninguna' &&
            <>
              <Form.Row>
                <Form.Group as={Col} lg={6} xs={12}>
                  <Form.Label>
                    Ciudad <AddressInfoIcon />
                  </Form.Label>
                  <Form.Control
                    name="userCity"
                    type="text"
                    placeholder="Ciudad"
                    value={userCity}
                    onChange={this.handleInputChange}
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} lg={6} xs={12}>
                  <Form.Label>
                    Dirección <AddressInfoIcon />
                  </Form.Label>
                  <Form.Control
                    name="userAddress"
                    type="text"
                    placeholder="Dirección"
                    value={userAddress}
                    onChange={this.handleInputChange}
                    required
                  />
                </Form.Group>

              </Form.Row>

              <Form.Row>

                <Form.Group as={Col} lg={6} xs={12}>
                  <Form.Label><small>Número de la tarjeta</small></Form.Label>
                  <Form.Control
                    name="cardNumber"
                    type="number"
                    placeholder="0000 0000 0000 0000"
                    value={cardNumber}
                    onChange={this.handleInputChange}
                    required
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} lg={4} xs={12}>
                  <Form.Label><small>Fecha de expiración</small></Form.Label>
                  <InputGroup>
                    <Form.Control
                      name="cardExpirationMonth"
                      type="number"
                      placeholder="MM"
                      min={1}
                      max={12}
                      value={cardExpirationMonth}
                      onChange={this.handleInputChange}
                      required
                    />
                    <Form.Control
                      name="cardExpirationYear"
                      type="number"
                      min={20}
                      placeholder="YY"
                      value={cardExpirationYear}
                      onChange={this.handleInputChange}
                      required
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group as={Col} lg={4} xs={12}>
                  <Form.Label><small>Código de seguridad</small></Form.Label>
                  <Form.Control
                    name="cvv"
                    type="number"
                    value={cvv}
                    onChange={this.handleInputChange}
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} lg={4} xs={12}>
                  <Form.Label><small>Número de cuotas</small></Form.Label>
                  <Form.Control
                    name="installments"
                    type="number"
                    min={1}
                    max={36}
                    value={installments}
                    onChange={this.handleInputChange}
                    required
                  />
                </Form.Group>
                {
                  showContactMethod &&
                  <Form.Group as={Col} lg={6}>
                    <Form.Label>
                      <small>
                        ¿Por dónde te gustaría recibir la información de tu impacto?
                      </small>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="contactMethod"
                      value={contactMethod}
                      onChange={this.handleInputChange}
                    >
                      <option value="WHATS_APP">WhatsApp</option>
                      <option value="CELL_PHONE">Llamada</option>
                      <option value="EMAIL">Correo</option>
                    </Form.Control>
                  </Form.Group>
                }
              </Form.Row>
            </>
          }

          <br />
          <Form.Row>
            {
              savedPaymentSource === 'Ninguna' &&
              <Form.Group as={Col} xs={12}>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="saveCardCheckbox"
                    onChange={event => this.setState({ saveCard: event.target.checked })}
                    checked={saveCard}
                  />
                  <label className="custom-control-label" htmlFor="saveCardCheckbox">
                    Deseo guardar mi tarjeta de crédito para futuras transacciones.
                  </label>
                </div>
              </Form.Group>
            }

            <Form.Group as={Col} xs={12}>
              <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="permalinkCheckBox" required />
                <label className="custom-control-label" htmlFor="permalinkCheckBox">
                  Acepto haber leído los <a href={permalink} target="_blank" rel="noopener noreferrer">términos y condiciones y la política de privacidad</a> para hacer este pago.
                </label>
              </div>
            </Form.Group>

            <Button variant="primary" className="btn-modal" type="submit">
              Pagar
            </Button>
          </Form.Row>
        </Form>

      </div>

    );
  }
}

const CardPaymentComponent = connect(select)(ConnectedCardPaymentComponent);

export default CardPaymentComponent;
