const SET_CAMPAIGN = 'nibi/campaign/SET_CAMPAIGN';
const SET_SHOW_CAMPAIGN_POPUP = 'nibi/campaign/SET_SHOW_CAMPAIGN_POPUP';
const SET_SHOW_RACE_POPUP = 'nibi/campaign/SET_SHOW_RACE_POPUP';
const SET_SHOW_ORG_PROMO = 'nibi/campaign/SET_SHOW_ORG_PROMO';
const SET_SHOW_FESTIVAL_PROMO = 'nibi/campaign/SET_SHOW_FESTIVAL_PROMO';

const initialState = {
  campaign: '',
  showCampaignPopup: false,
  showRacePopup: false,
  showOrgPromo: false,
  showFestivalPromo: false,
};

export default function reducer(state = initialState, action) {

  const payload = action.payload;

  switch (action.type) {
    case SET_CAMPAIGN:
      return {
        ...state,
        campaign: payload.campaign
      };
    case SET_SHOW_CAMPAIGN_POPUP:
      return {
        ...state,
        showCampaignPopup: payload.show
      };
    case SET_SHOW_RACE_POPUP:
      return {
        ...state,
        showRacePopup: payload.show
      };
    case SET_SHOW_ORG_PROMO:
      return {
        ...state,
        showOrgPromo: payload
      };
    case SET_SHOW_FESTIVAL_PROMO:
      return {
        ...state,
        showFestivalPromo: payload
      };
    default:
      return state;
  }

}

export function setCampaign(payload) {
  return { type: SET_CAMPAIGN, payload }
}

export function setShowCampaignPopup(payload) {
  return { type: SET_SHOW_CAMPAIGN_POPUP, payload }
}

export function setShowRacePopup(payload) {
  return { type: SET_SHOW_RACE_POPUP, payload }
}

export function setShowOrgPromo(payload) {
  return { type: SET_SHOW_ORG_PROMO, payload }
}

export function setShowFestivalPromo(payload) {
  return { type: SET_SHOW_FESTIVAL_PROMO, payload }
}
