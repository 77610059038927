import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import queryString from 'query-string';
import AppSpinner from '../Extras/AppSpinner';
import './styles/styles.scss'

function FoundationUserRegister(props) {

  const values = queryString.parse(props.location.search);
  const { token, fId } = values;

  const [loading, setLoading] = useState(true);
  const [tokenVerified, setTokenVerified] = useState(false);

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [confPassword, setConfPassword] = useState('');
  const [creating, setCreating] = useState(false);
  const [formValidated, setFormValidated] = useState(false);
  const [userCreated, setUserCreated] = useState(false);

  useEffect(() => {
    checkToken();
  }, []);

  const checkToken = async () => {
    setLoading(true);

    if (token) {
      const checkTokenProm = await fetch(`/foundations/user/verify/${token}`);
      const checkTokenResp = await checkTokenProm.json();

      if (checkTokenResp.success) setEmail(checkTokenResp.email);
      setTokenVerified(checkTokenResp.success);
    }

    setLoading(false);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    event.stopPropagation();

    setFormValidated(true);

    const form = event.currentTarget;

    if (password !== confPassword) return alert('Las contraseñas deben coincidir.');

    if (form.checkValidity()) {
      setCreating(true);

      const updateProm = await fetch('/foundations/user/update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          firstName,
          lastName,
          password,
          id: token,
          foundationId: fId
        })
      });

      const updateResp = await updateProm.json();

      if (!updateResp.success) {
        alert(updateResp.message || 'Ocurrió un error. Inténtalo de nuevo.');
      } else {
        setUserCreated(true);
        alert('Información actualizada correctamente.');
      }

      setCreating(false);
    }
  };

  if (loading) {
    return (
      <div className="foundation-user-register">
        <Container>
          <Row>
            <Col xs={12}>
              <AppSpinner />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  return (
    <div className="foundation-user-register">
      <Container>
        <Row className="justify-content-center">
          <Col md={10}>
            {
              (!token || !fId || !tokenVerified) ?
              <h3 style={{ textAlign: 'center', marginTop: '100px' }}>
                Error. Inténtalo nuevamente.
              </h3> :
              userCreated ?
              <h3 style={{ textAlign: 'center', marginTop: '100px' }}>
                Información actualizada correctamente. Ya puedes ingresar con tu correo y contraseña.
              </h3>:
              <div>
                <Form noValidate validated={formValidated} onSubmit={handleSubmit}>
                  <Form.Row>
                    <Form.Group as={Col} md={12}>
                      <Form.Label>Correo electrónico</Form.Label>
                      <Form.Control
                        type="email"
                        value={email}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                      <Form.Label>Nombre</Form.Label>
                      <Form.Control
                        type="text"
                        value={firstName}
                        onChange={event => setFirstName(event.target.value)}
                        placeholder="Nombre"
                        required
                      />
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                      <Form.Label>Apellido</Form.Label>
                      <Form.Control
                        type="text"
                        value={lastName}
                        onChange={event => setLastName(event.target.value)}
                        placeholder="Apellido"
                        required
                      />
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                      <Form.Label>Contraseña</Form.Label>
                      <Form.Control
                        type="password"
                        value={password}
                        onChange={event => setPassword(event.target.value)}
                        placeholder="Contraseña"
                        required
                      />
                    </Form.Group>
                    <Form.Group as={Col} md={6}>
                      <Form.Label>Confirmar contraseña</Form.Label>
                      <Form.Control
                        type="password"
                        value={confPassword}
                        onChange={event => setConfPassword(event.target.value)}
                        placeholder="Confirmar contraseña"
                        required
                      />
                    </Form.Group>
                    <Col xs={12}>
                      <Button type="submit" disabled={creating} style={{ padding: '5px 35px', display: 'block', margin: '15px auto 0' }}>
                        {
                          creating ? <Spinner variant="warning" animation="border" size="sm" /> : 'Confirmar'
                        }
                      </Button>
                    </Col>
                  </Form.Row>
                </Form>
              </div>
            }
          </Col>
        </Row>
      </Container>
    </div>
  );

}

export default FoundationUserRegister;
