import { combineReducers } from 'redux';
import auth from './auth';
import campaign from './campaign';
import business from './business';
import chat from './chat';
import billingPlanWarning from './billingPlanWarning';

export default combineReducers({
  auth,
  campaign,
  business,
  chat,
  billingPlanWarning
});