import React from 'react';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PaymentExtension from './PaymentExtension';
import './styles/rehabilitacion-integral.scss';

// const foundationId = '5e6832390149723a18478fe4';
// const foundationName = 'Fundación Redux';

const foundationId = '618d648ef355a21e6d731daa';
const foundationName = 'FUNDACION REHABILITACION INTEGRAL';

function PaymentExtensionRehabilitacionIntegral() {

  const [showPayment, setShowPayment] = useState(false);

  return (
    <div className="payment-extension-component-fp">
      <Container>
        <Row>
          <Col xs={12}>
            <div>
              <h2 className="title">Realiza tu donación mensual</h2>
              {/* <span className="amount">${formatMoney(amount)}</span> */}
              <hr />
            </div>
            {
              showPayment ?
              <PaymentExtension
                foundationId={foundationId}
                foundationName={foundationName}
                customDonation={true}
                onBack={() => setShowPayment(false)}
              /> :
              <>
                <div className="plan-description">
                  <h6 className="plan-title">
                  </h6>
                  <p>
                    Realiza tu donación desde 10.000 pesos.
                  </p>
                </div>
                <div>
                  <button className="nibi-btn" onClick={() => setShowPayment(true)}>
                    Continuar
                  </button>
                </div>
              </>
            }
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PaymentExtensionRehabilitacionIntegral;
